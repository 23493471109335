import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from "@mui/material";

import useTable from "components/Table/useTable";
import { TableEmptyRows, TableHeadCustom } from "components/Table";
import UserRow from "sections/@dashboard/member/CIndTabComponents";

const TABLE_HEAD = [
  {
    id: "",
    type: "checkbox",
  },
  {
    id: "email",
    label: "MAIN EMAIL",
    alignRight: false,
  },
  {
    id: "membership",
    label: "MEMBERSHIP",
  },
  {
    id: "jobtitle",
    label: "JOB TITLE",
  },
  {
    id: "birthyear",
    label: "BIRTHYEAR",
  },
  {
    id: "location",
    label: "LOCATION",
  },
  {
    id: "gender",
    label: "GENDER",
  },
  {
    id: "marital",
    label: "MARITAL STATUS",
  },
  {
    id: "status",
    label: "STATUS",
  },
  { id: "act" },
];

CIndTab.propTypes = {
  list: PropTypes.object,
  pagination: PropTypes.object,
  tempSelected: PropTypes.object,
  getAnyPage: PropTypes.func,
  getChangeRows: PropTypes.func,
  onEdit: PropTypes.func,
  loadDataPagi: PropTypes.func,
  countSelect: PropTypes.func,
  tempoSel: PropTypes.func,
  onChangeSpecialist: PropTypes.func,
};
export default function CIndTab({
  list,
  pagination,
  tempSelected,
  onChangeSpecialist,
  tempoSel,
  countSelect,
  getAnyPage,
  getChangeRows,
  handleDelete,
  onEdit,
}) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("username");
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [selected, setSelected] = useState<any[]>([]);
  const { dense, page, rowsPerPage, onSort } = useTable();
  useEffect(() => {
    //
  }, [selected]);
  const handleSelectAllClick = (event) => {
    if (tempSelected.length > 1) {
      tempoSel([]);
    } else {
      if (event.target.checked) {
        const newSelecteds = list.map((n) => n.invitation_email);
        const tempSel = list.map((n) => ({
          member_code: n.member_code,
          invitation_code: n.invitation_code,
          invitation_email: n.invitation_email,
        }));

        setSelected(newSelecteds);
        countSelect(newSelecteds);
        tempoSel(tempSel);

        return;
      }
    }

    setSelected([]);
  };
  const handleEditRow = (
    member_code,
    organization_code,
    department_code,
    statusa,
    role_name
  ) => {
    const status = statusa;
    const payload = {
      member_code,
      organization_code,
      department_code,
      status,
      role_name,
    };

    onEdit("edit", payload);
    // console.log(payload);
  };

  const handleCSpecialist = (row) => {
    onChangeSpecialist(row);
  };

  const dataFiltered = applySortFilter({
    list,
    comparator: getComparator(order, orderBy),
  });

  const denseHeight = dense ? 52 : 72;

  function findSelected(array, member_code) {
    return array.find((element) => {
      return element.member_code === member_code;
    });
  }
  const roleChange = (member_code) => {
    console.log(member_code);
  };

  const handleClick = (event, name, code, memcode) => {
    const selectedIndex = findSelected(tempSelected, memcode);

    const newSelected: any[] = [];
    let newSelectedHer: any[] = [];
    newSelected.push({
      invitation_email: name,
      invitation_code: code,
      member_code: memcode,
    });

    if (selectedIndex === undefined) {
      newSelectedHer = newSelected.concat(tempSelected);
    } else {
      newSelectedHer = tempSelected.filter(
        (item) => item.member_code !== memcode
      );
    }

    setSelected(newSelectedHer);
    countSelect(newSelectedHer);
    tempoSel(newSelectedHer);
  };

  return (
    <div>
      <TableContainer
        sx={{
          minWidth: 800,
          position: "relative",
        }}
      >
        <Table size={dense ? "small" : "medium"}>
          <TableHeadCustom
            order={order}
            checkSelected={tempSelected}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headLabel={TABLE_HEAD}
            rowCount={list.length}
            onSelectAllRows={handleSelectAllClick}
            numSelected={selected.length}
            onSort={onSort}
          />

          <TableBody>
            {dataFiltered.map((row, index) => {
              const found = findSelected(tempSelected, row.member_code);
              const labelIdx = `enhanced-table-checkbox-${index}`;

              return (
                <UserRow
                  handleClickFunc={(e) =>
                    handleClick(
                      e,
                      row.invitation_email,
                      row.invitation_code,
                      row.member_code
                    )
                  }
                  key={row.member_code}
                  isSelectedRow={found ? true : false}
                  row={row}
                  changeRole={(e) => roleChange(e)}
                  labelId={labelIdx}
                  handleDelete={() =>
                    handleDelete(row.member_code, row.content)
                  }
                  onEditRow={(a, b, c, d, e) => handleEditRow(a, b, c, d, e)}
                  changeSpecialist={() => handleCSpecialist(row)}
                />
              );
            })}

            <TableEmptyRows
              height={denseHeight}
              emptyRows={dataFiltered.length}
            />
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ position: "relative" }}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          labelRowsPerPage="Items per page: "
          count={pagination[0]?.count || 0}
          rowsPerPage={pagination[0]?.limit || 10}
          page={pagination[0]?.page - 1 || 0}
          onPageChange={(e, pg) => getAnyPage(pg)}
          onRowsPerPageChange={getChangeRows}
        />
      </Box>
    </div>
  );
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter({ list, comparator }) {
  const stabilizedThis = list.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  list = stabilizedThis.map((el) => el[0]);

  return list;
}
