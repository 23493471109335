import React, { useEffect, useState } from 'react';
import { Box, DialogActions, DialogContent, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';
import { ModalSimple } from 'components/Modal/ModalSimple';
import { TextAreaModal } from 'components/Modal/style';
import { useForm } from "react-hook-form";
import Iconify from 'screens/Layout/components/Iconify';
import { LabelModal } from 'screens/Login/style';
import { Button as ButtonStyle } from 'screens/style';

export default function ModalModify(props: {
    // listSales: any[];
    // listSubscription: any[];
    isAddContent: boolean;
    onSubmit: (val: any) => void;
    hide: () => void;
    isShow: boolean;
    value: any;
}) {
    const { register, formState: { errors }, setValue, handleSubmit } = useForm();

    useEffect(() => {
        if (props.isShow && !props.isAddContent) {
            const value = props.value;

            setValue('name', value.organization_name);
            setValue('email', value.email);
            setValue('phone', value.phone);
            setValue('address', value.office_address);
            // setValue('sales', value.sales_code);
            // setValue('subscription_code', value.subscription_code);
        }
    }, [props.isShow]);

    const onReset = () => {
        setValue('name', '');
        setValue('email', '');
        setValue('phone', '');
        setValue('address', '');
        // setValue('sales', '');
        // setValue('subscription_code', '');
    };

    const onSubmit = (val: any) => {
        props.onSubmit({
            email: val.email,
            name: val.name,
            phone: val.phone,
            office_address: val.address,
            // sales_code: val.sales,
            // subscription_code: val.subscription_code,
        });
    };

    return (
        <ModalSimple hide={ props.hide } isShown={ props.isShow } title={ `${props.isAddContent ? 'Add New Organization' : 'Edit Organization'}` } reset={ onReset }>
            <form onSubmit={ handleSubmit(onSubmit) }>
                <DialogContent>
                    <Box sx={ { overFlowY: 'initial !important' } }>
                        <div>
                            <LabelModal style={ {
                                margin: '20px 0 10px 0',
                                display: 'inline-block'
                            } }>Organization Name</LabelModal>
                            <div>
                                <OutlinedInput type='text'
                                    placeholder='Organization Name'
                                    fullWidth
                                    error={ errors.name !== undefined }
                                    { ...register('name', { required: 'organization name required!', minLength: 4 }) }
                                />
                            </div>

                            <LabelModal style={ {
                                margin: '10px 0 10px 0',
                                display: 'inline-block'
                            } }>Contact Person</LabelModal>
                            <div>
                                <OutlinedInput type='number'
                                    placeholder='Contact Person'
                                    fullWidth
                                    error={ errors.phone !== undefined }
                                    { ...register('phone', { required: 'contact person required!', min: 10 }) }
                                />
                            </div>

                            <LabelModal style={ {
                                margin: '10px 0 10px 0',
                                display: 'inline-block'
                            } }>Contact Email</LabelModal>
                            <div>
                                <OutlinedInput type='email'
                                    placeholder='Contact Email'
                                    fullWidth
                                    error={ errors.email !== undefined }
                                    { ...register('email', { required: 'contact email required!', min: 4 }) }
                                />
                            </div>

                            <LabelModal style={ {
                                margin: '10px 0 0 0',
                                display: 'inline-block'
                            } }>Contact Address</LabelModal>
                            <div className='input-wrapper form-inline'>
                                <TextAreaModal
                                    placeholder='Contact Address..'
                                    className={ `${errors.address !== undefined ? '!border !border-red-500' : ''}` }
                                    { ...register('address', { required: 'contact address required!' }) }
                                />
                            </div>

                            {/* <div className='input-wrapper' style={ { marginTop: '5px' } }>
                                <div className='flex gap-4' style={ { width: '100%' } }>
                                    <div className='time-schedule-cus' style={ { width: '50%' } }>
                                        <LabelModal className='block mb-2'>Sales Rep</LabelModal>
                                        <Select
                                            displayEmpty
                                            fullWidth
                                            defaultValue={ props.value.sales_code ?? '' }
                                            error={ errors.subscription !== undefined }
                                            className='h-12'
                                            { ...register('sales', { required: 'sales rep required!', minLength: 2 }) }
                                        >
                                            <MenuItem disabled value=''>
                                                <em>Select Sales Rep</em>
                                            </MenuItem>
                                            {
                                                props.listSales.map(fn => (
                                                    <MenuItem key={ fn.sales_code } value={ fn.sales_code }>
                                                        <em>{ fn.name }</em>
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </div>
                                    <div className='time-schedule-cus' style={ { width: '50%' } }>
                                        <LabelModal className='block mb-2'>Subscription</LabelModal>
                                        <Select
                                            fullWidth
                                            displayEmpty
                                            defaultValue={ props.value.subscription_code ?? '' }
                                            className='h-12'
                                            error={ errors.subscription !== undefined }
                                            { ...register('subscription_code', { required: 'subscription required!', minLength: 2 }) }
                                        >
                                            <MenuItem disabled value=''>
                                                <em>Subscription</em>
                                            </MenuItem>
                                            {
                                                props.listSubscription.map((val, i) => (
                                                    <MenuItem key={ i } value={ val.subscription_code }>
                                                        <em>{ val.subscription_plan }</em>
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </Box>
                </DialogContent>
                <DialogActions sx={ { padding: '20px 24px !important' } }>
                    <Box sx={ {
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%'
                    } }>
                        <ButtonStyle type='submit' className='btn-primary' title='Add New Admin' style={ { marginTop: '30px' } } >
                            { props.isAddContent ? "Add Organization" : "Edit Organization" }
                        </ButtonStyle>
                    </Box>
                </DialogActions>
            </form>
        </ModalSimple>
    );
}