import React from 'react';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import { PaperComponent } from 'components/Modal/Paper';
import { IVoidCallback } from 'interfaces/callback';
import { ModalCLoseIcon } from 'screens/Login/style';

export default function ModalInvitation(props: { isOpen: boolean, onClose: IVoidCallback, invitations: any[], onSend: IVoidCallback; }) {
    return (
        <Dialog
            open={ props.isOpen }
            onClose={ (_, reason) => {
                if (reason !== 'backdropClick') {
                    props.onClose();
                }
            } }
            PaperComponent={ PaperComponent }
            aria-labelledby='draggable-dialog-title'
            PaperProps={ { sx: { width: '470px', } } }
        >
            <DialogTitle style={ {
                cursor: 'move',
                textAlign: 'center'
            } } id='draggable-dialog-title'>
                Import Successful
                <DialogContentText sx={ {
                    fontSize: '14px',
                    textAlign: 'center',
                    fontFamily: 'Fonts.interRegular',
                    color: '#767E8C',
                    marginBottom: '20px'
                } }>
                    Kindly re-check email invitation list
                </DialogContentText>
                <ModalCLoseIcon title='Close' onClick={ props.onClose }><FontAwesomeIcon icon={ faClose } /></ModalCLoseIcon>
            </DialogTitle>
            <DialogContent>
                <DialogContentText sx={ {
                    display: 'inline-block',
                    alignItems: 'center',
                    background: '#FFFFFF',
                    borderRadius: '5px',
                    fontFamily: 'Inter-Regular',
                    textAlign: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    padding: '10px',
                    flexDirection: 'column'
                } }>
                    {
                        props.invitations.map(row => {
                            if (row.invitation_email !== '') {
                                return (<Typography sx={ {
                                    backgroundColor: 'lightblue',
                                    display: 'inherit',
                                    padding: '5px 10px',
                                    color: '#292A2E',
                                    borderRadius: '15px',
                                    fontFamily: 'Inter-Regular',
                                    marginRight: '10px',
                                    marginBottom: '10px',
                                    width: 'auto'
                                } } key={ row.invitation_email }>{ row.invitation_email }</Typography>);
                            }
                        })
                    }
                </DialogContentText>
                <Button className='btn btn-action-filter-dark' style={ {
                    width: '100%',
                    marginTop: '20px'
                } } onClick={ props.onSend }>Send Invitation Code</Button>
            </DialogContent>
        </Dialog>
    );
}