import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
	Box,
	Table,
	TableBody,
	TableContainer,
} from '@mui/material';

// hooks
import useTable from 'components/Table/useTable';
import { TableEmptyRows, TableHeadCustom } from 'components/Table';
import { ImportUserRow } from 'sections/@dashboard/notification/import';

import ModalInvitation from 'components/Modal/ModalInvitation';
import ModalError from './ModalError';

import service from 'service';
import { AlertComponent } from 'components/Alert';
import { useAlert } from 'components/Alert/useAlert';

const TABLE_HEAD = [
	{
		id: 'jobName',
		label: 'JOB NAME',
		alignRight: false
	},
	{
		id: 'jobStatus',
		label: 'JOB STATUS',
		alignRight: false
	},
	{
		id: 'jobStarted',
		label: 'JOB STARTED',
		alignRight: false
	},
	{
		id: 'jobFinished',
		label: 'JOB FINISHED',
		alignRight: false
	},
	{ id: 'act' },
];

export default function TabImportUser(props: { jobs: any[]; }) {
	// Alert
	const [alertType, setAlertType] = useState('');
	const [alertMsg, setAlertMsg] = useState('');
	const { isShownAlert, toggleAlert } = useAlert();

	// Error
	const [isShowError, setShowError] = useState(false);
	const [errors, setErrors] = useState([]);

	// Invitations
	const [isShowInvitation, setShowInvitation] = useState(false);
	const [invitations, setInvitations] = useState([]);

	const {
		dense,
		page,
		rowsPerPage,
		onSort,
	} = useTable();

	const denseHeight = dense ? 52 : 72;

	const onInvitation = async (row) => {
		const resp = await service.getJob({
			jobId: row.id,
		});
		const message = resp.data.job_message.invitations;

		setInvitations(message);
		setShowInvitation(true);
	};

	const onError = async (row) => {
		const resp = await service.getJob({
			jobId: row.id,
		});
		const message = resp.data.job_message;

		if (!message) {
			setAlertType('error');
			setAlertMsg('Import Internal Error!');
			toggleAlert();
			return;
		}

		setErrors(message.warning);
		setShowError(true);
	};

	const onSendInvitation = async () => {
		await service.sendInvitations(invitations);

		setAlertType('success');
		setAlertMsg('Send Invitation Code Success!');
		toggleAlert();

		setShowInvitation(false);
	};

	useEffect(() => {
		if (!isShowInvitation) {
			setInvitations([]);
		}
	}, [isShowInvitation]);

	return (
		<>
			<TableContainer sx={ {
				minWidth: 800,
				position: 'relative'
			} }>

				<Table size={ dense ? 'small' : 'medium' }>
					<TableHeadCustom
						headLabel={ TABLE_HEAD }
						rowCount={ props.jobs.length }
						onSort={ onSort }
					/>

					<TableBody>
						{
							props.jobs.map((row, index) => {
								return (
									<ImportUserRow
										key={ index }
										row={ row }
										onInvitation={ () => onInvitation(row) }
										onError={ () => onError(row) }
									/>
								);
							})
						}

						<TableEmptyRows height={ denseHeight } emptyRows={ props.jobs.length } />

					</TableBody>
				</Table>
			</TableContainer>

			<ModalInvitation
				invitations={ invitations }
				isOpen={ isShowInvitation }
				onClose={ () => setShowInvitation(false) }
				onSend={ onSendInvitation }
			></ModalInvitation>

			<ModalError
				errors={ errors }
				isOpen={ isShowError }
				onClose={ () => setShowError(false) }
			></ModalError>

			<AlertComponent isShownAlert={ isShownAlert } hide={ toggleAlert } severity={ alertType } content={ alertMsg } />
		</>
	);
}
