import { Endpoints } from 'constant';
import { ILineValues } from 'interfaces/chart';
import { IDetail, ISummary } from 'interfaces/voe';
import React, { useEffect, useState } from 'react';
import api from 'service/api';
import { utils } from 'utils';
import { Content } from './AssessmentContent';

export function SatisfactionAnalytic(props: {
    orgCode: string,
    departCode: string,
    industryName: string,
    monthValue: number,
    yearValue: number,
    isMinimal?: boolean;
}) {
    const answerCount = 11;

    // Local State
    const [lineValuesRaw, setLineValuesRaw] = useState<ILineValues>({});
    const [lineValues, setLineValues] = useState<ILineValues>({});
    const [detailValues, setDetailValues] = useState<IDetail[]>([]);
    const [totalUser, setTotalUser] = useState(0);
    const [averageScore, setAverageScore] = useState(0);
    const [summary, setSummary] = useState<ISummary[]>([]);

    const isMinimal = props.isMinimal ?? false;

    const detailLabels: string[] = [];
    for (let index = 0; index < answerCount; index++) {
        detailLabels.push(`${index}`);
    }

    const initAnalytic = async () => {
        const resp = await api.get(`${Endpoints.voe}/satisfaction-analytic`, {
            params: {
                "organization_code": props.orgCode,
                "department_code": props.departCode,
                "industry_name": props.industryName,
                "month": props.monthValue,
                "year": props.yearValue,
            },
        });
        const respData = resp.data;
        const userCount = respData.pagination.count;
        if (userCount == 0) {
            setTotalUser(0);
            setLineValuesRaw({});
            setSummary([]);
        } else {
            const values = splitChartLineSatisfaction(respData.data);
            const summary = splitSummarySatisfaction(respData.data);

            let count = 0;
            for (const item of respData.data as any[]) {
                count += item.feedback_value;
            }

            const average = count / userCount;

            setSummary(summary);
            setAverageScore(average);
            setTotalUser(userCount);
            setLineValuesRaw({ "Satisfaction": values.users });
            setLineValues({ "Satisfaction": values.values });
        }
    };

    const initDetail = async () => {
        const resp = await api.get(`${Endpoints.voe}/satisfaction-analytic-detail`, {
            params: {
                "organization_code": props.orgCode,
                "department_code": props.departCode,
                "industry_name": props.industryName,
                "month": props.monthValue,
                "year": props.yearValue,
            },
        });
        const respData = resp.data;
        const count = respData.pagination.count;
        if (count == 0) {
            setDetailValues([]);
        }
        else {
            const values = splitChartDetailSatisfaction(respData.data, answerCount);
            const count = values.reduce((prev, cur) => prev + cur, 0);
            const average = values.reduce((prev, cur, curIndex) => prev + (cur * curIndex), 0) / count;

            setDetailValues([
                {
                    title: "Q: How likely are you to refer your company as a place to work. ",
                    values: values,
                    average: average,
                }
            ]);
        }
    };

    useEffect(() => {
        initAnalytic();
        initDetail();
    }, [props]);

    return <Content
        title='Work Satisfaction'
        detailStartIndex={ 0 }
        detailLabels={ detailLabels }
        summary={ summary }
        detailValues={ detailValues }
        averageScore={ averageScore }
        totalUser={ totalUser }
        values={ lineValues }
        valuesRaw={ lineValuesRaw }
        isMinimal={ isMinimal }
    ></Content>;
}

const splitChartLineSatisfaction = (data: any[]) => {
    const biggestWeek = Math.ceil(utils.lastDate() / 7);
    const temp = Array(biggestWeek).fill(0);
    const weekCount = Array(biggestWeek).fill(0);
    const tempUser = Array(biggestWeek).fill({});

    for (const item of data) {
        const week = Math.floor(new Date(item.created_date).getDate() / 7);

        weekCount[week]++;
        temp[week] += Number(item.feedback_value);
    }

    for (let index = 0; index < biggestWeek; index++) {
        if (temp[index] != 0) {
            temp[index] = (temp[index] / weekCount[index]).toFixed(2);
        }
    }

    return { values: temp, users: weekCount };
};

const splitChartDetailSatisfaction = (data: any[], answerCount: number) => {
    const biggestFeedback = answerCount;
    const temp = Array(biggestFeedback).fill(0);

    for (const item of data) {
        temp[item.feedback_value] = item.count_feedback_value;
    }

    return temp;
};

const splitSummarySatisfaction = (data: any[]) => {
    const summary: ISummary[] = [
        {
            title: "Dissatisfied",
            values: [0, data.length],
        }, {
            title: "Satisfied but Unenthusiastic",
            values: [0, data.length],
        }, {
            title: "Enthusiastic",
            values: [0, data.length],
        }
    ];

    for (const item of data) {
        const val = item.feedback_value;

        if (val <= 6) {
            summary[0].values[0]++;
        }
        else if (val <= 8) {
            summary[1].values[0]++;
        }
        else {
            summary[2].values[0]++;
        }
    }

    for (const item of summary) {
        item.values[1] -= item.values[0];
    }

    return summary;
};
