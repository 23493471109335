import api from "./api";

export const getListRefund = async (params: {
	limit: number;
	page: number;
	offset?: number;
	keyword?: string;
	sort?: string;
	order?: string;
	status?: string;
}) => {
	const resp = await api.get("/v1/refund", { params: params });
	return resp.data;
};

export const updateRefund = async (params: any) => {
	const resp = await api.put(`/v1/refund/${params.order_refund_code}`, params);
	return resp.data;
};
