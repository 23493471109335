import React from 'react';
import { Box, TableCell, TableRow, Typography } from '@mui/material';
import { Images } from 'constant';
import Button from '@mui/material/Button';

export default function UserTableRow(props: {
	row: any;
	selected?: any;
	onEdit: () => void;
	onDelete: () => void;
	onMember: () => void;
	onImport: () => void;
	onSelectRow?: any;
	openModal?: any;
}) {
	const { id, organization_code, organization_name, email, phone, office_address } = props.row;

	return (
		<TableRow key={ id } hover selected={ props.selected }>

			<TableCell component='th' scope='row' padding='normal'>
				<Typography variant='subtitle2' noWrap sx={ {
					fontFamily: 'Fonts.interMedium',
					width: '250px !important'
				} }>
					{ organization_code }
				</Typography>
			</TableCell>

			<TableCell component='th' scope='row' padding='normal'>
				<Typography variant='subtitle2' noWrap sx={ {
					fontFamily: 'Fonts.interMedium',
					width: '250px !important'
				} }>
					{ organization_name }
				</Typography>
			</TableCell>

			<TableCell align='left' sx={ { textTransform: 'capitalize' } }>
				<Typography variant='subtitle2' noWrap sx={ {
					fontFamily: 'Fonts.interRegular',
					width: '150px !important'
				} }>
					{ phone }
				</Typography>
			</TableCell>

			<TableCell align='left'>
				<Typography variant='subtitle2' noWrap sx={ {
					fontFamily: 'Fonts.interRegular',
					width: '200px !important'
				} }>
					{ email.toLowerCase() }
				</Typography>
			</TableCell>

			<TableCell align='left' sx={ { textTransform: 'capitalize' } }>
				<Typography variant='subtitle2' noWrap sx={ {
					fontFamily: 'Fonts.interRegular',
					width: '150px !important'
				} }>
					{ office_address }
				</Typography>
			</TableCell>

			<TableCell onClick={ props.onMember } align='left' sx={ { textTransform: 'capitalize' } }>
				<Typography className='text-brand-dark font-semibold cursor-pointer' variant='subtitle2' noWrap sx={ {
					fontFamily: 'Fonts.interRegular'
				} }
				>
					Manage Members
				</Typography>
			</TableCell>

			<TableCell align='left'>
				<Box sx={ { width: '150px' } }>
					<Button onClick={ props.onImport } sx={ { minWidth: 'auto !important' } }>
						<img src={ Images.btn_export } style={ { width: '30px' } } />
					</Button>
					<Button onClick={ props.onEdit } sx={ { minWidth: 'auto !important' } }>
						<img src={ Images.btn_edit } style={ { width: '30px' } } />
					</Button>

					<Button onClick={ props.onDelete } sx={ { minWidth: 'auto !important' } }>
						<img src={ Images.btn_delete } style={ { width: '30px' } } />
					</Button>
				</Box>
			</TableCell>

		</TableRow>
	);
}
