import React, { useRef, useState } from 'react';
import {
	TableRow, TableCell, Typography, Box, Menu, MenuItem, IconButton, ListItemText
} from '@mui/material';

import Iconify from 'screens/Layout/components/Iconify';


export default function UserTableRows(props: { row: any; onEdit: () => void; onDelete: () => void; }) {
	const { name, email, phone, location, status } = props.row;

	const ref = useRef(null);
	const [isOpen, setIsOpen] = useState(false);

	return (
		<TableRow hover >

			<TableCell align='left'>
				<Typography variant='subtitle2' noWrap sx={ {
					fontFamily: 'Fonts.interRegular',
					width: '150px !important'
				} }>
					{ name }
				</Typography>
			</TableCell>

			<TableCell align='left'>
				<Typography variant='subtitle2' noWrap sx={ {
					fontFamily: 'Fonts.interRegular',
					width: '150px !important'
				} }>
					{ email ?? '-' }
				</Typography>
			</TableCell>

			<TableCell align='left' sx={ { textTransform: 'capitalize' } }>
				<Typography variant='subtitle2' noWrap sx={ {
					fontFamily: 'Fonts.interRegular',
					width: '150px !important'
				} }>
					{ phone ?? '-' }
				</Typography>
			</TableCell>

			<TableCell align='left' sx={ { textTransform: 'capitalize' } }>
				<Typography variant='subtitle2' noWrap sx={ {
					fontFamily: 'Fonts.interRegular',
					width: '100px !important'
				} }>
					{ location ?? '-' }
				</Typography>
			</TableCell>

			<TableCell align='left' sx={ { textTransform: 'capitalize' } }>
				<Typography variant='subtitle2' noWrap sx={ {
					fontFamily: 'Fonts.interRegular',
					width: '170px !important'
				} }>
					{ status === 'active' ? <span style={ {
						textTransform: 'uppercase',
						background: '#E8F4FB',
						padding: '4px 12px',
						borderRadius: '10px',
						fontSize: '12px',
						color: '#2D9CDB',
						fontWeight: '600'
					} }>Active</span> :
						<span style={ {
							fontSize: '12px',
							fontWeight: '600',
							color: '#FF1F7D',
							textTransform: 'uppercase',
							background: '#FFE9F2',
							padding: '4px 12px',
							borderRadius: '10px'
						} }> { status === 'pending_verification' ? 'EMAIL SENT' : status === 'pending_invitation' ? 'EMAIL UNSENT' : status } </span> }
				</Typography>
			</TableCell>
			<TableCell align='left' sx={ { textTransform: 'capitalize' } }>
				<Box sx={ { width: '50px' } }>
					<IconButton ref={ ref } onClick={ () => setIsOpen(true) } className='user-action'>
						<Iconify icon='eva:more-horizontal-fill' width={ 20 } sx={ {} } height={ 20 } />
					</IconButton>
					<Menu
						open={ isOpen }
						anchorEl={ ref.current }
						onClose={ () => setIsOpen(false) }
						PaperProps={ {
							sx: {
								width: 200,
								maxWidth: '100%'
							},
						} }
						anchorOrigin={ {
							vertical: 'top',
							horizontal: 'right'
						} }
						transformOrigin={ {
							vertical: 'top',
							horizontal: 'right'
						} }
					>
						<MenuItem sx={ { color: 'text.secondary' } }>
							<ListItemText onClick={ () => {
								setIsOpen(false);
								props.onEdit();
							} } primary='Edit' primaryTypographyProps={ { variant: 'body2' } } />
						</MenuItem>
						<MenuItem sx={ { color: 'text.secondary' } }>
							<ListItemText onClick={ () => {
								setIsOpen(false);
								props.onDelete();
							} } primary='Delete' primaryTypographyProps={ { variant: 'body2' } } />
						</MenuItem>
					</Menu>
				</Box>
			</TableCell>

		</TableRow>
	);
}
