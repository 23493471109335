import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, Typography, CircularProgress, Button } from '@mui/material';
import { blue, green } from '@mui/material/colors';
import { PaperComponent } from 'components/Modal/Paper';
import { Config, Images } from 'constant';
import Iconify from 'screens/Layout/components/Iconify';
import { ModalCLoseIcon } from 'screens/Login/style';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDropzone } from 'react-dropzone';
import { refreshJobs } from 'store/jobs/actions';
import { getJob } from 'service/jobs';
import { toast } from 'react-toastify';

const JOB_FETCH_DURATION = 1000 * 5; // 5 Seconds

export default function ModalImport(props: {
    isShow: boolean;
    organization: any;
    onHide: () => void;
    onImport: (file: any) => Promise<any>;
}) {
    const [files, setFiles] = useState<any[]>([]);
    const [isLoadFile, setLoadFile] = useState(false);
    const [isImporting, setImporting] = useState(false);
    const [jobId, setJobId] = useState(-1);

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'application/vnd.ms-excel': [],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
        },
        onDrop: acceptedFiles => {
            setLoadFile(true);
            for (let index = 0; index <= 100; index++) {
                setTimeout(function timer() {
                    if (index === 100) {
                        setFiles(
                            acceptedFiles.map(file => Object.assign(file, {
                                preview: URL.createObjectURL(file)
                            }))
                        );
                        setLoadFile(false);
                    }
                }, index * 20);
            }
        }
    });

    useEffect(() => {
        checkJob();
    }, [jobId]);

    useEffect(() => {
        if (!props.isShow) {
            setFiles([]);
            setImporting(false);
        }
    }, [props.isShow]);

    const checkJob = async () => {
        if (jobId === -1) return;

        const resp = await getJob({
            jobId: jobId,
            compact: true,
        });
        const status = resp.data.job_status;

        if (status === 'FINISHED' || status === 'INVALID') {
            setImporting(false);

            toast.info('data already imported!');

            props.onHide();
        } else {
            setTimeout(checkJob, JOB_FETCH_DURATION);
        }
    };

    const onImport = async () => {
        setImporting(true);
        const resp = await props.onImport(files[0]);

        setJobId(resp.data.id);
        refreshJobs();
    };

    const onReset = () => {
        setJobId(-1);
    };

    const isButtonDisabled = isImporting || files.length == 0;

    return (
        <Dialog
            open={ props.isShow }
            onClose={ props.onHide }
            PaperComponent={ PaperComponent }
            aria-labelledby='draggable-dialog-title'
            onReset={ onReset }
        >
            <DialogTitle style={ {
                cursor: 'move',
                fontSize: '18px',
                fontFamily: 'Fonts.interMedium',
                padding: '16px 24px 0px 24px'
            } } id='draggable-dialog-title'>
                Import Member(s)
                <ModalCLoseIcon title='Close' onClick={ props.onHide }>
                    <IconCloseModal></IconCloseModal>
                </ModalCLoseIcon>
            </DialogTitle>
            <DialogContent>
                <div className='mt-4'>
                    <div className='my-2'>
                        <div className='text-xs text-neutral-700 mb-1'>Organization Name</div>
                        <div className='font-semibold text-sm'>{ props.organization.organization_name }</div>
                    </div>
                    <div className='my-2'>
                        <div className='text-xs text-neutral-700 mb-1'>Official Email</div>
                        <div className='font-semibold text-sm'>{ props.organization.email }</div>
                    </div>
                    <div className='flex'>
                        <div className='basis-1/2 mb-4 mt-2'>
                            <div className='text-xs text-neutral-700 mb-1'>Sales Rep</div>
                            <div className='font-semibold text-sm'>{ props.organization.sales_name ?? '-' }</div>
                        </div>
                        <div className='basis-1/2 mb-4 mt-2'>
                            <div className='text-xs text-neutral-700'>Subscription</div>
                            <div className='font-semibold text-sm'>Premium (Paid)</div>
                        </div>
                    </div>
                </div>
                <DialogContentText sx={ {
                    fontSize: '14px',
                    fontFamily: 'Fonts.interRegular',
                    color: '#767E8C',
                    marginBottom: '10px'
                } }>
                    <b>Important</b>: You can import maximum 500 rows per entry
                </DialogContentText>
                <Typography sx={ {
                    fontSize: '14px',
                    fontFamily: 'Inter-Medium',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    color: '#167CB8'
                } } onClick={ () => window.open(`${Config.urlaws}/assets/template_import_email_only.xlsx`) }>
                    Download template here
                </Typography>

                {
                    isImporting ? (
                        <div className='border border-dashed rounded-md py-12 mt-4'>
                            <div className='text-center'>
                                <CircularProgress className='mx-auto'
                                    size={ 42 }
                                    sx={ {
                                        color: blue[500],
                                        display: 'block'
                                    } }
                                />
                                <div className='text-sm font-semibold text-neutral-500 mt-8'>
                                    importing members
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            <section style={ {
                                width: '100%',
                                height: '231px',
                                backgroundColor: '#ffffff'
                            } }>
                                <div { ...getRootProps({ className: 'dropzone dropzone-cus' }) } style={ { backgroundColor: '#ffffff' } }>
                                    <input { ...getInputProps() } />
                                    <>
                                        <div style={ { display: 'flex' } }>
                                            <img src={ Images.import } style={ {
                                                width: '60px',
                                                marginBottom: '10px'
                                            } } />
                                        </div>
                                        <div style={ {
                                            marginBottom: '10px',
                                            marginTop: '20px',
                                            fontSize: '14px',
                                            color: '#292A2E',
                                            fontFamily: 'Fonts.interMedium !important'
                                        } }>
                                            <h3>Choose your <span className='font-bold'>excel</span> file or drop here</h3>
                                        </div>
                                        <div style={ { display: 'flex' } }>
                                            <p style={ {
                                                fontSize: '12px',
                                                lineHeight: '140%',
                                                fontFamily: 'Fonts.interRegular !important',
                                                color: '#767E8C'
                                            } }>
                                                Make sure your file contains these following fields: <br />
                                                email*.
                                            </p>

                                        </div>
                                    </>
                                </div>
                            </section>
                            <div style={ { marginTop: '20px', } }>
                                {
                                    files.length > 0 ?
                                        files.map(file => (
                                            <div key={ file.name } style={ {
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'space-between'
                                            } }>
                                                <div>
                                                    <Typography sx={ {
                                                        fontFamily: 'Fonts.interMedium !important',
                                                        fontSize: '14px'
                                                    } }>
                                                        { file.name }
                                                    </Typography>
                                                </div>
                                                <div>
                                                    <Typography sx={ {
                                                        fontFamily: 'Fonts.interMedium !important',
                                                        fontSize: '14px',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        display: 'flex'
                                                    } }>
                                                        { Math.round(file.size / 1000) } KB &nbsp; <Iconify sx={ { cursor: 'pointer' } } icon={ 'carbon:close-filled' } onClick={ e => setFiles([]) } />
                                                    </Typography>
                                                </div>
                                            </div>
                                        ))
                                        :
                                        <></>
                                }

                                {
                                    isLoadFile ?
                                        <div style={ {
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'space-between'
                                        } }>
                                            <div>
                                                <Typography sx={ {
                                                    fontFamily: 'Fonts.interMedium !important',
                                                    fontSize: '14px'
                                                } }>
                                                    File upload processing...
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography sx={ {
                                                    fontFamily: 'Fonts.interMedium !important',
                                                    fontSize: '14px'
                                                } }>
                                                    <CircularProgress
                                                        size={ 15 }
                                                        sx={ {
                                                            color: green[500],
                                                        } }
                                                    />
                                                </Typography>
                                            </div>
                                        </div>
                                        : ''
                                }
                            </div>
                        </>
                    )
                }

                <Button disabled={ isButtonDisabled } className={ `btn btn-action-filter-dark ${isButtonDisabled ? '!bg-slate-300 !cursor-not-allowed' : ''}` } style={ {
                    width: '100%',
                    marginTop: '20px'
                } } onClick={ onImport }>Submit</Button>
            </DialogContent>
        </Dialog>
    );
}

function IconCloseModal() {
    return (
        <FontAwesomeIcon icon={ faClose } />
    );
}
