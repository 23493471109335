import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, DialogActions, DialogContent, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { ModalResign, ModalSimple } from 'components/Modal/ModalSimple';
import { TableEmptyRows, TableHeadCustom } from 'components/Table';
import { Images } from 'constant';
import { Button as ButtonStyle } from 'screens/style';
import { getMembersCompact } from 'service/member';
import { toast } from 'react-toastify';

const TABLE_HEAD = [
    {
        id: '',
        type: 'checkbox',
        width: 50,
        minWidth: 20
    },
    {
        id: 'email',
        label: 'CORRESPONDENT EMAIL'
    },
    {
        id: 'membership',
        label: 'INVITATION CODE'
    },
    {
        id: 'status',
        label: 'STATUS'
    },
    { id: 'act' },
];
export default function ModalMember(props: {
    isShow: boolean;
    value: any;
    hide: () => void;
    onResigns: (val: any[]) => void;
    onSingleResign: (val: any) => void;
    onInvite: (val: any[]) => void;
}) {
    const [data, setData] = useState<{ items: any[]; }>({ items: [] });
    const [selected, setSelected] = useState<{ items: any[]; }>({ items: [] });
    const [isShowModalResign, setShowModalResign] = useState(false);

    useEffect(() => {
        if (props.isShow) {
            init();
        }
    }, [props.isShow]);

    const findSelected = (data: any[], member_code: string) => {
        return data.find(element => {
            return element.member_code === member_code;
        });
    };

    const onSelected = (data: any[], val: any) => {
        const memberCode = val.member_code;
        const exist = findSelected(data, memberCode);

        let newData = [...data];
        if (exist) {
            newData = newData.filter((val: any) => val.member_code !== memberCode);
        } else {
            newData = [
                ...newData,
                val
            ];
        }

        setSelected({
            items: newData
        });
    };

    const onSingleInvite = (val: any) => {
        props.onInvite([
            {
                invitation_email: val.invitation_email,
                invitation_code: val.invitation_code,
                organization_name: val.organization_name,
            }
        ]);
    };

    const onInvitations = () => {
        if (selected.items.length === 0) {
            return toast.warn('no selected items');
        }

        const temp: any[] = [];
        for (const val of selected.items) {
            temp.push({
                invitation_email: val.invitation_email,
                invitation_code: val.invitation_code,
                organization_name: val.organization_name,
            });
        }

        props.onInvite(temp);
        props.hide();
    };

    const onConfirmResign = () => {
        setShowModalResign(false);

        if (selected.items.length === 0) {
            return toast.warn('no selected items');
        }

        const temp: any[] = [];
        for (const val of selected.items) {
            temp.push({
                department_code: '',
                is_verified: false,
                member_code: val.member_code,
                organization_code: '',
                role_name: 'member',
                status: val.status,
            });
        }

        props.hide();
        props.onResigns(temp);
    };

    const onSingleResign = (val: any) => {
        props.onSingleResign({
            department_code: '',
            is_verified: false,
            member_code: val.member_code,
            organization_code: '',
            role_name: 'member',
            status: val.status,
        });
    };

    const onResigns = () => {
        setShowModalResign(true);
    };

    const init = () => {
        getMembersCompact({
            page: 1,
            limit: 500,
            organization_code: props.value.organization_code
        }).then((val: any) => {
            setData({
                items: val.data
            });
        });
    };

    const onReset = () => {
        setSelected({ items: [] });
        setData({ items: [] });
    };

    return (
        <>
            <ModalSimple
                hide={ props.hide }
                isShown={ props.isShow }
                title='Manage Members'
                reset={ onReset }
            >
                <>
                    <DialogContent>
                        <div className='flex justify-end my-8'>
                            <Button className='btn-action-filter-light' sx={ { width: '120px' } } endIcon={ <img className='pl-3' src={ Images.btn_resign } style={ { width: '20px' } } /> }
                                onClick={ onResigns }
                            >
                                Resign
                            </Button>
                            <Button
                                className='btn-action-filter-light' sx={ { width: '190px' } } endIcon={ <img className='pl-3' src={ Images.btn_send } style={ { width: '20px' } } /> }
                                onClick={ onInvitations }
                            >
                                Email Invitation
                            </Button>
                        </div>
                        <TableContainer sx={ {
                            minWidth: 540,
                            position: 'relative'
                        } }>

                            <Table size='small'>
                                <TableHeadCustom
                                    headLabel={ TABLE_HEAD }
                                    rowCount={ data.items.length }
                                    checkSelected={ selected.items }
                                    numSelected={ selected.items.length }
                                    onSelectAllRows={ () => {
                                        if (selected.items.length > 1) {
                                            setSelected({ items: [] });
                                        } else {
                                            setSelected({ items: data.items });
                                        }
                                    } }
                                />

                                <TableBody>
                                    {
                                        data.items.map((row, index) => {
                                            const exist = findSelected(selected.items, row.member_code);
                                            const labelIdx = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <UserRow
                                                    key={ index }
                                                    row={ row }
                                                    isSelected={ exist }
                                                    onSelected={ () => onSelected(selected.items, row) }
                                                    labelId={ labelIdx }
                                                    onResign={ () => onSingleResign(row) }
                                                    onInvite={ () => onSingleInvite(row) }
                                                />
                                            );
                                        })
                                    }
                                    <TableEmptyRows height={ 72 } emptyRows={ data.items.length } />
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                    <DialogActions sx={ { padding: '20px 24px !important' } }>
                        <Box sx={ {
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%'
                        } }>
                            <ButtonStyle type='submit' className='btn-primary' style={ { marginTop: '30px' } } >
                                Done
                            </ButtonStyle>
                        </Box>
                    </DialogActions>
                </>
            </ModalSimple>
            <ModalResign
                title='Are you sure want to resign all selected members?'
                onClose={ () => setShowModalResign(false) }
                isOpen={ isShowModalResign }
                onResign={ onConfirmResign }
            ></ModalResign>
        </>
    );
}

function UserRow(props: {
    row: any;
    isSelected: boolean;
    labelId: string;
    onResign: () => void;
    onInvite: () => void;
    onSelected: () => void;
}) {
    const row = props.row;

    return (
        <TableRow key={ row.id } hover selected={ props.isSelected }>

            <TableCell align='left'>
                <Checkbox
                    onClick={ props.onSelected }
                    checked={ props.isSelected }
                    inputProps={ { 'aria-labelledby': props.labelId } }
                    color='primary'
                />
            </TableCell>

            <TableCell scope='row' padding='normal'>
                <Typography variant='subtitle2' noWrap sx={ {
                    fontFamily: 'Fonts.interMedium'
                } }>
                    { row.invitation_email }
                </Typography>
            </TableCell>

            <TableCell scope='row' padding='normal' className='w-24'>
                <Typography variant='subtitle2' noWrap sx={ {
                    fontFamily: 'Fonts.interMedium',
                } }>
                    { row.invitation_code }
                </Typography>
            </TableCell>

            <TableCell align='left' sx={ { textTransform: 'capitalize' } }>
                <Typography variant='subtitle2' noWrap sx={ {
                    fontFamily: 'Fonts.interRegular',
                    width: '100px !important'
                } }>
                    { row.status === 'active' ? <span style={ {
                        textTransform: 'uppercase',
                        background: '#E8F4FB',
                        padding: '4px 12px',
                        borderRadius: '10px',
                        fontSize: '12px',
                        color: '#2D9CDB',
                        fontWeight: '600'
                    } }>Active</span> :
                        <span style={ {
                            fontSize: '12px',
                            fontWeight: '600',
                            color: '#FF1F7D',
                            textTransform: 'uppercase',
                            background: '#FFE9F2',
                            padding: '4px 12px',
                            borderRadius: '10px'
                        } }> { row.status === 'pending_verification' ? 'EMAIL SENT' : row.status === 'pending_invitation' ? 'EMAIL UNSENT' : row.status } </span> }
                </Typography>
            </TableCell>

            <TableCell align='left' className='w-24'>
                <Box style={ { display: 'flex' } }>
                    <Button onClick={ props.onResign } sx={ { minWidth: 'auto !important' } }>
                        <img src={ Images.btn_resign_act } style={ { width: '30px' } } />
                    </Button>

                    <Button onClick={ props.onInvite } sx={ { minWidth: 'auto !important' } }>
                        <img src={ Images.btn_send_act } style={ { width: '30px' } } />
                    </Button>
                </Box>
            </TableCell>

        </TableRow>
    );
}
