import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from "@mui/material";
import { TableEmptyRows, TableHeadCustom } from "components/Table";
import React, { useEffect, useState } from "react";
import UserRow from "sections/@dashboard/member/Specialist/Deleted";
import { getSpecialistList } from "service/specialist";

const TABLE_HEAD = [
  {
    id: "username",
    label: "USERNAME",
    alignRight: false,
  },
  {
    id: "email",
    label: "MAIN EMAIL",
    alignRight: false,
  },
  {
    id: "membership",
    label: "MEMBERSHIP",
  },
  {
    id: "specialist_title",
    label: "TITLE",
  },
  {
    id: "phone",
    label: "PHONE NUMBER",
    alignRight: false,
  },
  {
    id: "hourly_rate",
    label: "HOURLY PRICE",
    alignRight: false,
  },
  {
    id: "hourly_fee",
    label: "HOURLY FEE",
    alignRight: false,
  },
  {
    id: "bank_name",
    label: "BANK NAME",
    alignRight: false,
  },
  {
    id: "account_number",
    label: "ACCOUNT NUMBER",
    alignRight: false,
  },
  {
    id: "account_holder",
    label: "ACCOUNT HOLDER",
    alignRight: false,
  },
  {
    id: "biography",
    label: "BIOGRAPHY",
    alignRight: false,
  },
  {
    id: "universities",
    label: "UNIVERSITY",
    alignRight: false,
  },
  {
    id: "licenses",
    label: "LICENSE",
    alignRight: false,
  },
  {
    id: "credentials",
    label: "CREDENTIALS",
    alignRight: false,
  },
  {
    id: "experience",
    label: "EXPERIENCE",
  },
  {
    id: "expertises",
    label: "EXPERTISES",
  },
  {
    id: "approaches",
    label: "APPROACHES",
  },
  {
    id: "target_users",
    label: "TARGET CLIENT",
  },
  {
    id: "introduction_video_url",
    label: "VIDEO URL",
  },
  {
    id: "language",
    label: "LANGUAGE",
  },
  {
    id: "country",
    label: "COUNTRY",
  },
  {
    id: "gender",
    label: "GENDER",
  },
  {
    id: "status",
    label: "STATUS",
  },
  { id: "act" },
];

export default function TabDeleted(props: {
  search: string;
  membership: string;
  forceUpdate: number;
  onUnbanned: (val: any) => void;
  onCount: (val: number) => void;
}) {
  const [data, setData] = useState<any[]>([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);

  useEffect(() => {
    props.onCount(count);
  }, [count]);

  useEffect(() => {
    init();
  }, [props.search, props.membership, props.forceUpdate, page, limit]);

  const onChangeRowPerPage = (val) => {
    setPage(1);
    setLimit(val);
  };

  const onChangePage = (val) => {
    setPage(val + 1);
  };

  const init = async () => {
    const resp = await getSpecialistList({
      page: page,
      limit: limit,
      keyword: props.search,
      status: "inactive",
      membership: props.membership,
    });

    const pagination = resp.pagination;

    setCount(pagination.count);
    setLimit(pagination.limit);
    setPage(pagination.page);
    setData(resp.data ?? []);
  };

  return (
    <div>
      <TableContainer
        sx={{
          minWidth: 800,
          position: "relative",
        }}
      >
        <Table size="medium">
          <TableHeadCustom headLabel={TABLE_HEAD} rowCount={data.length} />

          <TableBody>
            {data.map((row, index) => {
              const found = false;
              const labelIdx = `test-${index}`;

              return (
                <UserRow
                  key={row.member_code}
                  isSelectedRow={found}
                  row={row}
                  labelId={labelIdx}
                  onUnbanned={() => props.onUnbanned(row)}
                />
              );
            })}

            <TableEmptyRows height={72} emptyRows={data.length} />
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ position: "relative" }}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          labelRowsPerPage="Items per page: "
          count={count || 0}
          rowsPerPage={limit || 10}
          page={page - 1 || 0}
          onPageChange={(_, pg) => onChangePage(pg)}
          onRowsPerPageChange={(e) => onChangeRowPerPage(e.target.value)}
        />
      </Box>
    </div>
  );
}
