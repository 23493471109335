import React from 'react';
import PropTypes from 'prop-types';
// @mui
import { TableRow, TableCell, Typography } from '@mui/material';

// ----------------------------------------------------------------------

TableEmptyRows.propTypes = {
	emptyRows: PropTypes.number,
	height: PropTypes.number,
};
export default function TableEmptyRows({ emptyRows, height }) {
	if (emptyRows > 0) {
		return null;
	}

	return (
		<TableRow
			sx={ {
				...(height && {
					height: height * emptyRows,
				}),
			} }
		>
			<TableCell colSpan={ 9 }>
				<Typography sx={ {
					fontSize: '14px !important',
					color: 'text.disabled',
					fontStyle: 'italic', textAlign: 'center'
				} }>
					Data is empty or not found </Typography>
			</TableCell>
		</TableRow>
	);
}
