import { Edit } from '@mui/icons-material';
import { Box, Checkbox, DialogActions, DialogContent, IconButton, ListItemText, ListSubheader, MenuItem, Select, TextField, Typography } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import { ModalSimple } from 'components/Modal/ModalSimple';
import { LabelModal } from 'components/Modal/style';
import { Colors, Endpoints, Fonts, Images } from 'constant';
import { IChangeCallback } from 'interfaces/callback';
import React, { useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { Button as ButtonStyle } from 'screens/style';
import { getEmotionList } from 'service/emotion';
import { getExpertiseList } from 'service/expertise';
import { utils } from 'utils';

export default function ContentModal(props: {
    isAddContent: boolean, isDefaultlanguage: boolean,
    isShow: boolean, listTopic: any[], listReviewer: any[],
    value: any, onSubmit: IChangeCallback<any, void>,
    hide: () => void;
}) {
    const [topic, setTopic] = useState('');
    const [reviewer, setReviewer] = useState('');
    const [title, setTitle] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [isEnableButton, setIsEnableButton] = useState(false);
    const [files, setFiles] = useState<any[]>([]);
    const [content, setContent] = useState('');
    const [rebuildEditor, setRebuildEditor] = useState(false);
    const [language, setLanguage] = useState('en');
    const [emotion, setEmotion] = useState<string[]>([]);
    const [expertises, setExpertises] = useState<string[]>([]);

    const [emotionList, setEmotionList] = useState<any[]>([]);
    const [expertiseList, setExpertiseList] = useState<any[]>([]);

    const onReset = () => {
        setContent("");
        setTopic("");
        setReviewer("");
        setTitle("");
        setImageUrl("");
        setLanguage("id");
        setFiles([]);
        setEmotion([]);
        setExpertises([]);
    };

    // Check if button is enable
    useEffect(() => {
        if (props.isDefaultlanguage) {
            if (topic && topic.length > 0 &&
                reviewer && reviewer.length > 0 &&
                content && content.length > 0 &&
                title && title.length > 0 &&
                (files.length > 0 || imageUrl.length > 0)
            ) {
                setIsEnableButton(true);
            } else {
                setIsEnableButton(false);
            }
        } else {
            if (content && content.length > 0 &&
                title && title.length > 0 &&
                language && language.length > 0
            ) {
                setIsEnableButton(true);
            } else {
                setIsEnableButton(false);
            }
        }
    }, [topic, reviewer, content, title, files]);

    useEffect(() => {
        if (props.isShow) {
            setRebuildEditor(!rebuildEditor);

            if (!props.isAddContent) {
                const emotion = (props.value.emotions ?? []).map(val => val.emotion_code);
                const expertise = (props.value.expertises ?? []).map(val => val.expertise_code);

                setLanguage(props.value.translation ?? "en");
                setContent(props.value.content ?? "");
                setTopic(props.value.topic_code ?? "");
                setReviewer(props.value.reviewer_code ?? "");
                setTitle(props.value.title ?? "");
                setImageUrl(props.value.bg_image_url ?? "");
                setFiles([]);
                setEmotion(emotion);
                setExpertises(expertise);
            }
        }
    }, [props.isShow]);

    useEffect(() => {
        getEmotionList()
            .then(val => {
                setEmotionList(val.data);
            });

        getExpertiseList()
            .then(val => {
                const items: any[] = [];
                for (const category of val.data) {
                    items.push({
                        name: utils.removeEmoji(category.category.name),
                        isHeader: true,
                    });
                    for (const item of category.expertises) {
                        items.push({
                            expertise_code: item.expertise_code,
                            name: item.name,
                            isHeader: false,
                        });
                    }
                }

                setExpertiseList(items);
            });
    }, []);

    const onChangeContent = (val: string) => {
        setContent(val);
    };

    const onChangeEmotion = (val: string | string[]) => {
        if (typeof val === 'string') {
            setEmotion(val.split(','));
        } else {
            if (val.length > 2) {
                toast.error('maximum 2 feelings');
            } else {
                setEmotion(val);
            }
        }
    };

    const onChangeExpertise = (val: string | string[]) => {
        if (typeof val === 'string') {
            setExpertises(val.split(','));
        } else {
            if (val.length > 2) {
                toast.error('maximum 2 expertises');
            } else {
                setExpertises(val);
            }
        }
    };

    // Dropzone
    const [startUF, setSUF] = useState(false);
    const [numberProgress, setNumberProgress] = useState(0);
    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': []
        },
        multiple: false,
        onDrop: acceptedFiles => {
            setSUF(true);
            for (let index = 0; index <= 100; index++) {
                setTimeout(function timer() {
                    setNumberProgress(index);
                    if (index === 100) {
                        const newFiles = acceptedFiles.map(file => {
                            return {
                                preview: URL.createObjectURL(file),
                                origin: file,
                            };
                        });
                        setFiles(newFiles);
                        setSUF(false);
                        setNumberProgress(0);
                    }
                }, index * 10);
            }
        }
    });

    const onSubmit = () => {
        props.onSubmit({
            topic: topic,
            reviewer: reviewer,
            content: content,
            title: title,
            files: files,
            imageUrl: imageUrl,
            reviewerBio: '',
            language: language,
            emotions: emotion,
            expertises,
        });
    };

    const memoEditor = useMemo(() => {
        const removeAttributes = (elem: HTMLElement) => {
            const length = elem.children.length;
            if (length == 0) return;

            for (let i = 0; i < length; i++) {
                const elemItem = elem.children.item(i) as HTMLElement;

                removeAttributes(elemItem);

                elemItem.removeAttribute('class');
                elemItem.removeAttribute('style');
                elemItem.removeAttribute('dir');
                elemItem.removeAttribute("face");
            }
        };

        return (
            <Editor
                onEditorChange={ (val) => onChangeContent(val) }
                initialValue={ content }
                init={ {
                    height: 320,
                    width: "100%",
                    menubar: false,
                    branding: false,
                    paste_postprocess: (editor, args) => {
                        removeAttributes(args.node);
                    },
                    placeholder: "Write your insight content here...",
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'paste',
                        'fullscreen', 'insertdatetime', 'media', 'help', 'wordcount', 'searchreplace', 'visualblocks'
                    ],
                    toolbar: 'casechange blocks | bold italic underline strikethrough | ' +
                        'alignleft aligncenter alignright alignjustify | ' +
                        'bullist numlist outdent indent',
                    content_style: 'html { font-family: Inter-Regular; font-size:16px }',
                } }
            ></Editor>
        );
    }, [rebuildEditor]);

    return (
        <ModalSimple isShown={ props.isShow } title={ props.isAddContent ? "Add New Article" : "Edit Article" } hide={ props.hide } reset={ onReset }>
            <>
                <DialogContent>
                    <Box sx={ { overFlowY: 'initial !important' } }>
                        <div className='modalBodyC'>
                            {
                                props.isDefaultlanguage ? (
                                    <>
                                        <LabelModal style={ {
                                            marginTop: '20px',
                                            display: 'inline-block'
                                        } }>Topic *</LabelModal>
                                        <div className='input-wrapper'>
                                            <Select
                                                required
                                                displayEmpty
                                                value={ topic }
                                                inputProps={ { 'aria-label': 'Without label' } }
                                                onChange={ (val) => setTopic(val.target.value!) }
                                                sx={ {
                                                    marginTop: '8px',
                                                    width: '100%',
                                                    border: 'solid 1px Colors.app.GhostGrey',
                                                    background: Colors.white.default,
                                                    borderRadius: '5px',
                                                    color: Colors.app.black,
                                                    fontWeight: Fonts.weights.w5,
                                                    fontFamily: 'Fonts.interMedium',
                                                    fontSize: Fonts.sizes.xs
                                                } }
                                            >
                                                <MenuItem value=''>
                                                    <em>Topic</em>
                                                </MenuItem>
                                                {
                                                    props.listTopic.map(topic => (
                                                        <MenuItem key={ topic.topic_code } value={ topic.topic_code }>
                                                            { topic.name }
                                                        </MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </div>
                                    </>
                                ) : null
                            }

                            {
                                !props.isDefaultlanguage ? (
                                    <>
                                        <LabelModal style={ {
                                            marginTop: '20px',
                                            display: 'inline-block'
                                        } }>Language</LabelModal>
                                        <div className='input-wrapper'>
                                            <Select
                                                disabled={ !props.isAddContent }
                                                required
                                                displayEmpty
                                                value={ language }
                                                inputProps={ { 'aria-label': 'Without label' } }
                                                onChange={ (val) => setLanguage(val.target.value!) }
                                                sx={ {
                                                    marginTop: '8px',
                                                    width: '100%',
                                                    border: 'solid 1px Colors.app.GhostGrey',
                                                    background: Colors.white.default,
                                                    borderRadius: '5px',
                                                    color: Colors.app.black,
                                                    fontWeight: Fonts.weights.w5,
                                                    fontFamily: 'Fonts.interMedium',
                                                    fontSize: Fonts.sizes.xs
                                                } }
                                            >
                                                <MenuItem value=''>
                                                    <em>Choose Language</em>
                                                </MenuItem>
                                                <MenuItem value='en'>
                                                    <em>English</em>
                                                </MenuItem>
                                                <MenuItem value='id'>
                                                    <em>Indonesia</em>
                                                </MenuItem>
                                            </Select>
                                        </div>
                                    </>
                                ) : null
                            }

                            <LabelModal style={ {
                                marginTop: '20px',
                                display: 'inline-block'
                            } }>Article Title</LabelModal>
                            <div className='input-wrapper'>
                                <TextField
                                    required
                                    fullWidth
                                    value={ title }
                                    placeholder="Title"
                                    onChange={ e => setTitle(e.target.value) }
                                    sx={ {
                                        marginTop: '8px',
                                        width: '100%',
                                        border: 'solid 1px Colors.app.GhostGrey',
                                        background: Colors.white.default,
                                        borderRadius: '5px',
                                        color: Colors.app.black,
                                        fontWeight: Fonts.weights.w5,
                                        fontFamily: 'Fonts.interMedium',
                                        fontSize: Fonts.sizes.xs
                                    } }
                                ></TextField>
                            </div>

                            <LabelModal style={ {
                                marginTop: '20px',
                                display: 'inline-block',
                                marginBottom: '8px',
                            } }>Article Content</LabelModal>
                            <div className='input-wrapper'>
                                {
                                    memoEditor
                                }
                            </div>

                            {
                                props.isDefaultlanguage ? (
                                    <>
                                        <LabelModal style={ {
                                            marginTop: '20px',
                                            display: 'inline-block',
                                        } }>Reviewer</LabelModal>
                                        <div className='input-wrapper'>
                                            <Select
                                                required
                                                displayEmpty
                                                value={ reviewer }
                                                inputProps={ { 'aria-label': 'Without label' } }
                                                onChange={ (val) => setReviewer(val.target.value!) }
                                                sx={ {
                                                    marginTop: '8px',
                                                    width: '100%',
                                                    border: 'solid 1px Colors.app.GhostGrey',
                                                    background: Colors.white.default,
                                                    borderRadius: '5px',
                                                    color: Colors.app.black,
                                                    fontWeight: Fonts.weights.w5,
                                                    fontFamily: 'Fonts.interMedium',
                                                    fontSize: Fonts.sizes.xs,
                                                    marginBottom: '8px',
                                                } }
                                            >
                                                <MenuItem value=''>
                                                    <em>Reviewer</em>
                                                </MenuItem>
                                                {
                                                    props.listReviewer.map(sugges => (
                                                        <MenuItem key={ sugges.member_code } value={ sugges.member_code }>
                                                            { sugges.username }
                                                        </MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </div>

                                        <div style={ { margin: '0 0 8px 0' } }>
                                            <div style={ {
                                                width: '100%',
                                                paddingTop: '0'
                                            } }>
                                                <div style={ { width: '100%' } }>
                                                    <LabelModal style={ {
                                                        margin: '0 0 10px 0',
                                                        display: 'inline-block'
                                                    } }>Feelings (Maximum 2)</LabelModal>
                                                    <div>
                                                        <Select
                                                            displayEmpty
                                                            multiple
                                                            inputProps={ { 'aria-label': 'Without label' } }
                                                            value={ emotion }
                                                            onChange={ val => onChangeEmotion(val.target.value) }
                                                            renderValue={ selected => {
                                                                if (selected.length === 0) {
                                                                    return (
                                                                        <em className='text-slate-400'>Feelings (Maximum 2)</em>
                                                                    );
                                                                }

                                                                const vals = selected.map(item => {
                                                                    const idx = emotionList.findIndex(val => {
                                                                        return val.emotion_code === item;
                                                                    });
                                                                    if (idx !== -1) {
                                                                        return emotionList[idx].title;
                                                                    } else {
                                                                        return '';
                                                                    }
                                                                });

                                                                return vals.join(', ');
                                                            } }
                                                            sx={ {
                                                                width: '100%',
                                                                border: 'solid 1px Colors.app.GhostGrey',
                                                                background: Colors.white.default,
                                                                borderRadius: '5px',
                                                                color: Colors.app.black,
                                                                fontWeight: Fonts.weights.w5,
                                                                fontFamily: 'Fonts.interMedium',
                                                                fontSize: Fonts.sizes.xs
                                                            } }>

                                                            {
                                                                emotionList.map((item, i) => {
                                                                    return (
                                                                        <MenuItem key={ `${i}-${item.emotion_code}` } value={ item.emotion_code }>
                                                                            <Checkbox color='default' checked={ emotion.indexOf(item.emotion_code) > -1 } />
                                                                            <ListItemText>{ item.title }</ListItemText>
                                                                        </MenuItem>
                                                                    );
                                                                })
                                                            }
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='input-wrapper' style={ { margin: '0 0 8px 0' } }>
                                            <div className='schedule-cus' style={ {
                                                width: '100%',
                                                paddingTop: '0',
                                                marginBottom: '8px'
                                            } }>
                                                <div className='time-schedule-cus' style={ { width: '100%' } }>
                                                    <LabelModal style={ {
                                                        margin: '5px 0 10px 0',
                                                        display: 'inline-block'
                                                    } }>Expertise (Maximum 2)</LabelModal>
                                                    <div className='input-wrapper form-inline'>
                                                        <Select
                                                            displayEmpty
                                                            multiple
                                                            inputProps={ { 'aria-label': 'Without label' } }
                                                            value={ expertises }
                                                            onChange={ val => onChangeExpertise(val.target.value) }
                                                            renderValue={ selected => {
                                                                if (selected.length === 0) {
                                                                    return (
                                                                        <em className='text-slate-400'>Expertise</em>
                                                                    );
                                                                }

                                                                const vals = selected.map(item => {
                                                                    const idx = expertiseList.findIndex(val => {
                                                                        return val.expertise_code === item;
                                                                    });
                                                                    if (idx !== -1) {
                                                                        return expertiseList[idx].name;
                                                                    } else {
                                                                        return '';
                                                                    }
                                                                });

                                                                return vals.join(', ');
                                                            } }
                                                            sx={ {
                                                                width: '100%',
                                                                border: 'solid 1px Colors.app.GhostGrey',
                                                                background: Colors.white.default,
                                                                borderRadius: '5px',
                                                                color: Colors.app.black,
                                                                fontWeight: Fonts.weights.w5,
                                                                fontFamily: 'Fonts.interMedium',
                                                                fontSize: Fonts.sizes.xs
                                                            } }
                                                        >

                                                            {
                                                                expertiseList.map(item => {
                                                                    if (item.isHeader) {
                                                                        return (
                                                                            <ListSubheader sx={ {
                                                                                fontSize: '16px',
                                                                                fontWeight: '600',
                                                                                marginLeft: '8px'
                                                                            } } key={ item.name }>{ item.name }</ListSubheader>
                                                                        );
                                                                    }

                                                                    return (
                                                                        <MenuItem key={ item.expertise_code } value={ item.expertise_code }>
                                                                            <Checkbox color='default' checked={ expertises.indexOf(item.expertise_code) > -1 } />
                                                                            <ListItemText primary={ item.name } />
                                                                        </MenuItem>
                                                                    );
                                                                })
                                                            }
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <LabelModal style={ { display: 'inline-block' } }>Upload Image</LabelModal>
                                        <div>
                                            {
                                                !startUF && (files.length > 0 || imageUrl.length > 0) ?
                                                    <aside style={ {
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        flexWrap: 'wrap',
                                                        marginTop: 16
                                                    } }>
                                                        <Thumbs
                                                            files={ files }
                                                            getInputProps={ getInputProps }
                                                            getRootProps={ getRootProps }
                                                            imageUrl={ imageUrl }
                                                        ></Thumbs>
                                                    </aside>
                                                    :
                                                    <section style={ {
                                                        width: '100%',
                                                        height: '231px'
                                                    } } className="cursor-pointer">
                                                        <div { ...getRootProps({ className: 'dropzone dropzone-cus' }) }>
                                                            <input { ...getInputProps() } />
                                                            {
                                                                startUF ?
                                                                    <>
                                                                        <div style={ { display: 'flex' } }>
                                                                            <Typography sx={ {
                                                                                color: '#292A2E',
                                                                                fontSize: '12px'
                                                                            } }>
                                                                                Uploading File { numberProgress }%...
                                                                            </Typography>
                                                                        </div>
                                                                        <div style={ {
                                                                            display: 'flex',
                                                                            width: '100px',
                                                                            backgroundColor: 'gray',
                                                                            borderRadius: '10px'
                                                                        } }>
                                                                            <div style={ {
                                                                                backgroundColor: 'black',
                                                                                height: '10px',
                                                                                borderRadius: '10px',
                                                                                width: numberProgress
                                                                            } } >&nbsp;&nbsp;</div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div style={ { display: 'flex' } }>
                                                                            <img src={ Images.addImagePost } style={ {
                                                                                width: '32px',
                                                                                marginBottom: '10px'
                                                                            } } />
                                                                        </div>
                                                                        <div style={ { display: 'flex' } }>
                                                                            <p>Upload an image or drag and drop</p>
                                                                        </div>
                                                                    </>
                                                            }
                                                        </div>
                                                    </section>
                                            }

                                        </div>
                                    </>
                                ) : null
                            }
                        </div>
                    </Box >
                </DialogContent>
                <DialogActions sx={ { padding: '20px 24px !important' } }>
                    <Box sx={ {
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%'
                    } }>
                        <ButtonStyle disabled={ !isEnableButton } onClick={ onSubmit } className='btn-primary' title='Add New Admin' style={ { marginTop: '30px' } } >
                            { props.isAddContent ? "Add Article" : "Edit Article" }
                        </ButtonStyle>
                    </Box>
                </DialogActions>
            </>
        </ModalSimple>
    );
}

function Thumbs(props: { files: any[], getRootProps: any, getInputProps: any, imageUrl: string; }) {
    if (props.imageUrl.length > 0 && props.files.length === 0) {
        const names = props.imageUrl.split('/');
        const name = names[names.length - 1];

        return <Thumb
            { ...props }
            name={ name }
            url={ Endpoints.baseUrlAws + props.imageUrl }
        />;
    }

    const file = props.files[0];

    return <Thumb
        { ...props }
        name={ file.origin.name }
        url={ file.preview }
    />;
}

function Thumb(props: { name: string, url: string, getRootProps: any, getInputProps: any; }) {
    return (
        <div style={ {
            display: 'inline-flex',
            borderRadius: 2,
            border: '1px solid #eaeaea',
            marginBottom: 8,
            marginRight: 8,
            width: '100%',
            height: 'auto',
            padding: 4,
            boxSizing: 'border-box'
        } }>
            <div style={ {
                display: 'flex',
                minWidth: 0,
                overflow: 'hidden'
            } }>
                <div { ...props.getRootProps({ className: 'dropzone' }) }>
                    <input { ...props.getInputProps() } />
                    <div className='postReview'>
                        <div style={ {
                            background: `url(${props.url}) #D3D3D3`,
                            backgroundSize: 'cover',
                            width: '440px',
                            height: '240px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        } } >
                            <IconButton
                                size='small'
                                aria-label='Edit'
                                color='inherit'
                                title='Edit Picture'
                                sx={ {
                                    background: '#ffffff',
                                    position: 'absolute',
                                    top: '10px',
                                    right: '10px'
                                } }
                            >
                                <Edit fontSize='small' />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
