export enum AdminActionTypes {
  GET_ADMIN_LIST_LOADING = 'GET_ADMIN_LIST_LOADING',
  GET_ADMIN_LIST_SUCCESS = 'GET_ADMIN_LIST_SUCCESS',
  GET_ADMIN_LIST_ERROR = 'GET_ADMIN_LIST_ERROR',

  DELETE_ADMIN_LOADING = 'DELETE_ADMIN_LOADING',
  DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS',
  DELETE_ADMIN_ERROR = 'DELETE_ADMIN_ERROR',

  ADD_ADMIN_LOADING = 'ADD_ADMIN_LOADING',
  ADD_ADMIN_SUCCESS = 'ADD_ADMIN_SUCCESS',
  ADD_ADMIN_ERROR = 'ADD_ADMIN_ERROR',
}