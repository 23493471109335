import {
	Box,
	Table,
	TableBody,
	TableContainer,
	TablePagination
} from '@mui/material';
import { TableEmptyRows, TableHeadCustom } from 'components/Table';
import useTable from 'components/Table/useTable';
import React, { useEffect, useState } from 'react';
import AvailabilityRow from 'sections/@dashboard/specialist/availability';
import { fetchScheduleAvailability } from 'service/schedule';
import { utils } from 'utils';

const TABLE_HEAD = [
	{
		id: 'username',
		label: 'USERNAME',
		alignRight: false
	},
	{
		id: 'titla',
		label: 'TITLE',
		alignRight: false
	},
	{
		id: 'time_zone',
		label: 'TIME ZONE',
	},
	{
		id: 'date_range',
		label: 'DATE RANGE',
	},
	{
		id: 'notice_periode',
		label: 'NOTICE PERIOD',
		alignRight: false
	},
	{
		id: 'google_integration',
		label: 'GOOGLE INTEGRATION',
		alignRight: false
	},
	{
		id: 'schedule',
		label: 'SCHEDULE',
		alignRight: false
	},
	{ id: 'act' },
];
export default function TabDeleted(props: {
	search: string;
	membership: string;
	forceUpdate: number;
	onCount: (val: number) => void;
}) {
	const [data, setData] = useState<any[]>([]);
	const [limit, setLimit] = useState(10);
	const [page, setPage] = useState(1);
	const [count, setCount] = useState(0);

	const { onSort } = useTable();
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('username');

	useEffect(() => {
		props.onCount(count);
	}, [count]);

	useEffect(() => {
		init();
	}, [props.search, props.membership, props.forceUpdate, page, limit]);

	const onChangeRowPerPage = (val) => {
		setPage(1);
		setLimit(val);
	};

	const onChangePage = (val) => {
		setPage(val + 1);
	};

	const init = async () => {
		const resp = await fetchScheduleAvailability({
			page: page,
			limit: limit,
			keyword: props.search,
			status: 'inactive',
			membership: props.membership,
		});

		const pagination = resp.pagination;

		setCount(pagination.count);
		setLimit(pagination.limit);
		setPage(pagination.page);
		setData(resp.data ?? []);
	};

	const onRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const dataFiltered = utils.applySortFilter({
		data: data,
		comparator: utils.getComparator(order, orderBy),
	});

	return (
		<div>
			<TableContainer sx={ {
				minWidth: 800,
				position: 'relative'
			} }>

				<Table size='medium'>
					<TableHeadCustom
						headLabel={ TABLE_HEAD }
						rowCount={ data.length }
						orderBy={ orderBy }
						onRequestSort={ onRequestSort }
						onSort={ onSort }
						order={ order }
					/>

					<TableBody>
						{
							dataFiltered.map((row, index) => {
								const found = false;
								const labelIdx = `test-${index}`;

								return (
									<AvailabilityRow
										key={ row.member_code }
										isSelectedRow={ found }
										row={ row }
										labelId={ labelIdx }
										withoutAction={ true }
									/>
								);
							})
						}

						<TableEmptyRows height={ 72 } emptyRows={ data.length } />

					</TableBody>
				</Table>
			</TableContainer>
			<Box sx={ { position: 'relative' } }>
				<TablePagination
					rowsPerPageOptions={ [10, 25, 100] }
					component='div'
					labelRowsPerPage='Items per page: '
					count={ count || 0 }
					rowsPerPage={ limit || 10 }
					page={ page - 1 || 0 }
					onPageChange={ (_, pg) => onChangePage(pg) }
					onRowsPerPageChange={ e => onChangeRowPerPage(e.target.value) }
				/>
			</Box>
		</div>
	);
}
