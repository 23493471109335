import React from 'react';
import { Checkbox, TableCell, TableRow, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { Images } from 'constant';
import { IVoidCallback } from 'interfaces/callback';

export default function SpecialistRateRow(props: {
    row: any;
    selected: boolean;
    isSelected: boolean;
    onSelected: IVoidCallback;
    onInvoice: IVoidCallback;
}) {
    const id = props.row.id;

    return (
        <TableRow key={ id } hover selected={ props.selected }>

            <TableCell align='left'>
                <Checkbox
                    onClick={ props.onSelected }
                    checked={ props.isSelected }
                    inputProps={ { 'aria-labelledby': id } }
                    color='primary'
                />
            </TableCell>

            <TableCell component='th' sx={ {
                textTransform: 'capitalize',
                fontFamily: 'Fonts.interRegular'
            } }>
                <Typography variant='subtitle2' noWrap sx={ {
                    minWidth: '120px',
                } }>
                    INV98234
                </Typography>
            </TableCell>

            <TableCell component='th' sx={ {
                textTransform: 'capitalize',
                fontFamily: 'Fonts.interRegular'
            } }>
                <Typography variant='subtitle2' noWrap sx={ {
                    minWidth: '120px',
                } }>
                    PT Maxi
                </Typography>
            </TableCell>

            <TableCell component='th' sx={ {
                textTransform: 'capitalize',
                fontFamily: 'Fonts.interRegular'
            } }>
                <Typography variant='subtitle2' noWrap sx={ {
                    minWidth: '120px',
                } }>
                    30 October 2022
                </Typography>
            </TableCell>

            <TableCell component='th' sx={ {
                textTransform: 'capitalize',
                fontFamily: 'Fonts.interRegular'
            } }>
                <Typography variant='subtitle2' noWrap sx={ {
                    minWidth: '120px',
                } }>
                    5
                </Typography>
            </TableCell>

            <TableCell component='th' sx={ {
                textTransform: 'capitalize',
                fontFamily: 'Fonts.interRegular'
            } }>
                <Typography variant='subtitle2' noWrap sx={ {
                    minWidth: '120px',
                } }>
                    Rp500.000
                </Typography>
            </TableCell>

            <TableCell align='left' sx={ { textTransform: 'capitalize' } }>
                <Typography variant='subtitle2' noWrap sx={ {
                    fontFamily: 'Fonts.interRegular',
                    width: '70px !important'
                } }>
                    <span style={ {
                        textTransform: 'uppercase',
                        background: '#E8F4FB',
                        padding: '4px 12px',
                        borderRadius: '10px',
                        fontSize: '12px',
                        color: '#2D9CDB',
                        fontWeight: '600'
                    } }>PAID</span>
                </Typography>
            </TableCell>


            <TableCell align='left' sx={ { minWidth: '200px' } }>
                <Button sx={ { minWidth: 'auto !important' } }>
                    <img src={ Images.btn_send_act } style={ { width: '30px' } } />
                </Button>

                <Button sx={ { minWidth: 'auto !important' } }>
                    <img src={ Images.btn_import_act } style={ { width: '30px' } } />
                </Button>

                <Button
                    variant="contained" color="success"
                    sx={ { paddingLeft: '36px', paddingRight: '36px' } }
                    disableElevation
                    onClick={ props.onInvoice }
                >
                    PAID
                </Button>
            </TableCell>
        </TableRow>
    );
}
