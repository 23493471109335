import React, { useEffect, useState } from 'react';
import { Card, FormControl, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';
import { Colors } from 'constant';
import { getMonths } from 'constant/Date';
import service from 'service';

export default function AnonymousAnalytic(props: {
	orgCode: string, departCode: string, industryName: string,
	monthValue: number, yearValue: number;
}) {
	const labels = [
		"Dissatisfied (1.0 - 6.0)",
		"Satisfied but Unenthusiastic (7.0 - 8.0)",
		"Enthusiastic (9.0 - 10.0)"
	];

	// Local State
	const [values, setValues] = useState<any[][]>([
		[],
		[],
		[]
	]);

	const onInit = async () => {
		const resp = await service.getSatisfactionFeedbackList({
			departCode: props.departCode,
			industry: props.industryName,
			month: props.monthValue,
			orgCode: props.orgCode,
			year: props.yearValue,
		});
		const data = resp.data as any[];
		const temp: any[][] = [
			[],
			[],
			[]
		];

		for (const item of data) {
			const val = item.feedback_value;
			if (val <= 6) {
				temp[0].push(item);
			} else if (val <= 8) {
				temp[1].push(item);
			} else {
				temp[2].push(item);
			}
		}

		setValues(temp);
	};

	useEffect(() => {
		onInit();
	}, [props]);

	return (
		<>
			<Card className='mb-8'>
				<div className='bg-slate-100 py-4 pl-4 flex justify-between'>
					<div className='font-bold text-xl pt-2'>Company NPS</div>
				</div>
				<div className='px-7 pb-6'>
					{
						labels.map((val, i) => {
							return (
								<Content { ...props } items={ values[i] ?? [] } label={ val } color={ Colors.chart.getColor(i) } key={ `container-${i}` }></Content>
							);
						})
					}
				</div>
			</Card>
		</>
	);
}

function Content(props: { color: string, items: any[], label: string; }) {
	const [hide, setHide] = useState(true);
	const items = props.items;
	const count = items.length;
	const isSLice = hide && count > 8;
	const arr = isSLice ? items.slice(0, 8) : items;

	return (
		<>
			{
				count > 0 ?
					<>
						<div className='mt-10'>
							<div className='flex mb-7'>
								<div className='w-4 h-4 rounded-full mt-1 mr-3' style={ { backgroundColor: props.color } }></div>
								<div className='text-xl'>{ props.label }</div>
							</div>
							<div className='flex flex-wrap gap-5'>
								{
									arr.map((item, i) => {
										return (
											<div key={ `content-${i}` }>
												<ContentItem date={ item.created_date } message={ item.feedback_description }></ContentItem>
											</div>
										);
									})
								}
							</div>
							{
								count > 8 ?
									<div onClick={ () => setHide(!hide) } className='text-center cursor-pointer text-blue-500 my-10'>{ hide ? "See More" : "See Less" }</div>
									:
									<></>
							}
						</div>
					</>
					: <></>
			}</>
	);
}

function ContentItem(props: { message: string, date: string; }) {
	const message = props.message;
	const isExceeded = message.length > 92;
	const [isReadMore, setReadMore] = useState(false);

	return (
		<div className='w-64 bg-slate-50 p-5 text-sm rounded'>
			{
				props.message.length == 0 ?
					<div className='mb-5 text-slate-400 text-center'>~ No Message ~</div>
					:
					<div className='mb-5'>
						<span>
							{
								isExceeded ?
									(
										isReadMore ?
											message
											:
											message.substring(0, 92) + " ..."
									)
									:
									message
							}
						</span>
						{
							isExceeded ?
								<span className='font-semibold text-slate-500 pl-6 cursor-pointer'
									onClick={ () => setReadMore(!isReadMore) }
								>{ isReadMore ? "Read Less" : "Read More" }</span>
								:
								<></>
						}
					</div>
			}
			<div className='opacity-50'>{ props.date }</div>
		</div >
	);
}
