import { Endpoints } from "constant";
import api from "./api";

export const getAnonymousList = async (data: {
	orgCode: string;
	departCode: string;
	industry: string;
	month: number;
	moodValue: string;
	limit: number;
	year: number;
}) => {
	const resp = await api.get(`${Endpoints.voe}/anon-feedbacks`, {
		params: {
			organization_code: data.orgCode,
			department_code: data.departCode,
			industry_name: data.industry,
			month: data.month,
			year: data.year,
			mood_value: data.moodValue,
			keyword: "",
			page: 1,
			limit: data.limit,
			sort: "desc",
			order: "",
		},
	});

	return resp.data;
};

export const getSatisfactionFeedbackList = async (params: {
	orgCode: string;
	departCode: string;
	industry: string;
	month: number;
	year: number;
}) => {
	const resp = await api.get(
		`${Endpoints.voe}/satisfaction-analytic-feedback`,
		{
			params: {
				organization_code: params.orgCode,
				department_code: params.departCode,
				industry_name: params.industry,
				month: params.month,
				year: params.year,
			},
		}
	);

	return resp.data;
};

export const getAppFeedbackList = async (params: {
	orgCode: string;
	departCode: string;
	industry: string;
	month: number;
	year: number;
}) => {
	const resp = await api.get(`${Endpoints.voe}/anon-feedback-app`, {
		params: {
			organization_code: params.orgCode,
			department_code: params.departCode,
			industry_name: params.industry,
			month: params.month,
			year: params.year,
		},
	});

	return resp.data;
};

export const getActiveScreen = async (params: {
	orgCode: string;
	departCode: string;
	industry: string;
	month: number;
	year: number;
}) => {
	const resp = await api.get(`${Endpoints.voe}/active-screen`, {
		params: {
			organization_code: params.orgCode,
			department_code: params.departCode,
			industry_name: params.industry,
			month: params.month,
			year: params.year,
		},
	});
	return resp.data;
};

export const getActiveUser = async (params: {
	orgCode: string;
	departCode: string;
	industry: string;
	month: number;
	year: number;
}) => {
	const resp = await api.get(`${Endpoints.voe}/active-user-monthly`, {
		params: {
			organization_code: params.orgCode,
			department_code: params.departCode,
			industry_name: params.industry,
			month: params.month,
			year: params.year,
		},
	});
	return resp.data;
};

export const getActiveUserList = async (params: {
	orgCode: string;
	departCode: string;
	industry: string;
	month: number;
	year: number;
}) => {
	const resp = await api.get(`${Endpoints.voe}/active-user`, {
		params: {
			organization_code: params.orgCode,
			department_code: params.departCode,
			industry_name: params.industry,
			month: params.month,
			year: params.year,
		},
	});
	return resp.data;
};
