import React, { useRef } from 'react';
import { ModalDefault } from 'components/Modal';
import { Button } from '@mui/material';
import pdf from 'service/pdf';

export default function ModalInvoice(props: {
    onHide: () => void;
    isShow: boolean;
}) {
    const refContent = useRef(null);

    const onDownloadInvoice = async () => {
        const content = refContent.current!;

        await pdf(content, "invoice");
    };

    const ModalContent = () => {
        return (
            <div className='text-left px-6 pb-16 text-sm' ref={ refContent }>
                <div className='flex justify-between mt-8'>
                    <div className='text-4xl font-bold'>INVOICE</div>
                    <div className='flex flex-col gap-2 text-right font-semibold text-neutral-500'>
                        <div className='text-black mb-2'>YOUR COMPANY</div>
                        <div>1234 your street</div>
                        <div>City, Jakarta</div>
                        <div>90102</div>
                        <div>Indonesia</div>
                        <div>0890-8978-8989</div>
                    </div>
                </div>
                <div className='flex justify-between mt-12'>
                    <div>
                        <div className='text-blue-700/90 font-semibold mb-2'>Invoice Number</div>
                        <div>#987834</div>
                    </div>
                    <div>
                        <div className='text-blue-700/90 font-semibold mb-2'>Amount</div>
                        <div>Rp 213.343,00</div>
                    </div>
                    <div>
                        <div className='text-blue-700/90 font-semibold mb-2'>Date Issued</div>
                        <div>26/03/2022</div>
                    </div>
                    <div>
                        <div className='text-blue-700/90 font-semibold mb-2'>Due Date</div>
                        <div>01/04/2022</div>
                    </div>
                </div>
                <div className='w-full h-1 bg-blue-700/70 rounded mt-12'></div>
                <table style={ { minWidth: '500px', marginTop: '24px' } }>
                    <tr>
                        <th className='text-blue-700/90 font-semibold mb-2 w-56 pb-2'>Description</th>
                        <th className='text-blue-700/90 font-semibold mb-2 w-28'>Rate</th>
                        <th className='text-blue-700/90 font-semibold mb-2 w-10'>Qty</th>
                        <th className='text-blue-700/90 font-semibold mb-2'>Amount</th>
                    </tr>
                    <tr>
                        <td className='py-1 leading-6'>Organization Bussiness Plan testing</td>
                        <td className='font-semibold'>Rp 250.000</td>
                        <td>1</td>
                        <td>Rp 1.250.000</td>
                    </tr>
                    <tr>
                        <td className='py-1 leading-6'>Organization Bussiness Plan testing</td>
                        <td className='font-semibold'>Rp 250.000</td>
                        <td>1</td>
                        <td>Rp 1.250.000</td>
                    </tr>
                </table>
                <div className='mt-6 flex'>
                    <div className='flex-grow'></div>
                    <div className='flex flex-col gap-2 text-right'>
                        <div className='flex justify-between'>
                            <div className='w-24 mr-4'>Subtotal</div>
                            <div>Rp 1.250.000,000000,000</div>
                        </div>
                        <div className='flex justify-between'>
                            <div className='w-24'>Discount</div>
                            <div>Rp 100.000</div>
                        </div>
                        <div className='flex justify-between'>
                            <div className='w-24'>Tax</div>
                            <div>Rp 150.000</div>
                        </div>
                        <div className='w-full h-1 bg-blue-700/70 rounded my-1'></div>
                        <div className='flex justify-between'>
                            <div className='w-24'>Total</div>
                            <div className='font-semibold'>Rp 1.500.000</div>
                        </div>
                    </div>
                </div>
                <div className='mt-8'>
                    <div className='text-blue-700/90 font-semibold mb-2'>Notes</div>
                    <div>Thank you for business!</div>
                </div>
                <div className='mt-6'>
                    <div className='text-blue-700/90 font-semibold mb-2'>Terms</div>
                    <div>Please pay within 7 days using link ink your invoice email.</div>
                </div>
            </div >
        );
    };

    return (
        <ModalDefault
            width='640px'
            hide={ props.onHide }
            isShown={ props.isShow }
            title=''
            modalContent={ <ModalContent></ModalContent> }
            modalFooter={
                <Button
                    className='btn-action-filter-dark-w-250'
                    fullWidth
                    onClick={ onDownloadInvoice }
                >
                    Download Invoice
                </Button>
            }
        ></ModalDefault>
    );
}
