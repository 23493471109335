import React from "react";
import { Stack, TableCell, TableRow, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { Images } from "constant";
import dayjs from "dayjs";
import { IVoidCallback } from "interfaces/callback";
import numeral from "numeral";

const confirmed = "confirmed";
const rescheduled = "rescheduled";
const requestReschedule = "request_reschedule";
const waitingPayment = "waiting_payment";
const done = "done";

const cancelled = "cancelled";
const cancelledBook = "cancelled_book";
const failed = "failed";
// const expired = "expired";
// const refund = "refund"

function statusIsConfirmed(status: string) {
  return [confirmed, rescheduled, requestReschedule].includes(status);
}

function statusIsCancelled(status: string) {
  return [cancelled, cancelledBook, failed].includes(status);
}

export default function SessionRow(props: {
  row: any;
  withoutAction?: boolean;
  onEdit?: IVoidCallback;
  onShare?: IVoidCallback;
  onPrecounseling?: IVoidCallback;
  onCounselingNote?: IVoidCallback;
  onOvertime?: IVoidCallback;
  onOvertimeLink?: IVoidCallback;
}) {
  const {
    reservation_code,
    date,
    time_from,
    psycholog_username,
    member_email,
    format_call,
    status,
    note_exist,
    precounseling_exist,
    overtime,
    promo_code,
  } = props.row;

  let duration = "";
  let price = "";
  if (overtime) {
    duration = `${overtime.duration} Minutes`;
    price = `Rp ${numeral(overtime.price).format("0,0")}`;
  }

  return (
    <TableRow key={reservation_code} hover>
      <TableCell
        component="th"
        sx={{
          textTransform: "capitalize",
          fontFamily: "Fonts.interRegular",
        }}
      >
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            minWidth: "120px",
          }}
        >
          {dayjs(date).format("MMMM D YYYY")}
        </Typography>
      </TableCell>

      <TableCell
        component="th"
        sx={{
          textTransform: "capitalize",
          fontFamily: "Fonts.interRegular",
        }}
      >
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            minWidth: "120px",
          }}
        >
          {time_from}
          {/* { dayjs(, { format: "HH:mm:ss" }).format('HH:mm A') } */}
        </Typography>
      </TableCell>

      <TableCell component="th" scope="row" padding="normal">
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography
            variant="subtitle2"
            sx={{
              flexWrap: "wrap",
              width: "250px",
              fontFamily: "Fonts.interMedium",
            }}
          >
            {psycholog_username}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell align="left">
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            minWidth: "120px",
          }}
        >
          {member_email}
        </Typography>
      </TableCell>

      <TableCell
        align="left"
        sx={{
          textTransform: "capitalize",
          fontFamily: "Fonts.interRegular",
        }}
      >
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            width: "200px",
            fontFamily: "Fonts.interMedium",
          }}
        >
          {format_call.toLowerCase() === "video_call"
            ? "Video Call 60 minutes"
            : format_call.toLowerCase() === "voice_call"
            ? "Voice Call 60 minutes"
            : "Preview 15 minutes"}
        </Typography>
      </TableCell>

      <TableCell align="left">
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            minWidth: "120px",
          }}
        >
          {promo_code !== "" ? promo_code : "-"}
        </Typography>
      </TableCell>

      <TableCell align="left">
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            minWidth: "120px",
          }}
          className={`${duration ? "" : "text-gray-500"}`}
        >
          {duration ? duration : "No Overtime"}
        </Typography>
      </TableCell>

      <TableCell align="left">
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            minWidth: "150px",
          }}
          className={`${price ? "" : "text-gray-500"}`}
        >
          {price ? price : "No Overtime"}
        </Typography>
      </TableCell>

      <TableCell align="left">
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            minWidth: "120px",
          }}
          className={`${price && duration ? "" : "text-gray-500"}`}
        >
          {price && duration ? (
            <div
              className={`${
                price && duration
                  ? "text-brand-dark cursor-pointer"
                  : "text-gray-500"
              }`}
              onClick={price && duration ? props.onOvertimeLink : undefined}
            >
              View Link
            </div>
          ) : (
            "No Overtime"
          )}
        </Typography>
      </TableCell>

      <TableCell align="left">
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            minWidth: "200px",
          }}
        >
          <div
            className={`${
              precounseling_exist
                ? "text-brand-dark cursor-pointer"
                : "text-gray-500"
            }`}
            onClick={precounseling_exist ? props.onPrecounseling : undefined}
          >
            View Form
          </div>
        </Typography>
      </TableCell>

      <TableCell align="left">
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            minWidth: "180px",
          }}
        >
          <div
            className={`${
              note_exist ? "text-brand-dark cursor-pointer" : "text-gray-500"
            }`}
            onClick={note_exist ? props.onCounselingNote : undefined}
          >
            View Notes
          </div>
        </Typography>
      </TableCell>

      <TableCell align="left">
        <Typography
          variant="body1"
          noWrap
          sx={{
            minWidth: "120px",
          }}
        >
          <div className="flex">
            {props.withoutAction ? (
              <div
                className={`
                    rounded-full px-4 bg-blue-50 text-blue-500
                    ${
                      statusIsCancelled(status)
                        ? "bg-pink-50 text-pink-500"
                        : "bg-gray-50 text-gray-500"
                    }
                `}
              >
                {statusIsCancelled(status)
                  ? "Payment Cancelled"
                  : "Payment Expired"}
              </div>
            ) : (
              <div
                className={`
                    rounded-full px-4 bg-blue-50 text-blue-500
                    ${
                      statusIsConfirmed(status)
                        ? "bg-green-50 text-green-700"
                        : ""
                    }
                    ${
                      status === waitingPayment
                        ? "bg-yellow-50 text-yellow-500"
                        : ""
                    }
                    ${status === done ? "bg-green-50 text-green-700" : ""}
                `}
              >
                {statusIsConfirmed(status)
                  ? "Payment Successful"
                  : status === done
                  ? "Done"
                  : status === waitingPayment
                  ? "Payment Pending"
                  : "Refund Requested"}
              </div>
            )}
          </div>
        </Typography>
      </TableCell>

      {!props.withoutAction ? (
        <TableCell align="left" sx={{ minWidth: "150px" }}>
          <Button
            onClick={props.onShare}
            sx={{ minWidth: "auto !important" }}
            className={`${
              statusIsConfirmed(status) ||
              status === done ||
              status === waitingPayment
                ? ""
                : "!hidden"
            }`}
          >
            <img src={Images.btn_share} style={{ width: "30px" }} />
          </Button>
          <Button onClick={props.onEdit} sx={{ minWidth: "auto !important" }}>
            <img src={Images.btn_edit} style={{ width: "30px" }} />
          </Button>
          <Button
            sx={{ minWidth: "auto !important" }}
            className={`${!statusIsConfirmed(status) ? "!hidden" : ""}`}
            onClick={props.onOvertime}
          >
            <img src={Images.btn_time} style={{ width: "30px" }} />
          </Button>
        </TableCell>
      ) : null}
    </TableRow>
  );
}
