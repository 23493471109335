import api from "./api";

export const getSpecialistTargetList = async (param: { isActive: boolean }) => {
	const resp = await api.get("/v1/specialists/targets", {
		params: {
			is_active: param.isActive,
		},
	});

	return resp.data;
};
