import { Auth } from 'interfaces';

import { AuthActionTypes } from './actionTypes';

const initialState: Auth.State = {
	list: [],
	error: '',
	loadingGet: false,
	loadingAdd: false,
	loadingUpdate: false,
	token: '',
};

const setLogin = (state: Auth.State, payload:Auth.AuthPayload) => ({
	...state,
	list: payload.listData as Auth.Detail[],
	token: payload.dataToken as Auth.Detail[],
	loadingAdd: false
});

const reqLogin = (state: Auth.State, type: AuthActionTypes) => ({
	...state,
	loadingGet: type === AuthActionTypes.LOGIN_LOADING,
	loadingUpdate: false
});

const rejectLogin = (state: Auth.State, payload:Auth.AuthPayload) => ({
	...state,
	loadingGet: false,
	loadingAdd: false,
	loadingUpdate: false,
	error: payload.error
});

const AuthReducer = (state:Auth.State = initialState, action: {type: AuthActionTypes; payload: Auth.AuthPayload;}) => {
	switch (action.type) {
		case AuthActionTypes.LOGIN_SUCCESS:
			return setLogin(state, action.payload);

		case AuthActionTypes.LOGIN_LOADING:
			return reqLogin(state, action.type);

		case AuthActionTypes.LOGIN_ERROR:
			return rejectLogin(state, action.payload);
		default:
			return state;
	}
};

export default AuthReducer;
