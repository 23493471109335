import styled from 'styled-components';
import { Fonts, Colors } from 'constant';
const LogoMaxiSmall = styled.img`
    width:70px;
    height:70px;
`;

const HeroLogin = styled.img`
    width:600px;
`;

const SubTitle = styled.h3`
    font-size:${Fonts.sizes.m2};
    font-weight:${Fonts.weights.w4};
    font-family:'${Fonts.interRegular}';
    margin-top:60px;
    color:${Colors.app.black};
`;
const Title = styled.h1`
    font-size:${Fonts.sizes.l2};
    font-weight:${Fonts.weights.w6};
    font-family:'${Fonts.interSemiBold}';
    margin-top:20px;
    color:${Colors.app.black};
    margin-bottom:40px;
`;
const BoxWrap = styled.div`
    display:flex;
    flex-direction:column;
    margin-bottom:20px;
`;
const Label = styled.label`
    color:${Colors.app.black};
    font-weight:${Fonts.weights.w5};
    font-family:'${Fonts.interMedium}';
    font-size:${Fonts.sizes.xs2};
`;
const Input = styled.input`
    margin-top:8px;    
    padding:15px 20px;
    width:100%;
    border: solid 1px ${Colors.app.GhostGrey};
    background: ${Colors.white.default};
    border-radius:5px;
    color:${Colors.app.black};
    font-weight:${Fonts.weights.w5};
    font-family:'${Fonts.interMedium}';
    font-size:${Fonts.sizes.xs};
`;

const ForgotPasswordLink = styled.a`
    color:${Colors.app.black};
    font-weight:${Fonts.weights.w5};
    font-family:'${Fonts.interMedium}';
    font-size:14px;
    display:flex;
    justify-content:end;
    margin-bottom:50px;
    margin-top:30px;
`;

const ModalCLoseIcon = styled.i`
    color:rgba(118, 126, 140, 1);
    font-size:21px;
    position:absolute;
    top:14px;
    right:23px;
    cursor:pointer;
`;

const SubTitleModal = styled.h3`
    font-size:${Fonts.sizes.s2};
    font-weight:${Fonts.weights.w4};
    font-family:'${Fonts.interRegular}';
    margin-bottom:40px;
    color:${Colors.app.SecondaryGrey};
    line-height: 150%;
    text-align: center;
`;
const TitleModal = styled.h1`
    font-size:${Fonts.sizes.m1};
    font-weight:${Fonts.weights.w5};
    font-family:'${Fonts.interMedium}';
    color:${Colors.app.black};
    margin-bottom:15px;
    text-align:center;
`;

const LabelModal = styled.label`
    color:${Colors.app.black};
    font-weight:${Fonts.weights.w5};
    font-family:'${Fonts.interMedium}';
    font-size:${Fonts.sizes.xs2};
`;
const InputModal = styled.input`
    margin-top:8px;    
    padding:15px 20px;
    width:100%;
    border: solid 1px ${Colors.app.GhostGrey};
    background: ${Colors.white.default};
    border-radius:5px;
    color:${Colors.app.black};
    font-weight:${Fonts.weights.w5};
    font-family:'${Fonts.interMedium}';
    font-size:${Fonts.sizes.xs};
    margin-bottom:30px;
`;

const mainStyle = {
	position: 'absolute' as const,
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
	background: '#FFFFFF',
	borderRadius: '5px',
};

export {
	mainStyle, SubTitleModal, TitleModal, LabelModal, InputModal, LogoMaxiSmall, HeroLogin, SubTitle, Title, BoxWrap, Label, Input, ForgotPasswordLink, ModalCLoseIcon
};
