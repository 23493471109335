import React from 'react';
import { Box, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { useState } from 'react';
import useTable from 'components/Table/useTable';
import { TableEmptyRows, TableHeadCustom } from 'components/Table';
import { UserTableRow } from 'sections/@dashboard/insight/text';

const TABLE_HEAD = [
	{
		id: 'title',
		label: 'ARTICLE TITLE',
		alignRight: false
	}, {
		id: 'content',
		label: 'ARTICLE CONTENT',
		alignRight: false
	},
	{
		id: 'picture',
		label: 'ARTICLE PICTURE',
		alignRight: false
	},
	{
		id: 'topic_name',
		label: 'TOPIC',
		alignRight: false
	},
	{
		id: 'reviewed_by',
		label: 'REVIEWED BY',
		alignRight: false
	},
	{
		id: 'emotions',
		label: 'FEELING',
		alignRight: false
	},
	{
		id: 'expetises',
		label: 'EXPERTISES',
		alignRight: false
	},
	{
		id: 'num_points',
		label: 'NUMBER OF POINTS'
	},
	{
		id: 'num_hearts',
		label: 'HEARTS'
	},
	{
		id: 'preview',
		label: 'ARTICLE PREVIEW'
	},
	{ id: '' }
];

export default function DeletedTab(props: {
	onPreview: (val: any) => void,
	data: any[], pagination: any[],
	onChangePage?: any, onChangeRows?: any;
}) {
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('title');
	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';

		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};
	const [isShowPreview, setIsPreview] = useState(false);
	const { dense, selected, onSelectRow, onSort } = useTable();

	const togglePreview = () => {
		setIsPreview(!isShowPreview);
	};

	const dataFiltered = applySortFilter({
		list: props.data,
		comparator: getComparator(order, orderBy),
	});

	const denseHeight = dense ? 52 : 72;

	return (
		<div>
			<TableContainer sx={ {
				minWidth: 800,
				position: 'relative'
			} }>

				<Table size={ dense ? 'small' : 'medium' }>
					<TableHeadCustom
						order={ order }
						orderBy={ orderBy }
						onRequestSort={ handleRequestSort }
						headLabel={ TABLE_HEAD }
						rowCount={ props.data.length }
						numSelected={ selected.length }
						onSort={ onSort }
					/>

					<TableBody>
						{
							dataFiltered.map((row, index) => (
								<UserTableRow
									key={ index }
									row={ row }
									selected={ selected.includes(row) }
									onSelected={ () => onSelectRow(row) }
									onPreview={ () => props.onPreview(row) }
									withoutAction={ true }
								/>
							))
						}

						<TableEmptyRows height={ denseHeight } emptyRows={ dataFiltered.length } />

					</TableBody>
				</Table>
			</TableContainer>

			<Box sx={ { position: 'relative' } }>
				<TablePagination
					rowsPerPageOptions={ [10, 25, 100] }
					component='div'
					labelRowsPerPage='Items per page: '
					count={ props.pagination[0]?.count || 0 }
					rowsPerPage={ props.pagination[0]?.limit || 10 }
					page={ props.pagination[0]?.page - 1 || 0 }
					onPageChange={ (e, page) => props!.onChangePage(page) }
					onRowsPerPageChange={ props!.onChangeRows }
				/>

			</Box>
		</div>
	);
}

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}
function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter({ list, comparator }) {

	const stabilizedThis = list.map((el, index) => [el, index]);

	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});

	list = stabilizedThis.map(el => el[0]);

	return list;
}
