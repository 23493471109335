import React from "react";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { Endpoints, Images } from "constant";

// USERName
// Title
// TIME ZONE
// DATE RANGE
// NOTICE PERIOD
// Google Integration
// SCHEDULE
export default function AvailabilityRow(props: {
  row: any;
  isSelectedRow: boolean;
  labelId: any;
  withoutAction: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
}) {
  const {
    psycholog_code,
    psycholog_username,
    psycholog_profile_url,
    psycholog_title,
    timezone,
    date_range,
    notice_period,
    calendar_sync_status,
    schedule_day,
  } = props.row;

  const schedules: string[] = [];
  for (const item of schedule_day ?? []) {
    schedules.push(item.day);
  }

  return (
    <TableRow
      hover
      key={psycholog_code}
      aria-checked={props.isSelectedRow}
      role="checkbox"
      tabIndex={-1}
      selected={props.isSelectedRow}
    >
      <TableCell align="left">
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "200px !important",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ marginRight: "20px" }}>
            {props.row.profile_url === "" ? (
              <img
                alt="maxi-cms"
                src={String(Images.avatar)}
                style={{ width: " 36px ", height: "36px" }}
              />
            ) : (
              <div
                style={{
                  width: "36px",
                  height: "36px",
                  borderRadius: "100%",
                  backgroundSize: "cover",
                  backgroundImage: `url(${String(
                    Endpoints.baseUrlAws + psycholog_profile_url
                  )})`,
                }}
              />
            )}
          </div>
          <div className="truncate">{psycholog_username}</div>
        </Typography>
      </TableCell>

      <TableCell align="left">
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "150px !important",
          }}
        >
          {psycholog_title}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "160px !important",
          }}
        >
          {timezone}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "150px !important",
          }}
        >
          {date_range} Days
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "150px !important",
          }}
        >
          {notice_period} Hours
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "150px !important",
          }}
        >
          {calendar_sync_status === "not_linked" ? "Not Integrated" : "Linked"}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "100px !important",
          }}
        >
          {schedules.join(", ")}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Box sx={{ width: "80px", display: "flex" }}>
          {!props.withoutAction ? (
            <>
              <IconButton className="bg-neutral-200" onClick={props.onEdit}>
                <img src={Images.btn_edit_act} alt="" />
              </IconButton>

              {/* <IconButton className='bg-neutral-200' onClick={ props.onDelete }>
                                    <img src={ Images.btn_delete_account } alt="" />
                                </IconButton> */}
            </>
          ) : null}
        </Box>
      </TableCell>
    </TableRow>
  );
}
