export enum FlagsActionTypes {
  GET_FLAGS_LIST_LOADING = 'GET_FLAGS_LIST_LOADING',
  GET_FLAGS_LIST_SUCCESS = 'GET_FLAGS_LIST_SUCCESS',
  GET_FLAGS_LIST_ERROR = 'GET_FLAGS_LIST_ERROR',

  GET_FLAGS_PREVIEW_LOADING = 'GET_FLAGS_PREVIEW_LOADING',
  GET_FLAGS_PREVIEW_SUCCESS = 'GET_FLAGS_PREVIEW_SUCCESS',
  GET_FLAGS_PREVIEW_ERROR = 'GET_FLAGS_PREVIEW_ERROR',

  UPDATE_FLAGS_LOADING = 'UPDATE_FLAGS_LOADING',
  UPDATE_FLAGS_SUCCESS = 'UPDATE_FLAGS_SUCCESS',
  UPDATE_FLAGS_ERROR = 'UPDATE_FLAGS_ERROR',
  
}