import React, { useEffect } from 'react';
import service from 'service';
import { useAppSelector } from 'store';
import { refreshJobs } from 'store/jobs/actions';

export const JOB_STATUS_QUEUE = "QUEUE";
export const JOB_STATUS_PROCESS = "PROCESS";
export const JOB_STATUS_INVALID = "INVALID";
const JOB_FETCH_DURATION = 1000 * 5; // 5 Seconds
const JOB_MAX_TIME_RECOVER = 1000 * 60 * 60 * 1; // 1 Hour

export default function Worker() {
    const { jobs } = useAppSelector(state => state.jobs);

    const worker = async () => {
        let isFinish = true;

        for (const item of jobs) {
            if (item.job_status === JOB_STATUS_QUEUE || item.job_status === JOB_STATUS_PROCESS) {
                isFinish = false;

                const resp = await service.getJob({
                    jobId: item.id,
                    compact: true,
                });
                if (resp.data.job_status != item.job_status) {
                    return refreshJobs();
                }
            }

            if (item.job_status === JOB_STATUS_QUEUE && needRecover(new Date(item.created_date))) {
                await service.updateJobStatus({
                    id: item.id,
                    job_status: JOB_STATUS_INVALID,
                });
                return refreshJobs();
            }
        }

        if (!isFinish) {
            setTimeout(worker, JOB_FETCH_DURATION);
        }
    };

    useEffect(() => {
        if (jobs.length === 0) {
            return;
        }

        worker();
    }, [jobs]);

    return <></>;
}

function needRecover(date: Date) {
    let curDate = new Date();
    let difDate = curDate.getTime() - date.getTime();

    return difDate > JOB_MAX_TIME_RECOVER;
}
