import { Flags } from 'interfaces';

import { FlagsActionTypes } from './actionTypes';

const initialState: Flags.State = {
	list: [],
	pagination: [],
	error: '',
	loadingGet: false,
	loadingAdd: false,
	loadingDelete: false,
	loadingUpdate: false
};

const setFlags = (state: Flags.State, payload:Flags.FlagsPayload) => ({
	...state,
	list: payload.listData as Flags.Detail[],
	pagination: payload.pagination as Flags.Pagination[],
	loadingGet: false
});

const addFlags = (state: Flags.State, payload:Flags.FlagsPayload) => ({
	...state,
	list: state.list.concat(payload.data as Flags.Detail),
	loadingAdd: false
});

const reqFlags = (state: Flags.State, type: FlagsActionTypes) => ({
	...state,
	loadingGet: type === FlagsActionTypes.GET_FLAGS_LIST_LOADING,
	loadingUpdate: false
});

const rejectFlags = (state: Flags.State) => ({
	...state,
	loadingGet: false,
	loadingAdd: false,
	loadingDelete: false,
	loadingUpdate: false,
});

const flagsReducer = (state:Flags.State = initialState, action: {type: FlagsActionTypes; payload: Flags.FlagsPayload;}) => {
	switch (action.type) {
		case FlagsActionTypes.GET_FLAGS_LIST_SUCCESS:
			return setFlags(state, action.payload);
			
		case  FlagsActionTypes.GET_FLAGS_PREVIEW_SUCCESS:
			return setFlags(state, action.payload);

		case FlagsActionTypes.GET_FLAGS_LIST_LOADING, FlagsActionTypes.GET_FLAGS_PREVIEW_LOADING:
			return reqFlags(state, action.type);

		case FlagsActionTypes.GET_FLAGS_LIST_ERROR, FlagsActionTypes.GET_FLAGS_PREVIEW_ERROR:
			return rejectFlags(state);
		default:
			return state;
	}
};

export default flagsReducer;
