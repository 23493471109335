import api from "./api";

export const getListBannedWord = async (params: {
	limit: number;
	page: number;
	offset?: number;
	keyword?: string;
	sort?: string;
	order?: string;
	status?: string;
}) => {
	params.order = params.sort ?? "banned_words.name";
	params.sort = params.sort ?? "asc";

	const resp = await api.get("/v1/bannedwords", { params: params });
	return resp.data;
};

export const addBannedWord = async (params: { banned_words: string[] }) => {
	const resp = await api.post("/v1/bannedwords", params);
	return resp.data;
};

export const deleteBannedWord = async (params: {
	banned_word_code: string;
}) => {
	const resp = await api.delete(`/v1/bannedwords/${params.banned_word_code}`);
	return resp.data;
};

export const repostBannedWord = async (params: {
	banned_word_code: string;
}) => {
	const resp = await api.put(
		`/v1/bannedwords/repost/${params.banned_word_code}`
	);
	return resp.data;
};
