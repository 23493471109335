import React from 'react';
import { Images } from 'constant';
import { useNavigate } from 'react-router-dom';
import { Paths } from 'constant';
import {
	Container, SingleColumn, Button
} from '../style';
import {
	LogoMaxiSmall,
	SubTitle,
	Title,
} from './style';

const Success = () => {
	const navigate = useNavigate();
	
	const routeChange = () => {
		const path = Paths.login;
		navigate(path);
	};
	
	return (
		<Container bg={ Images.bgContainer } >
			<SingleColumn>
				<LogoMaxiSmall alt='maxi-cms' src={ String(Images.logoMaxiRoundSmall) } />
				
				<Title margin='70px auto 15px auto'>Your Password Successfully Change</Title>
				<SubTitle textAlign='center'>
					Lörem ipsum sun trelig mitorade faning. Lödade plaredat.
					Antegisk rengen. Son semining eftersom vimijasade rek.
				</SubTitle>
				
				<Button title='Back to Log In' onClick={ routeChange }>Back to Log In</Button>
				
			</SingleColumn>
		</Container>
	);
};
export default Success;