import React, { useEffect } from 'react';
import { LocalStorage } from 'helpers';
import { getAdminAccess } from 'service/admin';
import { storeAccess } from 'store/access/actions';

export default function Access() {
    const init = () => {
        getAdminAccess()
            .then((val) => {
                storeAccess({
                    access: val.data ?? []
                });
            });
    };

    useEffect(() => {
        if (LocalStorage.getToken() !== null) {
            init();
        }
    }, []);

    return (<></>);
}
