import {
  Box,
  Container,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { Images } from "constant";
import { LocalStorage } from "helpers";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import Iconify from "screens/Layout/components/Iconify";
import { SearchStyle } from "./Panel";

export default function Appbar(props: {
  onSearch?: (val: string) => void;
  title: string;
}) {
  return (
    <div className="border-b border-solid border-neutral-200 mb-5">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={4}
      >
        <Box>
          <Typography variant="h4" sx={{ fontSize: "24px" }}>
            {props.title}
          </Typography>
        </Box>

        {props.onSearch ? (
          <SearchStyle
            onChange={(e) => props.onSearch!(e.target.value)}
            placeholder="Search"
            startAdornment={
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{
                    color: "text.disabled",
                    width: 25,
                    height: 25,
                  }}
                />
              </InputAdornment>
            }
          />
        ) : null}

        <Profile></Profile>
      </Stack>
    </div>
  );
}

function Profile() {
  const ref = useRef(null);

  const [isOpenMenu, setOpenMenu] = useState(false);

  const onLogout = () => {
    LocalStorage.clearToken();
    LocalStorage.clearName();
    LocalStorage.setLastRefreshDate("");
    location.href = "/";
  };

  return (
    <div>
      <div
        className="flex items-center cursor-pointer"
        ref={ref}
        onClick={() => setOpenMenu(true)}
      >
        <div className="mr-4">
          <img src={Images.avatar} alt="" />
        </div>
        <div className="">
          <h5 className="font-semibold">{LocalStorage.getName()}</h5>
          <p className="text-sm mt-1">Admin</p>
        </div>
        <div className="ml-2">
          <Iconify icon="bi:chevron-down" width={20} sx={{}} height={20} />
        </div>
      </div>
      <Menu
        open={isOpenMenu}
        anchorEl={ref.current}
        onClose={() => setOpenMenu(false)}
        PaperProps={{
          sx: {
            width: 200,
            maxWidth: "100%",
            top: "96px !important",
          },
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem component={Link} to="#" sx={{ color: "text.secondary" }}>
          <ListItemIcon>
            <Iconify
              icon="heroicons-outline:logout"
              width={24}
              sx={{}}
              height={24}
            />
          </ListItemIcon>
          <ListItemText
            primary="Logout"
            onClick={onLogout}
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
      </Menu>
    </div>
  );
}
