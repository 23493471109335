import React from 'react';

export function AcquisitionOrganization() {
    return (
        <Detail
            title='Organization'
            subtitle1='This number refers to the number of organisations that have been registered on the platform.'
            subtitle2='All organisations that are registered only after there is a signed agreement.'
        ></Detail>
    );
}

export function AcquisitionRegistered() {
    return (
        <Detail
            title='Registered Code'
            subtitle1='This number refers to the number of organisations that have been registered on the platform.'
            subtitle2='All organisations that are registered only after there is a signed agreement.'
        ></Detail>
    );
}

export function EngagementActiveCode() {
    return (
        <Detail
            title='Active Code'
            subtitle1='This number refers to the number of organisations that have been registered on the platform.'
            subtitle2='All organisations that are registered only after there is a signed agreement.'
        ></Detail>
    );
}

export function EngagementDailyUser() {
    return (
        <Detail
            title='Daily Active Users'
            subtitle1='This number refers to the number of organisations that have been registered on the platform.'
            subtitle2='All organisations that are registered only after there is a signed agreement.'
        ></Detail>
    );
}

export function EngagementScreenTime() {
    return (
        <Detail
            title='Total Screen Time'
            subtitle1='This number refers to the number of organisations that have been registered on the platform.'
            subtitle2='All organisations that are registered only after there is a signed agreement.'
        ></Detail>
    );
}

export function ContentInsight() {
    return (
        <Detail
            title='Insights'
            subtitle1='This number refers to the number of organisations that have been registered on the platform.'
            subtitle2='All organisations that are registered only after there is a signed agreement.'
        ></Detail>
    );
}

export function ContentInsightLike() {
    return (
        <Detail
            title='Insight Hearts'
            subtitle1='This number refers to the number of organisations that have been registered on the platform.'
            subtitle2='All organisations that are registered only after there is a signed agreement.'
        ></Detail>
    );
}

export function ContentPost() {
    return (
        <Detail
            title='Forum Post'
            subtitle1='This number refers to the number of organisations that have been registered on the platform.'
            subtitle2='All organisations that are registered only after there is a signed agreement.'
        ></Detail>
    );
}

export function ContentPostLike() {
    return (
        <Detail
            title='Forum Post Hearts'
            subtitle1='This number refers to the number of organisations that have been registered on the platform.'
            subtitle2='All organisations that are registered only after there is a signed agreement.'
        ></Detail>
    );
}

export function ContentPoll() {
    return (
        <Detail
            title='Forum Poll'
            subtitle1='This number refers to the number of organisations that have been registered on the platform.'
            subtitle2='All organisations that are registered only after there is a signed agreement.'
        ></Detail>
    );
}

export function ContentPollLike() {
    return (
        <Detail
            title='Forum Poll Hearts'
            subtitle1='This number refers to the number of organisations that have been registered on the platform.'
            subtitle2='All organisations that are registered only after there is a signed agreement.'
        ></Detail>
    );
}

export function ContentSpecialist() {
    return (
        <Detail
            title='Specialist'
            subtitle1='This number refers to the number of organisations that have been registered on the platform.'
            subtitle2='All organisations that are registered only after there is a signed agreement.'
        ></Detail>
    );
}

export function ContentSpecialistLike() {
    return (
        <Detail
            title='Specialist Hearts'
            subtitle1='This number refers to the number of organisations that have been registered on the platform.'
            subtitle2='All organisations that are registered only after there is a signed agreement.'
        ></Detail>
    );
}

export function ContentSpecialistAvailable() {
    return (
        <Detail
            title='Specialist Available Daily Hours'
            subtitle1='This number refers to the number of organisations that have been registered on the platform.'
            subtitle2='All organisations that are registered only after there is a signed agreement.'
        ></Detail>
    );
}

export function ContentSpecialistReservation() {
    return (
        <Detail
            title='Specialist Bookings'
            subtitle1='This number refers to the number of organisations that have been registered on the platform.'
            subtitle2='All organisations that are registered only after there is a signed agreement.'
        ></Detail>
    );
}

function Detail(props: { title: string, subtitle1: string, subtitle2: string; }) {
    return (
        <>
            <div className='font-semibold mb-4'>{ props.title }</div>
            <div className='font-light text-neutral-700'>
                <span className='block mb-4'>{ props.subtitle1 }</span>
                <span className='block'>{ props.subtitle2 }</span>
            </div>
        </>
    );
}
