import { Box, Chip, DialogActions, DialogContent, TextField } from '@mui/material';
import { ModalSimple } from 'components/Modal/ModalSimple';
import { Colors, Fonts } from 'constant';
import React, { useEffect, useState } from 'react';
import { Button as ButtonStyle } from 'screens/style';

export default function ModalModify(props: {
    isShow: boolean;
    value: any;
    onSubmit: (val: any) => void;
    onHide: () => void;
}) {
    const [words, setWords] = useState<{ items: string[]; }>({ items: [] });
    const [currentValue, setCurrentValue] = useState('');

    const [isEnableButton, setEnabledButton] = useState(false);

    useEffect(() => {
        onCheckValidity();
    }, [words]);

    const onCheckValidity = () => {
        setEnabledButton(words.items.length > 0);
    };

    const onReset = () => {
        setWords({ items: [] });
    };

    const onSubmit = () => {
        setEnabledButton(false);

        props.onSubmit({
            banned_words: words.items,
        });
    };

    const onDelete = (val: number) => {
        const temp = words.items;
        temp.splice(val, 1);
        setWords({
            items: temp
        });
    };
    const handleKeyUp = (e: any) => {
        if (e.keyCode === 32 && currentValue.length > 1) {
            setWords(oldState => {
                return {
                    items: [...oldState.items, e.target.value]
                };
            });
            setCurrentValue("");
        }
    };

    return (
        <ModalSimple
            isShown={ props.isShow }
            title={ "Add Words" }
            hide={ props.onHide }
            reset={ onReset }
        >
            <>
                <DialogContent>
                    <Box>
                        <Box sx={ { overFlowY: 'initial !important' } }>
                            <div className='modalBodyC'>
                                <h2>Banned Words</h2>
                                <TextField
                                    fullWidth
                                    placeholder='input banned words (use space to add the word)'
                                    value={ currentValue }
                                    onChange={ e => setCurrentValue(e.target.value.trim()) }
                                    onKeyDown={ handleKeyUp }
                                    sx={ {
                                        marginTop: '8px',
                                        width: '100%',
                                        border: 'solid 1px Colors.app.GhostGrey',
                                        background: Colors.white.default,
                                        borderRadius: '5px',
                                        color: Colors.app.black,
                                        fontWeight: Fonts.weights.w5,
                                        fontFamily: 'Fonts.interMedium',
                                        fontSize: Fonts.sizes.xs
                                    } }
                                ></TextField>
                                <div className='flex flex-wrap mt-4'>
                                    { words.items.map((item, index) => (
                                        <div key={ index } className='mx-1'>
                                            <Chip size='small' onDelete={ () => onDelete(index) } label={ item } />
                                        </div>
                                    )) }
                                </div>
                            </div>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions sx={ { padding: '20px 24px !important' } }>
                    <Box sx={ {
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%'
                    } }>
                        <ButtonStyle disabled={ !isEnableButton } onClick={ onSubmit } className='btn-primary' title='Add New Admin' style={ { marginTop: '30px' } } >
                            Add
                        </ButtonStyle>
                    </Box>
                </DialogActions>
            </>
        </ModalSimple>
    );
}
