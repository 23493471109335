import { Box, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import React, {
	useState
} from 'react';

// hooks
import useTable from 'components/Table/useTable';

import {
	TableEmptyRows, TableHeadCustom
} from 'components/Table';
import ExerciseRow from 'sections/@dashboard/exercise';
import { IChangeCallback } from 'interfaces/callback';

const TABLE_HEAD = [
	{
		id: 'title',
		label: 'EXERCISE TITLE',
		alignRight: false
	},
	{
		id: 'subtitle',
		label: 'EXERCISE SUBTITLE',
		alignRight: false
	},
	{
		id: 'description',
		label: 'EXERCISE DESCRIPTION',
		alignRight: false
	},
	{
		id: 'picture',
		label: 'EXERCISE PICTURE',
		alignRight: false
	},
	{
		id: 'translatin',
		label: 'TRANSLATION',
		alignRight: false
	},
	{
		id: 'narrations',
		label: 'AUDIO',
		alignRight: false
	},
	{
		id: 'reviewed_by',
		label: 'REVIEWED BY',
		alignRight: false
	},
	{
		id: 'preview',
		label: 'PREVIEW',
		alignRight: false
	},
	{ id: '' }
];

export default function ActivedTab(props: {
	data: any[],
	pagination: any[],
	onChangePage?: IChangeCallback<any, void>,
	onChangeRows?: IChangeCallback<any, void>,
	onDelete?: IChangeCallback<any, void>,
	onEdit?: IChangeCallback<any, void>;
	onPreview: IChangeCallback<any, void>;
	onTranslation: (row: any, val: string) => void;
	onAddTranslation: (row: any, val: string) => void;
	onNarration: (row: any) => void;
	onAddNarration: (row: any, val: string) => void;
}) {
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('title');
	const handleRequestSort = (_, property) => {
		const isAsc = orderBy === property && order === 'asc';

		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const { dense, selected, onSelectRow, onSort } = useTable();

	const dataFiltered = applySortFilter({
		list: props!.data,
		comparator: getComparator(order, orderBy),
	});

	const denseHeight = dense ? 52 : 72;

	return (
		<div>
			<TableContainer sx={ {
				minWidth: 800,
				position: 'relative'
			} }>

				<Table size={ dense ? 'small' : 'medium' }>
					<TableHeadCustom
						order={ order }
						orderBy={ orderBy }
						onRequestSort={ handleRequestSort }
						headLabel={ TABLE_HEAD }
						rowCount={ props.data.length }
						numSelected={ selected.length }
						onSort={ onSort }
					/>

					<TableBody>
						{
							dataFiltered.map((row, index) => (
								<ExerciseRow
									key={ index }
									row={ row }
									onDelete={ () => props!.onDelete!(row) }
									onEdit={ () => props!.onEdit!(row) }
									onPreview={ () => props.onPreview(row) }
									onNarration={ val => props.onNarration(val) }
									onAddNarration={ (val) => props.onAddNarration(row, val) }
									onTranslation={ (val) => props.onTranslation(row, val) }
									onAddTranslation={ (val) => props.onAddTranslation(row, val) } />
							))
						}

						<TableEmptyRows height={ denseHeight } emptyRows={ dataFiltered.length } />

					</TableBody>
				</Table>
			</TableContainer>

			<Box sx={ { position: 'relative' } }>
				<TablePagination
					rowsPerPageOptions={ [1, 10, 25, 100] }
					component='div'
					labelRowsPerPage='Items per page: '
					count={ props.pagination[0]?.count || 0 }
					rowsPerPage={ props.pagination[0]?.limit || 10 }
					page={ props.pagination[0]?.page - 1 || 0 }
					onPageChange={ (e, page) => props!.onChangePage!(page) }
					onRowsPerPageChange={ (val) => props!.onChangeRows!(val.target.value) }
				/>
			</Box>
		</div>
	);
}

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}
function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter({ list, comparator }) {

	const stabilizedThis = list.map((el, index) => [el, index]);

	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});

	list = stabilizedThis.map(el => el[0]);

	return list;
}
