import { compose, applyMiddleware, createStore, combineReducers } from "redux";
import { routerMiddleware, connectRouter } from "connected-react-router";
import thunk from "redux-thunk";

import forumPostReducer from "./forum/posts/reducers";
import topicsReducer from "./topics/reducers";
import membersReducer from "./members/reducers";
import voeUaReducer from "./voe/usage_analytics/reducers";
import adminReducer from "./admin/reducers";
import OrgsReducer from "./org/reducers";
import FlagsReducer from "./forum/flag/reducers";
import authReducer from "./auth/reducers";
import jobsReducer from "./jobs/reducers";
import translationReducer from "./translation/reducers";
import accessReducers from "./access/reducers";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { createBrowserHistory } from "history";

const history = createBrowserHistory({
	/* pass a configuration object here if needed */
});

const routeMiddleware = routerMiddleware(history);

const rootReducer = combineReducers({
	router: connectRouter(history),
	posts: forumPostReducer,
	orgs: OrgsReducer,
	flags: FlagsReducer,
	topics: topicsReducer,
	members: membersReducer,
	voeua: voeUaReducer,
	admin: adminReducer,
	auth: authReducer,
	jobs: jobsReducer,
	translation: translationReducer,
	access: accessReducers,
});

const store = createStore(
	rootReducer,
	compose(applyMiddleware(thunk, routeMiddleware))
);
export type RootState = ReturnType<typeof store.getState>;

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const dispatch = store.dispatch;

export { history, store, useAppSelector, dispatch };
