import { useTheme } from '@emotion/react';
import { Box, OutlinedInput, OutlinedInputProps, styled, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

export interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role='tabpanel'
            hidden={ value !== index }
            id={ `simple-tabpanel-${index}` }
            aria-labelledby={ `simple-tab-${index}` }
            { ...other }
        >
            { value === index && (
                <Box sx={ { adding: '24px 0' } }>
                    <Typography>{ children }</Typography>
                </Box>
            ) }
        </div>
    );
};

export const TabPanelHidden = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role='tabpanel'
            hidden={ value !== index }
            id={ `simple-tabpanel-${index}` }
            aria-labelledby={ `simple-tab-${index}` }
            { ...other }
        >
            <Box sx={ { adding: '24px 0' } } hidden={ value !== index }>
                <Typography>{ children }</Typography>
            </Box>
        </div>
    );
};

export const a11yProps = (index: number) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

const SearchStyleOrigin = styled(OutlinedInput)(({ theme }) => ({
    width: 350,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': { width: 400 },
    '& fieldset': {
        borderWidth: '1px !important',
        borderColor: `${theme.palette.grey[500_32]} !important`,
    },
}));

export interface SearchStyleProp extends OutlinedInputProps {
    searchDelay?: number,
}

export const SearchStyle = (props: SearchStyleProp) => {
    const [value, setValue] = useState<React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | null>(null);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (value) {
                props.onChange!(value!);
            }
        }, props.searchDelay ?? 600);

        return () => clearTimeout(delayDebounceFn);
    }, [value]);

    return (
        <SearchStyleOrigin { ...props } onChange={ (val) => setValue(val) }></SearchStyleOrigin>
    );
};
