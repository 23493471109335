import { Endpoints, Questionnaire } from 'constant';
import { ILineValues } from 'interfaces/chart';
import { IDetail, ISummary } from 'interfaces/voe';
import React, { useState, useEffect } from 'react';
import api from 'service/api';
import { Content } from './AssessmentContent';
import { splitChartLineQuestionnaire } from './Helper';

export function PersonalWellbeingAnalytic(props: {
    orgCode: string,
    departCode: string,
    industryName: string,
    monthValue: number,
    yearValue: number,
    isMinimal?: boolean;
}) {
    const maxY = 56;
    const voeType = "flourishing";
    const answerCount = 7;

    // Local State
    const [lineValues, setLineValues] = useState<ILineValues>({});
    const [lineValuesRaw, setLineValuesRaw] = useState<ILineValues>({});
    const [detailValues, setDetailValues] = useState<IDetail[]>([]);
    const [totalUser, setTotalUser] = useState(0);
    const [averageScore, setAverageScore] = useState(0);
    const [summary, setSummary] = useState<ISummary[]>([]);

    const isMinimal = props.isMinimal ?? false;

    const detailLabels: string[] = [];
    for (let index = 1; index <= answerCount; index++) {
        detailLabels.push(`${index}`);
    }

    const initAnalytic = async () => {
        const resp = await api.get(`${Endpoints.voe}/questionnaire-analytic`, {
            params: {
                "organization_code": props.orgCode,
                "department_code": props.departCode,
                "industry_name": props.industryName,
                "month": props.monthValue,
                "year": props.yearValue,
                "type": voeType,
            },
        });
        const respData = resp.data;
        const userCount = respData.pagination.count;
        if (userCount == 0) {
            setTotalUser(0);
            setLineValues({});
            setSummary([]);
        } else {
            const values = splitChartLineQuestionnaire(respData.data);
            const summary = splitSummaryPersonalWellbeing(respData.data);

            let count = 0;
            for (const item of respData.data as any[]) {
                count += item.questionnaire_score.score;
            }

            const average = count / userCount;

            setSummary(summary);
            setAverageScore(average);
            setTotalUser(userCount);
            setLineValues({ "Wellbeing": values.values });
            setLineValuesRaw({ "Wellbeing": values.users });
        }
    };

    const initDetail = async () => {
        const resp = await api.get(`${Endpoints.voe}/questionnaire-analytic-detail`, {
            params: {
                "organization_code": props.orgCode,
                "department_code": props.departCode,
                "industry_name": props.industryName,
                "month": props.monthValue,
                "year": props.yearValue,
                "type": voeType,
            },
        });

        const respData = resp.data;
        const count = respData.pagination.count;
        if (count == 0) {
            setDetailValues([]);
            setDetailValues([]);
        }
        else {
            const values = splitChartDetailQuestionnaire(respData.data, Questionnaire.flourise, answerCount);

            setDetailValues(values);
        }
    };

    useEffect(() => {
        initAnalytic();
        initDetail();
    }, [props]);

    return <Content
        title='Personal Wellbeing'
        detailStartIndex={ 1 }
        detailLabels={ detailLabels }
        maxY={ maxY }
        summary={ summary }
        detailValues={ detailValues }
        averageScore={ averageScore }
        totalUser={ totalUser }
        values={ lineValues }
        valuesRaw={ lineValuesRaw }
        isMinimal={ isMinimal }
    ></Content>;
}

// Point Start From Index 1
const splitChartDetailQuestionnaire = (
    data: any[],
    questionnaire: string[],
    answerCount: number
) => {
    const temp: IDetail[] = [];

    let index = 0;
    for (const item of questionnaire) {
        temp.push({
            title: `Q${++index}: ${item}`,
            values: Array(answerCount).fill(0),
            average: 0,
        });
    }

    for (const item of data) {
        const order = Number(item.questionnaire_order) - 1;
        const val = Number(item.questionnaire_score) - 1;
        const countVal = Number(item.count_questionnaire_score);

        temp[order].values[val] += countVal;
    }

    for (const item of temp) {
        const count = item.values.reduce((prev, cur) => prev + cur, 0);
        const average = item.values.reduce((prev, cur, curIndex) => prev + cur * (curIndex + 1), 0) / count;

        item.average = average;
    }

    return temp;
};

const splitSummaryPersonalWellbeing = (data: any[]) => {
    const summary: ISummary[] = [
        {
            title: "Low",
            values: [0, data.length],
        }, {
            title: "Medium",
            values: [0, data.length],
        }, {
            title: "High",
            values: [0, data.length],
        }
    ];

    for (const item of data) {
        const val = item.questionnaire_score.score;

        if (val >= 8 && val <= 15) {
            summary[0].values[0]++;
        }
        else if (val >= 16 && val <= 32) {
            summary[1].values[0]++;
        }
        else if (val >= 33 && val <= 56) {
            summary[2].values[0]++;
        }
    }

    for (const item of summary) {
        item.values[1] -= item.values[0];
    }

    return summary;
};
