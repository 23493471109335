import React from "react";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { Endpoints, Images } from "constant";
import numeral from "numeral";

export default function RateRow(props: {
  row: any;
  isSelectedRow: boolean;
  labelId: any;
  withoutAction: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
}) {
  const { specialist_rate_code, username, profile_url, title, rates } =
    props.row;

  let preview = "Rp0/Rp0";
  let voiceCall = "-";
  let videoCall = "-";
  for (const item of rates ?? []) {
    const price = numeral(item.price).format("0,0");
    const fee = numeral(item.fee).format("0,0");
    if (item.format_call === "voice_call") {
      voiceCall = `Rp${price}/Rp${fee}`;
    }

    if (item.format_call === "video_call") {
      videoCall = `Rp${price}/Rp${fee}`;
    }

    if (item.format_call === "preview") {
      preview = `Rp${price}/Rp${fee}`;
    }
  }

  return (
    <TableRow
      hover
      key={specialist_rate_code}
      aria-checked={props.isSelectedRow}
      role="checkbox"
      tabIndex={-1}
      selected={props.isSelectedRow}
    >
      <TableCell align="left">
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "200px !important",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ marginRight: "20px" }}>
            {props.row.profile_url === "" ? (
              <img
                alt="maxi-cms"
                src={String(Images.avatar)}
                style={{ width: " 36px ", height: "36px" }}
              />
            ) : (
              <div
                style={{
                  width: "36px",
                  height: "36px",
                  borderRadius: "100%",
                  backgroundSize: "cover",
                  backgroundImage: `url(${String(
                    Endpoints.baseUrlAws + profile_url
                  )})`,
                }}
              />
            )}
          </div>
          <div className="truncate">{username}</div>
        </Typography>
      </TableCell>

      <TableCell align="left">
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "150px !important",
          }}
        >
          {title}
        </Typography>
      </TableCell>

      <TableCell align="left">
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "150px !important",
          }}
        >
          {preview}
        </Typography>
      </TableCell>

      <TableCell align="left">
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "150px !important",
          }}
        >
          {voiceCall}
        </Typography>
      </TableCell>

      <TableCell align="left">
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "150px !important",
          }}
        >
          {videoCall}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Box sx={{ width: "150px", display: "flex" }}>
          {!props.withoutAction ? (
            <>
              <IconButton className="bg-neutral-200" onClick={props.onEdit}>
                <img src={Images.btn_edit_act} alt="" />
              </IconButton>
            </>
          ) : null}
        </Box>
      </TableCell>
    </TableRow>
  );
}
