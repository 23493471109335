import React from "react";
import ReactDOM from "react-dom";
// Dialog
import { ModalCLoseIcon } from "screens/Login/style";
import DialogTitle from "@mui/material/DialogTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { PaperComponent } from "./Paper";
import Dialog from "@mui/material/Dialog";
import { Button, DialogContent } from "@mui/material";
import { Images } from "constant";
import { IVoidCallback } from "interfaces/callback";
const modalCloseIcon = <FontAwesomeIcon icon={faClose} />;

export interface ModalProps {
  title: string;
  isShown: boolean;
  width?: string;
  hide: () => void;
  reset?: () => any;
  children: JSX.Element;
}
export const ModalSimple: React.FunctionComponent<ModalProps> = ({
  isShown,
  title,
  hide,
  reset,
  children,
  width,
}) => {
  const clearOut = () => {
    hide();

    if (reset) reset();
  };
  const ModalDefault = (
    <Dialog
      PaperProps={{
        sx: { width: width ?? "640px", maxWidth: width ?? "640px" },
      }}
      open={isShown}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          hide();
        }
      }}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      className="h-auto"
    >
      <DialogTitle
        style={{
          cursor: "move",
        }}
        id="draggable-dialog-title"
      >
        {title}
        <ModalCLoseIcon title="Close" onClick={clearOut}>
          {modalCloseIcon}
        </ModalCLoseIcon>
      </DialogTitle>

      {children}
    </Dialog>
  );

  return isShown ? ReactDOM.createPortal(ModalDefault, document.body) : null;
};

export function ModalChangeSpealist(props: {
  isOpen: boolean;
  onClose: IVoidCallback;
  onChange: IVoidCallback;
}) {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        Are you sure to change Specialist ?
      </DialogTitle>
      <DialogContent
        sx={{
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div>
          <img src={Images.delete} style={{ width: "146px" }} />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            marginTop: "40px",
            justifyContent: "space-around",
          }}
        >
          <Button className="btn-action-filter-light" onClick={props.onClose}>
            Cancel
          </Button>
          <Button className="btn-action-filter-dark" onClick={props.onChange}>
            Change Now
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export function ModalChangeRole(props: {
  isOpen: boolean;
  onClose: IVoidCallback;
  onChange: IVoidCallback;
}) {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        Are you sure, change this role member ?
      </DialogTitle>
      <DialogContent
        sx={{
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div>
          <img src={Images.delete} style={{ width: "146px" }} />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            marginTop: "40px",
            justifyContent: "space-around",
          }}
        >
          <Button className="btn-action-filter-light" onClick={props.onClose}>
            Cancel
          </Button>
          <Button className="btn-action-filter-dark" onClick={props.onChange}>
            Delete
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export function ModalConfirmPayment(props: {
  isOpen: boolean;
  title?: string;
  onClose: IVoidCallback;
  onConfirm: IVoidCallback;
}) {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        {props.title ?? "Do you confirm payment to the specialist?"}
      </DialogTitle>
      <DialogContent
        sx={{
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div>
          <img src={Images.transaction} style={{ width: "146px" }} />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            marginTop: "40px",
            justifyContent: "space-around",
          }}
        >
          <Button className="btn-action-filter-light" onClick={props.onClose}>
            Cancel
          </Button>
          <Button className="btn-action-filter-dark" onClick={props.onConfirm}>
            Confirm
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export function ModalRestore(props: {
  isOpen: boolean;
  title?: string;
  onClose: IVoidCallback;
  onChange: IVoidCallback;
}) {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        {props.title ?? "Are you sure, restore this member ?"}
      </DialogTitle>
      <DialogContent
        sx={{
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div>
          <img src={Images.delete} style={{ width: "146px" }} />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            marginTop: "40px",
            justifyContent: "space-around",
          }}
        >
          <Button className="btn-action-filter-light" onClick={props.onClose}>
            Cancel
          </Button>
          <Button className="btn-action-filter-dark" onClick={props.onChange}>
            Restore
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export function ModalOvertimeConfirm(props: {
  isShow: boolean;
  title?: string;
  onHide: IVoidCallback;
  onSubmit: IVoidCallback;
}) {
  return (
    <Dialog
      open={props.isShow}
      onClose={props.onHide}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        {props.title ?? "Do you want to edit this overtime?"}
      </DialogTitle>
      <DialogContent
        sx={{
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div>
          <img src={Images.overtime} style={{ width: "146px" }} />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            marginTop: "40px",
            justifyContent: "space-around",
          }}
        >
          <Button className="btn-action-filter-light" onClick={props.onHide}>
            Cancel
          </Button>
          <Button className="btn-action-filter-dark" onClick={props.onSubmit}>
            Confirm
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export function ModalInvite(props: {
  isOpen: boolean;
  title?: string;
  onClose: IVoidCallback;
  onClick: IVoidCallback;
}) {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        {props.title ?? "Are you sure want to send email?"}
      </DialogTitle>
      <DialogContent
        sx={{
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div>
          <img src={Images.send} style={{ width: "146px" }} />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            marginTop: "40px",
            justifyContent: "space-around",
          }}
        >
          <Button className="btn-action-filter-light" onClick={props.onClose}>
            Cancel
          </Button>
          <Button className="btn-action-filter-dark" onClick={props.onClick}>
            Confirm
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export function ModalRepost(props: {
  title: string;
  isOpen: boolean;
  onClose: IVoidCallback;
  onChange: IVoidCallback;
}) {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        {props.title}
      </DialogTitle>
      <DialogContent
        sx={{
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div>
          <img src={Images.delete} style={{ width: "146px" }} />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            marginTop: "40px",
            justifyContent: "space-around",
          }}
        >
          <Button className="btn-action-filter-light" onClick={props.onClose}>
            Cancel
          </Button>
          <Button className="btn-action-filter-dark" onClick={props.onChange}>
            Repost
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export function ModalDelete(props: {
  isOpen: boolean;
  onClose: IVoidCallback;
  onDelete: IVoidCallback;
  title?: string;
}) {
  const title = props.title ?? "Are you sure want to delete this post?";

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth={true}
      maxWidth={"xs"}
    >
      <DialogTitle
        style={{ cursor: "move" }}
        id="draggable-dialog-title"
        sx={{
          fontSize: "18px",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent
        sx={{
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div>
          <img src={Images.delete} style={{ width: "146px" }} />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            marginTop: "40px",
            justifyContent: "space-around",
          }}
        >
          <Button className="btn-action-filter-light" onClick={props.onClose}>
            Cancel
          </Button>
          <Button className="btn-action-filter-dark" onClick={props.onDelete}>
            Delete
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export function ModalBanned(props: {
  isOpen: boolean;
  onClose: IVoidCallback;
  onDelete: IVoidCallback;
  title?: string;
}) {
  const title = props.title ?? "Are you sure want to banned this member?";

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth={true}
      maxWidth={"xs"}
    >
      <DialogTitle
        style={{ cursor: "move" }}
        id="draggable-dialog-title"
        sx={{
          fontSize: "18px",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent
        sx={{
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div>
          <img src={Images.banned} style={{ width: "146px" }} />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            marginTop: "40px",
            justifyContent: "space-around",
          }}
        >
          <Button className="btn-action-filter-light" onClick={props.onClose}>
            Cancel
          </Button>
          <Button className="btn-action-filter-dark" onClick={props.onDelete}>
            Delete
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export function ModalResign(props: {
  isOpen: boolean;
  onClose: IVoidCallback;
  onResign: IVoidCallback;
  title?: string;
}) {
  const title = props.title ?? "Are you sure want to delete this post?";

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth={true}
      maxWidth={"xs"}
    >
      <DialogTitle
        style={{ cursor: "move" }}
        id="draggable-dialog-title"
        sx={{
          fontSize: "18px",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent
        sx={{
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div>
          <img src={Images.btn_resign} style={{ width: "146px" }} />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            marginTop: "40px",
            justifyContent: "space-around",
          }}
        >
          <Button className="btn-action-filter-light" onClick={props.onClose}>
            Cancel
          </Button>
          <Button className="btn-action-filter-dark" onClick={props.onResign}>
            Resign
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
