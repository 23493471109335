import React from 'react';
import { Box, DialogActions, DialogContent } from '@mui/material';
import { ModalSimple } from 'components/Modal/ModalSimple';
import { Endpoints, Images } from 'constant';
import { Button as ButtonStyle } from 'screens/style';
import "./ModalPreview.css";

export default function ContentModal(props: {
    title: string, actionTitle: string, isShow: boolean,
    value: any, onHide: () => void, onAction: () => void;
}) {
    const {
        title,
        content,
        bg_image_url,
        revier_image_url,
        reviewer_name,
        reviewer_bio
    } = props.value;

    const bgImgUrl = Endpoints.baseUrlAws + bg_image_url;
    const reviewerImgUrl = Endpoints.baseUrlAws + revier_image_url;

    return (
        <ModalSimple isShown={ props.isShow } title={ props.title } hide={ props.onHide }>
            <>
                <DialogContent>
                    <Box className='!font-Inter mt-4'>
                        <div className='text-2xl leading-7 line-clamp-2 font-semibold mb-6'>{ title }</div>
                        <div className='flex mb-6'>
                            {
                                revier_image_url ?
                                    <img className='h-9 w-9 rounded-full mr-3 mt-1' src={ reviewerImgUrl } alt="" />
                                    :
                                    <img className='h-9 w-9 rounded-full mr-3 mt-1' src={ String(Images.avatar) } />
                            }
                            <div>
                                <div className='text-xs font-normal mb-1'>Reviewed By</div>
                                <div>
                                    <span className='font-medium'>{ reviewer_name }, </span>
                                    <span className='font-extralight text-sm'>{ reviewer_bio }</span>
                                </div>
                            </div>
                        </div>
                        <img src={ bgImgUrl } alt="" className='w-full object-cover h-60 mb-6' />
                        <div className='preview-modal' dangerouslySetInnerHTML={ { __html: content } }></div>
                    </Box>
                </DialogContent>
                <DialogActions sx={ { padding: '20px 24px !important' } }>
                    <Box sx={ {
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%'
                    } }>
                        <ButtonStyle onClick={ props.onAction } className='btn-primary' title='Add New Admin' style={ { marginTop: '30px' } } >
                            { props.actionTitle }
                        </ButtonStyle>
                    </Box>
                </DialogActions>
            </>
        </ModalSimple>
    );
}
