import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import {
	Fonts, Colors, Images
} from 'constant';
import {
	Stack, Checkbox, TableRow, TableCell, Typography, MenuItem, Box, TextField
} from '@mui/material';

// components
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Iconify from 'screens/Layout/components/Iconify';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

UserTableRow.propTypes = {
	row: PropTypes.object,
	openModal: PropTypes.func,
};

export default function UserTableRow({ row, openModal }) {
	const { id, content, member_name, flagged_by, context, transaction_code, reason } = row;

	const clippedContent = content.length > 28 ? content.substring(0, 28) + ' ...' : content;

	return (
		<TableRow key={ id } hover >

			<TableCell component='th' scope='row' padding='normal'>
				<Tooltip title={ <div className='w-44'>{ content }</div> } arrow>
					<Typography variant='subtitle2' noWrap sx={ { fontFamily: 'Fonts.interMedium' } }>
						{ clippedContent }
					</Typography>
				</Tooltip>
			</TableCell>

			<TableCell align='left' sx={ { textTransform: 'capitalize' } }>
				{ reason }
			</TableCell>

			<TableCell align='left' sx={ {
				textTransform: 'capitalize',
				fontFamily: 'Fonts.interRegular'
			} }>
				{ context === 'regular' ? 'Post' : context }
			</TableCell>

			<TableCell align='left' sx={ {
				textTransform: 'capitalize',
				fontFamily: 'Fonts.interRegular'
			} }>
				{ member_name }
			</TableCell>

			<TableCell align='left' sx={ {
				textTransform: 'capitalize',
				fontFamily: 'Fonts.interRegular'
			} }>
				{ flagged_by }
			</TableCell>

			<TableCell align='left' sx={ {
				textTransform: 'capitalize',
				fontFamily: 'Fonts.interRegular'
			} }>
				<Link to='#' onClick={ () => openModal(transaction_code) }>Preview Content</Link>
			</TableCell>
		</TableRow>
	);
}
