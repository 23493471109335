import { LocalStorage } from 'helpers';
import React, { useEffect } from 'react';
import { refreshToken } from 'service/auth';

export default function Refresher() {

    const daysToMilisecond = (days: number) => 1000 * 60 * 60 * 24 * days;

    const getMinimumRefreshDate = () => {
        const lastPeriode = (new Date());
        lastPeriode.setDate(lastPeriode.getDate() - 3);
        return lastPeriode.toISOString();
    };

    const init = async () => {
        const lastRefresh = LocalStorage.getLastRefreshDate() ?? getMinimumRefreshDate();
        const token = LocalStorage.getToken();
        const lastRefreshMilisecond = new Date(lastRefresh).getTime();
        const currentMilisecond = new Date().getTime();

        if (token && currentMilisecond - daysToMilisecond(2) > lastRefreshMilisecond) {
            try {
                const resp = await refreshToken();
                const token = resp.data.token;
                const name = resp.data.name;

                LocalStorage.setTokenUser(token);
                LocalStorage.setNameUser(name);
                LocalStorage.setLastRefreshDate(new Date().toISOString());
            } catch (error) {
                LocalStorage.clearToken();
                LocalStorage.clearName();
                LocalStorage.setLastRefreshDate('');
                location.href = '/';
            }
        }
    };

    useEffect(() => {
        init();
    }, []);

    return <></>;
}
