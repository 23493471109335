import styled from "styled-components";
import { Fonts, Colors } from "constant";

const ModalCLoseIcon = styled.i`
	color: rgba(118, 126, 140, 1);
	font-size: 23px;
	position: absolute;
	top: 20px;
	right: 20px;
	cursor: pointer;
`;

const SubTitleModal = styled.h3`
	font-size: ${Fonts.sizes.s2};
	font-weight: ${Fonts.weights.w4};
	font-family: "${Fonts.interRegular}";
	margin-bottom: 40px;
	color: ${Colors.app.SecondaryGrey};
	line-height: 150%;
	text-align: center;
`;
const TitleModal = styled.h1`
	font-size: ${Fonts.sizes.s2};
	font-weight: ${Fonts.weights.w5};
	font-family: "${Fonts.interMedium}";
	color: ${Colors.app.black};
	margin-bottom: 25px;
	text-align: left;
`;

const LabelModal = styled.label`
	color: ${Colors.app.black};
	font-weight: ${Fonts.weights.w5};
	font-family: "${Fonts.interMedium}";
	font-size: ${Fonts.sizes.xs};
`;
const InputModal = styled.input`
	padding: 15px 20px;
	width: 100%;
	border: solid 1px ${Colors.app.GhostGrey};
	background: ${Colors.white.default};
	border-radius: 5px;
	color: ${Colors.app.black};
	font-weight: ${Fonts.weights.w5};
	font-family: "${Fonts.interMedium}";
	font-size: ${Fonts.sizes.xs};
	margin-bottom: 20px;
	margin-top: 8px;
`;

const TextAreaModal = styled.textarea`
	margin-top: 30px;
	padding: 15px 20px;
	width: 100%;
	border: solid 1px ${Colors.app.GhostGrey};
	background: ${Colors.white.default};
	border-radius: 5px;
	color: ${Colors.app.black};
	font-weight: ${Fonts.weights.w5};
	font-family: "${Fonts.interMedium}";
	font-size: ${Fonts.sizes.xs};
	margin-bottom: 20px;
	margin-top: 8px;
`;

const SelectModal = styled.select`
	padding: 15px 20px;
	width: 100%;
	border: solid 1px ${Colors.app.GhostGrey};
	background: ${Colors.white.default};
	border-radius: 5px;
	color: ${Colors.app.black};
	font-weight: ${Fonts.weights.w5};
	font-family: "${Fonts.interMedium}";
	font-size: ${Fonts.sizes.xs};
	margin-bottom: 20px;
	margin-top: 8px;
`;

const mainStyle = {
	position: "absolute" as const,
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
	background: "#FFFFFF",
	borderRadius: "5px",
};

export {
	mainStyle,
	SubTitleModal,
	TitleModal,
	LabelModal,
	InputModal,
	ModalCLoseIcon,
	SelectModal,
	TextAreaModal,
};
