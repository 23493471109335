import React from 'react';
import { Box } from '@mui/material';
import "./PreviewModal.css";
import { Endpoints, Images } from 'constant';

export default function PreviewModal(props: { item: any; }) {
    const {
        title,
        content,
        bg_image_url,
        reviewer_img,
        reviewer_name,
        reviewer_bio,
    } = props.item;

    const bgImgUrl = Endpoints.baseUrlAws + bg_image_url;

    return (
        <Box className='!font-Inter mt-4'>
            <div className='text-2xl leading-7 line-clamp-2 font-semibold mb-6'>{ title }</div>
            <div className='flex mb-6'>
                {
                    reviewer_img ?
                        <img className='h-9 w-9 rounded-full mr-3 mt-1' src={ reviewer_img } alt="" />
                        :
                        <img className='h-9 w-9 rounded-full mr-3 mt-1' src={ String(Images.avatar) } />
                }
                <div>
                    <div className='text-xs font-normal mb-1'>Reviewed By</div>
                    <div>
                        <span className='font-medium'>{ reviewer_name }, </span>
                        <span className='font-extralight text-sm'>{ reviewer_bio }</span>
                    </div>
                </div>
            </div>
            <img src={ bgImgUrl } alt="" className='w-full object-cover h-60 mb-6' />
            <div className='preview-modal' dangerouslySetInnerHTML={ { __html: content } }></div>
        </Box>
    );
}
