import {
	Box,
	Table, TableBody, TableContainer, TablePagination, Typography
} from '@mui/material';
import React, {
	useEffect,
	useState
} from 'react';

import CourseRow from "sections/@dashboard/specialist/course";
import useTable from 'components/Table/useTable';
import { TableEmptyRows, TableHeadCustom } from 'components/Table';
import service from 'service';

const TABLE_HEAD = [
	{
		id: 'course_title',
		label: 'COURSE TITLE'
	}, {
		id: 'course_content',
		label: 'COURSE CONTENT'
	}, {
		id: 'course_picture',
		label: 'COURSE PICTURE'
	}, {
		id: 'course_chapter',
		label: 'COURSE CHAPTER'
	}, {
		id: 'course_ordering',
		label: 'ORDERING'
	}, {
		id: 'course_translation',
		label: 'TRANSLATION'
	}, {
		id: 'reviewed_by',
		label: 'REVIEWED BY'
	}, {
		id: 'hearts',
		label: 'HEARTS'
	}, {
		id: 'course_preview',
		label: "COURSE PREVIEW"
	}, {
		id: 'action'
	}
];
export default function TanDeleted(props: {
	forceUpdate: number;
	search: string;
	reviewedBy: string;
	chapter: string;
	onCount: (val: number) => void;
	onPreview: (val: any) => void;
}) {
	const [data, setData] = useState<any[]>([]);
	const [limit, setLimit] = useState(10);
	const [page, setPage] = useState(1);
	const [count, setCount] = useState(0);

	const [order, setOrder] = useState('desc');
	const [orderBy, setOrderBy] = useState('scheduled_date');
	const handleRequestSort = (_, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const { dense, selected, onSort } = useTable();

	const onChangeRowPerPage = (val) => {
		setPage(1);
		setLimit(val);
	};

	const onChangePage = (val) => {
		setPage(val + 1);
	};

	const init = async () => {
		const resp = await service.course.getListCourse({
			keyword: props.search,
			limit: limit ?? 10,
			order: orderBy,
			sort: order,
			page: page ?? 1,
			is_active: false,
			reviewed_by: props.reviewedBy,
			chapter_code: props.chapter,
		});

		const pagination = resp.pagination;

		setCount(pagination.count);
		setLimit(pagination.limit);
		setPage(pagination.page);
		setData(resp.data ?? []);
	};

	useEffect(() => {
		props.onCount(count);
	}, [count]);

	useEffect(() => {
		init();
	}, [props.search, props.reviewedBy, props.forceUpdate, page, limit]);

	return (
		<div>
			<TableContainer sx={ {
				minWidth: 800,
				position: 'relative'
			} }>

				<Table size={ dense ? 'small' : 'medium' }>
					<TableHeadCustom
						order={ order }
						orderBy={ orderBy }
						onRequestSort={ handleRequestSort }
						headLabel={ TABLE_HEAD }
						rowCount={ data.length }
						numSelected={ selected.length }
						onSort={ onSort }
					/>

					<TableBody>
						{
							data.map((row, index) => (
								<CourseRow
									key={ index }
									row={ row }
									withActions={ false }
									onPreview={ () => props.onPreview(row) }
								/>
							))
						}

						<TableEmptyRows height={ dense ? 52 : 72 } emptyRows={ data.length } />

					</TableBody>
				</Table>
			</TableContainer>

			<Box sx={ { position: 'relative' } }>
				<TablePagination
					rowsPerPageOptions={ [10, 25, 100] }
					component='div'
					labelRowsPerPage='Items per page: '
					count={ count || 0 }
					rowsPerPage={ limit || 10 }
					page={ page - 1 || 0 }
					onPageChange={ (_, pg) => onChangePage(pg) }
					onRowsPerPageChange={ (e) => onChangeRowPerPage(e.target.value) }
				/>
			</Box>
		</div>
	);
}
