import api from "./api";

export const fetchSpecialistRate = async (params: {
	isActive: boolean;
	keyword?: string;
	limit?: number;
	page?: number;
	titleCode?: string;
	order?: string;
	sort?: string;
	lang?: string;
}) => {
	const resp = await api.get("/v1/specialists/rates", {
		params: {
			...params,
			is_active: params.isActive,
			title: params.titleCode,
			lang: params.lang,
		},
	});

	return resp.data;
};
