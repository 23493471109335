import React from "react";
import { Paths } from "constant";
import accessKey from "constant/Access";
import { LocalStorage } from "helpers";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import {
  // Admin
  Admin,
  AnonymousAnalytic,
  AppFeedback,
  ApplicationAnayltic,
  AssessmentAnalytic,
  BillingOrganization,
  BillingSpecialist,
  BillingSpecialistRate,
  // Billing
  BillingSubscription,

  // Daily
  FlagChat,

  // Flag
  FlagComment,
  FlagUser,
  ForgotPassword,
  ForgotPasswordSuccess,
  // Insight
  InsightText,
  // AUth
  Login,
  // Users
  MemberIndividual,
  MemberOrganization,
  MemberSales,
  // Dashboard
  Metric,
  // Notification
  NotificationImport,
  // Organization
  Organization,
  // Report
  OverallAnalytic,
  SpecialistCourse,
  // Specialist
  SpecialistMember,
  UsageAnalytic,
  // Topic
  Topic,
  BannedWords,
  // Promo
  Promo,
  Exercise,
  // Refund
  Refund,
  Session,
  SpecialistAvailability,
  Rates,
} from "screens";
import DashboardLayout from "screens/Layout";
import { useAppSelector } from "store";
import { utils } from "utils";
import Noaccess from "components/Noaccess";

interface IRoute {
  path: string;
  accessKey?: string;
  component: () => JSX.Element;
}

const publicRoutes: IRoute[] = [
  {
    path: "/",
    component: Login,
  },
  {
    path: Paths.login,
    component: Login,
  },
  {
    path: Paths.forgotPassword,
    component: ForgotPassword,
  },
  {
    path: Paths.forgotPasswordSuccess,
    component: ForgotPasswordSuccess,
  },
];

const privateRoutes: IRoute[] = [
  // Forum Routes
  {
    path: "/forum",
    component: () => <Navigate to={"/forum/post"}></Navigate>,
  },
  {
    path: "/forum/insight",
    component: InsightText,
    accessKey: accessKey.Content,
  },
  {
    path: "/forum/exercise",
    component: Exercise,
    accessKey: accessKey.Content,
  },
  {
    path: "/panel/banned",
    component: BannedWords,
    accessKey: accessKey.Content,
  },
  {
    path: "/flag/comment",
    accessKey: accessKey.Content,
    component: FlagComment,
  },

  // Flag
  {
    path: "/flag",
    component: () => <Navigate to={"/flag/comment"}></Navigate>,
  },
  {
    path: "/flag/user",
    accessKey: accessKey.Flagged,
    component: FlagUser,
  },
  {
    path: "/flag/chat",
    accessKey: accessKey.Flagged,
    component: FlagChat,
  },

  // Manage Routes
  {
    path: "/manage",
    component: () => <Navigate to={"/manage/organization"}></Navigate>,
  },
  {
    path: "/manage/organization",
    accessKey: accessKey.Organization,
    component: Organization,
  },
  {
    path: "/manage/member_organization",
    accessKey: accessKey.Organization,
    component: MemberOrganization,
  },
  {
    path: "/manage/sales",
    accessKey: accessKey.Organization,
    component: MemberSales,
  },

  {
    path: "/manage/admin",
    accessKey: accessKey.Admin,
    component: Admin,
  },

  // Individual
  {
    path: "/manage/member_individual",
    accessKey: accessKey.Individual,
    component: MemberIndividual,
  },

  // Specialist Route
  {
    path: "/manage/member_specialist",
    accessKey: accessKey.Specialist,
    component: SpecialistMember,
  },
  {
    path: "/manage/course",
    accessKey: accessKey.Specialist,
    component: SpecialistCourse,
  },
  {
    path: "/billing/specialist_rate",
    accessKey: accessKey.Specialist,
    component: BillingSpecialistRate,
  },
  {
    path: "/manage/availability",
    accessKey: accessKey.Specialist,
    component: SpecialistAvailability,
  },
  {
    path: "/manage/rates",
    accessKey: accessKey.Specialist,
    component: Rates,
  },
  {
    path: "/billing/specialist",
    accessKey: accessKey.Specialist,
    component: BillingSpecialist,
  },

  // VOE Routes
  {
    path: "/voe",
    component: () => <Navigate to={"/voe/overall"}></Navigate>,
  },
  {
    path: "/voe/overall",
    accessKey: accessKey.Report,
    component: OverallAnalytic,
  },
  {
    path: "/voe/usage",
    accessKey: accessKey.Report,
    component: UsageAnalytic,
  },
  {
    path: "/voe/application",
    accessKey: accessKey.Report,
    component: ApplicationAnayltic,
  },
  {
    path: "/voe/assessment",
    accessKey: accessKey.Report,
    component: AssessmentAnalytic,
  },
  {
    path: "/voe/anonymous",
    accessKey: accessKey.Report,
    component: AnonymousAnalytic,
  },
  {
    path: "/dashboard/app-feedback",
    accessKey: accessKey.Report,
    component: AppFeedback,
  },
  {
    path: "/dashboard/metrics",
    accessKey: accessKey.Report,
    component: Metric,
  },

  // Notification Routes
  {
    path: "/notification",
    component: () => <Navigate to={"/notification/import-user"}></Navigate>,
  },
  {
    path: "/notification/import-user",
    accessKey: accessKey.Organization,
    component: NotificationImport,
  },

  // Control Panel
  {
    path: "/panel",
    accessKey: accessKey.Admin,
    component: () => <Navigate to={"/panel/admin"}></Navigate>,
  },
  {
    path: "/panel/admin",
    accessKey: accessKey.Admin,
    component: Admin,
  },
  {
    path: "/panel/topic",
    component: Topic,
    accessKey: accessKey.Admin,
  },

  // Dashboard
  {
    path: "/dashboard",
    accessKey: accessKey.Dashboard,
    component: () => <Navigate to={"/dashboard/metrics"}></Navigate>,
  },
  {
    path: "/dashboard/session",
    accessKey: accessKey.Dashboard,
    component: Session,
  },
  {
    path: "/dashboard/refund",
    accessKey: accessKey.Dashboard,
    component: Refund,
  },
  {
    path: "/dashboard/promo",
    accessKey: accessKey.Dashboard,
    component: Promo,
  },

  // Billing
  {
    path: "/billing",
    component: () => <Navigate to={"/billing/subscription"}></Navigate>,
  },
  {
    path: "/billing/subscription",
    accessKey: accessKey.Billing,
    component: BillingSubscription,
  },
  {
    path: "/billing/organization",
    accessKey: accessKey.Billing,
    component: BillingOrganization,
  },
];

const Router = () => {
  const { access } = useAppSelector((state) => state.access);

  return (
    <BrowserRouter>
      <Routes>
        {publicRoutes.map((route) => {
          const token = LocalStorage.getToken();
          const name = LocalStorage.getName();

          if (token === null && name === null) {
            return (
              <Route
                key={route.path}
                path={route.path}
                element={<route.component />}
              />
            );
          }

          return (
            <Route
              key={route.path}
              path={route.path}
              element={<Navigate to={"/dashboard"} />}
            />
          );
        })}

        <Route
          key={"private"}
          path="/*"
          element={
            <DashboardLayout>
              <Routes>
                {privateRoutes.map((route) => {
                  const token = LocalStorage.getToken();
                  const name = LocalStorage.getName();

                  if (token === null && name === null) {
                    return (
                      <Route
                        key={route.path}
                        path={route.path}
                        element={<Navigate to={"/login"} />}
                      />
                    );
                  }

                  if (route.accessKey) {
                    if (utils.isAccessExist(access, route.accessKey)) {
                      return (
                        <Route
                          key={route.path}
                          path={route.path}
                          element={<route.component />}
                        />
                      );
                    }
                  }

                  return (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={<Noaccess></Noaccess>}
                    />
                  );
                })}
              </Routes>
            </DashboardLayout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
