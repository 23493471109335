import React from 'react';
import { Box, Button, TableCell, TableRow, Typography } from '@mui/material';
import dayjs from 'dayjs';

export default function RefundRow(props: {
    row: any;
    labelId: any;
    onChangeStatus?: (val: string) => void;
}) {
    const { email, title, requested_date, purchased_date } = props.row;

    return (
        <TableRow hover key={ props.labelId } role='checkbox' tabIndex={ -1 } >
            <TableCell align='left'>
                <Typography variant='subtitle2' noWrap sx={ {
                    fontFamily: 'Fonts.interRegular',
                    width: '150px !important'
                } }>
                    { email }
                </Typography>
            </TableCell>

            <TableCell align='left' sx={ { textTransform: 'capitalize' } }>
                <Typography variant='subtitle2' noWrap sx={ {
                    fontFamily: 'Fonts.interRegular'
                } }>
                    Counseling ( { title } )
                </Typography>
            </TableCell>

            <TableCell align='left' sx={ { textTransform: 'capitalize' } }>
                <Typography variant='subtitle2' noWrap sx={ {
                    fontFamily: 'Fonts.interRegular',
                    width: '150px !important'
                } }>
                    { dayjs(purchased_date).format('MMMM DD YYYY') }
                </Typography>
            </TableCell>

            <TableCell align='left' sx={ { textTransform: 'capitalize' } }>
                <Typography variant='subtitle2' noWrap sx={ {
                    fontFamily: 'Fonts.interRegular',
                    width: '150px !important'
                } }>
                    { dayjs(requested_date).format('MMMM DD YYYY') }
                </Typography>
            </TableCell>

            {
                props.onChangeStatus ? (
                    <TableCell align='left' sx={ { textTransform: 'capitalize' } }>
                        <Box sx={ { width: '200px', display: 'flex' } } className="gap-4">
                            <>
                                <Button onClick={ () => props.onChangeStatus!("approved") }
                                    sx={ { minWidth: 'auto !important' } }
                                    variant="outlined"
                                    size='small'
                                >
                                    Approve
                                </Button>
                                <Button onClick={ () => props.onChangeStatus!("declined") }
                                    sx={ { minWidth: 'auto !important' } }
                                    variant="outlined"
                                    color="error"
                                    size='small'
                                >
                                    Decline
                                </Button>
                            </>
                        </Box>
                    </TableCell>
                ) : null
            }

        </TableRow>
    );
}
