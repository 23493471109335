import { Members } from 'interfaces';

import { MembersActionTypes } from './actionTypes';

const initialState: Members.State = {
	members: [],
	pagination: [],
	error: '',
	loadingGet: false,
	loadingAdd: false,
	loadingDelete: false,
	loadingUpdate: false
};

const setMembers = (state: Members.State, payload:Members.MembersPayload) => ({
	...state,
	members: payload.listData as Members.Detail[],
	pagination: payload.pagination as Members.Pagination[],
	loadingGet: false
});

const addMembers = (state: Members.State, payload:Members.MembersPayload) => ({
	...state,
	members: state.members.concat(payload.data as Members.Detail),
	loadingAdd: false
});

const reqMembers = (state: Members.State, type: MembersActionTypes) => ({
	...state,
	loadingGet: type === MembersActionTypes.GET_MEMBERS_LIST_LOADING,
	loadingUpdate: false
});

const rejectMembers = (state: Members.State) => ({
	...state,
	loadingGet: false,
	loadingAdd: false,
	loadingDelete: false,
	loadingUpdate: false,
});

const membersReducer = (state:Members.State = initialState, action: {type: MembersActionTypes; payload: Members.MembersPayload;}) => {
	switch (action.type) {
		case MembersActionTypes.GET_MEMBERS_LIST_SUCCESS:
			return setMembers(state, action.payload);
		case MembersActionTypes.ADD_MEMBERS_SUCCESS:
			return addMembers(state, action.payload);

		case MembersActionTypes.GET_MEMBERS_LIST_LOADING:
		case MembersActionTypes.ADD_MEMBERS_LOADING:
			return reqMembers(state, action.type);

		case MembersActionTypes.GET_MEMBERS_LIST_ERROR:
		case MembersActionTypes.ADD_MEMBERS_ERROR:
			return rejectMembers(state);
		default:
			return state;
	}
};

export default membersReducer;
