import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default {
	useForm(initialValues: any) {
		const [values, setValues] = useState(initialValues);

		return [
			values, (e: any, key: any) => setValues({
				...values,
				[key]: e,
			}),
		];
	},

	useCustomDispatch: <T>(action: unknown) => {
		const dispatch = useDispatch();

		return useCallback(() => dispatch(action), [dispatch, action]);
	},
	
	useResponsive(query, key, start, end) {
		const theme = useTheme();
	
		const mediaUp = useMediaQuery(theme.breakpoints.up(key));
	
		const mediaDown = useMediaQuery(theme.breakpoints.down(key));
	
		const mediaBetween = useMediaQuery(theme.breakpoints.between(start, end));
	
		const mediaOnly = useMediaQuery(theme.breakpoints.only(key));
	
		if (query === 'up') {
			return mediaUp;
		}
	
		if (query === 'down') {
			return mediaDown;
		}
	
		if (query === 'between') {
			return mediaBetween;
		}
	
		if (query === 'only') {
			return mediaOnly;
		}
		return null;
	},
	
	useTabs(defaultValues: string) {
		const [currentTab, setCurrentTab] = useState(defaultValues || '');
	  
		return {
		  currentTab,
		  onChangeTab: (event: any, newValue: any) => {
				setCurrentTab(newValue);
		  },
		  setCurrentTab,
		};
	},
	
	useTable(props: { defaultDense: any; defaultOrderBy: any; defaultOrder: any; defaultCurrentPage: any; defaultRowsPerPage: any; defaultSelected: any; } | undefined) {
		const [dense, setDense] = useState(props?.defaultDense || false);
	  
		const [orderBy, setOrderBy] = useState(props?.defaultOrderBy || 'name');
	  
		const [order, setOrder] = useState(props?.defaultOrder || 'asc');
	  
		const [page, setPage] = useState(props?.defaultCurrentPage || 0);
	  
		const [rowsPerPage, setRowsPerPage] = useState(props?.defaultRowsPerPage || 5);
	  
		const [selected, setSelected] = useState(props?.defaultSelected || []);
	  
		const onSort = (id: string) => {
		  const isAsc = orderBy === id && order === 'asc';
		  if (id !== '') {
				setOrder(isAsc ? 'desc' : 'asc');
				setOrderBy(id);
		  }
		};
	  
		const onSelectRow = (id: ConcatArray<never>) => {
		  const selectedIndex = selected.indexOf(id);
	  
		  let newSelected = [];
	  
		  if (selectedIndex === -1) {
				newSelected = newSelected.concat(selected, id);
		  } else if (selectedIndex === 0) {
				newSelected = newSelected.concat(selected.slice(1));
		  } else if (selectedIndex === selected.length - 1) {
				newSelected = newSelected.concat(selected.slice(0, -1));
		  } else if (selectedIndex > 0) {
				newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
		  }
		  setSelected(newSelected);
		};
	  
		const onSelectAllRows = (checked: any, newSelecteds: any) => {
		  if (checked) {
				setSelected(newSelecteds);
				return;
		  }
		  setSelected([]);
		};
	  
		const onChangePage = (event: any, newPage: any) => {
		  setPage(newPage);
		};
	  
		const onChangeRowsPerPage = (event: { target: { value: string; }; }) => {
		  setRowsPerPage(parseInt(event.target.value, 10));
		  setPage(0);
		};
	  
		const onChangeDense = (event: { target: { checked: any; }; }) => {
		  setDense(event.target.checked);
		};
	  
		// filter
	  
		return {
		  dense,
		  order,
		  page,
		  setPage,
		  orderBy,
		  rowsPerPage,
		  //
		  selected,
		  setSelected,
		  onSelectRow,
		  onSelectAllRows,
		  //
		  onSort,
		  onChangePage,
		  onChangeDense,
		  onChangeRowsPerPage,
		};
	},
	
	descendingComparator(a: { [x: string]: number; }, b: { [x: string]: number; }, orderBy: string | number) {
		if (b[orderBy] < a[orderBy]) {
		  return -1;
		}
		if (b[orderBy] > a[orderBy]) {
		  return 1;
		}
		return 0;
	},
	  
	getComparator(order: string, orderBy: any) {
		return order === 'desc'
			? (a: any, b: any) => this.descendingComparator(a, b, orderBy)
			: (a: any, b: any) => -this.descendingComparator(a, b, orderBy);
	},
	
	emptyRows(page: number, rowsPerPage: number, arrayLength: number) {
		return page > 0 ? Math.max(0, (1 + page) * rowsPerPage - arrayLength) : 0;
	},
	
	generatePassword() {
		return Math.random().toString(36)
			.slice(2) + Math.random().toString(36)
			.slice(2);
	}

};
