import React, { useRef } from "react";
import { DialogContent } from "@mui/material";
import { ModalSimple } from "components/Modal/ModalSimple";
import { Endpoints } from "constant";
import dayjs from "dayjs";
import { Button as ButtonStyle } from "screens/style";
import { utils } from "utils";
import numeral from "numeral";
import { toast } from "react-toastify";

export default function ModalOvertimeLink(props: {
  value: any;
  isShow: boolean;
  onHide: () => void;
  onSubmit: (val: string) => void;
}) {
  const contentRef = useRef<HTMLDivElement>(null);
  const value = props.value;

  const onSubmit = () => {
    window.navigator.clipboard.writeText(
      contentRef.current!.innerText!.replace(/\n\n/g, "\n")
    );
    toast.success("link already copied into clipboard!");
  };

  return (
    <ModalSimple
      hide={props.onHide}
      isShown={props.isShow}
      title=""
      width="480px"
    >
      <DialogContent>
        <div ref={contentRef}>
          <WaitingPayment value={value}></WaitingPayment>
        </div>

        <ButtonStyle
          onClick={onSubmit}
          className="btn-primary"
          title="Copy text"
          style={{ marginTop: "30px" }}
        >
          Copy text
        </ButtonStyle>
      </DialogContent>
    </ModalSimple>
  );
}

function WaitingPayment(props: { value: any }) {
  const value = props.value;

  return (
    <div className="leading-6 !font-medium mx-auto w-96 mt-8">
      <div className="font-bold">Overtime Confirmation</div>
      <br />
      <div>{`Name: ${value.psycholog_username}`}</div>
      <div>{`Session: ${getFormatCall(value.format_call)}`}</div>
      <div>{`Date: ${dayjs(value.date).format("DD MMMM YYYY")}`}</div>
      <div>{`Time: ${value.time_from}`}</div>
      <div>{`Overtime: ${value.overtime.duration} Minutes`}</div>
      <div>{`Overtime Price: Rp${numeral(value.overtime.price).format(
        "0,0"
      )}`}</div>
      <br />
      <div>
        Use this link to complete your payment via website:{" "}
        <a
          className="text-brand-dark"
          href={getLink(value)}
          target="_blank"
          rel="noreferrer"
        >
          {getLink(value)}
        </a>
      </div>
    </div>
  );
}

function getFormatCall(formatCall: string) {
  return formatCall === "video_call"
    ? "60 Min Video Call"
    : "60 Min Voice Call";
}

function getLink(value: any) {
  return `${Endpoints.baseUrlLandingUrl}/booking/reservation/${value.reservation_code}/overtime`;
}
