import React from "react";
import {
  Box,
  Button,
  Card,
  Container,
  InputAdornment,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { ModalDelete } from "components/Modal/ModalSimple";
import { a11yProps, SearchStyle, TabPanelHidden } from "components/Panel";
import Compress from "compress.js";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Iconify from "screens/Layout/components/Iconify";
import { addUploadImage } from "store/uploadimages";
import ModalModify from "./components/ModalModify";
import TabActived from "./components/TabActivated";
import TabDeleted from "./components/TabDeleted";
import { getListCategory } from "service/category";
import {
  addTopic,
  deleteTopic,
  editTopic,
  getTopicTranslation,
  modifyTopicTranslation,
  repostTopic,
} from "service/topic";
import Appbar from "components/Appbar";

export default function Topic() {
  // Options Table
  const [filterSearch, setFilterSearch] = useState("");

  // List
  const [listCategory, setListCategory] = useState<any[]>([]);

  // Tab Index
  const [tabValue, setTabValue] = useState(0);

  // Count Label
  const [activedCount, setActivedCount] = useState(0);
  const [deletedCount, setDeletedCount] = useState(0);

  // Force Update
  const [activedForceUpdate, setActivedForceUpdate] = useState(0);
  const [deletedForceUpdate, setDeletedForceUpdate] = useState(0);

  // Modal
  const [isShowModalModify, setShowModalModify] = useState(false);

  // Additional
  const [isAddContent, setAddContent] = useState(false);
  const [currentItem, setCurrentItem] = useState<any>({});
  const [isDefaultLanguage, setDefaultLanguage] = useState(true);
  const [isShowDelete, setShowDelete] = useState(false);

  useEffect(() => {
    getListCategory({
      limit: 100,
      page: 1,
    }).then((val) => {
      setListCategory(val.data);
    });
  }, []);

  useEffect(() => {
    if (!isShowModalModify && !isShowDelete) {
      setCurrentItem({});
    }
  }, [isShowModalModify, isShowDelete]);

  useEffect(() => {
    if (!isShowModalModify) {
      setDefaultLanguage(true);
      setAddContent(true);
      setCurrentItem({});
    }
  }, [isShowModalModify]);

  const onAddPost = () => {
    setAddContent(true);
    setShowModalModify(true);
  };

  const onSubmit = async (data: any) => {
    let imageUrl = data.imageUrl;

    if (isDefaultLanguage) {
      if (!imageUrl && data.files.length === 0) {
        return toast.warn("input the image first");
      }

      if (!imageUrl) {
        const compress = new Compress();
        const blobImg = await compress.compress(data.files, {
          size: 0.8, // the max size in MB, defaults to 2MB
          quality: 0.75, // the quality of the image, max is 1,
        });
        const newFile = Compress.convertBase64ToFile(
          blobImg[0].data,
          blobImg[0].ext
        );
        imageUrl = await addUploadImage({ body: newFile });
      }
    }

    const params = {
      category_code: data.category_code,
      description: data.description,
      image_url: imageUrl,
      name: data.name,
      language: data.language,
    };

    // Add Topic
    if (isAddContent && isDefaultLanguage) {
      await addTopic(params);

      setShowModalModify(false);
      setActivedForceUpdate(Math.random());

      return toast.info("topic already added");
    }

    // Update Topic
    if (!isAddContent && isDefaultLanguage) {
      await editTopic({
        ...params,
        topic_code: currentItem.topic_code,
      });

      setShowModalModify(false);
      setActivedForceUpdate(Math.random());

      return toast.info("topic already updated");
    }

    // Add and update Topic Translation
    await modifyTopicTranslation({
      ...params,
      topic_code: currentItem.topic_code,
    });

    setShowModalModify(false);
    setActivedForceUpdate(Math.random());

    toast.info("topic already updated");
  };

  const onEdit = (item: any) => {
    setCurrentItem(item);
    setShowModalModify(true);
    setAddContent(false);
  };

  const onDelete = (item: any) => {
    setCurrentItem(item);
    setShowDelete(true);
  };

  const onConfirmDelete = async () => {
    await deleteTopic({ topic_code: currentItem.topic_code });

    setActivedForceUpdate(Math.random());
    setDeletedForceUpdate(Math.random());
    setShowDelete(false);

    toast.info("topic already deleted");
  };

  const onRepost = async (data: any) => {
    await repostTopic({ topic_code: data.topic_code });

    setDeletedForceUpdate(Math.random());
    setActivedForceUpdate(Math.random());

    toast.info("topic already reposted");
  };

  const onTranslation = async (row: any, lang: string) => {
    const resp = await getTopicTranslation({
      language: lang,
      topic_code: row.topic_code,
    });
    const data = resp.data;

    setCurrentItem({
      ...row,
      name: data.name,
      image_url: data.image_url,
      language: data.lang,
      description: data.description,
    });

    setDefaultLanguage(false);
    setAddContent(false);
    setShowModalModify(true);
  };

  const onAddTranslation = (row: any) => {
    setCurrentItem({
      ...row,
      language: "id",
    });

    setDefaultLanguage(false);
    setShowModalModify(true);
    setAddContent(true);
  };

  return (
    <>
      <Container maxWidth="lg">
        <Appbar onSearch={(val) => setFilterSearch(val)} title="Topic"></Appbar>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Tabs
              value={tabValue}
              onChange={(_, val) => setTabValue(val)}
              aria-label="basic tabs example"
            >
              <Tab label={`Actived (${activedCount})`} {...a11yProps(0)} />
              <Tab label={`Deleted (${deletedCount})`} {...a11yProps(1)} />
            </Tabs>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            margin: "0",
            paddingTop: "20px",
            paddingBottom: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box></Box>
          <Box
            sx={{
              flex: 3,
              textAlign: "right",
            }}
          >
            <Button
              onClick={onAddPost}
              className="btn-action-filter-dark"
              sx={{ width: "180px" }}
              endIcon={<Iconify icon="eva:plus-fill" sx={{}} />}
            >
              Add New Topic
            </Button>
          </Box>
        </Box>

        <TabPanelHidden value={tabValue} index={0}>
          <Card>
            <TabActived
              search={filterSearch}
              forceUpdate={activedForceUpdate}
              onEdit={onEdit}
              onDelete={onDelete}
              onCount={(val) => setActivedCount(val)}
              onTranslation={onTranslation}
              onAddTranslation={onAddTranslation}
            />
          </Card>
        </TabPanelHidden>
        <TabPanelHidden value={tabValue} index={1}>
          <Card>
            <TabDeleted
              search={filterSearch}
              forceUpdate={deletedForceUpdate}
              onCount={(val) => setDeletedCount(val)}
              onRepost={onRepost}
            />
          </Card>
        </TabPanelHidden>

        <ModalModify
          isDefaultlanguage={isDefaultLanguage}
          isAddContent={isAddContent}
          isShow={isShowModalModify}
          listCategory={listCategory}
          onHide={() => setShowModalModify(false)}
          value={currentItem}
          onSubmit={onSubmit}
        />

        <ModalDelete
          isOpen={isShowDelete}
          onClose={() => setShowDelete(false)}
          onDelete={onConfirmDelete}
          title="Are you sure want to delete this topic?"
        />
      </Container>
    </>
  );
}
