import {
	Box,
	Table, TableBody, TableContainer, TablePagination, Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import React, {
	useState
} from 'react';

import { ModalDefault } from 'components/Modal';
import { useModal } from 'components/Modal/useModal';
import { Colors, Fonts } from 'constant';
import { Button as ButtonStyle } from 'screens/style';

// hooks
import useTable from 'components/Table/useTable';

import {
	TableEmptyRows, TableHeadCustom
} from 'components/Table';
// sections
import { UserTableRow } from 'sections/@dashboard/forum/flag/OutstandingTabComponents';

const TABLE_HEAD = [
	{
		id: 'comment',
		label: 'COMMENT',
		alignRight: false
	},
	{
		id: 'reasons',
		label: 'REASONS',
		alignRight: false
	},
	{
		id: 'context',
		label: 'CONTEXT',
		alignRight: false
	},
	{
		id: 'comment_by',
		label: 'COMMENT BY',
		alignRight: false
	},
	{
		id: 'flagged_by',
		label: 'FLAGGED BY'
	},
	{
		id: 'preview',
		label: 'PREVIEW'
	}
];

OutstandingTab.propTypes = {
	list: PropTypes.object,
	pagination: PropTypes.object,
	getAnyPage: PropTypes.func,
	getChangeRows: PropTypes.func,
	onEdit: PropTypes.func,
	loadDataPagi: PropTypes.func,
	processConfirmFlag: PropTypes.func,
	getPreview: PropTypes.func,
	preview: PropTypes.object,
};
export default function OutstandingTab({ list, preview, pagination, getPreview, loadDataPagi, processConfirmFlag, getAnyPage, getChangeRows, handleDelete, onEdit }) {
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('comment_content');
	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};
	const { isShown, toggle } = useModal();
	const {
		dense,
		page,
		rowsPerPage,
		selected,
		onSelectRow,
		//
		onSort,
	} = useTable();
	const [tc, stc] = useState('');
	const openModal = cm => {
		toggle();
		stc(cm);
		getPreview(cm);
		// const split = published_date.split(' ');
		// setValueD(split[0]);
		// setValueT(convertFromStringToDate(published_date));
		// setPostCode(post_code);
		// setDataModalContent(content);

	};

	const clsModal = (cm, stt) => {
		toggle();
		processConfirmFlag(cm, stt);
	};

	const dataFiltered = applySortFilter({
		list,
		comparator: getComparator(order, orderBy),
	});

	const formatDate = date => {
		return new Date(date).getDate() + ' ' + new Date(date).toLocaleString('default', { month: 'short' }) + ' ' + new Date(date).getFullYear() + ' ' + ('0' + new Date(date).getHours()).slice(-2) + ':' + ('0' + new Date(date).getMinutes()).slice(-2);
	};

	const denseHeight = dense ? 52 : 72;

	const contentModal = (
		<Box>
			<Box>
				<Typography sx={ {
					marginTop: '10px',
					textAlign: 'left',
					fontSize: '16px',
					color: Colors.app.black,
					fontWeight: Fonts.weights.w5,
					fontFamily: 'Fonts.interMedium',
					marginBottom: '10px'
				} }>
					Flagged Comment:
				</Typography>

				<Typography sx={ {
					fontSize: '14px',
					color: '#FF579D',
					fontWeight: Fonts.weights.w5,
					fontFamily: 'Fonts.interMedium',
					lineHeight: '21px'
				} }>
					{ preview[0]?.comment_content }
				</Typography>

				<Typography sx={ {
					color: '#767E8C',
					fontSize: '14px',
					lineHeight: '150%',
					fontWeight: Fonts.weights.w4,
					fontFamily: 'Fonts.interRegular',
					marginTop: '10px',
					paddingBottom: '20px',
					borderBottom: 'solid 1px #E0E5ED'
				} }>
					{ preview[0]?.comment_from } ({ formatDate(preview[0]?.comment_created_date) })
				</Typography>

				<Typography sx={ {
					marginTop: '20px',
					textAlign: 'left',
					fontSize: '16px',
					color: Colors.app.black,
					fontWeight: Fonts.weights.w5,
					fontFamily: 'Fonts.interMedium',
					marginBottom: '10px'
				} }>
					Flagged Reasons:
				</Typography>

				{
					// Reason
					preview[0] === undefined ? 'empty' :
						[preview[0]?.flagged_reason].map((ppp, i) => (
							Object.keys(preview[0]?.flagged_reason).map((objk, k) => (
								<Box key={ i } >
									<Typography sx={ {
										fontSize: '14px',
										color: Colors.app.black,
										fontWeight: Fonts.weights.w5,
										fontFamily: 'Fonts.interMedium',
										lineHeight: '21px'
									} }>
										{ objk }
									</Typography>
									<Box key={ k } sx={ { marginBottom: '20px' } }>
										{ ppp[objk].map((dd, d) => (
											<Typography key={ d } sx={ {
												fontSize: '14px',
												color: '#FF579D',
												fontWeight: Fonts.weights.w5,
												fontFamily: 'Fonts.interMedium',
												lineHeight: '21px',
												marginTop: '10px'
											} }>
												{ dd }
											</Typography>
										)) }
									</Box>
								</Box>
							))
						))
				}

				{
					preview[0]?.postpoll.map((pp, i) => (
						<Box key={ i }>
							<Typography sx={ {
								marginTop: '20px',
								textAlign: 'left',
								fontSize: '16px',
								color: Colors.app.black,
								fontWeight: Fonts.weights.w5,
								fontFamily: 'Fonts.interMedium',
								marginBottom: '10px'
							} }>
								{ pp.poll_list === null ? 'Post' : 'Poll' } Title:
							</Typography>

							<Typography sx={ {
								fontSize: '14px',
								color: '#1F1F21',
								fontWeight: Fonts.weights.w5,
								fontFamily: 'Fonts.interMedium',
								lineHeight: '100%'
							} }>
								{ pp.content }
							</Typography>

							<Typography sx={ {
								color: '#767E8C',
								fontSize: '14px',
								lineHeight: '150%',
								fontWeight: Fonts.weights.w4,
								fontFamily: 'Fonts.interRegular',
								marginTop: '10px',
								paddingBottom: '20px'
							} }>
								{ pp.member_name === null ? pp.member_name : pp.admin_name } ({ formatDate(pp.published_date) })
							</Typography>
						</Box>
					))
				}

				<Typography sx={ {
					marginTop: '20px',
					textAlign: 'left',
					fontSize: '16px',
					color: Colors.app.black,
					fontWeight: Fonts.weights.w5,
					fontFamily: 'Fonts.interMedium',
					marginBottom: '10px'
				} }>
					Thread ({ preview[0]?.thread.length }):
				</Typography>

				{
					preview[0]?.thread.map((tt, t) => {

						return (
							<Box key={ t }>
								<Typography sx={ {
									fontSize: '14px',
									color: '#767E8C',
									fontWeight: Fonts.weights.w5,
									fontFamily: 'Fonts.interMedium',
									lineHeight: '100%'
								} }>
									{ tt.comment_content_highlight }
								</Typography>
								<Typography sx={ {
									color: '#767E8C',
									fontSize: '14px',
									lineHeight: '150%',
									fontWeight: Fonts.weights.w4,
									fontFamily: 'Fonts.interRegular',
									marginTop: '10px',
									paddingBottom: '20px',
									borderBottom: 'solid 1px #E0E5ED'
								} }>
									{ tt.comment_name_highlight } ({ formatDate(tt.comment_created_date_highlight) })
								</Typography>

								<Typography sx={ {
									fontSize: '14px',
									color: '#1F1F21',
									fontWeight: Fonts.weights.w5,
									fontFamily: 'Fonts.interMedium',
									marginTop: '10px',
									lineHeight: '100%'
								} }>
									{ tt.reply_content }
								</Typography>
								<Typography sx={ {
									color: '#1F1F21',
									fontSize: '14px',
									lineHeight: '150%',
									fontWeight: Fonts.weights.w4,
									fontFamily: 'Fonts.interRegular',
									marginTop: '10px',
									paddingBottom: '20px',
									borderBottom: 'solid 1px #E0E5ED'
								} }>
									{ tt.reply_name } ({ formatDate(tt.reply_created_date) })
								</Typography>
								<Typography sx={ {
									fontSize: '14px',
									color: '#1F1F21',
									fontWeight: Fonts.weights.w5,
									fontFamily: 'Fonts.interMedium',
									lineHeight: '100%'
								} }>
									{ tt.reply_to_content }
								</Typography>
								<Typography sx={ {
									color: '#767E8C',
									fontSize: '14px',
									lineHeight: '150%',
									fontWeight: Fonts.weights.w4,
									fontFamily: 'Fonts.interRegular',
									marginTop: '10px',
									paddingBottom: '20px',
									borderBottom: 'solid 1px #E0E5ED'
								} }>
									{ tt.reply_to_name } ({ tt.reply_to_created_date !== '' ? formatDate(tt.reply_to_created_date) : '-' })
								</Typography>

							</Box>
						);
					})
				}

			</Box>
		</Box>
	);

	const contentModalFooter = (
		<Box sx={ {
			display: 'flex',
			flexDirection: 'column',
			width: '100%'
		} }>
			<ButtonStyle className='btn-primary' title='Suggested' style={ {
				marginTop: '10px',
				marginBottom: '15px',
				width: '100%'
			} } onClick={ () => clsModal(tc, true) } >
				Confirm
			</ButtonStyle>
			<ButtonStyle className='btn-default' onClick={ () => clsModal(tc, false) } style={ {
				background: `${Colors.app.white}`,
				color: `${Colors.app.black}`,
				border: `solid 1px ${Colors.app.black}`,
				width: '100%'
			} } title='Deny' >
				{/* Deny */ }
				Cancel
			</ButtonStyle>
		</Box>
	);

	return (
		<div>
			<TableContainer sx={ {
				minWidth: 800,
				position: 'relative'
			} }>

				<Table size={ dense ? 'small' : 'medium' }>
					<TableHeadCustom
						order={ order }
						orderBy={ orderBy }
						onRequestSort={ handleRequestSort }
						headLabel={ TABLE_HEAD }
						rowCount={ list.length }
						numSelected={ selected.length }
						onSort={ onSort }
					/>

					<TableBody>
						{ dataFiltered.map((row, index) => (
							<UserTableRow
								key={ index }
								row={ row }
								openModal={ () => openModal(row.transaction_code) }
							/>
						)) }

						<TableEmptyRows height={ denseHeight } emptyRows={ dataFiltered.length } />

					</TableBody>
				</Table>
			</TableContainer>

			<Box sx={ { position: 'relative' } }>
				<TablePagination
					rowsPerPageOptions={ [10, 25, 100] }
					component='div'
					labelRowsPerPage='Items per page: '
					count={ pagination[0]?.count || 0 }
					rowsPerPage={ pagination[0]?.limit || 10 }
					page={ pagination[0]?.page - 1 || 0 }
					onPageChange={ (e, pg) => getAnyPage(pg) }
					onRowsPerPageChange={ getChangeRows }
				/>

			</Box>

			<ModalDefault isShown={ isShown } hide={ toggle } modalContent={ contentModal } reset={ () => console.log('Clearout') } modalFooter={ contentModalFooter } title='Preview Context' />

		</div>
	);
}

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}
function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter({ list, comparator }) {

	const stabilizedThis = list.map((el, index) => [el, index]);

	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});

	list = stabilizedThis.map(el => el[0]);

	return list;
}