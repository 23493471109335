import { Common, UploadImage } from "interfaces";
import { LocalStorage } from "helpers";
import { UploadImageActionTypes } from "./actionTypes";
import { Endpoints } from "constant";
import { dispatch } from "store";

export const uploadImageProgress = (
	type: UploadImageActionTypes,
	payload?: UploadImage.UploadImagePayload
) => {
	dispatch({
		type,
		payload,
	});
};
const makeid = (length: number) => {
	let result = "";
	const characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	const charactersLength = characters.length;
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
};
export const addUploadImage = (params?: Common.RequestData): Promise<any> => {
	return new Promise<any[]>(async (resolve, reject) => {
		uploadImageProgress(UploadImageActionTypes.ADD_UPLOADIMAGE_LOADING);

		const formData = new FormData();

		formData.append("uploadfile", params?.body);
		const result: any[] = [];
		await fetch(`${Endpoints.baseUrl}${Endpoints.uploadimage}`, {
			// mode:'no-cors',
			headers: {
				Authorization: `${LocalStorage.getToken()}`,
			},
			method: "POST",
			body: formData,
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.stat_code === "APP:SUCCESS") {
					resolve(response.data?.file_path);
				} else {
					result.push({
						stat_code: response.stat_code,
						stat_msg: response.stat_msg,
					});

					resolve(result);
				}
			})
			.catch(function (e) {
				reject(e);
			});
	});
};
