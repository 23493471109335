import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import SideMenu from './components/SideMenu';

import './index.css';

const APP_BAR_MOBILE = 64;

const RootStyle = styled('div')({
	display: 'flex',
	minHeight: '100%',
	overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
	flexGrow: 1,
	overflow: 'auto',
	minHeight: '100%',
	paddingTop: APP_BAR_MOBILE + 24,
	paddingBottom: theme.spacing(10),
	[theme.breakpoints.up('lg')]: {
		paddingTop: 24,
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2)
	}
}));

export default function DashboardLayout(props: { children: JSX.Element; }) {
	const [isOpen, setIsOpen] = useState(true);

	return (
		<RootStyle>
			<SideMenu
				isOpen={ isOpen }
				onToggleNav={ () => setIsOpen(!isOpen) }
			/>
			<MainStyle>
				{ props.children }
			</MainStyle>
		</RootStyle>
	);
}
