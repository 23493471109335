import * as setting from "./setting";
import * as insight from "./insight";
import * as member from "./member";
import * as org from "./organization";
import * as analytic from "./analytic";
import * as master from "./master";
import * as jobs from "./jobs";
import * as daily from "./daily";
import * as post from "./post";
import * as admin from "./admin";
import * as aritable from "./airtable";
import * as metric from "./metric";
import * as course from "./course";
import { uploadImage } from "./upload";

export default {
	metric,
	course,

	// Admin
	...admin,

	// Master Data
	...master,

	// Setting
	...setting,

	// Insight
	...insight,

	// Member
	...member,

	// Organization
	...org,

	// Analytic
	...analytic,

	// Jobs
	...jobs,

	// Daily
	...daily,

	// Post
	...post,

	// Airtable
	...aritable,

	// Global API
	uploadImage,
};
