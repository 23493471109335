import { IDetail, ISummary } from "interfaces/voe";
import { utils } from "utils";

export const splitChartLineQuestionnaire = (data: any[]) => {
	const biggestWeek = Math.ceil(utils.lastDate() / 7);
	const temp = Array(biggestWeek).fill(0);
	const weekCount = Array(biggestWeek).fill(0);

	for (const item of data) {
		const week = Math.floor(new Date(item.created_date).getDate() / 7);

		weekCount[week]++;
		temp[week] += Number(item.questionnaire_score.score);
	}

	for (let index = 0; index < biggestWeek; index++) {
		if (temp[index] !== 0) {
			temp[index] = (temp[index] / weekCount[index]).toFixed(2);
		}
	}

	return { values: temp, users: weekCount };
};

export const splitSummaryQuestionnaire = (data: any[], labels: string[]) => {
	const summary: ISummary[] = [];

	for (const label of labels) {
		summary.push({
			title: label,
			values: [0, data.length],
		});
	}

	for (const item of data) {
		const val = item.questionnaire_score.score;

		if (val < 3) {
			summary[0].values[0]++;
		} else if (val <= 4) {
			summary[1].values[0]++;
		} else {
			summary[2].values[0]++;
		}
	}

	for (const item of summary) {
		item.values[1] -= item.values[0];
	}

	return summary;
};
