import React from 'react';
import { Box, Checkbox, IconButton, TableCell, TableRow, Typography } from '@mui/material';
import { Images } from 'constant';
import 'react-loading-skeleton/dist/skeleton.css';

export default function UserTableRows(props: {
	row: any;
	isSelectedRow: boolean;
	handleDelete: () => void;
	labelId: string;
	handleClickFunc: () => void;
	onSendInvitation: () => void;
	onInactive: () => void;
	onSwitch: () => void;
}) {
	const { job, city, country, marital, invitation_code, email, organization_code, role_name, birthyear, status, gender } = props.row;

	let location = '-';
	if (city && country) {
		location = city + ", " + country;
	}

	return (
		<TableRow hover key={ props.labelId } role='checkbox' selected={ props.isSelectedRow } >
			<TableCell padding='checkbox' align='center'>
				<Checkbox
					onClick={ props.handleClickFunc }
					checked={ props.isSelectedRow }
					inputProps={ { 'aria-labelledby': props.labelId } }
					color='primary'
				/>
			</TableCell>

			<TableCell align='left'>
				<Typography variant='subtitle2' noWrap sx={ {
					fontFamily: 'Fonts.interRegular',
					width: '150px !important'
				} }>
					{ email }
				</Typography>
			</TableCell>

			<TableCell align='left'>
				<Typography variant='subtitle2' noWrap sx={ {
					fontFamily: 'Fonts.interRegular',
					width: '100px !important'
				} }>

					{ ((role_name === 'psycholog_org') || (role_name === 'psycholog')) ? <span style={ {
						textTransform: 'uppercase',
						background: '#F3F4F6',
						padding: '4px 12px',
						borderRadius: '10px',
						fontSize: '12px',
						fontWeight: '600'
					} }>Specialist</span> : <span style={ {
						fontSize: '12px',
						fontWeight: '600',
						color: '#FFFFFF',
						textTransform: 'uppercase',
						background: '#292A2E',
						padding: '4px 12px',
						borderRadius: '10px'
					} }>Member</span> }
				</Typography>
			</TableCell>

			<TableCell align='left' sx={ { textTransform: 'capitalize' } }>
				<Typography variant='subtitle2' noWrap sx={ {
					fontFamily: 'Fonts.interRegular',
					width: '100px !important'
				} }>
					{ job }
				</Typography>
			</TableCell>

			<TableCell align='left' sx={ { textTransform: 'capitalize' } }>
				<Typography variant='subtitle2' noWrap sx={ {
					fontFamily: 'Fonts.interRegular',
					width: '100px !important'
				} }>
					{ birthyear }
				</Typography>
			</TableCell>

			<TableCell align='left' sx={ { textTransform: 'capitalize' } }>
				<Typography variant='subtitle2' noWrap sx={ {
					fontFamily: 'Fonts.interRegular',
					width: '100px !important'
				} }>
					{ location }
				</Typography>
			</TableCell>

			<TableCell align='left' sx={ { textTransform: 'capitalize' } }>
				<Typography variant='subtitle2' noWrap sx={ {
					fontFamily: 'Fonts.interRegular',
					width: '100px !important'
				} }>
					{ gender }
				</Typography>
			</TableCell>

			<TableCell align='left' sx={ { textTransform: 'capitalize' } }>
				<Typography variant='subtitle2' noWrap sx={ {
					fontFamily: 'Fonts.interRegular',
					width: '150px !important'
				} }>
					{ marital }
				</Typography>
			</TableCell>

			<TableCell align='left' sx={ { textTransform: 'capitalize' } }>
				<Typography variant='subtitle2' noWrap sx={ {
					fontFamily: 'Fonts.interRegular'
				} }>
					{ invitation_code }
				</Typography>
			</TableCell>

			<TableCell align='left' sx={ { textTransform: 'capitalize' } }>
				<Typography variant='subtitle2' noWrap sx={ {
					fontFamily: 'Fonts.interRegular'
				} }>
					{ organization_code }
				</Typography>
			</TableCell>

			<TableCell align='left' sx={ { textTransform: 'capitalize' } }>
				<Typography variant='subtitle2' noWrap sx={ {
					fontFamily: 'Fonts.interRegular',
					width: '100px !important'
				} }>
					{ status === 'active' ? <span style={ {
						textTransform: 'uppercase',
						background: '#E8F4FB',
						padding: '4px 12px',
						borderRadius: '10px',
						fontSize: '12px',
						color: '#2D9CDB',
						fontWeight: '600'
					} }>Active</span> :
						<span style={ {
							fontSize: '12px',
							fontWeight: '600',
							color: '#FF1F7D',
							textTransform: 'uppercase',
							background: '#FFE9F2',
							padding: '4px 12px',
							borderRadius: '10px'
						} }> { status === 'pending_verification' ? 'EMAIL SENT' : status === 'pending_invitation' ? 'EMAIL UNSENT' : status } </span> }
				</Typography>
			</TableCell>

			<TableCell align='left' sx={ { textTransform: 'capitalize' } }>
				<Box sx={ { width: '150px' } } style={ { display: 'flex' } }>
					<IconButton className='bg-neutral-200' onClick={ props.onSwitch }>
						<img src={ Images.btn_resign_act } alt="" />
					</IconButton>

					<IconButton className='bg-neutral-200' onClick={ props.onSendInvitation }>
						<img src={ Images.btn_send_act } alt="" />
					</IconButton>

					<IconButton className='bg-neutral-200' onClick={ props.onInactive }>
						<img src={ Images.btn_flag_act } alt="" />
					</IconButton>
				</Box>
			</TableCell>

		</TableRow>
	);
}
