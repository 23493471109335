const getYearExperienceList = () => {
	return ["Under 3 Years", "3 to 5 Years", "5 to 10 Years", "Over 10 Years"];
};

const getExpertiseList = () => {
	return [
		"Business",
		"Career",
		"Colleagues",
		"Diversity",
		"Emotions",
		"Family",
		"Friends",
		"Health",
		"Leadership",
		"Management",
		"Money",
		"Personal Growth",
		"Self Love",
		"Significant Other",
	];
};

const getLanguageList = () => {
	return [
		"English",
		"Indonesian",
		"Malaysian",
		"Chinese",
		"Tagalog",
		"Thai",
		"Vietnamese",
	];
};

export default {
	getYearExperienceList,
	getExpertiseList,
	getLanguageList,
};
