import api from "./api";

export const getListSubscription = async (params: {
	search?: string;
	isActive: boolean;
}) => {
	const resp = await api.get("/v1/subscriptions", {
		params: {
			search: params.search,
			is_active: params.isActive,
		},
	});
	return resp.data;
};

export const addSubscription = async (data: any) => {
	const resp = await api.post("/v1/subscriptions", data);
	return resp.data;
};

export const updateSubscription = async (code: string, data: any) => {
	const resp = await api.put(`/v1/subscriptions/${code}`, data);
	return resp.data;
};

export const deleteSubscription = async (code: string) => {
	const resp = await api.delete(`/v1/subscriptions/${code}`);
	return resp.data;
};

export const getListSpecialistRate = async (params: {
	search?: string;
	isActive: boolean;
}) => {
	const resp = await api.get("/v1/specialists/rates", {
		params: {
			search: params.search,
			is_active: params.isActive,
		},
	});
	return resp.data;
};

export const addSpecialistRate = async (data: any) => {
	const resp = await api.post("/v1/specialists/rates", data);
	return resp.data;
};

export const updateSpecialistRate = (code: string, data: any) => {
	return api.put(`/v1/specialists/rates/${code}`, data).then(val => val.data);
};

export const deleteSpecialistRate = async (code: string) => {
	const resp = await api.delete(`/v1/specialists/rates/${code}`);
	return resp.data;
};
