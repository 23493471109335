const industry = (data: any) => {
	const temp = new Set();

	for (const item of data) {
		temp.add(item.industry_name);
	}

	return Array.from(temp) as string[];
};

export default {
	industry,
};
