import React from "react";
import { DialogContent } from "@mui/material";
import { ModalSimple } from "components/Modal/ModalSimple";
import { Endpoints } from "constant";
import dayjs from "dayjs";
import { Button as ButtonStyle } from "screens/style";
import { utils } from "utils";

export default function ModalLink(props: {
  value: any;
  isShow: boolean;
  onHide: () => void;
  onSubmit: (val: string) => void;
}) {
  const value = props.value;

  const onSubmit = () => {
    const lang = utils.getLanguage();
    if (value.status === "waiting_payment") {
      props.onSubmit(createSessionLinkPayment(value, lang));
    } else {
      props.onSubmit(createSessionSuccessPayment(value, lang));
    }
  };

  return (
    <ModalSimple
      hide={props.onHide}
      isShown={props.isShow}
      title=""
      width="480px"
    >
      <DialogContent>
        {value.status === "waiting_payment" ? (
          <WaitingPayment value={value}></WaitingPayment>
        ) : (
          <SuccessPayment value={value}></SuccessPayment>
        )}

        <ButtonStyle
          onClick={onSubmit}
          className="btn-primary"
          title="Copy text"
          style={{ marginTop: "30px" }}
        >
          Copy text
        </ButtonStyle>
      </DialogContent>
    </ModalSimple>
  );
}

function SuccessPayment(props: { value: any }) {
  const value = props.value;
  const lang = utils.getLanguage();

  const getTitle = () => {
    if (lang === "en") {
      return "Consultation Confirmation";
    }

    return "Konfirmasi Konsultasi";
  };

  const getName = () => {
    if (lang === "en") {
      return `Name: ${value.psycholog_username}`;
    }

    return `Nama: ${value.psycholog_username}`;
  };

  const getSession = () => {
    if (lang === "en") {
      return `Session: ${getFormatCall(value.format_call)}`;
    }

    return `Sesi: ${getFormatCall(value.format_call)}`;
  };

  const getDate = () => {
    if (lang === "en") {
      return `Date: ${dayjs(value.date).format("DD MMMM YYYY")}`;
    }

    return `Tanggal: ${dayjs(value.date).format("DD MMMM YYYY")}`;
  };

  const getTime = () => {
    if (lang === "en") {
      return `Time: ${value.time_from}`;
    }

    return `Waktu: ${value.time_from}`;
  };

  const getPromo = () => {
    return `Promo: ${value.promo_code}`;
  };

  const getLinkStr = () => {
    if (lang === "en") {
      return "Use this link to join your session: ";
    }

    return "Gunakan link ini untuk mengikuti sesi kamu: ";
  };

  const getContact = () => {
    if (lang === "en") {
      return `You may contact us by whatsapp at +628885591188 if you need any help. Thank you.`;
    }

    return `Kamu dapat menghubungi kami melalui whatsapp di +628885591188 jika kamu memerlukan bantuan. Terima kasih.`;
  };

  return (
    <div className="leading-6 !font-medium mx-auto w-96 mt-8">
      <div className="font-bold mb-2">{getTitle()}</div>
      <div>{getName()}</div>
      <div>{getSession()}</div>
      <div>{getDate()}</div>
      <div>{getTime()}</div>
      {value.promo_code ? <div>{getPromo()}</div> : null}
      <div className="mt-4">
        {getLinkStr()}
        <a
          className="text-brand-dark"
          href={getLink(value)}
          target="_blank"
          rel="noreferrer"
        >
          {getLink(value)}
        </a>
      </div>
      <div className="mt-4">{getContact()}</div>
    </div>
  );
}

function WaitingPayment(props: { value: any }) {
  const value = props.value;
  const lang = utils.getLanguage();

  const getTitle = () => {
    if (lang === "en") {
      return "Consultation Confirmation";
    }

    return "Konfirmasi Konsultasi";
  };

  const getName = () => {
    if (lang === "en") {
      return `Name: ${value.psycholog_username}`;
    }

    return `Nama: ${value.psycholog_username}`;
  };

  const getSession = () => {
    if (lang === "en") {
      return `Session: ${getFormatCall(value.format_call)}`;
    }

    return `Sesi: ${getFormatCall(value.format_call)}`;
  };

  const getDate = () => {
    if (lang === "en") {
      return `Date: ${dayjs(value.date).format("DD MMMM YYYY")}`;
    }

    return `Tanggal: ${dayjs(value.date).format("DD MMMM YYYY")}`;
  };

  const getTime = () => {
    if (lang === "en") {
      return `Time: ${value.time_from}`;
    }

    return `Waktu: ${value.time_from}`;
  };

  const getPromo = () => {
    return `Promo: ${value.promo_code}`;
  };

  const getLinkStr = () => {
    if (lang === "en") {
      return "Use this link to complete your payment via website: ";
    }

    return "Gunakan link ini untuk menyelesaikan pembayaran kamu melalui website: ";
  };

  const getContact = () => {
    if (lang === "en") {
      return `You may contact us by whatsapp at +628885591188 if you need any help. Thank you.`;
    }

    return `Kamu dapat menghubungi kami melalui whatsapp di +628885591188 jika kamu memerlukan bantuan. Terima kasih.`;
  };

  const getCode = () => {
    if (lang === "en") {
      return "*Code valid for one time use";
    }

    return `*Kode berlaku untuk sekali pakai`;
  };

  const getDownloadPre = () => {
    if (lang === "en") {
      return "Download Maxi app and use referral code";
    }

    return "Unduh aplikasi Maxi dan gunakan kode";
  };

  const getDownloadPost = () => {
    if (lang === "en") {
      return "to get 10,000 Maxi Points to kick off your journey. Give it a try and see how it can help improve your well-being! App link: ";
    }

    return "untuk mendapatkan 10,000 Maxi Points untuk memulai perjalanan kamu.  Ayo mulai mencoba dan lihat bagaimana aplikasi ini dapat meningkatkan wellbeing kamu! Link aplikasi: ";
  };

  return (
    <div className="leading-6 !font-medium mx-auto w-96 mt-8">
      <div className="font-bold mb-2">{getTitle()}</div>
      <div>{getName()}</div>
      <div>{getSession()}</div>
      <div>{getDate()}</div>
      <div>{getTime()}</div>
      {value.promo_code ? <div>{getPromo()}</div> : null}
      <div className="mt-4">
        {getLinkStr()}
        <a
          className="text-brand-dark"
          href={getLink(value)}
          target="_blank"
          rel="noreferrer"
        >
          {getLink(value)}
        </a>
      </div>
      <div className="mt-4">
        <span>
          {getDownloadPre()} &apos;{" "}
          <span className="font-bold">{value.referral_code}</span>&apos;{" "}
          {getDownloadPost()}
        </span>
        <a
          className="text-brand-dark"
          href={`${Endpoints.baseUrlLandingUrl}/download-now`}
          target="_blank"
          rel="noreferrer"
        >{`${Endpoints.baseUrlLandingUrl}/download-link`}</a>
      </div>
      <div className="mt-4">{getCode()}</div>
    </div>
  );
}

function createSessionSuccessPayment(value: any, lang: string) {
  if (lang === "id") {
    return `
Konfirmasi Konsultasi

Nama: ${value.psycholog_username}
Sesi: ${getFormatCall(value.format_call)}
Tanggal: ${dayjs(value.date).format("DD MMMM YYYY")}
Waktu: ${value.time_from}
${value.promo_code ? "Promo: " + value.promo_code : ""}

Gunakan link ini untuk mengikuti sesi kamu: ${getLink(value)}

Kamu dapat menghubungi kami melalui whatsapp di +628885591188 jika kamu memerlukan bantuan. Terima kasih.
        `;
  }

  return `
Consultation Confirmation

Name: ${value.psycholog_username}
Session: ${getFormatCall(value.format_call)}
Date: ${dayjs(value.date).format("DD MMMM YYYY")}
Time: ${value.time_from}
${value.promo_code ? "Promo: " + value.promo_code : ""}

Use this link to join your session: ${getLink(value)}

You may contact us by whatsapp at +628885591188 if you need any help. Thank you.
    `;
}

function createSessionLinkPayment(value: any, lang: string) {
  if (lang === "id") {
    return `
Konfirmasi Konsultasi

Nama: ${value.psycholog_username}
Sesi: ${getFormatCall(value.format_call)}
Tanggal: ${dayjs(value.date).format("DD MMMM YYYY")}
Waktu: ${value.time_from}
${value.promo_code ? "Promo: " + value.promo_code : ""}

Gunakan link ini untuk menyelesaikan pembayaran kamu melalui website: ${getLink(
      value
    )}

Unduh aplikasi Maxi dan gunakan kode '${
      value.referral_code
    }' untuk mendapatkan 10,000 Maxi Points untuk memulai perjalanan kamu.  Ayo mulai mencoba dan lihat bagaimana aplikasi ini dapat meningkatkan wellbeing kamu! Link aplikasi: ${`${Endpoints.baseUrlLandingUrl}/download-now`}

*Kode berlaku untuk sekali pakai`;
  }

  return `
Consultation Confirmation
Name: ${value.psycholog_username}
Session: ${getFormatCall(value.format_call)}
Date: ${dayjs(value.date).format("DD MMMM YYYY")}
Time: ${value.time_from}
${value.promo_code ? "Promo: " + value.promo_code : ""}

Use this link to complete your payment via website: ${getLink(value)}

Download Maxi app and use referral code '${
    value.referral_code
  }' to get 10,000 Maxi Points to kick off your journey. Give it a try and see how it can help improve your well-being! App link: ${`${Endpoints.baseUrlLandingUrl}/download-now`}

*Code valid for one time use`;
}

function getFormatCall(formatCall: string) {
  return formatCall === "video_call"
    ? "60 Min Video Call"
    : formatCall === "voice_call"
    ? "60 Min Voice Call"
    : "15 Min Preview";
}

function getLink(value: any) {
  if (value.reservation_code) {
    if (value.room_link) {
      return value.room_link;
    }

    return `${Endpoints.baseUrlLandingUrl}/booking/reservation/${value.reservation_code}`;
  }

  return `${Endpoints.baseUrlLandingUrl}/booking/${value.psycholog_code}/${
    value.schedule_detail_code
  }?appointmentType=${value.format_call}&promoCode=${value.promo_code ?? ""}`;
}
