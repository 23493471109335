import {
  Box,
  Button,
  Card,
  Container,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import { ModalDelete, ModalResign } from "components/Modal/ModalSimple";
import { SearchStyle } from "components/Panel";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Iconify from "screens/Layout/components/Iconify";
import { addJobImportMember } from "service/jobs";
import { sendInvitations, updateMemberRole } from "service/member";
import {
  addOrganization,
  deleteOrganization,
  updateOrganization,
} from "service/organization";
import ModalImport from "./components/ModalImport";
import ModalMember from "./components/ModalMember";
import ModalModify from "./components/ModalModify";
import TabOrganization from "./components/TabOrganization";
import Appbar from "components/Appbar";

export default function Organization() {
  const [filterSearch, setFilterSearch] = useState("");
  const [filterSales, setFilterSales] = useState("");
  const [forceUpdate, setForceUpdate] = useState(0);

  const [isAddContent, setAddContent] = useState(true);
  const [isShowModalModify, setShowModalModify] = useState(false);
  const [isShowModalDelete, setShowModalDelete] = useState(false);
  const [isShowModalMember, setShowModalMember] = useState(false);
  const [isShowModalImport, setShowModalImport] = useState(false);
  const [isShowModalResign, setShowModalResign] = useState(false);
  const [isShowModalInvitation, setShowModalInvitation] = useState(false);

  const [currentItem, setCurrentItem] = useState<any>({});
  const [currentMember, setCurrentMember] = useState<any>({});
  const [invitations, setInvitations] = useState([]);

  useEffect(() => {
    if (!isShowModalModify) {
      setAddContent(true);

      if (!isShowModalDelete && !isShowModalMember && !isShowModalImport) {
        setCurrentItem({});
      }
    }
  }, [
    isShowModalModify,
    isShowModalDelete,
    isShowModalMember,
    isShowModalImport,
  ]);

  useEffect(() => {
    if (!isShowModalResign) {
      setCurrentMember({});
    }
  }, [isShowModalResign]);

  useEffect(() => {
    if (!isShowModalInvitation) {
      setInvitations([]);
    }
  }, [isShowModalInvitation]);

  const onConfirmDelete = () => {
    deleteOrganization(currentItem.organization_code).then(() => {
      setForceUpdate(Math.random());
      setShowModalDelete(false);
    });
  };

  const onConfirmResign = async () => {
    await updateMemberRole({
      department_code: "",
      is_verified: false,
      member_code: currentMember.member_code,
      organization_code: "",
      role_name: "member",
      status: currentMember.status,
    });

    setShowModalResign(false);
    setCurrentItem({});

    toast.info("data already updated!");
  };

  const onMemberResigns = async (val: any[]) => {
    for (let i = 0; i < val.length; i++) {
      const item = val[i];
      await updateMemberRole(item);
    }

    toast.info("data already updated!");
  };

  const onEdit = (val: any) => {
    setCurrentItem(val);
    setShowModalModify(true);
    setAddContent(false);
  };

  const onDelete = (val: any) => {
    setCurrentItem(val);
    setShowModalDelete(true);
  };

  const onMember = (val: any) => {
    setShowModalMember(true);
    setCurrentItem(val);
  };

  const onImport = (val: any) => {
    setShowModalImport(true);
    setCurrentItem(val);
  };

  const onConfirmImport = async (val: any) => {
    return addJobImportMember({
      file: val,
      organization_code: currentItem.organization_code,
      type: "import-member-org-excel-email-only",
    });
  };

  const onMemberInvite = async (val: any) => {
    await sendInvitations(val);
    toast.success("invitations email already on queue to send");
  };

  const onMemberResign = (val: any) => {
    setCurrentMember(val);
    setShowModalMember(false);
    setShowModalResign(true);
  };

  const onSubmit = async (val: any) => {
    if (isAddContent) {
      const resp = await addOrganization({
        ...val,
        is_active: true,
      });

      setCurrentItem(resp.data);
      setShowModalImport(true);
    } else {
      await updateOrganization({
        ...val,
        code: currentItem.organization_code,
        is_active: true,
      });
    }

    setForceUpdate(Math.random());
    setShowModalModify(false);
  };

  return (
    <>
      <Container maxWidth="lg">
        <Appbar
          onSearch={(val) => setFilterSearch(val)}
          title="Manage Organization"
        ></Appbar>
        <Box
          sx={{
            width: "100%",
            margin: "0",
            paddingBottom: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box></Box>
          <Box
            sx={{
              flex: 3,
              textAlign: "right",
            }}
          >
            <Button
              className="btn-action-filter-dark-w-250"
              onClick={() => setShowModalModify(true)}
              endIcon={<Iconify icon="eva:plus-fill" sx={{}} />}
            >
              Add New Organization
            </Button>
          </Box>
        </Box>

        <Card>
          <TabOrganization
            sales={filterSales}
            search={filterSearch}
            forceUpdate={forceUpdate}
            onEdit={onEdit}
            onDelete={onDelete}
            onMember={onMember}
            onImport={onImport}
          />
        </Card>

        <ModalModify
          hide={() => setShowModalModify(false)}
          isShow={isShowModalModify}
          isAddContent={isAddContent}
          onSubmit={onSubmit}
          // listSales={ listSales }
          // listSubscription={ listSubscription }
          value={currentItem}
        ></ModalModify>

        <ModalDelete
          title="Are you sure want to delete this organization?"
          onClose={() => setShowModalDelete(false)}
          isOpen={isShowModalDelete}
          onDelete={onConfirmDelete}
        ></ModalDelete>

        <ModalResign
          title="Are you sure want to resign this memberl?"
          onClose={() => setShowModalResign(false)}
          isOpen={isShowModalResign}
          onResign={onConfirmResign}
        ></ModalResign>

        <ModalMember
          hide={() => setShowModalMember(false)}
          isShow={isShowModalMember}
          value={currentItem}
          onInvite={onMemberInvite}
          onResigns={onMemberResigns}
          onSingleResign={onMemberResign}
        ></ModalMember>

        <ModalImport
          organization={currentItem}
          onHide={() => setShowModalImport(false)}
          isShow={isShowModalImport}
          onImport={onConfirmImport}
        ></ModalImport>
      </Container>
    </>
  );
}
