import api from "./api";

// Quote //
export const getListDailyQuote = async (params: {
	keyword?: string;
	page?: number;
	limit?: number;
	order?: string;
	suggested_by?: string;
	sort?: string;
	is_active?: boolean;
}) => {
	const resp = await api.get("/v1/daily/quote", {
		params: params,
	});
	return resp.data;
};

export const addDailyQuote = async (body: any) => {
	const resp = await api.post("/v1/daily/quote", body);
	return resp.data;
};

export const updateDailyQuote = async (body: any) => {
	const resp = await api.put(`/v1/daily/quote/${body.id}`, body);
	return resp.data;
};

export const deleteDailyQuote = async (id: any) => {
	const resp = await api.delete(`/v1/daily/quote/${id}`);
	return resp.data;
};

export const repostDailyQuote = async (id: any) => {
	const resp = await api.put(`/v1/daily/quote/repost/${id}`);
	return resp.data;
};

// Wallpaper //
export const getListDailyWallpaper = async (params: {
	keyword?: string;
	page?: number;
	limit?: number;
	order?: string;
	sort?: string;
	is_active?: boolean;
	suggested_by?: string;
}) => {
	const resp = await api.get("/v1/daily/wallpaper", {
		params: params,
	});
	return resp.data;
};

export const addDailyWallpaper = async (body: any) => {
	const resp = await api.post("/v1/daily/wallpaper", body);
	return resp.data;
};

export const updateDailyWallpaper = async (body: any) => {
	const resp = await api.put(`/v1/daily/wallpaper/${body.id}`, body);
	return resp.data;
};

export const deleteDailyWallpaper = async (id: any) => {
	const resp = await api.delete(`/v1/daily/wallpaper/${id}`);
	return resp.data;
};

export const repostDailyWallpaper = async (id: any) => {
	const resp = await api.put(`/v1/daily/wallpaper/repost/${id}`);
	return resp.data;
};

// Post //
export const getListDailyPost = async (params: {
	keyword?: string;
	page?: number;
	limit?: number;
	order?: string;
	sort?: string;
	is_active?: boolean;
	type: string;
	status: string;
	suggested_by?: string;
}) => {
	const resp = await api.get("/v1/daily/post", {
		params: params,
	});
	return resp.data;
};

export const addDailyPost = async (body: any) => {
	const resp = await api.post("/v1/daily/post", body);
	return resp.data;
};

export const repostDailyPost = async (id: any) => {
	const resp = await api.put(`/v1/daily/post/repost/${id}`);
	return resp.data;
};

export const updateDailyPost = async (body: any) => {
	const resp = await api.put(`/v1/daily/post/${body.id}`, body);
	return resp.data;
};

export const deleteDailyPost = async (id: any) => {
	const resp = await api.delete(`/v1/daily/post/${id}`);
	return resp.data;
};

// Insight //
export const getListDailyInsight = async (params: {
	keyword?: string;
	page?: number;
	limit?: number;
	order?: string;
	sort?: string;
	is_active?: boolean;
	status: string;
	suggested_by?: string;
}) => {
	const resp = await api.get("/v1/daily/insight", {
		params: params,
	});
	return resp.data;
};

export const addDailyInsight = async (body: any) => {
	const resp = await api.post("/v1/daily/insight", body);
	return resp.data;
};

export const deleteDailyInsight = async (id: any) => {
	const resp = await api.delete(`/v1/daily/insight/${id}`);
	return resp.data;
};

export const repostDailyInsight = async (id: any) => {
	const resp = await api.put(`/v1/daily/insight/repost/${id}`);
	return resp.data;
};

export const updateDailyInsight = async (body: any) => {
	const resp = await api.put(`/v1/daily/insight/${body.id}`, body);
	return resp.data;
};

// Specialist //
export const getListDailySpecialist = async (params: {
	keyword?: string;
	page?: number;
	limit?: number;
	order?: string;
	sort?: string;
	is_active?: boolean;
	status: string;
	suggested_by?: string;
}) => {
	const resp = await api.get("/v1/daily/specialist", {
		params: params,
	});
	return resp.data;
};

export const addDailySpecialist = async (body: any) => {
	const resp = await api.post("/v1/daily/specialist", body);
	return resp.data;
};

export const deleteDailySpecialist = async (id: any) => {
	const resp = await api.delete(`/v1/daily/specialist/${id}`);
	return resp.data;
};

export const repostDailySpecialist = async (id: any) => {
	const resp = await api.put(`/v1/daily/specialist/repost/${id}`);
	return resp.data;
};

export const updateDailySpecialist = async (body: any) => {
	const resp = await api.put(`/v1/daily/specialist/${body.id}`, body);
	return resp.data;
};

// Emergency //
export const getListDailyEmergency = async (params: {
	keyword?: string;
	page?: number;
	limit?: number;
	order?: string;
	sort?: string;
	is_active?: boolean;
	status: string;
	suggested_by?: string;
}) => {
	const resp = await api.get("/v1/daily/emergency", {
		params: params,
	});
	return resp.data;
};

export const addDailyEmergency = async (body: any) => {
	const resp = await api.post("/v1/daily/emergency", body);
	return resp.data;
};

export const deleteDailyEmergency = async (id: any) => {
	const resp = await api.delete(`/v1/daily/emergency/${id}`);
	return resp.data;
};

export const repostDailyEmergency = async (id: any) => {
	const resp = await api.put(`/v1/daily/emergency/repost/${id}`);
	return resp.data;
};

export const updateDailyEmergency = async (body: any) => {
	const resp = await api.put(`/v1/daily/emergency/${body.id}`, body);
	return resp.data;
};
