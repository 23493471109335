import { DialogContent } from '@mui/material';
import { ModalSimple } from 'components/Modal/ModalSimple';
import React, { useEffect, useState } from 'react';
import { getCounselingByReservation } from 'service/reservation';

export default function ModalPrecounseling(props: {
    reservationCode: string;
    isShow: boolean;
    onHide: () => void;
}) {
    const [answers, setAnswers] = useState<any[]>([]);

    useEffect(() => {
        if (props.isShow) {
            getCounselingByReservation({
                reservationCode: props.reservationCode,
                type: 'pre_counseling',
            }).then(val => setAnswers(val.data.answers ?? []));
        }
    }, [props.isShow, props.reservationCode]);

    return (
        <ModalSimple
            hide={ props.onHide }
            isShown={ props.isShow }
            title='Pre-Counseling Form'
            width='512px'
        >
            <DialogContent>
                {
                    answers.length === 0 ? (
                        <div className='text-center my-12'>No Pre-counseling yet</div>
                    ) : null
                }
                {
                    answers.map(val => {
                        return (
                            <Item
                                title={ val.title }
                                value={ val.answer }
                                key={ val.title }
                            ></Item>
                        );
                    })
                }
            </DialogContent>
        </ModalSimple>
    );
}

function Item(props: {
    title: string;
    value: string;
}) {
    return (
        <div className='mb-6'>
            <div className='text-neutral-secondary font-medium mb-2'>{ props.title }</div>
            <div className='text-neutral-primary font-medium'>{ props.value }</div>
        </div>
    );
}
