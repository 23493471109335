
export default {
	interRegular: 'Inter-Regular',
	interMedium: 'Inter-Medium',
	interSemiBold: 'Inter-SemiBold',
	interBold: 'Inter-Bold',
	interBlack: 'Inter-Black',
	sizes: {
		xl: '56px',
		l: '48px',
		l2: '40px',
		m2: '32px',
		m1: '24px',
		m: '20px',
		s2: '18px',
		s: '16px',
		xs: '14px',
		xs2: '12px',
	},
	weights: {
		wb: 'bold',
		w1: '100',
		w2: '200',
		w3: '300',
		w4: '400',
		w5: '500',
		w6: '600',
		w7: '700',
		w8: '800',
		w9: '900',
	}
};
