import React, { useEffect, useState } from 'react';

import { Box, Button, Card, Container, FormControl, InputAdornment, ListItemText, MenuItem, OutlinedInput, Stack, Tab, Tabs, Typography } from '@mui/material';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import Iconify from 'screens/Layout/components/Iconify';

// component
import TabSales from './components/TabSales';

import { a11yProps, TabPanel } from "components/Panel";
import service from 'service';
import { ContentModal, ContentModalSuccess } from './components/ContentModal';

// eslint-disable-next-line no-undef
const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
	width: 350,
	transition: theme.transitions.create(['box-shadow', 'width'], {
		easing: theme.transitions.easing.easeInOut,
		duration: theme.transitions.duration.shorter,
	}),
	'&.Mui-focused': { width: 400 },
	'& fieldset': {
		borderWidth: '1px !important',
		borderColor: `${theme.palette.grey[500_32]} !important`,
	},
}));

const statusList = [
	{
		value: 'active',
		label: 'Active'
	}, {
		value: 'pending_verification',
		label: 'EMAIL SENT'
	}, {
		value: 'pending_invitation',
		label: 'EMAIL UNSENT'
	},
];

// Multiple Select
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 188,
		},
	},
};

export default function MemberSpecialist() {
	const [labelTab, setLabelTab] = useState('Individual Sales Rep');
	const [valueTab, setValueTab] = useState(0);
	const [isShowAddSales, setShowAddSales] = useState(false);
	const [isShowModalSuccess, setShowModalSuccess] = useState(false);
	const [isAddSales, setAddSales] = useState(true);

	const [filterPage, setFilterPage] = useState(0);
	const [filterLimit, setFilterLimit] = useState(10);
	// const [filterMembership, setFilterMembership] = useState('');
	const [filterStatus, setFilterStatus] = useState('');
	const [filterSearch, setFilterSearch] = useState('');
	const [currentSales, setCurrentSales] = useState<any>({});

	const [salesList, setSalesList] = useState<any[]>([]);
	const [salesPagination, setSalesPagination] = useState<any>([]);

	const onAddSales = () => {
		setShowAddSales(true);
	};

	const onSearch = (val) => {
		setFilterSearch(val);

		setFilterPage(1);
	};

	// Update Data
	useEffect(() => {
		// service.getSettingList({
		// 	group: 'psycholog_level',
		// })
		// 	.then((resp) => {
		// 		setMembershipList(resp.data);
		// 	});

		onRefreshData();
	}, []);

	useEffect(() => {
		onRefreshData();
	}, [filterSearch, filterStatus, filterLimit, filterPage]);

	useEffect(() => {
		if (!isShowAddSales) {
			setAddSales(true);
		}
	}, [isShowAddSales]);

	useEffect(() => {
		if (!isShowAddSales) {
			setCurrentSales({});
		}
	}, [isShowAddSales]);

	const onRefreshData = async () => {
		const resp = await service.getSalesList({
			limit: filterLimit,
			page: filterPage + 1,
			keyword: filterSearch,
		});

		setSalesList(resp.data);
		setSalesPagination(resp.pagination);
		setLabelTab(`Individual Sales Rep (${resp.pagination.count})`);
	};

	const onEdit = (row) => {
		setCurrentSales(row);
		setShowAddSales(true);
		setAddSales(false);
	};

	const onDelete = async (row) => {
		await service.deleteSales({
			sales_code: row.sales_code
		});

		onRefreshData();
	};

	const onSubmit = async (val) => {
		if (isAddSales) {
			await service.addSales(val);
		} else {
			await service.updateSales({
				...val,
				sales_code: currentSales.sales_code
			});
		}

		onRefreshData();

		setShowAddSales(false);
		setShowModalSuccess(true);
	};

	return (
		<Container maxWidth='lg'>
			<Stack direction='row' alignItems='center' justifyContent='space-between' mb={ 4 }>
				<Box>
					<Typography variant='h4' sx={ { fontSize: '24px' } } >
						Manage Sales Rep
					</Typography>
				</Box>

			</Stack>
			<Box sx={ { width: '100%' } }>
				<Box sx={ {
					borderBottom: 1,
					borderColor: 'divider'
				} }>
					<Tabs value={ valueTab } aria-label='basic tabs example'>
						<Tab label={ labelTab } { ...a11yProps(0) } />
					</Tabs>
				</Box>
			</Box>
			<Box sx={ {
				width: '100%',
				margin: '0',
				paddingTop: '20px',
				paddingBottom: '20px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
			} }>

				<Box sx={ { width: '250px' } }>
					<SearchStyle
						placeholder='Search'
						onChange={ e => onSearch(e.target.value) }
						sx={ {
							width: '100% !important',
							height: '40px'
						} }
						startAdornment={
							<InputAdornment position='start'>
								<Iconify icon='eva:search-fill' sx={ {
									color: 'text.disabled',
									width: 25,
									height: 25
								} } />
							</InputAdornment>
						}
					/>
				</Box>
				<Box sx={ {
					flex: 1,
					textAlign: 'right'
				} }>
					<Button className='btn-action-filter-dark-w-250' onClick={ onAddSales } endIcon={ <Iconify icon='eva:plus-fill' sx={ {} } /> }>
						Add New Sales Rep
					</Button>
				</Box>
			</Box>
			<Box sx={ {
				width: '100%',
				marginBottom: '20px'
			} }>
				<FormControl sx={ { m: '10px 0 10px 0px' } }>
					<Select
						displayEmpty
						value={ filterStatus }
						onChange={ (val) => setFilterStatus(val.target.value) }
						input={ <OutlinedInput /> }
						MenuProps={ MenuProps }
						inputProps={ { 'aria-label': 'Without label' } }
						sx={ {
							height: '40px',
							fontSize: '14px',
							fontFamily: 'Inter-Medium'
						} }
					>
						<MenuItem value=''>
							<em>All Status</em>
						</MenuItem>
						{
							statusList.map(fu => (
								<MenuItem key={ fu.value } value={ fu.label }>
									<ListItemText primary={ fu.label } sx={ { fontSize: '14px !important' } } />
								</MenuItem>
							))
						}
					</Select>
				</FormControl>
				{/* <FormControl sx={ { m: '10px 0 10px 20px' } }>
					<Select
						displayEmpty
						value={ filterMembership }
						onChange={ (val) => setFilterMembership(val.target.value) }
						input={ <OutlinedInput /> }
						renderValue={ selected => {
							if ((selected.length === 0) || (selected[0] === '')) {
								return <em>All Membership</em>;
							}

							return selected;
						} }
						MenuProps={ MenuProps }
						inputProps={ { 'aria-label': 'Without label' } }
						sx={ {
							height: '40px',
							fontSize: '14px',
							fontFamily: 'Inter-Medium'
						} }
					>
						<MenuItem value=''>
							<em>All Membership</em>
						</MenuItem>
						{
							membershipList.map(dpr => (
								<MenuItem key={ dpr.set_key } value={ dpr.set_label }>

									<ListItemText primary={ dpr.set_label } sx={ { fontSize: '14px !important' } } />
								</MenuItem>
							))
						}
					</Select>
				</FormControl> */}
			</Box>
			<TabPanel value={ valueTab } index={ 0 } >
				<Card>
					<TabSales
						list={ salesList }
						pagination={ salesPagination }
						onChangeRowsPerPage={ (val) => setFilterLimit(val) }
						onChangePage={ val => setFilterPage(val) }
						onEdit={ (val) => onEdit(val) }
						onDelete={ (val) => onDelete(val) }
					/>
				</Card>
			</TabPanel>

			<ContentModal
				isShow={ isShowAddSales }
				onSubmit={ onSubmit }
				isAddSales={ isAddSales }
				onHide={ () => setShowAddSales(false) }
				value={ currentSales }
			></ContentModal>

			<ContentModalSuccess
				isShow={ isShowModalSuccess }
				onToggle={ () => setShowModalSuccess(false) }
			></ContentModalSuccess>
		</Container>
	);
}
