import { Box, DialogActions, DialogContent, OutlinedInput } from '@mui/material';
import { ModalSimple } from 'components/Modal/ModalSimple';
import React, { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { LabelModal } from 'screens/Login/style';
import { Button as ButtonStyle } from 'screens/style';

export default function ModalModify(props: {
    isAddContent: boolean;
    onSubmit: (val: any) => void;
    hide: () => void;
    isShow: boolean;
    value: any;
}) {
    const { register, formState: { errors }, setValue, reset, handleSubmit } = useForm();

    useEffect(() => {
        if (props.isShow && !props.isAddContent) {
            const value = props.value;

            setValue('hourly_price', value.hourly_price);
            setValue('hourly_fee', value.hourly_fee);
        }
    }, [props.isShow]);

    const onReset = () => {
        reset();
    };

    const onSubmit = (val: any) => {
        props.onSubmit({
            hourly_price: val.hourly_price,
            hourly_fee: val.hourly_fee,
        });
    };

    return (
        <ModalSimple hide={ props.hide } isShown={ props.isShow } title={ `${props.isAddContent ? 'Add Specialist Rate' : 'Edit Specialist Rate'}` } reset={ onReset }>
            <form onSubmit={ handleSubmit(onSubmit) }>
                <DialogContent>
                    <Box sx={ { overFlowY: 'initial !important' } }>
                        <div>
                            <LabelModal style={ {
                                margin: '20px 0 10px 0',
                                display: 'inline-block'
                            } }>Hourly Price</LabelModal>
                            <div>
                                <OutlinedInput type='number'
                                    placeholder='Hourly Price'
                                    fullWidth
                                    error={ errors.hourly_price !== undefined }
                                    { ...register('hourly_price', { required: 'hourly price required!' }) }
                                />
                            </div>

                            <LabelModal style={ {
                                margin: '10px 0 10px 0',
                                display: 'inline-block'
                            } }>Hourly Fee</LabelModal>
                            <div>
                                <OutlinedInput type='number'
                                    placeholder='Hourly Fee'
                                    fullWidth
                                    error={ errors.hourly_fee !== undefined }
                                    { ...register('hourly_fee', { required: 'hourly fee required!' }) }
                                />
                            </div>
                        </div>
                    </Box>
                </DialogContent>
                <DialogActions sx={ { padding: '20px 24px !important' } }>
                    <Box sx={ {
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%'
                    } }>
                        <ButtonStyle type='submit' className='btn-primary' title={ `${props.isAddContent ? 'Add Specialist Rate' : 'Edit Specialist Rate'}` } style={ { marginTop: '30px' } } >
                            { props.isAddContent ? "Add" : "Edit" }
                        </ButtonStyle>
                    </Box>
                </DialogActions>
            </form>
        </ModalSimple>
    );
}
