import React from 'react';
import { Box, Button, TableCell, TableRow, Typography } from '@mui/material';
import { Images } from 'constant';
import numeral from 'numeral';

export default function PromoRow(props: {
    row: any,
    labelId: any,
    onEdit?: () => void,
    onDelete?: () => void;
    onRestore?: () => void;
}) {
    const { promo_code, promo_type, discount_type, value, promo_budget, usage, max_discount, status } = props.row;

    return (
        <TableRow hover key={ props.labelId } role='checkbox' tabIndex={ -1 } >
            <TableCell align='left'>
                <Typography variant='subtitle2' noWrap sx={ {
                    fontFamily: 'Fonts.interRegular',
                    width: '150px !important'
                } }>
                    { promo_code }
                </Typography>
            </TableCell>

            <TableCell align='left' sx={ { textTransform: 'capitalize' } }>
                <Typography variant='subtitle2' noWrap sx={ {
                    fontFamily: 'Fonts.interRegular',
                    width: '150px !important'
                } }>
                    { promo_type ?? '-' }
                </Typography>
            </TableCell>

            <TableCell align='left' sx={ { textTransform: 'capitalize' } }>
                <Typography variant='subtitle2' noWrap sx={ {
                    fontFamily: 'Fonts.interRegular',
                    width: '150px !important'
                } }>
                    { discount_type ?? '-' }
                </Typography>
            </TableCell>

            <TableCell align='left' sx={ { textTransform: 'capitalize' } }>
                <Typography variant='subtitle2' noWrap sx={ {
                    fontFamily: 'Fonts.interRegular',
                    width: '150px !important'
                } }>
                    { discount_type === 'flat' ? `IDR ${numeral(value).format('0,0')}` : `${value}%` }
                </Typography>
            </TableCell>

            <TableCell align='left' sx={ { textTransform: 'capitalize' } }>
                <Typography variant='subtitle2' noWrap sx={ {
                    fontFamily: 'Fonts.interRegular',
                    width: '150px !important'
                } }>
                    { `IDR ${numeral(max_discount).format('0,0')}` }
                </Typography>
            </TableCell>

            <TableCell align='left' sx={ { textTransform: 'capitalize' } }>
                <Typography variant='subtitle2' noWrap sx={ {
                    fontFamily: 'Fonts.interRegular',
                    width: '150px !important'
                } }>
                    { `IDR ${numeral(promo_budget).format('0,0')}` }
                </Typography>
            </TableCell>

            <TableCell align='left' sx={ { textTransform: 'capitalize' } }>
                <Typography variant='subtitle2' noWrap sx={ {
                    fontFamily: 'Fonts.interRegular',
                    width: '150px !important'
                } }>
                    { usage }
                </Typography>
            </TableCell>

            <TableCell align='left' sx={ { textTransform: 'capitalize' } }>
                <Typography variant='subtitle2' noWrap sx={ {
                    fontFamily: 'Fonts.interRegular',
                    width: '100px !important'
                } }>
                    { status === 'active' ? <span style={ {
                        textTransform: 'uppercase',
                        background: '#E8F4FB',
                        padding: '4px 12px',
                        borderRadius: '10px',
                        fontSize: '12px',
                        color: '#2D9CDB',
                        fontWeight: '600'
                    } }>Active</span> :
                        <span style={ {
                            fontSize: '12px',
                            fontWeight: '600',
                            color: '#FF1F7D',
                            textTransform: 'uppercase',
                            background: '#FFE9F2',
                            padding: '4px 12px',
                            borderRadius: '10px'
                        } }> { status } </span> }
                </Typography>
            </TableCell>

            <TableCell align='left' sx={ { textTransform: 'capitalize' } }>
                <Box sx={ { width: '150px', display: 'flex' } }>
                    {
                        props.onEdit ? (
                            <Button onClick={ props.onEdit } sx={ { minWidth: 'auto !important' } }>
                                <img src={ Images.btn_edit } style={ { width: '30px' } } />
                            </Button>
                        ) : null
                    }

                    {
                        props.onDelete ? (
                            <Button onClick={ props.onDelete } sx={ { minWidth: 'auto !important' } }>
                                <img src={ Images.btn_warning } style={ { width: '30px' } } />
                            </Button>
                        ) : null
                    }

                    {
                        props.onRestore ? (
                            <Button onClick={ props.onRestore } sx={ { minWidth: 'auto !important' } }>
                                <img src={ Images.btn_repost_act } style={ { width: '30px' } } />
                            </Button>
                        ) : null
                    }
                </Box>
            </TableCell>

        </TableRow>
    );
}
