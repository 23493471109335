import { Box, Button, Container, FormControl, InputAdornment, MenuItem, OutlinedInput, Stack, Typography } from '@mui/material';
import Select from '@mui/material/Select';
import { SearchStyle } from 'components/Panel';
import Images from 'constant/Images';
import React, { useState } from 'react';
import Iconify from 'screens/Layout/components/Iconify';
import TabOrganization from './components/TabOrganization';
import ModalInvoice from './components/ModalInvoice';

export default function DailyWallpaper() {
	// Filter
	const [filterSearch, setFilterSearch] = useState('');
	const [filterOrganization, setFilterOrganization] = useState('');
	const [filterStatus, setFilterStatus] = useState('');

	// Modal
	const [activedForceUpdate, setActivedForceUpdate] = useState(0);
	const [isShowInvoice, setShowInvoice] = useState(false);

	return (
		<>
			<Container maxWidth='lg'>
				<Stack direction='row' alignItems='center' justifyContent='space-between' mb={ 4 }>
					<Box>
						<Typography variant='h4' sx={ { fontSize: '24px' } } >
							Organisation Invoice
						</Typography>
					</Box>
				</Stack>
				<Box sx={ { width: '100%', display: 'flex', justifyContent: 'space-between' } }>
					<SearchStyle
						onChange={ e => setFilterSearch(e.target.value) }
						placeholder='Search'
						startAdornment={
							<InputAdornment position='start'>
								<Iconify icon='eva:search-fill' sx={ {
									color: 'text.disabled',
									width: 25,
									height: 25
								} } />
							</InputAdornment>
						}
					/>
					<Box sx={ { display: 'flex' } }>
						<Button
							className='btn-action-filter-light'
							sx={ { width: '200px' } } endIcon={ <img src={ Images.btn_send } style={ { width: '24px' } } /> }
						>
							Email Reminder
						</Button>
						<Button
							className='btn-action-filter-light'
							sx={ { width: '200px' } } endIcon={ <img src={ Images.btn_import } style={ { width: '24px' } } /> }
						>
							Download Invoice
						</Button>
					</Box>
				</Box>
				<Box sx={ {
					width: '100%',
					margin: '0',
					paddingTop: '20px',
					paddingBottom: '20px',
					display: 'flex',
					alignItems: 'center',
				} }>

					<FormControl sx={ {
						m: 0,
						width: 188
					} }>
						<Select
							displayEmpty
							value={ filterOrganization }
							onChange={ e => setFilterOrganization(e.target.value) }
							input={ <OutlinedInput /> }
							inputProps={ { 'aria-label': 'Without label' } }
							sx={ { height: '40px', fontFamily: 'Inter-Medium', fontSize: '14px' } }
						>
							<MenuItem value=''>
								<em>All Organisation</em>
							</MenuItem>
						</Select>
					</FormControl>

					<FormControl sx={ {
						m: 1,
						width: 238
					} }>
						<Select
							displayEmpty
							value={ filterStatus }
							onChange={ e => setFilterStatus(e.target.value) }
							input={ <OutlinedInput /> }
							inputProps={ { 'aria-label': 'Without label' } }
							sx={ { height: '40px', fontSize: '14px', fontFamily: 'Inter-Medium' } }
						>
							<MenuItem value=''>
								<em>All Status</em>
							</MenuItem>
						</Select>
					</FormControl>
				</Box>

				<TabOrganization
					search={ filterSearch }
					reviewedBy={ filterStatus }
					chapter={ filterOrganization }
					forceUpdate={ activedForceUpdate }
					onInvoice={ () => setShowInvoice(true) }
				></TabOrganization>

				<ModalInvoice
					isShow={ isShowInvoice }
					onHide={ () => setShowInvoice(false) }
				></ModalInvoice>
			</Container>
		</>
	);
}
