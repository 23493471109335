import { Common, Auth } from "interfaces";
import { apiService } from "helpers";

import { AuthActionTypes } from "./actionTypes";
import { Endpoints } from "constant";
import { dispatch } from "store";
import { LocalStorage } from "helpers";

export const authProgress = (
	type: AuthActionTypes,
	payload?: Auth.AuthPayload
) => {
	dispatch({
		type,
		payload,
	});
};

export const processLogin = (params?: Common.RequestData) => {
	return new Promise<void>(async (resolve, reject) => {
		authProgress(AuthActionTypes.LOGIN_LOADING);

		const response: Common.ApiResponse<Auth.Detail[]> = await apiService(
			Endpoints.adminLogin,
			{
				method: "POST",
				body: params?.body,
			}
		);
		const { data, error, object } = response;
		if (data) {
			// if success
			authProgress(AuthActionTypes.LOGIN_SUCCESS, {
				listData: data,
				dataToken: object.data.token,
			});
			LocalStorage.setTokenUser(object.data.token);
			LocalStorage.setNameUser(object.data.name);

			LocalStorage.setLastRefreshDate(new Date().toISOString());
			resolve();
		} else {
			// you can put toast here
			authProgress(AuthActionTypes.LOGIN_ERROR, { error: error });
			reject(error);
		}
	});
};
