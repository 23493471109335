import React from 'react';
import { ChartOptions } from 'chart.js';
import { Colors } from 'constant';
import { Doughnut } from 'react-chartjs-2';

export default function DoughnutChart(props: { colors?: string[], values: number[], labels: string[], showLegend?: boolean, children?: any, showPercentage?: boolean; }) {
    const isShowPercentage = props.showPercentage ?? false;
    const isShowLegend = props.showLegend ?? true;
    const count = props.values.reduce((prev, cur) => prev + cur);
    const options: ChartOptions<"doughnut"> = {
        responsive: true,
        plugins: {
            legend: {
                display: isShowLegend,
                position: "right",
                maxHeight: 14,
                labels: {
                    boxWidth: 14,
                    boxHeight: 14,
                    usePointStyle: true,
                },
            },
            datalabels: {
                display: isShowPercentage,
                color: "#000",
                font: {
                    weight: "bold",
                    size: 12,
                },
                formatter: (val) => {
                    const percentage = Math.round(val / count * 100);
                    if (percentage < 5) {
                        return "";
                    }

                    return `${percentage}%`;
                }
            }
        },
    };

    let colors: string[] = [];
    if (props.colors == null) {
        for (let i = 0; i < props.values.length; i++) {
            colors.push(Colors.chart.getColor(i));
        }
    } else {
        colors = props.colors;
    }

    const data: any = {
        labels: props.labels,
        datasets: [{
            label: 'Analytic',
            data: props.values,
            backgroundColor: colors,
            hoverOffset: 4,
        }],
    };

    return (
        <Doughnut height={ 360 } width={ 360 } options={ options } data={ data } />
    );
}
