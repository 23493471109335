import React, { useEffect, useState } from 'react';
import { Card, FormControl, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';
import { Colors, Endpoints, Mood } from 'constant';
import { getMonths } from 'constant/Date';
import service from 'service';

export default function AnonymousAnalytic(props: {
	orgCode: string, departCode: string, industryName: string,
	monthValue: number, yearValue: number;
}) {
	return (
		<>
			<Card className='mb-8'>
				<div className='bg-slate-100 py-4 pl-4 flex justify-between'>
					<div className='font-bold text-xl pt-2'>All Moods</div>
				</div>
				<div className='px-7 pb-6'>
					{
						Mood.listMood.map((val, i) => {
							return (
								<Content { ...props } monthValue={ props.monthValue } yearValue={ props.yearValue } mood={ val } color={ Colors.chart.getColor(i) } key={ `container-${i}` }></Content>
							);
						})
					}
				</div>
			</Card>
		</>
	);
}

function Content(props: { color: string, monthValue: number, yearValue: number, mood: string, orgCode: string, departCode: string, industryName: string; }) {
	const [items, setItems] = useState<any[]>([]);
	const [count, setCount] = useState(0);
	const [hide, setHide] = useState(true);
	const isSLice = hide && items.length > 8;
	const arr = isSLice ? items.slice(0, 8) : items;

	const init = async (limit = 8) => {
		const respData = await service.getAnonymousList({
			departCode: props.departCode,
			industry: props.industryName,
			limit: limit,
			month: props.monthValue,
			year: props.yearValue,
			moodValue: props.mood,
			orgCode: props.orgCode,
		});
		const data = respData.data;
		const pagination = respData.pagination;

		if (data == null || data.length == 0) {
			setItems([]);
			setCount(0);
		} else {
			setItems(data);
			setCount(pagination.count);
		}
	};

	useEffect(() => {
		init();
	}, [props]);

	const onChangeHide = async () => {
		if (count > items.length && hide) {
			await init(count);
		}

		setHide(!hide);
	};

	return (
		<>
			{
				count > 0 ?
					<>
						<div className='mt-10'>
							<div className='flex mb-7'>
								<div className='w-4 h-4 rounded-full mt-1 mr-3' style={ { backgroundColor: props.color } }></div>
								<div className='text-xl'>{ props.mood }</div>
							</div>
							<div className='flex flex-wrap gap-5'>
								{
									arr.map((item, i) => {
										return (
											<div key={ `content-${i}` }>
												<ContentItem date={ item.created_date } message={ item.feedback_content }></ContentItem>
											</div>
										);
									})
								}
							</div>
							{
								count > 8 ?
									<div onClick={ onChangeHide } className='text-center cursor-pointer text-blue-500 my-10'>{ hide ? "See More" : "See Less" }</div>
									:
									<></>
							}
						</div>
					</>
					: <></>
			}</>
	);
}

function ContentItem(props: { message: string, date: string; }) {
	const message = props.message;
	const isExceeded = message.length > 92;
	const [isReadMore, setReadMore] = useState(false);

	return (
		<div className='w-64 bg-slate-50 p-5 text-sm rounded'>
			{
				props.message.length == 0 ?
					<div className='mb-5 text-slate-400 text-center'>~ No Message ~</div>
					:
					<div className='mb-5'>
						<span>
							{
								isExceeded ?
									(
										isReadMore ?
											message
											:
											message.substring(0, 92) + " ..."
									)
									:
									message
							}
						</span>
						{
							isExceeded ?
								<span className='font-semibold text-slate-500 pl-6 cursor-pointer'
									onClick={ () => setReadMore(!isReadMore) }
								>{ isReadMore ? "Read Less" : "Read More" }</span>
								:
								<></>
						}
					</div>
			}
			<div className='opacity-50'>{ props.date }</div>
		</div >
	);
}
