import { Posts } from 'interfaces';

import { PostsActionTypes } from './actionTypes';

const initialState: Posts.State = {
	list: [],
	pagination: [],
	error: '',
	loadingGet: false,
	loadingAdd: false,
	loadingDelete: false,
	loadingUpdate: false
};

const setPosts = (state: Posts.State, payload:Posts.PostsPayload) => ({
	...state,
	list: payload.listData as Posts.Detail[],
	pagination: payload.pagination as Posts.Pagination[],
	loadingGet: false
});


const setPostsByCode = (state: Posts.State, payload:Posts.PostsPayload) => ({
	...state,
	listByCode: payload.listData as Posts.Detail[],
	pagination: payload.pagination as Posts.Pagination[],
	loadingGet: false
});

const addPosts = (state: Posts.State, payload:Posts.PostsPayload) => ({
	...state,
	list: state.list.concat(payload.data as Posts.Detail),
	loadingAdd: false
});

const reqPosts = (state: Posts.State, type: PostsActionTypes) => ({
	...state,
	loadingGet: type === PostsActionTypes.GET_POSTS_LIST_LOADING,
	loadingUpdate: false
});

const rejectPosts = (state: Posts.State) => ({
	...state,
	loadingGet: false,
	loadingAdd: false,
	loadingDelete: false,
	loadingUpdate: false,
});

const postsReducer = (state:Posts.State = initialState, action: {type: PostsActionTypes; payload: Posts.PostsPayload;}) => {
	switch (action.type) {
		case PostsActionTypes.GET_POSTS_LIST_SUCCESS:
			return setPosts(state, action.payload);
			
		case PostsActionTypes.GET_POSTS_BY_CODE_LIST_SUCCESS:
			return setPostsByCode(state, action.payload);

		case PostsActionTypes.GET_POSTS_LIST_LOADING,
		PostsActionTypes.GET_POSTS_BY_CODE_LIST_LOADING:
			return reqPosts(state, action.type);

		case PostsActionTypes.GET_POSTS_LIST_ERROR,
		PostsActionTypes.GET_POSTS_BY_CODE_LIST_ERROR:
			return rejectPosts(state);
		default:
			return state;
	}
};

export default postsReducer;
