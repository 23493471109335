import {
	Box,
	Table,
	TableBody,
	TableContainer,
	TablePagination
} from '@mui/material';
import { TableEmptyRows, TableHeadCustom } from 'components/Table';
import useTable from 'components/Table/useTable';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import UserRow from 'sections/@dashboard/member/CUserTabComponents';

const TABLE_HEAD = [
	{
		id: '',
		type: 'checkbox'
	},
	{
		id: 'email',
		label: 'MAIN EMAIL',
		alignRight: false
	},
	{
		id: 'membership',
		label: 'MEMBERSHIP'
	},
	{
		id: 'job_title',
		label: 'JOB TITLE'
	},
	{
		id: 'birthyear',
		label: 'BIRTHYEAR'
	},
	{
		id: 'location',
		label: 'LOCATION'
	},
	{
		id: 'gender',
		label: 'GENDER'
	},
	{
		id: 'marital',
		label: 'MARITAL STATUS'
	},
	{
		id: 'invitation_code',
		label: 'INVITATION CODE'
	},
	{
		id: 'unit_id',
		label: 'ORGANIZATION ID',
		alignRight: false
	},
	{
		id: 'status',
		label: 'STATUS',
		alignRight: false
	},
	{ id: 'act' },
];

CUserTab.propTypes = {
	list: PropTypes.object,
	pagination: PropTypes.object,
	tempSelected: PropTypes.object,
	getAnyPage: PropTypes.func,
	getChangeRows: PropTypes.func,
	onEdit: PropTypes.func,
	loadDataPagi: PropTypes.func,
	countSelect: PropTypes.func,
	tempoSel: PropTypes.func,
};
export default function CUserTab(props: {
	list;
	pagination;
	tempSelected;
	tempoSel;
	countSelect;
	getAnyPage;
	getChangeRows;
	handleDelete;
	onEdit;
	onSendInvitation: (val: any[]) => void;
}) {
	const [order, setOrder] = useState('');
	const [orderBy, setOrderBy] = useState('');
	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const [selected, setSelected] = useState<any[]>([]);
	const { dense, onSort } = useTable();

	const handleSelectAllClick = event => {
		if (props.tempSelected.length > 1) {
			props.tempoSel([]);
		} else {
			if (event.target.checked) {
				const newSelecteds = props.list.map(n => n.invitation_email);

				setSelected(newSelecteds);
				props.countSelect(newSelecteds);
				props.tempoSel(props.list);
				return;
			}
		}

		setSelected([]);
	};

	const onSwitch = (val: any) => {
		props.onEdit('edit', {
			member_code: val.member_code,
			organization_code: val.organization_code,
			department_code: val.department_code,
			status: val.status,
			role_name: 'member',
			is_verified: val.is_verified,
		});
	};

	const onInactive = (val: any) => {
		props.onEdit('edit', {
			member_code: val.member_code,
			organization_code: val.organization_code,
			department_code: val.department_code,
			status: 'inactive',
			role_name: val.role_name,
			is_verified: val.is_verified,
		});
	};

	const dataFiltered = applySortFilter({
		list: props.list,
		comparator: getComparator(order, orderBy),
	});

	const denseHeight = dense ? 52 : 72;

	function findSelected(array, member_code) {
		return array.find(element => {
			return element.member_code === member_code;
		});
	}

	const handleClick = (row: any) => {
		const selectedIndex = findSelected(props.tempSelected, row.member_code);
		const newSelected: any[] = [];

		let newSelectedHer: any[] = [];
		newSelected.push(row);

		if (selectedIndex === undefined) {
			newSelectedHer = newSelected.concat(props.tempSelected);
		} else {
			newSelectedHer = props.tempSelected.filter(item => item.member_code !== row.member_code);
		}

		setSelected(newSelectedHer);
		props.countSelect(newSelectedHer);
		props.tempoSel(newSelectedHer);
	};

	const onSendSingleInvitation = (val: any) => {
		props.onSendInvitation([
			{
				invitation_email: val.invitation_email,
				invitation_code: val.invitation_code,
				organization_name: val.organization_name,
			}
		]);
	};

	return (
		<div>
			<TableContainer sx={ {
				minWidth: 800,
				position: 'relative'
			} }>

				<Table size={ dense ? 'small' : 'medium' }>
					<TableHeadCustom
						order={ order }
						checkSelected={ props.tempSelected }
						orderBy={ orderBy }
						onRequestSort={ handleRequestSort }
						headLabel={ TABLE_HEAD }
						rowCount={ props.list.length }
						onSelectAllRows={ handleSelectAllClick }
						numSelected={ selected.length }
						onSort={ onSort }
					/>

					<TableBody>
						{
							dataFiltered.map((row, index) => {
								const found = findSelected(props.tempSelected, row.member_code);
								const labelIdx = `enhanced-table-checkbox-${index}`;

								return (
									<UserRow
										handleClickFunc={ () => handleClick(row) }
										key={ row.member_code }
										isSelectedRow={ !!found }
										row={ row }
										labelId={ labelIdx }
										handleDelete={ () => props.handleDelete(row.member_code, row.content) }
										onSwitch={ () => onSwitch(row) }
										onInactive={ () => onInactive(row) }
										onSendInvitation={ () => onSendSingleInvitation(row) }
									/>
								);
							})
						}

						<TableEmptyRows height={ denseHeight } emptyRows={ dataFiltered.length } />

					</TableBody>
				</Table>
			</TableContainer>
			<Box sx={ { position: 'relative' } }>
				<TablePagination
					rowsPerPageOptions={ [10, 25, 100] }
					component='div'
					labelRowsPerPage='Items per page: '
					count={ props.pagination[0]?.count || 0 }
					rowsPerPage={ props.pagination[0]?.limit || 10 }
					page={ props.pagination[0]?.page - 1 || 0 }
					onPageChange={ (e, pg) => props.getAnyPage(pg) }
					onRowsPerPageChange={ props.getChangeRows }
				/>

			</Box>
		</div>
	);
}

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}
function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter({ list, comparator }) {

	const stabilizedThis = list.map((el, index) => [el, index]);

	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});

	list = stabilizedThis.map(el => el[0]);

	return list;
}