import { Endpoints } from "constant";
import api from "./api";

export const getListPost = async (params: {
	post_type: string;
	status: string;
	page?: number;
	limit?: number;
	is_compact: boolean;
	topcode?: string;
}) => {
	const resp = await api.get(Endpoints.posts, {
		params: params,
	});
	return resp.data;
};

export const deletePost = async (params: { postCode: string }) => {
	const resp = await api.delete(`/v1/posts/${params.postCode}`);
	return resp.data;
};

export const editPost = async (params: {
	post_code: string;
	topic_code: string;
	post_type: string;
	content: string;
	bg_image_url?: string;
	status: string;
	published_date: string;
	insights?: any[];
	polls_descriptions?: any[];
}) => {
	const resp = await api.put(`/v1/posts/${params.post_code}`, params);
	return resp.data;
};

export const repostPost = async (params: {
	post_code: string;
	published_date: string;
}) => {
	const resp = await api.put(`/v1/posts/repost/${params.post_code}`, params);
	return resp.data;
};

export const boostPost = async (params: { post_code: string }) => {
	const resp = await api.put(`/v1/posts/boost/${params.post_code}`, params);
	return resp.data;
};

export const getPostTranslation = async (params: {
	post_code: string;
	language: string;
}) => {
	const resp = await api.get(
		`/v1/posts/translation/${params.post_code}/${params.language}`
	);

	return resp.data;
};

export const addPostTranslation = async (params: {
	post_code: string;
	post_type: string;
	content: string;
	bg_image_url?: string;
	language: string;
}) => {
	const resp = await api.post(
		`/v1/posts/translation/${params.post_code}`,
		params
	);
	return resp.data;
};

export const updatePostTranslation = async (params: {
	post_code: string;
	post_type: string;
	content: string;
	bg_image_url?: string;
	language: string;
}) => {
	const resp = await api.put(
		`/v1/posts/translation/${params.post_code}`,
		params
	);
	return resp.data;
};
