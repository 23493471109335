import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  InputAdornment,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ModalSimple } from "components/Modal/ModalSimple";
import { Colors, Endpoints, Fonts, Images, Member } from "constant";
import { Add } from "iconsax-react";
import { IChangeCallback, IVoidCallback } from "interfaces/callback";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { LabelModal } from "screens/Login/style";
import { Button as ButtonStyle } from "screens/style";
import service from "service";
import { getApproachList } from "service/approach";
import { getCredentialList } from "service/credential";
import { getExpertiseList } from "service/expertise";
import { utils } from "utils";
import ContentUniversityModal from "./ContentUniversityModal";
import { Close } from "@mui/icons-material";
import ContentLicenseModal from "./ContentLicenseModal";

export default function ContentModal(props: {
  isEdit: boolean;
  currentItem: any;
  isShow: boolean;
  successType: string;
  listGender: any[];
  listSpecialistTarget: any[];
  listSpecialistTitle: any[];
  listCountry: any[];
  toggle: IVoidCallback;
  onSubmit: IChangeCallback<any, Promise<string | null>>;
}) {
  // Static State
  const languageList = Member.getLanguageList();

  // Dropzone
  const [files, setFiles] = useState<any[]>([]);
  const [startUF, setSUF] = useState(false);
  const [numberProgress, setNumberProgress] = useState(0);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      setSUF(true);
      for (let index = 0; index <= 100; index++) {
        setTimeout(function timer() {
          setNumberProgress(index);
          if (index === 100) {
            setFiles(
              acceptedFiles.map((file) =>
                Object.assign(file, {
                  preview: URL.createObjectURL(file),
                })
              )
            );
            setSUF(false);
            setNumberProgress(0);
          }
        }, index * 10);
      }
    },
  });

  // Form
  const [memberCode, setMemberCode] = useState("");
  const [country, setCountry] = useState("");
  const [roleName, setRoleName] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [status, setStatus] = useState("");
  const [profileUrl, setProfileUrl] = useState("");
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const [birthdate, setBirthdate] = useState<Date | null>(null);
  const [yearExperience, setYearExperience] = useState("");
  const [expertises, setExpertises] = useState<string[]>([]);
  const [language, setLanguage] = useState<string[]>([]);
  const [membership, setMembership] = useState("");
  const [biography, setBiography] = useState("");
  // const [specialistFee, setSpecialistFee] = useState("");
  // const [specialistPrice, setSpecialistPrice] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [specialistTitle, setSpecialistTitle] = useState("");

  const [licenses, setLicenses] = useState<any[]>([]);
  const [universities, setUniversities] = useState<any[]>([]);
  const [credentials, setCredentials] = useState<string[]>([]);
  const [targetUsers, setTargetUsers] = useState<string[]>([]);
  const [approaches, setApproaches] = useState<string[]>([]);
  const [registrations, setRegistrations] = useState<string[]>([]);

  const [bankName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountHolder, setAccountHolder] = useState("");

  const [credentialList, setCredentialList] = useState<any[]>([]);
  const [expertiseList, setExpertiseList] = useState<any[]>([]);
  const [membershipList, setMembershipList] = useState<any[]>([]);
  const [experienceList, setExperienceList] = useState<any[]>([]);
  const [approachList, setApproachList] = useState<any[]>([]);
  const [isEnabled, setIsEnabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Universities Modal
  const [isShowUniversity, setShowUniversity] = useState(false);
  const [isShowLicense, setShowLicense] = useState(false);

  const checkFormValid = () => {
    let isVideoUrl = true;
    if (videoUrl.length > 0) {
      if (!videoUrl.startsWith("https://www.youtube.com")) {
        isVideoUrl = false;
      }
    }

    let isBankExist = true;
    if (
      bankName.length > 0 ||
      accountHolder.length > 0 ||
      accountNumber.length > 0
    ) {
      isBankExist =
        bankName.length > 0 &&
        accountHolder.length > 0 &&
        accountNumber.length > 0;
    }

    if (
      fullname.length > 0 &&
      utils.isEmail(email) &&
      phone.length > 0 &&
      gender.length > 0 &&
      (files.length > 0 || profileUrl) &&
      yearExperience.length > 0 &&
      expertises.length > 0 &&
      language.length > 0 &&
      membership.length > 0 &&
      biography.length > 0 &&
      credentials.length > 0 &&
      country.length > 0 &&
      // specialistPrice.length > 0 &&
      // specialistFee.length > 0 &&
      specialistTitle.length > 0 &&
      isVideoUrl &&
      isBankExist
    ) {
      setIsEnabled(true);
    } else {
      setIsEnabled(false);
    }
  };

  useEffect(() => {
    service
      .getSettingList({
        group: "psycholog_level",
      })
      .then((val) => {
        setMembershipList(val.data);
      });

    service
      .getSettingList({
        group: "year_of_experience",
      })
      .then((val) => {
        setExperienceList(val.data);
      });

    getApproachList().then((val) => {
      const items: any[] = [];
      for (const title of val.data) {
        items.push({
          name: title.title,
          isHeader: true,
        });
        for (const item of title.approaches) {
          item.isHeader = false;
          items.push(item);
        }
      }

      setApproachList(items);
    });

    getCredentialList().then((val) => {
      const items: any[] = [];
      for (const title of val.data) {
        items.push({
          name: title.title,
          isHeader: true,
        });
        for (const item of title.credentials) {
          item.isHeader = false;
          items.push(item);
        }
      }

      setCredentialList(items);
    });

    getExpertiseList().then((val) => {
      const items: any[] = [];
      for (const category of val.data) {
        items.push({
          name: utils.removeEmoji(category.category.name),
          isHeader: true,
        });
        for (const item of category.expertises) {
          items.push({
            expertise_code: item.expertise_code,
            name: item.name,
            isHeader: false,
          });
        }
      }

      setExpertiseList(items);
    });
  }, []);

  useEffect(() => {
    checkFormValid();
  }, [
    fullname,
    email,
    phone,
    gender,
    files,
    membership,
    // specialistPrice,
    // specialistFee,
    country,
    language,
    expertises,
    biography,
    videoUrl,
    specialistTitle,
    bankName,
    accountNumber,
    accountHolder,
    credentials,
  ]);

  useEffect(() => {
    if (!props.isShow) {
      setMemberCode("");
      setRoleName("");
      setStatus("");
      setIsVerified(false);
      setProfileUrl("");
      setFullname("");
      setEmail("");
      setPhone("");
      setGender("");
      setFiles([]);
      setBirthdate(null);
      setYearExperience("");
      setExpertises([]);
      setLanguage([]);
      setMembership("");
      setBiography("");
      setCredentials([]);
      // setSpecialistRate('');
      // setSpecialistPrice("");
      // setSpecialistFee("");
      setCountry("");
      // setBirthyear('');

      setBankName("");
      setAccountHolder("");
      setAccountNumber("");

      setIsEnabled(false);
      setErrorMessage("");
      setVideoUrl("");

      setSpecialistTitle("");

      setApproaches([]);
      setRegistrations([]);

      setUniversities([]);
      setLicenses([]);
    } else {
      const item = props.currentItem;
      const expertise: string[] = [];
      const targetUsers: string[] = [];
      const approach: string[] = [];
      const registration: string[] = [];
      const credential: string[] = [];

      for (const val of item.expertises ?? []) {
        expertise.push(val.expertise_code);
      }

      for (const val of item.target_users ?? []) {
        targetUsers.push(val.target_code);
      }

      for (const val of item.approaches ?? []) {
        for (const item of approachList) {
          if (item.approach_code === val.approach_code) {
            approach.push(val.approach_code);
            break;
          }
        }
      }

      for (const val of item.registrations ?? []) {
        registration.push(val.registration_code);
      }

      for (const val of item.credentials ?? []) {
        credential.push(val.credential_code);
      }

      setMemberCode(item.member_code ?? "");
      setRoleName(item.role_name ?? "");
      setStatus(item.status ?? "");
      setIsVerified(item.is_verified ?? false);
      setProfileUrl(item.profile_url ?? "");
      setFullname(item.username ?? "");
      setEmail(item.email ?? "");
      setPhone(item.phone ?? "");
      setGender(item.gender ?? "");
      setCredentials(credential);
      setFiles([]);
      setBirthdate(new Date(item.birthdate ?? ""));
      // setSpecialistPrice(
      //   item.specialist_rate_hourly_price
      //     ? item.specialist_rate_hourly_price.toString()
      //     : ""
      // );
      // setSpecialistFee(
      //   item.specialist_rate_hourly_fee
      //     ? item.specialist_rate_hourly_fee.toString()
      //     : ""
      // );
      setYearExperience(item.year_of_experience ?? "");

      setLanguage(item.language ?? []);
      setMembership(item.membership_name ?? "");
      setBiography(item.bio ?? "");
      setCountry(item.country ?? "");
      setVideoUrl(item.introduction_video_url ?? "");
      setSpecialistTitle(item.specialist_title_code ?? "");

      setUniversities(item.universities ?? []);
      setLicenses(item.licenses ?? []);

      setTargetUsers(targetUsers);
      setExpertises(expertise);
      setApproaches(approach);
      setRegistrations(registration);

      setBankName(item.bank_name ?? "");
      setAccountHolder(item.account_holder ?? "");
      setAccountNumber(item.account_number ?? "");

      checkFormValid();
    }
  }, [props.isShow]);

  const onChangeExpertise = (val: string | string[]) => {
    if (typeof val === "string") {
      setExpertises(val.split(","));
    } else {
      setExpertises(val);
    }
  };

  const onChangeApproaches = (val: string | string[]) => {
    if (typeof val === "string") {
      setApproaches(val.split(","));
    } else {
      setApproaches(val);
    }
  };

  const onChangeTargetUsers = (val: string | string[]) => {
    if (typeof val === "string") {
      setTargetUsers(val.split(","));
    } else {
      setTargetUsers(val);
    }
  };

  const onChangeLanguage = (val: string | string[]) => {
    if (typeof val === "string") {
      setLanguage(val.split(","));
    } else {
      setLanguage(val);
    }
  };

  const onChangeCredential = (val: string | string[]) => {
    if (typeof val === "string") {
      setCredentials(val.split(","));
    } else {
      setCredentials(val);
    }
  };

  const onAddUniversity = (val: any) => {
    const temp = universities;
    temp.push(val);

    setUniversities(temp);
    setShowUniversity(false);
  };

  const onAddLicense = (val: any) => {
    const temp = licenses;
    temp.push(val);

    setLicenses(temp);
    setShowLicense(false);
  };

  const onUniversityRemoveAt = (i: number) => {
    setUniversities(universities.filter((_, index) => index != i));
  };

  const onLicenseRemoveAt = (i: number) => {
    setLicenses(licenses.filter((_, index) => index != i));
  };

  const onSubmit = async () => {
    setIsEnabled(false);
    const errMsg = await props.onSubmit({
      profile_url: profileUrl,
      member_code: memberCode,
      username: fullname,
      email,
      phone,
      gender,
      files,
      birthdate,
      yearExperience,
      language,
      membership,
      biography,
      roleName: roleName,
      status: status,
      isVerified: isVerified,
      specialistPrice: 0,
      specialistFee: 0,
      country,
      introduction_video_url: videoUrl,
      specialist_title_code: specialistTitle,

      target_users: targetUsers,
      approaches,
      registrations,
      expertises,
      credentials,
      universities,
      licenses,

      bank_name: bankName,
      account_holder: accountHolder,
      account_number: accountNumber,
    });

    if (errMsg !== null) {
      setErrorMessage(errMsg);
      checkFormValid();
    }
  };

  if (props.successType !== null && props.successType !== "") {
    return (
      <ModalSimple isShown={props.isShow} title="" hide={props.toggle}>
        <ContentModalSuccess
          successType={props.successType}
          onToggle={props.toggle}
        ></ContentModalSuccess>
      </ModalSimple>
    );
  }

  return (
    <ModalSimple
      isShown={props.isShow}
      title={props.isEdit ? "Update Specialist" : "Add New Specialist"}
      hide={props.toggle}
    >
      <>
        <DialogContent>
          {/* UNIVERSITIES */}
          <ContentUniversityModal
            isShow={isShowUniversity}
            onHide={() => setShowUniversity(false)}
            onSubmit={onAddUniversity}
          ></ContentUniversityModal>

          <ContentLicenseModal
            isShow={isShowLicense}
            onHide={() => setShowLicense(false)}
            onSubmit={onAddLicense}
          ></ContentLicenseModal>

          {/* CONTENT */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box sx={{ overFlowY: "initial !important" }}>
              <div className="modalBodyC">
                <div className="input-wrapper" style={{ margin: "0 0 8px 0" }}>
                  <div className="schedule-cus" style={{ width: "100%" }}>
                    <div
                      className="date-schedule-cus"
                      style={{ width: "100%" }}
                    >
                      <LabelModal
                        style={{
                          margin: "5px 0 10px 0",
                          display: "inline-block",
                        }}
                      >
                        Avatar Profile*
                      </LabelModal>
                      <div className="input-wrapper form-inline">
                        {files.length > 0 || profileUrl ? (
                          <aside
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                            }}
                          >
                            <Thumbs
                              getInputProps={getInputProps}
                              getRootProps={getRootProps}
                              files={files}
                              imageUrl={profileUrl}
                            ></Thumbs>
                          </aside>
                        ) : (
                          <section
                            style={{
                              width: "55px",
                              height: "55px",
                              borderRadius: "100%",
                            }}
                          >
                            <div
                              {...getRootProps({
                                className: "dropzone dropzone-cus",
                              })}
                              style={{
                                background: "#ffffff",
                                width: "55px",
                                marginTop: "0",
                                borderRadius: "100px",
                                height: "55px",
                                padding: "0",
                                cursor: "pointer",
                              }}
                            >
                              <input {...getInputProps()} />
                              {startUF ? (
                                <>
                                  <div style={{ display: "flex" }}>
                                    <Typography
                                      sx={{
                                        color: "#292A2E",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {numberProgress}%
                                    </Typography>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      fontSize: "20px",
                                    }}
                                  >
                                    +
                                  </div>
                                </>
                              )}
                            </div>
                          </section>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="input-wrapper" style={{ margin: "0 0 8px 0" }}>
                  <div className="schedule-cus" style={{ width: "100%" }}>
                    <div style={{ width: "100%" }}>
                      <LabelModal
                        style={{
                          margin: "5px 0 10px 0",
                          display: "inline-block",
                        }}
                      >
                        Full Name*
                      </LabelModal>
                      <div className="input-wrapper form-inline">
                        <input
                          type="text"
                          placeholder="Full Name"
                          onChange={(val) => setFullname(val.target.value)}
                          value={fullname}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="input-wrapper" style={{ margin: "0 0 8px 0" }}>
                  <div
                    className="schedule-cus"
                    style={{
                      width: "100%",
                      paddingTop: "0",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <LabelModal
                        style={{
                          margin: "0 0 10px 0",
                          display: "inline-block",
                        }}
                      >
                        Email Address*
                      </LabelModal>
                      <div className="input-wrapper form-inline">
                        <input
                          type="text"
                          placeholder="Official Email.."
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="input-wrapper" style={{ margin: "0 0 12px 0" }}>
                  <div style={{ width: "100%" }}>
                    <LabelModal>Membership*</LabelModal>
                    <Select
                      displayEmpty
                      value={membership}
                      onChange={(val) => setMembership(val.target.value)}
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        marginTop: "8px",
                        width: "100%",
                        border: "solid 1px Colors.app.GhostGrey",
                        background: Colors.white.default,
                        borderRadius: "5px",
                        color: Colors.app.black,
                        fontWeight: Fonts.weights.w5,
                        fontFamily: "Fonts.interMedium",
                        fontSize: Fonts.sizes.xs,
                      }}
                    >
                      <MenuItem value={""}>
                        <em className="text-slate-400">Membership</em>
                      </MenuItem>
                      {membershipList.map((item) => (
                        <MenuItem key={item.set_key} value={item.set_key}>
                          <em>{item.set_label}</em>
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="input-wrapper" style={{ margin: "0 0 12px 0" }}>
                  <div style={{ width: "100%" }}>
                    <LabelModal>Title*</LabelModal>
                    <Select
                      displayEmpty
                      value={specialistTitle}
                      onChange={(val) => setSpecialistTitle(val.target.value)}
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        marginTop: "8px",
                        width: "100%",
                        border: "solid 1px Colors.app.GhostGrey",
                        background: Colors.white.default,
                        borderRadius: "5px",
                        color: Colors.app.black,
                        fontWeight: Fonts.weights.w5,
                        fontFamily: "Fonts.interMedium",
                        fontSize: Fonts.sizes.xs,
                      }}
                    >
                      <MenuItem value={""}>
                        <em className="text-slate-400">Title</em>
                      </MenuItem>
                      {props.listSpecialistTitle.map((item) => (
                        <MenuItem
                          key={item.specialist_title_code}
                          value={item.specialist_title_code}
                        >
                          <em>{item.title}</em>
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="input-wrapper" style={{ margin: "0 0 8px 0" }}>
                  <div
                    className="schedule-cus"
                    style={{
                      width: "100%",
                      paddingTop: "0",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <LabelModal
                        style={{
                          margin: "0 0 10px 0",
                          display: "inline-block",
                        }}
                      >
                        Biography*
                      </LabelModal>
                      <div className="input-wrapper form-inline">
                        <input
                          type="text"
                          placeholder="Biography"
                          value={biography}
                          onChange={(val) => setBiography(val.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full mb-2">
                  <LabelModal
                    style={{
                      margin: "0 0 10px 0",
                      display: "block",
                    }}
                  >
                    University
                  </LabelModal>
                  {(universities ?? []).map((val, i) => {
                    return (
                      <div className="flex items-center" key={i}>
                        <div className="form-inline mt-2 flex-grow">
                          <input
                            type="text"
                            placeholder="Biography"
                            value={`${val.graduate_year} - ${val.degree} - ${val.university}`}
                            disabled
                          />
                        </div>
                        <div
                          className="px-2 ml-2 cursor-pointer"
                          onClick={() => onUniversityRemoveAt(i)}
                        >
                          <Close></Close>
                        </div>
                      </div>
                    );
                  })}
                  <div
                    className="text-left text-brand-dark py-3 px-4 bg-blue-background rounded-md cursor-pointer"
                    onClick={() => setShowUniversity(true)}
                  >
                    <div className="flex items-center">
                      <Add size={18} className="mr-2"></Add>
                      <span className="text-xs capitalize">Add University</span>
                    </div>
                  </div>
                </div>

                <div className="w-full mb-2">
                  <LabelModal
                    style={{
                      margin: "0 0 10px 0",
                      display: "block",
                    }}
                  >
                    License
                  </LabelModal>
                  {(licenses ?? []).map((val, i) => {
                    return (
                      <div className="flex items-center" key={i}>
                        <div className="form-inline mt-2 flex-grow">
                          <input
                            type="text"
                            placeholder="Licenses"
                            value={`${val.license_name} ${
                              val.license_number
                                ? "- " + val.license_number
                                : ""
                            }`}
                            disabled
                          />
                        </div>
                        <div
                          className="px-2 ml-2 cursor-pointer"
                          onClick={() => onLicenseRemoveAt(i)}
                        >
                          <Close></Close>
                        </div>
                      </div>
                    );
                  })}
                  <div
                    className="text-left text-brand-dark py-3 px-4 bg-blue-background rounded-md cursor-pointer"
                    onClick={() => setShowLicense(true)}
                  >
                    <div className="flex items-center">
                      <Add size={18} className="mr-2"></Add>
                      <span className="text-xs capitalize">Add License</span>
                    </div>
                  </div>
                </div>

                <div style={{ margin: "0 0 8px 0" }}>
                  <div
                    style={{
                      width: "100%",
                      paddingTop: "0",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <LabelModal
                        style={{
                          margin: "0 0 10px 0",
                          display: "inline-block",
                        }}
                      >
                        Credentials*
                      </LabelModal>
                      <div>
                        <Select
                          displayEmpty
                          multiple
                          inputProps={{ "aria-label": "Without label" }}
                          value={credentials}
                          onChange={(val) =>
                            onChangeCredential(val.target.value)
                          }
                          renderValue={(selected) => {
                            if (selected.length === 0) {
                              return (
                                <em className="text-slate-400">Credentials</em>
                              );
                            }

                            const vals = selected.map((item) => {
                              const idx = credentialList.findIndex((val) => {
                                return val.credential_code === item;
                              });
                              if (idx !== -1) {
                                return credentialList[idx].name;
                              } else {
                                return "";
                              }
                            });

                            return vals.join(", ");
                          }}
                          sx={{
                            width: "100%",
                            border: "solid 1px Colors.app.GhostGrey",
                            background: Colors.white.default,
                            borderRadius: "5px",
                            color: Colors.app.black,
                            fontWeight: Fonts.weights.w5,
                            fontFamily: "Fonts.interMedium",
                            fontSize: Fonts.sizes.xs,
                          }}
                        >
                          {credentialList.map((item) => {
                            if (item.isHeader) {
                              return (
                                <ListSubheader
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    marginLeft: "8px",
                                  }}
                                  key={item.name}
                                >
                                  {item.name}
                                </ListSubheader>
                              );
                            }

                            return (
                              <MenuItem
                                key={item.credential_code}
                                value={item.credential_code}
                              >
                                <Checkbox
                                  color="default"
                                  checked={
                                    credentials.indexOf(item.credential_code) >
                                    -1
                                  }
                                />
                                <ListItemText>{item.name}</ListItemText>
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="input-wrapper" style={{ margin: "0 0 8px 0" }}>
                  <div
                    className="schedule-cus"
                    style={{
                      width: "100%",
                      paddingTop: "0",
                      marginBottom: "8px",
                    }}
                  >
                    <div
                      className="time-schedule-cus"
                      style={{ width: "100%" }}
                    >
                      <LabelModal
                        style={{
                          margin: "5px 0 10px 0",
                          display: "inline-block",
                        }}
                      >
                        Years of Work Experience*
                      </LabelModal>
                      <div className="input-wrapper form-inline">
                        <Select
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          value={yearExperience}
                          onChange={(val) =>
                            setYearExperience(val.target.value)
                          }
                          sx={{
                            width: "100%",
                            border: "solid 1px Colors.app.GhostGrey",
                            background: Colors.white.default,
                            borderRadius: "5px",
                            color: Colors.app.black,
                            fontWeight: Fonts.weights.w5,
                            fontFamily: "Fonts.interMedium",
                            fontSize: Fonts.sizes.xs,
                          }}
                        >
                          <MenuItem value={""}>
                            <em className="text-slate-400">
                              Years of Work Experience
                            </em>
                          </MenuItem>
                          {experienceList.map((item) => (
                            <MenuItem key={item.set_key} value={item.set_key}>
                              <em>{item.set_label}</em>
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className='input-wrapper' style={ { margin: '0 0 8px 0' } }>
                                    <div className='schedule-cus' style={ {
                                        width: '100%',
                                        paddingTop: '0',
                                        marginBottom: '8px'
                                    } }>
                                        <div className='time-schedule-cus' style={ { width: '100%' } }>
                                            <LabelModal style={ {
                                                margin: '5px 0 10px 0',
                                                display: 'inline-block'
                                            } }>Registration*</LabelModal>
                                            <div className='input-wrapper form-inline'>
                                                <Select
                                                    displayEmpty
                                                    multiple
                                                    inputProps={ { 'aria-label': 'Without label' } }
                                                    value={ registrations }
                                                    onChange={ val => onChangeRegistrations(val.target.value) }
                                                    renderValue={ selected => {
                                                        if (selected.length === 0) {
                                                            return (
                                                                <em className='text-slate-400'>Registration</em>
                                                            );
                                                        }

                                                        const vals = selected.map(item => {
                                                            const idx = registrationList.findIndex(val => {
                                                                return val.registration_code === item;
                                                            });
                                                            if (idx !== -1) {
                                                                return registrationList[idx].name;
                                                            } else {
                                                                return '';
                                                            }
                                                        });

                                                        return vals.join(', ');
                                                    } }
                                                    sx={ {
                                                        width: '100%',
                                                        border: 'solid 1px Colors.app.GhostGrey',
                                                        background: Colors.white.default,
                                                        borderRadius: '5px',
                                                        color: Colors.app.black,
                                                        fontWeight: Fonts.weights.w5,
                                                        fontFamily: 'Fonts.interMedium',
                                                        fontSize: Fonts.sizes.xs
                                                    } }
                                                >

                                                    {
                                                        registrationList.map(item => (
                                                            <MenuItem key={ item.registration_code } value={ item.registration_code }>
                                                                <Checkbox color='default' checked={ registrations.indexOf(item.registration_code) > -1 } />
                                                                <ListItemText primary={ item.name } />
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                <div className="input-wrapper" style={{ margin: "0 0 8px 0" }}>
                  <div
                    className="schedule-cus"
                    style={{
                      width: "100%",
                      paddingTop: "0",
                      marginBottom: "8px",
                    }}
                  >
                    <div
                      className="time-schedule-cus"
                      style={{ width: "100%" }}
                    >
                      <LabelModal
                        style={{
                          margin: "5px 0 10px 0",
                          display: "inline-block",
                        }}
                      >
                        Expertise*
                      </LabelModal>
                      <div className="input-wrapper form-inline">
                        <Select
                          displayEmpty
                          multiple
                          inputProps={{ "aria-label": "Without label" }}
                          value={expertises}
                          onChange={(val) =>
                            onChangeExpertise(val.target.value)
                          }
                          renderValue={(selected) => {
                            if (selected.length === 0) {
                              return (
                                <em className="text-slate-400">Expertise</em>
                              );
                            }

                            const vals = selected.map((item) => {
                              const idx = expertiseList.findIndex((val) => {
                                return val.expertise_code === item;
                              });
                              if (idx !== -1) {
                                return expertiseList[idx].name;
                              } else {
                                return "";
                              }
                            });

                            return vals.join(", ");
                          }}
                          sx={{
                            width: "100%",
                            border: "solid 1px Colors.app.GhostGrey",
                            background: Colors.white.default,
                            borderRadius: "5px",
                            color: Colors.app.black,
                            fontWeight: Fonts.weights.w5,
                            fontFamily: "Fonts.interMedium",
                            fontSize: Fonts.sizes.xs,
                          }}
                        >
                          {expertiseList.map((item) => {
                            if (item.isHeader) {
                              return (
                                <ListSubheader
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    marginLeft: "8px",
                                  }}
                                  key={item.name}
                                >
                                  {item.name}
                                </ListSubheader>
                              );
                            }

                            return (
                              <MenuItem
                                key={item.expertise_code}
                                value={item.expertise_code}
                              >
                                <Checkbox
                                  color="default"
                                  checked={
                                    expertises.indexOf(item.expertise_code) > -1
                                  }
                                />
                                <ListItemText primary={item.name} />
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="input-wrapper" style={{ margin: "0 0 8px 0" }}>
                  <div
                    className="schedule-cus"
                    style={{
                      width: "100%",
                      paddingTop: "0",
                      marginBottom: "8px",
                    }}
                  >
                    <div
                      className="time-schedule-cus"
                      style={{ width: "100%" }}
                    >
                      <LabelModal
                        style={{
                          margin: "5px 0 10px 0",
                          display: "inline-block",
                        }}
                      >
                        Approach
                      </LabelModal>
                      <div className="input-wrapper form-inline">
                        <Select
                          displayEmpty
                          multiple
                          inputProps={{ "aria-label": "Without label" }}
                          value={approaches}
                          onChange={(val) =>
                            onChangeApproaches(val.target.value)
                          }
                          renderValue={(selected) => {
                            if (selected.length === 0) {
                              return (
                                <em className="text-slate-400">Approach</em>
                              );
                            }

                            const vals = selected.map((item) => {
                              const idx = approachList.findIndex((val) => {
                                return val.approach_code === item;
                              });
                              if (idx !== -1) {
                                return approachList[idx].name;
                              } else {
                                return "";
                              }
                            });

                            return vals.join(", ");
                          }}
                          sx={{
                            width: "100%",
                            border: "solid 1px Colors.app.GhostGrey",
                            background: Colors.white.default,
                            borderRadius: "5px",
                            color: Colors.app.black,
                            fontWeight: Fonts.weights.w5,
                            fontFamily: "Fonts.interMedium",
                            fontSize: Fonts.sizes.xs,
                          }}
                        >
                          {approachList.map((item) => {
                            if (item.isHeader) {
                              return (
                                <ListSubheader
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    marginLeft: "8px",
                                  }}
                                  key={item.name}
                                >
                                  {item.name}
                                </ListSubheader>
                              );
                            }

                            return (
                              <MenuItem
                                key={item.approach_code}
                                value={item.approach_code}
                              >
                                <Checkbox
                                  color="default"
                                  checked={
                                    approaches.indexOf(item.approach_code) > -1
                                  }
                                />
                                <ListItemText primary={item.name} />
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="input-wrapper" style={{ margin: "0 0 8px 0" }}>
                  <div
                    className="schedule-cus"
                    style={{
                      width: "100%",
                      paddingTop: "0",
                      marginBottom: "8px",
                    }}
                  >
                    <div
                      className="time-schedule-cus"
                      style={{ width: "100%" }}
                    >
                      <LabelModal
                        style={{
                          margin: "5px 0 10px 0",
                          display: "inline-block",
                        }}
                      >
                        Target Client
                      </LabelModal>
                      <div className="input-wrapper form-inline">
                        <Select
                          displayEmpty
                          multiple
                          inputProps={{ "aria-label": "Without label" }}
                          value={targetUsers}
                          onChange={(val) =>
                            onChangeTargetUsers(val.target.value)
                          }
                          renderValue={(selected) => {
                            if (selected.length === 0) {
                              return (
                                <em className="text-slate-400">
                                  Target Client
                                </em>
                              );
                            }

                            const vals = selected.map((item) => {
                              const idx = props.listSpecialistTarget.findIndex(
                                (val) => {
                                  return val.target_code === item;
                                }
                              );
                              if (idx !== -1) {
                                return props.listSpecialistTarget[idx].name;
                              } else {
                                return "";
                              }
                            });

                            return vals.join(", ");
                          }}
                          sx={{
                            width: "100%",
                            border: "solid 1px Colors.app.GhostGrey",
                            background: Colors.white.default,
                            borderRadius: "5px",
                            color: Colors.app.black,
                            fontWeight: Fonts.weights.w5,
                            fontFamily: "Fonts.interMedium",
                            fontSize: Fonts.sizes.xs,
                          }}
                        >
                          {props.listSpecialistTarget.map((item) => (
                            <MenuItem
                              key={item.target_code}
                              value={item.target_code}
                            >
                              <Checkbox
                                color="default"
                                checked={
                                  targetUsers.indexOf(item.target_code) > -1
                                }
                              />
                              <ListItemText primary={item.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="input-wrapper" style={{ margin: "0 0 0 0" }}>
                  <div className="schedule-cus" style={{ width: "100%" }}>
                    <div style={{ width: "100%" }}>
                      <LabelModal
                        style={{
                          margin: "5px 0 10px 0",
                          display: "inline-block",
                        }}
                      >
                        Introduction Video URL
                      </LabelModal>
                      <div className="input-wrapper form-inline">
                        <input
                          type="text"
                          placeholder="Video Url"
                          onChange={(val) => setVideoUrl(val.target.value)}
                          value={videoUrl}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="input-wrapper"
                  style={{ margin: "5px 0 10px 0" }}
                >
                  <div className="schedule-cus" style={{ width: "100%" }}>
                    <div className="date-schedule-cus" style={{ width: "50%" }}>
                      <LabelModal style={{ display: "inline-block" }}>
                        Language*
                      </LabelModal>
                      <Select
                        displayEmpty
                        multiple
                        inputProps={{ "aria-label": "Without label" }}
                        value={language}
                        onChange={(val) => onChangeLanguage(val.target.value)}
                        renderValue={(selected) => {
                          if (selected.length == 0) {
                            return <em className="text-slate-400">Language</em>;
                          }

                          return selected.join(", ");
                        }}
                        sx={{
                          marginTop: "8px",
                          width: "100%",
                          border: "solid 1px Colors.app.GhostGrey",
                          background: Colors.white.default,
                          borderRadius: "5px",
                          color: Colors.app.black,
                          fontWeight: Fonts.weights.w5,
                          fontFamily: "Fonts.interMedium",
                          fontSize: Fonts.sizes.xs,
                        }}
                      >
                        {languageList.map((item) => (
                          <MenuItem key={item} value={item}>
                            <Checkbox
                              color="default"
                              checked={language.indexOf(item) > -1}
                            />
                            <ListItemText primary={item} />
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className="time-schedule-cus" style={{ width: "50%" }}>
                      <LabelModal style={{ display: "inline-block" }}>
                        Country*
                      </LabelModal>
                      <TextField
                        sx={{
                          marginTop: "8px",
                          width: "100%",
                          border: "solid 1px Colors.app.GhostGrey",
                          background: Colors.white.default,
                          borderRadius: "5px",
                          color: Colors.app.black,
                          fontWeight: Fonts.weights.w5,
                          fontFamily: "Fonts.interMedium",
                          fontSize: Fonts.sizes.xs,
                        }}
                        type="text"
                        // pattern='[0-9]*'
                        placeholder="Country"
                        onChange={(e) => setCountry(e.target.value)}
                        value={country}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="input-wrapper"
                  style={{ margin: "5px 0 10px 0" }}
                >
                  <div className="schedule-cus" style={{ width: "100%" }}>
                    <div className="date-schedule-cus" style={{ width: "50%" }}>
                      <LabelModal style={{ display: "inline-block" }}>
                        Gender*
                      </LabelModal>
                      <Select
                        displayEmpty
                        value={gender}
                        onChange={(e) => setGender(e.target.value as string)}
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{
                          marginTop: "8px",
                          width: "100%",
                          border: "solid 1px Colors.app.GhostGrey",
                          background: Colors.white.default,
                          borderRadius: "5px",
                          color: Colors.app.black,
                          fontWeight: Fonts.weights.w5,
                          fontFamily: "Fonts.interMedium",
                          fontSize: Fonts.sizes.xs,
                        }}
                      >
                        <MenuItem value={""}>
                          <em className="text-slate-400">Gender</em>
                        </MenuItem>
                        {props.listGender.map((item) => (
                          <MenuItem key={item.set_key} value={item.set_key}>
                            <em>{item.set_label}</em>
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className="time-schedule-cus" style={{ width: "50%" }}>
                      <LabelModal style={{ display: "inline-block" }}>
                        Phone Number*
                      </LabelModal>
                      <TextField
                        sx={{
                          marginTop: "8px",
                          width: "100%",
                          border: "solid 1px Colors.app.GhostGrey",
                          background: Colors.white.default,
                          borderRadius: "5px",
                          color: Colors.app.black,
                          fontWeight: Fonts.weights.w5,
                          fontFamily: "Fonts.interMedium",
                          fontSize: Fonts.sizes.xs,
                        }}
                        type="text"
                        // pattern='[0-9]*'
                        placeholder="Phone Number.."
                        onChange={(e) => setPhone(e.target.value)}
                        value={phone}
                      />
                    </div>
                  </div>
                </div>

                <div className="input-wrapper" style={{ margin: "0 0 0 0" }}>
                  <div className="schedule-cus" style={{ width: "100%" }}>
                    <div style={{ width: "100%" }}>
                      <LabelModal
                        style={{
                          margin: "5px 0 10px 0",
                          display: "inline-block",
                        }}
                      >
                        Bank Name
                      </LabelModal>
                      <div className="input-wrapper form-inline">
                        <input
                          type="text"
                          placeholder="Bank Name"
                          onChange={(val) => setBankName(val.target.value)}
                          value={bankName}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="input-wrapper" style={{ margin: "0 0 0 0" }}>
                  <div className="schedule-cus" style={{ width: "100%" }}>
                    <div style={{ width: "100%" }}>
                      <LabelModal
                        style={{
                          margin: "5px 0 10px 0",
                          display: "inline-block",
                        }}
                      >
                        Bank Account Number
                      </LabelModal>
                      <div className="input-wrapper form-inline">
                        <input
                          type="text"
                          placeholder="Bank Account Number"
                          onChange={(val) =>
                            setAccountNumber(utils.onlyNumber(val.target.value))
                          }
                          value={accountNumber}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="input-wrapper" style={{ margin: "0 0 0 0" }}>
                  <div className="schedule-cus" style={{ width: "100%" }}>
                    <div style={{ width: "100%" }}>
                      <LabelModal
                        style={{
                          margin: "5px 0 10px 0",
                          display: "inline-block",
                        }}
                      >
                        Bank Account Holder
                      </LabelModal>
                      <div className="input-wrapper form-inline">
                        <input
                          type="text"
                          placeholder="Bank Account Holder"
                          onChange={(val) => setAccountHolder(val.target.value)}
                          value={accountHolder}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "#CC1964",
                  marginBottom: "10px",
                  fontFamily: "Inter-Regular",
                }}
              >
                {errorMessage}
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: "20px 24px !important" }}>
          <ButtonStyle
            className="btn-primary"
            disabled={!isEnabled}
            title="Add New Specialist "
            onClick={onSubmit}
            style={{ marginTop: "30px" }}
          >
            {props.isEdit ? "Update Specialist" : "Add Specialist"}
          </ButtonStyle>
        </DialogActions>
      </>
    </ModalSimple>
  );
}

function ContentModalSuccess(props: {
  successType: string;
  onToggle: IVoidCallback;
}) {
  return (
    <Box margin={4}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={Images.successModal} style={{ width: "133px" }} />
        <LabelModal
          style={{
            fontSize: "18px",
            paddingTop: "30px",
          }}
        >
          {props.successType === "edit" ? "Update" : "Added new"} specialist
          successfully
        </LabelModal>
        {props.successType === "add" ? (
          <Typography
            sx={{
              fontSize: "16px",
              color: "#767E8C",
              marginTop: "10px",
              marginBottom: "30px",
            }}
          >
            Note: Username & Password has been automatically sent
          </Typography>
        ) : (
          <div className="h-8"></div>
        )}
        <Button
          className="btn-action-filter-dark"
          sx={{ width: "100%" }}
          onClick={props.onToggle}
        >
          Done
        </Button>
      </div>
    </Box>
  );
}

function Thumbs(props: {
  files: any[];
  getRootProps: any;
  getInputProps: any;
  imageUrl: string;
}) {
  if (props.files.length === 0 && props.imageUrl) {
    const names = props.imageUrl.split("/");
    const name = names[names.length - 1];

    return (
      <Thumb
        {...props}
        name={name}
        url={Endpoints.baseUrlAws + props.imageUrl}
      ></Thumb>
    );
  }

  const file = props.files[0];

  return <Thumb {...props} name={file.name} url={file.preview} />;
}

function Thumb(props: {
  name: string;
  url: string;
  getRootProps: any;
  getInputProps: any;
}) {
  return (
    <div
      style={{
        marginBottom: 8,
        marginRight: 8,
      }}
      key={props.name}
    >
      <div
        style={{
          display: "flex",
          minWidth: 0,
          overflow: "hidden",
        }}
      >
        <div
          {...props.getRootProps({ className: "dropzone" })}
          style={{
            background: "#ffffff",
            width: "55px !important",
            marginTop: "0 !important",
            borderRadius: "100px",
            height: "55px !important",
            padding: "0 !important",
            cursor: "pointer",
          }}
        >
          <input {...props.getInputProps()} />
          <div
            style={{
              background: `url(${props.url}) #D3D3D3`,
              backgroundSize: "100%",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              width: "55px",
              height: "55px",
              display: "flex",
              alignItems: "center",
              borderRadius: "100px",
              justifyContent: "center",
            }}
          />
        </div>
      </div>
    </div>
  );
}
