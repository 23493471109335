import api from "./api";

export const getSpecialistTitleList = async (params: {
	isActive: boolean;
	lang?: string;
}) => {
	const resp = await api.get("/v1/specialists/titles", {
		params: {
			is_active: params.isActive,
			lang: params.lang,
		},
	});

	return resp.data;
};

export const getSpecialistList = async (params: {
	page: number;
	limit: number;
	status?: string;
	keyword?: string;
	membership?: string;
}) => {
	const resp = await api.get("/v1/specialists", {
		params: {
			page: params.page,
			limit: params.limit,
			sort: "",
			organization_code: "",
			department_name: "",
			membership_name: params.membership,
			status: params.status ?? "active",
			keyword: params.keyword,
		},
	});

	return resp.data;
};

export const getSpecialistListHaveSchedule = async (params: {
	page: number;
	limit: number;
	status?: string;
	keyword?: string;
	membership?: string;
}) => {
	const resp = await api.get("/v1/specialists/have-schedule", {
		params: {
			page: params.page,
			limit: params.limit,
			sort: "",
			organization_code: "",
			department_name: "",
			membership_name: params.membership,
			status: params.status ?? "active",
			keyword: params.keyword,
		},
	});

	return resp.data;
};

export const updateSpecialistStatus = (params: {
	status: string;
	psychologCode: string;
}) => {
	return api
		.put(`/v1/specialists/${params.psychologCode}/status`, {
			status: params.status,
		})
		.then((val) => val.data);
};

export const updateSpecialistNoticePeriod = (params: {
	noticePeriod: number;
	psychologCode: string;
}) => {
	return api.put(`/v1/specialists/${params.psychologCode}/notice-period`, {
		notice_period: params.noticePeriod,
	}).then(val => val.data);
};

export const getSpecialistTransactionList = async (params: {
	page: number;
	limit: number;
	status?: string;
	keyword?: string;
	year?: string;
	month?: string;
}) => {
	const resp = await api.get("/v1/specialists/transactions", {
		params: params,
	});

	return resp.data;
};

export const getSpecialistTransaction = async (params: {
	specialistCode: string;
	year: number;
	month: number;
}) => {
	const resp = await api.get(
		`/v1/specialists/transactions/${params.specialistCode}`,
		{
			params: params,
		}
	);

	return resp.data;
};

export const refreshTransaction = async (params: {
	year: number;
	month: number;
}) => {
	const resp = await api.post("/v1/specialists/transactions/refresh", params);

	return resp.data;
};

export const updateStatusTransaction = async (params: {
	specialistCode: string;
	year: number;
	month: number;
	status: string;
}) => {
	const resp = await api.put(
		`/v1/specialists/transactions/${params.specialistCode}/status`,
		params
	);

	return resp.data;
};

export const invitationSpecialist = async (params: {
	invitations: {
		psycholog_code: string;
	}[]
}) => {
	return api.post(`/v1/specialists/invitations`, params).then(val => val.data);
};
