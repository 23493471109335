import api from "./api";

export const getListExercise = async (params: {
	limit: number;
	page: number;
	offset?: number;
	keyword?: string;
	sort?: string;
	order?: string;
	status?: string;
	reviewed_code?: string;
}) => {
	const resp = await api.get("/v1/exercise", { params: params });
	return resp.data;
};

export const addExercise = async (params: any) => {
	const resp = await api.post("/v1/exercise", params);
	return resp.data;
};

export const updateExercise = async (params: any) => {
	const resp = await api.put(`/v1/exercise/${params.exercise_code}`, params);
	return resp.data;
};

export const deleteExercise = async (params: { exercise_code: string }) => {
	const resp = await api.delete(`/v1/exercise/${params.exercise_code}`);
	return resp.data;
};

export const repostExercise = async (params: { exercise_code: string }) => {
	const resp = await api.put(`/v1/exercise/repost/${params.exercise_code}`);
	return resp.data;
};

export const getExerciseTranslation = async (params: {
	language: string;
	exercise_code: string;
}) => {
	const resp = await api.get(
		`/v1/exercise/translation/${params.exercise_code}/${params.language}`
	);
	return resp.data;
};

export const addExerciseTranslation = async (params: any) => {
	const resp = await api.post(
		`/v1/exercise/translation/${params.exercise_code}`,
		params
	);
	return resp.data;
};

export const getExerciseNarration = async (params: {
	language: string;
	exercise_code: string;
}) => {
	const resp = await api.get(
		`/v1/exercise/narration/${params.exercise_code}/${params.language}`
	);
	return resp.data;
};

export const addExerciseNarration = async (params: any) => {
	const resp = await api.post(
		`/v1/exercise/narration/${params.exercise_code}`,
		params
	);
	return resp.data;
};

export const updateExerciseNarration = async (params: any) => {
	const resp = await api.put(
		`/v1/exercise/narration/${params.exercise_narration_code}`,
		params
	);
	return resp.data;
};
