import React from 'react';
import { DialogContent, DialogActions } from '@mui/material';
import { ModalSimple } from 'components/Modal/ModalSimple';
import { useEffect, useState } from 'react';
import { LabelModal } from 'screens/Login/style';
import { Button as ButtonStyle } from 'screens/style';

export default function ContentUniversityModal(props: {
    isShow: boolean;
    onHide: () => void;
    onSubmit: (val: any) => void;
}) {
    const [graduateYear, setGraduateYear] = useState('');
    const [degree, setDegree] = useState('');
    const [university, setUniversity] = useState('');
    const [isEnabled, setEnabled] = useState(false);

    const checkFormValid = () => {
        if (graduateYear && degree && university) {
            setEnabled(true);
        } else {
            setEnabled(false);
        }
    };

    const onReset = () => {
        setGraduateYear('');
        setDegree('');
        setUniversity('');
    };

    useEffect(() => {
        checkFormValid();
    }, [graduateYear, degree, university]);

    useEffect(() => {
        if (!props.isShow) {
            onReset();
        }
    }, [props.isShow]);

    const onSubmit = () => {
        props.onSubmit({
            degree,
            graduate_year: Number(graduateYear),
            university,
        });
    };

    return (
        <ModalSimple isShown={ props.isShow } title='University' hide={ props.onHide } reset={ onReset }>
            <>
                <DialogContent>
                    <div className='input-wrapper'>
                        <div className='schedule-cus' style={ { width: '100%' } }>
                            <div style={ { width: '100%' } }>
                                <LabelModal style={ {
                                    margin: '5px 0 10px 0',
                                    display: 'inline-block'
                                } }>Graduation Year</LabelModal>
                                <div className='input-wrapper form-inline'>
                                    <input type='number'
                                        placeholder='Graduation Year'
                                        onChange={ (val) => setGraduateYear(val.target.value) }
                                        value={ graduateYear }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='input-wrapper'>
                        <div className='schedule-cus' style={ { width: '100%' } }>
                            <div style={ { width: '100%' } }>
                                <LabelModal style={ {
                                    margin: '5px 0 10px 0',
                                    display: 'inline-block'
                                } }>Degree</LabelModal>
                                <div className='input-wrapper form-inline'>
                                    <input type='text'
                                        placeholder='Degree'
                                        onChange={ (val) => setDegree(val.target.value) }
                                        value={ degree }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='input-wrapper'>
                        <div className='schedule-cus' style={ { width: '100%' } }>
                            <div style={ { width: '100%' } }>
                                <LabelModal style={ {
                                    margin: '5px 0 10px 0',
                                    display: 'inline-block'
                                } }>University</LabelModal>
                                <div className='input-wrapper form-inline'>
                                    <input type='text'
                                        placeholder='University'
                                        onChange={ (val) => setUniversity(val.target.value) }
                                        value={ university }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions sx={ { padding: '20px 24px !important' } }>
                    <ButtonStyle className='btn-primary' disabled={ !isEnabled } title='Add ' onClick={ onSubmit } style={ { marginTop: '30px' } } >
                        Add
                    </ButtonStyle>
                </DialogActions>
            </>
        </ModalSimple>
    );
}