import { Topics } from 'interfaces';

import { TopicsActionTypes } from './actionTypes';

const initialState: Topics.State = {
	topics: [],
	pagination: [],
	error: '',
	loadingGet: false,
	loadingAdd: false,
	loadingDelete: false,
	loadingUpdate: false
};

const setTopics = (state: Topics.State, payload:Topics.TopicsPayload) => ({
	...state,
	topics: payload.listData as Topics.Detail[],
	pagination: payload.pagination as Topics.Pagination[],
	loadingGet: false
});

const reqTopics = (state: Topics.State, type: TopicsActionTypes) => ({
	...state,
	loadingGet: type === TopicsActionTypes.GET_TOPICS_LIST_LOADING,
	loadingUpdate: false
});

const rejectTopics = (state: Topics.State) => ({
	...state,
	loadingGet: false,
	loadingAdd: false,
	loadingDelete: false,
	loadingUpdate: false,
});

const topicsReducer = (state:Topics.State = initialState, action: {type: TopicsActionTypes; payload: Topics.TopicsPayload;}) => {
	switch (action.type) {
		case TopicsActionTypes.GET_TOPICS_LIST_SUCCESS:
			return setTopics(state, action.payload);

		case TopicsActionTypes.GET_TOPICS_LIST_LOADING:
			return reqTopics(state, action.type);

		case TopicsActionTypes.GET_TOPICS_LIST_ERROR:
			return rejectTopics(state);
		default:
			return state;
	}
};

export default topicsReducer;
