import React from "react";
import { ChartData, ChartOptions, Tick, TooltipItem } from 'chart.js';
import { Colors } from 'constant';
import { ILineValues } from 'interfaces/chart';
import { Line } from 'react-chartjs-2';
import { Context } from 'chartjs-plugin-datalabels';

export default function LineChart(props: { tooltipFormatter?: (tooltipItem: TooltipItem<"line">) => string | string[], labelFormatter?: ((value: any, context: Context) => any), values: ILineValues, ticksStep: number, maxY: number, ticksCallback: (val: string | number, i: number, ticks: Tick[]) => string; }) {
    let index = 0;
    let itemsCount = 0;
    const data: ChartData<"line"> = {
        labels: [],
        datasets: []
    };
    for (const label in props.values) {
        if (Object.prototype.hasOwnProperty.call(props.values, label)) {
            const items = props.values[label];
            const color = Colors.chart.getColor(index++);

            itemsCount = items.length;

            data.datasets.push({
                pointHoverRadius: 8,
                pointRadius: 16,
                pointBackgroundColor: "transparent",
                pointHoverBackgroundColor: color,
                pointBorderColor: "transparent",
                label: label,
                data: items,
                fill: false,
                borderColor: color,
                tension: .4,
            });
        }
    }

    const options: ChartOptions<"line"> = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                formatter: props.labelFormatter,
            },
            tooltip: {
                callbacks: {
                    label: props.tooltipFormatter,
                }
            }
        },
        scales: {
            y: {
                min: 0,
                max: props.maxY,
                ticks: {
                    stepSize: props.ticksStep,
                    callback: props.ticksCallback,
                },
            },
            x: {
                grid: {
                    display: false,
                },
            }
        }
    };

    for (let index = 1; index <= itemsCount; index++) {
        data.labels?.push(`W${index}`);
    }

    return (
        <Line options={ options } data={ data }></Line>
    );
}
