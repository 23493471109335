export default {
	chart: {
		colors: [
			"#F29899", // red
			"#F8CE5F", // yellow
			"rgb(221, 143, 25)", // gold
			"#ABCD74", // green
			"#8BD2F0", // blue
			"#75C1D5", // dark blue
		],
		getColor: function (index: number){
			const n = this.colors.length;
			const i = index % n;

			return this.colors[i];
		},
	},
	app: {
		black: 'rgba(41, 42, 46, 1)',
		white: 'rgba(255, 255, 255, 1)',
		GhostGrey: 'rgba(160, 170, 184, 1)',
		SecondaryGrey: 'rgba(118, 126, 140, 1)',
	},
	white: {
		default: 'rgb(255, 255, 255)',
	},
	black: {
		default: 'rgb(38, 38, 38)',
	},
	grey: {
		default: 'rgb(128, 128, 128)',
		light: 'rgb(234, 234, 234)',
		lighter: 'rgb(245, 245, 245)',
		dark: 'rgb(67, 67, 67)'
	},
	red: {
		default: 'rgb(255, 0, 0)',
		light: 'rgb(255, 242, 243)'
	},
	blue: {
		default: 'rgb(26, 66, 120)',
		light: 'rgb(232, 236, 242)',
		dark: 'rgb(58, 91, 135)'
	},
	yellow: {
		default: 'rgb(255, 255, 0)',
	},
	green: {
		default: 'rgb(0, 128, 0)',
		tosca: 'rgb(6, 214, 159)',
		toscaLight: 'rgb(176, 227, 214)'
	},
	orange: {
		default: 'rgb(255, 166, 0)',
		light: 'rgb(239, 233, 231)'
	},
	pink: {
		default: 'rgb(255, 192, 203)',
	},
	gold: {
		default: 'rgb(221, 143, 25)',
	}
};
