import domtoimage from "dom-to-image-more";
import jsPDF from "jspdf";

export default async function (content: HTMLElement, fileName: string) {
	const canvas = await domtoimage.toCanvas(content);
	const imgData = canvas.toDataURL("image/png");
	const width = canvas.width;
	const height = canvas.height;

	const pdf = new jsPDF({
		unit: "px",
		format: [width, height],
		orientation: width < height ? "p" : "l",
	});

	pdf.addImage(imgData, "PNG", 0, 0, width, height);
	pdf.save(`${fileName}.pdf`);
}
