import React, { useEffect, useMemo, useState } from 'react';
import { Box, DialogActions, DialogContent, IconButton, ListItemText, MenuItem, Select, TextareaAutosize, TextField, Typography } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { LabelModal } from 'components/Modal/style';
import { Colors, Config, Endpoints, Fonts, Images } from 'constant';
import { Editor } from '@tinymce/tinymce-react';
import { useDropzone } from 'react-dropzone';
import { ModalSimple } from 'components/Modal/ModalSimple';
import { Button as ButtonStyle } from 'screens/style';
import { IChangeCallback } from 'interfaces/callback';
import Iconify from 'screens/Layout/components/Iconify';

export default function ContentModal(props: {
    isDefaultlanguage: boolean,
    isAddContent: boolean, hide: () => void, isShow: boolean,
    listChapter: any[], listReviewer: any[], value: any,
    onSubmit: IChangeCallback<any, void>;
}) {
    const [chapter, setChapter] = useState('');
    const [reviewer, setReviewer] = useState('');
    const [title, setTitle] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [isEnableButton, setIsEnableButton] = useState(false);
    const [files, setFiles] = useState<any[]>([]);
    const [content, setContent] = useState('');
    const [ordering, setOrdering] = useState(1);
    const [rebuildEditor, setRebuildEditor] = useState(false);
    const [language, setLanguage] = useState('id');

    const [isAddChapter, setAddChapter] = useState(false);

    const onReset = () => {
        setOrdering(1);
        setContent("");
        setChapter("");
        setReviewer("");
        setTitle("");
        setImageUrl("");
        setLanguage("id");
        setFiles([]);
        setAddChapter(false);
    };

    // Check if button is enable
    useEffect(() => {
        if (props.isDefaultlanguage) {
            if (chapter && chapter.length > 0 &&
                reviewer && reviewer.length > 0 &&
                content && content.length > 0 &&
                title && title.length > 0 &&
                (files.length > 0 || imageUrl.length > 0)
            ) {
                setIsEnableButton(true);
            } else {
                setIsEnableButton(false);
            }
        } else {
            if (content && content.length > 0 &&
                title && title.length > 0 &&
                language && language.length > 0
            ) {
                setIsEnableButton(true);
            } else {
                setIsEnableButton(false);
            }
        }
    }, [chapter, reviewer, content, title, files]);

    useEffect(() => {
        if (props.isShow) {
            setRebuildEditor(!rebuildEditor);

            if (!props.isAddContent) {
                setOrdering(props.value.ordering ? Number(props.value.ordering) : 1);
                setContent(props.value.content ?? "");
                setLanguage(props.value.translation ?? "");
                setChapter(props.value.chapter_code ?? "");
                setReviewer(props.value.reviewer_code ?? "");
                setTitle(props.value.title ?? "");
                setImageUrl(props.value.bg_image_url ?? "");
                setFiles([]);
                setAddChapter(false);
            }
        }
    }, [props.isShow]);

    const onChangeContent = (val: string) => {
        setContent(val);
    };

    // Dropzone
    const [startUF, setSUF] = useState(false);
    const [numberProgress, setNumberProgress] = useState(0);
    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': []
        },
        multiple: false,
        onDrop: acceptedFiles => {
            setSUF(true);
            for (let index = 0; index <= 100; index++) {
                setTimeout(function timer() {
                    setNumberProgress(index);
                    if (index === 100) {
                        const newFiles = acceptedFiles.map(file => {
                            return {
                                preview: URL.createObjectURL(file),
                                origin: file,
                            };
                        });
                        setFiles(newFiles);
                        setSUF(false);
                        setNumberProgress(0);
                    }
                }, index * 10);
            }
        }
    });

    const onSubmit = () => {
        props.onSubmit({
            isAddChapter: isAddChapter,
            chapter: chapter,
            reviewer: reviewer,
            content: content,
            title: title,
            files: files,
            imageUrl: imageUrl,
            ordering: ordering,
            language: language,
        });
    };

    const memoEditor = useMemo(() => {
        const removeAttributes = (elem: HTMLElement) => {
            const length = elem.children.length;
            if (length == 0) return;

            for (let i = 0; i < length; i++) {
                const elemItem = elem.children.item(i) as HTMLElement;

                removeAttributes(elemItem);

                elemItem.removeAttribute('class');
                elemItem.removeAttribute('style');
                elemItem.removeAttribute('dir');
                elemItem.removeAttribute("face");
            }
        };

        return (
            <Editor
                onEditorChange={ (val) => onChangeContent(val) }
                initialValue={ content }
                init={ {
                    height: 320,
                    width: "100%",
                    menubar: false,
                    branding: false,
                    paste_postprocess: (editor, args) => {
                        removeAttributes(args.node);
                    },
                    placeholder: "Write your insight content here...",
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'paste',
                        'fullscreen', 'insertdatetime', 'media', 'help', 'wordcount', 'searchreplace', 'visualblocks'
                    ],
                    toolbar: 'casechange blocks | bold italic underline strikethrough | ' +
                        'alignleft aligncenter alignright alignjustify | ' +
                        'bullist numlist outdent indent',
                    content_style: 'html { font-family: Inter-Regular; font-size:16px }',
                } }
            ></Editor>
        );
    }, [rebuildEditor]);

    return (
        <ModalSimple isShown={ props.isShow } title={ props.isAddContent ? "Add New Course" : "Edit Course" } hide={ props.hide } reset={ onReset }>
            <>
                <DialogContent>
                    <Box sx={ { overFlowY: 'initial !important' } }>
                        <div className='modalBodyC'>
                            {
                                props.isDefaultlanguage ? (
                                    <>
                                        <LabelModal style={ {
                                            marginTop: '20px',
                                            display: 'inline-block'
                                        } }>Course Chapter</LabelModal>
                                        <div className='input-wrapper'>
                                            {
                                                !isAddChapter ? (
                                                    <Select
                                                        required
                                                        displayEmpty
                                                        value={ chapter }
                                                        inputProps={ { 'aria-label': 'Without label' } }
                                                        onChange={ (val) => setChapter(val.target.value!) }
                                                        sx={ {
                                                            marginTop: '8px',
                                                            width: '100%',
                                                            border: 'solid 1px Colors.app.GhostGrey',
                                                            background: Colors.white.default,
                                                            borderRadius: '5px',
                                                            color: Colors.app.black,
                                                            fontWeight: Fonts.weights.w5,
                                                            fontFamily: 'Fonts.interMedium',
                                                            fontSize: Fonts.sizes.xs
                                                        } }
                                                    >
                                                        <MenuItem value=''>
                                                            <em>Course Chapter</em>
                                                        </MenuItem>
                                                        {
                                                            props.listChapter.map((val, i) => {
                                                                return (
                                                                    <MenuItem value={ val.chapter_code } key={ i }>
                                                                        <em>{ val.chapter_name }</em>
                                                                    </MenuItem>
                                                                );
                                                            })
                                                        }
                                                        <MenuItem onClick={ () => {
                                                            setAddChapter(true);
                                                            setChapter('');
                                                        } } value='add_chapter'>
                                                            <Iconify icon='eva:plus-fill' sx={ {
                                                                fontSize: '20px',
                                                                color: '#167CB8'
                                                            } } /> <ListItemText primary='Add New' sx={ {
                                                                color: '#167CB8',
                                                                fontSize: '14px !important'
                                                            } } />
                                                        </MenuItem>
                                                    </Select>
                                                ) : (
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        value={ chapter }
                                                        placeholder="Course Chapter"
                                                        onChange={ (val) => setChapter(val.target.value) }
                                                        sx={ {
                                                            marginTop: '8px',
                                                            width: '100%',
                                                            border: 'solid 1px Colors.app.GhostGrey',
                                                            background: Colors.white.default,
                                                            borderRadius: '5px',
                                                            color: Colors.app.black,
                                                            fontWeight: Fonts.weights.w5,
                                                            fontFamily: 'Fonts.interMedium',
                                                            fontSize: Fonts.sizes.xs
                                                        } }
                                                    ></TextField>
                                                )
                                            }
                                        </div>

                                        <LabelModal style={ {
                                            marginTop: '20px',
                                            display: 'inline-block'
                                        } }>Course Ordering</LabelModal>
                                        <div className='input-wrapper'>
                                            <TextField
                                                required
                                                fullWidth
                                                type='number'
                                                value={ ordering }
                                                placeholder="Course Ordering"
                                                onChange={ (val) => setOrdering(Number(val.target.value)) }
                                                sx={ {
                                                    marginTop: '8px',
                                                    width: '100%',
                                                    border: 'solid 1px Colors.app.GhostGrey',
                                                    background: Colors.white.default,
                                                    borderRadius: '5px',
                                                    color: Colors.app.black,
                                                    fontWeight: Fonts.weights.w5,
                                                    fontFamily: 'Fonts.interMedium',
                                                    fontSize: Fonts.sizes.xs
                                                } }
                                            ></TextField>
                                        </div>
                                    </>
                                ) : null
                            }

                            {
                                !props.isDefaultlanguage ? (
                                    <>
                                        <LabelModal style={ {
                                            marginTop: '20px',
                                            display: 'inline-block'
                                        } }>Language</LabelModal>
                                        <div className='input-wrapper'>
                                            <Select
                                                disabled={ !props.isAddContent }
                                                required
                                                displayEmpty
                                                value={ language }
                                                inputProps={ { 'aria-label': 'Without label' } }
                                                onChange={ (val) => setLanguage(val.target.value!) }
                                                sx={ {
                                                    marginTop: '8px',
                                                    width: '100%',
                                                    border: 'solid 1px Colors.app.GhostGrey',
                                                    background: Colors.white.default,
                                                    borderRadius: '5px',
                                                    color: Colors.app.black,
                                                    fontWeight: Fonts.weights.w5,
                                                    fontFamily: 'Fonts.interMedium',
                                                    fontSize: Fonts.sizes.xs
                                                } }
                                            >
                                                <MenuItem value=''>
                                                    <em>Choose Language</em>
                                                </MenuItem>
                                                <MenuItem value='en'>
                                                    <em>English</em>
                                                </MenuItem>
                                                <MenuItem value='id'>
                                                    <em>Indonesia</em>
                                                </MenuItem>
                                            </Select>
                                        </div>
                                    </>
                                ) : null
                            }

                            <LabelModal style={ {
                                marginTop: '20px',
                                display: 'inline-block'
                            } }>Course Title</LabelModal>
                            <div className='input-wrapper'>
                                <TextField
                                    required
                                    fullWidth
                                    value={ title }
                                    placeholder="Course Title"
                                    onChange={ (val) => setTitle(val.target.value) }
                                    sx={ {
                                        marginTop: '8px',
                                        width: '100%',
                                        border: 'solid 1px Colors.app.GhostGrey',
                                        background: Colors.white.default,
                                        borderRadius: '5px',
                                        color: Colors.app.black,
                                        fontWeight: Fonts.weights.w5,
                                        fontFamily: 'Fonts.interMedium',
                                        fontSize: Fonts.sizes.xs
                                    } }
                                ></TextField>
                            </div>

                            <LabelModal style={ {
                                marginTop: '20px',
                                display: 'inline-block',
                                marginBottom: '8px',
                            } }>Course Content</LabelModal>
                            <div className='input-wrapper'>
                                {
                                    memoEditor
                                }
                            </div>

                            {
                                props.isDefaultlanguage ? (
                                    <>
                                        <LabelModal style={ {
                                            marginTop: '20px',
                                            display: 'inline-block',
                                        } }>Reviewer</LabelModal>
                                        <div className='input-wrapper'>
                                            <Select
                                                required
                                                displayEmpty
                                                value={ reviewer }
                                                inputProps={ { 'aria-label': 'Without label' } }
                                                onChange={ (val) => setReviewer(val.target.value!) }
                                                sx={ {
                                                    marginTop: '8px',
                                                    width: '100%',
                                                    border: 'solid 1px Colors.app.GhostGrey',
                                                    background: Colors.white.default,
                                                    borderRadius: '5px',
                                                    color: Colors.app.black,
                                                    fontWeight: Fonts.weights.w5,
                                                    fontFamily: 'Fonts.interMedium',
                                                    fontSize: Fonts.sizes.xs,
                                                    marginBottom: '8px',
                                                } }
                                            >
                                                <MenuItem value=''>
                                                    <em>Reviewer</em>
                                                </MenuItem>
                                                {
                                                    props.listReviewer.map(sugges => (
                                                        <MenuItem key={ sugges.member_code } value={ sugges.member_code }>
                                                            { sugges.username }
                                                        </MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </div>

                                        <LabelModal style={ { display: 'inline-block' } }>Upload Image</LabelModal>
                                        <div>
                                            {
                                                !startUF && (files.length > 0 || imageUrl.length > 0) ?
                                                    <aside style={ {
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        flexWrap: 'wrap',
                                                        marginTop: 16
                                                    } }>
                                                        <Thumbs
                                                            files={ files }
                                                            getInputProps={ getInputProps }
                                                            getRootProps={ getRootProps }
                                                            imageUrl={ imageUrl }
                                                        ></Thumbs>
                                                    </aside>
                                                    :
                                                    <section style={ {
                                                        width: '100%',
                                                        height: '231px'
                                                    } } className="cursor-pointer">
                                                        <div { ...getRootProps({ className: 'dropzone dropzone-cus' }) }>
                                                            <input { ...getInputProps() } />
                                                            {
                                                                startUF ?
                                                                    <>
                                                                        <div style={ { display: 'flex' } }>
                                                                            <Typography sx={ {
                                                                                color: '#292A2E',
                                                                                fontSize: '12px'
                                                                            } }>
                                                                                Uploading File { numberProgress }%...
                                                                            </Typography>
                                                                        </div>
                                                                        <div style={ {
                                                                            display: 'flex',
                                                                            width: '100px',
                                                                            backgroundColor: 'gray',
                                                                            borderRadius: '10px'
                                                                        } }>
                                                                            <div style={ {
                                                                                backgroundColor: 'black',
                                                                                height: '10px',
                                                                                borderRadius: '10px',
                                                                                width: numberProgress
                                                                            } } >&nbsp;&nbsp;</div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div style={ { display: 'flex' } }>
                                                                            <img src={ Images.addImagePost } style={ {
                                                                                width: '32px',
                                                                                marginBottom: '10px'
                                                                            } } />
                                                                        </div>
                                                                        <div style={ { display: 'flex' } }>
                                                                            <p>Upload an image or drag and drop</p>
                                                                        </div>
                                                                    </>
                                                            }
                                                        </div>
                                                    </section>
                                            }

                                        </div>
                                    </>
                                ) : null
                            }
                        </div>
                    </Box >
                </DialogContent>
                <DialogActions sx={ { padding: '20px 24px !important' } }>
                    <Box sx={ {
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%'
                    } }>
                        <ButtonStyle disabled={ !isEnableButton } onClick={ onSubmit } className='btn-primary' title='Add New Admin' style={ { marginTop: '30px' } } >
                            { props.isAddContent ? "Add Course" : "Edit Course" }
                        </ButtonStyle>
                    </Box>
                </DialogActions>
            </>
        </ModalSimple>
    );
}

function Thumbs(props: { files: any[], getRootProps: any, getInputProps: any, imageUrl: string; }) {
    if (props.imageUrl.length > 0 && props.files.length == 0) {
        const names = props.imageUrl.split('/');
        const name = names[names.length - 1];

        return <Thumb
            { ...props }
            name={ name }
            url={ Endpoints.baseUrlAws + props.imageUrl }
        />;
    }

    const file = props.files[0];

    return <Thumb
        { ...props }
        name={ file.origin.name }
        url={ file.preview }
    />;
}

function Thumb(props: { name: string, url: string, getRootProps: any, getInputProps: any; }) {
    return (
        <div style={ {
            display: 'inline-flex',
            borderRadius: 2,
            border: '1px solid #eaeaea',
            marginBottom: 8,
            marginRight: 8,
            width: '100%',
            height: 'auto',
            padding: 4,
            boxSizing: 'border-box'
        } }>
            <div style={ {
                display: 'flex',
                minWidth: 0,
                overflow: 'hidden'
            } }>
                <div { ...props.getRootProps({ className: 'dropzone' }) }>
                    <input { ...props.getInputProps() } />
                    <div className='postReview'>
                        <div style={ {
                            background: `url(${props.url}) #D3D3D3`,
                            backgroundSize: 'cover',
                            width: '440px',
                            height: '240px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        } } >
                            <IconButton
                                size='small'
                                aria-label='Edit'
                                color='inherit'
                                title='Edit Picture'
                                sx={ {
                                    background: '#ffffff',
                                    position: 'absolute',
                                    top: '10px',
                                    right: '10px'
                                } }
                            >
                                <Edit fontSize='small' />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
