import React from 'react';
import { Link } from 'react-router-dom';
// @mui
import {
	Stack, TableCell, TableRow, Typography
} from '@mui/material';
import { Endpoints, Images } from 'constant';
// components
import Button from '@mui/material/Button';
import { IVoidCallback } from 'interfaces/callback';
import 'react-loading-skeleton/dist/skeleton.css';
import Iconify from 'screens/Layout/components/Iconify';
import { utils } from 'utils';

export default function UserTableRow(props: {
	row: any, selected: boolean, withoutAction?: boolean,
	onEdit?: IVoidCallback, onSelected: IVoidCallback,
	onDelete?: IVoidCallback, onPreview: IVoidCallback;
	onTranslation?: (val: string) => void;
	onAddTranslation?: (val: string) => void;
}) {
	const { id, content, bg_image_url, title, translations, reviewer_name, topic_name, total_score, total_likes, emotions, expertises } = props.row;
	const contentDiv = document.createElement('div');

	contentDiv.innerHTML = content;

	const bgImgUrl = Endpoints.baseUrlAws + bg_image_url;
	const textContent = contentDiv.textContent || contentDiv.innerText || '-';
	const contentStr = textContent.length > 64 ? textContent.substring(0, 64) + ' ...' : textContent;
	const withoutAction = props.withoutAction ?? false;
	const titleStr = title.length > 40 ? title.substring(0, 40) + ' ...' : title;

	const onTranslation = (val: string) => {
		if (props.onTranslation) {
			props.onTranslation(val);
		}
	};

	const onAddTranslation = (val: string) => {
		if (props.onAddTranslation) {
			props.onAddTranslation(val);
		}
	};

	return (
		<TableRow key={ id } hover selected={ props.selected }>

			<TableCell component='th' sx={ {
				textTransform: 'capitalize',
				fontFamily: 'Fonts.interRegular'
			} }>
				<Typography variant='subtitle2' noWrap sx={ {
					minWidth: '120px',
				} }>
					{ titleStr }
				</Typography>
			</TableCell>

			<TableCell component='th' scope='row' padding='normal'>
				<Stack direction='row' alignItems='center' spacing={ 2 }>
					<Typography variant='subtitle2' sx={ {
						flexWrap: 'wrap',
						width: '250px',
						fontFamily: 'Fonts.interMedium'
					} }>
						{ contentStr }
					</Typography>
				</Stack>
			</TableCell>

			<TableCell align='left'>
				<div style={ { width: '150px' } }>
					<img src={ bgImgUrl } className='aspect-video h-20 object-cover' />
				</div>
			</TableCell>

			<TableCell align='left' sx={ {
				fontFamily: 'Fonts.interRegular'
			} }>
				<div className='flex gap-2'>
					{
						translations ? (
							translations.map((val: string, i: number) => {
								return (
									<div style={ { paddingTop: '7px' } } key={ i }>
										<span className='bg-neutral-500 text-white px-2 py-1 rounded-md cursor-pointer' onClick={ () => onTranslation(val) }>{ val }</span>
									</div>
								);
							})
						) : null
					}

					<div className='ml-2'>
						<Button sx={ { minWidth: 'auto !important' } } onClick={ () => onAddTranslation('id') } >
							<Iconify icon='carbon:add-alt' sx={ { fontSize: '24px' } }></Iconify>
						</Button>
					</div>
				</div>
			</TableCell>

			<TableCell align='left' sx={ {
				textTransform: 'capitalize',
				fontFamily: 'Fonts.interRegular'
			} }>
				{ topic_name }
			</TableCell>

			<TableCell align='left' sx={ {
				textTransform: 'capitalize',
				fontFamily: 'Fonts.interRegular'
			} }>
				<Typography variant='subtitle2' noWrap sx={ {
					width: '200px',
					fontFamily: 'Fonts.interMedium'
				} }>
					{ reviewer_name }
				</Typography>
			</TableCell>

			<TableCell align='left' sx={ {
				textTransform: 'capitalize',
				fontFamily: 'Fonts.interRegular'
			} }>
				<Typography variant='subtitle2' noWrap sx={ {
					width: '200px',
					fontFamily: 'Fonts.interMedium'
				} }>
					{ ((emotions ?? []) as any[]).map(v => utils.removeEmoji(v.title)).join(', ') }
				</Typography>
			</TableCell>

			<TableCell align='left' sx={ {
				textTransform: 'capitalize',
				fontFamily: 'Fonts.interRegular'
			} }>
				<Typography variant='subtitle2' noWrap sx={ {
					width: '200px',
					fontFamily: 'Fonts.interMedium'
				} }>
					{ ((expertises ?? []) as any[]).map(v => utils.removeEmoji(v.name)).join(', ') }
				</Typography>
			</TableCell>

			<TableCell align='left' sx={ {
				textTransform: 'capitalize',
				fontFamily: 'Fonts.interRegular'
			} }>
				<Typography variant='subtitle2' noWrap sx={ {
					width: '150px',
					fontFamily: 'Fonts.interMedium'
				} }>
					{ total_score } Points
				</Typography>
			</TableCell>

			<TableCell align='left' sx={ {
				textTransform: 'capitalize',
				fontFamily: 'Fonts.interRegular'
			} }>
				{ total_likes } Likes
			</TableCell>

			<TableCell align='left' sx={ {
				textTransform: 'capitalize',
				fontFamily: 'Fonts.interRegular'
			} }>
				<Link to='#' onClick={ () => props.onPreview() }>Show Preview</Link>
			</TableCell>

			{
				!withoutAction ?
					<TableCell align='left' sx={ { minWidth: '150px' } }>
						<Button onClick={ props.onEdit } sx={ { minWidth: 'auto !important' } }>
							<img src={ Images.btn_edit } style={ { width: '30px' } } />
						</Button>

						<Button onClick={ props.onDelete } sx={ { minWidth: 'auto !important' } }>
							<img src={ Images.btn_delete } style={ { width: '30px' } } />
						</Button>
					</TableCell>
					:
					<></>
			}
		</TableRow>
	);
}
