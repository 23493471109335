import api from "./api";

export const getListCategory = async (params: {
	limit: number;
	page: number;
	offset?: number;
	keyword?: string;
	sort?: string;
	order?: string;
}) => {
	params.order = params.sort ?? "name";
	params.sort = params.sort ?? "asc";
	const resp = await api.get("/v1/categories", {
		params: params,
	});

	return resp.data;
};
