import React from 'react';
import { Stack, TableCell, TableRow, Typography } from '@mui/material';
import { Endpoints, Images } from 'constant';
import Button from '@mui/material/Button';
import Iconify from 'screens/Layout/components/Iconify';

export default function TopicRow(props: {
	row: any;
	onRepost: () => void;
}) {
	let { id, translation, category_name, image_url, name, description } = props.row;

	const contentStr = description.length > 64 ? description.substring(0, 64) + ' ...' : description;

	return (
		<TableRow key={ id } hover>

			<TableCell align='left' sx={ {
				textTransform: 'capitalize',
				fontFamily: 'Fonts.interRegular',
				width: '150px'
			} }>
				{ category_name }
			</TableCell>

			<TableCell align='left' sx={ {
				textTransform: 'capitalize',
				fontFamily: 'Fonts.interRegular',
				width: '150px'
			} }>
				{ name }
			</TableCell>

			<TableCell component='th' scope='row' padding='normal'>
				<Stack direction='row' alignItems='center' spacing={ 2 }>
					<Typography variant='subtitle2' sx={ {
						flexWrap: 'wrap',
						width: '250px',
						fontFamily: 'Fonts.interMedium'
					} }>
						{ contentStr }
					</Typography>
				</Stack>
			</TableCell>

			<TableCell align='left' sx={ { textTransform: 'capitalize' } }>
				{
					image_url ? (
						<div>
							<img src={ Endpoints.baseUrlAws + image_url } style={ { width: '75px' } } />
						</div>
					) : null
				}
			</TableCell>

			<TableCell align='left' sx={ {
				fontFamily: 'Fonts.interRegular'
			} }>
				<div className='flex gap-2'>
					{
						translation ? (
							translation.map((val: string, i: number) => {
								return (
									<div style={ { paddingTop: '7px' } } key={ i }>
										<span className='bg-neutral-500 text-white px-2 py-1 rounded-md cursor-pointer'>{ val }</span>
									</div>
								);
							})
						) : null
					}
				</div>
			</TableCell>

			<TableCell align='left' >
				<Typography variant='subtitle2' noWrap sx={ {
					fontFamily: 'Fonts.interRegular',
					width: '100px'
				} }>
					<Button onClick={ props.onRepost } sx={ { minWidth: 'auto !important' } }>
						<img src={ Images.btn_repost_act } style={ { width: '30px' } } />
					</Button>
				</Typography>
			</TableCell>
		</TableRow>
	);
}
