import React from 'react';
// @mui
import {
	TableCell, TableRow, Typography
} from '@mui/material';
// components
import Button from '@mui/material/Button';
import { IVoidCallback } from 'interfaces/callback';


export function ImportUserRow(props: { row: any, onInvitation: IVoidCallback, onError: IVoidCallback; }) {
	const { id, job_name, job_started, job_finished, job_status } = props.row;

	const jobStatus = <div className={ `w-24 text-center rounded-md py-1 text-xs font-semibold
		${job_status == 'QUEUE' ? 'bg-blue-500/20 text-blue-500' : ''}
		${job_status == 'PROCESS' ? 'bg-purple-500/20 text-purple-500' : ''}
		${job_status == 'FINISHED' ? 'bg-green-500/20 text-green-500' : ''}
		${job_status == 'INVALID' ? 'bg-red-500/20 text-red-500' : ''}
	`}>{ job_status == 'PROCESS' ? job_status + ' ...' : job_status }</div>;

	let jobStarted = '-';
	let jobElapsed = '-';
	if (job_started) {
		jobStarted = new Date(job_started).toLocaleString();
	}

	if (job_started && job_finished) {
		let finish = new Date(job_finished).getTime();
		let start = new Date(job_started).getTime();
		let seconds = Math.abs(Math.round((finish - start)) / 1000);
		let minutes = Math.floor(seconds / 60);

		seconds %= 60;
		jobElapsed = `${minutes} Minutes ${seconds} Seconds`;
	}

	return (
		<TableRow key={ id } hover>

			<TableCell component='th' sx={ {
				textTransform: 'capitalize',
				fontFamily: 'Fonts.interRegular'
			} }>
				<Typography variant='subtitle2' noWrap sx={ {
					minWidth: '120px',
				} }>
					{ job_name }
				</Typography>
			</TableCell>

			<TableCell align='left' sx={ {
				textTransform: 'capitalize',
				fontFamily: 'Fonts.interRegular',
			} }>
				{ jobStatus }
			</TableCell>

			<TableCell align='left' sx={ {
				textTransform: 'capitalize',
				fontFamily: 'Fonts.interRegular'
			} }>
				<Typography variant='subtitle2' noWrap sx={ {
					width: '150px',
					fontFamily: 'Fonts.interMedium'
				} }>
					{ jobStarted }
				</Typography>
			</TableCell>

			<TableCell align='left' sx={ {
				textTransform: 'capitalize',
				fontFamily: 'Fonts.interRegular'
			} }>
				{ jobElapsed }
			</TableCell>

			<TableCell align='left'>
				{
					job_status == "FINISHED" ?
						<Button onClick={ props.onInvitation } sx={ { minWidth: 'auto !important' } }>
							Send Invitation
						</Button>
						:
						job_status == "INVALID" ?
							<Button onClick={ props.onError } color='error' sx={ { minWidth: 'auto !important' } }>
								View Error
							</Button>
							:
							'-'
				}
			</TableCell>
		</TableRow>
	);
}
