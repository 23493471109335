import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import {
  ModalOvertimeConfirm,
  ModalSimple,
} from "components/Modal/ModalSimple";
import { LabelModal } from "components/Modal/style";
import numeral from "numeral";
import React, { useEffect, useState } from "react";
import { Button as ButtonStyle } from "screens/style";
import { utils } from "utils";

const FEE_PERCENTAGE = 0.85;
const ListTime = [30, 60, 90, 120];

export default function ModalOvertime(props: {
  onSubmit: (val: any) => void;
  onDelete: () => void;
  hide: () => void;
  isShow: boolean;
  value: any;
}) {
  const [isEnableButton, setEnableButton] = useState(false);
  const [value, setValue] = useState("");
  const [customPrice, setCustomPrice] = useState("");
  const [customTime, setCustomTime] = useState("");

  const [isShowOvertimeConfirm, setShowOvertimeConfirm] = useState(false);

  const getHourlyPrice = () => {
    const rates = props.value.rates ?? [];
    for (const item of rates) {
      if (item.format_call === props.value.format_call) {
        return item.price;
      }
    }

    return 0;
  };

  const price = (duration?: number) => {
    const hourlyPrice = getHourlyPrice();
    if (duration) {
      return (hourlyPrice * duration) / 60;
    }

    let price = 0;

    if (value === "other") {
      price = Number(customPrice);
    } else {
      duration = Number(value);
      price = (hourlyPrice * duration) / 60;
    }

    return price;
  };

  const duration = () => {
    let duration = 0;

    if (value === "other") {
      duration = Number(customTime);
    } else {
      duration = Number(value);
    }

    return duration;
  };

  useEffect(() => {
    if (!props.isShow) {
      setValue("");
      setCustomPrice("");
      setCustomTime("");
      return;
    }

    const overtime = props.value.overtime;
    if (overtime === null) {
      return;
    }

    const duration = overtime.duration;
    const price = overtime.price;
    if (ListTime.includes(duration)) {
      if (price === (duration / 60) * getHourlyPrice()) {
        setCustomPrice("");
        setCustomTime("");
        setValue(duration);
        return;
      }
    }

    setCustomPrice(price);
    setCustomTime(duration);
    setValue("other");
  }, [props.isShow]);

  useEffect(() => {
    setEnableButton(
      value.length > 0 &&
        (value === "other"
          ? customPrice.length > 0 && customTime.length > 0
          : true)
    );
  }, [value, customPrice, customTime]);

  const onOvertimeConfirm = () => {
    setShowOvertimeConfirm(false);
    props.onSubmit({
      price: price(),
      duration: duration(),
    });
  };

  const onSubmit = () => {
    setShowOvertimeConfirm(true);
  };

  return (
    <>
      {/* Overtime Confirm */}
      <ModalOvertimeConfirm
        isShow={isShowOvertimeConfirm}
        onHide={() => setShowOvertimeConfirm(false)}
        onSubmit={onOvertimeConfirm}
      ></ModalOvertimeConfirm>

      {/* Modal Overtime */}
      <ModalSimple
        isShown={props.isShow && !isShowOvertimeConfirm}
        title="Add Overtime Charge"
        hide={props.hide}
        width="460px"
      >
        <>
          <DialogContent>
            <FormControl className="w-full">
              <RadioGroup
                defaultValue={30}
                name="duration"
                className="gap-2"
                value={value}
                onChange={(val) => setValue(val.target.value)}
              >
                {ListTime.map((val) => {
                  return (
                    <div
                      className="flex justify-between items-center"
                      key={val}
                    >
                      <FormControlLabel
                        value={val.toString()}
                        control={<Radio />}
                        label={`${val} Minutes`}
                      />
                      <div className="text-black font-medium">
                        Rp {numeral(price(val)).format("0,0")}/Rp{" "}
                        {numeral(price(val) * FEE_PERCENTAGE).format("0,0")}
                      </div>
                    </div>
                  );
                })}

                <FormControlLabel
                  value="other"
                  control={<Radio />}
                  label="Custom"
                />

                <div
                  className={`mt-4 w-full ${value !== "other" ? "hidden" : ""}`}
                >
                  <div className="mb-4">
                    <LabelModal className="block mb-2" htmlFor="custom-time">
                      Custom Time
                    </LabelModal>
                    <TextField
                      type="text"
                      id="custom-time"
                      placeholder="Custom Time (minutes)"
                      variant="outlined"
                      value={customTime}
                      onChange={(val) =>
                        setCustomTime(utils.onlyNumber(val.target.value))
                      }
                      fullWidth
                    />
                  </div>

                  <LabelModal className="block mb-2" htmlFor="custom-price">
                    Custom Price
                  </LabelModal>
                  <TextField
                    type="text"
                    id="custom-price"
                    placeholder="Custom Price (rupiah)"
                    variant="outlined"
                    value={customPrice}
                    onChange={(val) =>
                      setCustomPrice(utils.onlyNumber(val.target.value))
                    }
                    fullWidth
                  />
                </div>

                <div className="flex justify-between text-lg font-semibold mt-4">
                  <div>Total</div>
                  <div>
                    {value === ""
                      ? "Rp -"
                      : value !== "other"
                      ? `Rp ${numeral(price(Number(value))).format(
                          "0,0"
                        )}/Rp ${numeral(
                          price(Number(value)) * FEE_PERCENTAGE
                        ).format("0,0")}`
                      : `Rp ${numeral(customPrice).format("0,0")}/Rp ${numeral(
                          Number(customPrice) * FEE_PERCENTAGE
                        ).format("0,0")}`}
                  </div>
                </div>
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions sx={{ padding: "20px 24px !important" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <ButtonStyle
                disabled={!isEnableButton}
                onClick={onSubmit}
                className="btn-primary"
                style={{ marginTop: "30px" }}
              >
                Confirm
              </ButtonStyle>
              {props.value.overtime ? (
                <Button
                  onClick={props.onDelete}
                  style={{ marginTop: "30px" }}
                  variant="outlined"
                  size="medium"
                  className="!py-2 !normal-case !text-lg"
                  color="inherit"
                >
                  Delete
                </Button>
              ) : null}
            </Box>
          </DialogActions>
        </>
      </ModalSimple>
    </>
  );
}
