import { Box, Button, Card, Container, InputAdornment, Stack, Typography } from '@mui/material';
import { ModalDelete } from 'components/Modal/ModalSimple';
import { SearchStyle } from 'components/Panel';
import React, { useEffect, useState } from 'react';
import Iconify from 'screens/Layout/components/Iconify';
import { addSubscription, deleteSubscription, getListSubscription, updateSubscription } from 'service/billing';
import ModalModify from './components/ModalModify';
import TabSubscription from "./components/TabSubscription";


export default function Insight() {
	// Options Table
	const [search, setSearch] = useState('');

	// Data Active
	const [paginationActive, setTablePagination] = useState<any[]>([]);
	const [dataActive, setTableData] = useState<any[]>([]);
	const [pageActive, setPageActive] = useState<number>(1);

	// Modal
	const [isAddContent, setAddContent] = useState(false);
	const [isShowModalModify, setShowModalModify] = useState(false);
	const [currentItem, setCurrentItem] = useState<any>({});
	const [isShowDelete, setShowDelete] = useState(false);

	// Use Effect
	useEffect(() => {
		if (!isShowModalModify && !isShowDelete) {
			setCurrentItem({});
		}

		if (!isShowModalModify) {
			setAddContent(true);
		}
	}, [isShowModalModify, isShowDelete]);

	useEffect(() => {
		onChangeData();
	}, [search, pageActive]);

	useEffect(() => {
		onChangeData();
	}, []);

	const toggleShowModal = () => {
		setShowModalModify(!isShowModalModify);
	};

	const toggleShowDelete = () => {
		setShowDelete(!isShowDelete);
	};

	const onChangeData = () => {
		getListSubscription({
			search,
			isActive: true
		})
			.then((resp) => {
				const data = resp.data;

				setPageActive(1);
				setTableData(data);
				setTablePagination([
					{
						limit: data.length,
						page: 1,
						count: data.length,
					}
				]);
			});
	};

	const onEdit = (rowItem) => {
		setAddContent(false);
		setCurrentItem(rowItem);

		toggleShowModal();
	};

	const onSubmit = async (data: any) => {
		if (isAddContent) {
			await addSubscription({
				subscription_plan: data.subscription_plan,
				subscription_price: Number(data.subscription_price),
				monthly_credit: Number(data.monthly_credit)
			});
		} else {
			await updateSubscription(currentItem.subscription_code, {
				subscription_plan: data.subscription_plan,
				subscription_price: Number(data.subscription_price),
				monthly_credit: Number(data.monthly_credit)
			});
		}

		onChangeData();
		setShowModalModify(false);
	};

	const onDelete = (item) => {
		setCurrentItem(item);

		toggleShowDelete();
	};

	const onConfirmDelete = async () => {
		await deleteSubscription(currentItem.subscription_code);

		toggleShowDelete();
		onChangeData();
	};

	return (
		<>
			<Container maxWidth='lg'>
				<Stack direction='row' alignItems='center' justifyContent='space-between' mb={ 4 }>
					<Box>
						<Typography variant='h4' sx={ { fontSize: '24px' } } >
							Subscription Plan
						</Typography>
					</Box>
				</Stack>
				<Box sx={ {
					width: '100%',
					margin: '0',
					paddingTop: '20px',
					paddingBottom: '20px',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
				} }>
					<SearchStyle
						onChange={ e => setSearch(e.target.value) }
						placeholder='Search'
						startAdornment={
							<InputAdornment position='start'>
								<Iconify icon='eva:search-fill' sx={ {
									color: 'text.disabled',
									width: 25,
									height: 25
								} } />
							</InputAdornment>
						}
					/>

					<Box sx={ {
						flex: 3,
						textAlign: 'right'
					} }>
						<Button onClick={ () => setShowModalModify(true) } className='btn-action-filter-dark' sx={ { width: '200px' } } endIcon={ <Iconify icon='eva:plus-fill' sx={ {} } /> }>
							Add Subscription
						</Button>
					</Box>
				</Box>

				<Card>
					<TabSubscription
						data={ dataActive }
						pagination={ paginationActive }
						onDelete={ onDelete }
						onEdit={ (params) => onEdit(params) }
						onChangeRows={ val => console.log("Change Rows :", val) }
						onChangePage={ val => console.log("Change Page :", val) }
					/>
				</Card>

				<ModalModify
					isAddContent={ isAddContent }
					isShow={ isShowModalModify }
					hide={ toggleShowModal }
					value={ currentItem }
					onSubmit={ onSubmit }
				/>

				<ModalDelete
					isOpen={ isShowDelete }
					onClose={ toggleShowDelete }
					onDelete={ onConfirmDelete }
				/>

			</Container>
		</>
	);
}
