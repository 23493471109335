import api from "./api";

export const getListPromo = async (params: {
  limit: number;
  page: number;
  offset?: number;
  keyword?: string;
  sort?: string;
  order?: string;
  status?: string;
  onlyAvailable?: boolean;
}) => {
  return api
    .get("/v1/promos", {
      params: {
        ...params,
        only_available: params.onlyAvailable,
      },
    })
    .then((val) => val.data);
};

export const addPromo = async (params: any) => {
  const resp = await api.post("/v1/promos", params);
  return resp.data;
};

export const updatePromo = async (params: any) => {
  const resp = await api.put(`/v1/promos/${params.promo_code}`, params);
  return resp.data;
};

export const deletePromo = async (params: { promo_code: string }) => {
  const resp = await api.delete(`/v1/promos/${params.promo_code}`);
  return resp.data;
};

export const repostPromo = async (params: { promo_code: string }) => {
  const resp = await api.put(`/v1/promos/repost/${params.promo_code}`);
  return resp.data;
};
