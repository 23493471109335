import React, { useEffect, useState } from 'react';
import { Box, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { TableEmptyRows, TableHeadCustom } from 'components/Table';
import OrganizationRow from 'sections/@dashboard/organization/CTabComponents';
import { getOrganizationList } from 'service/organization';

const TABLE_HEAD = [
	{
		id: 'organization_id',
		label: 'ORGANIZATION ID',
		alignRight: false
	}, {
		id: 'organization_name',
		label: 'ORGANIZATION NAME',
		alignRight: false
	},
	{
		id: 'contact_number',
		label: 'CONTACT PERSON',
		alignRight: false
	},
	{
		id: 'contact_email',
		label: 'CONTACT EMAIL',
		alignRight: false
	},
	{
		id: 'office_address',
		label: 'CONTACT ADDRESS',
		alignRight: false
	},
	{
		id: 'members',
		label: 'MEMBERS'
	},
	{ id: '' },
];

export default function OrganizationTab(props: {
	search: string;
	sales: string;
	forceUpdate: number;
	onEdit: (val: any) => void;
	onDelete: (val: any) => void;
	onMember: (val: any) => void;
	onImport: (val: any) => void;
}) {
	const [data, setData] = useState<any[]>([]);
	const [limit, setLimit] = useState(10);
	const [page, setPage] = useState(1);
	const [count, setCount] = useState(0);

	const onChangeRowPerPage = (val: number) => {
		setLimit(val);
	};

	const onChangePage = (val: number) => {
		setPage(val + 1);
	};

	const init = async () => {
		const resp = await getOrganizationList({
			limit: limit,
			page: page,
			search: props.search,
			sales: props.sales,
		});
		const pagination = resp.pagination;

		setPage(pagination.page);
		setLimit(pagination.limit);
		setCount(pagination.count);
		setData(resp.data ?? []);
	};

	useEffect(() => {
		init();
	}, [props.search, props.sales, props.forceUpdate, page, limit]);

	return (
		<div>
			<TableContainer sx={ {
				minWidth: 800,
				position: 'relative'
			} }>

				<Table>
					<TableHeadCustom
						headLabel={ TABLE_HEAD }
						rowCount={ data.length }
					/>

					<TableBody>
						{
							data.map((row, index) => (
								<OrganizationRow
									key={ index }
									row={ row }
									onDelete={ () => props.onDelete(row) }
									onEdit={ () => props.onEdit(row) }
									onMember={ () => props.onMember(row) }
									onImport={ () => props.onImport(row) }
								/>
							))
						}
						<TableEmptyRows height={ 72 } emptyRows={ data.length } />
					</TableBody>
				</Table>
			</TableContainer>

			<Box sx={ { position: 'relative' } }>
				<TablePagination
					rowsPerPageOptions={ [10, 25, 100] }
					component='div'
					labelRowsPerPage='Items per page: '
					count={ count }
					rowsPerPage={ limit }
					page={ page - 1 }
					onPageChange={ (_, pg) => onChangePage(pg) }
					onRowsPerPageChange={ (e) => onChangeRowPerPage(Number(e.target.value)) }
				/>

			</Box>
		</div>
	);
}
