import React from 'react';
import { Card } from '@mui/material';
import { ChartOptions } from 'chart.js';
import { Colors } from 'constant';
import { Bar } from 'react-chartjs-2';
import NoDataIcon from "assets/svg/analytic_nodata.svg";

export function Content(props: {
    stepSize: number,
    labels: string[],
    values: number[],
    title: string,
    detail: JSX.Element,
}) {
    const optionsBar: ChartOptions<"bar"> = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                display: false,
            }
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
        }
    };
    const dataBar = {
        labels: props.labels,
        datasets: [{
            label: '',
            data: props.values,
            backgroundColor: [Colors.chart.getColor(5)],
        }]
    };

    return (
        <Card className='mb-8'>
            <div className='bg-slate-100 py-4 pl-4 flex justify-between'>
                <div className='font-bold text-xl pt-2'>{ props.title }</div>
            </div>
            {
                props.values.length > 0 ?
                    <div className='px-16 pb-16 pt-6'>
                        <div className='flex'>
                            <div className='flex-grow'>
                                <Bar options={ optionsBar } data={ dataBar }></Bar>
                                <div className='mt-6 text-center'>Days of the Month</div>
                            </div>
                            <div className='mx-16 bg-slate-300'>
                                <div style={ { width: "1px" } }></div>
                            </div>
                            <div className='w-96 pt-14'>
                                { props.detail }
                            </div>
                        </div>
                    </div>
                    :
                    <div className='py-28 text-center'>
                        <img className='mx-auto' src={ NoDataIcon } alt="" />
                        <div className='text-center text-lg mt-10'>Sorry, no data is available</div>
                    </div>
            }
        </Card>
    );
}