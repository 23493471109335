import {
  Box,
  DialogActions,
  DialogContent,
  InputAdornment,
  TextField,
} from "@mui/material";
import { ModalSimple } from "components/Modal/ModalSimple";
import { Colors, Endpoints, Fonts } from "constant";
import { IVoidCallback } from "interfaces/callback";
import React, { useEffect, useState } from "react";
import { LabelModal } from "screens/Login/style";
import { Button as ButtonStyle } from "screens/style";
import { utils } from "utils";

export default function ModalModify(props: {
  currentItem: any;
  isShow: boolean;
  toggle: IVoidCallback;
  onSubmit: (val: any) => void;
}) {
  // Pricing
  const [previewPrice, setPreviewPrice] = useState("");
  const [voicePrice, setVoicePrice] = useState("");
  const [videoPrice, setVideoPrice] = useState("");

  // Fee
  const [previewFee, setPreviewFee] = useState("");
  const [voiceFee, setVoiceFee] = useState("");
  const [videoFee, setVideoFee] = useState("");

  const [isEnabled, setIsEnabled] = useState(false);

  const checkFormValid = () => {
    if (
      previewPrice !== "" &&
      voicePrice &&
      videoPrice &&
      previewFee !== "" &&
      voiceFee &&
      videoFee
    ) {
      setIsEnabled(true);
    } else {
      setIsEnabled(false);
    }
  };

  useEffect(() => {
    checkFormValid();
  }, [previewPrice, voicePrice, videoPrice, previewFee, voiceFee, videoFee]);

  useEffect(() => {
    if (!props.isShow) {
      setPreviewPrice("");
      setVoicePrice("");
      setVideoPrice("");

      setPreviewFee("");
      setVoiceFee("");
      setVideoFee("");
    } else {
      const item = props.currentItem;
      const rates = item.rates;
      if (!rates) {
        return;
      }

      for (const item of rates) {
        if (item.format_call === "preview") {
          setPreviewPrice(item.price);
          setPreviewFee(item.fee);
        }

        if (item.format_call === "video_call") {
          setVideoPrice(item.price);
          setVideoFee(item.fee);
        }

        if (item.format_call === "voice_call") {
          setVoicePrice(item.price);
          setVoiceFee(item.fee);
        }
      }

      checkFormValid();
    }
  }, [props.isShow]);

  const onSubmit = async () => {
    setIsEnabled(false);

    props.onSubmit({
      preview_price: previewPrice,
      preview_fee: previewFee,
      voice_price: voicePrice,
      voice_fee: voiceFee,
      video_price: videoPrice,
      video_fee: videoFee,
    });
  };

  return (
    <ModalSimple
      isShown={props.isShow}
      title={"Edit Specialist Rates"}
      hide={props.toggle}
    >
      <>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box sx={{ overFlowY: "initial !important" }}>
              <div className="modalBodyC">
                <div className="input-wrapper" style={{ margin: "0 0 8px 0" }}>
                  <div className="schedule-cus" style={{ width: "100%" }}>
                    <div
                      className="date-schedule-cus"
                      style={{ width: "100%" }}
                    >
                      <LabelModal
                        style={{
                          margin: "5px 0 10px 0",
                          display: "inline-block",
                        }}
                      >
                        Avatar Profile*
                      </LabelModal>
                      <div className="input-wrapper form-inline">
                        <aside
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}
                        >
                          <Thumbs
                            imageUrl={props.currentItem.profile_url}
                          ></Thumbs>
                        </aside>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="input-wrapper" style={{ margin: "0 0 0 0" }}>
                  <div className="schedule-cus" style={{ width: "100%" }}>
                    <div style={{ width: "100%" }}>
                      <LabelModal
                        style={{
                          margin: "5px 0 10px 0",
                          display: "inline-block",
                        }}
                      >
                        Full Name*
                      </LabelModal>
                      <div className="input-wrapper form-inline">
                        <input
                          disabled
                          type="text"
                          value={props.currentItem.username}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <LabelModal style={{ display: "inline-block" }}>
                  Preview 15-Min
                </LabelModal>
                <div
                  className="input-wrapper"
                  style={{ margin: "5px 0 10px 0" }}
                >
                  <div className="schedule-cus" style={{ width: "100%" }}>
                    <div className="date-schedule-cus" style={{ width: "50%" }}>
                      <LabelModal style={{ display: "inline-block" }}>
                        Price
                      </LabelModal>
                      <TextField
                        sx={{
                          marginTop: "8px",
                          width: "100%",
                          border: "solid 1px Colors.app.GhostGrey",
                          background: Colors.white.default,
                          borderRadius: "5px",
                          color: Colors.app.black,
                          fontWeight: Fonts.weights.w5,
                          fontFamily: "Fonts.interMedium",
                          fontSize: Fonts.sizes.xs,
                        }}
                        type="text"
                        placeholder="Price"
                        onChange={(e) =>
                          setPreviewPrice(utils.onlyNumber(e.target.value))
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              className="opacity-80"
                              position="start"
                            >
                              Rp
                            </InputAdornment>
                          ),
                        }}
                        value={previewPrice}
                      />
                    </div>
                    <div className="time-schedule-cus" style={{ width: "50%" }}>
                      <LabelModal
                        style={{
                          display: "inline-block",
                        }}
                      >
                        Eearning
                      </LabelModal>
                      <TextField
                        sx={{
                          marginTop: "8px",
                          width: "100%",
                          border: "solid 1px Colors.app.GhostGrey",
                          background: Colors.white.default,
                          borderRadius: "5px",
                          color: Colors.app.black,
                          fontWeight: Fonts.weights.w5,
                          fontFamily: "Fonts.interMedium",
                          fontSize: Fonts.sizes.xs,
                        }}
                        type="text"
                        placeholder="Earning"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              className="opacity-80"
                              position="start"
                            >
                              Rp
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) =>
                          setPreviewFee(utils.onlyNumber(e.target.value))
                        }
                        value={previewFee}
                      />
                    </div>
                  </div>
                </div>

                <LabelModal style={{ display: "inline-block" }}>
                  Voice Call 60-Min
                </LabelModal>
                <div
                  className="input-wrapper"
                  style={{ margin: "5px 0 10px 0" }}
                >
                  <div className="schedule-cus" style={{ width: "100%" }}>
                    <div className="date-schedule-cus" style={{ width: "50%" }}>
                      <LabelModal style={{ display: "inline-block" }}>
                        Price
                      </LabelModal>
                      <TextField
                        sx={{
                          marginTop: "8px",
                          width: "100%",
                          border: "solid 1px Colors.app.GhostGrey",
                          background: Colors.white.default,
                          borderRadius: "5px",
                          color: Colors.app.black,
                          fontWeight: Fonts.weights.w5,
                          fontFamily: "Fonts.interMedium",
                          fontSize: Fonts.sizes.xs,
                        }}
                        type="text"
                        placeholder="Price"
                        onChange={(e) =>
                          setVoicePrice(utils.onlyNumber(e.target.value))
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              className="opacity-80"
                              position="start"
                            >
                              Rp
                            </InputAdornment>
                          ),
                        }}
                        value={voicePrice}
                      />
                    </div>
                    <div className="time-schedule-cus" style={{ width: "50%" }}>
                      <LabelModal
                        style={{
                          display: "inline-block",
                        }}
                      >
                        Eearning
                      </LabelModal>
                      <TextField
                        sx={{
                          marginTop: "8px",
                          width: "100%",
                          border: "solid 1px Colors.app.GhostGrey",
                          background: Colors.white.default,
                          borderRadius: "5px",
                          color: Colors.app.black,
                          fontWeight: Fonts.weights.w5,
                          fontFamily: "Fonts.interMedium",
                          fontSize: Fonts.sizes.xs,
                        }}
                        type="text"
                        placeholder="Earning"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              className="opacity-80"
                              position="start"
                            >
                              Rp
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) =>
                          setVoiceFee(utils.onlyNumber(e.target.value))
                        }
                        value={voiceFee}
                      />
                    </div>
                  </div>
                </div>

                <LabelModal style={{ display: "inline-block" }}>
                  Video Call 60-Min
                </LabelModal>
                <div
                  className="input-wrapper"
                  style={{ margin: "5px 0 10px 0" }}
                >
                  <div className="schedule-cus" style={{ width: "100%" }}>
                    <div className="date-schedule-cus" style={{ width: "50%" }}>
                      <LabelModal style={{ display: "inline-block" }}>
                        Price
                      </LabelModal>
                      <TextField
                        sx={{
                          marginTop: "8px",
                          width: "100%",
                          border: "solid 1px Colors.app.GhostGrey",
                          background: Colors.white.default,
                          borderRadius: "5px",
                          color: Colors.app.black,
                          fontWeight: Fonts.weights.w5,
                          fontFamily: "Fonts.interMedium",
                          fontSize: Fonts.sizes.xs,
                        }}
                        type="text"
                        placeholder="Price"
                        onChange={(e) =>
                          setVideoPrice(utils.onlyNumber(e.target.value))
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              className="opacity-80"
                              position="start"
                            >
                              Rp
                            </InputAdornment>
                          ),
                        }}
                        value={videoPrice}
                      />
                    </div>
                    <div className="time-schedule-cus" style={{ width: "50%" }}>
                      <LabelModal
                        style={{
                          display: "inline-block",
                        }}
                      >
                        Eearning
                      </LabelModal>
                      <TextField
                        sx={{
                          marginTop: "8px",
                          width: "100%",
                          border: "solid 1px Colors.app.GhostGrey",
                          background: Colors.white.default,
                          borderRadius: "5px",
                          color: Colors.app.black,
                          fontWeight: Fonts.weights.w5,
                          fontFamily: "Fonts.interMedium",
                          fontSize: Fonts.sizes.xs,
                        }}
                        type="text"
                        placeholder="Earning"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              className="opacity-80"
                              position="start"
                            >
                              Rp
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) =>
                          setVideoFee(utils.onlyNumber(e.target.value))
                        }
                        value={videoFee}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: "20px 24px !important" }}>
          <ButtonStyle
            className="btn-primary"
            disabled={!isEnabled}
            title="Add New Specialist "
            onClick={onSubmit}
            style={{ marginTop: "30px" }}
          >
            Edit
          </ButtonStyle>
        </DialogActions>
      </>
    </ModalSimple>
  );
}

function Thumbs(props: { imageUrl: string }) {
  const names = props.imageUrl.split("/");
  const name = names[names.length - 1];

  return (
    <Thumb
      {...props}
      name={name}
      url={Endpoints.baseUrlAws + props.imageUrl}
    ></Thumb>
  );
}

function Thumb(props: { name: string; url: string }) {
  return (
    <div
      style={{
        marginBottom: 8,
        marginRight: 8,
      }}
      key={props.name}
    >
      <div
        style={{
          display: "flex",
          minWidth: 0,
          overflow: "hidden",
        }}
      >
        <div
          style={{
            background: "#ffffff",
            width: "55px !important",
            marginTop: "0 !important",
            borderRadius: "100px",
            height: "55px !important",
            padding: "0 !important",
            cursor: "pointer",
          }}
        >
          <div
            style={{
              background: `url(${props.url}) #D3D3D3`,
              backgroundSize: "100%",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              width: "55px",
              height: "55px",
              display: "flex",
              alignItems: "center",
              borderRadius: "100px",
              justifyContent: "center",
            }}
          />
        </div>
      </div>
    </div>
  );
}
