import { Common, Admin } from "interfaces";
import { apiService } from "helpers";

import { AdminActionTypes } from "./actionTypes";
import { Endpoints } from "constant";
import { dispatch } from "store";

export const postProgress = (
	type: AdminActionTypes,
	payload?: Admin.AdminPayload
) => {
	dispatch({
		type,
		payload,
	});
};

export const getAdmin = (params?: Common.RequestData) => {
	return new Promise<any[]>(async (resolve, reject) => {
		postProgress(AdminActionTypes.GET_ADMIN_LIST_LOADING);

		const response: Common.ApiResponse<Admin.Detail[]> = await apiService(
			`${Endpoints.getadmin}?is_active=${params?.body.is_active}&page=${
				params?.body.page
			}&limit=${params?.body.limit}&keyword=${
				params?.body.keyword ? params?.body.keyword : ""
			}`,
			{ method: "GET" },
			params?.query
		);

		const { object, data, error, pagination } = response;
		if (data) {
			const dd = object.data;
			const result: any[] = [];
			const pagiVal: any[] = [];

			pagiVal.push({
				count: pagination.count,
				page: pagination.page,
				limit: pagination.limit,
			});

			dd.map((item, i) => {
				const statusConv = item.is_active === true ? "active" : "inactive";

				result.push({
					name: `${item.name}`,
					email: `${item.email}`,
					admin_code: `${item.admin_code}`,
					status: `${statusConv}`,
					is_active: item.is_active,
					site_role: item.site_role,
					access: item.access,
				});
			});

			postProgress(AdminActionTypes.GET_ADMIN_LIST_SUCCESS, {
				listData: result,
				pagination: pagiVal,
			});
			resolve(pagiVal);
		} else {
			// you can put toast here
			reject(
				postProgress(AdminActionTypes.GET_ADMIN_LIST_ERROR, { error: error })
			);
		}
	});
};

export const getAdminCount = (params?: Common.RequestData) => {
	return new Promise<any[]>(async (resolve, reject) => {
		const response: Common.ApiResponse<Admin.Detail[]> = await apiService(
			`${Endpoints.getadmin}/count`,
			{ method: "GET" },
			params?.query
		);

		const { object, data, error } = response;
		if (data) {
			const dd = object.data;
			const result: any[] = [];

			result.push({
				active_count: `${dd.active_count}`,
				inactive_count: `${dd.inactive_count}`,
			});
			resolve(result);
		} else {
			// you can put toast here
			reject(error);
		}
	});
};

export const addAdmin = (params?: Common.RequestData) => {
	return new Promise<void>(async (resolve, reject) => {
		postProgress(AdminActionTypes.ADD_ADMIN_LOADING);

		const response: Common.ApiResponse<Admin.Detail> = await apiService(
			Endpoints.admin,
			{
				method: "POST",
				body: params?.body,
			}
		);
		const { data, error, object } = response;
		if (data) {
			resolve(postProgress(AdminActionTypes.ADD_ADMIN_SUCCESS, { data: data }));
		} else {
			// you can put toast here
			postProgress(AdminActionTypes.ADD_ADMIN_ERROR);
			reject(object);
		}
	});
};

export const changeStatus = (params?: Common.RequestData) => {
	return new Promise<void>(async (resolve, reject) => {
		postProgress(AdminActionTypes.DELETE_ADMIN_LOADING);

		const response: Common.ApiResponse<Admin.Detail> = await apiService(
			`${Endpoints.admin}${params?.body.id}`,
			{
				method: "PUT",
				body: params?.body,
			}
		);

		const { data, error } = response;
		if (data) {
			resolve(
				postProgress(AdminActionTypes.DELETE_ADMIN_SUCCESS, {
					data: { ...data, id: parseInt(params?.id || "") },
				})
			);
		} else {
			// you can put toast here
			reject(
				postProgress(AdminActionTypes.DELETE_ADMIN_ERROR, { error: error })
			);
		}
	});
};
