import api from "./api";

// Quote //
export const getListAdmin = async (params: {
	keyword?: string;
	page?: number;
	limit?: number;
	order?: string;
	sort?: string;
	is_active?: boolean;
}) => {
	const resp = await api.get("/v1/admins", {
		params: params,
	});
	return resp.data;
};

export const getListAccess = async () => {
	const resp = await api.get("/v1/access");
	return resp.data;
};

export const getAdminAccess = async () => {
	const resp = await api.get("/v1/admins/access");
	return resp.data;
};

export const updateAdminPassword = async (data: {
	code: string;
	password: string;
}) => {
	const resp = await api.put(`/v1/admins/${data.code}/password`, data);
	return resp.data;
};

export const updateAdmin = async (data: {
	code: string;
	name: string;
	site_role: string;
	is_active: boolean;
}) => {
	const resp = await api.put(`/v1/admins/${data.code}`, data);
	return resp.data;
};

export const updateAdminAccess = async (data: {
	code: string;
	access: number[];
}) => {
	const resp = await api.put(`/v1/admins/${data.code}/access`, data);
	return resp.data;
};
