import api from "./api";

export const getCategoryList = async () => {
	const resp = await api.get("/v1/categories", {
		params: {
			page: 1,
			limit: 100,
			offset: "",
			sort: "asc",
			order: "",
		},
	});
	return resp.data;
};
