import React from 'react';
import './index.css';

export default function Noaccess() {
    return (
        <div className='body-container'>
            <div className="forbidden">
                <div className="text">
                    FORBIDDEN
                </div>
            </div>
            <div className="text-403">4003</div>

            <div className="door"></div>
            <div id="hodor">
                <div className="l-arm">
                    <div className="hand"></div>
                    <div className="arm"></div>
                </div>
                <div className="r-arm">
                    <div className="hand"></div>
                    <div className="arm"></div>
                </div>
                <div className="body">
                    <div className="backpack-strap-r"></div>
                    <div className="backpack-strap-l"></div>
                </div>
                <div className="belt"></div>
                <div className="head">
                    <div className="hair"></div>
                    <div className="face"></div>
                    <div className="r-eye"></div>
                    <div className="l-eye"></div>
                    <div className="r-brow"></div>
                    <div className="l-brow"></div>
                    <div className="beard"></div>
                    <div className="bubble">H0D0R!</div>
                </div>
                <div className="l-leg">
                    <div className="foot"></div>
                </div>
                <div className="r-leg">
                    <div className="foot"></div>
                </div>
            </div>
        </div>
    );
}
