import React from 'react';
import { Box, DialogActions, DialogContent } from '@mui/material';
import { ModalSimple } from 'components/Modal/ModalSimple';
import { Button as ButtonStyle } from 'screens/style';
import { Endpoints } from 'constant';
import { ReactSVG } from 'react-svg';

export default function ModalPreview(props: {
    isShow: boolean;
    value: any;
    onHide: () => void;
}) {
    const { title, subtitle, reviewer_name, description, image_url, narrations } = props.value;
    const backgroundUrl = Endpoints.baseUrlAws + image_url;

    return (
        <ModalSimple
            hide={ props.onHide }
            isShown={ props.isShow }
            title='Exercise Preview'
            width='376px'
        >
            <>
                <DialogContent>
                    <Box>
                        <div className='postReview'>
                            <ReactSVG src={ backgroundUrl }
                                afterInjection={ svg => {
                                    svg.style.width = '240px';
                                    svg.style.height = '240px';
                                } }
                            ></ReactSVG>
                            {/* <div style={ {
                                background: `url(${backgroundUrl}) #D3D3D3`,
                                backgroundSize: 'cover',
                                width: '360px',
                                height: '240px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '16px'
                            } } /> */}
                        </div>

                        <div className='font-semibold text-lg block mt-6 mb-2'>{ title }</div>
                        {/* <div>{ subtitle }</div> */ }
                        <div className='text-sm pr-4 text-neutral-500'>{ description }</div>
                        <div className='mt-4 mb-2 text-xs text-neutral-500'>Reviewed by</div>
                        <div className='font-medium text-sm'>{ reviewer_name }</div>
                        <div className='mt-6 mb-4 font-semibold'>Choose your narrator</div>
                        <div>
                            {
                                (narrations ?? []).map((val) => {
                                    let lang = 'English';
                                    if (val.language === 'id') {
                                        lang = 'Indonesia';
                                    }

                                    return (
                                        <div key={ val.exercise_narration_code } className='border border-solid rounded-full text-center border-slate-400 font-medium px-4 py-4 my-2'>{ val.audio_title } ({ lang })</div>
                                    );
                                })
                            }
                        </div>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <ButtonStyle className='btn-primary' title='Actived' onClick={ props.onHide } style={ {
                        marginTop: '8px',
                        marginBottom: '16px',
                        marginRight: '12px',
                        marginLeft: '12px',
                    } } >
                        Confirm
                    </ButtonStyle>
                </DialogActions>
            </>
        </ModalSimple>
    );
}