import { utils } from "utils";

export const splitChart = (data: any[]) => {
	const lastDate = utils.lastDate();
	const temp = Array<number>(lastDate).fill(0);

	for (const item of data) {
		const itemDay = Number(item.date.split("/")[0]);

		temp[itemDay - 1] = item.total;
	}

	return temp;
};

export const makeCumulative = (data: number[]) => {
	const length = data.length;
	const temp = Array<number>(length).fill(0);

	let lastCumulative = 0;
	for (let i = 0; i < length; i++) {
		const val = lastCumulative + data[i];

		lastCumulative = val;
		temp[i] = val;
	}

	return temp;
};
