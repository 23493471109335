import React, { useEffect, useState } from 'react';
import service from 'service';
import { Content } from './Content';
import { AcquisitionOrganization, AcquisitionRegistered } from './Detail';
import { makeCumulative, splitChart } from './Helper';

export default function MetricContent(props: {
	orgCode: string, departCode: string, industryName: string,
	monthValue: number, yearValue: number;
}) {
	return (
		<>
			<Organization { ...props }></Organization>
			<Registered { ...props }></Registered>
		</>
	);
}

function Organization(props: {
	orgCode: string, departCode: string, industryName: string,
	monthValue: number, yearValue: number;
}) {
	// Local State
	const [values, setValues] = useState<number[]>([]);
	const [labels, setLabels] = useState<string[]>([]);
	const [stepSize, setStepSize] = useState(0);

	const initAnalytic = async () => {
		const resp = await service.metric.getOrganizationMetric({
			departCode: props.departCode,
			industry: props.industryName,
			month: props.monthValue,
			year: props.yearValue,
			orgCode: props.orgCode,
		});
		const countData = resp.pagination.count ?? 0;
		if (countData === 0 || resp.data.length === 0) {
			setValues([]);
			setLabels([]);
		} else {
			const values = makeCumulative(
				splitChart(resp.data)
			);

			const labels: string[] = [];
			for (let index = 1; index <= values.length; index++) {
				labels.push(`${index}`);
			}

			let maxActive = 0;
			for (const item of resp.data) {
				if (maxActive < item.total) {
					maxActive = item.total;
				}
			}

			setStepSize(Math.round(maxActive / 4));
			setValues(values);
			setLabels(labels);
		}
	};

	useEffect(() => {
		initAnalytic();
	}, [props]);

	return <Content
		{ ...props }
		stepSize={ stepSize }
		labels={ labels }
		values={ values }
		title="Number of Organisations"
		detail={ <AcquisitionOrganization></AcquisitionOrganization> }
	></Content>;
}

function Registered(props: {
	orgCode: string, departCode: string, industryName: string,
	monthValue: number, yearValue: number, isMinimal?: boolean;
}) {
	// Local State
	const [values, setValues] = useState<number[]>([]);
	const [labels, setLabels] = useState<string[]>([]);
	const [stepSize, setStepSize] = useState(0);

	const initAnalytic = async () => {
		const resp = await service.metric.getOrganizationRegisteredMetric({
			departCode: props.departCode,
			industry: props.industryName,
			month: props.monthValue,
			year: props.yearValue,
			orgCode: props.orgCode,
		});
		const countData = resp.pagination.count ?? 0;
		if (countData === 0 || resp.data.length === 0) {
			setValues([]);
			setLabels([]);
		} else {
			const values = makeCumulative(
				splitChart(resp.data)
			);

			const labels: string[] = [];
			for (let index = 1; index <= values.length; index++) {
				labels.push(`${index}`);
			}

			let maxActive = 0;
			for (const item of resp.data) {
				if (maxActive < item.total) {
					maxActive = item.total;
				}
			}

			setStepSize(Math.round(maxActive / 4));
			setValues(values);
			setLabels(labels);
		}
	};

	useEffect(() => {
		initAnalytic();
	}, [props]);

	return <Content
		{ ...props }
		stepSize={ stepSize }
		labels={ labels }
		values={ values }
		title="Number of Registered Code"
		detail={ <AcquisitionRegistered></AcquisitionRegistered> }
	></Content>;
}
