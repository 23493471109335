import { IMonth } from "interfaces/Date";

const months = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

export const getMonths = () => {
	return months.map<IMonth>((val, i, _) => {
		return {
			monthName: val,
			monthNumber: i + 1,
		};
	});
};

export const getYears = () => {
	const curYear = new Date().getFullYear();
	const years: number[] = [];

	for (let year = 2021; year <= curYear; year++) {
		years.push(year);
	}

	return years;
};

export const getBirthYears = () => {
	const years: number[] = [];

	let curYear = new Date().getFullYear();
	for (; curYear >= 1970; curYear--) {
		years.push(curYear);
	}

	return years;
};
