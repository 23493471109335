import React, { useEffect, useState } from 'react';
import { Box, Card, Container, Stack, Tab, Tabs, Typography } from '@mui/material';
import { a11yProps, TabPanel } from 'components/Panel';

import TabImportUser from './components/TabImportUser';
import { useAppSelector } from 'store';

export default function NotificationImport() {
	const [tabValue, setTabValue] = useState(0);
	const [labelImportTab, setLabelImportTab] = useState('Import User');
	const { jobs } = useAppSelector(state => state.jobs);

	return (
		<>
			<Container maxWidth='lg'>
				<Stack direction='row' alignItems='center' justifyContent='space-between' mb={ 4 }>
					<Box>
						<Typography variant='h4' sx={ { fontSize: '24px' } } >
							Import User
						</Typography>
					</Box>

				</Stack>
				<Box sx={ { width: '100%' } }>
					<Box sx={ {
						borderBottom: 1,
						borderColor: 'divider'
					} }>
						<Tabs value={ tabValue } onChange={ (_, i) => setTabValue(i) } aria-label='basic tabs example'>
							<Tab label={ labelImportTab } { ...a11yProps(0) } />
						</Tabs>
					</Box>
				</Box>
				<div className='h-8'></div>
				<TabPanel value={ tabValue } index={ 0 } >
					<Card>
						<TabImportUser
							jobs={ jobs }
						></TabImportUser>
					</Card>
				</TabPanel>
			</Container>
		</>
	);
}
