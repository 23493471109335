import React, { useEffect, useState } from 'react';
import {
	Box,
	Table,
	TableBody,
	TableContainer,
	TablePagination,
} from '@mui/material';

// hooks
import useTable from 'components/Table/useTable';
import { TableEmptyRows, TableHeadCustom } from 'components/Table';

import SalesRow from 'sections/@dashboard/member/Sales';
import { ContentModal } from './ContentModal';

const TABLE_HEAD = [
	{
		id: 'fullname',
		label: 'FULLNAME',
		alignRight: false
	},
	{
		id: 'mainEmail',
		label: 'MAIN EMAIL',
		alignRight: false
	},
	{
		id: 'phoneNumber',
		label: 'PHONE NUMBER',
		alignRight: false
	},
	{
		id: 'location',
		label: 'LOCATION',
		alignRight: false
	},
	{
		id: 'status',
		label: 'STATUS'
	},
	{ id: 'act' },
];


export default function TabSales(props: { list: any[], pagination: any, onChangePage: (number) => void, onChangeRowsPerPage: (number) => void, onEdit: (row: any) => void; onDelete: (row: any) => void; }) {
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('username');
	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const {
		dense,
		page,
		rowsPerPage,
		onSort,
	} = useTable();

	const dataFiltered = applySortFilter({
		list: props.list,
		comparator: getComparator(order, orderBy),
	});

	const denseHeight = dense ? 52 : 72;

	return (
		<div>
			<TableContainer sx={ {
				minWidth: 800,
				position: 'relative'
			} }>

				<Table size={ dense ? 'small' : 'medium' }>
					<TableHeadCustom
						order={ order }
						orderBy={ orderBy }
						onRequestSort={ handleRequestSort }
						headLabel={ TABLE_HEAD }
						rowCount={ props.list.length }
						onSort={ onSort }
					/>

					<TableBody>
						{
							dataFiltered.map((row, index) => {
								return (
									<SalesRow
										key={ index }
										row={ row }
										onDelete={ () => props.onDelete(row) }
										onEdit={ () => props.onEdit(row) }
									/>
								);
							})
						}

						<TableEmptyRows height={ denseHeight } emptyRows={ dataFiltered.length } />

					</TableBody>
				</Table>
			</TableContainer>
			<Box sx={ { position: 'relative' } }>
				<TablePagination
					rowsPerPageOptions={ [1, 10, 25, 100] }
					component='div'
					labelRowsPerPage='Items per page: '
					count={ props.pagination?.count || 0 }
					rowsPerPage={ props.pagination?.limit || 10 }
					page={ props.pagination?.page - 1 || 0 }
					onPageChange={ (e, pg) => props.onChangePage(pg) }
					onRowsPerPageChange={ (e) => props.onChangeRowsPerPage(e.target.value) }
				/>
			</Box>
		</div>
	);
}

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}
function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter({ list, comparator }) {

	const stabilizedThis = list.map((el, index) => [el, index]);

	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});

	list = stabilizedThis.map(el => el[0]);

	return list;
}
