import React from 'react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import './index.css';

// eslint-disable-next-line no-undef
const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
	<HelmetProvider>
		<App />
	</HelmetProvider>,
);

reportWebVitals();
