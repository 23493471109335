import api from "./api";

export const addCourseChapter = async (body: { chapter_name: string }) => {
	const resp = await api.post("/v1/courses/chapter", body);

	return resp.data;
};

export const addCourse = async (body: {
	title: string;
	chapter_code: string;
	content: string;
	ordering: number;
	reviewer_code: string;
	bg_image_url: string;
	status: string;
}) => {
	const resp = await api.post("/v1/courses", body);

	return resp.data;
};

export const addCourseTranslation = async (body: {
	course_code: string;
	title: string;
	content: string;
	language: string;
}) => {
	const resp = await api.post(
		`/v1/courses/translation/${body.course_code}`,
		body
	);

	return resp.data;
};

export const getCourseTranslation = async (params: {
	course_code: string;
	language: string;
}) => {
	const resp = await api.get(
		`/v1/courses/translation/${params.course_code}/${params.language}`
	);

	return resp.data;
};

export const updateCourseTranslation = async (body: {
	course_code: string;
	title: string;
	content: string;
	language: string;
}) => {
	const resp = await api.put(
		`/v1/courses/translation/${body.course_code}`,
		body
	);

	return resp.data;
};

export const updateCourse = async (body: {
	course_code: string;
	title: string;
	chapter_code: string;
	content: string;
	ordering: number;
	reviewer_code: string;
	bg_image_url: string;
	status: string;
}) => {
	const resp = await api.put(`/v1/courses/${body.course_code}`, body);

	return resp.data;
};

export const getListCourse = async (params: {
	keyword?: string;
	page?: number;
	limit?: number;
	order?: string;
	sort?: string;
	is_active?: boolean;
	reviewed_by?: string;
	chapter_code: string;
}) => {
	const resp = await api.get("/v1/courses", {
		params: params,
	});

	return resp.data;
};

export const getListCourseChapter = async (params: {
	keyword?: string;
	page?: number;
	limit?: number;
	order?: string;
	sort?: string;
}) => {
	const resp = await api.get("/v1/courses/chapter", {
		params: params,
	});

	return resp.data;
};

export const deleteCourse = async (courseCode: any) => {
	const resp = await api.delete(`/v1/courses/${courseCode}`);

	return resp.data;
};

export const repostCourse = async (courseCode: any) => {
	const resp = await api.put(`/v1/courses/repost/${courseCode}`);

	return resp.data;
};
