import { Common, Dprts } from "interfaces";
import { apiService } from "helpers";

import { DprtActionTypes } from "./actionTypes";
import { Endpoints } from "constant";
import { dispatch } from "store";

export const dprtProgress = (
	type: DprtActionTypes,
	payload?: Dprts.DprtPayload
) => {
	dispatch({
		type,
		payload,
	});
};

export const getDprt = (params?: Dprts.RequestData) => {
	return new Promise<any[]>(async (resolve, reject) => {
		dprtProgress(DprtActionTypes.GET_DPRTS_LIST_LOADING);

		const response: Common.ApiResponse<Dprts.Detail[]> = await apiService(
			`${Endpoints.org}${params?.body.organization_code}/departements?page=${params?.body.page}&limit=${params?.body.limit}`,
			{ method: "GET" },
			params?.query
		);

		const { object, data, error, pagination } = response;
		if (data) {
			const dd = object.data;
			const result: any[] = [];
			const pagiVal: any[] = [];

			pagiVal.push({
				count: pagination.count,
				page: pagination.page,
				limit: pagination.limit,
			});

			dd.map((item, i) => {
				result.push({
					departement_code: `${item.departement_code}`,
					topic_code: `${item.topic_code}`,
					name: `${item.name}`,
					created_date: `${item.created_date}`,
					updated_date: `${item.updated_date}`,
				});
			});

			dprtProgress(DprtActionTypes.GET_DPRTS_LIST_SUCCESS, {
				listData: result,
				pagination: pagiVal,
			});
			resolve(result);
		} else {
			// you can put toast here
			reject(
				dprtProgress(DprtActionTypes.GET_DPRTS_LIST_ERROR, { error: error })
			);
		}
	});
};

export const getDprtSeparate = (params?: Dprts.RequestData) => {
	return new Promise<any[]>(async (resolve, reject) => {
		dprtProgress(DprtActionTypes.GET_DPRTS_LIST_LOADING);

		const response: Common.ApiResponse<Dprts.Detail[]> = await apiService(
			`${Endpoints.org}${params?.body.organization_code}/departements?page=${params?.body.page}&limit=${params?.body.limit}`,
			{ method: "GET" },
			params?.query
		);

		const { object, data, error, pagination } = response;
		if (data) {
			const dd = object.data;
			const result: any[] = [];
			const pagiVal: any[] = [];

			pagiVal.push({
				count: pagination.count,
				page: pagination.page,
				limit: pagination.limit,
			});

			dd.map((item, i) => {
				result.push({
					topic_code: `${item.topic_code}`,
					name: `${item.name}`,
					departement_code: `${item.departement_code}`,
					created_date: `${item.created_date}`,
					updated_date: `${item.updated_date}`,
				});
			});
			resolve(result);
		} else {
			// you can put toast here
			reject(
				dprtProgress(DprtActionTypes.GET_DPRTS_LIST_ERROR, { error: error })
			);
		}
	});
};
