const Paths = {
	login: "/login",
	register: "/register",
	forgotPassword: "/forgot-password",
	forgotPasswordSuccess: "/forgot-password-success",
	dashboard: "/dashboard",
	post: "/post",
	forum: "/forum",
};

export default Paths;
