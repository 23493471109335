import { Common } from "interfaces";
import api from "./api";

export const getJobs = async (params: { compact?: boolean }) => {
	const resp = await api.get("/v1/jobs", {
		params: {
			compact: params.compact ?? false,
		},
	});

	return resp.data;
};

export const getJob = async (params: { jobId: any; compact?: boolean }) => {
	const resp = await api.get(`/v1/jobs/${params.jobId}`, {
		params: {
			compact: params.compact ?? false,
		},
	});

	return resp.data;
};

export const updateJobStatus = async (body: {
	id: number;
	job_status: string;
}) => {
	const resp = await api.put("/v1/jobs/status", body);

	return resp.data;
};

export const addJobImportMember = async (params: {
	file: any;
	organization_code?: string;
	type?: string;
}) => {
	const formData = new FormData();
	formData.append("uploadfile", params.file);
	formData.append("organization_code", params.organization_code ?? "");
	formData.append("type", params.type ?? "");

	const resp = await api.post("/v1/jobs/import-user", formData);
	return resp.data;
};
