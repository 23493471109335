import api from "./api";

export const fetchScheduleBySpecialist = async (params: {
  year: number;
  month: number;
  specialistCode: string;
  formatCall: string;
}) => {
  return api
    .get(`/v1/specialists/${params.specialistCode}/schedule/monthly`, {
      params: { ...params, format_call: params.formatCall },
    })
    .then((val) => val.data);
};

export const fetchScheduleAvailability = async (params: {
  limit: number;
  page: number;
  keyword?: string;
  status?: string;
  membership?: string;
}) => {
  return api
    .get("/v1/specialists/availability", {
      params: params,
    })
    .then((val) => val.data);
};

export const updateSchedule = async (params: {
  psychologCode: string;
  scheduleCode: string;
  dateRange: number;
  scheduleDay: any[];
  timezone: string;
  formatCall: string;
  location: string[];
}) => {
  return api
    .put(
      `/v1/specialists/${params.psychologCode}/schedule/${params.scheduleCode}`,
      {
        date_range: params.dateRange,
        schedule_day: params.scheduleDay,
        timezone: params.timezone,
        format_call: params.formatCall,
        location: params.location,
      }
    )
    .then((val) => val.data);
};

export const getSchedulesByDate = async (params: {
  specialistCode: string;
  date: string;
  formatCall: string;
}) => {
  return api
    .get(`/v1/specialists/${params.specialistCode}/schedule`, {
      params: {
        status: "active",
        format_call: params.formatCall,
        date: params.date,
      },
    })
    .then((val) => val.data);
};

export const generateSchedules = (params: { memberCode: string }) => {
  return api
    .put(`/v1/specialists/${params.memberCode}/generate`)
    .then((val) => val.data);
};
