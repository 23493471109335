import { Box, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { TableEmptyRows, TableHeadCustom } from 'components/Table';
import BannedWordRow from 'sections/@dashboard/bannedword/Deleted';
import { getListBannedWord } from 'service/bannedword';

const TABLE_HEAD = [
	{
		id: 'words',
		label: 'WORDS',
		alignRight: false
	},
	{ id: '' }
];
export default function TabDeleted(props: {
	search: string;
	forceUpdate: number;
	onCount: (val: number) => void;
	onRepost: (val: any) => void;
}) {
	const [data, setData] = useState<any[]>([]);
	const [limit, setLimit] = useState(10);
	const [page, setPage] = useState(1);
	const [count, setCount] = useState(0);

	const onChangeRowPerPage = val => {
		setPage(1);
		setLimit(val);
	};

	const onChangePage = val => {
		setPage(val + 1);
	};

	const init = async () => {
		const resp = await getListBannedWord({
			status: 'inactive',
			limit: limit,
			page: page,
			keyword: props.search,
		});

		const pagination = resp.pagination;

		setCount(pagination.count);
		setLimit(pagination.limit);
		setPage(pagination.page);
		setData(resp.data ?? []);
	};

	useEffect(() => {
		props.onCount(count);
	}, [count]);

	useEffect(() => {
		init();
	}, [props.search, props.forceUpdate, page, limit]);

	return (
		<div>
			<TableContainer sx={ {
				minWidth: 800,
				position: 'relative'
			} }>

				<Table size='medium'>
					<TableHeadCustom
						headLabel={ TABLE_HEAD }
						rowCount={ data.length }
					/>

					<TableBody>
						{
							data.map((row, index) => (
								<BannedWordRow
									key={ index }
									row={ row }
									onRepost={ () => props.onRepost(row) }
								/>
							))
						}

						<TableEmptyRows height={ 72 } emptyRows={ data.length } />

					</TableBody>
				</Table>
			</TableContainer>

			<Box sx={ { position: 'relative' } }>
				<TablePagination
					rowsPerPageOptions={ [10, 25, 100] }
					component='div'
					labelRowsPerPage='Items per page: '
					count={ count || 0 }
					rowsPerPage={ limit || 10 }
					page={ page - 1 || 0 }
					onPageChange={ (_, pg) => onChangePage(pg) }
					onRowsPerPageChange={ e => onChangeRowPerPage(e.target.value) }
				/>

			</Box>
		</div>
	);
}
