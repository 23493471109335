/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-undef */
/* eslint-disable object-curly-newline */

import React, { useCallback, useState, useEffect, useRef } from 'react';

import { TextField, CircularProgress, Button, InputLabel, IconButton, MenuItem, FormControl, ListItemText, Checkbox, Tabs, Tab, Typography, Box, Container, OutlinedInput, Toolbar, Card, Stack, InputAdornment } from '@mui/material';
import { styled, Theme, useTheme } from '@mui/material/styles';
import { Fonts, Colors, Images } from 'constant';
import { Endpoints } from 'constant';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import html2canvas from 'html2canvas';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { ModalDefault } from 'components/Modal';
import { useModal } from 'components/Modal/useModal';
import { Button as ButtonStyle } from 'screens/style';
import useTable from 'components/Table/useTable';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Iconify from 'screens/Layout/components/Iconify';
import { useAlert } from 'components/Alert/useAlert';
import {
	TitleModal, LabelModal, TextAreaModal
} from 'components/Modal/style';
import { green } from '@mui/material/colors';

// component
import ConfirmedTab from './components/ConfirmedTab';
import OutstandingTab from './components/OutstandingTab';

import { useAppSelector } from 'store';

import { editFlags } from 'store/forum/flag';
import { getFlags, getFlagsCount, getPreviewFlags } from 'store/forum/flag';
import { getMembers } from 'store/members';
// Dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';

const PaperComponent = (props: PaperProps) => {
	return (
		<Draggable
			handle='#draggable-dialog-title'
			cancel={ '[class*="MuiDialogContent-root"]' }
		>
			<Paper { ...props } />
		</Draggable>
	);
};

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

const TabPanel = (props: TabPanelProps) => {
	const { children, value, index, ...other } = props;
	return (
		<div
			role='tabpanel'
			hidden={ value !== index }
			id={ `simple-tabpanel-${index}` }
			aria-labelledby={ `simple-tab-${index}` }
			{ ...other }
		>
			{ value === index && (
				<Box sx={ { adding: '24px 0' } }>
					<Typography>{ children }</Typography>
				</Box>
			) }
		</div>
	);
};

const a11yProps = (index: number) => {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
};

// eslint-disable-next-line no-undef
const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
	width: 350,
	transition: theme.transitions.create(['box-shadow', 'width'], {
		easing: theme.transitions.easing.easeInOut,
		duration: theme.transitions.duration.shorter,
	}),
	'&.Mui-focused': { width: 400 },
	'& fieldset': {
		borderWidth: '1px !important',
		borderColor: `${theme.palette.grey[500_32]} !important`,
	},
}));
const context = [
	{
		value: 'forum',
		label: 'Forum'
	}, {
		value: 'group',
		label: 'Group'
	}, {
		value: 'poll',
		label: 'Poll'
	}, {
		value: 'regular',
		label: 'Post'
	}
];
// Multiple Select
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 188,
		},
	},
};

export default function ForumFlag() {

	const { list, loadingGet, error, pagination } = useAppSelector(state => state.flags);
	const { members } = useAppSelector(state => state.members);
	const [tablePagination, setTablePagination] = useState<any[]>(pagination);
	const [previewContext, setPreviewContext] = useState<any[]>([]);
	const [tableData, setTableData] = useState(list);
	const [tableMember, setTableMember] = useState(members);
	const [pagePagination, setPagePagination] = useState(1);
	const [pagex, setPagex] = useState(0);
	const [limitTable, setLimitTable] = useState(10);

	const [startFilter, setSF] = useState(false);
	const [contextF, setContextF] = useState('');
	const [flaggedUserF, setFlaggedUserF] = useState('');
	const [flaggerNameF, setFlaggerNameF] = useState('');

	// Label Tab

	const [labelConfirmedTab, setLabelConfirmedTab] = useState('');
	const [labelOutstandingTab, setLabelOutstandingTab] = useState('');

	const getAnyPage = params => {

		const pagar = pagex < params ? pagex === 0 ? params + pagex : params + 1 : params - pagex;
		loadDataPagi(params, tabName);
	};

	const handleSearch = q => {
		const page = pagePagination;
		const limit = 10;
		const context = contextFilter;
		const comment_by = flaggerFilter;
		const flagged_by = flaggedFilter;
		const is_confirm = tabName !== 'outstanding';
		const keyword = q;

		const payload = {
			page,
			limit,
			context,
			comment_by,
			flagged_by,
			is_confirm,
			keyword
		};

		getFlags({ body: payload }).then(function (e) {
			setTablePagination(prevTablePagination => (e));
			setRunUseEffect(true);
		});
	};

	const processConfirmFlag = (comment_code, stt) => {
		const confirm = stt;
		const payload = {
			confirm
		};

		editFlags({
			body: payload,
			cm: comment_code
		}).then(function (e) {
			loadDataPagi(pagex, tabName);
		})
			.catch(function (e) {
				console.log('Error Add Posts');
			});
	};

	const onChangeRowsPerPage = (event: { target: { value: string; }; }) => {
		changeLoadData(pagex, tabName, parseInt(event.target.value));

	};

	const changeLoadData = (params, status, limitParams) => {
		setLimitTable(limitParams);
		if (params < 0) {
			setPagex(0);
		} else {
			setPagex(params);
		}
		const page = params + 1;
		const limit = limitParams;
		const context = contextFilter;
		const comment_by = flaggerFilter;
		const flagged_by = flaggedFilter;
		const is_confirm = status !== 'outstanding';
		const payload = {
			page,
			limit,
			context,
			comment_by,
			flagged_by,
			is_confirm
		};
		getFlags({ body: payload }).then(function (e) {
			setTablePagination(prevTablePagination => (e));
		});

	};

	const [contextFilter, setContextFilter] = useState<string[]>([]);
	const [flaggedFilter, setFlaggedFilter] = useState<string[]>([]);
	const [flaggerFilter, setFlaggerFilter] = useState<string[]>([]);

	const loadDataPagi = (params, confirm) => {

		if (params < 0) {
			setPagex(0);
		} else {
			setPagex(params);
		}
		const page = params + 1;
		const limit = limitTable;
		const context = contextFilter;
		const comment_by = flaggerFilter;
		const flagged_by = flaggedFilter;
		const is_confirm = confirm !== 'outstanding';
		const payload = {
			page,
			limit,
			context,
			comment_by,
			flagged_by,
			is_confirm
		};
		getFlags({ body: payload }).then(function (e) {
			setTablePagination(prevTablePagination => (e));
			getCountTab();
		});
	};
	// Modal
	const { isShown, toggle } = useModal();

	// modal dialog
	const [openDialog, setOpenDialog] = React.useState(false);

	const [actionModal, setActionModal] = useState('');

	// GetCountLabelTemp
	const [tempCountLabel, setTempCountLabel] = useState(0);

	const handleDelete = (post_code, content) => {
		setOpenDialog(true);
	};

	const handleClose = () => {
		setOpenDialog(false);
	};

	const setAction = (action, params) => {
		// if (action === 'edit') {
		// 	setValueFormEdit(params);
		// }
		setActionModal(action);
		toggle();
	};
	const [runUseEffect, setRunUseEffect] = useState(false);

	const getCountTab = () => {
		getFlagsCount().then(function (e) {
			setLabelConfirmedTab('Confirmed (' + e[0].confirmed_count + ')');
			setLabelOutstandingTab('Outstanding (' + e[0].outstanding_count + ')');
		});
	};

	useEffect(() => {
		if (runUseEffect === false) {
			const page = pagePagination;
			const limit = 10;
			const context = contextFilter;
			const comment_by = flaggerFilter;
			const flagged_by = flaggedFilter;
			const is_confirm = tabName !== 'outstanding';

			const payload = {
				page,
				limit,
				context,
				comment_by,
				flagged_by,
				is_confirm
			};

			getFlags({ body: payload }).then(function (e) {
				setTablePagination(prevTablePagination => (e));

				setRunUseEffect(true);
			});

			if (members.length === 0) {
				const limit = 100;
				const payload = {
					limit
				};
				getMembers({ body: payload }).then(function (e) {
					// Activity your feedback successfully
				});
			}
		}

		setTableData(list);
		setTableMember(members);

		if (labelOutstandingTab === '') {

			getCountTab();

		}

		if (startFilter === true) {
			loadDataFilter(pagex, tabName);
		}
		setRunUseEffect(true);

	}, [
		startFilter,
		contextFilter,
		contextF,
		flaggedUserF,
		flaggerNameF,
		list,
		members,
		tablePagination
	]);

	// Tabs
	const [value, setValue] = React.useState(0);
	const [tabName, setTabName] = React.useState('outstanding');
	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setTabName(getTabName(newValue));
		setValue(newValue);
		setContentContext([]);
		setContextFilter([]);
		setContentFlaggerName([]);
		setContentFlaggedUser([]);
		// loadDataChange(pagex, getTabName(newValue));
		setLimitTable(10);
		loadDataChange(0, getTabName(newValue));
	};
	const getTabName = params => {
		if (params === 0) {
			return 'outstanding';
		} else {
			return 'confirmed';
		}
	};

	// Multiple Select
	const theme = useTheme();
	const [contentContext, setContentContext] = React.useState<string[]>([]);
	const [contentFlaggedUser, setContentFlaggedUser] = React.useState<string[]>([]);

	// Datepicker
	const [valueD, setValueD] = React.useState<Date | null>(null);
	const [valueT, setValueT] = React.useState<Date | null>(null);

	const handleChangeContext = (event: SelectChangeEvent<typeof contentContext>) => {
		const {
			target: { value },
		} = event;
		setContextFilter(
			// On autofill we get a stringified value.
			typeof value === 'string' ? value.split(',') : value,
		);
		setContentContext(
			// On autofill we get a stringified value.
			typeof value === 'string' ? value.split(',') : value,
		);
	};

	const loadDataFilter = (params, confirm) => {
		const page = params + 1;
		const limit = limitTable;
		const context = contextF;
		const comment_by = flaggedUserF;
		const flagged_by = flaggerNameF;
		const is_confirm = confirm !== 'outstanding';

		const payload = {
			page,
			limit,
			context,
			comment_by,
			flagged_by,
			is_confirm,
		};

		getFlags({ body: payload }).then(function (e) {
			setTablePagination(prevTablePagination => (e));
			setSF(false);
		});
	};
	const loadDataChange = (params, confirm) => {
		const page = params + 1;
		const limit = limitTable;
		const context = '';
		const comment_by = '';
		const flagged_by = '';
		const is_confirm = confirm !== 'outstanding';

		const payload = {
			page,
			limit,
			context,
			comment_by,
			flagged_by,
			is_confirm,
		};

		getFlags({ body: payload }).then(function (e) {
			setTablePagination(prevTablePagination => (e));
			setSF(false);
		});
	};

	const getPreview = params => {
		const tc = params;
		const payload = {
			tc
		};
		getPreviewFlags({ body: payload }).then(function (e) {
			setPreviewContext(e);
		});
	};

	const handleChangeFlaggedUser = (event: SelectChangeEvent<typeof contentFlaggedUser>) => {
		const {
			target: { value },
		} = event;
		setContentFlaggedUser(
			// On autofill we get a stringified value.
			typeof value === 'string' ? value.split(',') : value,
		);
	};

	const addValContextF = event => {
		setSF(true);
		setContextF(event.currentTarget.getAttribute('data-valcontext'));
	};

	const addValFlaggedUserF = event => {
		setSF(true);
		setFlaggedUserF(event.currentTarget.getAttribute('data-valfu'));
	};

	const addValFlaggerNameF = event => {
		setSF(true);
		setFlaggerNameF(event.currentTarget.getAttribute('data-valfn'));
	};

	const [contentFlaggerName, setContentFlaggerName] = React.useState<string[]>([]);
	const handleChangeFlaggerName = (event: SelectChangeEvent<typeof contentFlaggerName>) => {
		const {
			target: { value },
		} = event;
		setContentFlaggerName(
			// On autofill we get a stringified value.
			typeof value === 'string' ? value.split(',') : value,
		);
	};

	return (
		<React.Fragment>
			<Container maxWidth='lg'>
				<Stack direction='row' alignItems='center' justifyContent='space-between' mb={ 4 }>
					<Box>
						<Typography variant='h4' sx={ { fontSize: '24px' } } >
							Flag Comment
						</Typography>
					</Box>

					<SearchStyle
						onChange={ e => handleSearch(e.target.value) }
						placeholder='Search'
						startAdornment={
							<InputAdornment position='start'>
								<Iconify icon='eva:search-fill' sx={ {
									color: 'text.disabled',
									width: 25,
									height: 25
								} } />
							</InputAdornment>
						}
					/>
				</Stack>
				<Box sx={ { width: '100%' } }>
					<Box sx={ {
						borderBottom: 1,
						borderColor: 'divider'
					} }>
						<Tabs value={ value } onChange={ handleChange } aria-label='basic tabs example'>
							<Tab label={ labelOutstandingTab } { ...a11yProps(0) } />
							<Tab label={ labelConfirmedTab } { ...a11yProps(1) } />
						</Tabs>
					</Box>
				</Box>
				<Box sx={ {
					width: '100%',
					margin: '0',
					paddingTop: '20px',
					paddingBottom: '20px',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
				} }>
					<FormControl sx={ {
						m: 0,
						width: 188
					} }>
						<Select
							displayEmpty
							value={ contentContext }
							onChange={ handleChangeContext }
							input={ <OutlinedInput /> }
							renderValue={ selected => {
								if ((selected.length === 0) || (selected[0] === '')) {
									return <em>All Context</em>;
								}

								return selected;
							} }
							MenuProps={ MenuProps }
							inputProps={ { 'aria-label': 'Without label' } }
							sx={ { height: '40px', fontFamily: 'Inter-Medium', fontSize: '14px' } }
						>
							<MenuItem onClick={ addValContextF } data-valcontext='' value=''>
								<ListItemText primary='All Context' sx={ { fontSize: '14px' } } />
							</MenuItem>
							{ context.map(ct => (
								<MenuItem key={ ct.value } onClick={ addValContextF } data-valcontext={ ct.value } value={ ct.label }>
									<ListItemText primary={ ct.label } sx={ { fontSize: '14px' } } />
								</MenuItem>
							)) }
						</Select>
					</FormControl>

					<FormControl sx={ {
						m: 1,
						width: 238
					} }>
						<Select
							displayEmpty
							value={ contentFlaggedUser }
							onChange={ handleChangeFlaggedUser }
							input={ <OutlinedInput /> }
							renderValue={ selected => {
								if (selected.length === 0) {
									return <em>Flagged User</em>;
								}
								return selected;
							} }
							MenuProps={ MenuProps }
							inputProps={ { 'aria-label': 'Without label' } }
							sx={ { height: '40px', fontSize: '14px', fontFamily: 'Inter-Medium' } }
						>
							{ members.map(fu => (
								<MenuItem key={ fu.member_code } value={ fu.username } onClick={ addValFlaggedUserF } data-valfu={ fu.member_code }>
									<ListItemText primary={ fu.username } sx={ { fontSize: '14px' } } />
								</MenuItem>
							)) }
						</Select>
					</FormControl>

					<FormControl sx={ {
						m: 1,
						width: 238
					} }>
						<Select
							displayEmpty
							value={ contentFlaggerName }
							onChange={ handleChangeFlaggerName }
							input={ <OutlinedInput /> }
							renderValue={ selected => {
								if (selected.length === 0) {
									return <em>Flagger Name</em>;
								}
								return selected;
							} }
							MenuProps={ MenuProps }
							inputProps={ { 'aria-label': 'Without label' } }
							sx={ { height: '40px', fontSize: '14px', fontFamily: 'Inter-Medium' } }
						>
							{ members.map(fn => (
								<MenuItem key={ fn.member_code } value={ fn.username } onClick={ addValFlaggerNameF } data-valfn={ fn.member_code }>
									<ListItemText primary={ fn.username } sx={ { fontSize: '14px' } } />
								</MenuItem>
							)) }
						</Select>
					</FormControl>

					<Box sx={ {
						flex: 3,
						textAlign: 'right'
					} }> <></>
					</Box>

				</Box>
				<TabPanel value={ value } index={ 1 }>
					{ value === 1 ?
						<>
							<Card>
								<ConfirmedTab
									preview={ previewContext }
									list={ tableData }
									getPreview={ tc => getPreview(tc) }
									pagination={ tablePagination }
									loadDataPagi={ (params, tabNama) => loadDataPagi(params, tabNama) }
									getChangeRows={ onChangeRowsPerPage }
									getAnyPage={ getAnyPage }
									onEdit={ (action, params) => setAction(action, params) }
									handleDelete={ (post_code, content) => handleDelete(post_code, content) } />
							</Card>
						</> : 'Empty Tab' }
				</TabPanel>
				<TabPanel value={ value } index={ 0 }>
					{ value === 0 ?
						<>
							<Card>
								<OutstandingTab
									preview={ previewContext }
									list={ tableData }
									getPreview={ tc => getPreview(tc) }
									processConfirmFlag={ (cm, bol) => processConfirmFlag(cm, bol) }
									pagination={ tablePagination }
									loadDataPagi={ (params, tabNama) => loadDataPagi(params, tabNama) }
									getChangeRows={ onChangeRowsPerPage }
									getAnyPage={ getAnyPage }
									onEdit={ (action, params) => setAction(action, params) }
									handleDelete={ (post_code, content) => handleDelete(post_code, content) } />
							</Card>
						</> : 'Empty Tab' }
				</TabPanel>

				<Dialog
					open={ openDialog }
					onClose={ handleClose }
					PaperComponent={ PaperComponent }
					aria-labelledby='draggable-dialog-title'
				>
					<DialogTitle style={ { cursor: 'move' } } id='draggable-dialog-title'>
						Delete Poll
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Apakah Anda yakin akan menghapus data poll dengan content <b> "" </b> ?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button autoFocus onClick={ handleClose }>
							Cancel
						</Button>
						<Button>Delete</Button>
					</DialogActions>
				</Dialog>
			</Container>
		</React.Fragment>
	);
}