import api from "./api";

export const getListTopic = async (params: {
	limit: number;
	page: number;
	offset?: number;
	keyword?: string;
	sort?: string;
	order?: string;
	cat_code?: string;
	status?: string;
}) => {
	params.order = params.sort ?? "topics.name";
	params.sort = params.sort ?? "asc";

	const resp = await api.get("/v1/topics", { params: params });
	return resp.data;
};

export const addTopic = async (params: {
	image_url: string;
	name: string;
	description: string;
	category_code: string;
}) => {
	const resp = await api.post("/v1/topics", params);
	return resp.data;
};

export const deleteTopic = async (params: { topic_code: string }) => {
	const resp = await api.delete(`/v1/topics/${params.topic_code}`);
	return resp.data;
};

export const repostTopic = async (params: { topic_code: string }) => {
	const resp = await api.put(`/v1/topics/repost/${params.topic_code}`);
	return resp.data;
};

export const editTopic = async (params: {
	image_url: string;
	name: string;
	description: string;
	category_code: string;
	topic_code: string;
}) => {
	const resp = await api.put(`/v1/topics/${params.topic_code}`, params);
	return resp.data;
};

export const modifyTopicTranslation = async (params: {
	language: string;
	name: string;
	description: string;
	topic_code: string;
}) => {
	const resp = await api.post(
		`/v1/topics/translation/${params.topic_code}`,
		params
	);
	return resp.data;
};

export const getTopicTranslation = async (params: {
	language: string;
	topic_code: string;
}) => {
	const resp = await api.get(
		`/v1/topics/translation/${params.topic_code}/${params.language}`
	);
	return resp.data;
};
