import React, { useState } from 'react';

import { MenuItem, FormControl, ListItemText, Tabs, Tab, Typography, Box, Container, OutlinedInput, Card, Stack, InputAdornment } from '@mui/material';
import { styled } from '@mui/material/styles';
import Select from '@mui/material/Select';
import Iconify from 'screens/Layout/components/Iconify';

// component
import TabConfirmed from './components/TabConfirmed';
import TabOutstanding from './components/TabOutstanding';

import { SearchStyle, TabPanel, a11yProps } from 'components/Panel';

const context = [
	{
		value: 'forum',
		label: 'Forum'
	}, {
		value: 'group',
		label: 'Group'
	}, {
		value: 'poll',
		label: 'Poll'
	}, {
		value: 'regular',
		label: 'Post'
	}
];

export default function FlagChat() {
	const [tabValue, setTabValue] = useState(0);

	const [filterContext, setFilterContext] = useState('');
	const [filterFlagUser, setFilterFlagUser] = useState('');
	const [filterFlagName, setFilterFlagName] = useState('');

	const onSearch = (val) => {
		console.log("Search", val);
	};

	return (
		<>
			<Container maxWidth='lg'>
				<Stack direction='row' alignItems='center' justifyContent='space-between' mb={ 4 }>
					<Box>
						<Typography variant='h4' sx={ { fontSize: '24px' } } >
							Flag User
						</Typography>
					</Box>

					<SearchStyle
						onChange={ e => onSearch(e.target.value) }
						placeholder='Search'
						startAdornment={
							<InputAdornment position='start'>
								<Iconify icon='eva:search-fill' sx={ {
									color: 'text.disabled',
									width: 25,
									height: 25
								} } />
							</InputAdornment>
						}
					/>
				</Stack>
				<Box sx={ { width: '100%' } }>
					<Box sx={ {
						borderBottom: 1,
						borderColor: 'divider'
					} }>
						<Tabs value={ tabValue } onChange={ (_, i) => setTabValue(i) } aria-label='basic tabs example'>
							<Tab label={ "Outstanding (85)" } { ...a11yProps(0) } />
							<Tab label={ "Confirmed (15)" } { ...a11yProps(1) } />
						</Tabs>
					</Box>
				</Box>
				<Box sx={ {
					width: '100%',
					margin: '0',
					paddingTop: '20px',
					paddingBottom: '20px',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
				} }>

					<FormControl sx={ {
						m: 0,
						width: 188
					} }>
						<Select
							displayEmpty
							value={ filterContext }
							onChange={ (e) => setFilterContext(e.target.value) }
							input={ <OutlinedInput /> }
							inputProps={ { 'aria-label': 'Without label' } }
							sx={ { height: '40px', fontFamily: 'Inter-Medium', fontSize: '14px' } }
						>
							<MenuItem value=''>
								<em>All Context</em>
							</MenuItem>
							{
								context.map(ct => (
									<MenuItem key={ ct.value } value={ ct.label }>
										<em>{ ct.label }</em>
									</MenuItem>
								))
							}
						</Select>
					</FormControl>

					<FormControl sx={ {
						m: 1,
						width: 238
					} }>
						<Select
							displayEmpty
							value={ filterFlagUser }
							onChange={ (e) => setFilterFlagUser(e.target.value) }
							input={ <OutlinedInput /> }
							inputProps={ { 'aria-label': 'Without label' } }
							sx={ { height: '40px', fontSize: '14px', fontFamily: 'Inter-Medium' } }
						>
							<MenuItem value=''>
								<em>All Flagged User</em>
							</MenuItem>
							{/* {
								members.map(fu => (
									<MenuItem key={ fu.member_code } value={ fu.username } onClick={ addValFlaggedUserF } data-valfu={ fu.member_code }>
										<ListItemText primary={ fu.username } sx={{fontSize: '14px'}} />
									</MenuItem>
								))
							} */}
						</Select>
					</FormControl>

					<FormControl sx={ {
						m: 1,
						width: 238
					} }>
						<Select
							displayEmpty
							value={ filterFlagName }
							onChange={ (e) => setFilterFlagName(e.target.value) }
							input={ <OutlinedInput /> }
							inputProps={ { 'aria-label': 'Without label' } }
							sx={ { height: '40px', fontSize: '14px', fontFamily: 'Inter-Medium' } }
						>
							<MenuItem value=''>
								<em>All Flagger Name</em>
							</MenuItem>
							{/* {
								members.map(fn => (
									<MenuItem key={ fn.member_code } value={ fn.username } data-valfn={ fn.member_code }>
										<ListItemText primary={ fn.username } sx={ { fontSize: '14px' } } />
									</MenuItem>
								))
							} */}
						</Select>
					</FormControl>

					<Box sx={ {
						flex: 3,
						textAlign: 'right'
					} }> <></>
					</Box>

				</Box>

				<TabPanel value={ tabValue } index={ 0 }>
					<Card>
						<TabOutstanding />
					</Card>
				</TabPanel>
				<TabPanel value={ tabValue } index={ 1 }>
					<Card>
						<TabConfirmed />
					</Card>
				</TabPanel>
			</Container>
		</>
	);
}
