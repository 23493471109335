import { Card } from '@mui/material';
import NoDataIcon from "assets/svg/analytic_nodata.svg";
import { ChartOptions } from 'chart.js';
import { DoughnutChart, LineChart } from 'components';
import { Colors } from 'constant';
import { ILineValues } from 'interfaces/chart';
import { IDetail, ISummary } from 'interfaces/voe';
import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';

export function Content(props: {
    detailStartIndex: number,
    detailLabels: string[],
    maxY?: number,
    summary: ISummary[],
    detailValues: IDetail[],
    averageScore: number,
    totalUser: number,
    title: string,
    values: ILineValues,
    valuesRaw: ILineValues;
    isMinimal: boolean;
}) {
    const [hide, setHide] = useState(true);
    const maxY = props.maxY ?? 10;
    const ticksStep = props.maxY ? Math.round(maxY / 4) : 2;

    return (
        <Card className='mb-8'>
            <div className='bg-slate-100 py-4 pl-4 flex justify-between'>
                <div className='font-bold text-xl pt-2'>{ props.title }</div>
            </div>
            {
                props.totalUser > 0 ?
                    <>
                        <div className='px-16 pt-6'>
                            <div className='flex'>
                                <div className='max-w-xs'>
                                    <div className='flex'>
                                        <div className='mr-10'>
                                            <div className='mb-5'>Total Filled Users</div>
                                            <div className='font-semibold text-lg text-blue-400'>{ props.totalUser } Users</div>
                                        </div>
                                        <div>
                                            <div className='mb-5'>Average Score</div>
                                            <div className='font-semibold text-lg text-blue-400'>{ props.averageScore.toFixed(2) }</div>
                                        </div>
                                    </div>
                                    <div className='mt-14'>
                                        <div>Sumary Breakdown</div>
                                        <div className='flex justify-between'>
                                            {
                                                props.summary.map((item, i) => {
                                                    return (
                                                        <div className='w-28 relative' key={ `summary-${i}` }>
                                                            <DoughnutChart colors={ [Colors.chart.getColor(5), Colors.grey.light] } showLegend={ false } labels={ ["Users", "Users"] } values={ item.values }></DoughnutChart>
                                                            <div className='absolute top-1/4 left-10 mt-1 font-semibold w-8 text-center'>{ item.values[0] }</div>
                                                            <div className='text-center h-12'>{ item.title }</div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                {
                                    !props.isMinimal ? (
                                        <>
                                            <div className='mx-16 bg-slate-300'>
                                                <div style={ { width: "1px" } }></div>
                                            </div>
                                            <div className='relative shrink-0 w-4'>
                                                <div
                                                    className='absolute left-0 top-1/2 w-full whitespace-nowrap'
                                                    style={ { transform: 'rotate(-90deg) translateY(-24px) translateX(-10px)' } }
                                                >Average Score</div>
                                            </div>
                                            <div className='flex-grow max-h-80'>
                                                <LineChart
                                                    labelFormatter={ (val, context) => {
                                                        const label = context.dataset.label as string;
                                                        const index = context.dataIndex;
                                                        const vals = props.valuesRaw[label];

                                                        return vals[index];
                                                    } }
                                                    tooltipFormatter={ (tooltip) => {
                                                        return `${tooltip.label}: ${props.valuesRaw[tooltip.dataset.label!][tooltip.parsed.x]}`;
                                                    } }
                                                    values={ props.values } ticksStep={ ticksStep } maxY={ maxY } ticksCallback={ (val) => `${val}` }
                                                ></LineChart>
                                                <div className='mt-6 text-center'>Weeks of the Month</div>
                                            </div>
                                        </>
                                    ) : null
                                }
                            </div>
                        </div>
                        {
                            props.detailValues.length > 0 && !props.isMinimal ?
                                <>
                                    <div onClick={ () => setHide(!hide) } className={ `${hide ? 'block' : 'hidden'} text-center cursor-pointer text-blue-500 mt-10 mb-5` }>Show Detail</div>
                                    <div className={ `${hide ? 'max-h-0 opacity-0' : 'max-h-100000 opacity-100'} px-20 transition-all duration-700` }>
                                        <div className='bg-slate-200 h-px my-10'></div>
                                        <div>
                                            {
                                                props.detailValues.map((item, i) => {
                                                    return (
                                                        <AssessmentQuestionItem startIndex={ props.detailStartIndex } labels={ props.detailLabels } color={ Colors.chart.getColor(i) } detail={ item } key={ `assess-${i}` }></AssessmentQuestionItem>
                                                    );
                                                })
                                            }
                                        </div>
                                        <div onClick={ () => setHide(!hide) } className='text-center cursor-pointer text-blue-500 mt-10 mb-5'>Hide Detail</div>
                                    </div>
                                </>
                                :
                                <div className='h-12'></div>
                        }
                    </>
                    :
                    <div className='py-28'>
                        <img className='mx-auto block' src={ NoDataIcon } alt="" />
                        <div className='text-center text-lg mt-10'>Sorry, no data is available</div>
                    </div>
            }
        </Card>
    );
}

export function AssessmentQuestionItem(props: { labels: string[], detail: IDetail, color: string; startIndex: number; }) {
    const optionsBar: ChartOptions<"bar"> = {
        responsive: true,
        indexAxis: "y",
        plugins: {
            legend: {
                display: false,
            }
        },
        scales: {
            y: {
                grid: {
                    display: false,
                },
                ticks: {
                    callback: (val) => `${Number(val) + props.startIndex}`,
                },
            },
        }
    };
    const dataBar = {
        labels: props.labels,
        datasets: [{
            label: "Users",
            data: props.detail.values,
            backgroundColor: [props.color],
            borderColor: [props.color],
            borderWidth: 1
        }]
    };

    return (
        <div className='mb-14'>
            <div>{ props.detail.title }</div >
            <div className='flex ml-12 mt-5'>
                <div className='basis-7/12 w-7/12'>
                    <Bar options={ optionsBar } data={ dataBar }></Bar>
                </div>
                <div className='basis-5/12 w-5/12 pl-28'>
                    <div>Average Score</div>
                    <div className='mt-5 text-lg font-semibold text-blue-400'>{ props.detail.average.toFixed(2) }</div>
                </div>
            </div>
        </div>
    );
}
