import React from 'react';
import { Stack, TableCell, TableRow, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { Images } from 'constant';
import { IVoidCallback } from 'interfaces/callback';

export default function SpecialistRateRow(props: {
    row: any;
    selected: boolean;
    onEdit?: IVoidCallback;
    onSelected: IVoidCallback;
    onDelete?: IVoidCallback;
}) {
    const {
        id,
        hourly_price,
        hourly_fee
    } = props.row;

    return (
        <TableRow key={ id } hover selected={ props.selected }>

            <TableCell component='th' sx={ {
                textTransform: 'capitalize',
                fontFamily: 'Fonts.interRegular'
            } }>
                <Typography variant='subtitle2' noWrap sx={ {
                    minWidth: '120px',
                } }>
                    { hourly_price }
                </Typography>
            </TableCell>

            <TableCell component='th' scope='row' padding='normal'>
                <Stack direction='row' alignItems='center' spacing={ 2 }>
                    <Typography variant='subtitle2' sx={ {
                        flexWrap: 'wrap',
                        width: '250px',
                        fontFamily: 'Fonts.interMedium'
                    } }>
                        { hourly_fee }
                    </Typography>
                </Stack>
            </TableCell>

            <TableCell align='left' sx={ { minWidth: '150px' } }>
                <Button onClick={ props.onEdit } sx={ { minWidth: 'auto !important' } }>
                    <img src={ Images.btn_edit } style={ { width: '30px' } } />
                </Button>

                <Button onClick={ props.onDelete } sx={ { minWidth: 'auto !important' } }>
                    <img src={ Images.btn_delete } style={ { width: '30px' } } />
                </Button>
            </TableCell>
        </TableRow>
    );
}
