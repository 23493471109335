import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Select from "@mui/material/Select";
import Iconify from "screens/Layout/components/Iconify";

// component
import TabActived from "./components/TabActivated";
import TabDeleted from "./components/TabDeleted";

// Dialog
import { ModalDefault } from "components/Modal";
import { ModalDelete } from "components/Modal/ModalSimple";
import { a11yProps, SearchStyle, TabPanel } from "components/Panel";
import { IVoidCallback } from "interfaces/callback";
import "react-loading-skeleton/dist/skeleton.css";
import { Button as ButtonStyle } from "screens/style";
import service from "service";
import ContentModal from "./components/ContentModal";
import PreviewModal from "./components/PreviewModal";
import { getListTopic } from "service/topic";
import Appbar from "components/Appbar";

export default function Insight() {
  // Options Table
  const [search, setSearch] = useState("");
  const [contentTopic, setContentTopic] = useState("");
  const [contentReviewed, setContentReviewed] = useState("");

  // Data Active
  const [paginationActive, setTablePagination] = useState<any[]>([]);
  const [dataActive, setTableData] = useState<any[]>([]);
  const [pageActive, setPageActive] = useState<number>(1);
  const [rowCountActive, setRowCountActive] = useState<number>(10);

  // Data Inactive
  const [dataInactive, setDataInactive] = useState<any[]>([]);
  const [paginationInactive, setPaginationInactive] = useState<any[]>([]);
  const [pageInactive, setPageInactive] = useState<number>(1);
  const [rowCountInactive, setRowCountInactive] = useState<number>(10);

  // Header
  const [listTopic, setListTopic] = useState<any[]>([]);
  const [listMember, setListMember] = useState<any[]>([]);

  // Tab Index
  const [tabValue, setTablIndex] = useState(0);

  // Label Tab
  const [labelActivedTab, setLabelActivedTab] = useState("Active");
  const [labelDeletedTab, setLabelDeletedTab] = useState("Deleted");

  // Modal
  const [isShowModalPreview, setShowModalPreview] = useState(false);
  const [isAddContent, setAddContent] = useState(false);
  const [isShowModalModify, setShowModalModify] = useState(false);
  const [currentItem, setCurrentInsight] = useState<any>({});
  const [isDefaultLanguage, setDefaultLanguage] = useState(true);
  const [isShowDelete, setShowDelete] = useState(false);

  // Use Effect
  useEffect(() => {
    if (!isShowModalModify && !isShowDelete) {
      setCurrentInsight({});
    }
  }, [isShowModalModify, isShowDelete]);

  useEffect(() => {
    if (!(isShowModalModify || isShowModalPreview)) {
      setDefaultLanguage(true);
      setAddContent(true);
      setCurrentInsight({});
    }
  }, [isShowModalModify, isShowModalPreview]);

  useEffect(() => {
    onChangeData();
  }, [search, contentTopic, contentReviewed]);

  useEffect(() => {
    onChangeDataActive();
  }, [pageActive, rowCountActive]);

  useEffect(() => {
    onChangeDataInactive();
  }, [pageInactive, rowCountInactive]);

  useEffect(() => {
    getListTopic({
      limit: 1000,
      page: 1,
    }).then((e) => {
      setListTopic(e.data);
    });

    service
      .getMembers({
        limit: 1000,
        page: 1,
        role: "psycholog",
        type: "individual",
      })
      .then((val) => {
        setListMember(val.data);
      });

    onChangeData();
  }, []);

  const onPreview = (rowItem) => {
    setCurrentInsight(rowItem);
    setShowModalPreview(true);
  };

  const toggleShowModal = () => {
    setShowModalModify(!isShowModalModify);
  };

  const toggleShowDelete = () => {
    setShowDelete(!isShowDelete);
  };

  const onChangeDataActive = () => {
    service
      .getListInsight({
        topicCode: contentTopic,
        reviewedBy: contentReviewed,
        search,
        active: "active",
        limit: rowCountActive,
        page: pageActive,
      })
      .then((resp) => {
        const pagination = resp.pagination;
        const data = resp.data;

        setPageActive(pagination.page);
        setRowCountActive(pagination.limit);

        setTableData(data);
        setTablePagination([pagination]);
      });
  };

  const onChangeDataInactive = () => {
    service
      .getListInsight({
        topicCode: contentTopic,
        reviewedBy: contentReviewed,
        search,
        active: "inactive",
        limit: rowCountInactive,
        page: pageInactive,
      })
      .then((resp) => {
        const pagination = resp.pagination;
        const data = resp.data;

        setPageInactive(pagination.page);
        setRowCountInactive(pagination.limit);

        setDataInactive(data);
        setPaginationInactive([pagination]);
      });
  };

  const onChangeData = async () => {
    service.getCountInsight().then((val) => {
      const data = val.data;

      setLabelActivedTab(`Actived (${data.active_count})`);
      setLabelDeletedTab(`Deleted (${data.inactive_count})`);
    });

    onChangeDataActive();
    onChangeDataInactive();
  };

  const onChangeRowsCountActive = (val) => {
    setRowCountActive(val);
  };

  const onChangePageActive = (val) => {
    setPageActive(val + 1);
  };

  const onChangeRowsCountInactive = (val) => {
    setRowCountInactive(val);
  };

  const onChangePageInactive = (val) => {
    setPageInactive(val + 1);
  };

  const onPreviewAction = async () => {
    if (tabValue === 1) {
      await service.repostInsight(currentItem.insight_code);

      onChangeData();
    }

    if (tabValue === 0 && !isDefaultLanguage) {
      setShowModalModify(true);
    }

    setShowModalPreview(false);
  };

  const onTranslation = async (row: any, lang: string) => {
    const resp = await service.getInsightTranslation({
      insight_code: row.insight_code,
      language: lang,
    });
    const data = resp.data;

    setCurrentInsight({
      ...row,
      title: data.title,
      content: data.content,
      translation: lang,
    });
    setDefaultLanguage(false);
    setAddContent(false);
    setShowModalPreview(true);
  };

  const onAddTranslation = (row: any, lang: string) => {
    setCurrentInsight({
      ...row,
      translation: lang,
    });

    setDefaultLanguage(false);
    setShowModalModify(true);
    setAddContent(true);
  };

  const onAddInsight = () => {
    setAddContent(true);
    toggleShowModal();
  };

  const onEdit = (rowItem) => {
    setAddContent(false);
    setCurrentInsight(rowItem);

    toggleShowModal();
  };

  const refreshUpdateSubmit = () => {
    toggleShowModal();
    onChangeData();
  };

  const onSubmit = async (data: any) => {
    let filePath = data.imageUrl;
    if (data.files.length > 0 && isDefaultLanguage) {
      const file = data.files[0];
      const resp = await service.uploadImage(file.origin);
      filePath = resp.data.file_path;
    }

    if (isAddContent) {
      if (isDefaultLanguage) {
        const resp = await service.insertInsight({
          insight_type: "text",
          topic_code: data.topic,
          reviewer_code: data.reviewer,
          title: data.title,
          bg_image_url: filePath,
          status: "active",
          content: data.content,
          emotions: data.emotions,
          expertises: data.expertises,
        });

        await service.addInsightTranslation({
          content: data.content,
          insight_code: resp.data.insight_code,
          language: "en",
          title: data.title,
        });
      } else {
        await service.addInsightTranslation({
          content: data.content,
          insight_code: currentItem.insight_code,
          language: data.language,
          title: data.title,
        });
      }

      return refreshUpdateSubmit();
    }

    if (isDefaultLanguage) {
      await service.updateInsight({
        insight_code: currentItem.insight_code,
        insight_type: "text",
        status: "active",
        topic_code: data.topic,
        reviewer_code: data.reviewer,
        title: data.title,
        bg_image_url: filePath,
        content: data.content,
        emotions: data.emotions,
        expertises: data.expertises,
      });

      await service.updateInsightTranslation({
        content: data.content,
        insight_code: currentItem.insight_code,
        language: "en",
        title: data.title,
      });
    } else {
      await service.updateInsightTranslation({
        content: data.content,
        insight_code: currentItem.insight_code,
        language: currentItem.translation,
        title: data.title,
      });
    }

    refreshUpdateSubmit();
  };

  const onDelete = (item) => {
    setCurrentInsight(item);

    toggleShowDelete();
  };

  const onConfirmDelete = async () => {
    await service.updateInsight({
      ...currentItem,
      status: "inactive",
      emotions: ((currentItem.emotions ?? []) as any[]).map(
        (val) => val.emotion_code
      ),
      expertises: ((currentItem.expertises ?? []) as any[]).map(
        (val) => val.expertise_code
      ),
    });

    toggleShowDelete();
    onChangeData();
  };

  return (
    <>
      <Container maxWidth="lg">
        <Appbar
          onSearch={(val) => setSearch(val)}
          title="Forum Article"
        ></Appbar>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Tabs
              value={tabValue}
              onChange={(_, val) => setTablIndex(val)}
              aria-label="basic tabs example"
            >
              <Tab label={labelActivedTab} {...a11yProps(0)} />
              <Tab label={labelDeletedTab} {...a11yProps(1)} />
            </Tabs>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            margin: "0",
            paddingTop: "20px",
            paddingBottom: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <FormControl
            sx={{
              m: 0,
              width: 188,
            }}
          >
            <Select
              displayEmpty
              value={contentTopic}
              onChange={(val) => setContentTopic(val.target.value)}
              input={<OutlinedInput />}
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                height: "40px",
                fontSize: "14px",
                fontFamily: "Inter-Medium",
              }}
            >
              <MenuItem value={""}>
                <em>Topic By</em>
              </MenuItem>
              {listTopic.map((topic) => (
                <MenuItem key={topic.topic_code} value={topic.topic_code}>
                  {topic.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            sx={{
              m: 1,
              width: 258,
            }}
          >
            <Select
              displayEmpty
              value={contentReviewed}
              onChange={(val) => setContentReviewed(val.target.value)}
              input={<OutlinedInput />}
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                height: "40px",
                fontSize: "14px",
                fontFamily: "Inter-Medium",
              }}
            >
              <MenuItem value={""}>
                <em>Reviewed By</em>
              </MenuItem>
              {listMember.map((sugges) => (
                <MenuItem
                  key={sugges.member_code}
                  data-valmem={sugges.member_code}
                  value={sugges.member_code}
                >
                  {sugges.username}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box
            sx={{
              flex: 3,
              textAlign: "right",
            }}
          >
            <Button
              onClick={onAddInsight}
              className="btn-action-filter-dark"
              sx={{ width: "180px" }}
              endIcon={<Iconify icon="eva:plus-fill" sx={{}} />}
            >
              Add New Article
            </Button>
          </Box>
        </Box>

        <TabPanel value={tabValue} index={0}>
          <Card>
            <TabActived
              data={dataActive}
              pagination={paginationActive}
              onDelete={onDelete}
              onEdit={(params) => onEdit(params)}
              onChangeRows={onChangeRowsCountActive}
              onChangePage={onChangePageActive}
              onTranslation={onTranslation}
              onAddTranslation={onAddTranslation}
              onPreview={onPreview}
            />
          </Card>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Card>
            <TabDeleted
              data={dataInactive}
              pagination={paginationInactive}
              onChangeRows={onChangeRowsCountInactive}
              onChangePage={onChangePageInactive}
              onPreview={onPreview}
            />
          </Card>
        </TabPanel>

        <ContentModal
          isDefaultlanguage={isDefaultLanguage}
          isAddContent={isAddContent}
          isShow={isShowModalModify}
          hide={toggleShowModal}
          listTopic={listTopic}
          listReviewer={listMember}
          value={currentItem}
          onSubmit={onSubmit}
        />

        <ModalDelete
          isOpen={isShowDelete}
          onClose={toggleShowDelete}
          onDelete={onConfirmDelete}
        />

        <ModalDefault
          isShown={isShowModalPreview}
          hide={() => setShowModalPreview(false)}
          title="Article Preview"
          reset={() => console.log("Clearout")}
          modalContent={<PreviewModal item={currentItem} />}
          modalFooter={
            <PreviewModalFooter
              onConfirm={onPreviewAction}
              actionTitle={
                tabValue === 0
                  ? isDefaultLanguage
                    ? "Confirm"
                    : "Edit"
                  : "Re-post"
              }
            />
          }
        />
      </Container>
    </>
  );
}

function PreviewModalFooter(props: {
  actionTitle: string;
  onConfirm: IVoidCallback;
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <ButtonStyle
        className="btn-primary"
        title="Actived"
        onClick={props.onConfirm}
        style={{ marginTop: "10px" }}
      >
        {props.actionTitle}
      </ButtonStyle>
    </Box>
  );
}
