import {
  Box,
  Card,
  Container,
  FormControl,
  InputAdornment,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Select from "@mui/material/Select";
import { ModalDelete } from "components/Modal/ModalSimple";
import { SearchStyle, TabPanelHidden, a11yProps } from "components/Panel";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import Iconify from "screens/Layout/components/Iconify";
import { updateSpecialistRate } from "service/billing";
import {
  getSpecialistTitleList,
  updateSpecialistStatus,
} from "service/specialist";
import ModalModify from "./components/ModalModify";
import TabActived from "./components/TabActived";
import Appbar from "components/Appbar";

export default function SpecialistRate() {
  // Options Table
  const [filterSearch, setFilterSearch] = useState("");
  const [filterTitle, setFilterTitle] = useState("");

  // Tab Index
  const [tabValue, setTablIndex] = useState(0);

  // Count Label
  const [activedCount, setActivedCount] = useState(0);

  // Force Update
  const [activedForceUpdate, setActivedForceUpdate] = useState(0);

  // Modal
  const [isShowModalModify, setShowModalModify] = useState(false);
  const [isShowModalDeleted, setShowModalDeleted] = useState(false);

  // Additional
  const [currentItem, setCurrentItem] = useState<any>({});

  // Specialist Titles
  const onFetchTitles = () => {
    return getSpecialistTitleList({ isActive: true, lang: "en" }).then(
      (val) => val.data ?? []
    );
  };
  const queryTitles = useQuery("fetchTitles", onFetchTitles);
  const titles = queryTitles.data ?? [];

  useEffect(() => {
    if (!isShowModalModify && !isShowModalDeleted) {
      setCurrentItem({});
    }
  }, [isShowModalModify, isShowModalDeleted]);

  const onEdit = (item: any) => {
    setCurrentItem(item);
    setShowModalModify(true);
  };

  const onDelete = (item: any) => {
    setCurrentItem(item);

    setShowModalDeleted(true);
  };

  const onConfirmDelete = async () => {
    await updateSpecialistStatus({
      psychologCode: currentItem.psycholog_code,
      status: "inactive",
    });

    setShowModalDeleted(false);

    setActivedForceUpdate(Math.random());

    toast.success("psychologist already inactive");
  };

  const onSubmit = async (val: any) => {
    const data = {
      hourly_fee: Number(val.video_fee),
      hourly_price: Number(val.video_price),
      rates: [
        {
          format_call: "voice_call",
          price: Number(val.voice_price),
          fee: Number(val.voice_fee),
        },
        {
          format_call: "video_call",
          price: Number(val.video_price),
          fee: Number(val.video_fee),
        },
        {
          format_call: "preview",
          price: Number(val.preview_price),
          fee: Number(val.preview_fee),
        },
      ],
    };

    await updateSpecialistRate(currentItem.specialist_rate_code, data);

    setActivedForceUpdate(Math.random());

    toast.success("specialist rate already updated!");
    setShowModalModify(false);
  };

  return (
    <>
      <Container maxWidth="lg">
        <Appbar
          onSearch={(val) => setFilterSearch(val)}
          title="Specialist Rates"
        ></Appbar>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Tabs
              value={tabValue}
              onChange={(_, val) => setTablIndex(val)}
              aria-label="basic tabs example"
            >
              <Tab label={`Actived (${activedCount})`} {...a11yProps(1)} />
            </Tabs>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        >
          <FormControl>
            <Select
              displayEmpty
              value={filterTitle}
              onChange={(e) => setFilterTitle(e.target.value)}
              sx={{
                height: "40px",
                fontSize: "14px",
                fontFamily: "Inter-Medium",
              }}
            >
              <MenuItem value="">
                <em>All Titles</em>
              </MenuItem>
              {titles.map((item: any) => (
                <MenuItem
                  key={item.specialist_title_code}
                  value={item.specialist_title_code}
                >
                  <em>{item.title}</em>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <TabPanelHidden value={tabValue} index={0}>
          <Card>
            <TabActived
              search={filterSearch}
              forceUpdate={activedForceUpdate}
              title={filterTitle}
              onEdit={onEdit}
              onDelete={onDelete}
              onCount={(val) => setActivedCount(val)}
            />
          </Card>
        </TabPanelHidden>

        <ModalModify
          currentItem={currentItem}
          isShow={isShowModalModify}
          onSubmit={onSubmit}
          toggle={() => setShowModalModify(false)}
        ></ModalModify>

        <ModalDelete
          isOpen={isShowModalDeleted}
          onClose={() => setShowModalDeleted(false)}
          onDelete={onConfirmDelete}
          title="Are you sure want to inactive this psychologist?"
        ></ModalDelete>
      </Container>
    </>
  );
}
