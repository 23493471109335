import { Endpoints } from "constant";
import { ApiResponse, Query } from "interfaces/common";
import generateQueryString from "./generateQueryString";
import { LocalStorage } from "helpers";

type OptionsRequest<T> = {
	method: "GET" | "POST" | "DELETE" | "PUT";
	body?: T;
	headers?: HeadersInit;
};
const apiService = async <T = any>(
	path: string,
	{ method, body, headers }: OptionsRequest<T>,
	query?: Query
): Promise<ApiResponse<T>> => {
	if (headers) {
		headers = {
			...headers,
			Authorization: `${LocalStorage.getToken()}`,
			"X-Channel": "CMS",
		};
	} else {
		headers = {
			"Content-type": "application/json; charset=UTF-8",
			Authorization: `${LocalStorage.getToken()}`,
			"X-Channel": "CMS",
		};
	}
	try {
		const response = await fetch(
			`${Endpoints.baseUrl}${path}${generateQueryString(query)}`,
			{
				method: method,
				body: JSON.stringify(body),
				headers: headers,
			}
		);

		const res = await response.json();
		const resultsT = (await res) as T;
		const resultObject = await res;

		if (!response.ok) {
			return { error: resultsT, object: resultObject };
		}

		return {
			data: resultsT,
			object: resultObject,
			pagination: resultObject.pagination,
		};
	} catch (error) {
		throw new Error(error as any);
	}
};

export default apiService;
