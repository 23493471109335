import axios from "axios";
import { Config } from "constant";

// Gets Airtable
export const getsAirtable = async (table: string) => {
	const resp = await axios.get(
		`https://api.airtable.com/v0/${Config.airtable_app}/${table}`,
		{
			headers: {
				Authorization: "Bearer " + Config.airtable_key,
			},
		}
	);
	return resp.data;
};
