import React from 'react';
import { Box, Button, Card, Container, InputAdornment, Stack, Tab, Tabs, Typography } from '@mui/material';
import { ModalDelete } from 'components/Modal/ModalSimple';
import { a11yProps, SearchStyle, TabPanelHidden } from 'components/Panel';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Iconify from 'screens/Layout/components/Iconify';
import ModalModify from './components/ModalModify';
import TabActived from './components/TabActivated';
import TabDeleted from './components/TabDeleted';
import { addBannedWord, deleteBannedWord, repostBannedWord } from 'service/bannedword';

export default function Topic() {
	// Options Table
	const [filterSearch, setFilterSearch] = useState('');

	// Tab Index
	const [tabValue, setTablIndex] = useState(0);

	// Count Label
	const [activedCount, setActivedCount] = useState(0);
	const [deletedCount, setDeletedCount] = useState(0);

	// Force Update
	const [activedForceUpdate, setActivedForceUpdate] = useState(0);
	const [deletedForceUpdate, setDeletedForceUpdate] = useState(0);

	// Modal
	const [isShowModalModify, setShowModalModify] = useState(false);

	// Additional
	const [currentItem, setCurrentItem] = useState<any>({});
	const [isShowDelete, setShowDelete] = useState(false);

	useEffect(() => {
		if (!isShowModalModify && !isShowDelete) {
			setCurrentItem({});
		}
	}, [isShowModalModify, isShowDelete]);

	useEffect(() => {
		if (!isShowModalModify) {
			setCurrentItem({});
		}
	}, [isShowModalModify]);

	const onAddPost = () => {
		setShowModalModify(true);
	};

	const onSubmit = async (data: any) => {
		await addBannedWord(data);

		setShowModalModify(false);
		setActivedForceUpdate(Math.random());

		toast.info('banned word already updated');
	};

	const onDelete = (item: any) => {
		setCurrentItem(item);
		setShowDelete(true);
	};

	const onConfirmDelete = async () => {
		await deleteBannedWord({ banned_word_code: currentItem.banned_word_code });

		setActivedForceUpdate(Math.random());
		setDeletedForceUpdate(Math.random());
		setShowDelete(false);

		toast.info('banned word already deleted');
	};

	const onRepost = async (data: any) => {
		await repostBannedWord({ banned_word_code: data.banned_word_code });

		setDeletedForceUpdate(Math.random());
		setActivedForceUpdate(Math.random());

		toast.info('banned word already reposted');
	};

	return (
		<>
			<Container maxWidth='lg'>
				<Stack direction='row' alignItems='center' justifyContent='space-between' mb={ 4 }>
					<Box>
						<Typography variant='h4' sx={ { fontSize: '24px' } } >
							Banned Words
						</Typography>
					</Box>
				</Stack>
				<Box sx={ { width: '100%' } }>
					<Box sx={ {
						borderBottom: 1,
						borderColor: 'divider'
					} }>
						<Tabs value={ tabValue } onChange={ (_, val) => setTablIndex(val) } aria-label='basic tabs example'>
							<Tab label={ `Actived (${activedCount})` } { ...a11yProps(0) } />
							<Tab label={ `Deleted (${deletedCount})` } { ...a11yProps(1) } />
						</Tabs>
					</Box>
				</Box>
				<Box sx={ {
					width: '100%',
					margin: '0',
					paddingTop: '20px',
					paddingBottom: '20px',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
				} }>
					<SearchStyle
						onChange={ e => setFilterSearch(e.target.value) }
						placeholder='Search'
						startAdornment={
							<InputAdornment position='start'>
								<Iconify icon='eva:search-fill' sx={ {
									color: 'text.disabled',
									width: 25,
									height: 25
								} } />
							</InputAdornment>
						}
					/>

					<Box sx={ {
						flex: 3,
						textAlign: 'right'
					} }>
						<Button onClick={ onAddPost } className='btn-action-filter-dark' sx={ { width: '200px' } } endIcon={ <Iconify icon='eva:plus-fill' sx={ {} } /> }>
							Add Banned Words
						</Button>
					</Box>
				</Box>

				<TabPanelHidden value={ tabValue } index={ 0 }>
					<Card>
						<TabActived
							search={ filterSearch }
							forceUpdate={ activedForceUpdate }
							onDelete={ onDelete }
							onCount={ val => setActivedCount(val) }
						/>
					</Card>
				</TabPanelHidden>
				<TabPanelHidden value={ tabValue } index={ 1 }>
					<Card>
						<TabDeleted
							search={ filterSearch }
							forceUpdate={ deletedForceUpdate }
							onCount={ val => setDeletedCount(val) }
							onRepost={ onRepost }
						/>
					</Card>
				</TabPanelHidden>

				<ModalModify
					isShow={ isShowModalModify }
					onHide={ () => setShowModalModify(false) }
					value={ currentItem }
					onSubmit={ onSubmit }
				/>

				<ModalDelete
					isOpen={ isShowDelete }
					onClose={ () => setShowDelete(false) }
					onDelete={ onConfirmDelete }
					title='Are you sure want to delete this words?'
				/>
			</Container>
		</>
	);
}
