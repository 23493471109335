import api from "./api";

export const getListInsight = async (params: {
	topicCode: string;
	reviewedBy: string;
	search: string;
	active: string;
	limit: number;
	page: number;
	compact?: boolean;
}) => {
	const resp = await api.get("/v1/insights", {
		params: {
			insight_type: "text",
			status: params.active,
			page: params.page,
			limit: params.limit,
			keyword: params.search,
			topic_code: params.topicCode,
			reviewer_code: params.reviewedBy,
			min_resp: params.compact,
		},
	});

	return resp.data;
};

export const insertInsight = async (data: any) => {
	const resp = await api.post("/v1/insights", data);
	return resp.data;
};

export const addInsightTranslation = async (body: {
	insight_code: string;
	title: string;
	content: string;
	language: string;
}) => {
	const resp = await api.post(
		`/v1/insights/translation/${body.insight_code}`,
		body
	);

	return resp.data;
};

export const updateInsightTranslation = async (body: {
	insight_code: string;
	title: string;
	content: string;
	language: string;
}) => {
	const resp = await api.put(
		`/v1/insights/translation/${body.insight_code}`,
		body
	);

	return resp.data;
};

export const getInsightTranslation = async (params: {
	insight_code: string;
	language: string;
}) => {
	const resp = await api.get(
		`/v1/insights/translation/${params.insight_code}/${params.language}`
	);

	return resp.data;
};

export const updateInsight = async (data: any) => {
	const resp = await api.put(`/v1/insights/${data.insight_code}`, data);
	return resp.data;
};

export const deleteInsight = async (insightCode: string) => {
	const resp = await api.delete(`/v1/insights/${insightCode}`);
	return resp.data;
};

export const getCountInsight = async () => {
	const resp = await api.get("/v1/insights/count");
	return resp.data;
};

export const getInsight = async (insightCode: string) => {
	const resp = await api.get(`/v1/insights/${insightCode}`);
	return resp.data;
};

export const repostInsight = async (insightCode: any) => {
	const resp = await api.put(`/v1/insights/repost/${insightCode}`);

	return resp.data;
};
