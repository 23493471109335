const accessKey = {
	Flagged: "FLAGGED",
	Dashboard: "DASHBOARD",
	Content: "CONTENT",
	Daily: "DAILY",
	Organization: "ORGANIZATION",
	Individual: "INDIVIDUAL",
	Specialist: "SPECIALIST",
	Report: "REPORT",
	Billing: "BILLING",
	Admin: "ADMIN",
	ControlPanel: "CONTROL_PANEL",
};

export default accessKey;
