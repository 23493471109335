import axios from "axios";
import { Endpoints } from "constant";
import { LocalStorage } from "helpers";
import { toast } from "react-toastify";

const api = axios.create({
	baseURL: Endpoints.baseUrl,
	headers: {
		"Content-type": "application/json; charset=UTF-8",
		"X-Channel": "CMS",
		"X-TIMEZONE": 7,
	},
});

// Add a request interceptor
api.interceptors.request.use((req) => {
	const token = LocalStorage.getToken();
	req.headers!.Authorization = token!;

	return req;
});

// Add a response interceptor
api.interceptors.response.use(
	(resp) => resp,
	(error) => {
		let message = error.response.data.stat_msg;
		if (!message) {
			message = error.toString();
		}

		toast.error(message, { autoClose: false });

		return Promise.reject(error);
	}
);

export default api;
