import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ModalSimple } from "components/Modal/ModalSimple";
import { Colors, Endpoints, Fonts } from "constant";
import dayjs from "dayjs";
import { Add } from "iconsax-react";
import { IVoidCallback } from "interfaces/callback";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { LabelModal } from "screens/Login/style";
import { Button as ButtonStyle } from "screens/style";
import { fetchTimezone } from "service/timezone";
import { utils } from "utils";

const formatCallItems = [
  {
    key: "preview",
    label: "Preview 15-min",
  },
  {
    key: "video_call",
    label: "Video Call 60-min",
  },
  {
    key: "voice_call",
    label: "Voice Call 60-min",
  },
];

export default function ModalModify(props: {
  currentItem: any;
  isShow: boolean;
  toggle: IVoidCallback;
  onSubmit: (val: any) => void;
}) {
  // Form
  const [specialistTitle, setSpecialistTitle] = useState("");
  const [profileUrl, setProfileUrl] = useState("");
  const [username, setUsername] = useState("");
  const [calendarSync, setCalendarSync] = useState("");
  const [timezone, setTimezone] = useState("");
  const [dateRange, setDateRange] = useState("");
  const [noticePeriod, setNoticePeriod] = useState("");

  // Schedule Days
  const [schedules, setSchedules] = useState<any>({});

  const [isEnabled, setEnabled] = useState(false);

  const onTimezones = () => fetchTimezone().then((val) => val.data ?? []);
  const queryTimezone = useQuery("fetchTimezone", onTimezones);
  const timezones = queryTimezone.data ?? [];

  const checkFormValid = () => {
    const isFieldValid = dateRange.length > 0 && noticePeriod.length > 0;
    const isScheduleExist = Object.keys(schedules).length > 0;

    setEnabled(isFieldValid && isScheduleExist);
  };

  useEffect(() => {
    checkFormValid();
  }, [dateRange, noticePeriod, schedules]);

  useEffect(() => {
    if (!props.isShow) {
      setProfileUrl("");
      setUsername("");
      setSpecialistTitle("");
      setCalendarSync("");
      setTimezone("");
      setNoticePeriod("");
      setSchedules({});
    } else {
      const item = props.currentItem;
      const scheduleDays = item.schedule_day ?? [];
      const schedule: any = {};
      for (const item of scheduleDays) {
        const dayName = item.day as string;
        const times: any[] = [];

        for (const time of item.time) {
          const timeFroms = time.time_from.split(":");
          const timeTos = time.time_to.split(":");
          const timeFrom = new Date();
          timeFrom.setHours(Number(timeFroms[0]), Number(timeFroms[1]), 0, 0);

          const timeTo = new Date();
          timeTo.setHours(Number(timeTos[0]), Number(timeTos[1]), 0, 0);
          times.push({
            time_from: timeFrom,
            time_to: timeTo,
            format_calls: time.format_calls,
          });
        }

        schedule[dayName.toLocaleLowerCase()] = times;
      }

      setProfileUrl(item.psycholog_profile_url ?? "");
      setUsername(item.psycholog_username ?? "");
      setSpecialistTitle(item.psycholog_title ?? "");
      setCalendarSync(item.calendar_sync_status ?? "");
      setTimezone(item.timezone ?? "");
      setNoticePeriod(item.notice_period.toString());
      setDateRange(item.date_range.toString());
      setSchedules(schedule);
    }
  }, [props.isShow]);

  const onSubmit = async () => {
    if (Object.keys(schedules).length == 0) {
      toast.warn("input the schedules first!");
    }

    setEnabled(false);

    const scheduleDays: any[] = [];
    for (const key in schedules) {
      if (Object.prototype.hasOwnProperty.call(schedules, key)) {
        const items = schedules[key];
        const times: any[] = [];

        for (const item of items) {
          times.push({
            time_from: dayjs(item.time_from as Date).format("HH:mm"),
            time_to: dayjs(item.time_to as Date).format("HH:mm"),
            format_calls: item.format_calls,
          });
        }

        if (times.length > 0) {
          scheduleDays.push({
            day: utils.capitalizeFirstLetter(key),
            time: times,
          });
        }
      }
    }

    props.onSubmit({
      date_range: dateRange,
      timezone: timezone,
      schedule_day: scheduleDays,
      notice_period: Number(noticePeriod),
    });
  };

  const onAddSchedule = (day: string) => {
    const timeFrom = new Date();
    timeFrom.setHours(8, 0, 0, 0);

    const timeTo = new Date(timeFrom);
    timeTo.setHours(16);

    const times: any[] = schedules[day] ?? [];
    times.push({
      time_from: timeFrom,
      time_to: timeTo,
    });

    setSchedules({
      ...schedules,
      [day]: times,
    });
  };

  const onChangeSchedule = (day: string, index: number, newValue: any) => {
    const times: any[] = schedules[day] ?? [];
    if (times.length <= index) {
      return toast.error("invalid schedules. please restart!");
    }

    times[index] = newValue;
    setSchedules({
      ...schedules,
      [day]: times,
    });
  };

  const onRemoveSchedule = (day: string, index: number) => {
    let times: any[] = schedules[day] ?? [];
    if (times.length <= index) {
      return toast.error("invalid schedules. please restart!");
    }

    times.splice(index, 1);
    setSchedules({
      ...schedules,
      [day]: times,
    });
  };

  return (
    <ModalSimple
      isShown={props.isShow}
      title="Edit Specialist Availability"
      hide={props.toggle}
    >
      <>
        <DialogContent>
          {/* CONTENT */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Box sx={{ overFlowY: "initial !important" }}>
                <div className="modalBodyC">
                  <div
                    className="input-wrapper"
                    style={{ margin: "0 0 8px 0" }}
                  >
                    <div className="schedule-cus" style={{ width: "100%" }}>
                      <div
                        className="date-schedule-cus"
                        style={{ width: "100%" }}
                      >
                        <LabelModal
                          style={{
                            margin: "5px 0 10px 0",
                            display: "inline-block",
                          }}
                        >
                          Avatar Profile*
                        </LabelModal>
                        <div className="input-wrapper form-inline">
                          {
                            <aside
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              }}
                            >
                              <Thumbs imageUrl={profileUrl}></Thumbs>
                            </aside>
                          }
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="input-wrapper"
                    style={{ margin: "0 0 8px 0" }}
                  >
                    <div className="schedule-cus" style={{ width: "100%" }}>
                      <div style={{ width: "100%" }}>
                        <LabelModal
                          style={{
                            margin: "5px 0 10px 0",
                            display: "inline-block",
                          }}
                        >
                          Full Name*
                        </LabelModal>
                        <div className="input-wrapper form-inline">
                          <input type="text" disabled value={username} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="input-wrapper"
                    style={{ margin: "0 0 8px 0" }}
                  >
                    <div
                      className="schedule-cus"
                      style={{
                        width: "100%",
                        paddingTop: "0",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <LabelModal
                          style={{
                            margin: "0 0 10px 0",
                            display: "inline-block",
                          }}
                        >
                          Title*
                        </LabelModal>
                        <div className="input-wrapper form-inline">
                          <input type="text" disabled value={specialistTitle} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="input-wrapper"
                    style={{ margin: "0 0 8px 0" }}
                  >
                    <div
                      className="schedule-cus"
                      style={{
                        width: "100%",
                        paddingTop: "0",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <LabelModal
                          style={{
                            margin: "0 0 10px 0",
                            display: "inline-block",
                          }}
                        >
                          Google Integration*
                        </LabelModal>
                        <div className="input-wrapper form-inline">
                          <input
                            type="text"
                            disabled
                            value={
                              calendarSync === "not_linked"
                                ? "Not Integrated"
                                : "Linked"
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="input-wrapper"
                    style={{ margin: "0 0 12px 0" }}
                  >
                    <div style={{ width: "100%" }}>
                      <LabelModal>Time Zone*</LabelModal>
                      <Select
                        displayEmpty
                        value={timezone}
                        onChange={(val) => setTimezone(val.target.value)}
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{
                          marginTop: "8px",
                          width: "100%",
                          border: "solid 1px Colors.app.GhostGrey",
                          background: Colors.white.default,
                          borderRadius: "5px",
                          color: Colors.app.black,
                          fontWeight: Fonts.weights.w5,
                          fontFamily: "Fonts.interMedium",
                          fontSize: Fonts.sizes.xs,
                        }}
                      >
                        <MenuItem disabled value="">
                          <em className="text-slate-400">Time Zone*</em>
                        </MenuItem>
                        {timezones.map((val) => {
                          return (
                            <MenuItem value={val.title} key={val.title}>
                              <em>{val.title}</em>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </div>
                  </div>

                  <div
                    className="input-wrapper"
                    style={{ margin: "0 0 12px 0" }}
                  >
                    <div style={{ width: "100%" }}>
                      <LabelModal>Date Range*</LabelModal>
                      <Select
                        displayEmpty
                        value={dateRange}
                        onChange={(val) => setDateRange(val.target.value)}
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{
                          marginTop: "8px",
                          width: "100%",
                          border: "solid 1px Colors.app.GhostGrey",
                          background: Colors.white.default,
                          borderRadius: "5px",
                          color: Colors.app.black,
                          fontWeight: Fonts.weights.w5,
                          fontFamily: "Fonts.interMedium",
                          fontSize: Fonts.sizes.xs,
                        }}
                      >
                        <MenuItem disabled value={""}>
                          <em>Date Range*</em>
                        </MenuItem>
                        <MenuItem value={"30"}>
                          <em>30 Days</em>
                        </MenuItem>
                        <MenuItem value={"60"}>
                          <em>60 Days</em>
                        </MenuItem>
                      </Select>
                    </div>
                  </div>

                  <div
                    className="input-wrapper"
                    style={{ margin: "0 0 12px 0" }}
                  >
                    <div style={{ width: "100%" }}>
                      <LabelModal>Notice Period*</LabelModal>
                      <Select
                        displayEmpty
                        value={noticePeriod}
                        onChange={(val) => setNoticePeriod(val.target.value)}
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{
                          marginTop: "8px",
                          width: "100%",
                          border: "solid 1px Colors.app.GhostGrey",
                          background: Colors.white.default,
                          borderRadius: "5px",
                          color: Colors.app.black,
                          fontWeight: Fonts.weights.w5,
                          fontFamily: "Fonts.interMedium",
                          fontSize: Fonts.sizes.xs,
                        }}
                      >
                        <MenuItem disabled value={""}>
                          <em>Notice Period*</em>
                        </MenuItem>
                        <MenuItem value={"1"}>
                          <em>1 Hour</em>
                        </MenuItem>
                        <MenuItem value={"6"}>
                          <em>6 Hours</em>
                        </MenuItem>
                        <MenuItem value={"12"}>
                          <em>12 Hours</em>
                        </MenuItem>
                        <MenuItem value={"18"}>
                          <em>18 Hours</em>
                        </MenuItem>
                        <MenuItem value={"24"}>
                          <em>24 Hours</em>
                        </MenuItem>
                      </Select>
                    </div>
                  </div>

                  <div className="mt-6 mb-4">
                    <LabelModal>Schedule*</LabelModal>
                  </div>

                  {/* Sundays */}
                  <ScheduleItem
                    day="Sundays"
                    schedules={schedules["sunday"] ?? []}
                    onAddSchedule={() => onAddSchedule("sunday")}
                    onRemoveSchedule={(index) =>
                      onRemoveSchedule("sunday", index)
                    }
                    onChange={(index, newValue) =>
                      onChangeSchedule("sunday", index, newValue)
                    }
                  ></ScheduleItem>

                  {/* Mondays */}
                  <ScheduleItem
                    day="Mondays"
                    schedules={schedules["monday"] ?? []}
                    onAddSchedule={() => onAddSchedule("monday")}
                    onRemoveSchedule={(index) =>
                      onRemoveSchedule("monday", index)
                    }
                    onChange={(index, newValue) =>
                      onChangeSchedule("monday", index, newValue)
                    }
                  ></ScheduleItem>

                  {/* Tuesdays */}
                  <ScheduleItem
                    day="Tuesdays"
                    schedules={schedules["tuesday"] ?? []}
                    onAddSchedule={() => onAddSchedule("tuesday")}
                    onRemoveSchedule={(index) =>
                      onRemoveSchedule("tuesday", index)
                    }
                    onChange={(index, newValue) =>
                      onChangeSchedule("tuesday", index, newValue)
                    }
                  ></ScheduleItem>

                  {/* Wednesdays */}
                  <ScheduleItem
                    day="Wednesdays"
                    schedules={schedules["wednesday"] ?? []}
                    onAddSchedule={() => onAddSchedule("wednesday")}
                    onRemoveSchedule={(index) =>
                      onRemoveSchedule("wednesday", index)
                    }
                    onChange={(index, newValue) =>
                      onChangeSchedule("wednesday", index, newValue)
                    }
                  ></ScheduleItem>

                  {/* Thursdays */}
                  <ScheduleItem
                    day="Thursdays"
                    schedules={schedules["thursday"] ?? []}
                    onAddSchedule={() => onAddSchedule("thursday")}
                    onRemoveSchedule={(index) =>
                      onRemoveSchedule("thursday", index)
                    }
                    onChange={(index, newValue) =>
                      onChangeSchedule("thursday", index, newValue)
                    }
                  ></ScheduleItem>

                  {/* Fridays */}
                  <ScheduleItem
                    day="Fridays"
                    schedules={schedules["friday"] ?? []}
                    onAddSchedule={() => onAddSchedule("friday")}
                    onRemoveSchedule={(index) =>
                      onRemoveSchedule("friday", index)
                    }
                    onChange={(index, newValue) =>
                      onChangeSchedule("friday", index, newValue)
                    }
                  ></ScheduleItem>

                  {/* Saturdays */}
                  <ScheduleItem
                    day="Saturdays"
                    schedules={schedules["saturday"] ?? []}
                    onAddSchedule={() => onAddSchedule("saturday")}
                    onRemoveSchedule={(index) =>
                      onRemoveSchedule("saturday", index)
                    }
                    onChange={(index, newValue) =>
                      onChangeSchedule("saturday", index, newValue)
                    }
                  ></ScheduleItem>
                </div>
              </Box>
            </LocalizationProvider>
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: "20px 24px !important" }}>
          <ButtonStyle
            className="btn-primary"
            disabled={!isEnabled}
            title="Edit Schedule "
            onClick={onSubmit}
            style={{ marginTop: "30px" }}
          >
            Edit
          </ButtonStyle>
        </DialogActions>
      </>
    </ModalSimple>
  );
}

function ScheduleItem(props: {
  day: string;
  schedules: any[];
  onRemoveSchedule: (index: number) => void;
  onAddSchedule: () => void;
  onChange: (index: number, value: any) => void;
}) {
  return (
    <div className="w-full mb-4 my-2">
      <LabelModal
        style={{
          margin: "0 0 10px 0",
          display: "block",
        }}
      >
        {props.day}
      </LabelModal>
      {props.schedules.map((val, i) => {
        return (
          <div key={i}>
            <div className="mt-2 flex items-center">
              <MobileTimePicker
                value={val.time_from}
                onChange={(newTime) =>
                  props.onChange(i, {
                    time_from: newTime,
                    time_to: val.time_to,
                    format_calls: val.format_calls,
                  })
                }
              />
              <div className="mx-2">to</div>
              <MobileTimePicker
                value={val.time_to}
                onChange={(newTime) =>
                  props.onChange(i, {
                    time_from: val.time_from,
                    time_to: newTime,
                    format_calls: val.format_calls,
                  })
                }
              />
              <Select
                displayEmpty
                multiple
                value={val.format_calls ? val.format_calls.split(",") : []}
                onChange={(event) =>
                  props.onChange(i, {
                    time_from: val.time_from,
                    time_to: val.time_to,
                    format_calls:
                      typeof event.target.value === "string"
                        ? event.target.value
                        : event.target.value.join(","),
                  })
                }
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em className="text-slate-400">Format Call</em>;
                  }

                  const vals = formatCallItems
                    .filter((item) => selected.includes(item.key))
                    .map((val) => val.label);

                  return vals.join(", ");
                }}
                sx={{
                  border: "solid 1px Colors.app.GhostGrey",
                  background: Colors.white.default,
                  borderRadius: "5px",
                  color: Colors.app.black,
                  fontWeight: Fonts.weights.w5,
                  fontFamily: "Fonts.interMedium",
                  fontSize: Fonts.sizes.xs,
                  marginLeft: "16px",
                }}
                className="!w-36 shrink-0"
              >
                {formatCallItems.map((item) => {
                  return (
                    <MenuItem key={item.key} value={item.key}>
                      <Checkbox
                        color="default"
                        checked={(val.format_calls ?? "")
                          .split(",")
                          .includes(item.key)}
                      />
                      <ListItemText>{item.label}</ListItemText>
                    </MenuItem>
                  );
                })}
              </Select>
              <div
                className="px-2 ml-2 cursor-pointer"
                onClick={() => props.onRemoveSchedule(i)}
              >
                <Close></Close>
              </div>
            </div>
          </div>
        );
      })}
      <div
        className="bg-blue-background py-3 px-4 flex gap-2 items-center cursor-pointer mt-4"
        onClick={() => props.onAddSchedule()}
      >
        <Add className="text-brand-dark" size={18}></Add>
        <div className="text-sm capitalize text-brand-dark">Add Times</div>
      </div>
    </div>
  );
}

function Thumbs(props: { imageUrl: string }) {
  const names = props.imageUrl.split("/");
  const name = names[names.length - 1];

  return (
    <Thumb
      {...props}
      name={name}
      url={Endpoints.baseUrlAws + props.imageUrl}
    ></Thumb>
  );
}

function Thumb(props: { name: string; url: string }) {
  return (
    <div
      style={{
        marginBottom: 8,
        marginRight: 8,
      }}
      key={props.name}
    >
      <div
        style={{
          display: "flex",
          minWidth: 0,
          overflow: "hidden",
        }}
      >
        <div
          style={{
            background: "#ffffff",
            width: "55px !important",
            marginTop: "0 !important",
            borderRadius: "100px",
            height: "55px !important",
            padding: "0 !important",
            cursor: "pointer",
          }}
        >
          <div
            style={{
              background: `url(${props.url}) #D3D3D3`,
              backgroundSize: "100%",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              width: "55px",
              height: "55px",
              display: "flex",
              alignItems: "center",
              borderRadius: "100px",
              justifyContent: "center",
            }}
          />
        </div>
      </div>
    </div>
  );
}
