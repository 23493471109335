import { Box, Typography } from '@mui/material';
import { ModalDefault } from 'components/Modal';
import { Colors, Fonts } from 'constant';
import { Button as ButtonStyle } from 'screens/style';
import React from 'react';

export default function PreviewModal(props: { chat: string, onConfirm: () => void, onCancel: () => void, isShow: boolean, onHide: () => void; }) {
	const contentModal = (
		<Box>
			<Box>
				<Typography sx={ {
					marginTop: '10px',
					textAlign: 'left',
					fontSize: '16px',
					color: Colors.app.black,
					fontWeight: Fonts.weights.w5,
					fontFamily: 'Fonts.interMedium',
					marginBottom: '10px'
				} }>
					Flagged Chat:
				</Typography>

				<Typography sx={ {
					fontSize: '14px',
					color: '#FF579D',
					fontWeight: Fonts.weights.w5,
					fontFamily: 'Fonts.interMedium',
					lineHeight: '21px'
				} }>
					{ props.chat }
				</Typography>

				<Typography sx={ {
					color: '#767E8C',
					fontSize: '14px',
					lineHeight: '150%',
					fontWeight: Fonts.weights.w4,
					fontFamily: 'Fonts.interRegular',
					marginTop: '10px',
					paddingBottom: '20px',
					borderBottom: 'solid 1px #E0E5ED'
				} }>
					Yellow Cat Tail (May 1 2022, 08:15)
				</Typography>

				{/* Flagged Reasons */ }
				<Typography sx={ {
					marginTop: '20px',
					textAlign: 'left',
					fontSize: '16px',
					color: Colors.app.black,
					fontWeight: Fonts.weights.w5,
					fontFamily: 'Fonts.interMedium',
					marginBottom: '10px'
				} }>
					Flagged Reasons:
				</Typography>
				<Box>
					<Typography sx={ {
						fontSize: '14px',
						color: Colors.app.black,
						fontWeight: Fonts.weights.w5,
						fontFamily: 'Fonts.interMedium',
						lineHeight: '21px'
					} }>
						Spam
					</Typography>
					<Box sx={ { marginBottom: '20px' } }>
						<Typography sx={ {
							fontSize: '14px',
							color: '#767E8C',
							fontWeight: Fonts.weights.w5,
							fontFamily: 'Fonts.interMedium',
							lineHeight: '21px',
							marginTop: '10px'
						} }>
							Annisa Axelta (May 5 2022, 10.00 am)
						</Typography>
					</Box>
				</Box>

				<Box sx={ {
					borderBottom: 'solid 1px #E0E5ED'
				} }></Box>

				<Typography sx={ {
					marginTop: '20px',
					textAlign: 'left',
					fontSize: '16px',
					color: Colors.app.black,
					fontWeight: Fonts.weights.w5,
					fontFamily: 'Fonts.interMedium',
					marginBottom: '10px'
				} }>
					Thread (8):
				</Typography>
				<Box>
					<Typography sx={ {
						fontSize: '14px',
						color: '#FF579D',
						fontWeight: Fonts.weights.w5,
						fontFamily: 'Fonts.interMedium',
						lineHeight: '100%'
					} }>
						Lörem ipsum heterocentrism ososka lålig tesk derar. Dopäv kypp då pseudov de båmevis, oaktat ponyling. Valogi mir raskap vist. Gädat dode, ett osk derade. Bedivis soguning. Poktigt äre oment
					</Typography>
					<Typography sx={ {
						color: '#767E8C',
						fontSize: '14px',
						lineHeight: '150%',
						fontWeight: Fonts.weights.w4,
						fontFamily: 'Fonts.interRegular',
						marginTop: '10px',
						paddingBottom: '20px',
					} }>
						Yellow Cat Tail (May 1 2022, 08:15)
					</Typography>
				</Box>
			</Box>
		</Box>
	);

	const contentModalFooter = (
		<Box sx={ {
			display: 'flex',
			flexDirection: 'column',
			width: '100%'
		} }>
			<ButtonStyle className='btn-primary' title='Suggested' style={ {
				marginTop: '10px',
				marginBottom: '15px',
				width: '100%'
			} } onClick={ props.onConfirm } >
				Confirm
			</ButtonStyle>
			<ButtonStyle className='btn-default' onClick={ props.onCancel } style={ {
				background: `${Colors.app.white}`,
				color: `${Colors.app.black}`,
				border: `solid 1px ${Colors.app.black}`,
				width: '100%'
			} } title='Deny' >
				{/* Deny */ }
				Cancel
			</ButtonStyle>
		</Box>
	);

	return (
		<ModalDefault isShown={ props.isShow } hide={ props.onHide } modalContent={ contentModal } reset={ () => console.log('Clearout') } modalFooter={ contentModalFooter } title='Preview Context' />
	);
}