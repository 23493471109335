import React from 'react';
import ReactDOM from 'react-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
	props,
	ref,
) {
	return <MuiAlert elevation={ 6 } ref={ ref } variant='filled' { ...props } />;
});

export interface AlertCompProps {
    isShownAlert: boolean;
    hide: () => void;
	severity: any;
	content: string;
  }
  
export const AlertComponent: React.FunctionComponent<AlertCompProps> = ({
	isShownAlert,
	severity,
	hide,
	content
}) => {
	const vertical = 'top';
	const horizontal = 'right';
	
	const AlertComponent = (
		// eslint-disable-next-line object-property-newline
		<Snackbar open={ isShownAlert } onClose={ hide } autoHideDuration={ 6000 } anchorOrigin={ { vertical, horizontal } }>
			<Alert onClose={ hide } severity={ severity } sx={ { width: '100%' } }>
				 { severity === 'error' && ('Error: ') } { content }
			</Alert>
		</Snackbar>
	);

	return isShownAlert ? ReactDOM.createPortal(AlertComponent, document.body) : null;
};