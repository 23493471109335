import { Common, Members } from "interfaces";
import { apiService } from "helpers";
import { LocalStorage } from "helpers";
import { MembersActionTypes } from "./actionTypes";
import { Endpoints } from "constant";
import { dispatch } from "store";

export const membersProgress = (
	type: MembersActionTypes,
	payload?: Members.MembersPayload
) => {
	dispatch({
		type,
		payload,
	});
};

export const getMembers = (params?: Common.RequestData) => {
	return new Promise<any[]>(async (resolve, reject) => {
		membersProgress(MembersActionTypes.GET_MEMBERS_LIST_LOADING);

		const response: Common.ApiResponse<Members.Detail[]> = await apiService(
			`${Endpoints.members}?page=1&limit=100&sort=asc&type=merged`,
			{ method: "GET" },
			params?.query
		);

		const { object, data, error, pagination } = response;
		if (data) {
			const dd = object.data;
			const result: any[] = [];
			const pagiVal: any[] = [];

			pagiVal.push({
				count: pagination.count,
				page: pagination.page,
				limit: pagination.limit,
			});

			dd.map((item, i) => {
				item.username !== ""
					? result.push({
							member_code: item.member_code,
							username: item.username,
							organization: item.organization,
							department: item.department,
							industry_name: item.industry_name,
							temp_organization: item.temp_organization,
							temp_department: item.temp_department,
							temp_industry_name: item.temp_industry_name,
							birthdate: item.birthdate,
							profile_url: item.profile_url,
							gender: item.gender,
							email: item.email,
							phone: item.phone,
							status: item.status,
							role_name: item.role_name,
							is_taking_mood_test: item.is_taking_mood_test,
							is_verified: item.is_verified,
							invitation_email: item.invitation_email,
							invitation_code: item.invitation_code,
					  })
					: "";
			});
			const sorted = result.sort((a, b) =>
				a.username.localeCompare(b.username)
			);
			membersProgress(MembersActionTypes.GET_MEMBERS_LIST_SUCCESS, {
				listData: sorted,
				pagination: pagiVal,
			});
			resolve(sorted);
		} else {
			// you can put toast here
			reject(
				membersProgress(MembersActionTypes.GET_MEMBERS_LIST_ERROR, {
					error: error,
				})
			);
		}
	});
};

export const getMemberLists = (params?: Common.RequestData) => {
	return new Promise<any[]>(async (resolve, reject) => {
		membersProgress(MembersActionTypes.GET_MEMBERS_LIST_LOADING);

		const response: Common.ApiResponse<Members.Detail[]> = await apiService(
			`${Endpoints.members}?page=${params?.body.page}&limit=${
				params?.body.limit
			}&sort=&role_name=${params?.body.role_name}&organization_code=${
				params?.body.organization_code
			}&department_name=${params?.body.department_name}&membership_name=${
				params?.body.membership_name
			}&status=${params?.body.status}&type=${params?.body.type}&keyword=${
				params?.body.keyword ? params?.body.keyword : ""
			}`,
			{ method: "GET" },
			params?.query
		);

		const { object, data, error, pagination } = response;
		if (data) {
			const dd = object.data;
			const pagiVal: any[] = [];

			pagiVal.push({
				count: pagination.count,
				page: pagination.page,
				limit: pagination.limit,
			});

			// dd.map((item, i) => {
			// 	result.push({
			// 		member_code: item.member_code,
			// 		username: item.username,
			// 		organization: item.organization,
			// 		organization_code: item.organization_code,
			// 		department: item.department,
			// 		department_code: item.department_code,
			// 		industry_name: item.industry_name,
			// 		temp_organization: item.temp_organization,
			// 		temp_department: item.temp_department,
			// 		temp_industry_name: item.temp_industry_name,
			// 		birthdate: item.birthdate,
			// 		profile_url: item.profile_url,
			// 		gender: item.gender,
			// 		email: item.email,
			// 		invitation_email: item.invitation_email,
			// 		phone: item.phone,
			// 		status: item.status,
			// 		role_name: item.role_name,
			// 		is_taking_mood_test: item.is_taking_mood_test,
			// 		is_verified: item.is_verified,
			// 		invitation_code: item.invitation_code,
			// 	});
			// });
			const sorted = dd.sort((a, b) => a.username.localeCompare(b.username));
			membersProgress(MembersActionTypes.GET_MEMBERS_LIST_SUCCESS, {
				listData: sorted,
				pagination: pagiVal,
			});
			resolve(pagiVal);
		} else {
			// you can put toast here
			reject(
				membersProgress(MembersActionTypes.GET_MEMBERS_LIST_ERROR, {
					error: error,
				})
			);
		}
	});
};

export const getMemberListsCount = (params?: Common.RequestData) => {
	return new Promise<any[]>(async (resolve, reject) => {
		const response: Common.ApiResponse<Members.Detail[]> = await apiService(
			`${Endpoints.members}count`,
			{ method: "GET" },
			params?.query
		);

		const { object, data, error, pagination } = response;
		if (data) {
			const dd = object.data;
			const result: any[] = [];

			result.push({
				organization_count: `${dd.organization_count}`,
				individual_count: `${dd.individual_count}`,
				specialist_count: `${dd.specialist_count}`,
			});
			resolve(result);
		} else {
			// you can put toast here
			reject(error);
		}
	});
};

export const addMember = (params?: Common.RequestData) => {
	return new Promise<any[]>(async (resolve, reject) => {
		const response: Common.ApiResponse<Members.Detail> = await apiService(
			Endpoints.members,
			{
				method: "POST",
				body: params?.body,
			}
		);
		const { data, error, object } = response;

		if (response) {
			const result: any[] = [];

			result.push({
				data: object.data,
				stat_code: object.stat_code,
				stat_msg: object.stat_msg,
			});

			resolve(result);
		} else {
			// you can put toast here
			reject(object);
		}
	});
};

export const send = (params?: Common.RequestData) => {
	return new Promise<void>(async (resolve, reject) => {
		const response: Common.ApiResponse<Members.Detail> = await apiService(
			Endpoints.members + "send-invitations",
			{
				method: "POST",
				body: params?.body,
			}
		);
		const { data, error, object } = response;
		if (data) {
			const dd = object.data;
			resolve(dd);
		} else {
			// you can put toast here
			reject(object);
		}
	});
};

export const importMember = (params?: Common.RequestData) => {
	return new Promise<any[]>(async (resolve, reject) => {
		const formData = new FormData();
		formData.append("uploadfile", params?.body);

		await fetch(`${Endpoints.baseUrl}${Endpoints.members}import-user`, {
			// mode:'no-cors',
			headers: {
				Authorization: `${LocalStorage.getToken()}`,
			},
			method: "POST",
			body: formData,
		})
			.then((response) => response.json())
			.then((response) => {
				const result: any[] = [];
				[response].map((item, i) => {
					result.push({
						data: item.data,
						stat_code: item.stat_code,
						stat_msg: item.stat_msg,
					});
				});
				resolve(result);
			})
			.catch(function (e) {
				const result: any[] = [];
				[e].map((item, i) => {
					result.push({
						stat_code: item.stat_code,
						stat_msg: item.stat_msg,
					});
				});
				reject(e);
			});
	});
};

export const addMembers = (params?: Common.RequestData) => {
	return new Promise<void>(async (resolve, reject) => {
		membersProgress(MembersActionTypes.ADD_MEMBERS_LOADING);

		const response: Common.ApiResponse<Members.Detail> = await apiService(
			Endpoints.members,
			{
				method: "POST",
				body: params?.body,
			}
		);
		const { data, error, object } = response;
		if (data) {
			const dd = object.data;

			resolve(
				membersProgress(MembersActionTypes.ADD_MEMBERS_SUCCESS, { data: dd })
			);
		} else {
			// you can put toast here
			membersProgress(MembersActionTypes.ADD_MEMBERS_ERROR);
			reject(object);
		}
	});
};

export const editMembers = (params?: Common.RequestData) => {
	return new Promise<void>(async (resolve, reject) => {
		const response: Common.ApiResponse<Members.Detail> = await apiService(
			Endpoints.members + params?.body.member_code + "/role",
			{
				method: "PUT",
				body: params?.body,
			}
		);
		const { data, error, object } = response;
		if (data) {
			resolve(object.data);
		} else {
			// you can put toast here
			reject(object);
		}
	});
};
