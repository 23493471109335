import React from 'react';
import { Stack, TableCell, TableRow, Typography } from '@mui/material';
import { Endpoints, Images } from 'constant';
import Button from '@mui/material/Button';
import { IVoidCallback } from 'interfaces/callback';
import Iconify from 'screens/Layout/components/Iconify';
import { ReactSVG } from 'react-svg';

export default function ExerciseRow(props: {
    row: any,
    withoutAction?: boolean,
    onEdit?: IVoidCallback,
    onDelete?: IVoidCallback,
    onRepost?: IVoidCallback,
    onPreview?: IVoidCallback,
    onTranslation?: (val: string) => void;
    onAddTranslation?: (val: string) => void;
    onNarration?: (val: string) => void;
    onAddNarration?: (val: string) => void;
}) {
    const { id, description, subtitle, image_url, title, translations, narrations, reviewer_name } = props.row;
    const bgImgUrl = Endpoints.baseUrlAws + image_url;
    const withoutAction = props.withoutAction ?? false;
    const titleStr = title.length > 40 ? title.substring(0, 40) + ' ...' : title;

    const onTranslation = (val: string) => {
        if (props.onTranslation) {
            props.onTranslation(val);
        }
    };

    const onAddTranslation = (val: string) => {
        if (props.onAddTranslation) {
            props.onAddTranslation(val);
        }
    };

    const onNarration = (val: any) => {
        if (props.onNarration) {
            props.onNarration(val);
        }
    };

    const onAddNarration = (val: any) => {
        if (props.onAddNarration) {
            props.onAddNarration(val);
        }
    };

    return (
        <TableRow key={ id } hover>

            <TableCell component='th' sx={ {
                textTransform: 'capitalize',
                fontFamily: 'Fonts.interRegular'
            } }>
                <Typography variant='subtitle2' noWrap sx={ {
                    minWidth: '120px',
                } }>
                    { titleStr }
                </Typography>
            </TableCell>

            <TableCell component='th' sx={ {
                textTransform: 'capitalize',
                fontFamily: 'Fonts.interRegular'
            } }>
                <Typography variant='subtitle2' noWrap sx={ {
                    minWidth: '120px',
                } }>
                    { subtitle }
                </Typography>
            </TableCell>

            <TableCell component='th' scope='row' padding='normal'>
                <Stack direction='row' alignItems='center' spacing={ 2 }>
                    <Typography variant='subtitle2' sx={ {
                        flexWrap: 'wrap',
                        width: '250px',
                        fontFamily: 'Fonts.interMedium'
                    } }>
                        { description }
                    </Typography>
                </Stack>
            </TableCell>

            <TableCell align='left'>
                <div style={ { width: '150px' } }>
                    <ReactSVG height={ '100px' }
                        afterInjection={ svg => {
                            svg.style.height = '100px';
                            svg.style.width = '100px';
                        } }
                        src={ bgImgUrl }
                    />
                </div>
            </TableCell>

            <TableCell align='left' sx={ {
                fontFamily: 'Fonts.interRegular'
            } }>
                <div className='flex gap-2'>
                    {
                        translations ? (
                            translations.map((val: string, i: number) => {
                                return (
                                    <div style={ { paddingTop: '7px' } } key={ i }>
                                        <span className='bg-neutral-500 text-white px-2 py-1 rounded-md cursor-pointer' onClick={ () => onTranslation(val) }>{ val }</span>
                                    </div>
                                );
                            })
                        ) : null
                    }

                    {
                        !props.withoutAction ? (
                            <div className='ml-2'>
                                <Button sx={ { minWidth: 'auto !important' } } onClick={ () => onAddTranslation('id') } >
                                    <Iconify icon='carbon:add-alt' sx={ { fontSize: '24px' } }></Iconify>
                                </Button>
                            </div>
                        ) : null
                    }
                </div>
            </TableCell>

            <TableCell align='left' sx={ {
                fontFamily: 'Fonts.interRegular',
            } }>
                <Typography variant='subtitle2' noWrap sx={ {
                    width: '200px',
                    fontFamily: 'Fonts.interMedium'
                } }>
                    {
                        narrations ? (
                            narrations.map((val: any, i: number) => {
                                return (
                                    <div className='flex bg-neutral-500 text-white px-2 py-1 rounded-md cursor-pointer mb-1'
                                        onClick={ () => onNarration(val) }
                                        key={ i }
                                    >
                                        <div className='w-28 overflow-hidden'>{ val.audio_title }</div>
                                        <div> ({ val.language === 'id' ? 'indonesia' : 'english' })</div>
                                    </div>
                                );
                            })
                        ) : null
                    }

                    {
                        !props.withoutAction ? (
                            <div className='ml-2'>
                                <Button sx={ { minWidth: 'auto !important' } } onClick={ () => onAddNarration('id') } >
                                    <Iconify icon='carbon:add-alt' sx={ { fontSize: '24px' } }></Iconify>
                                </Button>
                            </div>
                        ) : null
                    }
                </Typography>
            </TableCell>

            <TableCell align='left' sx={ {
                textTransform: 'capitalize',
                fontFamily: 'Fonts.interRegular'
            } }>
                <Typography variant='subtitle2' noWrap sx={ {
                    width: '200px',
                    fontFamily: 'Fonts.interMedium'
                } }>
                    { reviewer_name }
                </Typography>
            </TableCell>

            <TableCell align='left' sx={ {
                textTransform: 'capitalize',
                fontFamily: 'Fonts.interRegular',
            } }>
                <Typography variant='subtitle2' noWrap sx={ {
                    width: '200px',
                    fontFamily: 'Fonts.interMedium',
                    cursor: 'pointer'
                } }>
                    <div onClick={ props.onPreview }>Show Preview</div>
                </Typography>
            </TableCell>

            {
                !withoutAction ?
                    <TableCell align='left' sx={ { minWidth: '100px' } }>
                        <Button onClick={ props.onEdit } sx={ { minWidth: 'auto !important' } }>
                            <img src={ Images.btn_edit } style={ { width: '30px' } } />
                        </Button>

                        <Button onClick={ props.onDelete } sx={ { minWidth: 'auto !important' } }>
                            <img src={ Images.btn_delete } style={ { width: '30px' } } />
                        </Button>
                    </TableCell>
                    :
                    <>
                        <TableCell align='left' sx={ { minWidth: '60px' } }>
                            <Button onClick={ props.onRepost } sx={ { minWidth: 'auto !important' } }>
                                <img src={ Images.btn_check } style={ { width: '30px' } } />
                            </Button>
                        </TableCell>
                    </>
            }
        </TableRow>
    );
}
