import React from "react";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { Endpoints, Images } from "constant";
import numeral from "numeral";

export default function UserTableRows(props: {
  row: any;
  isSelectedRow: boolean;
  labelId: any;
  onUnbanned: () => void;
}) {
  const {
    country,
    expertise,
    approaches,
    universities,
    licenses,
    introduction_video_url,
    bank_name,
    account_holder,
    account_number,
    specialist_title,
    target_users,
    specialist_rate_hourly_fee,
    specialist_rate_hourly_price,
    credentials,
    language,
    year_of_experience,
    bio,
    username,
    email,
    phone,
    status,
    gender,
    membership_name,
  } = props.row;

  let hourlyPrice = "Not Applicable";
  if (specialist_rate_hourly_price && specialist_rate_hourly_price !== "") {
    hourlyPrice = `Rp ${numeral(specialist_rate_hourly_price).format("0,0")}`;
  }

  let hourlyFee = "Not Applicable";
  if (specialist_rate_hourly_fee && specialist_rate_hourly_fee !== "") {
    hourlyFee = `Rp ${numeral(specialist_rate_hourly_fee).format("0,0")}`;
  }

  return (
    <TableRow
      hover
      key={props.row.invitation_email}
      aria-checked={props.isSelectedRow}
      role="checkbox"
      tabIndex={-1}
      selected={props.isSelectedRow}
    >
      <TableCell align="left">
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "200px !important",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ marginRight: "20px" }}>
            {props.row.profile_url === "" ? (
              <img
                alt="maxi-cms"
                src={String(Images.avatar)}
                style={{ width: " 36px ", height: "36px" }}
              />
            ) : (
              <div
                style={{
                  width: "36px",
                  height: "36px",
                  borderRadius: "100%",
                  backgroundSize: "cover",
                  backgroundImage: `url(${String(
                    Endpoints.baseUrlAws + props.row.profile_url
                  )})`,
                }}
              />
            )}
          </div>
          <div className="truncate">{username}</div>
        </Typography>
      </TableCell>

      <TableCell align="left">
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "150px !important",
          }}
        >
          {email}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "160px !important",
          }}
        >
          <span
            style={{
              textTransform: "uppercase",
              background: "#F3F4F6",
              padding: "4px 12px",
              borderRadius: "10px",
              fontSize: "12px",
              fontWeight: "600",
            }}
          >
            {membership_name}
          </span>
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "150px !important",
          }}
        >
          {specialist_title}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "150px !important",
          }}
        >
          {phone}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "150px !important",
          }}
        >
          {hourlyPrice}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "100px !important",
          }}
        >
          {hourlyFee}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "100px !important",
          }}
        >
          {bank_name}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "100px !important",
          }}
        >
          {account_number}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "100px !important",
          }}
        >
          {account_holder}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "150px !important",
          }}
        >
          {bio ?? "-"}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "150px !important",
          }}
        >
          {(universities ?? []).map((val) => val.university).join(", ")}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "150px !important",
          }}
        >
          {(licenses ?? []).map((val) => val.license_name).join(", ")}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "150px !important",
          }}
        >
          {(credentials ?? ([] as any[])).map((val) => val.name).join(", ")}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "150px !important",
          }}
        >
          {year_of_experience}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "150px !important",
          }}
        >
          {(expertise ?? ([] as any[]))
            .map((val) => val.category_name)
            .join(", ")}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "150px !important",
          }}
        >
          {(approaches ?? ([] as any[])).map((val) => val.name).join(", ")}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "150px !important",
          }}
        >
          {(target_users ?? ([] as any[])).map((val) => val.name).join(",")}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "150px !important",
          }}
        >
          {introduction_video_url.length === 0 ? "-" : introduction_video_url}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "150px !important",
          }}
        >
          {language ? language.join(", ") : "-"}
        </Typography>
      </TableCell>

      <TableCell align="left">
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "150px !important",
          }}
        >
          {country}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "100px !important",
          }}
        >
          {gender === "rather_not_saying" ? "Prefer Not to Say" : gender}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            fontFamily: "Fonts.interRegular",
            width: "100px !important",
          }}
        >
          {status === "active" ? (
            <span
              style={{
                textTransform: "uppercase",
                background: "#E8F4FB",
                padding: "4px 12px",
                borderRadius: "10px",
                fontSize: "12px",
                color: "#2D9CDB",
                fontWeight: "600",
              }}
            >
              Active
            </span>
          ) : (
            <span
              style={{
                fontSize: "12px",
                fontWeight: "600",
                color: "#FF1F7D",
                textTransform: "uppercase",
                background: "#FFE9F2",
                padding: "4px 12px",
                borderRadius: "10px",
              }}
            >
              {" "}
              {status === "pending_verification"
                ? "EMAIL SENT"
                : status === "pending_invitation"
                ? "EMAIL UNSENT"
                : status}{" "}
            </span>
          )}
        </Typography>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: "capitalize" }}>
        <Box sx={{ width: "150px", display: "flex" }}>
          <IconButton className="bg-neutral-200" onClick={props.onUnbanned}>
            <img className="w-8" src={Images.btn_check} alt="" />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  );
}
