import AccessTypes from "./actionTypes";

interface IAccess {
	access: any[];
}

const accessReducers = (
	state: IAccess = { access: [] },
	action: { type: AccessTypes; payload: any }
): IAccess => {
	if (action.type === AccessTypes.StoreAccess) {
		return {
			...action.payload,
		};
	}

	return state;
};

export default accessReducers;
