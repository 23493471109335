import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import { Link } from 'react-router-dom';

export default function UserRow(props: { row: any, onPreview: () => void; }) {
    const { id, chat, comment_by, flagged_by, context, reason } = props.row;

    return (
        <TableRow key={ id } hover >

            <TableCell align='left' sx={ {
                textTransform: 'capitalize',
                fontFamily: 'Fonts.interRegular'
            } }>
                { chat }
            </TableCell>

            <TableCell align='left' sx={ { textTransform: 'capitalize' } }>
                { reason }
            </TableCell>

            <TableCell align='left' sx={ {
                textTransform: 'capitalize',
                fontFamily: 'Fonts.interRegular'
            } }>
                { context === 'regular' ? 'Post' : context }
            </TableCell>

            <TableCell align='left' sx={ {
                textTransform: 'capitalize',
                fontFamily: 'Fonts.interRegular'
            } }>
                { comment_by }
            </TableCell>

            <TableCell align='left' sx={ {
                textTransform: 'capitalize',
                fontFamily: 'Fonts.interRegular'
            } }>
                { flagged_by }
            </TableCell>

            <TableCell align='left' sx={ {
                textTransform: 'capitalize',
                fontFamily: 'Fonts.interRegular'
            } }>
                <Link to='#' onClick={ () => props.onPreview() }>Preview Context</Link>
            </TableCell>
        </TableRow>
    );
}
