import Config from "./Config";

export default {
	baseUrl: Config.url,
	baseUrlAws: Config.urlaws,
	baseUrlAwsSecretMyBucket: Config.url_aws_secret_my_bucket,
	baseUrlAwsAccessKeyId: Config.url_aws_access_key_id,
	baseUrlAwsSignatureVersion: Config.url_aws_signature_version,
	baseUrlAwsRegion: Config.url_aws_region,
	baseUrlAwsSecretAccessKey: Config.url_aws_secret_access_key,
	baseUrlLandingUrl: Config.url_landing,
	settings: "/v1/settings/",
	org: "/v1/organizations/",
	manageflag: "/v1/manage-flags/",
	posts_segment: "/v1/posts/segment/",
	postByCode: "/v1/posts/",
	posts: "/v1/posts/",
	members_posts: "/v1/posts/",
	posts_polls: "/v1/posts/",
	uploadimage: "/v1/upload-image",
	topics: "/v1/topics/",
	members: "/v1/manage-members/",
	voe: "/v1/voice-of-employees",
	getadmin: "/v1/admins",
	admin: "/v1/admins/",
	adminLogin: "/v1/auths/admin-login",
	param: {
		product: "/people/1/",
	},
};
