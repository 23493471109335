import Styled from 'styled-components';

import { Colors } from 'constant';

const { black } = Colors;

interface Props {
  color?: string;
}

const StyledText = Styled.p`
  color: ${(props: Props) => (props.color ? props.color : black.default)};
`;

export { StyledText };
