import { Card } from '@mui/material';
import NoDataIcon from "assets/svg/analytic_nodata.svg";
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { DoughnutChart, LineChart } from 'components';
import { Endpoints, Mood } from 'constant';
import { ILineValues } from 'interfaces/chart';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import api from 'service/api';
import { utils } from 'utils';

const MAX_LABEL = 10;

export default function UsageAnalytic(props: {
	orgCode: string,
	departCode: string,
	industryName: string,
	monthValue: number,
	yearValue: number,
}) {
	useLayoutEffect(() => {
		Chart.register(ChartDataLabels);
	});

	return (
		<>
			<MoodAnalytic { ...props }></MoodAnalytic>
			<HappinessAnalytic { ...props }></HappinessAnalytic>
			<DiscomfortAnalytic { ...props }></DiscomfortAnalytic>
			<ForumAnalytic { ...props }></ForumAnalytic>
		</>
	);
}

export function MoodAnalytic(props: {
	orgCode: string, departCode: string, industryName: string,
	monthValue: number, yearValue: number, isMinimal?: boolean;
}) {
	// Local State
	const [moodValues, setMoodValue] = useState<number[]>([]);
	const [moodLabels, setMoodLabel] = useState<string[]>([]);
	const [lineValues, setLineValues] = useState<ILineValues>({});
	const [lineValuesRaw, setLineValuesRaw] = useState<ILineValues>({});

	// Title
	const [userCount, setUserCount] = useState(0);
	const [labelValue, setLabelValue] = useState('');

	const isMinimal = props.isMinimal ?? false;

	const onMood = async () => {
		const resp = await api.get(`${Endpoints.voe}/moods`, {
			params: {
				"organization_code": props.orgCode,
				"department_code": props.departCode,
				"industry_name": props.industryName,
				"month": props.monthValue,
				"year": props.yearValue
			}
		});
		const respData = resp.data.data as any[];
		if (respData == null || respData.length == 0) {
			setLineValues({});
			setMoodLabel([]);
			setMoodValue([]);
		} else {
			const { labels, values } = splitChartDoughnut(respData, "mood_value", Mood.listMood);
			const { percent, raw } = splitChartLine(respData, labels, "mood_value");

			setLabelValue(labels[utils.maxIndex(values)]);
			setUserCount(respData.length);

			setMoodLabel(labels);
			setMoodValue(values);
			setLineValues(percent);
			setLineValuesRaw(raw);
		}
	};

	useEffect(() => {
		onMood();
	}, [props.departCode, props.industryName, props.monthValue, props.orgCode, props.yearValue]);

	return <Content
		userCount={ userCount }
		labelTitle="Dominant Mood"
		labelValue={ labelValue }
		title='Mood Tracker'
		labels={ moodLabels }
		lineValuesRaw={ lineValuesRaw }
		lineValues={ lineValues }
		values={ moodValues }
		isMinimal={ isMinimal }
	></Content>;
}

export function HappinessAnalytic(props: {
	orgCode: string, departCode: string, industryName: string,
	monthValue: number, yearValue: number, isMinimal?: boolean;
}) {
	// Local State
	const [moodValues, setMoodValue] = useState<number[]>([]);
	const [moodLabels, setMoodLabel] = useState<string[]>([]);
	const [lineValues, setLineValues] = useState<ILineValues>({});
	const [lineValuesRaw, setLineValuesRaw] = useState<ILineValues>({});

	// Title
	const [userCount, setUserCount] = useState(0);
	const [labelValue, setLabelValue] = useState('');

	const isMinimal = props.isMinimal ?? false;

	const onInit = async () => {
		const resp = await api.get(`${Endpoints.voe}/source-of-happiness`, {
			params: {
				"organization_code": props.orgCode,
				"department_code": props.departCode,
				"industry_name": props.industryName,
				"month": props.monthValue,
				"year": props.yearValue
			}
		});
		const respData = resp.data.data as any[];
		if (respData.length == 0) {
			setLineValues({});
			setMoodLabel([]);
			setMoodValue([]);
		} else {
			const { labels, values } = splitChartDoughnut(respData, "category_name");
			const { percent, raw } = splitChartLine(respData, labels, "category_name");

			setLabelValue(labels[utils.maxIndex(values)]);
			setUserCount(respData.length);

			setMoodLabel(labels);
			setMoodValue(values);
			setLineValues(percent);
			setLineValuesRaw(raw);
		}
	};

	useEffect(() => {
		onInit();
	}, [props.departCode, props.industryName, props.monthValue, props.orgCode, props.yearValue]);

	return <Content
		labelTitle='Top Reason'
		labelValue={ labelValue }
		userCount={ userCount }
		title='Source of Happiness'
		labels={ moodLabels }
		lineValuesRaw={ lineValuesRaw }
		lineValues={ lineValues }
		values={ moodValues }
		isMinimal={ isMinimal }
	></Content>;
}

export function DiscomfortAnalytic(props: {
	orgCode: string, departCode: string, industryName: string;
	monthValue: number, yearValue: number, isMinimal?: boolean;
}) {
	// Local State
	const [moodValues, setMoodValue] = useState<number[]>([]);
	const [moodLabels, setMoodLabel] = useState<string[]>([]);
	const [lineValues, setLineValues] = useState<ILineValues>({});
	const [lineValuesRaw, setLineValuesRaw] = useState<ILineValues>({});

	// Title
	const [userCount, setUserCount] = useState(0);
	const [labelValue, setLabelValue] = useState('');

	const isMinimal = props.isMinimal ?? false;

	const onInit = async () => {
		const resp = await api.get(`${Endpoints.voe}/cause-of-discomforts`, {
			params: {
				"organization_code": props.orgCode,
				"department_code": props.departCode,
				"industry_name": props.industryName,
				"month": props.monthValue,
				year: props.yearValue
			}
		});
		const respData = resp.data.data as any[];
		if (respData.length == 0) {
			setLineValues({});
			setMoodLabel([]);
			setMoodValue([]);
		} else {
			const { labels, values } = splitChartDoughnut(respData, "category_name");
			const { percent, raw } = splitChartLine(respData, labels, "category_name");

			setLabelValue(labels[utils.maxIndex(values)]);
			setUserCount(respData.length);

			setMoodLabel(labels);
			setMoodValue(values);
			setLineValues(percent);
			setLineValuesRaw(raw);
		}
	};

	useEffect(() => {
		onInit();
	}, [props.departCode, props.industryName, props.monthValue, props.orgCode, props.yearValue]);

	return <Content
		labelTitle='Top Reason'
		labelValue={ labelValue }
		userCount={ userCount }
		title='Cause of Discomfort'
		labels={ moodLabels }
		lineValuesRaw={ lineValuesRaw }
		lineValues={ lineValues }
		values={ moodValues }
		isMinimal={ isMinimal }
	></Content>;
}

export function ForumAnalytic(props: {
	orgCode: string, departCode: string, industryName: string,
	monthValue: number, yearValue: number, isMinimal?: boolean;
}) {
	// Local State
	const [moodValues, setMoodValue] = useState<number[]>([]);
	const [moodLabels, setMoodLabel] = useState<string[]>([]);
	const [lineValues, setLineValues] = useState<ILineValues>({});
	const [lineValuesRaw, setLineValuesRaw] = useState<ILineValues>({});

	// Title
	const [userCount, setUserCount] = useState(0);
	const [labelValue, setLabelValue] = useState('');

	const isMinimal = props.isMinimal ?? false;

	const onMood = async () => {
		const resp = await api.get(`${Endpoints.voe}/forums`, {
			params: {
				"organization_code": props.orgCode,
				"department_code": props.departCode,
				"industry_name": props.industryName,
				"month": props.monthValue,
				year: props.yearValue,
			}
		});
		const respData = resp.data.data as any[];
		if (respData == null || respData.length == 0) {
			setLineValues({});
			setMoodLabel([]);
			setMoodValue([]);
		} else {
			const { labels, values } = splitChartDoughnut(respData, "topic_name");
			const { percent, raw } = splitChartLine(respData, labels, "topic_name");

			setLabelValue(labels[utils.maxIndex(values)]);
			setUserCount(respData.length);

			setMoodLabel(labels);
			setMoodValue(values);
			setLineValues(percent);
			setLineValuesRaw(raw);
		}
	};

	useEffect(() => {
		onMood();
	}, [props.departCode, props.industryName, props.monthValue, props.orgCode, props.yearValue]);

	return <Content
		labelTitle='Top Topic'
		labelValue={ labelValue }
		userCount={ userCount }
		title='Forum'
		labels={ moodLabels }
		lineValuesRaw={ lineValuesRaw }
		lineValues={ lineValues }
		values={ moodValues }
		isMinimal={ isMinimal }
	></Content>;
}

function Content(props: { isMinimal: boolean, userCount: number, labelTitle: string, labelValue: string, title: string, labels: string[], lineValuesRaw: ILineValues, lineValues: ILineValues, values: number[]; }) {
	const maxVal = 100;

	return (
		<Card className='mb-8'>
			<div className='bg-slate-100 py-4 pl-4 flex justify-between'>
				<div className='font-bold text-xl pt-2'>{ props.title }</div>
			</div>
			{
				props.labels.length > 0 ?
					<div className='px-16 pb-12 pt-8'>
						<div className='flex justify-between'>
							<div>
								<div className='flex'>
									<div className='basis-1/2'>
										<div>Total Filled Users</div>
										<div className='text-brand-dark font-semibold mt-4'>{ props.userCount } Users</div>
									</div>
									<div>
										<div>{ props.labelTitle }</div>
										<div className='text-brand-dark font-semibold mt-4'>{ props.labelValue }</div>
									</div>
								</div>
								<div className='mt-8'>Summary Breakdown</div>
								<DoughnutChart showPercentage labels={ props.labels } values={ props.values }></DoughnutChart>
							</div>
							{
								!props.isMinimal ? (
									<>
										<div className='mx-8 bg-slate-300'>
											<div style={ { width: "1px" } }>
											</div>
										</div>
										<div className='relative shrink-0 w-12'>
											<div
												className='absolute left-0 top-1/2 w-full whitespace-nowrap'
												style={ { transform: 'rotate(-90deg) translateY(-10px) translateX(-10px)' } }
											>Percentage of Users</div>
										</div>
										<div className='flex-grow self-center'>
											<LineChart
												labelFormatter={ (val, context) => {
													const label = context.dataset.label as string;
													const index = context.dataIndex;
													const vals = props.lineValuesRaw[label];

													return vals[index];
												} }
												tooltipFormatter={ (tooltip) => {
													return `${tooltip.label}: ${props.lineValuesRaw[tooltip.dataset.label!][tooltip.parsed.x]}`;
												} }
												values={ props.lineValues } maxY={ maxVal }
												ticksStep={ Math.floor(maxVal / 4) } ticksCallback={ (val) => `${Number(val).toFixed(0)}%` }
											></LineChart>
											<div className='mt-6 text-center'>Weeks of the Month</div>
										</div>
									</>
								) : null
							}
						</div>
					</div>
					:
					<div className='py-28 text-center'>
						<img className='mx-auto' src={ NoDataIcon } alt="" />
						<div className='text-center text-lg mt-10'>Sorry, no data is available</div>
					</div>
			}
		</Card>
	);
}

const splitChartDoughnut = (data: any[], keyLabel: string, preLabels?: string[]) => {
	const mood = new Map<string, number>();
	const labels: string[] = [];
	const values: number[] = [];

	for (const item of data) {
		const moodLabel = (item[keyLabel] as string).trim();
		if (!mood[moodLabel]) {
			mood[moodLabel] = 0;
		}

		mood[item[keyLabel]]++;
	}

	if (preLabels != null) {
		for (const label of preLabels) {
			if (mood[label] == null) {
				values.push(0);
			} else {
				values.push(mood[label]);
			}

			labels.push(label);
		}
	} else {
		let temp: { val: number, key: string; }[] = [];
		for (const key in mood) {
			if (Object.prototype.hasOwnProperty.call(mood, key)) {
				const val = mood[key];

				temp.push({ key, val });
			}
		}

		temp = temp.sort((a, b) => b.val - a.val);

		const length = temp.length > MAX_LABEL ? MAX_LABEL : temp.length;
		for (let i = 0; i < length; i++) {
			const item = temp[i];

			labels.push(item.key);
			values.push(item.val);
		}
	}

	return { labels, values };
};

const splitChartLine = (
	data: any[],
	labels: string[],
	keyLabel: string
) => {
	const temp: ILineValues = {};
	const res: ILineValues = {};
	const biggestWeek = Math.ceil(utils.lastDate() / 7);
	const divisor = Array(biggestWeek).fill(0);

	for (const label of labels) {
		temp[label] = Array(biggestWeek).fill(0);
		res[label] = Array(biggestWeek).fill(0);
	}

	for (const item of data) {
		const label = item[keyLabel];
		const week = Math.floor(new Date(item.created_date).getDate() / 7);

		if (temp[label]) {
			temp[label][week]++;

			divisor[week]++;
		}
	}

	for (const key in temp) {
		if (Object.prototype.hasOwnProperty.call(temp, key)) {
			const item = temp[key];

			for (let week = 0; week < biggestWeek; week++) {
				if (item[week] != 0) {
					res[key][week] = Math.round(item[week] / divisor[week] * 100);
				}
			}
		}
	}

	return { percent: res, raw: temp };
};
