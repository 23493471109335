import { Endpoints } from "constant";
import api from "./api";

export const getOrganizationList = async (params: {
	limit?: number;
	page?: number;
	sales?: string;
	search?: string;
}) => {
	const resp = await api.get(`${Endpoints.org}`, {
		params: {
			page: params.page ?? 1,
			limit: params.limit ?? 10,
			keyword: params.search,
			sales_rep: params.sales,
		},
	});

	return resp.data;
};

export const addOrganization = async (data: {
	name: string;
	email: string;
	phone: string;
	industry_name?: string;
	office_address: string;
	pic?: string;
	is_active: boolean;
	sales_code: string;
}) => {
	const resp = await api.post("/v1/organizations/", data);
	return resp.data;
};

export const updateOrganization = async (data: {
	code: string;
	name: string;
	email: string;
	phone: string;
	industry_name?: string;
	office_address: string;
	pic?: string;
	is_active: boolean;
	sales_code: string;
	subscription_code: string;
}) => {
	const resp = await api.put(`/v1/organizations/${data.code}`, data);
	return resp.data;
};

export const deleteOrganization = async (organizationId: string) => {
	const resp = await api.delete(`/v1/organizations/${organizationId}`);
	return resp.data;
};
