import React, { useState } from 'react';
import { Images } from 'constant';
import { useNavigate } from 'react-router-dom';
import { Paths } from 'constant';
import {
	Container, SingleColumn, Button
} from '../style';
import {
	LogoMaxiSmall,
	SubTitle,
	Title,
	BoxWrap,
	Label,
	Input
} from './style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeLowVision } from '@fortawesome/free-solid-svg-icons';

const eye = <FontAwesomeIcon icon={ faEye } />;
const eyeLowVision = <FontAwesomeIcon icon={ faEyeLowVision } />;

const ForgotPassword = () => {
	const [passwordShown, setPasswordShown] = useState(false);
	const [confirmShown, setConfirmShown] = useState(false);
	const navigate = useNavigate();
	
	const routeChange = () => {
		const path = Paths.forgotPasswordSuccess;
		navigate(path);
	};
	
	const togglePassword = () => {
		setPasswordShown(!passwordShown);
	};
	
	const toggleConfirm = () => {
		setConfirmShown(!confirmShown);
	};
	
	return (
		<Container bg={ Images.bgContainer } >
			<SingleColumn>
				<LogoMaxiSmall alt='maxi-cms' src={ String(Images.logoMaxiRoundSmall) } />
				
				<Title margin='70px 0 15px 0'>Change Password</Title>
				<SubTitle textAlign='left'>Lörem ipsum ner råböktiga pett resam med kontrade. </SubTitle>
				
				<BoxWrap>
					<Label> New Password</Label>
					<div className='pass-wrapper'>
						<Input type={ passwordShown ? 'text' : 'password' } name='password' placeholder='Password' />
						<i onClick={ togglePassword } title='Show or Hidden Your Password'> { passwordShown ? eyeLowVision : eye } </i>
					</div>
					{/* <label className='label-error'></label> */}
				</BoxWrap>
				<BoxWrap>
					<Label>Confirm New Password</Label>
					<div className='pass-wrapper' style={ { marginBottom: '70px' } }>
						<Input type={ confirmShown ? 'text' : 'password' } name='confirm' placeholder='Password' />
						<i onClick={ toggleConfirm } title='Show or Hidden Your Confirm Password'> { confirmShown ? eyeLowVision : eye } </i>
					</div>
					<Button title='Click for Change Your Password' onClick={ routeChange }>Change Password</Button>
				</BoxWrap>
				
			</SingleColumn>
		</Container>
	);
};
export default ForgotPassword;