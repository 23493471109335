import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React, { useLayoutEffect } from 'react';
import { MonthlyScreenAnalytic, DailyScreenAnalytic } from 'screens/VOE/Application/components/ApplicationAnalytic';
import { AnxietyAnalytic, DepressionAnalytic, StressAnalytic } from 'screens/VOE/Assessment/components/AssessmentDistress';
import { EngageAnalytic } from 'screens/VOE/Assessment/components/AssessmentEngagement';
import { SatisfactionAnalytic } from 'screens/VOE/Assessment/components/AssessmentSatisfaction';
import { PersonalWellbeingAnalytic } from 'screens/VOE/Assessment/components/AssessmentWellbeing';
import { MoodAnalytic, HappinessAnalytic, DiscomfortAnalytic, ForumAnalytic } from 'screens/VOE/Usage/components/UsageAnalytic';

const MAX_LABEL = 10;

export default function UsageAnalytic(props: {
	orgCode: string,
	departCode: string,
	industryName: string,
	monthValue: number,
	yearValue: number,
}) {
	useLayoutEffect(() => {
		Chart.register(ChartDataLabels);
	});

	return (
		<>
			<div className='flex gap-8'>
				<div className='basis-1/2'>
					<MonthlyScreenAnalytic { ...props } isMinimal={ true }></MonthlyScreenAnalytic>
				</div>
				<div className='basis-1/2'>
					<DailyScreenAnalytic { ...props } isMinimal={ true }></DailyScreenAnalytic>
				</div>
			</div>
			<div className='flex gap-8'>
				<div className='basis-1/2'>
					<MoodAnalytic { ...props } isMinimal={ true }></MoodAnalytic>
				</div>
				<div className='basis-1/2'>
					<HappinessAnalytic { ...props } isMinimal={ true }></HappinessAnalytic>
				</div>
			</div>
			<div className='flex gap-8'>
				<div className='basis-1/2'>
					<DiscomfortAnalytic { ...props } isMinimal={ true }></DiscomfortAnalytic>
				</div>
				<div className='basis-1/2'>
					<ForumAnalytic { ...props } isMinimal={ true }></ForumAnalytic>
				</div>
			</div>

			{/* Assessments */ }
			<div className='flex gap-8'>
				<div className='basis-1/2'>
					<SatisfactionAnalytic { ...props } isMinimal={ true }></SatisfactionAnalytic>
				</div>
				<div className='basis-1/2'>
					<EngageAnalytic { ...props } isMinimal={ true }></EngageAnalytic>
				</div>
			</div>
			<div className='flex gap-8'>
				<div className='basis-1/2'>
					<PersonalWellbeingAnalytic { ...props } isMinimal={ true }></PersonalWellbeingAnalytic>
				</div>
				<div className='basis-1/2'>
					<DepressionAnalytic { ...props } isMinimal={ true }></DepressionAnalytic>
				</div>
			</div>
			<div className='flex gap-8'>
				<div className='basis-1/2'>
					<AnxietyAnalytic { ...props } isMinimal={ true }></AnxietyAnalytic>
				</div>
				<div className='basis-1/2'>
					<StressAnalytic { ...props } isMinimal={ true }></StressAnalytic>
				</div>
			</div>
		</>
	);
}
