import { Box, Checkbox, IconButton, TableCell, TableRow, Typography } from '@mui/material';
import { Images } from 'constant';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';

UserTableRows.propTypes = {
	row: PropTypes.object,
	selected: PropTypes.bool,
	onEditRow: PropTypes.func,
	onSelectRow: PropTypes.func,
	handleDelete: PropTypes.func,
	openModal: PropTypes.func,
	handleClickFunc: PropTypes.func,
	changeRole: PropTypes.func,
	isSelectedRow: PropTypes.bool,
	changeSpecialist: PropTypes.func,
};
export default function UserTableRows({ row, isSelectedRow, changeSpecialist, onEditRow, changeRole, labelId, handleClickFunc }) {
	const {
		department_code,
		email, organization_code, member_code,
		role_name, status, gender, birthyear,
		job_title, city, country, marital
	} = row;

	const refRights = useRef(null);
	const [isOpenRights, setIsOpenRights] = useState(false);

	let location = '-';
	if (city && country) {
		location = city + ", " + country;
	}

	return (
		<TableRow hover key={ row.invitation_email } aria-checked={ isSelectedRow } role='checkbox' tabIndex={ -1 } selected={ isSelectedRow } >
			<TableCell padding='checkbox' align='center'>
				<Checkbox
					onClick={ event => handleClickFunc(event, row.invitation_email, row.invitation_code, row.member_code) }
					checked={ isSelectedRow }
					inputProps={ { 'aria-labelledby': labelId } }
					color='primary'
				/>
			</TableCell>

			<TableCell align='left'>
				<Typography variant='subtitle2' noWrap sx={ {
					fontFamily: 'Fonts.interRegular',
					width: '150px !important'
				} }>
					{ email }
				</Typography>
			</TableCell>

			<TableCell align='left' sx={ { textTransform: 'capitalize' } }>
				<Typography variant='subtitle2' ref={ refRights } onClick={ () => setIsOpenRights(true) } noWrap sx={ {
					fontFamily: 'Fonts.interRegular',
					width: '100px !important'
				} }>

					{ ((role_name === 'psycholog_org') || (role_name === 'psycholog')) ? <span style={ {
						textTransform: 'uppercase',
						background: '#F3F4F6',
						padding: '4px 12px',
						borderRadius: '10px',
						fontSize: '12px',
						fontWeight: '600'
					} }>Specialist</span> : <span style={ {
						fontSize: '12px',
						fontWeight: '600',
						color: '#FFFFFF',
						textTransform: 'uppercase',
						background: '#292A2E',
						padding: '4px 12px',
						borderRadius: '10px',
						display: 'flex',
						width: 'max-content'
					} }>{ role_name }</span> }
				</Typography>
			</TableCell>

			<TableCell align='left' sx={ { textTransform: 'capitalize' } }>
				<Typography variant='subtitle2' noWrap sx={ {
					fontFamily: 'Fonts.interRegular',
					width: '100px !important'
				} }>
					{ job_title }
				</Typography>
			</TableCell>

			<TableCell align='left' sx={ { textTransform: 'capitalize' } }>
				<Typography variant='subtitle2' noWrap sx={ {
					fontFamily: 'Fonts.interRegular',
					width: '100px !important'
				} }>
					{ birthyear ?? '-' }
				</Typography>
			</TableCell>

			<TableCell align='left' sx={ { textTransform: 'capitalize' } }>
				<Typography variant='subtitle2' noWrap sx={ {
					fontFamily: 'Fonts.interRegular',
					width: '100px !important'
				} }>
					{ location }
				</Typography>
			</TableCell>

			<TableCell align='left' sx={ { textTransform: 'capitalize' } }>
				<Typography variant='subtitle2' noWrap sx={ {
					fontFamily: 'Fonts.interRegular',
					width: '100px !important'
				} }>
					{ gender === 'rather_not_saying' ? 'Prefer Not to Say' : gender }
				</Typography>
			</TableCell>

			<TableCell align='left' sx={ { textTransform: 'capitalize' } }>
				<Typography variant='subtitle2' noWrap sx={ {
					fontFamily: 'Fonts.interRegular',
					width: '100px !important'
				} }>
					{ marital ?? '-' }
				</Typography>
			</TableCell>

			<TableCell align='left' sx={ { textTransform: 'capitalize' } }>
				<Typography variant='subtitle2' noWrap sx={ {
					fontFamily: 'Fonts.interRegular',
					width: '100px !important'
				} }>
					{ status === 'active' ? <span style={ {
						textTransform: 'uppercase',
						background: '#E8F4FB',
						padding: '4px 12px',
						borderRadius: '10px',
						fontSize: '12px',
						color: '#2D9CDB',
						fontWeight: '600'
					} }>Active</span> :
						<span style={ {
							fontSize: '12px',
							fontWeight: '600',
							color: '#FF1F7D',
							textTransform: 'uppercase',
							background: '#FFE9F2',
							padding: '4px 12px',
							borderRadius: '10px'
						} }> { status === 'pending_verification' ? 'EMAIL SENT' : status === 'pending_invitation' ? 'EMAIL UNSENT' : status } </span> }
				</Typography>
			</TableCell>
			<TableCell align='left' sx={ { textTransform: 'capitalize' } }>
				<Box sx={ { width: '100px' } }>
					<IconButton onClick={ changeSpecialist }>
						<img src={ Images.btn_specialist_act } alt="" />
					</IconButton>
					<IconButton onClick={ () => onEditRow(member_code, organization_code, department_code, 'banned', role_name) }>
						<img src={ Images.btn_flag_act } alt="" />
					</IconButton>
					{/* <IconButton ref={ ref } onClick={ () => setIsOpen(true) } className='user-action'>
						<Iconify icon='eva:more-horizontal-fill' width={ 20 } sx={ {} } height={ 20 } />
					</IconButton>
					<Menu
						open={ isOpen }
						anchorEl={ ref.current }
						onClose={ () => setIsOpen(false) }
						PaperProps={ {
							sx: {
								width: 200,
								maxWidth: '100%'
							},
						} }
						anchorOrigin={ {
							vertical: 'top',
							horizontal: 'right'
						} }
						transformOrigin={ {
							vertical: 'top',
							horizontal: 'right'
						} }
					>
						{ ((role_name === 'psycholog_org') || (role_name === 'psycholog')) ?
							''
							:
							<MenuItem sx={ { color: 'text.secondary' } }>
								<ListItemText onClick={ e => changeSpecialist() } primary='Change to specialist' primaryTypographyProps={ { variant: 'body2' } } />
							</MenuItem>
						}
						{
							status !== 'banned' ?
								<MenuItem sx={ { color: 'text.secondary' } }>
									<ListItemText primary='Ban User' onClick={ e => onEditRow(member_code, organization_code, department_code, 'banned', role_name) } primaryTypographyProps={ { variant: 'body2' } } />
								</MenuItem> : ''
						}
						{
							status !== 'inactive' ?
								<MenuItem sx={ { color: 'text.secondary' } }>
									<ListItemText onClick={ e => onEditRow(member_code, organization_code, department_code, 'inactive', role_name) } primary='Delete' primaryTypographyProps={ { variant: 'body2' } } />
								</MenuItem> : ''
						}
					</Menu> */}
				</Box>
			</TableCell>

		</TableRow>
	);
}
