import { dispatch } from "store";
import TranslationStoreType from "./actionTypes";

export const changeLanguage = (lang: string) => {
	dispatch({
		type: TranslationStoreType.ChangeLanguage,
		payload: lang,
	});
};

export const storeLanguage = (localization: any) => {
	dispatch({
		type: TranslationStoreType.StoreLanguage,
		payload: localization,
	});
};
