import { VoeUA } from 'interfaces';

import { VoeUAActionTypes } from './actionTypes';

const initialState: VoeUA.State = {
	voeualists: [],
	voeuakey: [],
	voeuavalue: [],
	pagination: [],
	error: '',
	loadingGet: false,
	loadingAdd: false,
	loadingDelete: false,
	loadingUpdate: false
};

const setVoeUA = (state: VoeUA.State, payload:VoeUA.VoeUAPayload) => ({
	...state,
	voeuakey: payload.listKey as VoeUA.Detail[],
	voeuavalue: payload.listValue as VoeUA.Detail[],
	pagination: payload.pagination as VoeUA.Pagination[],
	loadingGet: false
});

const reqVoeUA = (state: VoeUA.State, type: VoeUAActionTypes) => ({
	...state,
	loadingGet: type === VoeUAActionTypes.GET_VOE_UA_LIST_LOADING,
	loadingUpdate: false
});

const rejectVoeUA = (state: VoeUA.State) => ({
	...state,
	loadingGet: false,
	loadingAdd: false,
	loadingDelete: false,
	loadingUpdate: false,
});

const voeualistsReducer = (state:VoeUA.State = initialState, action: {type: VoeUAActionTypes; payload: VoeUA.VoeUAPayload;}) => {
	switch (action.type) {
		case VoeUAActionTypes.GET_VOE_UA_LIST_SUCCESS:
			return setVoeUA(state, action.payload);

		case VoeUAActionTypes.GET_VOE_UA_LIST_LOADING:
			return reqVoeUA(state, action.type);

		case VoeUAActionTypes.GET_VOE_UA_LIST_ERROR:
			return rejectVoeUA(state);
		default:
			return state;
	}
};

export default voeualistsReducer;
