import React from 'react';
import { Dialog, DialogTitle, Typography, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { PaperComponent } from 'components/Modal/Paper';
import { IVoidCallback } from 'interfaces/callback';
import Iconify from 'screens/Layout/components/Iconify';

export default function ModalError(props: { isOpen: boolean, onClose: IVoidCallback, errors: string[]; }) {
    return (
        <Dialog
            open={ props.isOpen }
            onClose={ (_, reason) => {
                if (reason !== 'backdropClick') {
                    props.onClose();
                }
            } }
            PaperComponent={ PaperComponent }
            aria-labelledby='draggable-dialog-title'
            PaperProps={ { sx: { width: '520px', } } }
        >
            <DialogTitle style={ {
                cursor: 'move',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            } } id='draggable-dialog-title'>
                <Iconify icon='carbon:close-filled' sx={ {
                    fontSize: '20px',
                    color: '#CC1964'
                } } /> &nbsp;
                <Typography style={ {
                    fontSize: '18px',
                    color: '#292A2E',
                    fontFamily: 'Inter-Medium'
                } }>We found error(s) in the excel file</Typography>
            </DialogTitle>
            <DialogContent sx={ { textAlign: 'center' } }>
                <DialogContentText sx={ {
                    marginTop: '20px',
                    display: 'inline-flex',
                    flexDirection: 'column'
                } }>
                    <div style={ {
                        border: 'solid 1px #bfbfbf',
                        borderRadius: '0 0 15px 15px',
                        padding: '10px 20px 20px 20px',
                    } }>
                        {
                            props.errors.map(row => {
                                return (<Typography sx={ {
                                    background: '#ffdddd',
                                    borderRadius: '10px',
                                    display: 'flex',
                                    padding: '10px 10px',
                                    marginBottom: '10px',
                                    fontSize: '12px'
                                } } key={ row }><Iconify icon='eva:info-fill' sx={ {
                                    fontSize: '15px',
                                    color: '#000000',
                                    marginTop: '1px'
                                } } /> &nbsp; { row }</Typography>);
                            })
                        }
                    </div>

                </DialogContentText>

            </DialogContent>
            <DialogActions sx={ { padding: '0 30px 20px 30px' } }>
                <Button className='btn btn-action-filter-dark' style={ {
                    width: '100%',
                    marginTop: '20px'
                } } onClick={ props.onClose }>Got it</Button>
            </DialogActions>
        </Dialog>
    );
}