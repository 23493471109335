import {
  Badge,
  Box,
  FormControl,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { JOB_STATUS_PROCESS, JOB_STATUS_QUEUE } from "Worker";
import { Images } from "constant";
import accessKey from "constant/Access";
import { LocalStorage } from "helpers";
import {
  Building4,
  Category,
  DocumentText,
  HeartCircle,
  Message,
  Profile2User,
  Setting2,
} from "iconsax-react";
import { ILocalizationContent } from "interfaces/translation";
import React, { useEffect, useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import Iconify from "screens/Layout/components/Iconify";
import { useAppSelector } from "store";
import { changeLanguage } from "store/translation/actions";
import { utils } from "utils";
import MenuItems from "./MenuItem";

const DRAWER_WIDTH = 280;
const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

interface IMenu {
  name: string;
  to: string;
  icon?: JSX.Element;
  accessKey?: string;
  children?: IMenu[];
}

const langs = [
  {
    label: "English",
    key: "en",
  },
  {
    label: "Indonesia",
    key: "id",
  },
];

export default function SideMenu(props: {
  isOpen: boolean;
  onToggleNav: () => void;
}) {
  const [notifCount, setNotifCount] = useState(0);
  const [activeMenu, setActiveMenu] = useState(-1);
  const [langValue, setLangValue] = useState("en");
  const { jobs } = useAppSelector((state) => state.jobs);
  const { translation } = useAppSelector((state) => state);

  const menuItems = getMenus(translation);

  const onChangeLanguage = (val: string) => {
    utils.setLanguage(val);

    changeLanguage(val);
    setLangValue(val);
  };

  useEffect(() => {
    let temp = 0;
    for (const item of jobs) {
      if (
        item.job_status === JOB_STATUS_QUEUE ||
        item.job_status === JOB_STATUS_PROCESS
      ) {
        temp++;
      }
    }

    setNotifCount(temp);
  }, [jobs]);

  useEffect(() => {
    onChangeLanguage(utils.getLanguage());
  }, []);

  useEffect(() => {
    if (!props.isOpen) {
      setActiveMenu(-1);
    }
  }, [props.isOpen]);

  return (
    <RootStyle className={`root-style ${!props.isOpen ? "inactive" : ""}`}>
      <div
        className={`side-menu overflow-x-clip overflow-y-auto pb-24 ${
          !props.isOpen ? "inactive" : ""
        }`}
      >
        {/* Menu Header */}
        <Header isOpen={props.isOpen} onToggleNav={props.onToggleNav}></Header>

        {/* Menu Localization */}
        <Localization
          isOpen={props.isOpen}
          langValue={langValue}
          onChangeLanguage={onChangeLanguage}
        ></Localization>

        {/* Menus */}
        <Menus
          activeMenu={activeMenu}
          isOpen={props.isOpen}
          menuItems={menuItems}
          notifCount={notifCount}
          onToggleNav={props.onToggleNav}
          setActiveMenu={setActiveMenu}
        ></Menus>

        {/* Menu Footer */}
        {/* <Footer></Footer> */}
      </div>
    </RootStyle>
  );
}

function getMenus(translation: ILocalizationContent): IMenu[] {
  return [
    {
      name: translation.dashboard,
      to: "/dashboard/metrics",
      icon: (
        <Category
          variant="Bold"
          className="text-neutral-secondary"
          size="28"
        ></Category>
      ),
      accessKey: accessKey.Dashboard,
      children: [
        {
          name: "Session",
          to: "/dashboard/session",
        },
        {
          name: "Refund",
          to: "/dashboard/refund",
        },
        {
          name: "Promo Code",
          to: "/dashboard/promo",
        },
      ],
    },
    {
      name: translation.content,
      to: "forum/post",
      icon: (
        <Message
          variant="Bold"
          className="text-neutral-secondary"
          size="28"
        ></Message>
      ),
      accessKey: accessKey.Content,
      children: [
        {
          name: "Article",
          to: "forum/insight",
        },
        {
          name: "Guided Exercise",
          to: "forum/exercise",
        },
      ],
    },
    {
      name: translation.individual,
      to: "manage/member_individual",
      icon: (
        <Profile2User
          variant="Bold"
          className="text-neutral-secondary"
          size="28"
        ></Profile2User>
      ),
      accessKey: accessKey.Individual,
      children: [
        {
          name: "Individual Member",
          to: "manage/member_individual",
        },
        {
          name: "Individual Transaction",
          to: "billing/individual",
        },
      ],
    },
    {
      name: translation.specialist,
      to: "manage/member_specialist",
      icon: (
        <HeartCircle
          variant="Bold"
          className="text-neutral-secondary"
          size="28"
        ></HeartCircle>
      ),
      accessKey: accessKey.Specialist,
      children: [
        {
          name: "Spesialist Member",
          to: "manage/member_specialist",
        },
        {
          name: "Specialist Availability",
          to: "manage/availability",
        },
        {
          name: "Specialist Rates",
          to: "manage/rates",
        },
        {
          name: "Specialist Earnings",
          to: "billing/specialist",
        },
      ],
    },
    {
      name: translation.organization,
      to: "manage/organization",
      icon: (
        <Building4
          variant="Bold"
          className="text-neutral-secondary"
          size="28"
        ></Building4>
      ),
      accessKey: accessKey.Organization,
      children: [
        {
          name: "Organization",
          to: "manage/organization",
        },
        {
          name: "Organization Member",
          to: "manage/member_organization",
        },
        {
          name: "Import Member",
          to: "notification/import-user",
        },
      ],
    },
    {
      name: translation.report,
      to: "voe/overall",
      icon: (
        <DocumentText
          variant="Bold"
          className="text-neutral-secondary"
          size="28"
        ></DocumentText>
      ),
      accessKey: accessKey.Report,
      children: [
        {
          name: "Overall Report",
          to: "voe/overall",
        },
        {
          name: "Metrics",
          to: "/dashboard/metrics",
        },
        {
          name: "Pulse Report",
          to: "voe/usage",
        },
        {
          name: "Usage Report",
          to: "voe/application",
        },
        {
          name: "Screening Report",
          to: "voe/assessment",
        },
        {
          name: "Feedback Report",
          to: "voe/anonymous",
        },
        {
          name: "App Feedback",
          to: "/dashboard/app-feedback",
        },
      ],
    },
    // {
    // 	name: translation.controlPanel,
    // 	to: "panel/admin",
    // 	icon: <Setting4 variant='Bold' className='text-neutral-secondary' size='28'></Setting4>,
    // 	accessKey: accessKey.ControlPanel,
    // 	children: [
    // 		{
    // 			name: "Maxi Point",
    // 			to: "panel/point",
    // 		},
    // 	],
    // },
    {
      name: translation.setting,
      to: "panel/admin",
      icon: (
        <Setting2
          variant="Bold"
          className="text-neutral-secondary"
          size="28"
        ></Setting2>
      ),
      accessKey: accessKey.Admin,
      children: [
        {
          name: "Admin Roles",
          to: "panel/admin",
        },
        {
          name: "Topic",
          to: "panel/topic",
        },
        {
          name: "Maxi Point",
          to: "panel/point",
        },
      ],
    },
  ];
}

function Footer() {
  const ref = useRef(null);

  const [isOpenMenu, setOpenMenu] = useState(false);

  const onLogout = () => {
    LocalStorage.clearToken();
    LocalStorage.clearName();
    LocalStorage.setLastRefreshDate("");
    location.href = "/";
  };

  return (
    <div className="side-menu-footer fixed bottom-0">
      <div className="avatar">
        <img src={Images.avatar} alt="" />
      </div>
      <div className="user-info">
        <h5>{LocalStorage.getName()}</h5>
        <p>Admin</p>
      </div>

      <IconButton
        ref={ref}
        onClick={() => setOpenMenu(true)}
        className="user-action"
      >
        <Iconify
          icon="eva:more-horizontal-fill"
          width={20}
          sx={{}}
          height={20}
        />
      </IconButton>
      <Menu
        open={isOpenMenu}
        anchorEl={ref.current}
        onClose={() => setOpenMenu(false)}
        PaperProps={{
          sx: {
            width: 200,
            maxWidth: "100%",
          },
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          component={RouterLink}
          to="#"
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <Iconify
              icon="eva:log-out-outline"
              width={24}
              sx={{}}
              height={24}
            />
          </ListItemIcon>
          <ListItemText
            primary="Logout"
            onClick={onLogout}
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
      </Menu>
    </div>
  );
}

function Header(props: { isOpen: boolean; onToggleNav: () => void }) {
  return (
    <div className="top-section">
      <div className="logo">
        <img
          alt="maxi-cms"
          src={String(Images.logoMaxiRoundSmall)}
          style={{ width: " 56px " }}
        />
      </div>
      <div onClick={props.onToggleNav} className="toggle-menu-btn">
        {!props.isOpen ? (
          <Iconify
            icon={"bi:arrow-right-square"}
            sx={{}}
            width={20}
            height={20}
          />
        ) : (
          <Iconify
            icon={"bi:arrow-left-square"}
            sx={{}}
            width={20}
            height={20}
          />
        )}
      </div>
    </div>
  );
}

function Localization(props: {
  isOpen: boolean;
  langValue: string;
  onChangeLanguage: (val: string) => void;
}) {
  return (
    <div className="mt-8 px-4" hidden={!props.isOpen}>
      <FormControl
        className="w-full"
        sx={{
          m: 0,
        }}
      >
        <Select
          displayEmpty
          input={<OutlinedInput style={{ paddingBottom: "8px" }} />}
          inputProps={{ "aria-label": "Without label" }}
          sx={{
            height: "40px",
            fontSize: "14px",
            fontFamily: "Inter-Medium",
            backgroundColor: "white",
          }}
          value={props.langValue}
          onChange={(e) => props.onChangeLanguage(e.target.value)}
        >
          {langs.map((item, index) => {
            return (
              <MenuItem key={`industry-${index}`} value={item.key}>
                <ListItemText primary={item.label} />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}

function Menus(props: {
  isOpen: boolean;
  notifCount: number;
  activeMenu: number;
  menuItems: IMenu[];
  setActiveMenu: (val: number) => void;
  onToggleNav: () => void;
}) {
  const { access } = useAppSelector((state) => state.access);

  return (
    <div className="main-menu mt-4">
      <ul>
        {props.menuItems.map((menuItem, index) => {
          if (utils.isAccessExist(access, menuItem.accessKey as string)) {
            if (menuItem.accessKey === "ORGANIZATION") {
              return (
                <MenuItems
                  key={index}
                  name={menuItem.name}
                  to={menuItem.to}
                  subMenus={menuItem.children || []}
                  iconClassName={
                    props.notifCount > 0 ? (
                      <div className="relative">
                        {menuItem.icon}
                        <div className="absolute top-0 right-0">
                          <Badge
                            color="primary"
                            badgeContent={
                              <Box
                                sx={{
                                  transform:
                                    "translateY(-10px) translateX(1px)",
                                }}
                              >
                                {props.notifCount}
                              </Box>
                            }
                          ></Badge>
                        </div>
                      </div>
                    ) : (
                      menuItem.icon
                    )
                  }
                  isExpand={props.activeMenu === index}
                  onClick={() => {
                    if (props.activeMenu !== index) {
                      props.setActiveMenu(index);
                    } else {
                      props.setActiveMenu(-1);
                    }

                    if (!props.isOpen) {
                      props.onToggleNav();
                    }
                  }}
                />
              );
            }

            return (
              <MenuItems
                key={index}
                name={menuItem.name}
                to={menuItem.to}
                subMenus={menuItem.children || []}
                iconClassName={menuItem.icon}
                isExpand={props.activeMenu === index}
                onClick={() => {
                  if (props.activeMenu !== index) {
                    props.setActiveMenu(index);
                  } else {
                    props.setActiveMenu(-1);
                  }

                  if (!props.isOpen) {
                    props.onToggleNav();
                  }
                }}
              />
            );
          }

          return null;
        })}
      </ul>
    </div>
  );
}
