import React, {
	useState, useEffect, useRef
} from 'react';
import PropTypes from 'prop-types';
import {
	Box,
	Table,
	Tooltip,
	TableBody,
	IconButton,
	Typography,
	TableContainer,
	TextField,
	TablePagination,
} from '@mui/material';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { ModalDefault } from 'components/Modal';
import { useModal } from 'components/Modal/useModal';
import { Button as ButtonStyle } from 'screens/style';
import { TitleModal, LabelModal } from 'components/Modal/style';
import { Colors, Fonts } from 'constant';

// hooks
import { hooks } from 'helpers';
import useTable, { emptyRows } from 'components/Table/useTable';

import {
	TableEmptyRows, TableHeadCustom, TableNoData
} from 'components/Table';
// sections
import { UserTableRow } from 'sections/@dashboard/forum/flag/ConfirmedTabComponents';

const TABLE_HEAD = [
	{
		id: 'comment',
		label: 'COMMENT',
		alignRight: false
	},
	{
		id: 'reasons',
		label: 'REASONS',
		alignRight: false
	},
	{
		id: 'context',
		label: 'CONTEXT',
		alignRight: false
	},
	{
		id: 'comment_by',
		label: 'COMMENT BY',
		alignRight: false
	},
	{
		id: 'flagged_by',
		label: 'FLAGGED BY'
	},
	{
		id: 'preview',
		label: 'PREVIEW'
	}
];

ConfirmedTab.propTypes = {
	list: PropTypes.object,
	pagination: PropTypes.object,
	getAnyPage: PropTypes.func,
	getChangeRows: PropTypes.func,
	onEdit: PropTypes.func,
	loadDataPagi: PropTypes.func,
	getPreview: PropTypes.func,
	preview: PropTypes.object,
};
export default function ConfirmedTab({ list, preview, pagination, getPreview, loadDataPagi, getAnyPage, getChangeRows, handleDelete, onEdit }) {
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('post_content');
	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};
	const { isShown, toggle } = useModal();
	const {
		dense,
		page,
		rowsPerPage,
		selected,
		onSelectRow,
		//
		onSort,
	} = useTable();

	const [filterName, setFilterName] = useState('');
	const [dataModalContent, setDataModalContent] = useState('');
	const [postCode, setPostCode] = useState('');
	const [valueD, setValueD] = React.useState<Date | null>(null);
	const [valueT, setValueT] = React.useState<Date | null>(null);
	const [dataModalBGURL, setDataModalBGURL] = useState('');

	const [filterRole, setFilterRole] = useState('all');

	const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = hooks.useTabs('active');

	const [tc, stc] = useState('');
	const openModal = cm => {
		toggle();
		stc(cm);
		getPreview(cm);

	};

	const dataFiltered = applySortFilter({
		list,
		comparator: getComparator(order, orderBy),
	});

	const formatDate = date => {
		return new Date(date).getDate() + ' ' + new Date(date).toLocaleString('default', { month: 'short' }) + ' ' + new Date(date).getFullYear() + ' ' + ('0' + new Date(date).getHours()).slice(-2) + ':' + ('0' + new Date(date).getMinutes()).slice(-2);
	};

	const denseHeight = dense ? 52 : 72;

	const contentModal = <React.Fragment>
		<Box>
			<Box>
				<Typography sx={ {
					marginTop: '10px',
					textAlign: 'left',
					fontSize: '16px',
					color: Colors.app.black,
					fontWeight: Fonts.weights.w5,
					fontFamily: 'Fonts.interMedium',
					marginBottom: '10px'
				} }>
					Flagged Comment:
				</Typography>

				<Typography sx={ {
					fontSize: '14px',
					color: '#FF579D',
					fontWeight: Fonts.weights.w5,
					fontFamily: 'Fonts.interMedium',
					lineHeight: '21px'
				} }>
					{ preview[0]?.comment_content }
				</Typography>

				<Typography sx={ {
					color: '#767E8C',
					fontSize: '14px',
					lineHeight: '150%',
					fontWeight: Fonts.weights.w4,
					fontFamily: 'Fonts.interRegular',
					marginTop: '10px',
					paddingBottom: '20px',
					borderBottom: 'solid 1px #E0E5ED'
				} }>
					{ preview[0]?.comment_from } ({ formatDate(preview[0]?.comment_created_date) })
				</Typography>

				<Typography sx={ {
					marginTop: '20px',
					textAlign: 'left',
					fontSize: '16px',
					color: Colors.app.black,
					fontWeight: Fonts.weights.w5,
					fontFamily: 'Fonts.interMedium',
					marginBottom: '10px'
				} }>
					Flagged Reasons:
				</Typography>

				{

					// Reason
					preview[0] === undefined ? 'empty' :
						[preview[0]?.flagged_reason].map((ppp, i) => (
							Object.keys(preview[0]?.flagged_reason).map((objk, k) => (
								<Box key={ i } >
									<Typography sx={ {
										fontSize: '14px',
										color: Colors.app.black,
										fontWeight: Fonts.weights.w5,
										fontFamily: 'Fonts.interMedium',
										lineHeight: '21px'
									} }>
										{ objk }
									</Typography>
									<Box key={ k } sx={ { marginBottom: '20px' } }>
										{ ppp[objk].map((dd, d) => (
											<Typography key={ d } sx={ {
												fontSize: '14px',
												color: '#FF579D',
												fontWeight: Fonts.weights.w5,
												fontFamily: 'Fonts.interMedium',
												lineHeight: '21px',
												marginTop: '10px'
											} }>
												{ dd }
											</Typography>
										)) }
									</Box>

								</Box>

								// ppp[objk].map((do, d) => (
								// 	<Typography key={ i } sx={ { fontSize: '14px',
								// 		color: '#00AC56',
								// 		fontWeight: Fonts.weights.w5,
								// 		fontFamily: 'Fonts.interMedium',
								// 		lineHeight: '21px' } }>
								// 		{ do }
								// 	</Typography>
								// ));
							))

							// const objKu = Object.keys(preview[0]?.flagged_reason);

							// objKu.map((objk, k) => (
							// 	<Typography key={ k } sx={ { fontSize: '14px',
							// 		color: '#00AC56',
							// 		fontWeight: Fonts.weights.w5,
							// 		fontFamily: 'Fonts.interMedium',
							// 		lineHeight: '21px' } }>
							// 		{ objk }
							// 	</Typography>
							// ));
						))
				}

				{ /* { preview[0]?.flagged_reason.map((fr, i) => (
					<Box key={i}>
						<Typography sx={ { fontSize: '14px',
							color: '#00AC56',
							fontWeight: Fonts.weights.w5,
							fontFamily: 'Fonts.interMedium',
							lineHeight: '21px' } }>
							{ fr.reason }
						</Typography>
				
						<Typography sx={ { color: '#767E8C',
							fontSize: '14px',
							lineHeight: '150%',
							fontWeight: Fonts.weights.w4,
							fontFamily: 'Fonts.interRegular',
							marginTop: '10px',
							paddingBottom: '20px',
							borderBottom: 'solid 1px #E0E5ED' } }>
							{ fr.flagged_by } ({ fr.created_date })
						</Typography>
					</Box>
				)) } */ }
				{ preview[0]?.postpoll.map((pp, i) => (
					<Box key={ i }>
						<Typography sx={ {
							marginTop: '20px',
							textAlign: 'left',
							fontSize: '16px',
							color: Colors.app.black,
							fontWeight: Fonts.weights.w5,
							fontFamily: 'Fonts.interMedium',
							marginBottom: '10px'
						} }>
							{ pp.poll_list === null ? 'Post' : 'Poll' } Title:
						</Typography>

						<Typography sx={ {
							fontSize: '14px',
							color: '#1F1F21',
							fontWeight: Fonts.weights.w5,
							fontFamily: 'Fonts.interMedium',
							lineHeight: '100%'
						} }>
							{ pp.content }
						</Typography>

						<Typography sx={ {
							color: '#767E8C',
							fontSize: '14px',
							lineHeight: '150%',
							fontWeight: Fonts.weights.w4,
							fontFamily: 'Fonts.interRegular',
							marginTop: '10px',
							paddingBottom: '20px'
						} }>
							{ pp.member_name === null ? pp.member_name : pp.admin_name } ({ formatDate(pp.published_date) })
						</Typography>
					</Box>
				)) }

				<Typography sx={ {
					marginTop: '20px',
					textAlign: 'left',
					fontSize: '16px',
					color: Colors.app.black,
					fontWeight: Fonts.weights.w5,
					fontFamily: 'Fonts.interMedium',
					marginBottom: '10px'
				} }>
					Thread ({ preview[0]?.thread.length }):
				</Typography>

				{

					preview[0]?.thread.map((tt, t) => {

						return (
							<Box key={ t }>
								<Typography sx={ {
									fontSize: '14px',
									color: '#767E8C',
									fontWeight: Fonts.weights.w5,
									fontFamily: 'Fonts.interMedium',
									lineHeight: '100%'
								} }>
									{ tt.comment_content_highlight }
								</Typography>
								<Typography sx={ {
									color: '#767E8C',
									fontSize: '14px',
									lineHeight: '150%',
									fontWeight: Fonts.weights.w4,
									fontFamily: 'Fonts.interRegular',
									marginTop: '10px',
									paddingBottom: '20px',
									borderBottom: 'solid 1px #E0E5ED'
								} }>
									{ tt.comment_name_highlight } ({ formatDate(tt.comment_created_date_highlight) })
								</Typography>

								<Typography sx={ {
									fontSize: '14px',
									color: '#1F1F21',
									fontWeight: Fonts.weights.w5,
									fontFamily: 'Fonts.interMedium',
									lineHeight: '100%'
								} }>
									{ tt.reply_content }
								</Typography>
								<Typography sx={ {
									color: '#1F1F21',
									fontSize: '14px',
									lineHeight: '150%',
									fontWeight: Fonts.weights.w4,
									fontFamily: 'Fonts.interRegular',
									marginTop: '10px',
									paddingBottom: '20px',
									borderBottom: 'solid 1px #E0E5ED'
								} }>
									{ tt.reply_name } ({ formatDate(tt.reply_created_date) })
								</Typography>
								<Typography sx={ {
									fontSize: '14px',
									color: '#1F1F21',
									fontWeight: Fonts.weights.w5,
									fontFamily: 'Fonts.interMedium',
									lineHeight: '100%'
								} }>
									{ tt.reply_to_content }
								</Typography>
								<Typography sx={ {
									color: '#767E8C',
									fontSize: '14px',
									lineHeight: '150%',
									fontWeight: Fonts.weights.w4,
									fontFamily: 'Fonts.interRegular',
									marginTop: '10px',
									paddingBottom: '20px',
									borderBottom: 'solid 1px #E0E5ED'
								} }>
									{ tt.reply_to_name } ({ formatDate(tt.reply_to_created_date) })
								</Typography>

							</Box>
						);
					})
				}

			</Box>
		</Box>
	</React.Fragment>;

	const contentModalFooter = <React.Fragment>
		<Box sx={ {
			display: 'flex',
			flexDirection: 'column',
			width: '100%'
		} } />
	</React.Fragment>;

	return (
		<div>
			<TableContainer sx={ {
				minWidth: 800,
				position: 'relative'
			} }>

				<Table size={ dense ? 'small' : 'medium' }>
					<TableHeadCustom
						order={ order }
						orderBy={ orderBy }
						onRequestSort={ handleRequestSort }
						headLabel={ TABLE_HEAD }
						rowCount={ list.length }
						numSelected={ selected.length }
						onSort={ onSort }
					/>

					<TableBody>
						{
							dataFiltered.map((row, index) => (
								<UserTableRow
									key={ index }
									row={ row }
									openModal={ () => openModal(row.transaction_code) }
								/>
							))
						}
						<TableEmptyRows height={ denseHeight } emptyRows={ dataFiltered.length } />

					</TableBody>
				</Table>
			</TableContainer>

			<Box sx={ { position: 'relative' } }>
				<TablePagination
					rowsPerPageOptions={ [10, 25, 100] }
					component='div'
					labelRowsPerPage='Items per page: '
					count={ pagination[0]?.count || 0 }
					rowsPerPage={ pagination[0]?.limit || 10 }
					page={ pagination[0]?.page - 1 || 0 }
					onPageChange={ (e, pg) => getAnyPage(pg) }
					onRowsPerPageChange={ getChangeRows }
				/>

			</Box>

			<ModalDefault isShown={ isShown } hide={ toggle } modalContent={ contentModal } reset={ () => console.log('Clearout') } modalFooter={ contentModalFooter } title='Preview Context' />

		</div>
	);
}

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}
function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter({ list, comparator }) {

	const stabilizedThis = list.map((el, index) => [el, index]);

	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});

	list = stabilizedThis.map(el => el[0]);

	return list;
}