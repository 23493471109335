import { Box, DialogActions, DialogContent, MenuItem, Select, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ModalSimple } from 'components/Modal/ModalSimple';
import { Colors, Fonts } from 'constant';
import { IVoidCallback } from 'interfaces/callback';
import React, { useEffect, useState } from 'react';
import { LabelModal } from 'screens/Login/style';
import { Button as ButtonStyle } from 'screens/style';

export default function ContentModal(props: {
    isEdit: boolean;
    currentItem: any;
    isShow: boolean;
    discountType: string;
    isAddContent: boolean;
    toggle: IVoidCallback;
    onSubmit: (val: any) => void;
}) {
    const [promoCode, setPromoCode] = useState('');
    const [value, setValue] = useState('');
    const [targetUser, setTargetUser] = useState('');
    const [minimumPurchase, setMinimumPurchase] = useState('');
    const [maximumDiscount, setMaximumDiscount] = useState('');
    const [promoBudget, setPromoBudget] = useState('');
    const [usageType, setUsageType] = useState('');
    const [scheduleStart, setScheduleStart] = useState(new Date());
    const [scheduleEnd, setScheduleEnd] = useState(new Date());

    const [isEnabled, setIsEnabled] = useState(false);

    const checkFormValid = () => {
        let checkMaximum = true;
        if (props.discountType !== 'flat') {
            if (maximumDiscount.length === 0) {
                checkMaximum = false;
            }
        }

        if (
            promoCode &&
            value &&
            targetUser &&
            promoBudget &&
            usageType &&
            checkMaximum
        ) {
            setIsEnabled(true);
        } else {
            setIsEnabled(false);
        }
    };

    useEffect(() => {
        checkFormValid();
    }, [promoCode, value, minimumPurchase, targetUser, promoBudget, maximumDiscount, usageType]);

    useEffect(() => {
        if (!props.isShow) {

            setPromoCode('');
            setValue('');
            setTargetUser('');
            setMinimumPurchase('');
            setPromoBudget('');
            setUsageType('');

            setIsEnabled(false);
            setScheduleStart(new Date());
            setScheduleEnd(new Date());
        } else {
            const item = props.currentItem;

            setPromoCode(item.promo_code ?? "");
            setValue(item.value ?? "");
            setPromoBudget(item.promo_budget ?? "");
            setTargetUser(item.target_user ?? "");
            setUsageType(item.use_type ?? "");
            setMinimumPurchase(item.min_purchase ?? "");
            setMaximumDiscount(item.max_discount ?? "");
            setScheduleStart(new Date(item.start_date));
            setScheduleEnd(new Date(item.end_date));
        }
    }, [props.isShow]);

    const onSubmit = async () => {
        setIsEnabled(false);

        // TODO: Need to check for promo type
        props.onSubmit({
            promo_code: promoCode,
            value: Number(value),
            promo_budget: Number(promoBudget),
            target_user: targetUser,
            min_purchase: Number(minimumPurchase),
            max_discount: Number(maximumDiscount),
            promo_type: 'discount',
            use_type: usageType,
            discount_type: props.discountType,
            start_date: scheduleStart.toISOString(),
            end_date: scheduleEnd.toISOString()
        });
    };

    return (
        <ModalSimple
            isShown={ props.isShow }
            title={ props.discountType === 'flat' ? props.isAddContent ? 'Add Fixed Amount Promo' : 'Update Fixed Amount Promo' : props.isAddContent ? 'Add Percentage Promo' : 'Update Percentage Promo' }
            hide={ props.toggle }
        >
            <>
                <DialogContent>
                    <Box sx={ {
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%'
                    } }>
                        <Box sx={ { overFlowY: 'initial !important' } }>

                            <div className='modalBodyC'>
                                <div className='input-wrapper' style={ { margin: '0 0 0 0' } }>
                                    <div className='schedule-cus' style={ { width: '100%' } }>
                                        <div style={ { width: '100%' } }>
                                            <LabelModal style={ {
                                                margin: '5px 0 10px 0',
                                                display: 'inline-block'
                                            } }>Promo Code</LabelModal>
                                            <div className='input-wrapper form-inline'>
                                                <input type='text'
                                                    disabled={ !props.isAddContent }
                                                    placeholder='Promo Code'
                                                    onChange={ val => setPromoCode(val.target.value) }
                                                    value={ promoCode }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {
                                    props.discountType === 'flat' ? (
                                        <div className='input-wrapper' style={ { margin: '0 0 0 0' } }>
                                            <div className='schedule-cus' style={ {
                                                width: '100%',
                                                paddingTop: '0'
                                            } }>
                                                <div style={ { width: '100%' } }>
                                                    <LabelModal style={ {
                                                        margin: '0 0 10px 0',
                                                        display: 'inline-block'
                                                    } }>Fixed Amount Discount</LabelModal>
                                                    <div className='input-wrapper form-inline'>
                                                        <input type='number'
                                                            placeholder='Fixed Amount IDR'
                                                            onChange={ e => setValue(e.target.value) }
                                                            value={ value }
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    ) : (
                                        <div className='input-wrapper' style={ { margin: '0 0 0 0' } }>
                                            <div className='schedule-cus' style={ {
                                                width: '100%',
                                                paddingTop: '0'
                                            } }>
                                                <div style={ { width: '100%' } }>
                                                    <LabelModal style={ {
                                                        margin: '0 0 10px 0',
                                                        display: 'inline-block'
                                                    } }>Percentage Discount</LabelModal>
                                                    <div className='input-wrapper form-inline'>
                                                        <input type='number'
                                                            placeholder='Percentage Discount %'
                                                            onChange={ e => setValue(e.target.value) }
                                                            value={ value }
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    )
                                }

                                <div className='input-wrapper' style={ { margin: '0 0 0 0' } }>
                                    <div className='schedule-cus' style={ {
                                        width: '100%',
                                        paddingTop: '0'
                                    } }>
                                        <div style={ { width: '100%' } }>
                                            <LabelModal style={ {
                                                margin: '0 0 10px 0',
                                                display: 'inline-block'
                                            } }>Minimum Purchase (Optional)</LabelModal>
                                            <div className='input-wrapper form-inline'>
                                                <input type='number'
                                                    placeholder='Minimum Purchase IDR (Optional)'
                                                    value={ minimumPurchase }
                                                    onChange={ val => setMinimumPurchase(val.target.value) }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {
                                    props.discountType !== 'flat' ? (
                                        <div className='input-wrapper' style={ { margin: '0 0 0 0' } }>
                                            <div className='schedule-cus' style={ {
                                                width: '100%',
                                                paddingTop: '0'
                                            } }>
                                                <div style={ { width: '100%' } }>
                                                    <LabelModal style={ {
                                                        margin: '0 0 10px 0',
                                                        display: 'inline-block'
                                                    } }>Maximum Discount { props.discountType === 'flat' ? "(Optional)" : "" }</LabelModal>
                                                    <div className='input-wrapper form-inline'>
                                                        <input type='number'
                                                            placeholder={ `Maximum Discount IDR ${props.discountType === 'flat' ? "(Optional)" : ""}` }
                                                            value={ maximumDiscount }
                                                            onChange={ val => setMaximumDiscount(val.target.value) }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null
                                }

                                <div className='input-wrapper' style={ { margin: '0 0 0 0' } }>
                                    <div className='schedule-cus' style={ {
                                        width: '100%',
                                        paddingTop: '0'
                                    } }>
                                        <div style={ { width: '100%' } }>
                                            <LabelModal style={ {
                                                margin: '0 0 10px 0',
                                                display: 'inline-block'
                                            } }>Promo Budget</LabelModal>
                                            <div className='input-wrapper form-inline'>
                                                <input type='number'
                                                    placeholder='Promo Budget IDR'
                                                    value={ promoBudget }
                                                    onChange={ val => setPromoBudget(val.target.value) }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='schedule-cus'>
                                    <div className='date-schedule-cus w-1/2'>
                                        <LabelModal style={ {
                                            margin: '0 0 10px 0',
                                            display: 'inline-block'
                                        } }>Tanggal Promo Mulai</LabelModal>
                                        <LocalizationProvider dateAdapter={ AdapterDateFns }>
                                            <DatePicker
                                                className='w-full'
                                                value={ scheduleStart }
                                                onChange={ newValue => {
                                                    if (!newValue) return;
                                                    setScheduleStart(newValue);
                                                } }
                                            />
                                        </LocalizationProvider>
                                    </div>
                                    <div className='time-schedule-cus w-1/2'>
                                        <LabelModal style={ {
                                            margin: '0 0 10px 0',
                                            display: 'inline-block'
                                        } }>Tanggal Promo Selesai</LabelModal>
                                        <LocalizationProvider dateAdapter={ AdapterDateFns }>
                                            <DatePicker
                                                className='w-full'
                                                value={ scheduleEnd }
                                                onChange={ newValue => {
                                                    if (!newValue) return;
                                                    setScheduleEnd(newValue);
                                                } }
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>

                                <div className='input-wrapper' style={ { margin: '0 0 0 0' } }>
                                    <div style={ {
                                        width: '100%',
                                        paddingTop: '16px',
                                        marginBottom: '8px'
                                    } }>
                                        <LabelModal style={ {
                                            margin: '5px 0 10px 0',
                                            display: 'block'
                                        } }>Target User</LabelModal>
                                        <Select
                                            displayEmpty
                                            inputProps={ { 'aria-label': 'Without label' } }
                                            value={ targetUser }
                                            onChange={ val => setTargetUser(val.target.value) }
                                            sx={ {
                                                width: '100%',
                                                border: 'solid 1px Colors.app.GhostGrey',
                                                background: Colors.white.default,
                                                borderRadius: '5px',
                                                color: Colors.app.black,
                                                fontWeight: Fonts.weights.w5,
                                                fontFamily: 'Fonts.interMedium',
                                                fontSize: Fonts.sizes.xs
                                            } }>
                                            <MenuItem value={ '' }>
                                                <em className='text-slate-400'>Choose Taget User</em>
                                            </MenuItem>
                                            <MenuItem value={ 'all' }>
                                                <em>All</em>
                                            </MenuItem>
                                            <MenuItem value={ 'organization' }>
                                                <em>Organization</em>
                                            </MenuItem>
                                        </Select>
                                    </div>
                                </div>
                                <div className='input-wrapper' style={ { margin: '0 0 0 0' } }>
                                    <div style={ {
                                        width: '100%',
                                        paddingTop: '16px',
                                        marginBottom: '8px'
                                    } }>
                                        <LabelModal style={ {
                                            margin: '5px 0 10px 0',
                                            display: 'block'
                                        } }>Usage Limit Per Email</LabelModal>
                                        <Select
                                            displayEmpty
                                            inputProps={ { 'aria-label': 'Without label' } }
                                            value={ usageType }
                                            onChange={ val => setUsageType(val.target.value) }
                                            sx={ {
                                                width: '100%',
                                                border: 'solid 1px Colors.app.GhostGrey',
                                                background: Colors.white.default,
                                                borderRadius: '5px',
                                                color: Colors.app.black,
                                                fontWeight: Fonts.weights.w5,
                                                fontFamily: 'Fonts.interMedium',
                                                fontSize: Fonts.sizes.xs
                                            } }>
                                            <MenuItem value={ '' }>
                                                <em className='text-slate-400'>Choose Usage Limit Per Email</em>
                                            </MenuItem>
                                            <MenuItem value={ 'one_time' }>
                                                <em>One Time</em>
                                            </MenuItem>
                                            <MenuItem value={ 'repeated' }>
                                                <em>Repeated</em>
                                            </MenuItem>
                                        </Select>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions sx={ { padding: '20px 24px !important' } }>
                    <ButtonStyle className='btn-primary' disabled={ !isEnabled } title='Add New Specialist ' onClick={ onSubmit } style={ { marginTop: '30px' } } >
                        { props.isEdit ? 'Update Promo' : 'Add Promo' }
                    </ButtonStyle>
                </DialogActions>
            </>
        </ModalSimple>
    );
}
