import {
	Box,
	Table, TableBody, TableContainer, TablePagination
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';


// hooks
import useTable from 'components/Table/useTable';

import {
	TableEmptyRows, TableHeadCustom
} from 'components/Table';

// sections
import UserTableRow from 'sections/@dashboard/admin/Inactive';

const TABLE_HEAD = [
	{
		id: 'name',
		label: 'FULL NAME',
		alignRight: false
	}, {
		id: 'email',
		label: 'EMAIL',
		alignRight: false
	}, {
		id: 'role',
		label: 'SITE ROLE',
		alignRight: false
	}, { id: '' },
];

InactiveTab.propTypes = {
	list: PropTypes.object,
	pagination: PropTypes.object,
	getAnyPage: PropTypes.func,
	getChangeRows: PropTypes.func,
	loadDataPagi: PropTypes.func,
	onRestore: PropTypes.func,
};
export default function InactiveTab({ loadDataPagi, list, pagination, getAnyPage, getChangeRows, onRestore }) {
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('name');
	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const { dense, selected, onSort } = useTable();

	const dataFiltered = applySortFilter({
		list,
		comparator: getComparator(order, orderBy),
	});

	const denseHeight = dense ? 52 : 72;


	return (
		<div>
			<TableContainer sx={ {
				minWidth: 800,
				position: 'relative'
			} }>

				<Table size={ dense ? 'small' : 'medium' }>
					<TableHeadCustom
						order={ order }
						orderBy={ orderBy }
						headLabel={ TABLE_HEAD }
						onRequestSort={ handleRequestSort }
						rowCount={ list.length }
						numSelected={ selected.length }
						onSort={ onSort }
					/>

					<TableBody>
						{ dataFiltered.map((row, index) => (
							<UserTableRow
								key={ index }
								row={ row }
								onRestore={ () => onRestore(row) }
							/>
						)) }

						<TableEmptyRows height={ denseHeight } emptyRows={ dataFiltered.length } />

					</TableBody>
				</Table>
			</TableContainer>

			<Box sx={ { position: 'relative' } }>
				<TablePagination
					rowsPerPageOptions={ [10, 25, 100] }
					component='div'
					labelRowsPerPage='Items per page: '
					count={ pagination[0]?.count || 0 }
					rowsPerPage={ pagination[0]?.limit || 10 }
					page={ pagination[0]?.page - 1 || 0 }
					onPageChange={ (e, pg) => getAnyPage(pg) }
					onRowsPerPageChange={ getChangeRows }
				/>

			</Box>
		</div>
	);
}

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}
function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter({ list, comparator }) {

	const stabilizedThis = list.map((el, index) => [el, index]);

	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});

	list = stabilizedThis.map(el => el[0]);

	return list;
}