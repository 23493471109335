import { CheckBox } from "@mui/icons-material";
import { Checkbox, DialogContent } from "@mui/material";
import { ModalSimple } from "components/Modal/ModalSimple";
import { Endpoints } from "constant";
import { DocumentText } from "iconsax-react";
import React, { useEffect, useState } from "react";
import {
  getNoteByReservation,
  getNoteFilesByReservation,
} from "service/reservation";

export default function ModalNote(props: {
  reservationCode: string;
  isShow: boolean;
  onHide: () => void;
}) {
  const [note, setNote] = useState<any | null>(null);
  const [files, setFiles] = useState<any[]>([]);

  useEffect(() => {
    if (props.isShow) {
      getNoteByReservation({
        reservationCode: props.reservationCode,
      }).then((val) => setNote(val.data));

      getNoteFilesByReservation({
        reservationCode: props.reservationCode,
      }).then((val) => setFiles(val.data ?? []));
    }
  }, [props.isShow, props.reservationCode]);

  return (
    <ModalSimple
      hide={props.onHide}
      isShown={props.isShow}
      title="Client Progress Note"
      width="512px"
    >
      <DialogContent>
        {note === null ? (
          <div className="text-center my-12">No Notes yet</div>
        ) : (
          <>
            <div className="flex gap-2 items-center mb-4">
              <Checkbox defaultChecked={note.red_flag} color="default" />
              <div>Need further consultation</div>
            </div>
            <div className="ml-3">
              <Item
                title="Description of issues"
                value={note.description_issues}
              ></Item>
              <Item
                title="Assessment of situation"
                value={note.assessment_situation}
              ></Item>
              <Item
                title="Plans for follow up"
                value={note.plan_followup}
              ></Item>
            </div>

            <div className="mb-6 ml-3">
              <div className="text-neutral-secondary font-medium mb-2">
                Attachment
              </div>
              {files.map((val, i) => {
                const parts = val.file_url.split(".");
                return (
                  <AttachmentItem
                    key={val.counseling_note_file_code}
                    link={val.file_url}
                    title={`Attachment-${i + 1}.${
                      parts.length > 1 ? parts[1] : "txt"
                    }`}
                  ></AttachmentItem>
                );
              })}
            </div>
          </>
        )}
      </DialogContent>
    </ModalSimple>
  );
}

function AttachmentItem(props: { title: string; link: string }) {
  return (
    <div className="flex justify-between items-center my-4">
      <div className="flex items-center gap-2">
        <DocumentText variant="Linear"></DocumentText>
        <div>{props.title}</div>
      </div>
      <a
        className="text-brand-dark"
        href={`${Endpoints.baseUrlAws}${props.link}`}
        target="_blank"
        rel="noreferrer"
      >
        Download
      </a>
    </div>
  );
}

function Item(props: { title: string; value: string }) {
  return (
    <div className="mb-6">
      <div className="text-neutral-secondary font-medium mb-2">
        {props.title}
      </div>
      <div className="text-neutral-primary font-medium">{props.value}</div>
    </div>
  );
}
