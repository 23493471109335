import styled from 'styled-components';
import { Fonts, Colors } from 'constant';

export interface Props {
    textAlign: string;
}
export interface Align {
    margin: string;
}

const LogoMaxiSmall = styled.img`
    width:70px;
    height:70px;
    margin:0 auto;
`;

const HeroLogin = styled.img`
    width:100%;
`;

const SubTitle = styled.h3`
    font-size:${Fonts.sizes.s2};
    font-weight:${Fonts.weights.w4};
    font-family:'${Fonts.interRegular}';
    color:${Colors.app.SecondaryGrey};
    margin-bottom:40px;
    text-align:${(p: Props) => p.textAlign};
    line-height: 150%;
`;
const Title = styled.h3`
    font-size:${Fonts.sizes.m2};
    font-weight:${Fonts.weights.w6};
    font-family:'${Fonts.interRegular}';
    color:${Colors.app.black};
    margin:${(a: Align) => a.margin};
`;
const BoxWrap = styled.div`
    display:flex;
    flex-direction:column;
    margin-bottom:20px;
`;
const Label = styled.label`
    color:${Colors.app.black};
    font-weight:${Fonts.weights.w5};
    font-family:'${Fonts.interMedium}';
    font-size:${Fonts.sizes.xs2};
`;
const Input = styled.input`
    margin-top:8px;    
    padding:15px 20px;
    width:450px;
    border: solid 1px ${Colors.app.GhostGrey};
    background: ${Colors.white.default};
    border-radius:5px;
    color:${Colors.app.black};
    font-weight:${Fonts.weights.w5};
    font-family:'${Fonts.interMedium}';
    font-size:${Fonts.sizes.xs};
`;

export {
	LogoMaxiSmall, HeroLogin, SubTitle, Title, BoxWrap, Label, Input
};
