import { Admin } from 'interfaces';

import { AdminActionTypes } from './actionTypes';

const initialState: Admin.State = {
	list: [],
	pagination: [],
	error: '',
	loadingGet: false,
	loadingAdd: false,
	loadingDelete: false,
	loadingUpdate: false
};

const setAdmin = (state: Admin.State, payload:Admin.AdminPayload) => ({
	...state,
	list: payload.listData as Admin.Detail[],
	pagination: payload.pagination as Admin.Pagination[],
	loadingGet: false
});

const addAdmin = (state: Admin.State, payload:Admin.AdminPayload) => ({
	...state,
	list: state.list.concat(payload.data as Admin.Detail),
	loadingAdd: false
});

const removeAdmin = (state: Admin.State, payload:Admin.AdminPayload) => ({
	...state,
	list: state.list.filter(item => item.id !== payload?.data?.id),
	loadingDelete: false
});

const reqAdmin = (state: Admin.State, type: AdminActionTypes) => ({
	...state,
	loadingGet: type === AdminActionTypes.GET_ADMIN_LIST_LOADING,
	loadingAdd: type === AdminActionTypes.ADD_ADMIN_LOADING,
	loadingDelete: type === AdminActionTypes.DELETE_ADMIN_LOADING,
	loadingUpdate: false
});

const rejectAdmin = (state: Admin.State) => ({
	...state,
	loadingGet: false,
	loadingAdd: false,
	loadingDelete: false,
	loadingUpdate: false,
});

const adminReducer = (state:Admin.State = initialState, action: {type: AdminActionTypes; payload: Admin.AdminPayload;}) => {
	switch (action.type) {
		case AdminActionTypes.GET_ADMIN_LIST_SUCCESS:
			return setAdmin(state, action.payload);
		case AdminActionTypes.ADD_ADMIN_SUCCESS:
			return addAdmin(state, action.payload);
		case AdminActionTypes.DELETE_ADMIN_SUCCESS:
			return removeAdmin(state, action.payload);

		case AdminActionTypes.GET_ADMIN_LIST_LOADING:
		case AdminActionTypes.ADD_ADMIN_LOADING:
		case AdminActionTypes.DELETE_ADMIN_LOADING:
			return reqAdmin(state, action.type);

		case AdminActionTypes.GET_ADMIN_LIST_ERROR:
		case AdminActionTypes.ADD_ADMIN_ERROR:
		case AdminActionTypes.DELETE_ADMIN_ERROR:
			return rejectAdmin(state);
		default:
			return state;
	}
};

export default adminReducer;
