import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Select from "@mui/material/Select";
import { SearchStyle, TabPanelHidden, a11yProps } from "components/Panel";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Iconify from "screens/Layout/components/Iconify";
import { updateReservationSchedule } from "service/reservation";
import { getSpecialistListHaveSchedule } from "service/specialist";
import ModalLink from "./components/ModalLink";
import ModalModify from "./components/ModalModify";
import ModalNote from "./components/ModalNote";
import ModalPrecounseling from "./components/ModalPrecounseling";
import TabActived from "./components/TabActivated";
import TabDeleted from "./components/TabDeleted";
import ModalOvertime from "./components/ModalOvertime";
import { addOvertime, deleteOvertime } from "service/overtime";
import ModalOvertimeLink from "./components/ModalOvertimeLink";
import {
  ModalDelete,
  ModalOvertimeConfirm,
} from "components/Modal/ModalSimple";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Appbar from "components/Appbar";

export default function Session() {
  // Options Table
  const [filterSearch, setFilterSearch] = useState("");
  const [filterSpecialist, setFilterSpecialist] = useState("");
  const [filterStartDate, setFilterStartDate] = useState<Date | null>(null);
  const [filterEndDate, setFilterEndDate] = useState<Date | null>(null);

  // Tab Count
  const [activeCount, setActiveCount] = useState(0);
  const [inactiveCount, setInactiveCount] = useState(0);

  // Header
  const [specialists, setSpecialists] = useState<any[]>([]);

  // Tab Index
  const [tabValue, setTablIndex] = useState(0);

  // Modal
  const [isAddContent, setAddContent] = useState(true);
  const [currentItem, setCurrentItem] = useState<any>({});

  const [isShowModalModify, setShowModalModify] = useState(false);
  const [isShowModalPrecounseling, setShowModalPrecounseling] = useState(false);
  const [isShowModalNote, setShowModalNote] = useState(false);
  const [isShowModalLink, setShowModalLink] = useState(false);
  const [isShowModalOvertime, setShowModalOvertime] = useState(false);
  const [isShowModalOvertimeLink, setShowModalOvertimeLink] = useState(false);
  const [isShowModalDeleteOvertime, setShowModalDeleteOvertime] =
    useState(false);

  // Force Refresh
  const [refreshActive, setRefreshActive] = useState(0);

  // Use Effect
  useEffect(() => {
    if (!isShowModalModify && !isShowModalLink && !isShowModalOvertimeLink) {
      setAddContent(true);
      setCurrentItem({});
    }
  }, [isShowModalModify, isShowModalLink, isShowModalOvertimeLink]);

  useEffect(() => {
    getSpecialistListHaveSchedule({
      page: 1,
      limit: 1000,
    }).then((val) => {
      setSpecialists(val.data ?? []);
    });
  }, []);

  const onAddSession = () => {
    setAddContent(true);
    setShowModalModify(true);
  };

  const onEdit = (val: any) => {
    setCurrentItem(val);
    setShowModalModify(true);
    setAddContent(false);
  };

  const onShare = (val: any) => {
    if (
      ![
        "done",
        "confirmed",
        "rescheduled",
        "request_reschedule",
        "waiting_payment",
      ].includes(val.status)
    ) {
      return toast.warn("cannot share session!");
    }

    setCurrentItem(val);
    setShowModalLink(true);
  };

  const onOvertime = (val: any) => {
    if (val.overtime && val.overtime.status !== "created") {
      return toast.warn("Cannot edit already payed overtime!");
    }

    setCurrentItem(val);
    setShowModalOvertime(true);
  };

  const onOvertimeLink = (val: any) => {
    setCurrentItem(val);
    setShowModalOvertimeLink(true);
  };

  const onPrecounseling = (val: any) => {
    setCurrentItem(val);
    setShowModalPrecounseling(true);
  };

  const onCounselingNote = (val: any) => {
    setCurrentItem(val);
    setShowModalNote(true);
  };

  const onShareConfirm = (content: string) => {
    navigator.clipboard.writeText(content);

    toast.success("link already copied into your clipboard!");
  };

  const onDeleteOvertime = () => {
    if (currentItem.overtime.status !== "created") {
      return toast.warn("cannot delete overtime");
    }

    setShowModalOvertime(false);
    setShowModalDeleteOvertime(true);
  };

  const onDeleteOvertimeAction = async () => {
    await deleteOvertime({ reservationCode: currentItem.reservation_code });

    setShowModalDeleteOvertime(false);
    setRefreshActive(Math.random());
    toast.success("data already updated");
  };

  const onSubmitOvertime = async (val: any) => {
    await addOvertime({
      reservationCode: currentItem.reservation_code,
      ...val,
    });

    setShowModalOvertime(false);
    setRefreshActive(Math.random());
    toast.success("data already updated");
  };

  const onSubmit = async (data: any) => {
    if (isAddContent) {
      setShowModalLink(true);
      setCurrentItem(data);
      return;
    }

    const reservationCode = currentItem.reservation_code;
    const scheduleDetailCode = data.schedule_detail_code;
    const formatCall = data.format_call;

    await updateReservationSchedule({
      reservationCode: reservationCode,
      scheduleDetailCode: scheduleDetailCode,
      formatCall: formatCall,
    });

    setShowModalModify(false);
    setRefreshActive(Math.random());
    toast.success("data already updated");
  };

  return (
    <>
      <Container maxWidth="lg">
        <Appbar
          onSearch={(val) => setFilterSearch(val)}
          title="Manage Session"
        ></Appbar>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Tabs
              value={tabValue}
              onChange={(_, val) => setTablIndex(val)}
              aria-label="basic tabs example"
            >
              <Tab label={`Active ${activeCount}`} {...a11yProps(0)} />
              <Tab label={`Delete ${inactiveCount}`} {...a11yProps(1)} />
            </Tabs>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            margin: "0",
            paddingTop: "20px",
            paddingBottom: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Start Date"
              sx={{
                m: 1,
                width: 200,
              }}
              value={filterStartDate}
              onChange={(val) => setFilterStartDate(val)}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="End Date"
              sx={{
                m: 1,
                width: 200,
              }}
              value={filterEndDate}
              onChange={(val) => setFilterEndDate(val)}
            />
          </LocalizationProvider>
          <FormControl
            sx={{
              m: 1,
              width: 258,
            }}
          >
            <Select
              displayEmpty
              value={filterSpecialist}
              onChange={(val) => setFilterSpecialist(val.target.value)}
              input={<OutlinedInput />}
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                height: "40px",
                fontSize: "14px",
                fontFamily: "Inter-Medium",
              }}
            >
              <MenuItem value={""}>
                <em>All Specialists</em>
              </MenuItem>
              {specialists.map((sugges) => (
                <MenuItem
                  key={sugges.member_code}
                  data-valmem={sugges.member_code}
                  value={sugges.member_code}
                >
                  {sugges.username}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box
            sx={{
              flex: 3,
              textAlign: "right",
            }}
          >
            <Button
              onClick={onAddSession}
              className="btn-action-filter-dark"
              sx={{ width: "200px" }}
              endIcon={<Iconify icon="eva:plus-fill" sx={{}} />}
            >
              Add Session
            </Button>
          </Box>
        </Box>

        <TabPanelHidden value={tabValue} index={0}>
          <Card>
            <TabActived
              keyword={filterSearch}
              refresh={refreshActive}
              specialist={filterSpecialist}
              startDate={filterStartDate}
              endDate={filterEndDate}
              onEdit={(val: any) => onEdit(val)}
              onShare={onShare}
              onCount={(val) => setActiveCount(val)}
              onPrecounseling={onPrecounseling}
              onCounselingNote={onCounselingNote}
              onOvertime={onOvertime}
              onOvertimeLink={onOvertimeLink}
            />
          </Card>
        </TabPanelHidden>
        <TabPanelHidden value={tabValue} index={1}>
          <Card>
            <TabDeleted
              specialist={filterSpecialist}
              keyword={filterSearch}
              startDate={filterStartDate}
              endDate={filterEndDate}
              onCount={(val) => setInactiveCount(val)}
              onPrecounseling={onPrecounseling}
              onCounselingNote={onCounselingNote}
            />
          </Card>
        </TabPanelHidden>

        <ModalDelete
          isOpen={isShowModalDeleteOvertime}
          onClose={() => setShowModalDeleteOvertime(false)}
          onDelete={onDeleteOvertimeAction}
          title="Are you sure want to delete this overtime?"
        ></ModalDelete>

        <ModalModify
          isAddContent={isAddContent}
          isShow={isShowModalModify}
          hide={() => setShowModalModify(false)}
          specialists={specialists}
          value={currentItem}
          onSubmit={onSubmit}
        />

        <ModalOvertime
          hide={() => setShowModalOvertime(false)}
          isShow={isShowModalOvertime}
          onSubmit={onSubmitOvertime}
          onDelete={onDeleteOvertime}
          value={currentItem}
        ></ModalOvertime>

        <ModalOvertimeLink
          value={currentItem}
          isShow={isShowModalOvertimeLink}
          onHide={() => setShowModalOvertimeLink(false)}
          onSubmit={onShareConfirm}
        ></ModalOvertimeLink>

        <ModalPrecounseling
          isShow={isShowModalPrecounseling}
          onHide={() => setShowModalPrecounseling(false)}
          reservationCode={currentItem.reservation_code}
        ></ModalPrecounseling>

        <ModalNote
          isShow={isShowModalNote}
          onHide={() => setShowModalNote(false)}
          reservationCode={currentItem.reservation_code}
        ></ModalNote>

        <ModalLink
          value={currentItem}
          isShow={isShowModalLink}
          onHide={() => setShowModalLink(false)}
          onSubmit={onShareConfirm}
        ></ModalLink>
      </Container>
    </>
  );
}
