import React from 'react';
import ReactDOM from 'react-dom';
import { ModalCLoseIcon } from 'screens/Login/style';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

const modalCloseIcon = <FontAwesomeIcon icon={ faClose } />;

const PaperComponent = (props: PaperProps) => {
	return (
		<Draggable
			handle='#draggable-dialog-title'
			cancel={ '[class*="MuiDialogContent-root"]' }
		>
			<Paper { ...props } />
		</Draggable>
	);
};

export function ModalDefault(props: {
	isShown: boolean;
	title: string;
	modalContent: JSX.Element;
	modalFooter: JSX.Element;
	width?: string;
	hide: () => void;
	reset?: () => void;
}) {
	const clearOut = () => {
		props.hide();
		if (props.reset) {
			props.reset();
		}
	};

	const ModalDefault = (
		<Dialog
			PaperProps={ { sx: { width: props.width === undefined ? '512px' : props.width } } }
			open={ props.isShown }
			onClose={ (_, reason) => {
				if (reason !== 'backdropClick') {
					props.hide();
				}
			} }
			PaperComponent={ PaperComponent }
			aria-labelledby='draggable-dialog-title'
		>
			<DialogTitle style={ {
				cursor: 'move',
				textAlign: 'center'
			} } id='draggable-dialog-title'>
				{ props.title }
				<ModalCLoseIcon title='Close' onClick={ clearOut }>{ modalCloseIcon }</ModalCLoseIcon>
			</DialogTitle>
			<DialogContent>
				{ props.modalContent }
			</DialogContent>
			<DialogActions sx={ { padding: '20px 24px !important' } }>
				{ props.modalFooter }
			</DialogActions>
		</Dialog>
	);

	return props.isShown ? ReactDOM.createPortal(ModalDefault, document.body) : null;
}
