import { Orgs } from 'interfaces';

import { OrgsActionTypes } from './actionTypes';

const initialState: Orgs.State = {
	list: [],
	pagination: [],
	error: '',
	loadingGet: false,
	loadingAdd: false,
	loadingDelete: false,
	loadingUpdate: false
};

const setOrgs = (state: Orgs.State, payload:Orgs.OrgsPayload) => ({
	...state,
	list: payload.listData as Orgs.Detail[],
	pagination: payload.pagination as Orgs.Pagination[],
	loadingGet: false
});

const addOrgs = (state: Orgs.State, payload:Orgs.OrgsPayload) => ({
	...state,
	list: state.list.concat(payload.data as Orgs.Detail),
	loadingAdd: false
});

const reqOrgs = (state: Orgs.State, type: OrgsActionTypes) => ({
	...state,
	loadingGet: type === OrgsActionTypes.GET_ORGS_LIST_LOADING,
	loadingUpdate: false
});

const rejectOrgs = (state: Orgs.State) => ({
	...state,
	loadingGet: false,
	loadingAdd: false,
	loadingDelete: false,
	loadingUpdate: false,
});

const orgsReducer = (state:Orgs.State = initialState, action: {type: OrgsActionTypes; payload: Orgs.OrgsPayload;}) => {
	switch (action.type) {
		case OrgsActionTypes.GET_ORGS_LIST_SUCCESS:
			return setOrgs(state, action.payload);

		case OrgsActionTypes.GET_ORGS_LIST_LOADING:
			return reqOrgs(state, action.type);

		case OrgsActionTypes.GET_ORGS_LIST_ERROR:
			return rejectOrgs(state);
		default:
			return state;
	}
};

export default orgsReducer;
