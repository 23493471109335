export function clearToken() {
	return localStorage.removeItem("token");
}
export function clearName() {
	return localStorage.removeItem("name_user");
}
export function getToken() {
	return localStorage.getItem("token");
}
export function getName() {
	return localStorage.getItem("name_user");
}

export function getLastRefreshDate() {
	return localStorage.getItem("last_refresh");
}

export function setLastRefreshDate(val: string) {
	return localStorage.setItem("last_refresh", val);
}

export function setTokenUser(token: string) {
	return localStorage.setItem("token", token);
}

export function setNameUser(name: string) {
	return localStorage.setItem("name_user", name);
}

export function setUserStorage(user: any) {
	return localStorage.setItem("token", user.token);
}

export function clearStorage() {
	return localStorage.removeItem("token");
}
