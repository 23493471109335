import React, { useEffect, useState } from 'react';
import { ModalSimple } from 'components/Modal/ModalSimple';
import { Select, MenuItem, Typography, Box, IconButton, DialogActions, DialogContent, TextField } from '@mui/material';
import { TextAreaModal } from 'components/Modal/style';
import { Colors, Config, Fonts, Images } from 'constant';
import { LabelModal } from 'screens/Login/style';
import EditIcon from '@mui/icons-material/Edit';
import { useDropzone } from 'react-dropzone';
import { Button as ButtonStyle } from 'screens/style';

export default function ModalModify(props: {
    isDefaultlanguage: boolean;
    isShow: boolean;
    isAddContent: boolean;
    value: any;
    listCategory: any[];
    onSubmit: (val: any) => void;
    onHide: () => void;
}) {
    const [files, setFiles] = useState<any[]>([]);
    const [name, setName] = useState('');
    const [language, setLanguage] = useState('en');
    const [imageUrl, setImageUrl] = useState('');
    const [category, setCategory] = useState('');
    const [description, setDescription] = useState('');

    const [isEnableButton, setEnabledButton] = useState(false);
    const [isProcess, setProcess] = useState(false);
    const [numberProgress, setNumberProgress] = useState(0);

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': []
        },
        onDrop: acceptedFiles => {
            setProcess(true);
            for (let index = 0; index <= 100; index++) {
                setTimeout(function timer() {
                    setNumberProgress(index);
                    if (index === 100) {
                        setFiles(
                            acceptedFiles.map(file => Object.assign(file, {
                                preview: URL.createObjectURL(file)
                            }))
                        );
                        setProcess(false);
                        setNumberProgress(0);
                    }
                }, index * 20);
            }
        }
    });

    useEffect(() => {
        if (props.isShow) {
            const value = props.value;

            setLanguage(value.language ?? 'en');
            setName(value.name ?? '');
            setImageUrl(value.image_url ?? '');
            setCategory(value.category_code ?? '');
            setDescription(value.description ?? '');
        }
    }, [props.isShow]);

    useEffect(() => {
        onCheckValidity();
    }, [language, name, files]);

    const onCheckValidity = () => {
        const isValid =
            language.length > 0 &&
            name.length > 4 &&
            (files.length > 0 || imageUrl.length > 0 || !props.isDefaultlanguage);

        setEnabledButton(isValid);
    };

    const onReset = () => {
        setLanguage('en');
        setName('');
        setImageUrl('');
        setCategory('');
        setDescription('');
        setFiles([]);
    };

    const onSubmit = () => {
        setEnabledButton(false);
        props.onSubmit({
            language,
            name,
            description,
            category_code: category,
            imageUrl,
            files,
        });
    };

    return (
        <ModalSimple
            isShown={ props.isShow }
            title={ props.isAddContent ? "Add New Topic" : "Edit Topic" }
            hide={ props.onHide }
            reset={ onReset }
        >
            <>
                <DialogContent>
                    <Box>
                        <Box sx={ { overFlowY: 'initial !important' } }>
                            <div className='modalBodyC'>
                                {
                                    !props.isDefaultlanguage ? (
                                        <>
                                            <LabelModal style={ {
                                                marginTop: '20px',
                                                display: 'inline-block'
                                            } }>Language</LabelModal>
                                            <div className='input-wrapper'>
                                                <Select
                                                    disabled={ !props.isAddContent }
                                                    required
                                                    displayEmpty
                                                    value={ language }
                                                    inputProps={ { 'aria-label': 'Without label' } }
                                                    onChange={ e => setLanguage(e.target.value as string) }
                                                    sx={ {
                                                        marginTop: '8px',
                                                        width: '100%',
                                                        border: 'solid 1px Colors.app.GhostGrey',
                                                        background: Colors.white.default,
                                                        borderRadius: '5px',
                                                        color: Colors.app.black,
                                                        fontWeight: Fonts.weights.w5,
                                                        fontFamily: 'Fonts.interMedium',
                                                        fontSize: Fonts.sizes.xs
                                                    } }
                                                >
                                                    <MenuItem value=''>
                                                        <em>Choose Language</em>
                                                    </MenuItem>
                                                    <MenuItem value='en'>
                                                        <em>English</em>
                                                    </MenuItem>
                                                    <MenuItem value='id'>
                                                        <em>Indonesia</em>
                                                    </MenuItem>
                                                </Select>
                                            </div>
                                        </>
                                    ) : null
                                }

                                {
                                    props.isDefaultlanguage ? (
                                        <>
                                            <LabelModal style={ {
                                                marginTop: '20px',
                                                display: 'inline-block'
                                            } }>Topic</LabelModal>
                                            <div className='input-wrapper'>
                                                <Select
                                                    disabled={ !props.isAddContent }
                                                    required
                                                    displayEmpty
                                                    value={ category }
                                                    inputProps={ { 'aria-label': 'Without label' } }
                                                    onChange={ e => setCategory(e.target.value as string) }
                                                    sx={ {
                                                        marginTop: '8px',
                                                        width: '100%',
                                                        border: 'solid 1px Colors.app.GhostGrey',
                                                        background: Colors.white.default,
                                                        borderRadius: '5px',
                                                        color: Colors.app.black,
                                                        fontWeight: Fonts.weights.w5,
                                                        fontFamily: 'Fonts.interMedium',
                                                        fontSize: Fonts.sizes.xs
                                                    } }
                                                >
                                                    <MenuItem value='' disabled>
                                                        <em>Topic</em>
                                                    </MenuItem>
                                                    {
                                                        props.listCategory.map((val, i) => {
                                                            return (
                                                                <MenuItem value={ val.category_code } key={ i }>
                                                                    <em>{ val.name }</em>
                                                                </MenuItem>
                                                            );
                                                        })
                                                    }
                                                </Select>
                                            </div>
                                        </>
                                    ) : null
                                }

                                <LabelModal style={ {
                                    marginTop: '20px',
                                    display: 'inline-block'
                                } }>Subtopic</LabelModal>
                                <div className='input-wrapper'>
                                    <TextField
                                        required
                                        fullWidth
                                        value={ name }
                                        placeholder="Title"
                                        onChange={ e => setName(e.target.value) }
                                        sx={ {
                                            marginTop: '8px',
                                            width: '100%',
                                            border: 'solid 1px Colors.app.GhostGrey',
                                            background: Colors.white.default,
                                            borderRadius: '5px',
                                            color: Colors.app.black,
                                            fontWeight: Fonts.weights.w5,
                                            fontFamily: 'Fonts.interMedium',
                                            fontSize: Fonts.sizes.xs
                                        } }
                                    ></TextField>
                                </div>

                                <LabelModal style={ {
                                    marginTop: '20px',
                                    display: 'inline-block'
                                } }>Description</LabelModal>
                                <div className='input-wrapper'>
                                    <TextAreaModal
                                        placeholder='Description'
                                        value={ description }
                                        onChange={ e => setDescription(e.target.value) }
                                    />
                                </div>

                                {
                                    props.isDefaultlanguage ? (
                                        <>
                                            <LabelModal style={ { display: 'inline-block' } }>Upload Image</LabelModal>
                                            <div>
                                                {
                                                    files.length > 0 ?
                                                        <aside style={ {
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            flexWrap: 'wrap',
                                                            marginTop: 16
                                                        } }>
                                                            <Thumbs
                                                                files={ files }
                                                                setFiles={ setFiles }
                                                                setShowPreview={ () => console.log("TEST") }
                                                                getRootProps={ getRootProps }
                                                                getInputProps={ getInputProps }
                                                            ></Thumbs>
                                                        </aside>
                                                        :
                                                        imageUrl ?
                                                            <aside style={ {
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                flexWrap: 'wrap',
                                                                marginTop: 16
                                                            } }>
                                                                <ThumbsEdit
                                                                    getInputProps={ getInputProps }
                                                                    getRootProps={ getRootProps }
                                                                    imageUrl={ imageUrl }
                                                                ></ThumbsEdit>
                                                            </aside>
                                                            :
                                                            <section style={ {
                                                                width: '100%',
                                                                height: '231px'
                                                            } }>
                                                                <div { ...getRootProps({ className: 'dropzone dropzone-cus' }) }>
                                                                    <input { ...getInputProps() } />
                                                                    { isProcess ?
                                                                        <>
                                                                            <div style={ { display: 'flex' } }>
                                                                                <Typography sx={ {
                                                                                    color: '#292A2E',
                                                                                    fontSize: '12px'
                                                                                } }>
                                                                                    Uploading File { numberProgress }%...
                                                                                </Typography>
                                                                            </div>
                                                                            <div style={ {
                                                                                display: 'flex',
                                                                                width: '100px',
                                                                                backgroundColor: 'gray',
                                                                                borderRadius: '10px'
                                                                            } }>
                                                                                <div style={ {
                                                                                    backgroundColor: 'black',
                                                                                    height: '10px',
                                                                                    borderRadius: '10px',
                                                                                    width: numberProgress
                                                                                } } >&nbsp;&nbsp;</div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <div style={ { display: 'flex' } }>
                                                                                <img src={ Images.addImagePost } style={ {
                                                                                    width: '32px',
                                                                                    marginBottom: '10px'
                                                                                } } />
                                                                            </div>
                                                                            <div style={ { display: 'flex' } }>
                                                                                <p>Upload an image or drag and drop</p>
                                                                            </div>
                                                                        </>

                                                                    }

                                                                </div>
                                                            </section>
                                                }
                                            </div>
                                        </>
                                    ) : null
                                }
                            </div>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions sx={ { padding: '20px 24px !important' } }>
                    <Box sx={ {
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%'
                    } }>
                        <ButtonStyle disabled={ !isEnableButton } onClick={ onSubmit } className='btn-primary' title='Add New Admin' style={ { marginTop: '30px' } } >
                            { props.isAddContent ? "Add Topic" : "Edit Topic" }
                        </ButtonStyle>
                    </Box>
                </DialogActions>
            </>
        </ModalSimple>
    );
}

function ThumbsEdit(props: {
    getRootProps: any;
    getInputProps: any;
    imageUrl: string;
}) {
    const imageUrl = Config.urlaws + props.imageUrl;

    return (
        <div style={ {
            display: 'inline-flex',
            borderRadius: 2,
            border: '1px solid #eaeaea',
            marginBottom: 8,
            marginRight: 8,
            width: '100%',
            height: 'auto',
            padding: 4,
            boxSizing: 'border-box'
        } } >
            <div style={ {
                display: 'flex',
                minWidth: 0,
                overflow: 'hidden'
            } }>
                <div { ...props.getRootProps({ className: 'dropzone' }) }>
                    <input { ...props.getInputProps() } />
                    <div className='postReview'>
                        <div id='thumbsEdit' style={ {
                            background: `url(${imageUrl}) #CCCCCC`,
                            backgroundSize: 'cover !important',
                            width: '397px',
                            height: '240px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        } } >
                            <IconButton
                                size='small'
                                aria-label='Edit'
                                color='inherit'
                                title='Edit Picture'
                                sx={ {
                                    background: '#ffffff',
                                    position: 'absolute',
                                    top: '10px',
                                    right: '10px'
                                } }
                            >
                                <EditIcon fontSize='small' />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function Thumbs(props: {
    files: any[];
    getRootProps: any;
    getInputProps: any;
    setShowPreview: (val: boolean) => void;
    setFiles: (files: any[]) => void;
}) {
    return (
        <>
            {
                props.files.map(file => (
                    <div style={ {
                        display: 'inline-flex',
                        borderRadius: 2,
                        border: '1px solid #eaeaea',
                        marginBottom: 8,
                        marginRight: 8,
                        width: '100%',
                        height: 'auto',
                        padding: 4,
                        boxSizing: 'border-box'
                    } } key={ file.name }>
                        <div style={ {
                            display: 'flex',
                            minWidth: 0,
                            overflow: 'hidden'
                        } }>
                            <div { ...props.getRootProps({ className: 'dropzone' }) }>
                                <input { ...props.getInputProps() } />
                                <div className='postReview'>
                                    <div style={ {
                                        background: `url(${file.preview}) #D3D3D3`,
                                        backgroundSize: 'cover',
                                        width: '397px',
                                        height: '240px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    } } >
                                        <IconButton
                                            size='small'
                                            aria-label='Edit'
                                            color='inherit'
                                            title='Edit Picture'
                                            sx={ {
                                                background: '#ffffff',
                                                position: 'absolute',
                                                top: '10px',
                                                right: '10px'
                                            } }
                                        >
                                            <EditIcon fontSize='small' />
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </>
    );
}
