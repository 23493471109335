import React from 'react';
import PropTypes from 'prop-types';
// @mui
import {
	Box, Checkbox, TableRow, TableCell, TableHead, TableSortLabel
} from '@mui/material';

// ----------------------------------------------------------------------

const visuallyHidden = {
	border: 0,
	margin: -1,
	padding: 0,
	width: '1px',
	height: '1px',
	overflow: 'hidden',
	position: 'absolute',
	whiteSpace: 'nowrap',
	clip: 'rect(0 0 0 0)',
};

// ----------------------------------------------------------------------

TableHeadCustom.propTypes = {
	onSort: PropTypes.func,
	orderBy: PropTypes.string,
	headLabel: PropTypes.array,
	rowCount: PropTypes.number,
	numSelected: PropTypes.number,
	onRequestSort: PropTypes.func,
	onSelectAllRows: PropTypes.func,
	order: PropTypes.oneOf(['asc', 'desc']),
	sx: PropTypes.object,
	checkSelected: PropTypes.array,
};
export default function TableHeadCustom({
	order,
	orderBy,
	rowCount = 0,
	headLabel,
	onRequestSort,
	checkSelected,
	numSelected = 0,
	onSelectAllRows,
	onSort,
	sx,
}) {
	const createSortHandler = property => event => {
		onRequestSort(event, property);
	};
	return (
		<TableHead sx={ sx }>
			<TableRow>
				{
					headLabel.map((headCell, index) => {
						return (
							<TableCell
								key={ index }
								align={ headCell.align || 'left' }
								sortDirection={ orderBy === headCell.id ? order : false }
								sx={ {
									width: headCell.width,
									minWidth: headCell.minWidth, color: '#767E8C', padding: '12px 16px !important'
								} }
							>
								{ headCell.type === 'checkbox' ?
									<Checkbox
										indeterminate={ !!(checkSelected.length > 0 && checkSelected.length < rowCount) }
										checked={ checkSelected.length === rowCount ? true : false }
										onChange={ onSelectAllRows }
										color='primary'
										inputProps={ { 'aria-label': 'select all desserts' } }
									/> : <TableSortLabel
										hideSortIcon
										active={ orderBy === headCell.id }
										direction={ orderBy === headCell.id ? order : 'asc' }
										onClick={ createSortHandler(headCell.id) }
									>
										{ headCell.label }
										{ orderBy === headCell.id ? (
											<Box sx={ { ...visuallyHidden } }>{ order === 'desc' ? 'sorted descending' : 'sorted ascending' }</Box>
										) : null }
									</TableSortLabel> }

							</TableCell>
						);
					})
				}
			</TableRow>
		</TableHead>
	);
}
