import styled from 'styled-components';
import { Fonts, Colors } from 'constant';
export interface Props {
    bg: string;
}
const Container = styled.div`
    padding: 50px 90px 50px 120px;
    display: flex;
    flex: 1;
    background-image: url('${(p: Props) => p.bg}');
    background-size:45%;
    background-repeat:no-repeat;
    overflow-x:hidden;
    margin:0;
    justify-content:center;
    min-height:100vh;
`;

const LeftColumn = styled.div`
    display:flex;
    flex-direction: column;
    justify-content:center;
    width: 45%;
    padding-right:100px;
`;

const SingleColumn = styled.div`
    display:flex;
    flex-direction: column;
    justify-content:center;
    max-width: 600px;
    align-items:start;
`;

const RightColumn = styled.div`
    display:flex;
    flex-direction: column;
    justify-content:center;
    width: 55%;
    padding-top:100px;
    align-items:end;
`;

const Button = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 49px;
    background: ${Colors.app.black};
    border-radius: 5px;
    font-family:'${Fonts.interRegular}';
    font-style: normal;
    font-weight: ${Fonts.weights.w6};
    font-size: ${Fonts.sizes.s};
    line-height: 100%;
    color: ${Colors.white.default};
    width:100%;
    cursor:pointer;
`;

export {
	Container, LeftColumn, RightColumn, Button, SingleColumn
};
