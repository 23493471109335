export default {
	gallup: [
		"I know what is expected of me at work.",
		"I have the materials and equipment I need to do my work right.",
		"At work, I have the opportunity to do what I do best every day.",
		"In the last 7 days, I’ve received recognition or praise for doing good work.",
		"My supervisor, or someone at work, seems to care about me as a person.",
		"There is someone at work who encourages my development.",
		"At work, my opinions seem to count.",
		"The mission or purpose of my company makes me feel my job is important.",
		"My coworkers are committed to doing quality work.",
		"I have a best friend at work.",
		"In the last six months, someone at work has talked to me about my progress.",
		"This last year, I have had opportunities at work to learn and grow.",
	],
	flourise: [
		"I lead a purposeful and meaningful life",
		"My social relationships are supportive and rewarding",
		"I am engaged and interested in my daily activities",
		"I actively contribute to the happiness and well-being of others",
		"I am competent and capable in the activities that are important to me",
		"I am a good person and live a good life",
		"I am optimistic about my future",
		"People respect me",
	],
	dass: [
		"I found it hard to wind down.",
		"I was aware of dryness of my mouth.",
		"I couldn’t seem to experience any positive feeling at all.",
		"I experienced breathing difficulty (e.g. excessively rapid breathing, breathlessness in the absence of physical exertion).",
		"I found it difficult to work up the initiative to do things.",
		"I tended to over-react to situations.",
		"I experienced trembling (e.g. in the hands).",
		"I felt that I was using a lot of nervous energy.",
		"I was worried about situations in which I might panic and make a fool of myself.",
		"I felt that I had nothing to look forward to.",
		"I found myself getting agitated.",
		"I found it difficult to relax.",
		"I felt down-hearted and blue.",
		"I was intolerant of anything that kept me from getting on with what I was doing.",
		"I felt I was close to panic.",
		"I was unable to become enthusiastic about anything.",
		"I felt I wasn’t worth much as a person.",
		"I felt that I was rather touchy.",
		"I was aware of the action of my heart in the absence of physical exertion (e.g. sense of heart rate increase, heart missing a beat).",
		"I felt scared without any good reason.",
		"I felt that life was meaningless.",
	],
};
