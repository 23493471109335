import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Select from "@mui/material/Select";
import { ModalDelete, ModalRepost } from "components/Modal/ModalSimple";
import { a11yProps, SearchStyle, TabPanel } from "components/Panel";
import Iconify from "screens/Layout/components/Iconify";
import {
  addExercise,
  addExerciseNarration,
  addExerciseTranslation,
  deleteExercise,
  getExerciseNarration,
  getExerciseTranslation,
  getListExercise,
  repostExercise,
  updateExercise,
  updateExerciseNarration,
} from "service/exercise";
import { getMembers } from "service/member";
import ModalModify from "./components/ModalModify";
import TabActived from "./components/TabActivated";
import TabDeleted from "./components/TabDeleted";
import { addUploadImage } from "store/uploadimages";
import ModalNarrationModify from "./components/ModalNarrationModify";
import { uploadAudio } from "service/upload";
import ModalPreview from "./components/ModalPreview";
import Appbar from "components/Appbar";

export default function Exercise() {
  // Options Table
  const [filterSearch, setFilterSearch] = useState("");
  const [filterReviewed, setFilterReviewed] = useState("");

  // Data Active
  const [paginationActive, setTablePagination] = useState<any[]>([]);
  const [dataActive, setTableData] = useState<any[]>([]);
  const [pageActive, setPageActive] = useState<number>(1);
  const [rowCountActive, setRowCountActive] = useState<number>(10);

  // Data Inactive
  const [dataInactive, setDataInactive] = useState<any[]>([]);
  const [paginationInactive, setPaginationInactive] = useState<any[]>([]);
  const [pageInactive, setPageInactive] = useState<number>(1);
  const [rowCountInactive, setRowCountInactive] = useState<number>(10);

  // Header
  const [listMember, setListMember] = useState<any[]>([]);

  // Tab Index
  const [tabValue, setTablIndex] = useState(0);

  // Label Tab
  const [labelActivedTab, setLabelActivedTab] = useState("Active");
  const [labelDeletedTab, setLabelDeletedTab] = useState("Deleted");

  // Modal
  const [isShowRepost, setShowRepost] = useState(false);
  const [isAddContent, setAddContent] = useState(false);
  const [currentItem, setCurrentItem] = useState<any>({});
  const [isDefaultLanguage, setDefaultLanguage] = useState(true);
  const [isShowDelete, setShowDelete] = useState(false);

  const [isShowModalModify, setShowModalModify] = useState(false);
  const [isShowModalNarration, setShowModalNarration] = useState(false);
  const [isShowModalPreview, setShowModalPreview] = useState(false);

  // Use Effect
  useEffect(() => {
    if (
      !(
        isShowModalModify ||
        isShowDelete ||
        isShowModalNarration ||
        isShowModalPreview
      )
    ) {
      setCurrentItem({});
    }
  }, [
    isShowModalModify,
    isShowDelete,
    isShowModalNarration,
    isShowModalPreview,
  ]);

  useEffect(() => {
    if (!(isShowModalModify || isShowRepost)) {
      setDefaultLanguage(true);
      setAddContent(true);
      setCurrentItem({});
    }
  }, [isShowModalModify, isShowRepost]);

  useEffect(() => {
    onChangeData();
  }, [filterSearch, filterReviewed]);

  useEffect(() => {
    onChangeDataActive();
  }, [pageActive, rowCountActive]);

  useEffect(() => {
    onChangeDataInactive();
  }, [pageInactive, rowCountInactive]);

  useEffect(() => {
    getMembers({
      limit: 1000,
      page: 1,
      role: "psycholog",
      type: "individual",
    }).then((val) => {
      setListMember(val.data);
    });

    onChangeData();
  }, []);

  const onRepost = (rowItem) => {
    setCurrentItem(rowItem);
    setShowRepost(true);
  };

  const toggleShowModal = () => {
    setShowModalModify(!isShowModalModify);
  };

  const toggleShowDelete = () => {
    setShowDelete(!isShowDelete);
  };

  const onChangeDataActive = () => {
    getListExercise({
      reviewed_code: filterReviewed,
      keyword: filterSearch,
      status: "active",
      limit: rowCountActive,
      page: pageActive,
    }).then((resp) => {
      const pagination = resp.pagination;
      const data = resp.data ?? [];

      setPageActive(pagination.page);
      setRowCountActive(pagination.limit);

      setLabelActivedTab(`Actived (${pagination.count})`);
      setTableData(data);
      setTablePagination([pagination]);
    });
  };

  const onChangeDataInactive = () => {
    getListExercise({
      reviewed_code: filterReviewed,
      keyword: filterSearch,
      status: "inactive",
      limit: rowCountInactive,
      page: pageInactive,
    }).then((resp) => {
      const pagination = resp.pagination;
      const data = resp.data ?? [];

      setPageInactive(pagination.page);
      setRowCountInactive(pagination.limit);

      setLabelDeletedTab(`Deleted (${pagination.count})`);
      setDataInactive(data);
      setPaginationInactive([pagination]);
    });
  };

  const onChangeData = async () => {
    onChangeDataActive();
    onChangeDataInactive();
  };

  const onChangeRowsCountActive = (val) => {
    setRowCountActive(val);
  };

  const onChangePageActive = (val) => {
    setPageActive(val + 1);
  };

  const onChangeRowsCountInactive = (val) => {
    setRowCountInactive(val);
  };

  const onChangePageInactive = (val) => {
    setPageInactive(val + 1);
  };

  const onRepostAction = async () => {
    await repostExercise({
      exercise_code: currentItem.exercise_code,
    });

    onChangeData();
    setShowRepost(false);
  };

  const onPreview = (val) => {
    setCurrentItem(val);

    setShowModalPreview(true);
  };

  const onNarration = async (narration: any) => {
    setCurrentItem(narration);
    setAddContent(false);
    setShowModalNarration(true);
  };

  const onAddNarration = (row: any) => {
    setCurrentItem(row);

    setShowModalNarration(true);
    setAddContent(true);
  };

  const onTranslation = async (row: any, lang: string) => {
    const resp = await getExerciseTranslation({
      exercise_code: row.exercise_code,
      language: lang,
    });
    const data = resp.data;

    setCurrentItem({
      ...row,
      title: data.title,
      subtitle: data.subtitle,
      description: data.description,
      translation: lang,
    });
    setDefaultLanguage(false);
    setAddContent(false);
    setShowModalModify(true);
  };

  const onAddTranslation = (row: any, lang: string) => {
    setCurrentItem({
      ...row,
      translation: lang,
    });

    setDefaultLanguage(false);
    setShowModalModify(true);
    setAddContent(true);
  };

  const onAddInsight = () => {
    setAddContent(true);
    toggleShowModal();
  };

  const onEdit = (rowItem) => {
    setAddContent(false);
    setCurrentItem(rowItem);

    toggleShowModal();
  };

  const refreshUpdateSubmit = () => {
    toggleShowModal();
    onChangeData();
  };

  const onSubmit = async (data: any) => {
    let filePath = data.imageUrl;
    if (data.files.length > 0 && isDefaultLanguage) {
      const file = data.files[0].origin;
      filePath = await addUploadImage({ body: file });
    }

    const body = {
      image_url: filePath,
      description: data.description,
      title: data.title,
      subtitle: data.subtitle,
      reviewer_code: data.reviewer,
    };

    if (isAddContent) {
      if (isDefaultLanguage) {
        await addExercise(body);
      } else {
        await addExerciseTranslation({
          ...body,
          language: data.language,
          exercise_code: currentItem.exercise_code,
        });
      }

      return refreshUpdateSubmit();
    }

    if (isDefaultLanguage) {
      await updateExercise({
        exercise_code: currentItem.exercise_code,
        image_url: filePath,
        description: data.description,
        title: data.title,
        subtitle: data.subtitle,
        reviewer_code: data.reviewer,
      });
    } else {
      await addExerciseTranslation({
        ...body,
        language: data.language,
        exercise_code: currentItem.exercise_code,
      });
    }

    refreshUpdateSubmit();
  };

  const onSubmitNarration = async (data: any) => {
    let audioUrl = data.audioUrl;
    const files = data.files;
    if (files.length > 0) {
      const resp = await uploadAudio(files[0]);

      audioUrl = resp.data.file_path;
    }
    const dataUpload = {
      audio_length: Number(data.audioLength),
      audio_url: audioUrl,
      audio_title: data.audioTitle,
      language: data.language,
      exercise_code: currentItem.exercise_code,
      exercise_narration_code: currentItem.exercise_narration_code,
    };
    if (isAddContent) {
      await addExerciseNarration(dataUpload);
    } else {
      await updateExerciseNarration(dataUpload);
    }

    onChangeData();
    setShowModalNarration(false);
  };

  const onDelete = (item) => {
    setCurrentItem(item);

    toggleShowDelete();
  };

  const onConfirmDelete = async () => {
    await deleteExercise({
      exercise_code: currentItem.exercise_code,
    });

    toggleShowDelete();
    onChangeData();
  };

  return (
    <>
      <Container maxWidth="lg">
        <Appbar
          onSearch={(val) => setFilterSearch(val)}
          title="Mindfulness Exercise"
        ></Appbar>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Tabs
              value={tabValue}
              onChange={(_, val) => setTablIndex(val)}
              aria-label="basic tabs example"
            >
              <Tab label={labelActivedTab} {...a11yProps(0)} />
              <Tab label={labelDeletedTab} {...a11yProps(1)} />
            </Tabs>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            margin: "0",
            paddingTop: "20px",
            paddingBottom: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <FormControl
            sx={{
              m: 1,
              width: 258,
            }}
          >
            <Select
              displayEmpty
              value={filterReviewed}
              onChange={(val) => setFilterReviewed(val.target.value)}
              input={<OutlinedInput />}
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                height: "40px",
                fontSize: "14px",
                fontFamily: "Inter-Medium",
              }}
            >
              <MenuItem value={""}>
                <em>Reviewed By</em>
              </MenuItem>
              {listMember.map((sugges) => (
                <MenuItem
                  key={sugges.member_code}
                  data-valmem={sugges.member_code}
                  value={sugges.member_code}
                >
                  {sugges.username}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box
            sx={{
              flex: 3,
              textAlign: "right",
            }}
          >
            <Button
              onClick={onAddInsight}
              className="btn-action-filter-dark"
              sx={{ width: "200px" }}
              endIcon={<Iconify icon="eva:plus-fill" sx={{}} />}
            >
              Add New Exercise
            </Button>
          </Box>
        </Box>

        <TabPanel value={tabValue} index={0}>
          <Card>
            <TabActived
              data={dataActive}
              pagination={paginationActive}
              onDelete={onDelete}
              onEdit={(params) => onEdit(params)}
              onChangeRows={onChangeRowsCountActive}
              onChangePage={onChangePageActive}
              onTranslation={onTranslation}
              onAddTranslation={onAddTranslation}
              onNarration={onNarration}
              onAddNarration={onAddNarration}
              onPreview={onPreview}
            />
          </Card>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Card>
            <TabDeleted
              data={dataInactive}
              pagination={paginationInactive}
              onChangeRows={onChangeRowsCountInactive}
              onChangePage={onChangePageInactive}
              onRepost={onRepost}
            />
          </Card>
        </TabPanel>

        <ModalPreview
          onHide={() => setShowModalPreview(false)}
          isShow={isShowModalPreview}
          value={currentItem}
        ></ModalPreview>

        <ModalModify
          isDefaultlanguage={isDefaultLanguage}
          isAddContent={isAddContent}
          isShow={isShowModalModify}
          hide={toggleShowModal}
          listReviewer={listMember}
          value={currentItem}
          onSubmit={onSubmit}
        />

        <ModalNarrationModify
          hide={() => setShowModalNarration(false)}
          isAddContent={isAddContent}
          isShow={isShowModalNarration}
          onSubmit={onSubmitNarration}
          value={currentItem}
        ></ModalNarrationModify>

        <ModalDelete
          isOpen={isShowDelete}
          onClose={toggleShowDelete}
          onDelete={onConfirmDelete}
        />

        <ModalRepost
          title="Are you sure, repost this exercise?"
          isOpen={isShowRepost}
          onClose={() => setShowRepost(false)}
          onChange={onRepostAction}
        />
      </Container>
    </>
  );
}
