import { Common, Settings } from 'interfaces';
import { apiService } from 'helpers';

import { SettingsActionTypes } from './actionTypes';
import { Endpoints } from 'constant';
import { dispatch } from 'store';

export const postProgress = (type: SettingsActionTypes, payload?:Settings.SettingsPayload) => {
	dispatch({
		type,
		payload
	});
};

export const getSettings = (params?:Common.RequestData) => {
	return new Promise<any[]>(async(resolve, reject) => {
		postProgress(SettingsActionTypes.GET_SETTINGS_LIST_LOADING);

		const response:Common.ApiResponse<Settings.Detail[]> = await apiService(`${Endpoints.settings}?page=${params?.body.page}&limit=${params?.body.limit}&is_active=${params?.body.is_active}&set_group=${params?.body.set_group}&sort=${params?.body.sort}`, { method: 'GET' }, params?.query);

		const { object, data, error, pagination } = response;
		if (data) {
			const dd = object.data;
			const result : any[] = [];
			const pagiVal : any[] = [];
			
			pagiVal.push(
				{
					count: pagination.count,
					page: pagination.page,
					limit: pagination.limit,
				});
			
			dd.map((item, i) => {
				
				result.push(
					{
						setting_code: item.setting_code,
						set_group: item.set_group,
						set_key: item.set_key,
						set_label: item.set_label,
						set_order: item.set_order,
						content_type: item.content_type,
						content_value: item.content_value,
						is_active: item.is_active,
					}
				);
			});
			
			postProgress(SettingsActionTypes.GET_SETTINGS_LIST_SUCCESS, { listData: result, pagination: pagiVal });
			resolve(result);
		} else {
			// you can put toast here
			reject(postProgress(SettingsActionTypes.GET_SETTINGS_LIST_ERROR, { error: error }));
		}
	});
};
