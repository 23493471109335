import React from "react";
import { DialogContent, DialogActions } from "@mui/material";
import { ModalSimple } from "components/Modal/ModalSimple";
import { useEffect, useState } from "react";
import { LabelModal } from "screens/Login/style";
import { Button as ButtonStyle } from "screens/style";

export default function ContentLicenseModal(props: {
  isShow: boolean;
  onHide: () => void;
  onSubmit: (val: any) => void;
}) {
  const [licenseName, setLicenseName] = useState("");
  const [licenseNumber, setLicenseNumber] = useState("");
  const [isEnabled, setEnabled] = useState(false);

  const checkFormValid = () => {
    if (licenseName) {
      setEnabled(true);
    } else {
      setEnabled(false);
    }
  };

  const onReset = () => {
    setLicenseName("");
    setLicenseNumber("");
  };

  useEffect(() => {
    checkFormValid();
  }, [licenseName, licenseNumber]);

  useEffect(() => {
    if (!props.isShow) {
      onReset();
    }
  }, [props.isShow]);

  const onSubmit = () => {
    props.onSubmit({
      license_name: licenseName,
      license_number: licenseNumber,
    });
  };

  return (
    <ModalSimple
      isShown={props.isShow}
      title="License"
      hide={props.onHide}
      reset={onReset}
    >
      <>
        <DialogContent>
          <div className="input-wrapper">
            <div className="schedule-cus" style={{ width: "100%" }}>
              <div style={{ width: "100%" }}>
                <LabelModal
                  style={{
                    margin: "5px 0 10px 0",
                    display: "inline-block",
                  }}
                >
                  License
                </LabelModal>
                <div className="input-wrapper form-inline">
                  <input
                    type="text"
                    placeholder="License Name"
                    onChange={(val) => setLicenseName(val.target.value)}
                    value={licenseName}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="input-wrapper">
            <div className="schedule-cus" style={{ width: "100%" }}>
              <div style={{ width: "100%" }}>
                <LabelModal
                  style={{
                    margin: "5px 0 10px 0",
                    display: "inline-block",
                  }}
                >
                  License Number
                </LabelModal>
                <div className="input-wrapper form-inline">
                  <input
                    type="text"
                    placeholder="License Number"
                    onChange={(val) => setLicenseNumber(val.target.value)}
                    value={licenseNumber}
                  />
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions sx={{ padding: "20px 24px !important" }}>
          <ButtonStyle
            className="btn-primary"
            disabled={!isEnabled}
            title="Add "
            onClick={onSubmit}
            style={{ marginTop: "30px" }}
          >
            Add
          </ButtonStyle>
        </DialogActions>
      </>
    </ModalSimple>
  );
}
