import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, TextField, DialogContent, DialogActions, Dialog } from '@mui/material';
import { ModalSimple } from 'components/Modal/ModalSimple';
import { Images, Colors, Fonts } from 'constant';
import { IChangeCallback, IVoidCallback } from 'interfaces/callback';
import { LabelModal } from 'screens/Login/style';
import { Button as ButtonStyle } from 'screens/style';


export function ContentModal(props: { isAddSales: boolean, isShow: boolean, onHide: IVoidCallback, onSubmit: IChangeCallback<any, void>; value: any; }) {
    // Form
    const [fullname, setFullname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [location, setLocation] = useState('');

    const [isEnabled, setIsEnabled] = useState(false);

    const checkFormValid = () => {
        if (
            fullname.length > 0 &&
            email.length > 0 &&
            phone.length > 0 &&
            location.length > 0
        ) {
            setIsEnabled(true);
        } else {
            setIsEnabled(false);
        }
    };

    useEffect(() => {
        checkFormValid();
    }, [fullname, email, phone, location]);

    useEffect(() => {
        console.log(props.isAddSales);
        if (!props.isShow) {
            setFullname('');
            setEmail('');
            setPhone('');
            setLocation('');

            setIsEnabled(false);
        }

        if (props.isShow && props.value) {
            const value = props.value;

            setFullname(value.name ?? '');
            setEmail(value.email ?? '');
            setPhone(value.phone ?? '');
            setLocation(value.location ?? '');
        }
    }, [props.isShow]);

    const onSubmit = async () => {
        props.onSubmit({
            name: fullname,
            email,
            phone,
            location,
        });
    };

    return (
        <ModalSimple
            isShown={ props.isShow }
            title={ `${props.isAddSales ? 'Add New' : 'Update'} Sales Rep` }
            hide={ props.onHide }
        >
            <>
                <DialogContent>
                    <Box sx={ {
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%'
                    } }>
                        <Box sx={ { overFlowY: 'initial !important' } }>
                            <div className='modalBodyC'>
                                <div className='input-wrapper' style={ { margin: '0 0 0 0' } }>
                                    <div className='schedule-cus' style={ { width: '100%' } }>
                                        <div style={ { width: '100%' } }>
                                            <LabelModal style={ {
                                                margin: '5px 0 10px 0',
                                                display: 'inline-block'
                                            } }>Full Name*</LabelModal>
                                            <div className='input-wrapper form-inline'>
                                                <input type='text'
                                                    placeholder='Full Name'
                                                    onChange={ (val) => setFullname(val.target.value) }
                                                    value={ fullname }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='input-wrapper' style={ { margin: '8px 0 0 0' } }>
                                    <div className='schedule-cus' style={ {
                                        width: '100%',
                                        paddingTop: '0'
                                    } }>
                                        <div style={ { width: '100%' } }>
                                            <LabelModal style={ {
                                                margin: '0 0 10px 0',
                                                display: 'inline-block'
                                            } }>Email Address*</LabelModal>
                                            <div className='input-wrapper form-inline'>
                                                <input type='text'
                                                    placeholder='Email Address'
                                                    onChange={ e => setEmail(e.target.value) }
                                                    value={ email }
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className='input-wrapper'>
                                    <div className='schedule-cus' style={ { width: '100%' } }>
                                        <div className='time-schedule-cus' style={ { width: '50%', marginRight: '16px' } }>
                                            <LabelModal style={ { display: 'inline-block' } }>Phone Number*</LabelModal>
                                            <TextField
                                                sx={ {
                                                    marginTop: '8px',
                                                    width: '100%',
                                                    border: 'solid 1px Colors.app.GhostGrey',
                                                    background: Colors.white.default,
                                                    borderRadius: '5px',
                                                    color: Colors.app.black,
                                                    fontWeight: Fonts.weights.w5,
                                                    fontFamily: 'Fonts.interMedium',
                                                    fontSize: Fonts.sizes.xs
                                                } }
                                                type='text'
                                                // pattern='[0-9]*'
                                                placeholder='Phone Number'
                                                onChange={ e => setPhone(e.target.value) }
                                                value={ phone }
                                            />
                                        </div>
                                        <div className='time-schedule-cus' style={ { width: '50%' } }>
                                            <LabelModal style={ { display: 'inline-block' } }>Location (City, Country)*</LabelModal>
                                            <TextField
                                                sx={ {
                                                    marginTop: '8px',
                                                    width: '100%',
                                                    border: 'solid 1px Colors.app.GhostGrey',
                                                    background: Colors.white.default,
                                                    borderRadius: '5px',
                                                    color: Colors.app.black,
                                                    fontWeight: Fonts.weights.w5,
                                                    fontFamily: 'Fonts.interMedium',
                                                    fontSize: Fonts.sizes.xs
                                                } }
                                                type='text'
                                                // pattern='[0-9]*'
                                                placeholder='Location (City, Country)'
                                                onChange={ e => setLocation(e.target.value) }
                                                value={ location }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions sx={ { padding: '20px 24px !important' } }>
                    <ButtonStyle className='btn-primary' disabled={ !isEnabled } title='Add New Specialist ' onClick={ onSubmit } >
                        {
                            props.isAddSales ? 'Add Sales' : 'Update Sales'
                        }
                    </ButtonStyle>
                </DialogActions>
            </>
        </ModalSimple>
    );
}

export function ContentModalSuccess(props: { isShow: boolean, onToggle: IVoidCallback; }) {
    return (
        <Dialog open={ props.isShow } style={ { minWidth: '250px', marginLeft: 'auto', marginRight: 'auto' } }>
            <Box margin={ 4 }>
                <div style={ {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                } }>
                    <img src={ Images.successModal } style={ { width: '133px' } } />
                    <LabelModal style={ {
                        fontSize: '18px',
                        paddingTop: '30px'
                    } }>Added new data successfully</LabelModal>
                    <Button className='btn-action-filter-dark' sx={ { width: '100%', marginTop: '24px' } } onClick={ props.onToggle } >Done</Button>
                </div>
            </Box>
        </Dialog>
    );
}
