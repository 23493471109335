import { Box, DialogActions, DialogContent, OutlinedInput } from '@mui/material';
import { ModalSimple } from 'components/Modal/ModalSimple';
import React, { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { LabelModal } from 'screens/Login/style';
import { Button as ButtonStyle } from 'screens/style';

export default function ModalModify(props: {
    isAddContent: boolean;
    onSubmit: (val: any) => void;
    hide: () => void;
    isShow: boolean;
    value: any;
}) {
    const { register, formState: { errors }, setValue, reset, handleSubmit } = useForm();

    useEffect(() => {
        if (props.isShow && !props.isAddContent) {
            const value = props.value;

            setValue('subscription_plan', value.subscription_plan);
            setValue('monthly_credit', value.monthly_credit);
            setValue('subscription_price', value.subscription_price);
        }
    }, [props.isShow]);

    const onReset = () => {
        reset();
    };

    const onSubmit = (val: any) => {
        props.onSubmit({
            subscription_plan: val.subscription_plan,
            monthly_credit: val.monthly_credit,
            subscription_price: val.subscription_price,
        });
    };

    return (
        <ModalSimple hide={ props.hide } isShown={ props.isShow } title={ `${props.isAddContent ? 'Add New Subscription' : 'Edit Subscription'}` } reset={ onReset }>
            <form onSubmit={ handleSubmit(onSubmit) }>
                <DialogContent>
                    <Box sx={ { overFlowY: 'initial !important' } }>
                        <div>
                            <LabelModal style={ {
                                margin: '20px 0 10px 0',
                                display: 'inline-block'
                            } }>Subscription Plan</LabelModal>
                            <div>
                                <OutlinedInput type='text'
                                    placeholder='Subscription Plan'
                                    fullWidth
                                    error={ errors.subscription_plan !== undefined }
                                    { ...register('subscription_plan', { required: 'subscription plan required!', minLength: 4 }) }
                                />
                            </div>

                            <LabelModal style={ {
                                margin: '10px 0 10px 0',
                                display: 'inline-block'
                            } }>Subscription Price</LabelModal>
                            <div>
                                <OutlinedInput type='number'
                                    placeholder='Subscription Price'
                                    fullWidth
                                    error={ errors.subscription_price !== undefined }
                                    { ...register('subscription_price', { required: 'subscription price required!' }) }
                                />
                            </div>

                            <LabelModal style={ {
                                margin: '10px 0 10px 0',
                                display: 'inline-block'
                            } }>Monthly Credits</LabelModal>
                            <div>
                                <OutlinedInput type='number'
                                    placeholder='Monthly Credits'
                                    fullWidth
                                    error={ errors.monthly_credit !== undefined }
                                    { ...register('monthly_credit', { required: 'monthly credits required!' }) }
                                />
                            </div>
                        </div>
                    </Box>
                </DialogContent>
                <DialogActions sx={ { padding: '20px 24px !important' } }>
                    <Box sx={ {
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%'
                    } }>
                        <ButtonStyle type='submit' className='btn-primary' title={ `${props.isAddContent ? 'Add Subscription' : 'Edit Subscription'}` } style={ { marginTop: '30px' } } >
                            { props.isAddContent ? "Add" : "Edit" }
                        </ButtonStyle>
                    </Box>
                </DialogActions>
            </form>
        </ModalSimple>
    );
}
