import { Common, Flags } from 'interfaces';
import { apiService } from 'helpers';

import { FlagsActionTypes } from './actionTypes';
import { Endpoints } from 'constant';
import { dispatch } from 'store';

export const flagProgress = (type: FlagsActionTypes, payload?:Flags.FlagsPayload) => {
	dispatch({
		type,
		payload
	});
};

export const getFlagsCount = (params?:Flags.RequestData) => {
	return new Promise<any[]>(async(resolve, reject) => {
		const response:Common.ApiResponse<Flags.Detail[]> = await apiService(`${Endpoints.manageflag}count`, { method: 'GET' }, params?.query);

		const { object, data, error } = response;
		if (data) {
			const dd = object.data;
			const result : any[] = [];
			
			result.push(
				{
					outstanding_count: `${dd.outstanding_count}`,
					confirmed_count: `${dd.confirmed_count}`,
				}
			);
			resolve(result);
		} else {
			// you can put toast here
			reject(error);
		}
	});
};

export const getFlags = (params?:Flags.RequestData) => {
	return new Promise<any[]>(async(resolve, reject) => {
		flagProgress(FlagsActionTypes.GET_FLAGS_LIST_LOADING);

		const response:Common.ApiResponse<Flags.Detail[]> = await apiService(`${Endpoints.manageflag}?page=${params?.body.page}&limit=${params?.body.limit}&comment_by=${params?.body.comment_by}&context=${params?.body.context}&flagged_by=${params?.body.flagged_by}&is_confirm=${params?.body.is_confirm}&keyword=${params?.body.keyword ? params?.body.keyword : ''}`, { method: 'GET' }, params?.query);

		const { object, data, error, pagination } = response;
		if (data) {
			const dd = object.data;
			const result : any[] = [];
			const pagiVal : any[] = [];
			const flaggedBy : any[] = [];
			const reasonsMulti : any[] = [];
			
			pagiVal.push(
				{
					count: pagination.count,
					page: pagination.page,
					limit: pagination.limit,
				});
			
			dd.map((item, i) => {
				if (item?.flagged_by !== null) {
					item?.flagged_by.map((pi, i) => {
						flaggedBy.push(
							`${pi}`
						);
					});
				}
				if (item?.reason !== null) {
					item?.reason.map((r, i) => {
						reasonsMulti.push(
							`${r}`
						);
					});
				}
				result.push(
					{
						content: `${item.content}`,
						member_name: `${item.member_name}`,
						flagged_by: flaggedBy.join(', '),
						context: `${item.context}`,
						type: `${item.type}`,
						transaction_code: `${item.transaction_code}`,
						reason: reasonsMulti.join(', '),
						is_confirm: `${item.is_confirm}`,
						created_date: `${item.created_date}`,
					}
				);
				
				flaggedBy.splice(0, flaggedBy.length);
				reasonsMulti.splice(0, reasonsMulti.length);
			});
			
			flagProgress(FlagsActionTypes.GET_FLAGS_LIST_SUCCESS, { listData: result,
				pagination: pagiVal });
			resolve(pagiVal);
		} else {
			// you can put toast here
			reject(flagProgress(FlagsActionTypes.GET_FLAGS_LIST_ERROR, { error: error }));
		}
	});
};

const formatDate = date => {
	return new Date(date).getDate() + ' ' + new Date(date).toLocaleString('default', { month: 'short' }) + ' ' + new Date(date).getFullYear() + ' ' + ('0' + new Date(date).getHours()).slice(-2) + ':' + ('0' + new Date(date).getMinutes()).slice(-2);
};

export const getPreviewFlags = (params?:Flags.RequestData) => {
	return new Promise<any[]>(async(resolve, reject) => {
		flagProgress(FlagsActionTypes.GET_FLAGS_PREVIEW_LOADING);

		const response:Common.ApiResponse<Flags.Detail[]> = await apiService(`${Endpoints.manageflag}preview/${params?.body.tc}`, { method: 'GET' }, params?.query);

		const { object, data, error, pagination } = response;
		if (data) {
			const dd = [object.data];
			const result : any[] = [];
			const pagiVal : any[] = [];
			const flaggedReasons : any[] = [];
			const postPoll : any[] = [];
			const threadArr : any[] = [];
			
			pagiVal.push({
				count: pagination.count,
				page: pagination.page,
				limit: pagination.limit,
			});
			
			dd.map((item, i) => {
				const _fr = item?.flagged_reason;
				const _pp = [item?.postpoll];
				
				_fr.map((fr, i) => {
					
					flaggedReasons.push({
						created_date: formatDate(fr.created_date),
						flagged_by: fr.flagged_by,
						reason: fr.reason,
					});
					
				});
				_pp.map((pp, i) => {
					postPoll.push({
						admin_code: pp.admin_code,
						admin_name: pp.admin_name,
						content: pp.content,
						created_date: pp.created_date,
						member_code: pp.member_code,
						member_name: pp.member_name,
						poll_list: pp.poll_list,
						post_code: pp.post_code,
						post_type: pp.post_type,
						published_date: pp.published_date,
						status: pp.status,
						updated_date: pp.updated_date,
					});
				});
				[item?.thread].map((pp, i) => {
					threadArr.push({
						comment_code_highlight: pp.comment_code_highlight,
						comment_content_highlight: pp.comment_content_highlight,
						comment_name_highlight: pp.comment_name_highlight,
						comment_created_date_highlight: pp.comment_created_date_highlight,
						comment_profile_url_highlight: pp.comment_profile_url_highlight,
						created_date: pp.created_date,
						reply_code: pp.reply_code,
						reply_content: pp.reply_content,
						reply_name: pp.reply_name,
						reply_created_date: pp.reply_created_date,
						reply_profile_url: pp.reply_profile_url,
						reply_to_code: pp.reply_to_code,
						reply_to_content: pp.reply_to_content,
						reply_to_profile_url: pp.reply_to_profile_url,
						reply_to_created_date: pp.reply_to_created_date,
						updated_date: pp.updated_date,
					});
				});
				
				const _frGrouping = flaggedReasons.reduce((catMemo, { reason, flagged_by, created_date }) => {
					(catMemo[reason] = catMemo[reason] || []).push(flagged_by + ' (' + created_date +')');
					return catMemo;
				  }, {});
				  
				
				result.push(
					{
						comment_code: item.comment_code,
						comment_content: item.comment_content,
						comment_created_date: item.comment_created_date,
						comment_from: item.comment_from,
						flagged_reason: _frGrouping,
						postpoll: postPoll,
						thread: threadArr,
					}
				);
				
				// flaggedReasons.splice(0, flaggedReasons.length);
				// postPoll.splice(0, postPoll.length);
				// threadArr.splice(0, threadArr.length);
				
			});
			resolve(result);
		} else {
			// you can put toast here
			reject(flagProgress(FlagsActionTypes.GET_FLAGS_PREVIEW_ERROR, { error: error }));
		}
	});
};

export const editFlags =  (params?:Flags.RequestData) => {
	return new Promise<void>(async(resolve, reject) => {
		flagProgress(FlagsActionTypes.UPDATE_FLAGS_LOADING);
		const response:Common.ApiResponse<Flags.Detail> = await apiService(Endpoints.manageflag + params?.cm, {
			method: 'PUT',
			body: params?.body
		 });
		const { data, error, object } = response;
		if (data) {
			resolve(flagProgress(FlagsActionTypes.UPDATE_FLAGS_SUCCESS, { data: data }));
		} else {
			// you can put toast here
			flagProgress(FlagsActionTypes.UPDATE_FLAGS_ERROR);
			reject(object);
		}
	});
};
