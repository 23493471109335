import React, { useRef } from 'react';
import { ModalDefault } from 'components/Modal';
import { Button } from '@mui/material';
import pdf from 'service/pdf';
import numeral from 'numeral';
import dayjs from 'dayjs';

export default function ModalInvoice(props: {
    onHide: () => void;
    isShow: boolean;
    username: string;
    email: string;
    country: string;
    phone: string;
    amount: any;
    transactionDate: string;
    dateIssued: string;
    bankName: string;
    accountNumber: string;
    accountHolder: string;
    invoiceNumber: string;
    details: any[];
}) {
    const refContent = useRef(null);

    const onDownloadInvoice = async () => {
        const content = refContent.current!;

        await pdf(content, "invoice");
    };

    const ModalContent = () => {
        return (
            <div className='text-left px-6 pb-16 text-sm' ref={ refContent }>
                <div className='flex justify-between mt-8'>
                    <div className='text-4xl font-bold'>INVOICE</div>
                    <div className='flex flex-col gap-2 text-right font-semibold text-neutral-500'>
                        <div className='text-black mb-2 uppercase'>{ props.username }</div>
                        <div>{ props.email }</div>
                        <div>{ props.country }</div>
                        <div>{ props.phone }</div>
                    </div>
                </div>
                <div className='flex justify-between mt-12'>
                    <div>
                        <div>
                            <div className='text-blue-700/90 font-semibold mb-1'>Invoice Number</div>
                            <div className='capitalize'>{ props.invoiceNumber }</div>
                        </div>
                        <div>
                            <div className='text-blue-700/90 font-semibold mb-1 mt-4'>Pay To</div>
                            <div>
                                <div>{ props.bankName }</div>
                                <div>{ props.accountNumber }</div>
                                <div>{ props.accountHolder }</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div className='text-blue-700/90 font-semibold mb-1'>Billed To</div>
                            <div className='capitalize'>{ props.username }</div>
                        </div>
                        <div>
                            <div className='text-blue-700/90 font-semibold mb-1 mt-4'>Date Issued</div>
                            <div>{ props.dateIssued }</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div className='text-blue-700/90 font-semibold mb-1'>Amount Due</div>
                            <div>Rp { numeral(props.amount).format('0,0') }</div>
                        </div>
                        <div>
                            <div className='text-blue-700/90 font-semibold mb-1 mt-4'>Due Date</div>
                            <div>{ props.transactionDate }</div>
                        </div>
                    </div>
                </div>
                <div className='w-full h-1 bg-blue-700/70 rounded mt-6'></div>
                <table style={ { minWidth: '500px', marginTop: '24px', borderCollapse: 'collapse' } }>
                    <tr>
                        <th className='text-blue-700/90 font-medium uppercase mb-2 w-44 pb-2'>Reservation Date</th>
                        <th className='text-blue-700/90 font-medium uppercase mb-2 w-32'>Time</th>
                        <th className='text-blue-700/90 font-medium uppercase mb-2 w-14'>Qty</th>
                        <th className='text-blue-700/90 font-medium uppercase mb-2'>Fee</th>
                    </tr>
                    {
                        props.details.map((val, i) => {
                            return (
                                <tr className='!border-b !border-t-0 !border-l-0 !border-r-0 !border-t-transparent !border-l-transparent !border-r-transparent !border-b-slate-200 !border-solid' key={ i }>
                                    <td className='py-3 leading-6'>{ dayjs(val.date).format('DD MMMM YYYY') }</td>
                                    <td>{ val.time_from.slice(0, 5) } - { val.time_to.slice(0, 5) }</td>
                                    <td>1</td>
                                    <td>Rp { numeral(val.specialist_fee).format('0,0') }</td>
                                </tr>
                            );
                        })
                    }
                </table>

                <div className='pl-36'>
                    <div className='w-full h-1 bg-blue-700/70 rounded mt-4'></div>
                </div>
                <div className='mt-2 flex'>
                    <div className='flex flex-col gap-2 text-left ml-36'>
                        <div className='flex justify-between'>
                            <div className='w-24 mr-20 pl-10 font-semibold'>Total</div>
                            <div className='font-semibold'>Rp { numeral(getTotal(props.details)).format('0,0') }</div>
                        </div>
                    </div>
                </div>
                <div className='mt-6'>
                    <div className='text-blue-700/90 font-semibold mb-2'>Notes</div>
                    <div className='opacity-70'>Thank you for business!</div>
                </div>
                <div className='my-6'>
                    <div className='text-blue-700/90 font-semibold mb-2'>Terms</div>
                    <div className='opacity-70'>Please pay within 30 days using the link in your invoice email</div>
                </div>
            </div >
        );
    };

    return (
        <ModalDefault
            width='640px'
            hide={ props.onHide }
            isShown={ props.isShow }
            title=''
            modalContent={ <ModalContent></ModalContent> }
            modalFooter={
                <Button
                    fullWidth
                    className='btn-action-filter-dark-w-250'
                    onClick={ onDownloadInvoice }
                >
                    Download Invoice
                </Button>
            }
        ></ModalDefault>
    );
}

function getTotal(details: any[]) {
    let amount = 0;
    for (const item of details) {
        amount += Number(item.specialist_fee);
    }

    return amount;
}
