import { Common, Orgs } from "interfaces";
import { apiService } from "helpers";

import { OrgsActionTypes } from "./actionTypes";
import { Endpoints } from "constant";
import { dispatch } from "store";

export const orgsProgress = (
	type: OrgsActionTypes,
	payload?: Orgs.OrgsPayload
) => {
	dispatch({
		type,
		payload,
	});
};

export const getOrgs = (params?: Orgs.RequestData) => {
	return new Promise<any[]>(async (resolve, reject) => {
		orgsProgress(OrgsActionTypes.GET_ORGS_LIST_LOADING);

		const response: Common.ApiResponse<Orgs.Detail[]> = await apiService(
			`${Endpoints.org}?page=${params?.body.page}&limit=${params?.body.limit}
			&is_active=${params?.body.is_active ? params?.body.is_active : ""}
			&industry=${params?.body.industry ? params?.body.industry : ""}
			&keyword=${params?.body.keyword ? params?.body.keyword : ""}
			&sales_rep=${params?.body.sales_rep ?? ""}`,
			{ method: "GET" },
			params?.query
		);

		const { object, data, error, pagination } = response;
		if (data) {
			const dd = object.data;
			const result: any[] = [];
			const pagiVal: any[] = [];

			pagiVal.push({
				count: pagination.count,
				page: pagination.page,
				limit: pagination.limit,
			});

			dd.map((item, i) => {
				result.push({
					departement_code: `${item.departement_code}`,
					departement_name: `${item.departement_name}`,
					organization_code: `${item.organization_code}`,
					organization_name: `${item.organization_name}`,
					email: `${item.email}`,
					office_address: `${item.office_address}`,
					pic: `${item.pic}`,
					phone: `${item.phone}`,
					is_active: `${item.is_active}`,
					industry_name: `${item.industry_name}`,
					created_date: `${item.created_date}`,
					updated_date: `${item.updated_date}`,
					total_code: `${item.total_code}`,
					total_inactive: `${item.total_inactive}`,
					total_member: `${item.total_member}`,
					total_active: `${item.total_active}`,
					credit_reservation: `${item.credit_reservation}`,
					sales_name: item.sales_name,
					sales_code: item.sales_code,
				});
			});

			orgsProgress(OrgsActionTypes.GET_ORGS_LIST_SUCCESS, {
				listData: result,
				pagination: pagiVal,
			});
			resolve(pagiVal);
		} else {
			// you can put toast here
			reject(
				orgsProgress(OrgsActionTypes.GET_ORGS_LIST_ERROR, { error: error })
			);
		}
	});
};

function getUnique(arr, index) {
	const unique = arr
		.map((e) => e[index])

		// store the keys of the unique objects
		.map((e, i, final) => final.indexOf(e) === i && i)

		// eliminate the dead keys & store unique objects
		.filter((e) => arr[e])
		.map((e) => arr[e]);

	return unique;
}

export const getOrgsSeparate = (params?: Orgs.RequestData) => {
	return new Promise<any[]>(async (resolve, reject) => {
		orgsProgress(OrgsActionTypes.GET_ORGS_LIST_LOADING);

		const response: Common.ApiResponse<Orgs.Detail[]> = await apiService(
			`${Endpoints.org}?page=${params?.body.page}&limit=${
				params?.body.limit
			}&is_active=${
				params?.body.is_active ? params?.body.is_active : ""
			}&industry=${
				params?.body.industry ? params?.body.industry : ""
			}&keyword=${params?.body.keyword ? params?.body.keyword : ""}`,
			{ method: "GET" },
			params?.query
		);

		const { object, data, error, pagination } = response;
		if (data) {
			const dd = object.data;
			const result: any[] = [];
			const pagiVal: any[] = [];

			pagiVal.push({
				count: pagination.count,
				page: pagination.page,
				limit: pagination.limit,
			});

			dd.map((item, i) => {
				result.push({
					departement_code: `${item.departement_code}`,
					departement_name: `${item.departement_name}`,
					organization_code: `${item.organization_code}`,
					organization_name: `${item.organization_name}`,
					email: `${item.email}`,
					office_address: `${item.office_address}`,
					pic: `${item.pic}`,
					phone: `${item.phone}`,
					is_active: `${item.is_active}`,
					industry_name: `${item.industry_name}`,
					sales_name: `${item.sales_name}`,
					created_date: `${item.created_date}`,
					updated_date: `${item.updated_date}`,
					total_code: `${item.total_code}`,
					total_inactive: `${item.total_inactive}`,
				});
			});
			resolve(getUnique(result, "organization_code"));
		} else {
			// you can put toast here
			reject(
				orgsProgress(OrgsActionTypes.GET_ORGS_LIST_ERROR, { error: error })
			);
		}
	});
};

export const addOrg = (params?: Orgs.RequestData) => {
	return new Promise<void>(async (resolve, reject) => {
		orgsProgress(OrgsActionTypes.ADD_ORGS_LOADING);

		const response: Common.ApiResponse<Orgs.Detail> = await apiService(
			Endpoints.org,
			{
				method: "POST",
				body: params?.body,
			}
		);
		const { data, error, object } = response;
		if (data) {
			// orgsProgress(OrgsActionTypes.ADD_ORGS_SUCCESS, { data: data });

			const dd = object.data;
			resolve(dd.organization_code);
		} else {
			// you can put toast here
			orgsProgress(OrgsActionTypes.ADD_ORGS_ERROR);
			reject(object);
		}
	});
};

export const addDprt = (params?: Orgs.RequestData) => {
	return new Promise<any[]>(async (resolve, reject) => {
		const response: Common.ApiResponse<Orgs.Detail> = await apiService(
			Endpoints.org + params?.body.ocd + "/departements",
			{
				method: "POST",
				body: params?.body,
			}
		);
		const { data, error, object } = response;
		if (data) {
			const result: any[] = [];
			const dd = object.data;
			[dd].map((item, i) => {
				result.push({
					departement_code: `${item.departement_code}`,
				});
			});
			resolve(result);
		} else {
			// you can put toast here
			reject(object);
		}
	});
};

export const editOrg = (params?: Orgs.RequestData) => {
	return new Promise<void>(async (resolve, reject) => {
		const response: Common.ApiResponse<Orgs.Detail> = await apiService(
			Endpoints.org + params?.body.organization_code,
			{
				method: "PUT",
				body: params?.body,
			}
		);
		const { data, error, object } = response;
		if (data) {
			resolve(object.data.organization_code);
		} else {
			// you can put toast here
			reject(object);
		}
	});
};

export const deleteOrg = (params?: Common.RequestData) => {
	return new Promise<void>(async (resolve, reject) => {
		const response: Common.ApiResponse<Orgs.Detail> = await apiService(
			Endpoints.org + params?.body.organization_code,
			{
				method: "DELETE",
			}
		);
		const { data, error, object } = response;
		if (data) {
			resolve(object.data);
		} else {
			// you can put toast here

			reject(object);
		}
	});
};

export const editDprt = (params?: Orgs.RequestData) => {
	return new Promise<void>(async (resolve, reject) => {
		const response: Common.ApiResponse<Orgs.Detail> = await apiService(
			Endpoints.org +
				params?.body.ocd +
				"/departements/" +
				params?.body.departement_code,
			{
				method: "PUT",
				body: params?.body,
			}
		);
		const { data, error, object } = response;
		if (data) {
			resolve(object.data.organization_code);
		} else {
			// you can put toast here

			reject(object);
		}
	});
};
