import { Box, Button, DialogActions, DialogContent, LinearProgress, MenuItem, Select, TextField, Typography } from '@mui/material';
import { ModalSimple } from 'components/Modal/ModalSimple';
import { LabelModal } from 'components/Modal/style';
import { Colors, Endpoints, Fonts, Images } from 'constant';
import { useAudio } from 'hooks/audio';
import { Pause, Play } from 'iconsax-react';
import { IChangeCallback } from 'interfaces/callback';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button as ButtonStyle } from 'screens/style';

export default function ModalNarrationModify(props: {
    isAddContent: boolean;
    isShow: boolean;
    value: any;
    onSubmit: IChangeCallback<any, void>;
    hide: () => void;
}) {
    const [audioTitle, setAudioTitle] = useState('');
    const [audioLength, setAudioLength] = useState('');
    const [files, setFiles] = useState<any[]>([]);
    const [audioUrl, setAudioUrl] = useState('');
    const [language, setLanguage] = useState('en');
    const [isEnableButton, setEnableButton] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const audioRef = useRef<{ pause: any; }>();

    const onReset = () => {
        setAudioUrl("");
        setAudioLength("");
        setAudioTitle("");
        setLanguage("id");
        setFiles([]);
    };

    const hide = () => {
        audioRef.current?.pause();

        props.hide();
    };

    const validateForm = () => {
        if (audioLength && audioLength.length > 0 &&
            (files.length > 0 || audioUrl.length > 0) &&
            language.length > 0
        ) {
            setEnableButton(true);
        } else {
            setEnableButton(false);
        }
    };

    // Check if button is enable
    useEffect(() => {
        validateForm();
    }, [language, audioLength, files, audioUrl]);

    useEffect(() => {
        if (props.isShow) {
            setLoading(false);

            if (!props.isAddContent) {
                setAudioTitle(props.value.audio_title ?? "");
                setLanguage(props.value.language ?? "en");
                setAudioLength(props.value.audio_length.toString() ?? "");
                setAudioUrl(props.value.audio_url ?? "");
                setFiles([]);
            }
        }
    }, [props.isShow]);

    // Dropzone
    const [startUF, setSUF] = useState(false);
    const [numberProgress, setNumberProgress] = useState(0);
    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'audio/mp3': ['.mp3']
        },
        multiple: false,
        onDrop: acceptedFiles => {
            setSUF(true);
            for (let index = 0; index <= 100; index++) {
                setTimeout(function timer() {
                    setNumberProgress(index);
                    if (index === 100) {
                        setFiles(acceptedFiles);
                        setSUF(false);
                        setNumberProgress(0);
                    }
                }, index * 10);
            }
        }
    });

    const onSubmit = () => {
        audioRef.current?.pause();

        setEnableButton(false);
        setLoading(true);

        props.onSubmit({
            files,
            language,
            audioLength,
            audioUrl,
            audioTitle,
        });
    };

    return (
        <ModalSimple isShown={ props.isShow } title={ props.isAddContent ? "Add New Audio" : "Edit Audio" } hide={ hide } reset={ onReset }>
            <>
                <DialogContent>
                    <Box sx={ { overFlowY: 'initial !important' } }>

                        <LabelModal style={ {
                            marginTop: '20px',
                            display: 'inline-block'
                        } }>Audo Title</LabelModal>
                        <div className='input-wrapper'>
                            <TextField
                                required
                                fullWidth
                                value={ audioTitle }
                                placeholder="Audio Title"
                                onChange={ e => setAudioTitle(e.target.value) }
                                sx={ {
                                    marginTop: '8px',
                                    width: '100%',
                                    border: 'solid 1px Colors.app.GhostGrey',
                                    background: Colors.white.default,
                                    borderRadius: '5px',
                                    color: Colors.app.black,
                                    fontWeight: Fonts.weights.w5,
                                    fontFamily: 'Fonts.interMedium',
                                    fontSize: Fonts.sizes.xs
                                } }
                            ></TextField>
                        </div>

                        <div className='modalBodyC'>
                            <LabelModal style={ {
                                marginTop: '20px',
                                display: 'inline-block'
                            } }>Language</LabelModal>
                            <div className='input-wrapper'>
                                <Select
                                    required
                                    displayEmpty
                                    value={ language }
                                    inputProps={ { 'aria-label': 'Without label' } }
                                    onChange={ val => setLanguage(val.target.value ?? '') }
                                    sx={ {
                                        marginTop: '8px',
                                        width: '100%',
                                        border: 'solid 1px Colors.app.GhostGrey',
                                        background: Colors.white.default,
                                        borderRadius: '5px',
                                        color: Colors.app.black,
                                        fontWeight: Fonts.weights.w5,
                                        fontFamily: 'Fonts.interMedium',
                                        fontSize: Fonts.sizes.xs
                                    } }
                                >
                                    <MenuItem value=''>
                                        <em>Choose Language</em>
                                    </MenuItem>
                                    <MenuItem value='en'>
                                        <em>English</em>
                                    </MenuItem>
                                    <MenuItem value='id'>
                                        <em>Indonesia</em>
                                    </MenuItem>
                                </Select>
                            </div>

                            {/* <LabelModal style={ {
                                marginTop: '20px',
                                display: 'inline-block'
                            } }>Audo Length</LabelModal>
                            <div className='input-wrapper'>
                                <TextField
                                    required
                                    fullWidth
                                    disabled
                                    value={ audioLength }
                                    placeholder="Audio Length in Seconds"
                                    onChange={ e => setAudioLength(e.target.value) }
                                    type='number'
                                    sx={ {
                                        marginTop: '8px',
                                        width: '100%',
                                        border: 'solid 1px Colors.app.GhostGrey',
                                        background: Colors.white.default,
                                        borderRadius: '5px',
                                        color: Colors.app.black,
                                        fontWeight: Fonts.weights.w5,
                                        fontFamily: 'Fonts.interMedium',
                                        fontSize: Fonts.sizes.xs
                                    } }
                                ></TextField>
                            </div> */}

                            <LabelModal style={ {
                                marginTop: '20px',
                                display: 'inline-block'
                            } }>Upload Audio</LabelModal>
                            <div>
                                {
                                    !startUF && (files.length > 0 || audioUrl.length > 0) ?
                                        <aside style={ {
                                            display: 'flex',
                                            flexDirection: 'row',
                                            flexWrap: 'wrap',
                                            marginTop: 16
                                        } }>
                                            <Thumbs
                                                files={ files }
                                                getInputProps={ getInputProps }
                                                getRootProps={ getRootProps }
                                                audioUrl={ audioUrl }
                                                setDuration={ val => setAudioLength(val.toString()) }
                                                isLoading={ isLoading }
                                                ref={ audioRef }
                                            ></Thumbs>
                                        </aside>
                                        :
                                        <section style={ {
                                            width: '100%',
                                            height: '231px'
                                        } } className="cursor-pointer">
                                            <div { ...getRootProps({ className: 'dropzone dropzone-cus' }) }>
                                                <input { ...getInputProps() } />
                                                {
                                                    startUF ?
                                                        <>
                                                            <div style={ { display: 'flex' } }>
                                                                <Typography sx={ {
                                                                    color: '#292A2E',
                                                                    fontSize: '12px'
                                                                } }>
                                                                    Uploading File { numberProgress }%...
                                                                </Typography>
                                                            </div>
                                                            <div style={ {
                                                                display: 'flex',
                                                                width: '100px',
                                                                backgroundColor: 'gray',
                                                                borderRadius: '10px'
                                                            } }>
                                                                <div style={ {
                                                                    backgroundColor: 'black',
                                                                    height: '10px',
                                                                    borderRadius: '10px',
                                                                    width: numberProgress
                                                                } } >&nbsp;&nbsp;</div>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div style={ { display: 'flex' } }>
                                                                <img src={ Images.addImagePost } style={ {
                                                                    width: '32px',
                                                                    marginBottom: '10px'
                                                                } } />
                                                            </div>
                                                            <div style={ { display: 'flex' } }>
                                                                <p>Upload an audio or drag and drop</p>
                                                            </div>
                                                        </>
                                                }
                                            </div>
                                        </section>
                                }

                            </div>
                        </div>
                    </Box >
                </DialogContent>
                <DialogActions sx={ { padding: '20px 24px !important' } }>
                    <Box sx={ { width: '100%' } }>
                        {
                            isLoading ? (
                                <LinearProgress sx={ { width: '100%', marginTop: '8px' } } color="success" />
                            ) : null
                        }
                        <Box sx={ {
                            width: '100%',
                            marginTop: '8px'
                        } }>
                            <ButtonStyle disabled={ !isEnableButton } onClick={ onSubmit } className='btn-primary' title='Add New Admin' >
                                { props.isAddContent ? "Add Audio" : "Edit Audio" }
                            </ButtonStyle>
                        </Box>
                    </Box>
                </DialogActions>
            </>
        </ModalSimple>
    );
}

const Thumbs = forwardRef((props: { files: any[], getRootProps: any, getInputProps: any, audioUrl: string, isLoading: boolean, setDuration: (val: number) => void; }, ref) => {
    let name = '';
    let url = '';

    if (props.audioUrl.length > 0 && props.files.length === 0) {
        const names = props.audioUrl.split('/');

        name = names[names.length - 1];
        url = `${Endpoints.baseUrlAws}${props.audioUrl}`;
    } else {
        const file = props.files[0];

        name = file.name;
        url = URL.createObjectURL(file);
    }

    const [isPlaying, toggle, pause, duration] = useAudio(url);

    useEffect(() => {
        props.setDuration((Math.ceil(duration)));
    }, [duration]);

    useImperativeHandle(ref, () => ({
        pause() {
            if (isPlaying) {
                toggle();
            }

            pause();
        }
    }));

    const onToggle = () => {
        if (!props.isLoading) {
            toggle();
        }
    };

    return (
        <div style={ {
            display: 'flex',
            borderRadius: 2,
            border: '1px solid green',
            marginBottom: 8,
            marginRight: 8,
            width: '100%',
            height: 'auto',
            padding: 4,
            boxSizing: 'border-box',
        } } className='justify-between'>
            <div style={ {
                overflow: 'hidden',
            } } className='cursor-pointer'>
                <div { ...props.getRootProps({ className: 'dropzone' }) }>
                    <input { ...props.getInputProps() } />
                    <div className='postReview'>
                        <div style={ {
                            width: '320px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        } } >
                            <div>{ name }</div>
                        </div>
                    </div>
                </div>
            </div>
            <Button variant="outlined" color={ isPlaying ? 'warning' : 'success' } endIcon={ isPlaying ? <Pause /> : <Play /> } onClick={ onToggle }>
                { isPlaying ? 'Pause Audio' : 'Check Audio' }
            </Button>
        </div>
    );
});

Thumbs.displayName = 'Thumbs';
