import { useEffect, useState } from "react";

export const useAudio = (
	url: any
): [boolean, () => void, () => void, number] => {
	const [audio] = useState(new Audio(url));
	const [playing, setPlaying] = useState(false);
	const [duration, setDuration] = useState(0);

	const toggle = () => setPlaying(!playing);

	useEffect(() => {
		playing ? audio.play() : audio.pause();
	}, [playing]);

	useEffect(() => {
		audio.addEventListener("ended", () => setPlaying(false));
		audio.addEventListener("loadedmetadata", (val) => {
			setDuration(audio.duration);
		});

		return () => {
			audio.removeEventListener("ended", () => setPlaying(false));
			audio.removeEventListener("loadedmetadata", () => setDuration(0));
		};
	}, []);

	return [playing, toggle, () => audio.pause(), duration];
};
