import { Box, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { TableEmptyRows, TableHeadCustom } from 'components/Table';
import useTable from 'components/Table/useTable';
import { IChangeCallback } from 'interfaces/callback';
import React, { useState } from 'react';
import SpecialistRateRow from 'sections/@dashboard/billing/specialist_rate';

const TABLE_HEAD = [
	{
		id: 'price',
		label: 'HOURLY PRICE',
		alignRight: false
	}, {
		id: 'fee',
		label: 'HOURLY FEE',
		alignRight: false
	},
	{ id: '' }
];

export default function ActivedTab(props: {
	data: any[], pagination: any[];
	onChangePage?: IChangeCallback<any, void>;
	onChangeRows?: IChangeCallback<any, void>;
	onDelete?: IChangeCallback<any, void>;
	onEdit?: IChangeCallback<any, void>;
}) {
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('title');
	const handleRequestSort = (_, property) => {
		const isAsc = orderBy === property && order === 'asc';

		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const { dense, selected, onSelectRow, onSort } = useTable();
	const denseHeight = dense ? 52 : 72;

	return (
		<div>
			<TableContainer sx={ {
				minWidth: 800,
				position: 'relative'
			} }>

				<Table size={ dense ? 'small' : 'medium' }>
					<TableHeadCustom
						order={ order }
						orderBy={ orderBy }
						onRequestSort={ handleRequestSort }
						headLabel={ TABLE_HEAD }
						rowCount={ props.data.length }
						numSelected={ selected.length }
						onSort={ onSort }
					/>

					<TableBody>
						{
							props.data.map((row, index) => (
								<SpecialistRateRow
									key={ index }
									row={ row }
									selected={ selected.includes(row.id) }
									onSelected={ () => onSelectRow(row.id) }
									onDelete={ () => props!.onDelete!(row) }
									onEdit={ () => props!.onEdit!(row) }
								/>
							))
						}

						<TableEmptyRows height={ denseHeight } emptyRows={ props.data.length } />

					</TableBody>
				</Table>
			</TableContainer>

			<Box sx={ { position: 'relative' } }>
				<TablePagination
					rowsPerPageOptions={ [1, 10, 25, 100] }
					component='div'
					labelRowsPerPage='Items per page: '
					count={ props.pagination[0]?.count || 0 }
					rowsPerPage={ props.pagination[0]?.limit || 10 }
					page={ props.pagination[0]?.page - 1 || 0 }
					onPageChange={ (e, page) => props!.onChangePage!(page) }
					onRowsPerPageChange={ (val) => props!.onChangeRows!(val.target.value) }
				/>
			</Box>
		</div>
	);
}
