import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  FormControl,
  InputAdornment,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { styled, useTheme } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AlertComponent } from "components/Alert";
import { useAlert } from "components/Alert/useAlert";
import { ModalDefault } from "components/Modal";
import { useModal } from "components/Modal/useModal";
import { Colors, Fonts, Images } from "constant";
import Iconify from "screens/Layout/components/Iconify";
import { Button as ButtonStyle } from "screens/style";

import { green } from "@mui/material/colors";
import { LabelModal } from "components/Modal/style";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import { getDprt, getDprtSeparate } from "store/department";
import {
  addMember,
  editMembers,
  getMemberLists,
  getMemberListsCount,
  importMember,
  send,
} from "store/members";
import { addDprt, addOrg, getOrgsSeparate } from "store/org";
import { addUploadImage } from "store/uploadimages";
import CIndTab from "./components/CIndTab";
import { useAppSelector } from "store";

// Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper, { PaperProps } from "@mui/material/Paper";
import Draggable from "react-draggable";
import { useDropzone } from "react-dropzone";

import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ModalCLoseIcon } from "screens/Login/style";
import { getSettings } from "store/settings"; // Industries
import Appbar from "components/Appbar";
import { generateSchedules } from "service/schedule";

const modalCloseIcon = <FontAwesomeIcon icon={faClose} />;

const PaperComponent = (props: PaperProps) => {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
};

// eslint-disable-next-line no-undef
const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 350,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": { width: 400 },
  "& fieldset": {
    borderWidth: "1px !important",
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// Thumbnail
const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
};

const thumb = {
  marginBottom: 8,
  marginRight: 8,
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const membership = [
  {
    value: "specialist",
    label: "Specialists",
  },
  {
    value: "certified",
    label: "Certified",
  },
  {
    value: "licensed",
    label: "Licensed",
  },
];

const rights_org = [
  {
    value: "member_org",
    label: "Member",
  },
  {
    value: "psycholog_org",
    label: "Specialist",
  },
];
const rights_ind = [
  {
    value: "member",
    label: "Member",
  },
  {
    value: "psycholog",
    label: "Specialist",
  },
];

const statuss = [
  {
    value: "active",
    label: "Active",
  },
  {
    value: "pending_verification",
    label: "EMAIL SENT",
  },
  {
    value: "pending_invitation",
    label: "EMAIL UNSENT",
  },
];

// Multiple Select
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 188,
    },
  },
};

export default function Member() {
  const { members, loadingGet, error, pagination } = useAppSelector(
    (state) => state.members
  );
  const [tablePagination, setTablePagination] = useState<any[]>(pagination);
  const [genderLoad, setGenderLoad] = useState<any[]>([]);
  const [membershipLoad, setMembershipLoad] = useState<any[]>([]);
  const [tableData, setTableData] = useState(members);
  const [pagePagination, setPagePagination] = useState(1);
  const [pagex, setPagex] = useState(0);
  const [limitTable, setLimitTable] = useState(10);
  const [valueD, setValueD] = React.useState<Date | null>(null);
  const [alertType, setAlertType] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const { isShownAlert, toggleAlert } = useAlert();
  const [addSuccess, setAddSuccess] = useState(false);
  const [labelUserTab, setLabelUserTab] = useState("");
  const [labelIndTab, setLabelIndTab] = useState("");

  // Filter
  const [filterSeparateOrg, setFilterSeparateOrg] = useState<any[]>([]);
  const [filterDprt, setFilterDprt] = useState<any[]>([]);

  const getAnyPage = (params) => {
    const pagar =
      pagex < params
        ? pagex === 0
          ? params + pagex
          : params + 1
        : params - pagex;
    loadDataPagi(params, tabName);
  };

  const handleSendInvitation = () => {
    const email: any[] = [];
    {
      sb.map((row) => {
        if (row.invitation_email !== "") {
          email.push({
            invitation_email: row.invitation_email,
            invitation_code: row.invitation_code,
          });
        }
      });
    }
    // console.log(invitation.current);
    // const invitation_email =
    send({ body: email }).then(function (e) {
      //
      setAlertType("success");
      setAlertMsg("Send Invitation Code Success!");
      toggleAlert();
      closeSend();
      setB([]);
    });
  };
  const onChangeRowsPerPage = (event: { target: { value: string } }) => {
    changeLoadData(pagex, parseInt(event.target.value));
  };
  const changeLoadData = (params, limitParams) => {
    setLimitTable(limitParams);
    if (params < 0) {
      setPagex(0);
    } else {
      setPagex(params);
    }
    const page = params + 1;
    const limit = limitParams;
    const role_name = roleName;
    const type = tabName;
    const keyword = qeyword;
    const organization_code = "";
    const department_name = "";
    const membership_name = "";
    const status = "";

    const payload = {
      page,
      limit,
      role_name,
      type,
      keyword,
      organization_code,
      department_name,
      membership_name,
      status,
    };

    getMemberLists({ body: payload }).then(function (e) {
      setTablePagination((prevTablePagination) => e);
      getCountTab();
    });
  };
  const handleImportMember = () => {
    try {
      importMember({ body: files[0] }).then(function (e) {
        if (e[0].stat_code === "ERR:BAD_REQUEST") {
          // setAlertType('error');
          // setAlertMsg(e[0].stat_msg);
          // toggleAlert();
          closeImport();
          setOpenDialogImportError(true);

          if (Array.isArray(e[0].stat_msg)) {
            setErrorImportList(e[0].stat_msg);
          } else {
            setErrorImportList([e[0].stat_msg]);
          }
        } else {
          setB(e[0].data);
          showSend();
          console.log(e[0].data);
          closeImport();
          setAlertType("success");
          setAlertMsg("Import Members Successfully!");
          toggleAlert();
          setFiles([]);
          loadDataPagi(pagex, tabName);
          getCountTab();
        }
      });
    } catch (error) {
      throw new Error(error as any);
    }
  };

  const searchData = (q) => {
    const page = pagePagination;
    const limit = 10;
    const role_name = roleName;
    const type = tabName;
    const keyword = q;
    const organization_code = "";
    const department_name = "";
    const membership_name = "";
    const status = "";

    const payload = {
      page,
      limit,
      role_name,
      type,
      keyword,
      organization_code,
      department_name,
      membership_name,
      status,
    };

    getMemberLists({ body: payload }).then(function (e) {
      setTablePagination((prevTablePagination) => e);
    });
  };

  const loadDataFilter = (params) => {
    if (params < 0) {
      setPagex(0);
    } else {
      setPagex(params);
    }
    const page = 1;
    const limit = 10;
    const type = tabName;
    const keyword = qeyword;
    const organization_code = filterUAOrganizationPayload;
    const department_name = filterUADepartmentPayload;
    const role_name = filterUARightsPayload;
    // const membership_name = filterUAMembershipPayload;
    const membership_name = "";
    const status = filterUAStatusPayload;

    const payload = {
      page,
      limit,
      role_name,
      type,
      keyword,
      organization_code,
      department_name,
      membership_name,
      status,
    };

    getMemberLists({ body: payload }).then(function (e) {
      setTablePagination((prevTablePagination) => e);
      setSF(false);
    });
  };

  const loadDataPagi = (params, statusa) => {
    if (params < 0) {
      setPagex(0);
    } else {
      setPagex(params);
    }
    const page = params + 1;
    const limit = limitTable;
    const role_name = roleName;
    const type = statusa;
    const keyword = qeyword;
    const organization_code = "";
    const department_name = "";
    const membership_name = "";
    const status = "";
    const payload = {
      page,
      limit,
      role_name,
      type,
      keyword,
      organization_code,
      department_name,
      membership_name,
      status,
    };
    getMemberLists({ body: payload }).then(function (e) {
      setTablePagination((prevTablePagination) => e);
      getCountTab();
    });
  };
  // Modal
  const { isShown, toggle } = useModal();
  const [selectedModalOrg, setSelectedModalOrg] = useState<string[]>([]);
  const [enabledButton, setEnabledButton] = useState(true);
  const [enabledButtonEdit, setEnabledButtonEdit] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);

  const [modalOrgData, setModalOrgData] = useState<any[]>([]);
  const [modalIndByOrg, setModalIndByOrg] = useState<any[]>([]);
  const [modalDprtData, setModalDprtData] = useState<any[]>([]);
  const [selectedModalOrgCodeData, setSelectedModalOrgCodeData] = useState<
    string[]
  >([]); // Modal
  const [selectedModalDpetCodeData, setSelectedModalDeptCodeData] = useState<
    string[]
  >([]); // Modal
  const [valMembership, setValMembership] = useState<string[]>([]); // Modal
  const [valGender, setValGender] = useState<string[]>([]); // Modal

  const addValModalDpt = (params) => {
    setSelectedModalDeptCodeData(params);
  };
  const addValMembership = (params) => {
    setValMembership(params);
  };
  const addValGender = (params) => {
    setValGender(params);
  };
  const addValModalOrg = (params, industry) => {
    setSelectedModalOrgCodeData(params);
    setModalIndByOrg([{ industry_name: industry }]);

    const page = pagePagination;
    const limit = 100;
    const organization_code = params;
    const payload = {
      page,
      limit,
      organization_code,
    };

    getDprtSeparate({ body: payload }).then(function (e) {
      setModalDprtData(e);
    });
  };

  // modal dialog
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialogImportError, setOpenDialogImportError] =
    React.useState(false);
  const [openDialogChangeRole, setOpenDialogChangeRole] = React.useState(false);
  const [openDialogImport, setOpenDialogImport] = React.useState(false);
  const [openDialogSend, setOpenDialogSend] = React.useState(false);
  const [openDialogHardDelete, setOpenDialogHardDelete] = React.useState(false);

  const [roleName, setRoleName] = useState("");
  const [type, setType] = useState("organization");
  const [actionModal, setActionModal] = useState("");
  const [selectDepartment, setSelectDepartment] = useState("");
  const [selectGender, setGender] = useState("");
  const [selectMembership, setMembership] = useState("");
  const [countSelected, setCountSelected] = useState(0);
  const [selectFilter, setSelectFilter] = useState<string[]>([]);
  const invitation = useRef<any[]>([""]);
  const [sb, setB] = useState<any[]>([]);
  const [errorImportList, setErrorImportList] = useState<any[]>([]);
  const [startFilter, setSF] = useState(false);

  const handleDelete = (post_code, content) => {
    setOpenDialog(true);
  };

  const showImport = () => {
    setActionModal("import");
    setOpenDialogImport(true);
  };
  const showSend = () => {
    setOpenDialogSend(true);
  };
  const closeImport = () => {
    setOpenDialogImport(false);
  };
  const closeSend = () => {
    setOpenDialogSend(false);
  };
  const closeErrorImport = () => {
    setOpenDialogImportError(false);
    setFiles([]);
  };

  const handleShowButtonInvitation = (count) => {
    // invitation[0] = count;
    invitation.current = count;
  };

  const setTemporarySelected = (count) => {
    setB(count);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleCloseChangeRole = () => {
    setOpenDialogChangeRole(false);
  };

  const handleCloseHardDelete = () => {
    setOpenDialogHardDelete(false);
  };

  const handleHardDelete = (post_code, content) => {
    setOpenDialogHardDelete(true);
  };

  const validButton = () => {
    const username = fieldProcessAddFullName;
    const email = fieldProcessAddEmail;
    const organization_code = selectedModalOrgCodeData;
    const department_code = selectedModalDpetCodeData;
    const industry_name = filterIndustryModal[0];
    const temp_organization = "";
    const temp_department = "";
    const temp_industry_name = "";
    const birthdate = valueD ? formatDate(valueD) : 0;
    const gender = valGender;
    const phone = fieldProcessAddNumber;
    // const membership_name = valMembership;
    const membership_name = "specialist";
    console.log("Change Tab for", tabName);

    if (addNewOrgValidation || tabName === "individual") {
      console.log("Gender :", gender);
      if (
        username &&
        files.length > 0 &&
        fieldProcessAddIndustryName.length > 0 &&
        fieldProcessAddDepartmentName.length > 0 &&
        fieldProcessAddOrganizationName.length > 0 &&
        membership_name &&
        gender[0] !== ""
      ) {
        setEnabledButton(false);
      } else {
        setEnabledButton(true);
      }
    } else {
      if (
        username &&
        files.length > 0 &&
        industry_name &&
        department_code &&
        organization_code &&
        membership_name &&
        gender[0] !== ""
      ) {
        setEnabledButton(false);
      } else {
        setEnabledButton(true);
      }
    }
  };

  const formatDate = (date) => {
    // eslint-disable-next-line prefer-const
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      // eslint-disable-next-line prefer-const
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const handleProcessAdd = () => {
    setEnabledButton(true);
    const exec = addUploadImage({ body: files[0] });
    exec.then(function (e) {
      if (e[0].stat_code === "ERR:BAD_REQUEST") {
        toggleAlert();
        setAlertType("error");
        setAlertMsg(e[0].stat_msg);
        setEnabledButton(false);
      } else {
        // HIT API Add Org
        const username = fieldProcessAddFullName;
        const email = fieldProcessAddEmail;
        const organization_code = selectedModalOrgCodeData;
        const department_code = selectedModalDpetCodeData;
        const industry_name = filterIndustryModal[0];
        const temp_organization =
          tabName === "individual" ? fieldProcessAddOrganizationName : "";
        const temp_department =
          tabName === "individual" ? fieldProcessAddDepartmentName : "";
        const temp_industry_name =
          tabName === "individual" ? fieldProcessAddIndustryName : "";
        const birthdate = valueD ? formatDate(valueD) : 0;
        const gender = valGender;
        const phone = fieldProcessAddNumber;
        // const membership_name = valMembership;
        const membership_name = "specialist";
        const profile_url = e;
        const specialist_type = tabName === "organization" ? "org" : "ind";

        if (addNewOrgValidation || tabName === "individual") {
          const name = fieldProcessAddOrganizationName;
          const industry_name = fieldProcessAddIndustryName;
          const pic = "Empty";
          const office_address = "Empty";
          const is_active = true;
          const payliad = {
            name,
            email,
            phone,
            industry_name,
            pic,
            office_address,
            is_active,
          };
          addOrg({ body: payliad }).then(function (e) {
            const name = fieldProcessAddDepartmentName;
            const ocd = e;
            const payloadDprt = {
              name,
              ocd,
            };

            addDprt({ body: payloadDprt }).then(function (d) {
              const organization_code = ocd;
              const department_code = d[0].departement_code;
              const temp_industry_name = fieldProcessAddIndustryName;
              const paydor = {
                username,
                email,
                organization_code,
                department_code,
                industry_name,
                temp_organization,
                temp_department,
                temp_industry_name,
                birthdate,
                gender,
                phone,
                membership_name,
                profile_url,
                specialist_type,
              };
              addMember({ body: paydor }).then(function (e) {
                if (e[0].stat_code === "ERR:BAD_REQUEST") {
                  toggleAlert();
                  setAlertType("error");
                  setAlertMsg(e[0].stat_msg);
                  setErrorMsg(e[0].stat_msg);
                } else {
                  loadDataPagi(pagex, tabName);
                  toggle();
                  resetForm();
                  setAlertType("success");
                  setAlertMsg("Add New Specialist Organization Success!");
                  toggleAlert();
                }
              });
            });
          });
        } else {
          const payload = {
            username,
            email,
            organization_code,
            department_code,
            industry_name,
            temp_organization,
            temp_department,
            temp_industry_name,
            birthdate,
            gender,
            phone,
            membership_name,
            profile_url,
            specialist_type,
          };

          addMember({ body: payload }).then(function (e) {
            if (e[0].stat_code === "ERR:BAD_REQUEST") {
              toggleAlert();
              setAlertType("error");
              setAlertMsg(e[0].stat_msg);
              setErrorMsg(e[0].stat_msg);
            } else {
              loadDataPagi(pagex, tabName);
              setAddSuccess(true);
              // toggle();
              // resetForm();
              toggleAlert();
              setAlertType("success");
              setAlertMsg("Add New Specialist Organization Success!");
              toggleAlert();
            }
          });
        }
      }
    });
  };

  const resetForm = () => {
    setAddSuccess(false);
    setErrorMsg("");
    setFiles([]);
    setSelectedModalOrgCodeData([]);
    setSelectedModalDeptCodeData([]);
    setSelectedModalOrgData([""]);
    setSelectedModalDprt([""]);
    setFilterIndustryModal([]);
    setValueD(null);
    setSelectedGender([""]);
    setSelectedMembership([""]);
    setModalDprtData([]);
    setModalIndByOrg([]);
    // setModalOrgData([]);
    setEnabledButton(true);
    getCountTab();
  };

  const getCountTab = () => {
    getMemberListsCount().then(function (response) {
      // setLabelUserTab('Organization Member (' + response[0].organization_count + ')');
      setLabelIndTab(
        "Individual Member (" + response[0].individual_count + ")"
      );
    });
  };

  const setAction = (action, params) => {
    if (action === "add") {
      setActionModal("add");
      resetForm();
      toggle();
    } else {
      setOpenDialog(true);
      setEditData(params);
    }
    // editProcess(params);
  };
  const onChangeSpecialist = (params) => {
    setEditDataChangeSpecialist(params);
    setOpenDialogChangeRole(true);
  };

  const handleProcessEdit = () => {
    editProcess(editData);
  };

  const handleProcessChangeSpecialist = () => {
    changeSpecialistProcess(editDataChangeSpecialist);
  };

  const editProcess = (params) => {
    const member_code = params.member_code;
    const organization_code = params.organization_code;
    const department_code = params.department_code;
    const status = params.status;
    const role_name = params.role_name;

    const payload = {
      member_code,
      organization_code,
      department_code,
      status,
      role_name,
    };

    editMembers({ body: payload }).then(function (e) {
      setOpenDialog(false);
      toggleAlert();
      setAlertType("success");
      setAlertMsg(" Change Role to " + status + " is Successfully!");
      loadDataPagi(pagex, tabName);
    });
  };

  const changeSpecialistProcess = (params) => {
    const member_code = params.member_code;
    const temp_organization = params.temp_organization;
    const temp_department = params.temp_department;
    const role_name = "psycholog";
    const status = params.status;
    const is_verified = true;

    const payload = {
      member_code,
      temp_organization,
      temp_department,
      role_name,
      status,
      is_verified,
    };
    // console.log(payload);
    editMembers({ body: payload }).then(async function (e) {
      await generateSchedules({ memberCode: member_code });
      setOpenDialogChangeRole(false);
      toggleAlert();
      setAlertType("success");
      setAlertMsg(" Change to Specialist Successfully!");
      loadDataPagi(pagex, tabName);
    });
  };

  const [runUseEffect, setRunUseEffect] = useState(false);

  const loadDataOrganizationFilter = () => {
    // setFilterSeparateOrg
    const page = pagePagination;
    const limit = 100;
    const is_active = true;
    const industry = "";
    const payload = {
      page,
      limit,
      industry,
    };

    getOrgsSeparate({
      body: payload,
      is_active: is_active,
    }).then(function (e) {
      setFilterSeparateOrg(e);
    });
  };

  const addValOrg = (params) => {
    setSF(true);
    setFilterUAOrganizationPayload(params);
    setFilterUADepartmentPayload([""]);

    const page = pagePagination;
    const limit = 100;
    const organization_code = params;
    const payload = {
      page,
      limit,
      organization_code,
    };

    getDprt({ body: payload }).then(function (e) {
      setFilterDprt(e);
      setSF(false);
    });
  };

  const addDprtOrg = (params) => {
    setSF(true);
    setFilterUADepartmentPayload(params);
  };

  useEffect(() => {
    if (runUseEffect === false) {
      const page = pagePagination;
      const limit = 10;
      const role_name = roleName;
      const type = tabName;
      const keyword = qeyword;
      const organization_code = "";
      const department_name = "";
      const membership_name = "";
      const status = "";

      const payload = {
        page,
        limit,
        role_name,
        organization_code,
        department_name,
        membership_name,
        status,
        type,
        keyword,
      };

      getMemberLists({ body: payload }).then(function (e) {
        setTablePagination((prevTablePagination) => e);
        setRunUseEffect(true);
      });

      loadDataModal();
      loadDataOrganizationFilter();

      const set_group = "gender";
      const sort = "asc";
      const is_active = true;
      const payloadSetting = {
        page,
        limit,
        is_active,
        set_group,
        sort,
      };

      getSettings({ body: payloadSetting }).then(function (e) {
        setGenderLoad(e);
      });

      loadMembershipSetting();
    }

    setTableData(members);

    if (startFilter === true) {
      loadDataFilter(pagex);
    }

    if (labelUserTab === "") {
      getCountTab();
    }
    setRunUseEffect(true);
  }, [members, tablePagination, enabledButton, startFilter]);

  // Multiple Select
  const theme = useTheme();

  const processDelete = () => {
    //
  };

  const loadMembershipSetting = () => {
    const set_group = "psycholog_level";
    const page = pagePagination;
    const sort = "asc";
    const limit = 100;
    const is_active = true;
    const payloadSetting = {
      page,
      limit,
      is_active,
      set_group,
      sort,
    };

    getSettings({ body: payloadSetting }).then(function (e) {
      setMembershipLoad(e);
    });
  };

  const changeDate = (e) => {
    setValueD(e);
    validButton();
  };

  const handleChangeFilter = (
    event: SelectChangeEvent<typeof selectFilter>
  ) => {
    const {
      target: { value },
    } = event;
    setSelectFilter(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ adding: "24px 0" }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
      fontSize: "10px",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      padding: "10px 20px",
    },
  }));

  // Preprocess Add

  const loadDataModal = () => {
    const page = 1;
    const limit = 100;
    const is_active = true;
    const industry = "";
    const keyword = selectedModalOrg;

    const payload = {
      page,
      limit,
      is_active,
      industry,
      keyword,
    };

    getOrgsSeparate({ body: payload }).then(function (e) {
      setModalOrgData(e);
      // setSM(false);
    });
  };

  // Preprocess Add
  const [selectedModalOrgData, setSelectedModalOrgData] = useState<string[]>(
    []
  );
  const [editData, setEditData] = useState<any[]>([]);
  const [editDataChangeSpecialist, setEditDataChangeSpecialist] = useState<
    any[]
  >([]);
  const [selectedModalSubs, setSelectedModalSubs] = useState<string[]>([]);
  const [selectedRights, setSelectedRights] = useState<string[]>([]);
  const [selectedMembership, setSelectedMembership] = useState<string[]>([]);
  const [selectedGender, setSelectedGender] = useState<string[]>([]);
  const [selectedModalDprt, setSelectedModalDprt] = useState<string[]>([]);
  const [filterIndustryModal, setFilterIndustryModal] = useState<string[]>([]);

  const [fieldProcessAddEmail, setFieldProcessAddEmail] = useState<any>("");
  const [fieldProcessAddNumber, setFieldProcessAddNumber] = useState<any>("");
  const [fieldProcessAddFullName, setFieldProcessAddFullName] =
    useState<any>("");
  const [fieldProcessAddIndustri, setFieldProcessAddIndustri] =
    useState<any>("");
  const [fieldProcessAddDprt, setFieldProcessAddDprt] = useState<any>("");

  // Add New Org
  const [fieldProcessAddOrganizationName, setFieldProcessAddOrganizationName] =
    useState<any>("");
  const [fieldProcessAddDepartmentName, setFieldProcessAddDepartmentName] =
    useState<any>("");
  const [fieldProcessAddIndustryName, setFieldProcessAddIndustryName] =
    useState<any>("");

  const handleChangeModalOrganizationData = (
    event: SelectChangeEvent<typeof selectedModalOrgData>
  ) => {
    const {
      target: { value },
    } = event;

    if (value !== "Add New") {
      setAddNewOrgValidation(false);
    }

    setSelectedModalOrgData(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    validButton();
  };

  const handleFieldProcessAddOrganizationName = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFieldProcessAddOrganizationName(e.target.value);
    validButton();
  };

  const handleFieldProcessAddDepartmentName = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFieldProcessAddDepartmentName(e.target.value);
    validButton();
  };

  const handleFieldProcessAddIndustryName = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFieldProcessAddIndustryName(e.target.value);
    validButton();
  };

  const handleFieldProcessAddEmail = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFieldProcessAddEmail(e.target.value);
    validButton();
  };
  const handleFieldProcessAddNumber = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFieldProcessAddNumber(e.target.value.replace(/\D/, ""));
    validButton();
  };
  const handleFieldProcessAddFullName = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFieldProcessAddFullName(e.target.value);

    validButton();
  };
  const handleChangeModalDepartment = (
    event: SelectChangeEvent<typeof selectedModalDprt>
  ) => {
    const {
      target: { value },
    } = event;

    setSelectedModalDprt(typeof value === "string" ? value.split(",") : value);
    validButton();
  };
  const handleChangeMembership = (
    event: SelectChangeEvent<typeof selectedMembership>
  ) => {
    const {
      target: { value },
    } = event;

    setSelectedMembership(typeof value === "string" ? value.split(",") : value);
    validButton();
  };
  const handleChangeGender = (
    event: SelectChangeEvent<typeof selectedGender>
  ) => {
    const {
      target: { value },
    } = event;

    setSelectedGender(typeof value === "string" ? value.split(",") : value);
    validButton();
  };
  const handleFilterIndustryModal = (
    event: SelectChangeEvent<typeof filterIndustryModal>
  ) => {
    const {
      target: { value },
    } = event;
    setFilterIndustryModal(
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleFieldProcessAddIndustri = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFieldProcessAddIndustri(e.target.value);
  };

  const handleChangeModalSubs = (
    event: SelectChangeEvent<typeof selectedModalSubs>
  ) => {
    const {
      target: { value },
    } = event;

    setSelectedModalSubs(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  // End

  // Tabs

  const resetFilter = () => {
    setFilterUAOrganization([""]);
    setFilterUAOrganizationPayload([""]);
    setFilterUADepartment([""]);
    setFilterUADepartmentPayload([""]);
    setFilterUARights([""]);
    setFilterUARightsPayload([""]);
    setFilterUAStatus([""]);
    setFilterUAStatusPayload([""]);
    setFilterUAMembership([""]);
    setFilterUAMembershipPayload([""]);
  };

  const [value, setValue] = React.useState(0);
  const [tabName, setTabName] = React.useState("individual");
  const [qeyword, setKeyword] = React.useState("");
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    resetFilter();
    setLimitTable(10);
    loadDataPagi(0, tabName);
    setB([]);
  };

  // FILTERUA
  const [filterUAOrganization, setFilterUAOrganization] = React.useState<
    string[]
  >([""]);
  const [filterUAOrganizationPayload, setFilterUAOrganizationPayload] =
    React.useState<string[]>([""]);
  const handleFilterUAOrganization = (
    event: SelectChangeEvent<typeof filterUAOrganization>
  ) => {
    const {
      target: { value },
    } = event;
    setFilterUAOrganization(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const resetOrganization = (params) => {
    setFilterUAOrganization([""]);
    setFilterUAOrganizationPayload([""]);
    setFilterDprt([]);
    setSF(true);
  };
  const [filterUADepartment, setFilterUADepartment] = React.useState<string[]>(
    []
  );
  const [filterUADepartmentPayload, setFilterUADepartmentPayload] =
    React.useState<string[]>([""]);
  const handleFilterUADepartment = (
    event: SelectChangeEvent<typeof filterUADepartment>
  ) => {
    const {
      target: { value },
    } = event;
    setFilterUADepartment(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const resetDepartment = (params) => {
    setFilterUADepartment([""]);
    setFilterUADepartmentPayload([""]);
    setSF(true);
  };
  const resetRights = (params) => {
    setFilterUARights([""]);
    setFilterUARightsPayload([""]);
    setSF(true);
  };
  const addValRights = (params) => {
    setSF(true);
    setFilterUARightsPayload(params);
  };
  const addValMem = (params) => {
    setSF(true);
    setFilterUAMembershipPayload(params);
  };
  const addValStatus = (params) => {
    setSF(true);
    setFilterUAStatusPayload(params);
  };
  const [filterUARights, setFilterUARights] = React.useState<string[]>([]);
  const [filterUARightsPayload, setFilterUARightsPayload] = React.useState<
    string[]
  >([]);
  const handleFilterUARights = (
    event: SelectChangeEvent<typeof filterUARights>
  ) => {
    const {
      target: { value },
    } = event;
    setFilterUARights(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const [filterUAStatus, setFilterUAStatus] = React.useState<string[]>([]);
  const [filterUAStatusPayload, setFilterUAStatusPayload] = React.useState<
    string[]
  >([]);
  const handleFilterUAStatus = (
    event: SelectChangeEvent<typeof filterUAStatus>
  ) => {
    const {
      target: { value },
    } = event;
    setFilterUAStatus(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const resetStatus = (params) => {
    setSF(true);
    setFilterUAStatus([""]);
    setFilterUAStatusPayload([""]);
  };
  const [filterUAMembership, setFilterUAMembership] = React.useState<string[]>(
    []
  );
  const [filterUAMembershipPayload, setFilterUAMembershipPayload] =
    React.useState<string[]>([]);
  const handleFilterUAMembership = (
    event: SelectChangeEvent<typeof filterUAMembership>
  ) => {
    const {
      target: { value },
    } = event;
    setFilterUAMembership(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const resetMembership = (params) => {
    setFilterUAMembership([""]);
    setFilterUAMembershipPayload([""]);
    setSF(true);
  };

  const [addNewOrgValidation, setAddNewOrgValidation] = useState(false);
  const addNewOrg = () => {
    setAddNewOrgValidation(true);
    // setFieldProcessAddEmail('');
    // setFieldProcessAddNumber('');
  };

  // Dropzone
  const [files, setFiles] = useState([] as any);

  const [startUF, setSUF] = useState(false);
  const [isAction, setIsAction] = useState("");
  const [numberProgress, setNumberProgress] = useState(0);
  const { getRootProps, getInputProps } = useDropzone({
    accept:
      actionModal === "add"
        ? {
            "image/*": [],
          }
        : {
            "text/csv": [],
          },
    onDrop: (acceptedFiles) => {
      setSUF(true);
      for (let index = 0; index <= 100; index++) {
        setTimeout(function timer() {
          setNumberProgress(index);
          if (index === 100) {
            console.log(acceptedFiles);
            setFiles(
              acceptedFiles.map((file) =>
                Object.assign(file, {
                  preview: URL.createObjectURL(file),
                })
              )
            );
            setSUF(false);
            setNumberProgress(0);
          }
        }, index * 20);
      }
    },
  });

  const thumbs = files.map((file) => (
    <div style={thumb as React.CSSProperties} key={file.name}>
      <div style={thumbInner}>
        <div
          {...getRootProps({ className: "dropzone" })}
          style={{
            background: "#ffffff",
            width: "55px !important",
            marginTop: "0 !important",
            borderRadius: "100px",
            height: "55px !important",
            padding: "0 !important",
            cursor: "pointer",
          }}
        >
          <input {...getInputProps()} />
          <div
            style={{
              background: `url(${file.preview}) #D3D3D3`,
              backgroundSize: "100%",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              width: "55px",
              height: "55px",
              display: "flex",
              alignItems: "center",
              borderRadius: "100px",
              justifyContent: "center",
            }}
          />
        </div>
      </div>
    </div>
  ));

  const contentEmptyFooterAction = <React.Fragment />;

  const contentModalFooter = (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <ButtonStyle
          className="btn-primary"
          disabled={enabledButton}
          title="Add New Specialist "
          onClick={handleProcessAdd}
          style={{ marginTop: "30px" }}
        >
          Add Specialist
        </ButtonStyle>
      </Box>
    </React.Fragment>
  );

  const contentModalSuccess = (
    <React.Fragment>
      <Box>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={Images.successModal} style={{ width: "133px" }} />
          <LabelModal
            style={{
              fontSize: "18px",
              paddingTop: "30px",
            }}
          >
            Added new specialist successfully
          </LabelModal>
          <Typography
            sx={{
              fontSize: "16px",
              color: "#767E8C",
              marginTop: "10px",
              marginBottom: "30px",
            }}
          >
            Note: Username & Password has been automatically sent
          </Typography>
          <Button
            className="btn-action-filter-dark"
            sx={{ width: "100%" }}
            onClick={() => {
              toggle();
              resetForm();
            }}
          >
            Done
          </Button>
        </div>
      </Box>
    </React.Fragment>
  );

  const contentModal = (
    <React.Fragment>
      <Box>
        <Box sx={{ overFlowY: "initial !important" }}>
          <div className="modalBodyC">
            <div className="input-wrapper" style={{ margin: "0 0 10px 0" }}>
              <div className="schedule-cus" style={{ width: "100%" }}>
                <div className="date-schedule-cus" style={{ width: "100%" }}>
                  <LabelModal
                    style={{
                      margin: "5px 0 10px 0",
                      display: "inline-block",
                    }}
                  >
                    Avatar Profile*
                  </LabelModal>
                  <div className="input-wrapper form-inline">
                    {files.length > 0 ? (
                      <aside style={thumbsContainer as React.CSSProperties}>
                        {thumbs}
                      </aside>
                    ) : (
                      <section
                        style={{
                          width: "55px",
                          height: "55px",
                          borderRadius: "100%",
                        }}
                      >
                        <div
                          {...getRootProps({
                            className: "dropzone dropzone-cus",
                          })}
                          style={{
                            background: "#ffffff",
                            width: "55px",
                            marginTop: "0",
                            borderRadius: "100px",
                            height: "55px",
                            padding: "0",
                            cursor: "pointer",
                          }}
                        >
                          <input {...getInputProps()} />
                          {startUF ? (
                            <>
                              <div style={{ display: "flex" }}>
                                <Typography
                                  sx={{
                                    color: "#292A2E",
                                    fontSize: "12px",
                                  }}
                                >
                                  {numberProgress}%
                                </Typography>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  fontSize: "20px",
                                }}
                              >
                                +
                              </div>
                            </>
                          )}
                        </div>
                      </section>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="input-wrapper" style={{ margin: "0 0 0 0" }}>
              <div className="schedule-cus" style={{ width: "100%" }}>
                <div style={{ width: "100%" }}>
                  <LabelModal
                    style={{
                      margin: "5px 0 10px 0",
                      display: "inline-block",
                    }}
                  >
                    Full Name*
                  </LabelModal>
                  <div className="input-wrapper form-inline">
                    <input
                      type="text"
                      placeholder="Full Name"
                      onBlur={(e) => handleFieldProcessAddFullName(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="input-wrapper" style={{ margin: "0 0 0 0" }}>
              <div
                className="schedule-cus"
                style={{
                  width: "100%",
                  paddingTop: "0",
                }}
              >
                <div style={{ width: "100%" }}>
                  <LabelModal
                    style={{
                      margin: "0 0 10px 0",
                      display: "inline-block",
                    }}
                  >
                    Email Address*
                  </LabelModal>
                  <div className="input-wrapper form-inline">
                    <input
                      type="text"
                      placeholder="Official Email.."
                      onChange={(e) => handleFieldProcessAddEmail(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="input-wrapper" style={{ margin: "0 0 0 0" }}>
              <div
                className="schedule-cus"
                style={{
                  width: "100%",
                  paddingTop: "0",
                }}
              >
                <div className="time-schedule-cus" style={{ width: "100%" }}>
                  <LabelModal
                    style={{
                      margin: "5px 0 10px 0",
                      display: "inline-block",
                    }}
                  >
                    Phone Number
                  </LabelModal>
                  <div className="input-wrapper form-inline">
                    <input
                      type="text"
                      pattern="[0-9]*"
                      value={fieldProcessAddNumber}
                      placeholder="Phone Number.."
                      onChange={handleFieldProcessAddNumber}
                    />
                  </div>
                </div>
              </div>
            </div>
            {tabName !== "individual" ? (
              <div className="input-wrapper" style={{ margin: "5px 0 0 0" }}>
                <div
                  className="schedule-cus"
                  style={{
                    width: "100%",
                    paddingTop: "0",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <LabelModal
                      style={{
                        margin: "0 0 10px 0",
                        display: "inline-block",
                      }}
                    >
                      Organization
                    </LabelModal>
                    <div className="input-wrapper form-inline">
                      <Select
                        // multiple
                        displayEmpty
                        value={selectedModalOrgData}
                        onChange={handleChangeModalOrganizationData}
                        input={<OutlinedInput />}
                        renderValue={(selected) => {
                          if (selected.length === 0 || selected[0] === "") {
                            return <em>All Organization</em>;
                          }

                          return selected;
                        }}
                        MenuProps={MenuProps}
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{
                          marginTop: "0px",
                          width: "100%",
                          border: "solid 1px Colors.app.GhostGrey",
                          background: Colors.white.default,
                          borderRadius: "5px",
                          fontSize: "14px !important",
                          color: Colors.app.black,
                          fontWeight: Fonts.weights.w5,
                          fontFamily: "Fonts.interMedium",
                        }}
                      >
                        {modalOrgData.map((morgd) => (
                          <MenuItem
                            key={"add" + morgd.organization_code}
                            onClick={() =>
                              addValModalOrg(
                                morgd.organization_code,
                                morgd.industry_name
                              )
                            }
                            value={morgd.organization_name}
                          >
                            <ListItemText
                              primary={morgd.organization_name}
                              sx={{ fontSize: "14px !important" }}
                            />
                          </MenuItem>
                        ))}
                        <MenuItem onClick={addNewOrg} value="Add New">
                          <Iconify
                            icon="eva:plus-fill"
                            sx={{
                              fontSize: "20px",
                              color: "#167CB8",
                            }}
                          />{" "}
                          <ListItemText
                            primary="Add New"
                            sx={{
                              color: "#167CB8",
                              fontSize: "14px !important",
                            }}
                          />
                        </MenuItem>
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {addNewOrgValidation || tabName === "individual" ? (
              <div className="input-wrapper" style={{ margin: "5px 0 0 0" }}>
                <div
                  className="schedule-cus"
                  style={{
                    width: "100%",
                    paddingTop: "0",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <LabelModal
                      style={{
                        margin: "10px 0 10px 0",
                        display: "inline-block",
                      }}
                    >
                      Organization Name
                    </LabelModal>
                    <div className="input-wrapper form-inline">
                      <input
                        type="text"
                        placeholder="Organization Name.."
                        onBlur={(e) => handleFieldProcessAddOrganizationName(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="input-wrapper" style={{ margin: "5px 0 0 0" }}>
              <div className="schedule-cus" style={{ width: "100%" }}>
                <div className="date-schedule-cus" style={{ width: "50%" }}>
                  <LabelModal style={{ display: "inline-block" }}>
                    Department
                  </LabelModal>

                  {addNewOrgValidation || tabName === "individual" ? (
                    <div
                      className="input-wrapper form-inline"
                      style={{ marginTop: "10px" }}
                    >
                      <input
                        type="text"
                        placeholder="Department"
                        onBlur={(e) => handleFieldProcessAddDepartmentName(e)}
                      />
                    </div>
                  ) : (
                    <Select
                      displayEmpty
                      onChange={handleChangeModalDepartment}
                      value={selectedModalDprt}
                      renderValue={(selected) => {
                        if (selected.length === 0 || selected[0] === "") {
                          return <em>Select Department</em>;
                        }
                        return selected;
                      }}
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        marginTop: "8px",
                        width: "100%",
                        border: "solid 1px Colors.app.GhostGrey",
                        background: Colors.white.default,
                        borderRadius: "5px",
                        color: Colors.app.black,
                        fontWeight: Fonts.weights.w5,
                        fontFamily: "Fonts.interMedium",
                        fontSize: Fonts.sizes.xs,
                      }}
                    >
                      {modalDprtData.map((mdprd) => (
                        <MenuItem
                          key={mdprd.name}
                          onClick={() => addValModalDpt(mdprd.departement_code)}
                          value={mdprd.name}
                        >
                          {mdprd.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </div>
                <div className="time-schedule-cus" style={{ width: "50%" }}>
                  <LabelModal style={{ display: "inline-block" }}>
                    Industry
                  </LabelModal>
                  {addNewOrgValidation || tabName === "individual" ? (
                    <div
                      className="input-wrapper form-inline"
                      style={{ marginTop: "10px" }}
                    >
                      <input
                        type="text"
                        placeholder="Department"
                        onBlur={(e) => handleFieldProcessAddIndustryName(e)}
                      />
                    </div>
                  ) : (
                    <Select
                      displayEmpty
                      onChange={handleFilterIndustryModal}
                      value={filterIndustryModal}
                      renderValue={(selected) => {
                        if (selected.length === 0 || selected[0] === "") {
                          return <em>Select Industry</em>;
                        }
                        return selected;
                      }}
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        marginTop: "8px",
                        width: "100%",
                        border: "solid 1px Colors.app.GhostGrey",
                        background: Colors.white.default,
                        borderRadius: "5px",
                        color: Colors.app.black,
                        fontWeight: Fonts.weights.w5,
                        fontFamily: "Fonts.interMedium",
                        fontSize: Fonts.sizes.xs,
                      }}
                    >
                      {modalIndByOrg.map((fn) => (
                        <MenuItem
                          key={fn.industry_name}
                          value={fn.industry_name}
                        >
                          <ListItemText primary={fn.industry_name} />
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </div>
              </div>
            </div>
            <div className="input-wrapper" style={{ margin: "5px 0 10px 0" }}>
              <div className="schedule-cus" style={{ width: "100%" }}>
                <div className="date-schedule-cus" style={{ width: "50%" }}>
                  <LabelModal style={{ display: "inline-block" }}>
                    Gender
                  </LabelModal>
                  <Select
                    displayEmpty
                    onChange={handleChangeGender}
                    value={selectedGender}
                    renderValue={(selected) => {
                      if (selected.length === 0 || selected[0] === "") {
                        return <em>Gender</em>;
                      }
                      return selected;
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{
                      marginTop: "8px",
                      width: "100%",
                      border: "solid 1px Colors.app.GhostGrey",
                      background: Colors.white.default,
                      borderRadius: "5px",
                      color: Colors.app.black,
                      fontWeight: Fonts.weights.w5,
                      fontFamily: "Fonts.interMedium",
                      fontSize: Fonts.sizes.xs,
                    }}
                  >
                    {genderLoad.map((dpr) => (
                      <MenuItem
                        key={dpr.set_key}
                        onClick={(e) => addValGender(dpr.set_key)}
                        value={dpr.set_label}
                      >
                        {dpr.set_label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="time-schedule-cus" style={{ width: "50%" }}>
                  <LabelModal
                    style={{
                      display: "inline-block",
                      marginBottom: "10px",
                    }}
                  >
                    Birthday
                  </LabelModal>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={valueD}
                      onChange={(newValue) => changeDate(newValue)}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
          </div>
          <Typography
            sx={{
              fontSize: "12px",
              color: "#CC1964",
              marginBottom: "10px",
              fontFamily: "Inter-Regular",
            }}
          >
            {errorMsg}
          </Typography>
        </Box>
      </Box>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Appbar
          onSearch={(val) => searchData(val)}
          title="Manage Individual User"
        ></Appbar>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label={labelIndTab} {...a11yProps(0)} />
            </Tabs>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            margin: "0",
            paddingTop: "20px",
            paddingBottom: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              flex: 1,
              textAlign: "right",
            }}
          >
            {sb.length > 0 ? (
              <Button
                className="btn-action-filter-light"
                sx={{ width: "200px" }}
                onClick={() => console.log("Band Member")}
                endIcon={
                  <img
                    className="!h-5 !w-5 pl-4"
                    src={Images.btn_flag}
                    style={{ width: "24px" }}
                  />
                }
              >
                Ban Member
              </Button>
            ) : (
              ""
            )}
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <FormControl sx={{ m: "10px 0 10px 0px" }}>
            <Select
              displayEmpty
              value={filterUARights}
              onChange={handleFilterUARights}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                if (selected.length === 0 || selected[0] === "") {
                  return <em>All Rights</em>;
                }

                return selected;
              }}
              MenuProps={MenuProps}
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                height: "40px",
                fontSize: "14px",
                fontFamily: "Inter-Medium",
              }}
            >
              <MenuItem onClick={() => resetRights("")} value="">
                <ListItemText
                  primary="All Rights"
                  sx={{ fontSize: "14px !important" }}
                />
              </MenuItem>
              {tabName === "organization"
                ? rights_org.map((fu) => (
                    <MenuItem
                      key={fu.value}
                      onClick={() => addValRights(fu.value)}
                      value={fu.label}
                    >
                      <ListItemText
                        primary={fu.label}
                        sx={{ fontSize: "14px !important" }}
                      />
                    </MenuItem>
                  ))
                : rights_ind.map((fu) => (
                    <MenuItem
                      key={fu.value}
                      onClick={() => addValRights(fu.value)}
                      value={fu.label}
                    >
                      <ListItemText
                        primary={fu.label}
                        sx={{ fontSize: "14px !important" }}
                      />
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
          <FormControl sx={{ m: "10px 0 10px 20px" }}>
            <Select
              displayEmpty
              value={filterUAMembership}
              onChange={handleFilterUAMembership}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                if (selected.length === 0 || selected[0] === "") {
                  return <em>All Membership</em>;
                }

                return selected;
              }}
              MenuProps={MenuProps}
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                height: "40px",
                fontSize: "14px",
                fontFamily: "Inter-Medium",
              }}
            >
              <MenuItem onClick={() => resetMembership("")} value="">
                <ListItemText
                  primary="All Membership"
                  sx={{ fontSize: "14px !important" }}
                />
              </MenuItem>
              {membershipLoad.map((dpr) => (
                <MenuItem
                  key={dpr.set_key}
                  onClick={() => addValMem(dpr.set_key)}
                  value={dpr.set_label}
                >
                  <ListItemText
                    primary={dpr.set_label}
                    sx={{ fontSize: "14px !important" }}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <TabPanel value={value} index={0}>
          <Card>
            <CIndTab
              countSelect={(e) => handleShowButtonInvitation(e)}
              tempoSel={(e) => setTemporarySelected(e)}
              list={tableData}
              tempSelected={sb}
              pagination={tablePagination}
              getChangeRows={onChangeRowsPerPage}
              getAnyPage={getAnyPage}
              onEdit={(action, params) => setAction(action, params)}
              onChangeSpecialist={(params) => onChangeSpecialist(params)}
              handleDelete={(post_code, content) =>
                handleDelete(post_code, content)
              }
            />
          </Card>
        </TabPanel>

        <ModalDefault
          isShown={isShown}
          hide={toggle}
          reset={() => console.log("Clearout")}
          title={
            tabName === "organization"
              ? addSuccess === true
                ? ""
                : "Add New Specialist Organization"
              : "Add New Specialist Individual"
          }
          modalContent={
            addSuccess === true ? contentModalSuccess : contentModal
          }
          modalFooter={
            addSuccess === true ? contentEmptyFooterAction : contentModalFooter
          }
        />
        <AlertComponent
          isShownAlert={isShownAlert}
          hide={toggleAlert}
          severity={alertType}
          content={alertMsg}
        />

        <Dialog
          open={openDialog}
          onClose={handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            Are you sure, change this role member ?
          </DialogTitle>
          <DialogContent
            sx={{
              marginTop: "20px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div>
              <img src={Images.delete} style={{ width: "146px" }} />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                marginTop: "40px",
                justifyContent: "space-around",
              }}
            >
              <Button className="btn-action-filter-light" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                className="btn-action-filter-dark"
                onClick={handleProcessEdit}
              >
                Delete
              </Button>
            </div>
          </DialogContent>
        </Dialog>
        <Dialog
          open={openDialogChangeRole}
          onClose={handleCloseChangeRole}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            Are you sure to change Specialist ?
          </DialogTitle>
          <DialogContent
            sx={{
              marginTop: "20px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div>
              <img src={Images.delete} style={{ width: "146px" }} />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                marginTop: "40px",
                justifyContent: "space-around",
              }}
            >
              <Button
                className="btn-action-filter-light"
                onClick={handleCloseChangeRole}
              >
                Cancel
              </Button>
              <Button
                className="btn-action-filter-dark"
                onClick={handleProcessChangeSpecialist}
              >
                Change Now
              </Button>
            </div>
          </DialogContent>
        </Dialog>
        <Dialog
          open={openDialogHardDelete}
          onClose={handleCloseHardDelete}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            Delete Permanently Posts
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Apakah Anda yakin akan menghapus permanen data ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCloseHardDelete}>
              Cancel
            </Button>
            <Button onClick={processDelete}>Delete</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openDialogImport}
          onClose={closeImport}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle
            style={{
              cursor: "move",
              fontSize: "18px",
              fontFamily: "Fonts.interMedium",
              padding: "16px 24px 0px 24px",
            }}
            id="draggable-dialog-title"
          >
            Import Member(s)
            <ModalCLoseIcon title="Close" onClick={closeImport}>
              {modalCloseIcon}
            </ModalCLoseIcon>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{
                fontSize: "14px",
                fontFamily: "Fonts.interRegular",
                color: "#767E8C",
                marginBottom: "20px",
              }}
            >
              <b>Important</b>: You can import maximum 40 rows per entry
            </DialogContentText>
            <Typography
              sx={{
                fontSize: "14px",
                fontFamily: "Inter-Medium",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                color: "#167CB8",
              }}
              onClick={() =>
                window.open(
                  "https://maxi-prod-assets.s3.ap-southeast-1.amazonaws.com/assets/template_csv_member.csv"
                )
              }
            >
              Download template here
            </Typography>

            <section
              style={{
                width: "100%",
                height: "231px",
                backgroundColor: "#ffffff",
              }}
            >
              <div
                {...getRootProps({ className: "dropzone dropzone-cus" })}
                style={{ backgroundColor: "#ffffff" }}
              >
                <input {...getInputProps()} />
                <>
                  <div style={{ display: "flex" }}>
                    <img
                      src={Images.import}
                      style={{
                        width: "60px",
                        marginBottom: "10px",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      marginBottom: "10px",
                      marginTop: "20px",
                      fontSize: "14px",
                      color: "#292A2E",
                      fontFamily: "Fonts.interMedium !important",
                    }}
                  >
                    <h3>Choose your csv file or drop here</h3>
                  </div>
                  <div style={{ display: "flex" }}>
                    <p
                      style={{
                        fontSize: "12px",
                        lineHeight: "140%",
                        fontFamily: "Fonts.interRegular !important",
                        color: "#767E8C",
                      }}
                    >
                      Make sure your file contains these following fields:{" "}
                      <br />
                      email*, phone number, organisation*, department*,
                      industry*,
                      <br />
                      membership, gender, and, birthday.
                    </p>
                  </div>
                </>
              </div>
            </section>
            <div style={{ marginTop: "20px" }}>
              {files.length > 0
                ? files.map((file) => (
                    <div
                      key={file.name}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Typography
                          sx={{
                            fontFamily: "Fonts.interMedium !important",
                            fontSize: "14px",
                          }}
                        >
                          {file.name}
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          sx={{
                            fontFamily: "Fonts.interMedium !important",
                            fontSize: "14px",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          {file.size} KB &nbsp;{" "}
                          <Iconify
                            sx={{ cursor: "pointer" }}
                            icon={"carbon:close-filled"}
                            onClick={(e) => setFiles([])}
                          />
                        </Typography>
                      </div>
                    </div>
                  ))
                : ""}

              {startUF ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Typography
                      sx={{
                        fontFamily: "Fonts.interMedium !important",
                        fontSize: "14px",
                      }}
                    >
                      File upload processing...
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      sx={{
                        fontFamily: "Fonts.interMedium !important",
                        fontSize: "14px",
                      }}
                    >
                      <CircularProgress
                        size={15}
                        sx={{
                          color: green[500],
                        }}
                      />
                    </Typography>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <Button
              className="btn btn-action-filter-dark"
              style={{
                width: "100%",
                marginTop: "20px",
              }}
              onClick={handleImportMember}
            >
              Submit
            </Button>
          </DialogContent>
        </Dialog>
        <Dialog
          open={openDialogSend}
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              closeSend();
            }
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          PaperProps={{ sx: { width: "470px" } }}
        >
          <DialogTitle
            style={{
              cursor: "move",
              textAlign: "center",
            }}
            id="draggable-dialog-title"
          >
            Import Successful
            <DialogContentText
              sx={{
                fontSize: "14px",
                textAlign: "center",
                fontFamily: "Fonts.interRegular",
                color: "#767E8C",
                marginBottom: "20px",
              }}
            >
              Kindly re-check email invitation list
            </DialogContentText>
            <ModalCLoseIcon title="Close" onClick={closeSend}>
              {modalCloseIcon}
            </ModalCLoseIcon>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{
                display: "inline-block",
                alignItems: "center",
                background: "#FFFFFF",
                borderRadius: "5px",
                fontFamily: "Inter-Regular",
                textAlign: "center",
                justifyContent: "center",
                width: "100%",
                padding: "10px",
                flexDirection: "column",
              }}
            >
              {sb.map((row) => {
                if (row.invitation_email !== "") {
                  return (
                    <Typography
                      sx={{
                        backgroundColor: "lightblue",
                        display: "inherit",
                        padding: "5px 10px",
                        color: "#292A2E",
                        borderRadius: "15px",
                        fontFamily: "Inter-Regular",
                        marginRight: "10px",
                        marginBottom: "10px",
                        width: "auto",
                      }}
                      key={row.invitation_email}
                    >
                      {row.invitation_email}
                    </Typography>
                  );
                }
              })}
            </DialogContentText>
            <Button
              className="btn btn-action-filter-dark"
              style={{
                width: "100%",
                marginTop: "20px",
              }}
              onClick={handleSendInvitation}
            >
              Send Invitation Code
            </Button>
          </DialogContent>
        </Dialog>
        <Dialog
          open={openDialogImportError}
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              closeErrorImport();
            }
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          PaperProps={{ sx: { width: "520px" } }}
        >
          <DialogTitle
            style={{
              cursor: "move",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            id="draggable-dialog-title"
          >
            <Iconify
              icon="carbon:close-filled"
              sx={{
                fontSize: "20px",
                color: "#CC1964",
              }}
            />{" "}
            &nbsp;
            <Typography
              style={{
                fontSize: "18px",
                color: "#292A2E",
                fontFamily: "Inter-Medium",
              }}
            >
              We found error(s) in the csv file
            </Typography>
          </DialogTitle>
          <DialogContent sx={{ textAlign: "center" }}>
            <DialogContentText
              sx={{
                marginTop: "20px",
                display: "inline-flex",
                flexDirection: "column",
              }}
            >
              {files.map((file) => (
                <div
                  key={file.name}
                  style={{
                    border: "solid 1px #bfbfbf",
                    borderRadius: "15px 15px 0 0",
                    padding: "10px 20px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    borderBottom: "none",
                  }}
                >
                  <div>
                    <img
                      src={
                        "https://maxi-prod-assets.s3.ap-southeast-1.amazonaws.com/assets/csv_icon.png"
                      }
                      style={{ width: "40px" }}
                    />
                  </div>
                  <div
                    style={{
                      flex: "1",
                      marginLeft: "20px",
                      textAlign: "left",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "14px",
                        fontFamily: "Inter-Medium",
                      }}
                    >
                      {file.name}
                    </div>
                    <div
                      style={{
                        color: "#A0AAB8",
                        fontSize: "14px",
                        fontFamily: "Inter-Regular",
                      }}
                    >
                      {file.size} KB
                    </div>
                  </div>
                  <div
                    style={{
                      flex: 1,
                      textAlign: "left",
                      fontSize: "14px",
                      fontFamily: "Inter-Medium",
                    }}
                  >
                    Count : {errorImportList.length}
                  </div>
                  <div>
                    <BootstrapTooltip
                      placement="top-start"
                      title={"We only show a maximum of 10 error rows"}
                    >
                      <span style={{ cursor: "pointer" }}>
                        <Iconify
                          icon="ant-design:info-circle-outlined"
                          sx={{
                            fontSize: "20px",
                            color: "#A0AAB8",
                          }}
                        />
                      </span>
                    </BootstrapTooltip>
                  </div>
                </div>
              ))}
              <div
                style={{
                  border: "solid 1px #bfbfbf",
                  borderRadius: "0 0 15px 15px",
                  padding: "10px 20px 20px 20px",
                }}
              >
                {errorImportList.map((row) => {
                  return (
                    <Typography
                      sx={{
                        background: "#ffdddd",
                        borderRadius: "10px",
                        display: "flex",
                        padding: "10px 10px",
                        marginBottom: "10px",
                        fontSize: "12px",
                      }}
                      key={row}
                    >
                      <Iconify
                        icon="eva:info-fill"
                        sx={{
                          fontSize: "15px",
                          color: "#000000",
                          marginTop: "1px",
                        }}
                      />{" "}
                      &nbsp; {row}
                    </Typography>
                  );
                })}
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ padding: "0 30px 20px 30px" }}>
            <Button
              className="btn btn-action-filter-dark"
              style={{
                width: "100%",
                marginTop: "20px",
              }}
              onClick={closeErrorImport}
            >
              Got it
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </React.Fragment>
  );
}
