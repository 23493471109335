import {
  Box,
  Card,
  Container,
  FormControl,
  InputAdornment,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Select from "@mui/material/Select";
import { ModalDelete } from "components/Modal/ModalSimple";
import { SearchStyle, TabPanelHidden, a11yProps } from "components/Panel";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Iconify from "screens/Layout/components/Iconify";
import { updateSchedule } from "service/schedule";
import { getSettingList } from "service/setting";
import {
  updateSpecialistNoticePeriod,
  updateSpecialistStatus,
} from "service/specialist";
import ModalModify from "./components/ModalModify";
import TabActived from "./components/TabActived";
import TabDeleted from "./components/TabDeleted";
import Appbar from "components/Appbar";

export default function MemberSpecialist() {
  // Options Table
  const [filterSearch, setFilterSearch] = useState("");
  const [filterMembership, setFilterMembership] = useState("");

  // Header
  const [listMembership, setListMembership] = useState<any[]>([]);

  // Tab Index
  const [tabValue, setTablIndex] = useState(0);

  // Count Label
  const [activedCount, setActivedCount] = useState(0);
  const [deletedCount, setDeletedCount] = useState(0);

  // Force Update
  const [activedForceUpdate, setActivedForceUpdate] = useState(0);
  const [deletedForceUpdate, setDeletedForceUpdate] = useState(0);

  // Modal
  const [isShowModalModify, setShowModalModify] = useState(false);
  const [isShowModalDeleted, setShowModalDeleted] = useState(false);

  // Additional
  const [currentItem, setCurrentItem] = useState<any>({});

  useEffect(() => {
    getSettingList({
      group: "psycholog_level",
    }).then((val) => {
      setListMembership(val.data ?? []);
    });
  }, []);

  useEffect(() => {
    if (!isShowModalModify && !isShowModalDeleted) {
      setCurrentItem({});
    }
  }, [isShowModalModify, isShowModalDeleted]);

  const onEdit = (item: any) => {
    setCurrentItem(item);
    setShowModalModify(true);
  };

  const onDelete = (item: any) => {
    setCurrentItem(item);

    setShowModalDeleted(true);
  };

  const onConfirmDelete = async () => {
    await updateSpecialistStatus({
      psychologCode: currentItem.psycholog_code,
      status: "inactive",
    });

    setShowModalDeleted(false);

    setActivedForceUpdate(Math.random());
    setDeletedForceUpdate(Math.random());

    toast.success("psychologist already inactive");
  };

  const onSubmit = async (val: any) => {
    const data = {
      psychologCode: currentItem.psycholog_code,
      scheduleCode: currentItem.schedule_code,
      dateRange: val.date_range,
      scheduleDay: val.schedule_day,
      timezone: val.timezone,
      formatCall: "both_call",
      location: ["Google Meet"],
    };

    await updateSchedule(data);
    await updateSpecialistNoticePeriod({
      noticePeriod: val.notice_period,
      psychologCode: currentItem.psycholog_code,
    });

    setActivedForceUpdate(Math.random());

    toast.success("schedule already updated!");
    setShowModalModify(false);
  };

  return (
    <>
      <Container maxWidth="lg">
        <Appbar
          onSearch={(val) => setFilterSearch(val)}
          title="Manage Specialist Availability"
        ></Appbar>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Tabs
              value={tabValue}
              onChange={(_, val) => setTablIndex(val)}
              aria-label="basic tabs example"
            >
              <Tab label={`Actived (${activedCount})`} {...a11yProps(1)} />
              <Tab label={`Deleted (${deletedCount})`} {...a11yProps(2)} />
            </Tabs>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        >
          <FormControl>
            <Select
              displayEmpty
              value={filterMembership}
              onChange={(e) => setFilterMembership(e.target.value)}
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                height: "40px",
                fontSize: "14px",
                fontFamily: "Inter-Medium",
              }}
            >
              <MenuItem value="">
                <em>All Membership</em>
              </MenuItem>
              {listMembership.map((dpr) => (
                <MenuItem key={dpr.set_key} value={dpr.set_key}>
                  <em>{dpr.set_label}</em>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <TabPanelHidden value={tabValue} index={0}>
          <Card>
            <TabActived
              search={filterSearch}
              forceUpdate={activedForceUpdate}
              membership={filterMembership}
              onEdit={onEdit}
              onDelete={onDelete}
              onCount={(val) => setActivedCount(val)}
            />
          </Card>
        </TabPanelHidden>
        <TabPanelHidden value={tabValue} index={1}>
          <Card>
            <TabDeleted
              search={filterSearch}
              forceUpdate={deletedForceUpdate}
              membership={filterMembership}
              onCount={(val) => setDeletedCount(val)}
            />
          </Card>
        </TabPanelHidden>

        <ModalModify
          currentItem={currentItem}
          isShow={isShowModalModify}
          onSubmit={onSubmit}
          toggle={() => setShowModalModify(false)}
        ></ModalModify>

        <ModalDelete
          isOpen={isShowModalDeleted}
          onClose={() => setShowModalDeleted(false)}
          onDelete={onConfirmDelete}
          title="Are you sure want to inactive this psychologist?"
        ></ModalDelete>
      </Container>
    </>
  );
}
