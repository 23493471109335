import React, { useEffect, useState } from 'react';

// Diagram
import { Card } from '@mui/material';
import NoDataIcon from "assets/svg/analytic_nodata.svg";
import { ChartOptions, TooltipItem } from 'chart.js';
import { DoughnutChart } from 'components';
import { Colors } from 'constant';
import { IChangeCallback } from 'interfaces/callback';
import { ISummary } from 'interfaces/voe';
import { Bar } from 'react-chartjs-2';
import service from 'service';
import { utils } from 'utils';

export default function ApplicationAnalytic(props: {
	orgCode: string, departCode: string, industryName: string,
	monthValue: number, yearValue: number;
}) {
	return (
		<>
			<MonthlyScreenAnalytic { ...props }></MonthlyScreenAnalytic>
			<DailyScreenAnalytic { ...props }></DailyScreenAnalytic>
		</>
	);
}

export function DailyScreenAnalytic(props: {
	orgCode: string, departCode: string, industryName: string,
	monthValue: number, yearValue: number, isMinimal?: boolean;
}) {
	const [values, setValues] = useState<number[]>([]);
	const [labels, setLabels] = useState<string[]>([]);
	const [totalUser, setTotalUser] = useState(0);
	const [totalDownload, setTotalDownload] = useState(0);
	const [average, setAverage] = useState(0);
	const [summary, setSummary] = useState<ISummary[]>([]);
	const [stepSize, setStepSize] = useState(0);
	const isMinimal = props.isMinimal ?? false;

	const ticksCallback = (val: number | string) => {
		return normalizeSeconds(Number(val));
	};

	const tooltipCallback = (tooltipItem: TooltipItem<"bar">) => {
		return normalizeSeconds(Number(tooltipItem.raw));
	};

	const initAnalytic = async () => {
		const resp = await service.getActiveScreen({
			departCode: props.departCode,
			industry: props.industryName,
			orgCode: props.orgCode,
			month: props.monthValue,
			year: props.yearValue,
		});
		const count = resp.pagination.count ?? 0;
		if (count === 0 || resp.data.length === 0) {
			setTotalUser(0);
			setSummary([]);
			setValues([]);
			setLabels([]);
		} else {
			const dailyData = splitChartDaily(resp.data);
			const summary = splitSummaryDaily(resp.data);

			const values = dailyData.values;
			const userCount = dailyData.memberCount;
			const labels: string[] = [];
			for (let index = 1; index <= values.length; index++) {
				labels.push(`${index}`);
			}

			let count = 0;
			let maxVal = 0;
			let unique = {};
			for (const item of resp.data) {
				if (item.second !== 0) {
					let d = new Date(item.date).getDate();

					unique[d] = true;
					count += item.second;
				}

				if (maxVal < item.second) {
					maxVal = item.second;
				}
			}

			const average = count / Object.keys(unique).length;

			setStepSize(Math.ceil(maxVal / 8));
			setSummary(summary);
			setAverage(average);
			setTotalUser(userCount);
			setValues(values);
			setLabels(labels);
		}
	};

	useEffect(() => {
		initAnalytic();
	}, [props]);

	return <Content
		{ ...props }
		tooltipCallback={ tooltipCallback }
		showAverage={ true }
		ticksCallback={ ticksCallback }
		stepSize={ stepSize }
		totalUser={ totalUser }
		totalDownload={ totalDownload }
		yLabel='Screen time per user'
		average={ average }
		labels={ labels }
		values={ values }
		summary={ summary }
		title="Daily Screen Time"
		isMinimal={ isMinimal }
	></Content>;
}

export function MonthlyScreenAnalytic(props: {
	orgCode: string, departCode: string, industryName: string,
	monthValue: number, yearValue: number, isMinimal?: boolean;
}) {
	// Local State
	const [values, setValues] = useState<number[]>([]);
	const [labels, setLabels] = useState<string[]>([]);
	const [summary, setSummary] = useState<ISummary[]>([]);
	const [totalUser, setTotalUser] = useState(0);
	const [totalDownload, setTotalDownload] = useState(0);
	const [stepSize, setStepSize] = useState(0);
	const isMinimal = props.isMinimal ?? false;

	const ticksCallback = (val: number | string) => {
		return `${val}`;
	};

	const initAnalytic = async () => {
		const respSummary = await service.getActiveUser({
			departCode: props.departCode,
			industry: props.industryName,
			month: props.monthValue,
			year: props.yearValue,
			orgCode: props.orgCode,
		});
		const resp = await service.getActiveUserList({
			departCode: props.departCode,
			industry: props.industryName,
			month: props.monthValue,
			year: props.yearValue,
			orgCode: props.orgCode,
		});
		const countData = resp.pagination.count ?? 0;
		if (countData === 0 || resp.data.length === 0) {
			setTotalUser(0);
			setSummary([]);
			setValues([]);
			setLabels([]);
		} else {
			const values = splitChartMonthly(resp.data);
			const summary = splitSummaryMonthly(respSummary.data);

			const labels: string[] = [];
			for (let index = 1; index <= values.length; index++) {
				labels.push(`${index}`);
			}

			let maxActive = 0;
			for (const item of resp.data) {
				if (maxActive < item.total_active_user) {
					maxActive = item.total_active_user;
				}
			}

			setStepSize(Math.round(maxActive / 4));
			setSummary(summary);
			setTotalUser(summary[0].values[0]);
			setTotalDownload(respSummary.data.total_inactive_user);
			setValues(values);
			setLabels(labels);
		}
	};

	useEffect(() => {
		initAnalytic();
	}, [props]);

	return <Content
		{ ...props }
		showAverage={ false }
		ticksCallback={ ticksCallback }
		stepSize={ stepSize }
		totalUser={ totalUser }
		totalDownload={ totalDownload }
		yLabel='Daily Active Users'
		labels={ labels }
		values={ values }
		summary={ summary }
		title="Downloads & MAU"
		isMinimal={ isMinimal }
	></Content>;
}

function Content(props: {
	tooltipCallback?: IChangeCallback<TooltipItem<"bar">, string>,
	showAverage: boolean,
	ticksCallback: IChangeCallback<number | string, string>,
	stepSize: number,
	totalUser: number,
	totalDownload: number,
	average?: number,
	yLabel: string,
	labels: string[],
	values: number[],
	summary: ISummary[],
	title: string, orgCode: string, departCode: string,
	industryName: string;
	isMinimal: boolean;
}) {
	const averageStr = normalizeSeconds(props.average ?? 0);
	const optionsBar: ChartOptions<"bar"> = {
		responsive: true,
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				callbacks: {
					label: props.tooltipCallback
				}
			},
			datalabels: {
				display: false,
			}
		},
		scales: {
			x: {
				grid: {
					display: false,
				},
			},
			y: {
				ticks: {
					stepSize: props.stepSize,
					callback: (val) => props.ticksCallback(val),
				}
			}
		}
	};
	const dataBar = {
		labels: props.labels,
		datasets: [{
			label: '',
			data: props.values,
			backgroundColor: [Colors.chart.getColor(5)],
		}]
	};

	return (
		<Card className='mb-8'>
			<div className='bg-slate-100 py-4 pl-4 flex justify-between'>
				<div className='font-bold text-xl pt-2'>{ props.title }</div>
			</div>
			{
				props.totalUser > 0 ?
					<div className='px-16 pb-16 pt-6'>
						<div className='flex'>
							<div className='w-80 shrink-0'>
								{
									props.showAverage ?
										<div className='flex'>
											<div className='mr-10'>
												<div className='mb-5 whitespace-nowrap'>Total Users</div>
												<div className='font-semibold text-lg text-blue-500'>{ props.totalUser } Users</div>
											</div>
											<div>
												<div className='mb-5 whitespace-nowrap'>Daily Screen Time (Average)</div>
												<div className='font-semibold text-lg text-blue-500'>{ averageStr }</div>
											</div>
										</div>
										:
										<div className='flex'>
											<div className='mr-10'>
												<div className='mb-5 whitespace-nowrap'>Total Downloads</div>
												<div className='font-semibold text-lg text-blue-500'>{ props.totalDownload } Users</div>
											</div>
											<div>
												<div className='mb-5 whitespace-nowrap'>Total MAU</div>
												<div className='font-semibold text-lg text-blue-500'>{ props.totalUser } Users</div>
											</div>
										</div>
								}
								<div className='mt-14'>
									<div>Sumary Breakdown</div>
									<div className='flex justify-center'>
										{
											props.summary.map((item, i) => {
												return (
													<div className='w-28 relative' key={ `summary-${i}` }>
														<DoughnutChart colors={ [Colors.chart.getColor(5), Colors.grey.light] } showLegend={ false } labels={ ["Users", "Users"] } values={ item.values }></DoughnutChart>
														<div className='absolute top-1/4 left-10 mt-1 font-semibold w-8 text-center'>{ Math.round(item.values[0] / (item.values[0] + item.values[1]) * 100) }%</div>
														<div className='text-center h-12'>{ item.title }</div>
													</div>
												);
											})
										}
									</div>
								</div>
							</div>
							{
								!props.isMinimal ? (
									<>
										<div className='mx-16 bg-slate-300'>
											<div style={ { width: "1px" } }></div>
										</div>
										<div className='relative shrink-0 w-4'>
											<div
												className='absolute left-0 top-1/2 w-full whitespace-nowrap'
												style={ { transform: 'rotate(-90deg) translateY(-24px) translateX(-10px)' } }
											>{ props.yLabel }</div>
										</div>
										<div className='flex-grow'>
											<Bar options={ optionsBar } data={ dataBar }></Bar>
											<div className='mt-6 text-center'>Days of the Month</div>
										</div>
									</>
								) : null
							}
						</div>
					</div>
					:
					<div className='py-28 text-center'>
						<img className='mx-auto' src={ NoDataIcon } alt="" />
						<div className='text-center text-lg mt-10'>Sorry, no data is available</div>
					</div>
			}
		</Card>
	);
}

function normalizeSeconds(seconds: number) {
	const averageMinutes = Math.floor(seconds / 60);
	const averageHour = Math.floor(averageMinutes / 60);
	const averageStr = `${averageHour}h ${averageMinutes % 60}m`;

	return averageStr;
}

// Doesn't use javascript Date because not supported date
const splitChartDaily = (data: any[]) => {
	const lastDate = utils.lastDate();
	const temp: number[] = Array(lastDate).fill(0);
	const tempCount: number[] = Array(lastDate).fill(0);
	const member: any = {};

	for (const item of data) {
		const itemDate = new Date(item.date).getDate();
		const val = item.second as number;

		member[item.member_id] = 1;

		temp[itemDate] += val;
		tempCount[itemDate]++;
	}

	for (let i = 0; i < lastDate; i++) {
		if (tempCount[i] > 0) {
			temp[i] = Math.round(temp[i] / tempCount[i]);
		}
	}

	return {
		memberCount: Object.keys(member).length,
		values: temp,
	};
};

const splitChartMonthly = (data: any[]) => {
	const lastDate = utils.lastDate();
	const temp = Array(lastDate).fill(0);

	for (const item of data) {
		const itemDay = Number(item.date.split('/')[0]);

		temp[itemDay - 1] = item.total_active_user;
	}

	return temp;
};

const splitSummaryDaily = (data: any[]) => {
	const temp: ISummary[] = [
		{
			title: "<5 min",
			values: [0, 0],
		}, {
			title: "5-30 min",
			values: [0, 0],
		}, {
			title: ">30 min",
			values: [0, 0],
		}
	];

	const fiveMin = 5 * 60;
	const halfHour = 30 * 60;

	let count = 0;
	for (const item of data) {
		count++;

		if (item.second < fiveMin) {
			temp[0].values[0]++;
		} else if (item.second < halfHour) {
			temp[1].values[0]++;
		} else {
			temp[2].values[0]++;
		}
	}

	for (const item of temp) {
		item.values[1] = count - item.values[0];
	}

	return temp;
};

const splitSummaryMonthly = (data: any) => {
	const totalActive = data.total_active_user;
	const totalInactive = data.total_inactive_user;
	const temp: ISummary[] = [
		{
			title: "Active",
			values: [totalActive, totalInactive],
		}, {
			title: "Inactive",
			values: [totalInactive, totalActive],
		},
	];

	return temp;
};
