import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Select from "@mui/material/Select";
import {
  ModalBanned,
  ModalInvite,
  ModalRestore,
} from "components/Modal/ModalSimple";
import { a11yProps, SearchStyle, TabPanelHidden } from "components/Panel";
import Compress from "compress.js";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Iconify from "screens/Layout/components/Iconify";
import { addSpecialist, updateSpecialist } from "service/member";
import { getSettingList } from "service/setting";
import {
  getSpecialistTitleList,
  invitationSpecialist,
} from "service/specialist";
import { getSpecialistTargetList } from "service/target";
import { editMembers, send } from "store/members";
import { addUploadImage } from "store/uploadimages";
import ContentModal from "./components/ContentModal";
import TabActived from "./components/TabActived";
import TabDeleted from "./components/TabDeleted";
import Images from "constant/Images";
import ModalInvitation from "components/Modal/ModalInvitation";
import Appbar from "components/Appbar";
import { generateSchedules } from "service/schedule";

const listStatus = [
  {
    value: "active",
    label: "Active",
  },
  {
    value: "inactive",
    label: "Inactive",
  },
];
export default function MemberSpecialist() {
  // Options Table
  const [filterSearch, setFilterSearch] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [filterMembership, setFilterMembership] = useState("");
  const [filterSelected, setFilterSelected] = useState<any[]>([]);

  // Header
  const [listMembership, setListMembership] = useState<any[]>([]);
  const [listCountry, setListCountry] = useState<any[]>([]);
  const [listGender, setListGender] = useState<any[]>([]);
  const [listSpecialistTarget, setListSpecialistTarget] = useState<any[]>([]);
  const [listSpecialistTitle, setListSpecialistTitle] = useState<any[]>([]);

  // Tab Index
  const [tabValue, setTablIndex] = useState(0);

  // Count Label
  const [activedCount, setActivedCount] = useState(0);
  const [deletedCount, setDeletedCount] = useState(0);

  // Force Update
  const [activedForceUpdate, setActivedForceUpdate] = useState(0);
  const [deletedForceUpdate, setDeletedForceUpdate] = useState(0);

  // Modal
  const [isShowModalModify, setShowModalModify] = useState(false);

  // Additional
  const [isAddContent, setAddContent] = useState(false);
  const [currentItem, setCurrentItem] = useState<any>({});
  const [successType, setSuccessType] = useState("");

  const [isShowDelete, setShowDelete] = useState(false);
  const [isShowUnbanned, setShowUnbanned] = useState(false);
  const [isShowDeleteAll, setShowDeleteAll] = useState(false);
  const [isShowInviteAll, setShowInviteAll] = useState(false);
  const [isShowInvite, setShowInvite] = useState(false);

  useEffect(() => {
    getSettingList({
      group: "psycholog_level",
    }).then((val) => {
      setListMembership(val.data ?? []);
    });

    getSettingList({
      group: "gender",
    }).then((val) => {
      setListGender(val.data ?? []);
    });

    getSpecialistTargetList({
      isActive: true,
    }).then((val) => {
      setListSpecialistTarget(val.data ?? []);
    });

    getSpecialistTitleList({
      isActive: true,
      lang: "en",
    }).then((val) => {
      setListSpecialistTitle(val.data ?? []);
    });
  }, []);

  useEffect(() => {
    if (
      !isShowModalModify &&
      !isShowDelete &&
      !isShowUnbanned &&
      !isShowInvite
    ) {
      setAddContent(true);
      setCurrentItem({});
    }
  }, [isShowModalModify, isShowDelete, isShowUnbanned, isShowInvite]);

  useEffect(() => {
    if (!isShowDeleteAll && !isShowInviteAll) {
      setFilterSelected([]);
    }
  }, [isShowDeleteAll, tabValue, isShowInviteAll]);

  const formatDate = (date) => {
    // eslint-disable-next-line prefer-const
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      // eslint-disable-next-line prefer-const
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const onSelectedItem = (val: any) => {
    const index = filterSelected.findIndex(
      (item) => item.member_code === val.member_code
    );
    if (index === -1) {
      return setFilterSelected([...filterSelected, val]);
    }

    filterSelected.splice(index, 1);
    setFilterSelected([...filterSelected]);
  };

  const onSelectedAll = (values: any[]) => {
    let unselect = true;
    for (const val of values) {
      const index = filterSelected.findIndex(
        (item) => item.member_code === val.member_code
      );

      if (index === -1) {
        filterSelected.push(val);
        unselect = false;
      }
    }

    if (unselect) {
      setFilterSelected([]);
    } else {
      setFilterSelected([...filterSelected]);
    }
  };

  const onAddSpecialist = () => {
    setAddContent(true);
    setShowModalModify(true);
  };

  const onEdit = (item: any) => {
    setCurrentItem(item);
    setShowModalModify(true);
    setAddContent(false);
  };

  const onDelete = (item: any) => {
    setCurrentItem(item);

    setShowDelete(true);
  };

  const onBannedAllMember = () => {
    setShowDeleteAll(true);
  };

  const onConfirmDelete = async () => {
    const payload = {
      member_code: currentItem.member_code,
      organization_code: currentItem.organization_code,
      department_code: "",
      status: "inactive",
      role_name: currentItem.role_name,
    };

    await editMembers({ body: payload });

    setShowDelete(false);
    setActivedForceUpdate(Math.random());
    setDeletedForceUpdate(Math.random());

    toast.success("member already banned");
  };

  const onConfirmDeleteAll = async () => {
    for (const item of filterSelected) {
      const payload = {
        member_code: item.member_code,
        organization_code: item.organization_code,
        department_code: "",
        status: "inactive",
        role_name: item.role_name,
      };

      await editMembers({ body: payload });
    }

    setShowDeleteAll(false);
    setActivedForceUpdate(Math.random());
    setDeletedForceUpdate(Math.random());

    toast.success("all selected member already banned");
  };

  const onInviteAllMember = () => {
    setShowInviteAll(true);
  };

  const onInviteAllAction = async () => {
    const invitations: { psycholog_code: string }[] = [];
    for (const item of filterSelected) {
      if (item.invitation_email !== "") {
        invitations.push({
          psycholog_code: item.member_code,
        });
      }
    }

    await invitationSpecialist({ invitations });

    setShowInviteAll(false);
    setActivedForceUpdate(Math.random());
    toast.success("Send Invitation Code Success!");
  };

  const onInvitation = (item: any) => {
    setCurrentItem(item);
    setShowInvite(true);
  };

  const onInvitationAction = async () => {
    await invitationSpecialist({
      invitations: [
        {
          psycholog_code: currentItem.member_code,
        },
      ],
    });
    setShowInvite(false);
    setActivedForceUpdate(Math.random());
    toast.success("Send Invitation Code Success!");
  };

  const onUnbanned = (item: any) => {
    setCurrentItem(item);

    setShowUnbanned(true);
  };

  const onConfirmUnbanned = async () => {
    const payload = {
      member_code: currentItem.member_code,
      organization_code: currentItem.organization_code,
      department_code: "",
      status: "active",
      role_name: currentItem.role_name,
    };

    await editMembers({ body: payload });

    setShowUnbanned(false);

    setActivedForceUpdate(Math.random());
    setDeletedForceUpdate(Math.random());

    toast.success("member already actived");
  };

  const onSubmit = async (val: any) => {
    let profile = val.profile_url;
    if (val.files.length > 0) {
      const compress = new Compress();
      const blobImg = await compress.compress(val.files, {
        size: 0.8, // the max size in MB, defaults to 2MB
        quality: 0.75, // the quality of the image, max is 1,
      });
      const newFile = Compress.convertBase64ToFile(
        blobImg[0].data,
        blobImg[0].ext
      );

      profile = (await addUploadImage({ body: newFile })) as any;
      if (profile[0].stat_code === "ERR:BAD_REQUEST") {
        return profile[0].stat_msg;
      }
    }

    // HIT API Add Org
    const bank_account_code = currentItem.bank_account_code;
    const bank_name = val.bank_name;
    const account_number = val.account_number;
    const account_holder = val.account_holder;
    const memberCode = val.member_code;
    const username = val.username;
    const email = val.email;
    const birthdate = formatDate(val.birthdate);
    const birthyear = val.birthyear;
    const gender = val.gender;
    const phone = val.phone;
    const membership_name = val.membership;
    const profile_url = profile;
    const specialist_type = "ind";
    const role_name = val.roleName;
    const status = val.status;
    // TODO: Change is_verified as optional on API
    // const is_verified = val.isVerified;
    const is_verified = true;
    const country = val.country;
    const body = {
      member_code: memberCode,
      username,
      email,
      birthdate,
      birthyear,
      gender,
      phone,
      membership_name,
      specialist_type,
      bio: val.biography,
      language: val.language,
      location: val.location,
      year_of_experience: val.yearExperience,
      specialist_rate_code: currentItem.specialist_rate_code,
      specialist_price: val.specialistPrice,
      specialist_fee: val.specialistFee,
      introduction_video_url: val.introduction_video_url,
      target_users: val.target_users,
      specialist_title_code: val.specialist_title_code,
      profile_url,
      status,
      role_name,
      is_verified,
      country,
      bank_account_code,
      bank_name,
      account_number,
      account_holder,

      approaches: val.approaches,
      registrations: val.registrations,
      expertises: val.expertises,
      credentials: val.credentials,
      licenses: val.licenses,
      universities: val.universities,
    };

    if (!isAddContent) {
      await updateSpecialist(body);
    } else {
      const data = await addSpecialist(body).then((val) => val.data);
      if (data) {
        await generateSchedules({ memberCode: data.member_code });
      }
    }

    setShowModalModify(false);
    setActivedForceUpdate(Math.random());

    if (isAddContent) {
      toast.success("new specialist already added");
    } else {
      toast.success("specialist already updated");
    }
  };

  return (
    <>
      <Container maxWidth="lg">
        <Appbar
          onSearch={(val) => setFilterSearch(val)}
          title="Manage Specialist"
        ></Appbar>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Tabs
              value={tabValue}
              onChange={(_, val) => setTablIndex(val)}
              aria-label="basic tabs example"
            >
              <Tab label={`Actived (${activedCount})`} {...a11yProps(1)} />
              <Tab label={`Inactived (${deletedCount})`} {...a11yProps(2)} />
            </Tabs>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            margin: "0",
            paddingTop: "20px",
            paddingBottom: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            {/* <FormControl>
              <Select
                displayEmpty
                value={filterStatus}
                onChange={(e) => setFilterStatus(e.target.value)}
                input={<OutlinedInput />}
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  height: "40px",
                  fontSize: "14px",
                  fontFamily: "Inter-Medium",
                }}
              >
                <MenuItem value="">
                  <em>All Status</em>
                </MenuItem>
                {listStatus.map((status) => (
                  <MenuItem key={status.label} value={status.value}>
                    <em>{status.label}</em>
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
            {/* <FormControl style={{ marginLeft: "16px" }}> */}
            <FormControl>
              <Select
                displayEmpty
                value={filterMembership}
                onChange={(e) => setFilterMembership(e.target.value)}
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  height: "40px",
                  fontSize: "14px",
                  fontFamily: "Inter-Medium",
                }}
              >
                <MenuItem value="">
                  <em>All Membership</em>
                </MenuItem>
                {listMembership.map((dpr) => (
                  <MenuItem key={dpr.set_key} value={dpr.set_key}>
                    <em>{dpr.set_label}</em>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box
            sx={{
              flex: 1,
              textAlign: "right",
            }}
          >
            {filterSelected.length > 0 ? (
              <>
                <Button
                  className="btn-action-filter-light"
                  sx={{ width: "200px" }}
                  onClick={onInviteAllMember}
                  endIcon={
                    <img
                      className="!h-5 !w-5 pl-4"
                      src={Images.btn_send}
                      style={{ width: "24px" }}
                    />
                  }
                >
                  Email Invitation
                </Button>
                <Button
                  className="btn-action-filter-light"
                  sx={{ width: "200px" }}
                  onClick={onBannedAllMember}
                  endIcon={
                    <img
                      className="!h-5 !w-5 pl-4"
                      src={Images.btn_flag}
                      style={{ width: "24px" }}
                    />
                  }
                >
                  Ban Member
                </Button>
              </>
            ) : null}
            <Button
              className="btn-action-filter-dark-w-250"
              onClick={onAddSpecialist}
              endIcon={<Iconify icon="eva:plus-fill" sx={{}} />}
            >
              Add New Specialist
            </Button>
          </Box>
        </Box>

        <TabPanelHidden value={tabValue} index={0}>
          <Card>
            <TabActived
              search={filterSearch}
              forceUpdate={activedForceUpdate}
              membership={filterMembership}
              status={filterStatus}
              onEdit={onEdit}
              onDelete={onDelete}
              onCount={(val) => setActivedCount(val)}
              onSelected={onSelectedItem}
              onSelectedAll={onSelectedAll}
              selectedItems={filterSelected}
              onInvitation={onInvitation}
            />
          </Card>
        </TabPanelHidden>
        <TabPanelHidden value={tabValue} index={1}>
          <Card>
            <TabDeleted
              search={filterSearch}
              forceUpdate={deletedForceUpdate}
              membership={filterMembership}
              onUnbanned={onUnbanned}
              onCount={(val) => setDeletedCount(val)}
            />
          </Card>
        </TabPanelHidden>

        <ContentModal
          currentItem={currentItem}
          isShow={isShowModalModify}
          isEdit={!isAddContent}
          successType={successType}
          listCountry={listCountry}
          listGender={listGender}
          listSpecialistTarget={listSpecialistTarget}
          listSpecialistTitle={listSpecialistTitle}
          onSubmit={onSubmit}
          toggle={() => setShowModalModify(false)}
        ></ContentModal>

        <ModalBanned
          isOpen={isShowDelete}
          onClose={() => setShowDelete(false)}
          onDelete={onConfirmDelete}
        ></ModalBanned>

        <ModalBanned
          title="Are you sure banned all selected specialists?"
          isOpen={isShowDeleteAll}
          onClose={() => setShowDeleteAll(false)}
          onDelete={onConfirmDeleteAll}
        ></ModalBanned>

        <ModalRestore
          isOpen={isShowUnbanned}
          onClose={() => setShowUnbanned(false)}
          onChange={onConfirmUnbanned}
        ></ModalRestore>

        <ModalInvite
          isOpen={isShowInviteAll}
          onClose={() => setShowInviteAll(false)}
          onClick={onInviteAllAction}
        ></ModalInvite>

        <ModalInvite
          title=""
          isOpen={isShowInvite}
          onClose={() => setShowInvite(false)}
          onClick={onInvitationAction}
        ></ModalInvite>
      </Container>
    </>
  );
}
