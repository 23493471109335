import service from "service";
import { dispatch } from "store";

export const JobsActionType = {
	RefreshJobs: "REFRESH_JOBS",
};

export const refreshJobs = async () => {
	let data = [];

	try {
		const resp = await service.getJobs({ compact: true });

		data = resp.data ?? [];
	} finally {
		dispatch({
			type: JobsActionType.RefreshJobs,
			payload: data,
		});
	}
};
