import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import {
  Box,
  Button,
  Container,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Iconify from "screens/Layout/components/Iconify";

// Chart JS
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";

// Export PDF
import { Orgs } from "interfaces";
import pdf from "service/pdf";
import api from "service/api";
import { Endpoints } from "constant";
import filter from "helpers/filter";
import { SatisfactionAnalytic } from "./components/AssessmentSatisfaction";
import { PersonalDistressAnalytic } from "./components/AssessmentDistress";
import { EngageAnalytic } from "./components/AssessmentEngagement";
import { PersonalWellbeingAnalytic } from "./components/AssessmentWellbeing";
import { a11yProps, TabPanel } from "components/Panel";
import { getMonths, getYears } from "constant/Date";
import Appbar from "components/Appbar";

export default function Assessment() {
  const currentDate = new Date();
  const months = getMonths();
  const years = getYears();

  // Local State
  const [isExport, setIsExport] = useState(false);
  const [orgValue, setOrgValue] = useState("");
  const [industryValue, setIndustryValue] = useState("");
  const [departementValue, setDepartementValue] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [sourcesOrgList, setSourceOrgList] = useState<any[]>([]);
  const [industryList, setIndustryList] = useState<string[]>([]);

  // Date
  const [monthValue, setMonthValue] = useState(currentDate.getMonth() + 1);
  const [yearValue, setYearValue] = useState(currentDate.getFullYear());

  // Reference
  const refContent = useRef<HTMLDivElement>(null);

  // Local Value
  const orgList: Orgs.Detail[] = [];
  const departList: Orgs.Detail[] = [];

  sourcesOrgList.forEach((item) => {
    if (item.industry_name == industryValue) {
      orgList.push(item);
    }
  });
  sourcesOrgList.forEach((item) => {
    if (item.organization_code == orgValue) {
      departList.push(item);
    }
  });

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const onInit = async () => {
    const resp = await api.get(`${Endpoints.org}`, {
      params: {
        page: 1,
        limit: 50,
        order: "asc",
        keyword: "",
      },
    });
    const respData = resp.data.data as any[];
    const industry = filter.industry(respData);

    setIndustryList(industry);
    setSourceOrgList(respData);
  };

  useEffect(() => {
    onInit();
  }, []);

  useLayoutEffect(() => {
    ChartJS.register(
      ArcElement,
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      BarElement,
      Title,
      Tooltip,
      Legend
    );
  });

  const onChangeIndustry = (val: string) => {
    setOrgValue("");
    setDepartementValue("");
    setIndustryValue(val);
  };

  const onDownloadPDF = async () => {
    setIsExport(true);

    const content = refContent.current!;
    const label = [
      "Work Satisfaction",
      "Work Engagement",
      "Personal Wellbeing",
      "Personal Distress",
    ][tabValue];

    await pdf(content, "Assessment Analytic " + label);

    setIsExport(false);
  };

  return (
    <div className="pb-6" ref={refContent}>
      <Container>
        <Appbar title="Screening Report"></Appbar>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                key={"satisfaction"}
                label={"Works Satisfaction"}
                {...a11yProps(0)}
              />
              <Tab
                key={"engagement"}
                label={"Work Engagement"}
                {...a11yProps(1)}
              />
              <Tab
                key={"wellbeing"}
                label={"Personal Wellbeing"}
                {...a11yProps(2)}
              />
              <Tab
                key={"distress"}
                label={"Personal Distress"}
                {...a11yProps(3)}
              />
            </Tabs>
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            margin: "0",
            paddingTop: "20px",
            paddingBottom: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="flex mr-5 gap-5">
            <FormControl
              sx={{
                m: 0,
                width: 160,
              }}
            >
              <Select
                displayEmpty
                input={<OutlinedInput style={{ paddingBottom: "8px" }} />}
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  height: "40px",
                  fontSize: "14px",
                  fontFamily: "Inter-Medium",
                  backgroundColor: "white",
                }}
                value={industryValue}
                onChange={(val) => onChangeIndustry(val.target.value)}
              >
                <MenuItem value={""}>
                  <ListItemText primary={"All Industry"} />
                </MenuItem>
                {industryList.map((item, index) => {
                  return (
                    <MenuItem key={`industry-${index}`} value={item}>
                      <ListItemText primary={item} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl
              sx={{
                m: 0,
                width: 160,
              }}
            >
              <Select
                displayEmpty
                input={<OutlinedInput style={{ paddingBottom: "8px" }} />}
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  height: "40px",
                  fontSize: "14px",
                  fontFamily: "Inter-Medium",
                  backgroundColor: "white",
                }}
                value={orgValue}
                onChange={(val) => setOrgValue(val.target.value)}
              >
                <MenuItem value={""}>
                  <ListItemText primary={"All Organization"} />
                </MenuItem>
                {orgList.map((item, index) => {
                  return (
                    <MenuItem
                      key={`org-${index}`}
                      value={item.organization_code}
                    >
                      <ListItemText primary={item.organization_name} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl
              sx={{
                m: 0,
                width: 160,
              }}
            >
              <Select
                displayEmpty
                input={<OutlinedInput style={{ paddingBottom: "8px" }} />}
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  height: "40px",
                  fontSize: "14px",
                  fontFamily: "Inter-Medium",
                  backgroundColor: "white",
                }}
                value=""
              >
                <MenuItem value={""}>
                  <ListItemText primary={"All Departement"} />
                </MenuItem>
                {departList.map((item, index) => {
                  return (
                    <MenuItem
                      key={`depart-${index}`}
                      value={item.departement_code}
                    >
                      <ListItemText primary={item.departement_name} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl
              sx={{
                m: 0,
                width: 150,
              }}
            >
              <Select
                displayEmpty
                input={<OutlinedInput style={{ paddingBottom: "8px" }} />}
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  height: "40px",
                  fontSize: "14px",
                  fontFamily: "Inter-Medium",
                  backgroundColor: "white",
                }}
                value={monthValue}
                onChange={(val) => setMonthValue(Number(val.target.value))}
              >
                {months.map((val, i) => {
                  return (
                    <MenuItem key={`month-${i}`} value={val.monthNumber}>
                      <ListItemText primary={val.monthName} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl
              sx={{
                m: 0,
                width: 96,
              }}
            >
              <Select
                displayEmpty
                input={<OutlinedInput style={{ paddingBottom: "8px" }} />}
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  height: "40px",
                  fontSize: "14px",
                  fontFamily: "Inter-Medium",
                  backgroundColor: "white",
                }}
                value={yearValue}
                onChange={(val) => setYearValue(Number(val.target.value))}
              >
                {years.map((val, i) => {
                  return (
                    <MenuItem key={i} value={val}>
                      <ListItemText primary={val} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          {!isExport ? (
            <Box
              sx={{
                flex: 3,
                textAlign: "right",
              }}
            >
              <Button
                onClick={onDownloadPDF}
                className="btn-action-filter-light"
                endIcon={<Iconify icon="eva:plus-fill" sx={{}} />}
              >
                Export PDF
              </Button>
            </Box>
          ) : (
            <></>
          )}
        </Box>

        <TabPanel value={tabValue} index={0}>
          <SatisfactionAnalytic
            departCode={departementValue}
            industryName={industryValue}
            orgCode={orgValue}
            monthValue={monthValue}
            yearValue={yearValue}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <EngageAnalytic
            departCode={departementValue}
            industryName={industryValue}
            orgCode={orgValue}
            monthValue={monthValue}
            yearValue={yearValue}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <PersonalWellbeingAnalytic
            departCode={departementValue}
            industryName={industryValue}
            orgCode={orgValue}
            monthValue={monthValue}
            yearValue={yearValue}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <PersonalDistressAnalytic
            departCode={departementValue}
            industryName={industryValue}
            orgCode={orgValue}
            monthValue={monthValue}
            yearValue={yearValue}
          />
        </TabPanel>
      </Container>
    </div>
  );
}
