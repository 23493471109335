import React from 'react';
import { Stack, TableCell, TableRow, Typography } from '@mui/material';
import { Images } from 'constant';
import Button from '@mui/material/Button';

export default function BannedWordRow(props: {
	row: any;
	onRepost: () => void;
}) {
	let { id, words } = props.row;

	const contentStr = words.length > 64 ? words.substring(0, 64) + ' ...' : words;

	return (
		<TableRow key={ id } hover>

			<TableCell component='th' scope='row' padding='normal'>
				<Stack direction='row' alignItems='center' spacing={ 2 }>
					<Typography variant='subtitle2' sx={ {
						flexWrap: 'wrap',
						width: '250px',
						fontFamily: 'Fonts.interMedium'
					} }>
						{ contentStr }
					</Typography>
				</Stack>
			</TableCell>

			<TableCell align='left' >
				<Typography variant='subtitle2' noWrap sx={ {
					fontFamily: 'Fonts.interRegular',
					width: '100px'
				} }>
					<Button onClick={ props.onRepost } sx={ { minWidth: 'auto !important' } }>
						<img src={ Images.btn_repost_act } style={ { width: '30px' } } />
					</Button>
				</Typography>
			</TableCell>
		</TableRow>
	);
}
