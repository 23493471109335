export enum OrgsActionTypes {
  GET_ORGS_LIST_LOADING = 'GET_ORGS_LIST_LOADING',
  GET_ORGS_LIST_SUCCESS = 'GET_ORGS_LIST_SUCCESS',
  GET_ORGS_LIST_ERROR = 'GET_ORGS_LIST_ERROR',

  ADD_ORGS_LOADING = 'ADD_ORGS_LOADING',
  ADD_ORGS_SUCCESS = 'ADD_ORGS_SUCCESS',
  ADD_ORGS_ERROR = 'ADD_ORGS_ERROR',

  
}