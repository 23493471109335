import {
	Box,
	Table, TableBody, TableContainer, TablePagination, Typography
} from '@mui/material';
import React, {
	useEffect,
	useState
} from 'react';

import UserRow from "sections/@dashboard/flag/chat";

import useTable from 'components/Table/useTable';

import { TableEmptyRows, TableHeadCustom } from 'components/Table';

const TABLE_HEAD = [
	{
		id: 'user',
		label: 'USER',
		alignRight: false
	},
	{
		id: 'reasons',
		label: 'REASONS',
		alignRight: false
	},
	{
		id: 'context',
		label: 'CONTEXT',
		alignRight: false
	},
	{
		id: 'flagged_by',
		label: 'FLAGGED BY'
	},
	{
		id: 'preview',
		label: 'PREVIEW'
	}
];

export default function OutstandingTab() {
	const [data, setData] = useState<any[]>([]);
	const [pagination, setPagination] = useState<any>({});
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('user');
	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const {
		dense,
		page,
		rowsPerPage,
		selected,
		onSelectRow,
		onSort,
	} = useTable();

	const onChangeRowPerPage = (val) => {
		console.log("Change Row per Page", val);
	};

	const onChangePage = (val) => {
		console.log("Change Page", val);
	};

	const onPreview = (row: any) => {
		console.log("Row", row);
	};

	useEffect(() => {
		setData([
			{
				id: 1,
				chat: "The dangerous chat",
				reason: "Offensive",
				context: "Specialist Chat",
				flagged_by: "Annisa Axelta",
				comment_by: "Axelta Annisa"
			}, {
				id: 2,
				chat: "The dangerous chat",
				reason: "Offensive",
				context: "Specialist Chat",
				flagged_by: "Annisa Axelta",
				comment_by: "Axelta Annisa"
			}, {
				id: 3,
				chat: "The dangerous chat",
				reason: "Offensive",
				context: "Specialist Chat",
				flagged_by: "Annisa Axelta",
				comment_by: "Axelta Annisa"
			},
		]);
	}, []);

	return (
		<div>
			<TableContainer sx={ {
				minWidth: 800,
				position: 'relative'
			} }>

				<Table size={ dense ? 'small' : 'medium' }>
					<TableHeadCustom
						order={ order }
						orderBy={ orderBy }
						onRequestSort={ handleRequestSort }
						headLabel={ TABLE_HEAD }
						rowCount={ data.length }
						numSelected={ selected.length }
						onSort={ onSort }
					/>

					<TableBody>
						{
							data.map((row, index) => (
								<UserRow
									key={ index }
									row={ row }
									onPreview={ () => onPreview(row) }
								/>
							))
						}

						<TableEmptyRows height={ dense ? 52 : 72 } emptyRows={ data.length } />

					</TableBody>
				</Table>
			</TableContainer>

			<Box sx={ { position: 'relative' } }>
				<TablePagination
					rowsPerPageOptions={ [10, 25, 100] }
					component='div'
					labelRowsPerPage='Items per page: '
					count={ pagination?.count || 0 }
					rowsPerPage={ pagination?.limit || 10 }
					page={ pagination?.page - 1 || 0 }
					onPageChange={ (_, pg) => onChangePage(pg) }
					onRowsPerPageChange={ (e) => onChangeRowPerPage(e.target.value) }
				/>
			</Box>
		</div>
	);
}
