import api from "./api";

export const getDepartmentList = async () => {
	const resp = await api.get("/v1/settings", {
		params: {
			is_active: true,
			set_group: "department",
			sort: "acs",
			order: "content_value",
			limit: 100,
		},
	});

	return resp.data.data as { set_key: string; content_value: string }[];
};

export const getSettingList = async (params: { group: string }) => {
	const resp = await api.get("/v1/settings", {
		params: {
			is_active: true,
			set_group: params.group,
			sort: "asc",
			order: "content_value",
			limit: 100,
			page: 1,
		},
	});

	return resp.data;
};
