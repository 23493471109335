import api from "./api";

export const getInsightMetric = async (params: {
	orgCode: string;
	departCode: string;
	industry: string;
	month: number;
	year: number;
}) => {
	const resp = await api.get("/v1/metric/insight", {
		params: {
			organization_code: params.orgCode,
			department_code: params.departCode,
			industry_name: params.industry,
			month: params.month,
			year: params.year,
		},
	});
	return resp.data;
};

export const getInsightLikeMetric = async (params: {
	orgCode: string;
	departCode: string;
	industry: string;
	month: number;
	year: number;
}) => {
	const resp = await api.get("/v1/metric/insight/like", {
		params: {
			organization_code: params.orgCode,
			department_code: params.departCode,
			industry_name: params.industry,
			month: params.month,
			year: params.year,
		},
	});
	return resp.data;
};

export const getPostMetric = async (params: {
	orgCode: string;
	departCode: string;
	industry: string;
	month: number;
	year: number;
	type: string;
}) => {
	const resp = await api.get("/v1/metric/post", {
		params: {
			organization_code: params.orgCode,
			department_code: params.departCode,
			industry_name: params.industry,
			month: params.month,
			year: params.year,
			type: params.type,
		},
	});
	return resp.data;
};

export const getPostLikeMetric = async (params: {
	orgCode: string;
	departCode: string;
	industry: string;
	month: number;
	year: number;
	type: string;
}) => {
	const resp = await api.get("/v1/metric/post/like", {
		params: {
			organization_code: params.orgCode,
			department_code: params.departCode,
			industry_name: params.industry,
			month: params.month,
			year: params.year,
			type: params.type,
		},
	});
	return resp.data;
};

export const getSpecialistMetric = async (params: {
	orgCode: string;
	departCode: string;
	industry: string;
	month: number;
	year: number;
}) => {
	const resp = await api.get("/v1/metric/specialist", {
		params: {
			organization_code: params.orgCode,
			department_code: params.departCode,
			industry_name: params.industry,
			month: params.month,
			year: params.year,
		},
	});
	return resp.data;
};

export const getSpecialistLikeMetric = async (params: {
	orgCode: string;
	departCode: string;
	industry: string;
	month: number;
	year: number;
}) => {
	const resp = await api.get("/v1/metric/specialist/favorite", {
		params: {
			organization_code: params.orgCode,
			department_code: params.departCode,
			industry_name: params.industry,
			month: params.month,
			year: params.year,
		},
	});
	return resp.data;
};

export const getSpecialistAvailableMetric = async (params: {
	orgCode: string;
	departCode: string;
	industry: string;
	month: number;
	year: number;
}) => {
	const resp = await api.get("/v1/metric/specialist/available", {
		params: {
			organization_code: params.orgCode,
			department_code: params.departCode,
			industry_name: params.industry,
			month: params.month,
			year: params.year,
		},
	});
	return resp.data;
};

export const getSpecialistReservationMetric = async (params: {
	orgCode: string;
	departCode: string;
	industry: string;
	month: number;
	year: number;
}) => {
	const resp = await api.get("/v1/metric/specialist/reservation", {
		params: {
			organization_code: params.orgCode,
			department_code: params.departCode,
			industry_name: params.industry,
			month: params.month,
			year: params.year,
		},
	});
	return resp.data;
};

export const getOrganizationMetric = async (params: {
	orgCode: string;
	departCode: string;
	industry: string;
	month: number;
	year: number;
}) => {
	const resp = await api.get("/v1/metric/organization", {
		params: {
			organization_code: params.orgCode,
			department_code: params.departCode,
			industry_name: params.industry,
			month: params.month,
			year: params.year,
		},
	});
	return resp.data;
};

export const getOrganizationRegisteredMetric = async (params: {
	orgCode: string;
	departCode: string;
	industry: string;
	month: number;
	year: number;
}) => {
	const resp = await api.get("/v1/metric/organization/registered-code", {
		params: {
			organization_code: params.orgCode,
			department_code: params.departCode,
			industry_name: params.industry,
			month: params.month,
			year: params.year,
		},
	});
	return resp.data;
};

export const getOrganizationActiveCodeMetric = async (params: {
	orgCode: string;
	departCode: string;
	industry: string;
	month: number;
	year: number;
}) => {
	const resp = await api.get("/v1/metric/organization/active-code", {
		params: {
			organization_code: params.orgCode,
			department_code: params.departCode,
			industry_name: params.industry,
			month: params.month,
			year: params.year,
		},
	});
	return resp.data;
};

export const getMemberActiveUserMetric = async (params: {
	orgCode: string;
	departCode: string;
	industry: string;
	month: number;
	year: number;
}) => {
	const resp = await api.get("/v1/metric/member/active-user", {
		params: {
			organization_code: params.orgCode,
			department_code: params.departCode,
			industry_name: params.industry,
			month: params.month,
			year: params.year,
		},
	});
	return resp.data;
};

export const getMemberActiveScreenMetric = async (params: {
	orgCode: string;
	departCode: string;
	industry: string;
	month: number;
	year: number;
}) => {
	const resp = await api.get("/v1/metric/member/active-screen", {
		params: {
			organization_code: params.orgCode,
			department_code: params.departCode,
			industry_name: params.industry,
			month: params.month,
			year: params.year,
		},
	});
	return resp.data;
};
