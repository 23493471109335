import React, { useEffect, useState } from 'react';
import service from 'service';
import { Content } from './Content';
import { EngagementActiveCode, EngagementDailyUser, EngagementScreenTime } from './Detail';
import { splitChart } from './Helper';

export default function MetricContent(props: {
	orgCode: string, departCode: string, industryName: string,
	monthValue: number, yearValue: number;
}) {
	return (
		<>
			<ActiceCode { ...props }></ActiceCode>
			<DailyActive { ...props }></DailyActive>
			<ScreenTime { ...props }></ScreenTime>
		</>
	);
}

function ActiceCode(props: {
	orgCode: string, departCode: string, industryName: string,
	monthValue: number, yearValue: number;
}) {
	// Local State
	const [values, setValues] = useState<number[]>([]);
	const [labels, setLabels] = useState<string[]>([]);
	const [stepSize, setStepSize] = useState(0);

	const initAnalytic = async () => {
		const resp = await service.metric.getOrganizationActiveCodeMetric({
			departCode: props.departCode,
			industry: props.industryName,
			month: props.monthValue,
			year: props.yearValue,
			orgCode: props.orgCode,
		});
		const countData = resp.pagination.count ?? 0;
		if (countData === 0 || resp.data.length === 0) {
			setValues([]);
			setLabels([]);
		} else {
			const values = splitChart(resp.data);

			const labels: string[] = [];
			for (let index = 1; index <= values.length; index++) {
				labels.push(`${index}`);
			}

			let maxActive = 0;
			for (const item of resp.data) {
				if (maxActive < item.total) {
					maxActive = item.total;
				}
			}

			setStepSize(Math.round(maxActive / 4));
			setValues(values);
			setLabels(labels);
		}
	};

	useEffect(() => {
		initAnalytic();
	}, [props]);

	return <Content
		{ ...props }
		stepSize={ stepSize }
		labels={ labels }
		values={ values }
		title="Number of Active Code"
		detail={ <EngagementActiveCode></EngagementActiveCode> }
	></Content>;
}

function DailyActive(props: {
	orgCode: string, departCode: string, industryName: string,
	monthValue: number, yearValue: number, isMinimal?: boolean;
}) {
	// Local State
	const [values, setValues] = useState<number[]>([]);
	const [labels, setLabels] = useState<string[]>([]);
	const [stepSize, setStepSize] = useState(0);

	const initAnalytic = async () => {
		const resp = await service.metric.getMemberActiveUserMetric({
			departCode: props.departCode,
			industry: props.industryName,
			month: props.monthValue,
			year: props.yearValue,
			orgCode: props.orgCode,
		});
		const countData = resp.pagination.count ?? 0;
		if (countData === 0 || resp.data.length === 0) {
			setValues([]);
			setLabels([]);
		} else {
			const values = splitChart(resp.data);

			const labels: string[] = [];
			for (let index = 1; index <= values.length; index++) {
				labels.push(`${index}`);
			}

			let maxActive = 0;
			for (const item of resp.data) {
				if (maxActive < item.total) {
					maxActive = item.total;
				}
			}

			setStepSize(Math.round(maxActive / 4));
			setValues(values);
			setLabels(labels);
		}
	};

	useEffect(() => {
		initAnalytic();
	}, [props]);

	return <Content
		{ ...props }
		stepSize={ stepSize }
		labels={ labels }
		values={ values }
		title="Number of Daily Active Users"
		detail={ <EngagementDailyUser></EngagementDailyUser> }
	></Content>;
}

function ScreenTime(props: {
	orgCode: string, departCode: string, industryName: string,
	monthValue: number, yearValue: number, isMinimal?: boolean;
}) {
	// Local State
	const [values, setValues] = useState<number[]>([]);
	const [labels, setLabels] = useState<string[]>([]);
	const [stepSize, setStepSize] = useState(0);

	const initAnalytic = async () => {
		const resp = await service.metric.getMemberActiveScreenMetric({
			departCode: props.departCode,
			industry: props.industryName,
			month: props.monthValue,
			year: props.yearValue,
			orgCode: props.orgCode,
		});
		const countData = resp.pagination.count ?? 0;
		if (countData === 0 || resp.data.length === 0) {
			setValues([]);
			setLabels([]);
		} else {
			const values = splitChart(resp.data);

			const labels: string[] = [];
			for (let index = 1; index <= values.length; index++) {
				labels.push(`${index}`);
			}

			let maxActive = 0;
			for (const item of resp.data) {
				if (maxActive < item.total) {
					maxActive = item.total;
				}
			}

			for (let index = 0; index < values.length; index++) {
				values[index] = Math.round(Number(values[index] / 60));
			}

			setStepSize(Math.round(maxActive / 4));
			setValues(values);
			setLabels(labels);
		}
	};

	useEffect(() => {
		initAnalytic();
	}, [props]);

	return <Content
		{ ...props }
		stepSize={ stepSize }
		labels={ labels }
		values={ values }
		title="Number of Total Screen Time (mins)"
		detail={ <EngagementScreenTime></EngagementScreenTime> }
	></Content>;
}
