import React, { useEffect, useState } from 'react';
import service from 'service';
import { Content } from './Content';
import { ContentInsight, ContentInsightLike, ContentPoll, ContentPollLike, ContentPost, ContentPostLike } from './Detail';
import { makeCumulative, splitChart } from './Helper';

export default function MetricContent(props: {
	orgCode: string, departCode: string, industryName: string,
	monthValue: number, yearValue: number;
}) {
	return (
		<>
			<Insight { ...props }></Insight>
			<InsightLike { ...props }></InsightLike>
			<Post { ...props } type="regular"></Post>
			<PostLike { ...props } type="regular"></PostLike>
			<Poll { ...props } type="poll"></Poll>
			<PollLike { ...props } type="poll"></PollLike>
		</>
	);
}

function Insight(props: {
	orgCode: string, departCode: string, industryName: string,
	monthValue: number, yearValue: number;
}) {
	// Local State
	const [values, setValues] = useState<number[]>([]);
	const [labels, setLabels] = useState<string[]>([]);
	const [stepSize, setStepSize] = useState(0);

	const initAnalytic = async () => {
		const resp = await service.metric.getInsightMetric({
			departCode: props.departCode,
			industry: props.industryName,
			month: props.monthValue,
			year: props.yearValue,
			orgCode: props.orgCode,
		});
		const countData = resp.pagination.count ?? 0;
		if (countData === 0 || resp.data.length === 0) {
			setValues([]);
			setLabels([]);
		} else {
			const values = makeCumulative(
				splitChart(resp.data)
			);

			const labels: string[] = [];
			for (let index = 1; index <= values.length; index++) {
				labels.push(`${index}`);
			}

			let maxActive = 0;
			for (const item of resp.data) {
				if (maxActive < item.total) {
					maxActive = item.total;
				}
			}

			setStepSize(Math.round(maxActive / 4));
			setValues(values);
			setLabels(labels);
		}
	};

	useEffect(() => {
		initAnalytic();
	}, [props]);

	return <Content
		{ ...props }
		stepSize={ stepSize }
		labels={ labels }
		values={ values }
		title="Number of Insights"
		detail={ <ContentInsight></ContentInsight> }
	></Content>;
}

function InsightLike(props: {
	orgCode: string, departCode: string, industryName: string,
	monthValue: number, yearValue: number, isMinimal?: boolean;
}) {
	// Local State
	const [values, setValues] = useState<number[]>([]);
	const [labels, setLabels] = useState<string[]>([]);
	const [stepSize, setStepSize] = useState(0);

	const initAnalytic = async () => {
		const resp = await service.metric.getInsightLikeMetric({
			departCode: props.departCode,
			industry: props.industryName,
			month: props.monthValue,
			year: props.yearValue,
			orgCode: props.orgCode,
		});
		const countData = resp.pagination.count ?? 0;
		if (countData === 0 || resp.data.length === 0) {
			setValues([]);
			setLabels([]);
		} else {
			const values = makeCumulative(
				splitChart(resp.data)
			);

			const labels: string[] = [];
			for (let index = 1; index <= values.length; index++) {
				labels.push(`${index}`);
			}

			let maxActive = 0;
			for (const item of resp.data) {
				if (maxActive < item.total) {
					maxActive = item.total;
				}
			}

			setStepSize(Math.round(maxActive / 4));
			setValues(values);
			setLabels(labels);
		}
	};

	useEffect(() => {
		initAnalytic();
	}, [props]);

	return <Content
		{ ...props }
		stepSize={ stepSize }
		labels={ labels }
		values={ values }
		title="Number of Insight Hearts"
		detail={ <ContentInsightLike></ContentInsightLike> }
	></Content>;
}

function Post(props: {
	orgCode: string, departCode: string, industryName: string,
	monthValue: number, yearValue: number, isMinimal?: boolean;
	type: string;
}) {
	// Local State
	const [values, setValues] = useState<number[]>([]);
	const [labels, setLabels] = useState<string[]>([]);
	const [stepSize, setStepSize] = useState(0);

	const initAnalytic = async () => {
		const resp = await service.metric.getPostMetric({
			departCode: props.departCode,
			industry: props.industryName,
			month: props.monthValue,
			year: props.yearValue,
			orgCode: props.orgCode,
			type: props.type,
		});
		const countData = resp.pagination.count ?? 0;
		if (countData === 0 || resp.data.length === 0) {
			setValues([]);
			setLabels([]);
		} else {
			const values = makeCumulative(
				splitChart(resp.data)
			);

			const labels: string[] = [];
			for (let index = 1; index <= values.length; index++) {
				labels.push(`${index}`);
			}

			let maxActive = 0;
			for (const item of resp.data) {
				if (maxActive < item.total) {
					maxActive = item.total;
				}
			}

			setStepSize(Math.round(maxActive / 4));
			setValues(values);
			setLabels(labels);
		}
	};

	useEffect(() => {
		initAnalytic();
	}, [props]);

	return <Content
		{ ...props }
		stepSize={ stepSize }
		labels={ labels }
		values={ values }
		title="Number of Forum Post"
		detail={ <ContentPost></ContentPost> }
	></Content>;
}

function PostLike(props: {
	orgCode: string, departCode: string, industryName: string,
	monthValue: number, yearValue: number, isMinimal?: boolean;
	type: string;
}) {
	// Local State
	const [values, setValues] = useState<number[]>([]);
	const [labels, setLabels] = useState<string[]>([]);
	const [stepSize, setStepSize] = useState(0);

	const initAnalytic = async () => {
		const resp = await service.metric.getPostMetric({
			departCode: props.departCode,
			industry: props.industryName,
			month: props.monthValue,
			year: props.yearValue,
			orgCode: props.orgCode,
			type: props.type,
		});
		const countData = resp.pagination.count ?? 0;
		if (countData === 0 || resp.data.length === 0) {
			setValues([]);
			setLabels([]);
		} else {
			const values = makeCumulative(
				splitChart(resp.data)
			);

			const labels: string[] = [];
			for (let index = 1; index <= values.length; index++) {
				labels.push(`${index}`);
			}

			let maxActive = 0;
			for (const item of resp.data) {
				if (maxActive < item.total) {
					maxActive = item.total;
				}
			}

			setStepSize(Math.round(maxActive / 4));
			setValues(values);
			setLabels(labels);
		}
	};

	useEffect(() => {
		initAnalytic();
	}, [props]);

	return <Content
		{ ...props }
		stepSize={ stepSize }
		labels={ labels }
		values={ values }
		title="Number of Forum Post Hearts"
		detail={ <ContentPostLike></ContentPostLike> }
	></Content>;
}

function Poll(props: {
	orgCode: string, departCode: string, industryName: string,
	monthValue: number, yearValue: number, isMinimal?: boolean;
	type: string;
}) {
	// Local State
	const [values, setValues] = useState<number[]>([]);
	const [labels, setLabels] = useState<string[]>([]);
	const [stepSize, setStepSize] = useState(0);

	const initAnalytic = async () => {
		const resp = await service.metric.getPostMetric({
			departCode: props.departCode,
			industry: props.industryName,
			month: props.monthValue,
			year: props.yearValue,
			orgCode: props.orgCode,
			type: props.type,
		});
		const countData = resp.pagination.count ?? 0;
		if (countData === 0 || resp.data.length === 0) {
			setValues([]);
			setLabels([]);
		} else {
			const values = makeCumulative(
				splitChart(resp.data)
			);

			const labels: string[] = [];
			for (let index = 1; index <= values.length; index++) {
				labels.push(`${index}`);
			}

			let maxActive = 0;
			for (const item of resp.data) {
				if (maxActive < item.total) {
					maxActive = item.total;
				}
			}

			setStepSize(Math.round(maxActive / 4));
			setValues(values);
			setLabels(labels);
		}
	};

	useEffect(() => {
		initAnalytic();
	}, [props]);

	return <Content
		{ ...props }
		stepSize={ stepSize }
		labels={ labels }
		values={ values }
		title="Number of Forum Poll"
		detail={ <ContentPoll></ContentPoll> }
	></Content>;
}

function PollLike(props: {
	orgCode: string, departCode: string, industryName: string,
	monthValue: number, yearValue: number, isMinimal?: boolean;
	type: string;
}) {
	// Local State
	const [values, setValues] = useState<number[]>([]);
	const [labels, setLabels] = useState<string[]>([]);
	const [stepSize, setStepSize] = useState(0);

	const initAnalytic = async () => {
		const resp = await service.metric.getPostMetric({
			departCode: props.departCode,
			industry: props.industryName,
			month: props.monthValue,
			year: props.yearValue,
			orgCode: props.orgCode,
			type: props.type,
		});
		const countData = resp.pagination.count ?? 0;
		if (countData === 0 || resp.data.length === 0) {
			setValues([]);
			setLabels([]);
		} else {
			const values = makeCumulative(
				splitChart(resp.data)
			);

			const labels: string[] = [];
			for (let index = 1; index <= values.length; index++) {
				labels.push(`${index}`);
			}

			let maxActive = 0;
			for (const item of resp.data) {
				if (maxActive < item.total) {
					maxActive = item.total;
				}
			}

			setStepSize(Math.round(maxActive / 4));
			setValues(values);
			setLabels(labels);
		}
	};

	useEffect(() => {
		initAnalytic();
	}, [props]);

	return <Content
		{ ...props }
		stepSize={ stepSize }
		labels={ labels }
		values={ values }
		title="Number of Forum Poll Hearts"
		detail={ <ContentPollLike></ContentPollLike> }
	></Content>;
}
