import React from 'react';
import { Button, TableCell, TableRow } from '@mui/material';
import { Link } from 'react-router-dom';
import { Endpoints, Images } from 'constant';
import Iconify from 'screens/Layout/components/Iconify';

export default function CourseRow(props: {
    row: any, withActions: boolean, onPreview: () => void; onEdit?: () => void; onDelete?: () => void;
    onTranslation?: (val: string) => void; onAddTranslation?: (val: string) => void;
}) {
    const { id, bg_image_url, title, content, chapter_name, ordering, reviewer_name, total_likes, translations } = props.row;
    const contentDiv = document.createElement('div');

    contentDiv.innerHTML = content;

    const bgImgUrl = Endpoints.baseUrlAws + bg_image_url;
    const textContent = contentDiv.textContent || contentDiv.innerText || '-';
    const contentStr = textContent.length > 64 ? textContent.substring(0, 60) + ' ...' : textContent;

    const onTranslation = (val: string) => {
        if (props.onTranslation) {
            props.onTranslation(val);
        }
    };

    const onAddTranslation = (val: string) => {
        if (props.onAddTranslation) {
            props.onAddTranslation(val);
        }
    };

    return (
        <TableRow key={ id } hover >

            <TableCell align='left' sx={ {
                textTransform: 'capitalize',
                fontFamily: 'Fonts.interRegular',
                width: '150px'
            } }>
                { title }
            </TableCell>

            <TableCell align='left' sx={ {
                textTransform: 'capitalize',
                fontFamily: 'Fonts.interRegular',
                width: '250px'
            } }>
                { contentStr }
            </TableCell>

            <TableCell align='left' sx={ {
                textTransform: 'capitalize',
                fontFamily: 'Fonts.interRegular'
            } }>
                <img className='h-12 w-16 object-cover' src={ bgImgUrl } alt="" />
            </TableCell>

            <TableCell align='left' sx={ {
                textTransform: 'capitalize',
                fontFamily: 'Fonts.interRegular',
                width: '150px'
            } }>
                { chapter_name }
            </TableCell>

            <TableCell align='left' sx={ {
                fontFamily: 'Fonts.interRegular'
            } }>
                <div className='flex gap-2'>
                    {
                        translations ? (
                            translations.map((val: string, i: number) => {
                                return (
                                    <div style={ { paddingTop: '7px' } } key={ i }>
                                        <span className='bg-neutral-500 text-white px-2 py-1 rounded-md cursor-pointer' onClick={ () => onTranslation(val) }>{ val }</span>
                                    </div>
                                );
                            })
                        ) : null
                    }

                    <div className='ml-2'>
                        <Button sx={ { minWidth: 'auto !important' } } onClick={ () => onAddTranslation('id') } >
                            <Iconify icon='carbon:add-alt' sx={ { fontSize: '24px' } }></Iconify>
                        </Button>
                    </div>
                </div>
            </TableCell>

            <TableCell align='left' sx={ {
                textTransform: 'capitalize',
                fontFamily: 'Fonts.interRegular'
            } }>
                { ordering }
            </TableCell>

            <TableCell align='left' sx={ {
                textTransform: 'capitalize',
                fontFamily: 'Fonts.interRegular'
            } }>
                { reviewer_name }
            </TableCell>

            <TableCell align='left' sx={ {
                textTransform: 'capitalize',
                fontFamily: 'Fonts.interRegular'
            } }>
                { total_likes } likes
            </TableCell>

            <TableCell align='left' sx={ {
                textTransform: 'capitalize',
                fontFamily: 'Fonts.interRegular'
            } }>
                <Link to='#' onClick={ () => props.onPreview() }>Preview Context</Link>
            </TableCell>

            {
                props.withActions ? (
                    <>
                        <TableCell align='left' sx={ { width: '140px' } }>
                            <Button sx={ { minWidth: 'auto !important' } } onClick={ props.onEdit }>
                                <img src={ Images.btn_edit } style={ { width: '30px' } } />
                            </Button>

                            <Button sx={ { minWidth: 'auto !important' } } onClick={ props.onDelete }>
                                <img src={ Images.btn_delete } style={ { width: '30px' } } />
                            </Button>
                        </TableCell>
                    </>
                ) : (
                    <></>
                )
            }
        </TableRow>
    );
}
