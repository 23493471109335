import { Endpoints, Questionnaire } from 'constant';
import { IChangeCallback } from 'interfaces/callback';
import { ILineValues } from 'interfaces/chart';
import { IDetail, ISummary } from 'interfaces/voe';
import React, { useEffect, useState } from 'react';
import api from 'service/api';
import { utils } from 'utils';
import { Content } from './AssessmentContent';

const stressIndexQuestions = [1, 6, 8, 11, 12, 14, 18];
const depressIndexQuestions = [3, 5, 10, 13, 16, 17, 21];
const anxietyIndexQuestions = [2, 4, 7, 9, 15, 19, 20];
const answerCount = 4;
const maxY = 42;
const voeType = "dass_21";

export function PersonalDistressAnalytic(props: {
    orgCode: string, departCode: string, industryName: string,
    monthValue: number, yearValue: number;

}) {
    return (
        <>
            <DepressionAnalytic { ...props }></DepressionAnalytic>
            <AnxietyAnalytic { ...props }></AnxietyAnalytic>
            <StressAnalytic { ...props }></StressAnalytic>
        </>
    );
}

export function DepressionAnalytic(props: {
    orgCode: string, departCode: string, industryName: string;
    monthValue: number, yearValue: number, isMinimal?: boolean;
}) {
    // Local State
    const [lineValues, setLineValues] = useState<ILineValues>({});
    const [lineValuesRaw, setLineValuesRaw] = useState<ILineValues>({});
    const [detailValues, setDetailValues] = useState<IDetail[]>([]);
    const [totalUser, setTotalUser] = useState(0);
    const [averageScore, setAverageScore] = useState(0);
    const [summary, setSummary] = useState<ISummary[]>([]);

    const detailLabels: string[] = createDetailLabels();

    const isMinimal = props.isMinimal ?? false;

    const initAnalytic = async () => {
        const resp = await api.get(`${Endpoints.voe}/questionnaire-analytic`, {
            params: {
                "organization_code": props.orgCode,
                "department_code": props.departCode,
                "industry_name": props.industryName,
                "month": props.monthValue,
                "year": props.yearValue,
                "type": voeType,
            },
        });
        const respData = resp.data;
        const userCount = respData.pagination.count;
        if (userCount == 0) {
            setTotalUser(0);
            setLineValues({});
            setSummary([]);
        } else {
            const label = "depression";
            const scoreLabel = "depression_score";
            const values = splitChartLinePersonalDistress(respData.data, label, scoreLabel);
            const summary = splitSummaryPersonalDistress(respData.data, (item) => {
                const val = Number(item.depression_score);

                if (val <= 9) {
                    return 0;
                } else if (val <= 20) {
                    return 1;
                }

                return 2;
            });

            let count = 0;
            for (const item of respData.data) {
                count += item.questionnaire_score[scoreLabel];
            }

            const average = count / userCount;

            setSummary(summary);
            setAverageScore(average);
            setTotalUser(userCount);
            setLineValues(values.values);
            setLineValuesRaw(values.users);
        }
    };

    const initDetail = async () => {
        const resp = await api.get(`${Endpoints.voe}/questionnaire-analytic-detail`, {
            params: {
                "organization_code": props.orgCode,
                "department_code": props.departCode,
                "industry_name": props.industryName,
                "month": props.monthValue,
                "year": props.yearValue,
                "type": voeType,
            },
        });

        const respData = resp.data;
        const count = respData.pagination.count;
        if (count == 0) {
            setDetailValues([]);
            setDetailValues([]);
        }
        else {
            const temp = splitChartDetailQuestionnaire(respData.data, Questionnaire.dass, answerCount);
            const values: IDetail[] = [];
            for (const index of depressIndexQuestions) {
                if (index >= temp.length) {
                    break;
                }

                values.push(temp[index]);
            }

            setDetailValues(values);
        }
    };

    useEffect(() => {
        initAnalytic();
        initDetail();
    }, [props]);

    return (
        <Content
            title='Personal Distress (Depression)'
            detailStartIndex={ 0 }
            detailLabels={ detailLabels }
            maxY={ maxY }
            summary={ summary }
            detailValues={ detailValues }
            averageScore={ averageScore }
            totalUser={ totalUser }
            values={ lineValues }
            valuesRaw={ lineValuesRaw }
            isMinimal={ isMinimal }
        ></Content>
    );
}

export function AnxietyAnalytic(props: {
    orgCode: string;
    departCode: string;
    industryName: string;
    monthValue: number;
    yearValue: number;
    isMinimal?: boolean;
}) {
    const currentDate = new Date();

    // Local State
    const [monthValue, setMonthValue] = useState(currentDate.getMonth() + 1);
    const [lineValues, setLineValues] = useState<ILineValues>({});
    const [lineValuesRaw, setLineValuesRaw] = useState<ILineValues>({});
    const [detailValues, setDetailValues] = useState<IDetail[]>([]);
    const [totalUser, setTotalUser] = useState(0);
    const [averageScore, setAverageScore] = useState(0);
    const [summary, setSummary] = useState<ISummary[]>([]);

    const detailLabels: string[] = createDetailLabels();

    const isMinimal = props.isMinimal ?? false;

    const initAnalytic = async () => {
        const resp = await api.get(`${Endpoints.voe}/questionnaire-analytic`, {
            params: {
                "organization_code": props.orgCode,
                "department_code": props.departCode,
                "industry_name": props.industryName,
                "month": props.monthValue,
                "year": props.yearValue,
                "type": voeType,
            },
        });
        const respData = resp.data;
        const userCount = respData.pagination.count;
        if (userCount == 0) {
            setTotalUser(0);
            setLineValues({});
            setSummary([]);
        } else {
            const label = "anxiety";
            const scoreLabel = "anxiety_score";
            const values = splitChartLinePersonalDistress(respData.data, label, scoreLabel);
            const summary = splitSummaryPersonalDistress(respData.data, (item) => {
                const val = Number(item.anxiety_score);
                if (val <= 6) {
                    return 0;
                } else if (val <= 14) {
                    return 1;
                }

                return 2;
            });

            let count = 0;
            for (const item of respData.data) {
                count += item.questionnaire_score[scoreLabel];
            }

            const average = count / userCount;

            setSummary(summary);
            setAverageScore(average);
            setTotalUser(userCount);
            setLineValues(values.values);
            setLineValuesRaw(values.users);
        }
    };

    const initDetail = async () => {
        const resp = await api.get(`${Endpoints.voe}/questionnaire-analytic-detail`, {
            params: {
                "organization_code": props.orgCode,
                "department_code": props.departCode,
                "industry_name": props.industryName,
                "month": props.monthValue,
                "year": props.yearValue,
                "type": voeType,
            },
        });

        const respData = resp.data;
        const count = respData.pagination.count;
        if (count == 0) {
            setDetailValues([]);
            setDetailValues([]);
        }
        else {
            const temp = splitChartDetailQuestionnaire(respData.data, Questionnaire.dass, answerCount);
            const values: IDetail[] = [];
            for (const index of anxietyIndexQuestions) {
                if (index >= temp.length) {
                    break;
                }

                values.push(temp[index]);
            }

            setDetailValues(values);
        }
    };

    useEffect(() => {
        initAnalytic();
        initDetail();
    }, [monthValue, props]);

    return (
        <Content
            title='Personal Distress (Anxiety)'
            detailStartIndex={ 0 }
            detailLabels={ detailLabels }
            maxY={ maxY }
            summary={ summary }
            detailValues={ detailValues }
            averageScore={ averageScore }
            totalUser={ totalUser }
            values={ lineValues }
            valuesRaw={ lineValuesRaw }
            isMinimal={ isMinimal }
        ></Content>
    );
}

export function StressAnalytic(props: {
    orgCode: string,
    departCode: string,
    industryName: string,
    monthValue: number,
    yearValue: number;
    isMinimal?: boolean;
}) {
    // Local State
    const [lineValues, setLineValues] = useState<ILineValues>({});
    const [lineValuesRaw, setLineValuesRaw] = useState<ILineValues>({});
    const [detailValues, setDetailValues] = useState<IDetail[]>([]);
    const [totalUser, setTotalUser] = useState(0);
    const [averageScore, setAverageScore] = useState(0);
    const [summary, setSummary] = useState<ISummary[]>([]);

    const detailLabels: string[] = createDetailLabels();

    const isMinimal = props.isMinimal ?? false;

    const initAnalytic = async () => {
        const resp = await api.get(`${Endpoints.voe}/questionnaire-analytic`, {
            params: {
                "organization_code": props.orgCode,
                "department_code": props.departCode,
                "industry_name": props.industryName,
                "month": props.monthValue,
                "year": props.yearValue,
                "type": voeType,
            },
        });
        const respData = resp.data;
        const userCount = respData.pagination.count;
        if (userCount == 0) {
            setTotalUser(0);
            setLineValues({});
            setSummary([]);
        } else {
            const label = "stress";
            const scoreLabel = "stress_score";
            const values = splitChartLinePersonalDistress(respData.data, label, scoreLabel);
            const summary = splitSummaryPersonalDistress(respData.data, (item) => {
                const val = Number(item.stress_score);
                if (val <= 10) {
                    return 0;
                } else if (val <= 26) {
                    return 1;
                }

                return 2;
            });

            let count = 0;
            for (const item of respData.data) {
                count += item.questionnaire_score[scoreLabel];
            }

            const average = count / userCount;

            setSummary(summary);
            setAverageScore(average);
            setTotalUser(userCount);
            setLineValues(values.values);
            setLineValuesRaw(values.users);
        }
    };

    const initDetail = async () => {
        const resp = await api.get(`${Endpoints.voe}/questionnaire-analytic-detail`, {
            params: {
                "organization_code": props.orgCode,
                "department_code": props.departCode,
                "industry_name": props.industryName,
                "month": props.monthValue,
                "year": props.yearValue,
                "type": voeType,
            },
        });

        const respData = resp.data;
        const count = respData.pagination.count;
        if (count == 0) {
            setDetailValues([]);
            setDetailValues([]);
        }
        else {
            const temp = splitChartDetailQuestionnaire(respData.data, Questionnaire.dass, answerCount);
            const values: IDetail[] = [];
            for (const index of stressIndexQuestions) {
                if (index >= temp.length) {
                    break;
                }

                values.push(temp[index]);
            }

            setDetailValues(values);
        }
    };

    useEffect(() => {
        initAnalytic();
        initDetail();
    }, [props]);

    return (
        <Content
            title='Personal Distress (Stress)'
            detailStartIndex={ 0 }
            detailLabels={ detailLabels }
            maxY={ maxY }
            summary={ summary }
            detailValues={ detailValues }
            averageScore={ averageScore }
            totalUser={ totalUser }
            values={ lineValues }
            valuesRaw={ lineValuesRaw }
            isMinimal={ isMinimal }
        ></Content>
    );
}

const createDetailLabels = () => {
    const temp: string[] = [];
    for (let i = 0; i < answerCount; i++) {
        temp.push(i.toString());
    }

    return temp;
};

// Point Start From Zero //
const splitChartDetailQuestionnaire = (
    data: any[],
    questionnaire: string[],
    answerCount: number
) => {
    const temp: IDetail[] = [];

    let index = 0;
    for (const item of questionnaire) {
        temp.push({
            title: `Q${++index}: ${item}`,
            values: Array(answerCount).fill(0),
            average: 0,
        });
    }

    for (const item of data) {
        const order = Number(item.questionnaire_order) - 1;
        const val = Number(item.questionnaire_score);
        const countVal = Number(item.count_questionnaire_score);

        if (val >= answerCount) {
            continue;
        }

        temp[order].values[val] += countVal;
    }

    for (const item of temp) {
        const count = item.values.reduce((prev, cur) => prev + cur);
        const average = item.values.reduce((prev, cur, curIndex) => prev + cur * curIndex, 0) / count;

        item.average = average;
    }

    return temp;
};

const splitChartLinePersonalDistress = (data: any[], label: string, scoreLabel: string) => {
    const biggestWeek = Math.ceil(utils.lastDate() / 7);
    const temp: ILineValues = {
        [label]: Array(biggestWeek).fill(0),
    };
    const weekCount = Array(biggestWeek).fill(0);

    for (const item of data) {
        const week = Math.floor(new Date(item.created_date).getDate() / 7);
        const val = Number(item.questionnaire_score[scoreLabel]);

        weekCount[week]++;
        temp[label][week] += val;
    }

    for (let index = 0; index < biggestWeek; index++) {
        if (weekCount[index] != 0) {
            temp[label][index] = Number((temp[label][index] / weekCount[index]).toFixed(2));
        }
    }

    return {
        values: temp, users: {
            [label]: weekCount,
        }
    };
};

const splitSummaryPersonalDistress = (data: any[], getScore: IChangeCallback<any, number>) => {
    const summary: ISummary[] = [
        {
            title: "Normal",
            values: [0, 0],
        }, {
            title: "Mild to Moderate",
            values: [0, 0],
        }, {
            title: "Severe",
            values: [0, 0],
        }
    ];

    for (const item of data) {
        const score = getScore(item.questionnaire_score);

        summary[score].values[0]++;
    }

    const count = data.length;
    for (const item of summary) {
        item.values[1] = count - item.values[0];
    }

    return summary;
};
