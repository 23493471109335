import { Box, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Typography } from '@mui/material';
import { TableEmptyRows, TableHeadCustom } from 'components/Table';
import useTable from 'components/Table/useTable';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { getAppFeedbackList } from 'service/analytic';
import { utils } from 'utils';

const TABLE_HEAD = [
	{
		id: 'created_date',
		label: 'DATE',
		alignRight: false
	},
	{
		id: 'email',
		label: 'EMAIL'
	},
	{
		id: 'feedback_description',
		label: 'FEEDBACK'
	},
];

export default function AppFeedback(props: {
	orgCode: string, departCode: string, industryName: string,
	monthValue: number, yearValue: number;
}) {
	const [data, setData] = useState<any[]>([]);
	const [limit, setLimit] = useState(10);
	const [page, setPage] = useState(1);
	const [count, setCount] = useState(0);

	const { onSort } = useTable();
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('created_date');

	const onInit = async () => {
		const resp = await getAppFeedbackList({
			departCode: props.departCode,
			industry: props.industryName,
			month: props.monthValue,
			orgCode: props.orgCode,
			year: props.yearValue,
		});

		const pagination = resp.pagination;

		setCount(pagination.count);
		setLimit(pagination.limit);
		setPage(pagination.page);
		setData(resp.data ?? []);
	};

	const onRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const dataFiltered = utils.applySortFilter({
		data: data,
		comparator: utils.getComparator(order, orderBy),
	});

	const onChangeRowPerPage = (val) => {
		setPage(1);
		setLimit(val);
	};

	const onChangePage = (val) => {
		setPage(val + 1);
	};

	useEffect(() => {
		onInit();
	}, [props]);

	return (
		<div>
			<TableContainer sx={ {
				minWidth: 800,
				position: 'relative'
			} }>

				<Table size='medium'>
					<TableHeadCustom
						headLabel={ TABLE_HEAD }
						rowCount={ data.length }
						orderBy={ orderBy }
						onRequestSort={ onRequestSort }
						onSort={ onSort }
						order={ order }
					/>

					<TableBody>
						{
							dataFiltered.map((row, index) => {
								return (
									<FeedbackRow
										key={ index }
										row={ row }
									/>
								);
							})
						}

						<TableEmptyRows height={ 72 } emptyRows={ data.length } />

					</TableBody>
				</Table>
			</TableContainer>
			<Box sx={ { position: 'relative' } }>
				<TablePagination
					rowsPerPageOptions={ [10, 25, 100] }
					component='div'
					labelRowsPerPage='Items per page: '
					count={ count || 0 }
					rowsPerPage={ limit || 10 }
					page={ page - 1 || 0 }
					onPageChange={ (_, pg) => onChangePage(pg) }
					onRowsPerPageChange={ e => onChangeRowPerPage(e.target.value) }
				/>
			</Box>
		</div>
	);
}

function FeedbackRow(props: { row: any; }) {
	const { created_date, email, feedback_description } = props.row;

	return (
		<TableRow hover role='checkbox' tabIndex={ -1 } >
			<TableCell align='left'>
				<Typography variant='subtitle2' noWrap sx={ {
					fontFamily: 'Fonts.interRegular',
					width: '150px !important'
				} }>
					{ dayjs(created_date).format('DD/MM/YYYY') }
				</Typography>
			</TableCell>

			<TableCell align='left' sx={ { textTransform: 'capitalize' } }>
				<Typography variant='subtitle2' noWrap sx={ {
					fontFamily: 'Fonts.interRegular',
				} }>
					{ email }
				</Typography>
			</TableCell>

			<TableCell align='left' sx={ { textTransform: 'capitalize' } }>
				<Typography variant='subtitle2' noWrap sx={ {
					fontFamily: 'Fonts.interRegular',
					width: '150px !important'
				} }>
					{ feedback_description }
				</Typography>
			</TableCell>
		</TableRow>
	);
}
