import { Jobs } from "interfaces";
import { JobsActionType } from "./actions";

const initialState: Jobs.State = {
	jobs: [],
};

const jobsReducer = (
	state: Jobs.State = initialState,
	action: { type: string; payload: any }
): Jobs.State => {
	if (action.type === JobsActionType.RefreshJobs) {
		return {
			...state,
			jobs: action.payload,
		};
	}

	return state;
};

export default jobsReducer;
